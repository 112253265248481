import { Permission, Spinner, Stack, useEntityDataTableContext, UseForm, useTheme } from "@in-core";
import RolePermissionsTable from "./RolePermissionsTable";
import PermissionImplicationGraph from "../PermissionImplicationGraph";

export interface IUserPermissionsProps {
    form: UseForm;
    permissions?: Permission[];
    permissionImplicationGraph: PermissionImplicationGraph;
    allFlatPermissions: Permission[];
}

const UserPermissions = (props: IUserPermissionsProps) => {
    const theme = useTheme();
    const entityDataTableContext = useEntityDataTableContext()!;

    const form = props.form as any;
    const isRead = entityDataTableContext.innerPermission?.Id === "Read";

    if (!props.permissions) {
        return <Spinner />;
    }

    return (
        <Stack styles={{ root: { height: "100%" } }} tokens={{ childrenGap: theme.spacing.s1 }}>
            <RolePermissionsTable
                form={form}
                permissions={props.permissions}
                permissionImplicationGraph={props.permissionImplicationGraph}
                allFlatPermissions={props.allFlatPermissions}
                disabled={isRead}
            />
        </Stack>
    );
};

export default UserPermissions;
