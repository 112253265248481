import {
    Dropdown as FluentDropdown,
    IconButton,
    IDropdownProps as IFluentDropdownProps,
    useTheme,
} from "@fluentui/react";
import { ISelectionChangeItem, useOnChange } from "@in-core";
import { useState } from "react";

export interface IDropdownProps
    extends Omit<
        IFluentDropdownProps,
        | "openOnKeyboardFocus"
        | "selectedKey"
        | "selectedKeys"
        | "defaultSelectedKey"
        | "defaultSelectedKeys"
        | "onChange"
    > {
    selectedKey?: string | number | null;
    defaultSelectedKey?: string | number;
    selectedKeys?: string[] | number[] | null;
    defaultSelectedKeys?: string[] | number[];
    openOnKeyboardFocus?: boolean;
    hideClear?: boolean;
    onChange?: (items: ISelectionChangeItem<string | number>[]) => void;
}

const Dropdown = (props: IDropdownProps) => {
    const theme = useTheme();
    const [selectedKey, setSelectedKey] = useState<string | number | null | undefined>(
        props.selectedKey ?? props.defaultSelectedKey,
    );
    const [selectedKeys, setSelectedKeys] = useState<string[] | number[] | null | undefined>(
        props.selectedKeys ?? props.defaultSelectedKeys,
    );

    useOnChange(() => {
        setSelectedKey(props.selectedKey);
    }, [props.selectedKey]);

    useOnChange(() => {
        setSelectedKeys(props.selectedKeys);
    }, [props.selectedKeys]);

    const defaultRenderCaretDown = (
        defaultRender: ((props?: IFluentDropdownProps) => JSX.Element | null) | undefined,
    ) => {
        return (renderProps?: IFluentDropdownProps | undefined) => {
            return (
                <>
                    {!props.hideClear &&
                        !props.disabled &&
                        (props.multiSelect
                            ? selectedKeys !== undefined && selectedKeys !== null && selectedKeys.length > 0
                            : selectedKey !== undefined && selectedKey !== null) && (
                            <IconButton
                                iconProps={{
                                    iconName: "Clear",
                                    styles: {
                                        root: {
                                            fontSize: 10,
                                            color: theme.palette.neutralDark,
                                        },
                                    },
                                }}
                                styles={{
                                    root: {
                                        paddingTop: 0,
                                        height: "96%",
                                        border: 0,
                                    },
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    if (props.multiSelect) {
                                        const existingKeys = selectedKeys;

                                        setSelectedKeys([]);

                                        props.onChange &&
                                            props.onChange(
                                                ((existingKeys ?? []) as (string | number)[])
                                                    .filter((x) => {
                                                        return x !== null && x !== undefined;
                                                    })
                                                    .map((x) => {
                                                        return {
                                                            key: x.toString(),
                                                            isSelected: false,
                                                        };
                                                    }) ?? [],
                                            );
                                    } else {
                                        const existingKey = selectedKey;

                                        setSelectedKey(null);

                                        if (existingKey !== undefined && existingKey !== null) {
                                            props.onChange && props.onChange([{ key: existingKey, isSelected: false }]);
                                        }
                                    }
                                }}
                                tabIndex={-1}
                            />
                        )}

                    {defaultRender!(renderProps)}
                </>
            );
        };
    };

    return (
        <FluentDropdown
            styles={{
                caretDownWrapper: {
                    paddingTop: 0,
                    height: 30,
                },
            }}
            {...props}
            selectedKey={props.multiSelect ? undefined : selectedKey}
            selectedKeys={props.multiSelect ? selectedKeys : undefined}
            onChange={(e, option, index) => {
                if (option === undefined) {
                    return;
                }

                if (!props.multiSelect) {
                    setSelectedKey(option.key);
                    props.onChange && props.onChange([{ key: option.key, isSelected: true }]);
                    return;
                }

                let newKeys = [...(selectedKeys ?? [])];
                const changes: ISelectionChangeItem[] = [];
                if (option.selected) {
                    newKeys = [...newKeys, option.key];
                    changes.push({ key: option.key, isSelected: true });
                } else {
                    newKeys = newKeys.filter((x) => {
                        return x !== option.key;
                    });
                    changes.push({ key: option.key, isSelected: false });
                }
                setSelectedKeys(newKeys as string[] | number[]);

                props.onChange && props.onChange(changes);
            }}
            openOnKeyboardFocus={props.openOnKeyboardFocus !== false}
            onRenderCaretDown={(renderProps, defaultRender) => {
                return props.onRenderCaretDown
                    ? props.onRenderCaretDown(renderProps, defaultRenderCaretDown(defaultRender))
                    : defaultRenderCaretDown(defaultRender)(renderProps);
            }}
        />
    );
};

export default Dropdown;
