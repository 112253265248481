import { Separator, Stack } from "@in-core";
import { GetActivities } from "@in-core/api/Entity";
import React from "react";
import DetailsSection from "./DetailsSection";
import EntityAuditLog from "./EntityAuditLog";

export interface IDetailsProps {
    activity: GetActivities.ActivityInfo;
}

const Details = (props: IDetailsProps) => {
    return (
        <Stack>
            <DetailsSection activity={props.activity} />

            {props.activity.AuditLogEntities.map((x) => {
                return (
                    <React.Fragment key={x.Id}>
                        <Separator />

                        <EntityAuditLog auditLogEntity={x} />
                    </React.Fragment>
                );
            })}
        </Stack>
    );
};

export default Details;
