import { translate } from "typed-intl";
import {
    useLocalization as useGenericLocalization,
    callApi as callGenericApi,
    useApi as useGenericApi,
    ValidationResult,
    BackgroundApiRequest,
    getUrlWithQueryString,
    CheckBackgroundApiRequest,
    JobInfo,
    IUseFormOptions,
    useForm as useGenericForm,
} from "@in-core";

export namespace GetAdditionalUserInfo {
    export interface Request {}

    export interface Response {
        UserLocale?: string;
        CountryLocale?: string;
        CountryId?: number;
        CurrencyId?: number;
        IsEmailConfirmed: boolean;
    }

    export const Localization = translate({});

    export const useLocalization = () => {
        return useGenericLocalization(Localization);
    };

    export const URL = "API/V1/GetAdditionalUserInfo";

    export const ValidateURL = "ValidateAPI/V1/GetAdditionalUserInfo";

    export const BackgroundURL = "BackgroundAPI/V1/GetAdditionalUserInfo";

    export const CheckBackgroundURL = "CheckBackgroundAPI/V1/GetAdditionalUserInfo";

    export const callApi = (request: GetAdditionalUserInfo.Request) => {
        return callGenericApi<GetAdditionalUserInfo.Request, GetAdditionalUserInfo.Response>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<GetAdditionalUserInfo.Request, GetAdditionalUserInfo.Response>(URL);
    };

    export const callValidateApi = (request: GetAdditionalUserInfo.Request) => {
        return callGenericApi<GetAdditionalUserInfo.Request, ValidationResult>(ValidateURL, request);
    };

    export const useValidateApi = () => {
        return useGenericApi<GetAdditionalUserInfo.Request, ValidationResult>(ValidateURL);
    };

    export const callBackgroundApi = (
        request: GetAdditionalUserInfo.Request,
        backgroundApiRequest?: BackgroundApiRequest,
    ) => {
        return callGenericApi<GetAdditionalUserInfo.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
            request,
        );
    };

    export const useBackgroundApi = (backgroundApiRequest?: BackgroundApiRequest) => {
        return useGenericApi<GetAdditionalUserInfo.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
        );
    };

    export const callCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return callGenericApi<any, JobInfo>(
            getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest),
            undefined,
        );
    };

    export const useCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return useGenericApi<any, JobInfo>(getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest));
    };
}

export namespace GetCurrencies {
    export interface Request {}

    export interface CurrencyInfo {
        Id: number;
        Name: string;
        Code: string;
        Symbol: string;
        IsPrefix: boolean;
    }

    export const Localization = translate({});

    export const useLocalization = () => {
        return useGenericLocalization(Localization);
    };

    export const URL = "API/V1/GetCurrencies";

    export const ValidateURL = "ValidateAPI/V1/GetCurrencies";

    export const BackgroundURL = "BackgroundAPI/V1/GetCurrencies";

    export const CheckBackgroundURL = "CheckBackgroundAPI/V1/GetCurrencies";

    export const callApi = (request: GetCurrencies.Request) => {
        return callGenericApi<GetCurrencies.Request, GetCurrencies.CurrencyInfo[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<GetCurrencies.Request, GetCurrencies.CurrencyInfo[]>(URL);
    };

    export const callValidateApi = (request: GetCurrencies.Request) => {
        return callGenericApi<GetCurrencies.Request, ValidationResult>(ValidateURL, request);
    };

    export const useValidateApi = () => {
        return useGenericApi<GetCurrencies.Request, ValidationResult>(ValidateURL);
    };

    export const callBackgroundApi = (request: GetCurrencies.Request, backgroundApiRequest?: BackgroundApiRequest) => {
        return callGenericApi<GetCurrencies.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
            request,
        );
    };

    export const useBackgroundApi = (backgroundApiRequest?: BackgroundApiRequest) => {
        return useGenericApi<GetCurrencies.Request, string>(getUrlWithQueryString(BackgroundURL, backgroundApiRequest));
    };

    export const callCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return callGenericApi<any, JobInfo>(
            getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest),
            undefined,
        );
    };

    export const useCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return useGenericApi<any, JobInfo>(getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest));
    };
}

export namespace GetEnvironment {
    export interface Request {}

    export interface Response {
        IsDebug: boolean;
    }

    export const URL = "API/V1/GetEnvironment";

    export const ValidateURL = "ValidateAPI/V1/GetEnvironment";

    export const BackgroundURL = "BackgroundAPI/V1/GetEnvironment";

    export const CheckBackgroundURL = "CheckBackgroundAPI/V1/GetEnvironment";

    export const callApi = (request: GetEnvironment.Request) => {
        return callGenericApi<GetEnvironment.Request, GetEnvironment.Response>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<GetEnvironment.Request, GetEnvironment.Response>(URL);
    };

    export const callValidateApi = (request: GetEnvironment.Request) => {
        return callGenericApi<GetEnvironment.Request, ValidationResult>(ValidateURL, request);
    };

    export const useValidateApi = () => {
        return useGenericApi<GetEnvironment.Request, ValidationResult>(ValidateURL);
    };

    export const callBackgroundApi = (request: GetEnvironment.Request, backgroundApiRequest?: BackgroundApiRequest) => {
        return callGenericApi<GetEnvironment.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
            request,
        );
    };

    export const useBackgroundApi = (backgroundApiRequest?: BackgroundApiRequest) => {
        return useGenericApi<GetEnvironment.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
        );
    };

    export const callCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return callGenericApi<any, JobInfo>(
            getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest),
            undefined,
        );
    };

    export const useCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return useGenericApi<any, JobInfo>(getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest));
    };
}

export namespace ResendEmailConfirmation {
    export interface Request {}

    export const Localization = translate({});

    export const useLocalization = () => {
        return useGenericLocalization(Localization);
    };

    export const URL = "API/V1/ResendEmailConfirmation";

    export const ValidateURL = "ValidateAPI/V1/ResendEmailConfirmation";

    export const BackgroundURL = "BackgroundAPI/V1/ResendEmailConfirmation";

    export const CheckBackgroundURL = "CheckBackgroundAPI/V1/ResendEmailConfirmation";

    export const callApi = (request: ResendEmailConfirmation.Request) => {
        return callGenericApi<ResendEmailConfirmation.Request>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<ResendEmailConfirmation.Request>(URL);
    };

    export const callValidateApi = (request: ResendEmailConfirmation.Request) => {
        return callGenericApi<ResendEmailConfirmation.Request, ValidationResult>(ValidateURL, request);
    };

    export const useValidateApi = () => {
        return useGenericApi<ResendEmailConfirmation.Request, ValidationResult>(ValidateURL);
    };

    export const callBackgroundApi = (
        request: ResendEmailConfirmation.Request,
        backgroundApiRequest?: BackgroundApiRequest,
    ) => {
        return callGenericApi<ResendEmailConfirmation.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
            request,
        );
    };

    export const useBackgroundApi = (backgroundApiRequest?: BackgroundApiRequest) => {
        return useGenericApi<ResendEmailConfirmation.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
        );
    };

    export const callCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return callGenericApi<any, JobInfo>(
            getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest),
            undefined,
        );
    };

    export const useCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return useGenericApi<any, JobInfo>(getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest));
    };
}

export namespace SetLastUse {
    export interface Request {}

    export const Localization = translate({});

    export const useLocalization = () => {
        return useGenericLocalization(Localization);
    };

    export const URL = "API/V1/SetLastUse";

    export const ValidateURL = "ValidateAPI/V1/SetLastUse";

    export const BackgroundURL = "BackgroundAPI/V1/SetLastUse";

    export const CheckBackgroundURL = "CheckBackgroundAPI/V1/SetLastUse";

    export const callApi = (request: SetLastUse.Request) => {
        return callGenericApi<SetLastUse.Request>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<SetLastUse.Request>(URL);
    };

    export const callValidateApi = (request: SetLastUse.Request) => {
        return callGenericApi<SetLastUse.Request, ValidationResult>(ValidateURL, request);
    };

    export const useValidateApi = () => {
        return useGenericApi<SetLastUse.Request, ValidationResult>(ValidateURL);
    };

    export const callBackgroundApi = (request: SetLastUse.Request, backgroundApiRequest?: BackgroundApiRequest) => {
        return callGenericApi<SetLastUse.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
            request,
        );
    };

    export const useBackgroundApi = (backgroundApiRequest?: BackgroundApiRequest) => {
        return useGenericApi<SetLastUse.Request, string>(getUrlWithQueryString(BackgroundURL, backgroundApiRequest));
    };

    export const callCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return callGenericApi<any, JobInfo>(
            getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest),
            undefined,
        );
    };

    export const useCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return useGenericApi<any, JobInfo>(getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest));
    };
}

export namespace UpdateCurrencyConversions {
    export interface Request {}

    export const Localization = translate({
        Confirm: "Do you want to update the value conversions for all the currencies in the system?",
        Success: "Currency conversions successfully updated.",
    })
        .partiallySupporting("hr", {
            Confirm: "Želite li ažurirati sve konverzije valuta za sve valute u sustavu?",
        })
        .partiallySupporting("sl", {
            Confirm: "Ali želite posodobiti pretvorbe vrednosti za vse valute v sistemu?",
            Success: "Pretvorba vrednosti v drugo valuto je bila uspešna.",
        });

    export const useLocalization = () => {
        return useGenericLocalization(Localization);
    };

    export const URL = "API/V1/UpdateCurrencyConversions";

    export const ValidateURL = "ValidateAPI/V1/UpdateCurrencyConversions";

    export const BackgroundURL = "BackgroundAPI/V1/UpdateCurrencyConversions";

    export const CheckBackgroundURL = "CheckBackgroundAPI/V1/UpdateCurrencyConversions";

    export const callApi = (request: UpdateCurrencyConversions.Request) => {
        return callGenericApi<UpdateCurrencyConversions.Request>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<UpdateCurrencyConversions.Request>(URL);
    };

    export const callValidateApi = (request: UpdateCurrencyConversions.Request) => {
        return callGenericApi<UpdateCurrencyConversions.Request, ValidationResult>(ValidateURL, request);
    };

    export const useValidateApi = () => {
        return useGenericApi<UpdateCurrencyConversions.Request, ValidationResult>(ValidateURL);
    };

    export const callBackgroundApi = (
        request: UpdateCurrencyConversions.Request,
        backgroundApiRequest?: BackgroundApiRequest,
    ) => {
        return callGenericApi<UpdateCurrencyConversions.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
            request,
        );
    };

    export const useBackgroundApi = (backgroundApiRequest?: BackgroundApiRequest) => {
        return useGenericApi<UpdateCurrencyConversions.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
        );
    };

    export const callCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return callGenericApi<any, JobInfo>(
            getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest),
            undefined,
        );
    };

    export const useCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return useGenericApi<any, JobInfo>(getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest));
    };

    export const useForm = (options?: IUseFormOptions<UpdateCurrencyConversions.Request>) => {
        return useGenericForm<UpdateCurrencyConversions.Request>({
            validate: async (values) => {
                const response = await callValidateApi(values as UpdateCurrencyConversions.Request);
                return response.data!;
            },
            ...options,
        });
    };
}
