import { Filter } from "@in-core/api";
import { DataType } from "@in-core/api/Entity";
import BooleanFilter from "./BooleanFilter";
import DateFilter from "./DateFilter";
import NumberFilter from "./NumberFilter";
import StringFilter from "./StringFilter";
import { IDataTableAllowedValue } from "..";
import EnumFilter from "./EnumFilter";

export interface IFilterProps {
    id: string;
    filter: Filter | undefined;
    onFilterChanged: (filterValue?: Filter) => void;
    isChangeInstant: boolean;
    allowedValues?: IDataTableAllowedValue[];
}

export interface IDefaultFilterProps extends IFilterProps {
    dataType: DataType;
}

const DefaultFilter = (props: IDefaultFilterProps) => {
    if (props.dataType === DataType.String) {
        return <StringFilter {...props} />;
    }

    if (props.dataType === DataType.Number) {
        return <NumberFilter {...props} />;
    }

    if (props.dataType === DataType.Boolean) {
        return <BooleanFilter {...props} />;
    }

    if (props.dataType === DataType.DateTime) {
        return <DateFilter {...props} />;
    }

    if (props.dataType === DataType.Enum) {
        return <EnumFilter {...props} />;
    }

    return null;
};

export default DefaultFilter;
