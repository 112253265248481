import {
    ActionButton,
    DataTable,
    Link,
    Panel,
    PanelType,
    PrimaryButton,
    SelectionMode,
    Setting,
    Spinner,
    Stack,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import { DataType } from "@in-core/api/Entity";
import { useState } from "react";
import SettingValueEditor from "../../SettingValueEditor";

export interface ISettingDetailsRolesProps {
    setting: Setting;
    form: any;
    allRoles?: any[];
}

const SettingDetailsRoles = (props: ISettingDetailsRolesProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();
    const [currentRoleId, setCurrentRoleId] = useState<any>();

    if (!props.allRoles) {
        return <Spinner />;
    }

    const roleValues = props.form.RoleValues.$value;

    const data = props.allRoles.map((x: any) => {
        const roleValue = roleValues?.find((y: any) => {
            return y.RoleId === x.Id;
        })?.Value;

        return {
            Id: x.Id,
            Name: x.Name,
            Description: x.Description,
            HasValue: roleValue !== undefined,
            Value: roleValue ?? "",
        };
    });

    const currentRole = props.allRoles.find((x: any) => {
        return x.Id === currentRoleId;
    });

    return (
        <>
            <DataTable
                data={data}
                columns={[
                    {
                        id: "Edit",
                        name: "",
                        onRender: (item) => {
                            return (
                                <ActionButton
                                    iconProps={{ iconName: "Edit" }}
                                    styles={{ root: { height: "auto", padding: 0 } }}
                                    onClick={() => {
                                        setCurrentRoleId(item.Id);
                                    }}
                                >
                                    {localization.Settings.Edit}
                                </ActionButton>
                            );
                        },
                        width: 100,
                        isSortable: false,
                        isSearchable: false,
                        isFilterable: false,
                        isResizable: false,
                    },
                    {
                        id: "Id",
                        property: "Id",
                        name: localization.Settings.RoleIdColumn,
                        dataType: DataType.Number,
                        width: 150,
                        onRender: (item) => {
                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        setCurrentRoleId(item.Id);
                                    }}
                                >
                                    {item.Id}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "Name",
                        property: "Name",
                        name: localization.Settings.RoleNameColumn,
                        dataType: DataType.String,
                        width: 150,
                        onRender: (item) => {
                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        setCurrentRoleId(item.Id);
                                    }}
                                >
                                    {item.Name}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "Description",
                        property: "Description",
                        name: localization.Settings.RoleDescriptionColumn,
                        dataType: DataType.String,
                        width: 200,
                        onRender: (item) => {
                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        setCurrentRoleId(item.Id);
                                    }}
                                >
                                    {item.Description}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "HasValue",
                        property: "HasValue",
                        name: localization.Settings.RoleHasValueColumn,
                        dataType: DataType.Boolean,
                        width: 120,
                        isResizable: false,
                        isSortable: false,
                    },
                    {
                        id: "Value",
                        property: "Value",
                        name: localization.Settings.RoleValueColumn,
                        dataType: DataType.String,
                        width: 300,
                        isSortable: false,
                    },
                ]}
                hideToolbar
                disableAggregating
                defaultSort={{ Id: "Description", IsDescending: false }}
                selectionMode={SelectionMode.none}
            />

            <Panel
                isOpen={currentRoleId !== undefined}
                onDismiss={() => {
                    setCurrentRoleId(undefined);
                }}
                headerText={`${props.setting.Name} - ${localization.Settings.Role} ${currentRole?.Description}`}
                type={PanelType.medium}
            >
                {currentRole && (
                    <Stack tokens={{ childrenGap: theme.spacing.l2 }}>
                        <SettingValueEditor
                            value={
                                props.form.RoleValues.$value?.find((x: any) => {
                                    return x.RoleId === currentRoleId;
                                })?.Value
                            }
                            onChange={(newValue) => {
                                const newRoleValues =
                                    props.form.RoleValues.$value
                                        ?.filter((x: any) => {
                                            return x.RoleId !== currentRoleId;
                                        })
                                        .map((x: any) => {
                                            return { ...x };
                                        }) ?? [];

                                if (newValue !== undefined) {
                                    newRoleValues.push({ RoleId: currentRoleId, Value: newValue });
                                }

                                props.form.RoleValues.$setValue(newRoleValues);
                            }}
                            relevantSettingValues={[
                                {
                                    Id: "__system",
                                    Name: `${localization.Settings.System}:`,
                                    Value: props.form.SystemValue.$value,
                                },
                            ]}
                        />

                        <Stack horizontal>
                            <PrimaryButton
                                onClick={() => {
                                    setCurrentRoleId(undefined);
                                }}
                            >
                                {localization.Ok}
                            </PrimaryButton>
                        </Stack>
                    </Stack>
                )}
            </Panel>
        </>
    );
};

export default SettingDetailsRoles;
