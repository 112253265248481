import { Panel as FluentPanel, IPanelProps as IFluentPanelProps } from "@fluentui/react";
import useOnChange from "@in-core/hooks/useOnChange";
import { createContext, useContext, useState } from "react";

export interface IPanelProps extends IFluentPanelProps {
    shouldKeepChildrenActive?: boolean;
}

const Panel = (props: IPanelProps) => {
    const [overridenProps, setOverridenProps] = useState<Omit<Partial<IPanelProps>, "children" | "isOpen">>({});

    useOnChange(() => {
        if (!props.isOpen) {
            setOverridenProps({});
        }
    }, [props.isOpen]);

    return (
        <panelContext.Provider
            value={{
                setProps: (newProps) => {
                    if (typeof newProps === "function") {
                        setOverridenProps((prevProps) => {
                            return { ...prevProps, ...newProps(props) };
                        });
                    } else {
                        setOverridenProps((prevProps) => {
                            return { ...prevProps, ...newProps };
                        });
                    }
                },
                clearProps: () => {
                    setOverridenProps({});
                },
                defaultProps: props,
                overridenProps: { ...props, ...overridenProps },
            }}
        >
            <FluentPanel
                {...props}
                {...overridenProps}
                children={
                    props.shouldKeepChildrenActive === true ? props.children : props.isOpen ? props.children : null
                }
            />
        </panelContext.Provider>
    );
};

export interface IPanelContext {
    setProps: (
        props:
            | Omit<Partial<IPanelProps>, "children" | "isOpen">
            | ((defaultprops: IPanelProps) => Omit<Partial<IPanelProps>, "children" | "isOpen">),
    ) => void;
    clearProps: () => void;
    defaultProps: IPanelProps;
    overridenProps: IPanelProps;
}

const panelContext = createContext<IPanelContext | undefined>(undefined);

export const usePanelContext = () => {
    return useContext(panelContext);
};

export default Panel;
