import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import {
    CommandBar,
    IButtonStyles,
    Icon,
    IconButton,
    Modal,
    Spinner,
    Stack,
    Text,
    useInCoreLocalization,
    useTheme,
} from "@in-core";

export interface IModalPdfViewerProps {
    isOpen?: boolean;
    onClose?: () => void;
    fileName?: string;
    content?: string;
    isContentUrl?: boolean;
}

const ModalPdfViewer = (props: IModalPdfViewerProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();

    const commandBarButtonStyles: IButtonStyles = {
        root: {
            borderRadius: theme.effects.roundedCorner2,
            height: 32,
            backgroundColor: theme.palette.neutralLighterAlt,
            marginLeft: theme.spacing.s1,
        },
        rootHovered: {
            backgroundColor: theme.palette.themeLighterAlt,
        },
        rootPressed: {
            backgroundColor: theme.palette.themeLighterAlt,
        },
    };

    const url = props.content
        ? props.isContentUrl
            ? props.content
            : URL.createObjectURL(base64ToBlob(props.content ?? "", "application/pdf"))
        : undefined;

    const fileName = props.fileName ?? localization.ModalPdfViewer.DefaultName;

    return (
        <Modal
            isOpen={props.isOpen}
            styles={{
                main: { height: "100%", width: "100%" },
                scrollableContent: { height: "100%" },
            }}
        >
            <Stack styles={{ root: { height: "100%" } }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    styles={{
                        root: {
                            borderBottomWidth: 1,
                            borderBottomColor: theme.palette.neutralLighter,
                            borderBottomStyle: "solid",
                        },
                    }}
                >
                    <CommandBar
                        items={[
                            {
                                key: "Download",
                                text: localization.Download,
                                iconProps: { iconName: "Download" },
                                buttonStyles: commandBarButtonStyles,
                                disabled: !props.content,
                                onClick: () => {
                                    const a = document.createElement("a");
                                    a.href = url!;
                                    a.download = fileName + ".pdf";
                                    a.click();
                                },
                            },
                        ]}
                        style={{ flex: 1 }}
                        styles={{
                            root: {
                                padding: 0,
                            },
                            primarySet: {
                                alignItems: "center",
                            },
                            secondarySet: {
                                alignItems: "center",
                            },
                        }}
                    />

                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: theme.spacing.s2 }}>
                        <Icon
                            {...getFileTypeIconProps({
                                extension: "pdf",
                            })}
                        />
                        <Text>{fileName}</Text>
                    </Stack>

                    <Stack
                        horizontal
                        horizontalAlign="end"
                        styles={{ root: { flex: 1, paddingRight: theme.spacing.s1 } }}
                    >
                        <IconButton
                            iconProps={{ iconName: "Cancel" }}
                            onClick={() => {
                                props.onClose && props.onClose();
                            }}
                        />
                    </Stack>
                </Stack>

                <Stack
                    verticalAlign="center"
                    styles={{
                        root: {
                            flex: 1,
                            overflow: "hidden",
                        },
                    }}
                >
                    {props.content ? (
                        <iframe title="content" src={url} style={{ border: "none", height: "100%", width: "100%" }} />
                    ) : (
                        <Spinner label={localization.ModalPdfViewer.Loading} />
                    )}
                </Stack>
            </Stack>
        </Modal>
    );
};

export default ModalPdfViewer;

function base64ToBlob(base64: string, type = "application/octet-stream") {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
}
