import { callApi as callGenericApi, useApi as useGenericApi, SettingValue, Setting } from "@in-core";

export namespace GetSettings {
    export const URL = "CoreAPI/Settings/GetSettings";

    export interface Request {}

    export const callApi = (request: Request) => {
        return callGenericApi<Request, Setting[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, Setting[]>(URL);
    };
}

export namespace GetSettingValues {
    export const URL = "CoreAPI/Settings/GetSettingValues";

    export interface Request {}

    export const callApi = (request: Request) => {
        return callGenericApi<Request, SettingValue[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, SettingValue[]>(URL);
    };
}

export namespace Read {
    export const URL = "CoreAPI/Settings/Read";

    export const callApi = (request: any) => {
        return callGenericApi<any, any>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<any, any>(URL);
    };
}

export namespace Save {
    export const URL = "CoreAPI/Settings/Save";

    export const callApi = (request: any) => {
        return callGenericApi<any, any>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<any, any>(URL);
    };
}
