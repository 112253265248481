import { List } from "@in-core";
import Directory from "../Directory";
import File from "../File";
import { NewDirectoryInfo, NewFileInfo } from "@in-core/api/Documents";

export interface IContentProps {
    directories: NewDirectoryInfo[];
    files: NewFileInfo[];
    onRemoveDirectory: (path: string[], directory: NewDirectoryInfo) => void;
    onRemoveFile: (path: string[], file: NewFileInfo) => void;
    forceUpdate: () => void;
    path: string[];
    additionalExistingNames?: string[];
    disableRename: boolean;
}

const Content = (props: IContentProps) => {
    return (
        <>
            <List
                items={props.directories}
                onRenderCell={(item) => {
                    return (
                        <Directory
                            directory={item!}
                            onRemove={() => {
                                return props.onRemoveDirectory(props.path, item!);
                            }}
                            onRemoveDirectory={(path, directory) => {
                                props.onRemoveDirectory(path, directory);
                            }}
                            onRemoveFile={(path, file) => {
                                props.onRemoveFile(path, file);
                            }}
                            forceUpdate={props.forceUpdate}
                            path={props.path}
                            existingNames={[
                                ...props.directories
                                    .filter((x) => {
                                        return x !== item;
                                    })
                                    .map((x) => {
                                        return x.Name;
                                    }),
                                ...props.files.map((x) => {
                                    return x.Name;
                                }),
                                ...(props.additionalExistingNames ?? []),
                            ]}
                            disableRename={props.disableRename}
                        />
                    );
                }}
            />

            <List
                items={props.files}
                onRenderCell={(item) => {
                    return (
                        <File
                            file={item!}
                            onRemove={() => {
                                return props.onRemoveFile(props.path, item!);
                            }}
                            forceUpdate={props.forceUpdate}
                            existingNames={[
                                ...props.directories.map((x) => {
                                    return x.Name;
                                }),
                                ...props.files
                                    .filter((x) => {
                                        return x !== item;
                                    })
                                    .map((x) => {
                                        return x.Name;
                                    }),
                                ...(props.additionalExistingNames ?? []),
                            ]}
                            disableRename={props.disableRename}
                        />
                    );
                }}
            />
        </>
    );
};

export default Content;
