import { Filter, IDataTableColumn, Icon, List, Stack, Text, useTheme } from "@in-core";
import DefaultFilter from "@in-core/components/DataTable/DefaultFilter";

export interface IFilterSectionProps<TData = any> {
    columns: IDataTableColumn<TData>[];
    filters: Record<string, Filter>;
    onFilterssChanged: (selectors: Record<string, Filter>) => void;
}

const FilterSection = <TData extends object = any>(props: IFilterSectionProps<TData>) => {
    const theme = useTheme();

    const renderFilterComponent = (column: IDataTableColumn<TData>) => {
        if (column.onRenderFilter) {
            return (
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <div style={{ flex: 1, maxWidth: "100%" }}>
                        {column.onRenderFilter(props.filters[column.id], (newFilterItem) => {
                            const newFilters = {
                                ...props.filters,
                                [column.id]: newFilterItem,
                            } as Record<string, Filter>;

                            props.onFilterssChanged(newFilters);
                        })}
                    </div>
                </Stack>
            );
        }

        const columnFilter = props.filters[column.id as any];

        return (
            <DefaultFilter
                id={column.id}
                dataType={column.dataType!}
                filter={columnFilter}
                onFilterChanged={(newFilter) => {
                    const newFilters: Record<string, Filter> = {
                        ...props.filters,
                    };
                    if (newFilter === undefined) {
                        delete newFilters[column.id];
                    } else {
                        newFilters[column.id] = newFilter;
                    }
                    props.onFilterssChanged(newFilters);
                }}
                isChangeInstant
            />
        );
    };

    return (
        <Stack styles={{ root: { maxHeight: 500, overflow: "auto" } }}>
            <List
                items={props.columns.filter((x) => {
                    return x.isFilterable !== false && (x.dataType !== undefined || x.onRenderFilter !== undefined);
                })}
                onRenderCell={(column) => {
                    if (!column) {
                        return null;
                    }

                    return (
                        <Stack
                            styles={{
                                root: {
                                    padding: theme.spacing.s1,
                                    borderBottomColor: theme.palette.neutralLight,
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: 1,
                                },
                            }}
                            tokens={{ childrenGap: theme.spacing.s2 }}
                        >
                            <Text styles={{ root: { fontWeight: 600, display: "flex", alignItems: "center" } }}>
                                {column.icon && (
                                    <Icon iconName={column.icon} styles={{ root: { marginRight: theme.spacing.s2 } }} />
                                )}

                                {column!.name}
                            </Text>

                            {renderFilterComponent(column)}
                        </Stack>
                    );
                }}
            />
        </Stack>
    );
};

export default FilterSection;
