import { DatePicker, DefaultButton, Dropdown, PrimaryButton, Stack, useInCoreLocalization, useTheme } from "@in-core";
import { useState } from "react";
import { ExpressionLocalizationKeys } from "../..";

export interface IFilterDateSelectorProps {
    initialValue: Date | undefined;
    onValueChanged: (date: Date | undefined) => void;
    initialExpression: string | undefined;
    onExpressionChanged: (expression: string | undefined) => void;
    onClear: () => void;
}

const FilterDateSelector = (props: IFilterDateSelectorProps) => {
    const [selectedValue, setSelectedValue] = useState(props.initialExpression ?? "specific");
    const [date, setDate] = useState(props.initialValue ?? new Date());
    const localization = useInCoreLocalization();
    const theme = useTheme();

    const onSelect = () => {
        if (selectedValue === "specific") {
            props.onValueChanged(date);
            return;
        }

        props.onExpressionChanged(selectedValue);
    };

    return (
        <Stack styles={{ root: { padding: theme.spacing.m, width: 300 } }} tokens={{ childrenGap: theme.spacing.s1 }}>
            <Dropdown
                options={[
                    { key: "specific", text: localization.DataTable.SpecificDate },
                    ...Object.entries(ExpressionLocalizationKeys).map(([key, value]) => {
                        return { key: key, text: (localization.DataTable as any)[value] };
                    }),
                ]}
                hideClear
                selectedKey={selectedValue}
                onChange={(items) => {
                    setSelectedValue(
                        items.find((x) => {
                            return x.isSelected;
                        })!.key as string,
                    );
                }}
            />

            {selectedValue === "specific" && (
                <DatePicker
                    value={date}
                    onSelectDate={(newDate) => {
                        setDate(newDate ?? new Date());
                    }}
                    hideClear
                />
            )}

            <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
                <PrimaryButton onClick={onSelect}>{localization.Accept}</PrimaryButton>

                <DefaultButton onClick={props.onClear}>{localization.Clear}</DefaultButton>
            </Stack>
        </Stack>
    );
};

export default FilterDateSelector;
