import { Dropdown, IDataTableColumn, Icon, Sort, Stack, useInCoreLocalization, useTheme } from "@in-core";

export interface ISortItemProps<TData = any> {
    columns: IDataTableColumn<TData>[];
    sort: Sort | undefined;
    onSortChanged: (newSort: Sort | undefined) => void;
    level?: number;
}

const SortItem = (props: ISortItemProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();

    const level = props.level ?? 0;

    return (
        <>
            <Stack horizontal styles={{ root: { marginLeft: level * 12 } }} tokens={{ childrenGap: theme.spacing.s1 }}>
                <Dropdown
                    placeholder={localization.DataTable.ColumnDropdownPlaceholder}
                    options={props.columns
                        .filter((x) => {
                            return x.isSortable === true || x.isSortable === undefined;
                        })
                        .map((x) => {
                            return { key: x.id, text: x.name, data: x };
                        })}
                    selectedKey={props.sort?.Id ?? null}
                    onChange={(items) => {
                        const selectedColumnKey = items.find((x) => {
                            return x.isSelected;
                        })?.key;

                        if (selectedColumnKey === undefined) {
                            props.onSortChanged(undefined);
                            return;
                        }

                        props.onSortChanged({
                            Id: selectedColumnKey as string,
                            IsDescending: props.sort?.IsDescending ?? false,
                        });
                    }}
                    styles={{ root: { flex: 1 } }}
                    onRenderOption={(renderProps, defaultRender) => {
                        if (!renderProps?.data.icon) {
                            return defaultRender!(renderProps);
                        }

                        return (
                            <Stack horizontal>
                                <Icon
                                    iconName={renderProps.data.icon}
                                    styles={{ root: { marginRight: theme.spacing.s2 } }}
                                />

                                {defaultRender!(renderProps)}
                            </Stack>
                        );
                    }}
                />

                <Dropdown
                    options={[
                        { key: "Ascending", text: localization.DataTable.Ascending },
                        { key: "Descending", text: localization.DataTable.Descending },
                    ]}
                    placeholder={localization.DataTable.DirectionDropdownPlaceholder}
                    hideClear
                    selectedKey={props.sort?.IsDescending ? "Descending" : "Ascending"}
                    disabled={!props.sort}
                    onChange={(items) => {
                        if (!props.sort) {
                            return;
                        }

                        const selectedColumnKey = items.find((x) => {
                            return x.isSelected;
                        })?.key;

                        props.onSortChanged({
                            Id: props.sort.Id,
                            IsDescending: selectedColumnKey === "Descending",
                        });
                    }}
                    styles={{ root: { minWidth: 100 } }}
                />
            </Stack>

            {props.sort && props.columns.length > 1 && (
                <SortItem
                    columns={props.columns.filter((x) => {
                        return x.id !== props.sort!.Id;
                    })}
                    sort={props.sort.NestedSort}
                    onSortChanged={(newNestedSort) => {
                        props.onSortChanged({
                            Id: props.sort!.Id,
                            IsDescending: props.sort!.IsDescending,
                            NestedSort: newNestedSort,
                        });
                    }}
                    level={level + 1}
                />
            )}
        </>
    );
};

export default SortItem;
