import {
    IconButton,
    IRawStyle,
    ITextFieldProps as IFluentTextFieldProps,
    ITextFieldStyles,
    TextField as FluentTextField,
    useTheme,
} from "@fluentui/react";
import { DeepPartial } from "@in-core/forms";
import useOnChange from "@in-core/hooks/useOnChange";
import { useState } from "react";

export interface ITextFieldProps extends Omit<IFluentTextFieldProps, "value" | "onChange"> {
    value?: string;
    onChange?: (event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
    hideClear?: boolean;
    transform?: (value: string | undefined) => string | undefined;
}

const TextField = (props: ITextFieldProps) => {
    const theme = useTheme();
    const [value, setValue] = useState<string | undefined>(props.value ?? props.defaultValue);

    useOnChange(() => {
        setValue(props.value);
    }, [props.value]);

    const defaultRenderInput = (
        defaultRender:
            | ((
                  props?:
                      | (React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>)
                      | undefined,
              ) => JSX.Element | null)
            | undefined,
    ) => {
        return (
            renderProps?:
                | (React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>)
                | undefined,
        ) => {
            return (
                <>
                    {defaultRender!(renderProps)}

                    {!props.readOnly && !props.hideClear && !props.disabled && value !== undefined && (
                        <IconButton
                            iconProps={{
                                iconName: "Clear",
                                styles: {
                                    root: {
                                        fontSize: 10,
                                        color: theme.palette.neutralDark,
                                    },
                                },
                            }}
                            styles={{
                                root: {
                                    height: "100%",
                                    border: 0,
                                    backgroundColor: (
                                        (props.styles as DeepPartial<ITextFieldStyles> | undefined)?.field as IRawStyle
                                    )?.backgroundColor,
                                },
                            }}
                            onClick={() => {
                                setValue("");
                                props.onChange && props.onChange(undefined, "");
                            }}
                            tabIndex={-1}
                        />
                    )}
                </>
            );
        };
    };

    return (
        <FluentTextField
            {...props}
            value={value}
            onChange={(e, newValue) => {
                const actualNewValue = props.transform ? props.transform(newValue) : newValue;
                setValue(actualNewValue);
                props.onChange && props.onChange(e, actualNewValue);
            }}
            autoComplete="off"
            onRenderInput={(renderProps, defaultRender) => {
                return props.onRenderInput
                    ? props.onRenderInput(renderProps, defaultRenderInput(defaultRender))
                    : defaultRenderInput(defaultRender)(renderProps);
            }}
        />
    );
};

export default TextField;
