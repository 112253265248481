import axios from "axios";
import { useCallback, useRef, useState } from "react";

export interface ResponseObject<TResponse = any> {
    response: TResponse;
    responseUrl?: string;
}

export interface ApiResult<TResponse> {
    data?: TResponse;
    statusCode: number;
    isSuccess: boolean;
    error?: any;
}

export async function callApi<TRequest = any, TResponse = any>(
    url: string,
    request: TRequest,
    signal?: AbortSignal,
): Promise<ApiResult<TResponse>> {
    try {
        var response = await axios.post<TResponse>(url, request, { signal: signal });

        if ((response as any)?.response?.status >= 400) {
            throw response;
        }

        return {
            data: response.data,
            statusCode: response.status,
            error: undefined,
            isSuccess: true,
        };
    } catch (e: any) {
        return {
            data: undefined,
            statusCode: e?.response?.status ?? -1,
            error: e?.response?.data ?? "CANCELLED",
            isSuccess: false,
        };
    }
}

export enum ApiStatus {
    Idle = "Idle",
    Loading = "Loading",
    Success = "Success",
    Error = "Error",
    Cancelled = "Cancelled",
}

export interface UseApi<TRequest = any, TResponse = any> {
    data?: TResponse;
    statusCode?: number;
    error?: any;
    status: ApiStatus;
    isIdle: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    isCancelled: boolean;
    call: (request: TRequest) => Promise<ApiResult<TResponse>>;
    cancel: () => void;
    reset: () => void;
}

export function useApi<TRequest = any, TResponse = any>(url: string): UseApi<TRequest, TResponse> {
    const [result, setResult] = useState<Omit<UseApi<TRequest, TResponse>, "call" | "cancel" | "reset">>({
        status: ApiStatus.Idle,
        isIdle: true,
        isLoading: false,
        isSuccess: false,
        isError: false,
        isCancelled: false,
    });
    const controllerRef = useRef<AbortController>();

    const call = useCallback(
        async (request: TRequest) => {
            setResult({
                status: ApiStatus.Loading,
                isIdle: false,
                isLoading: true,
                isSuccess: false,
                isError: false,
                isCancelled: false,
            });

            controllerRef.current = new AbortController();
            var apiResult = await callApi(url, request, controllerRef.current.signal);

            setResult({
                data: apiResult.data,
                statusCode: apiResult.statusCode,
                error: apiResult.error,
                status: apiResult.isSuccess ? ApiStatus.Success : ApiStatus.Error,
                isIdle: false,
                isLoading: false,
                isSuccess: apiResult.isSuccess,
                isError: !apiResult.isSuccess,
                isCancelled: apiResult.statusCode === -1,
            });

            return apiResult;
        },
        [url],
    );

    const cancel = useCallback(() => {
        controllerRef.current?.abort();
    }, []);

    const reset = useCallback(() => {
        setResult({
            status: ApiStatus.Idle,
            isIdle: true,
            isLoading: false,
            isSuccess: false,
            isError: false,
            isCancelled: false,
        });
    }, []);

    return { ...result, call: call, cancel: cancel, reset: reset };
}

export interface Permission {
    Id: string;
    CompleteId: string;
    Path: string[];
    CompleteIdPath: string[];
    ParentPermissionId?: string;
    Name: string;
    Description?: string;
    Icon?: string;
    GroupName?: string;
    ChildPermissions: Permission[];
    ImpliedPermissions: string[];
    Metadata: any;
}

export interface GetDataRequest {
    AdditionalFilter?: Filter;
    Search?: Search;
    Filter?: Filter;
    Selector?: Selector;
    Group?: Group;
    Sort?: Sort;
    Pagination?: Pagination;
    AggregateData?: AggregateData;
}

export interface GetDataResponse<TData = any> {
    Data: TData[];
    Count: number;
    AggregateDataResults: AggregateDataResult[];
    GroupResults: GroupResult[];
}

export interface Filter {
    IsNegated: boolean;
    Group?: FilterGroup;
    Value?: FilterValue;
}

export interface FilterGroup {
    Operator: FilterGroupOperator;
    Filters: Filter[];
}

export enum FilterGroupOperator {
    And = "And",
    Or = "Or",
}

export interface FilterValue {
    LeftOperand: FilterValueOperand;
    Operator: FilterValueOperator;
    RightOperand?: FilterValueOperand;
}

export interface FilterValueOperand {
    Id?: string;
    Value?: any;
    Expression?: string;
}

export enum FilterValueOperator {
    Equal = "Equal",
    NotEqual = "NotEqual",
    GreaterThan = "GreaterThan",
    GreaterThanOrEqual = "GreaterThanOrEqual",
    LessThan = "LessThan",
    LessThanOrEqual = "LessThanOrEqual",
    IsEmpty = "IsEmpty",
    IsNotEmpty = "IsNotEmpty",
    Contains = "Contains",
    NotContains = "NotContains",
    StartsWith = "StartsWith",
    NotStartsWith = "NotStartsWith",
    EndsWith = "EndsWith",
    NotEndsWith = "NotEndsWith",
}

export interface Sort {
    Id: string;
    IsDescending: boolean;
    NestedSort?: Sort;
}

export interface Pagination {
    ItemsPerPage: number;
    PageIndex: number;
}

export interface Search {
    SearchTerm: string;
    SearchProperties: string[];
}

export interface Selector {
    Id: string;
    Value?: SelectorValue;
    Group?: SelectorGroup;
}

export interface SelectorValue {
    ValueId?: string;
}

export interface SelectorGroup {
    ValueId?: string;
    Items: Selector[];
}

export interface Group {
    Id: string;
    NestedGroup?: Group;
}

export interface AggregateData {
    Items: AggregateDataItem[];
}

export interface AggregateDataItem {
    Id: string;
    AggregateDataTypes: AggregateDataType[];
}

export enum AggregateDataType {
    CountEmpty = "CountEmpty",
    CountNonEmpty = "CountNonEmpty",
    PercentEmpty = "PercentEmpty",
    PercentNonEmpty = "PercentNonEmpty",
    Sum = "Sum",
    Average = "Average",
    Minimum = "Minimum",
    Maximum = "Maximum",
    CountTrue = "CountTrue",
    CountFalse = "CountFalse",
    PercentTrue = "PercentTrue",
    PercentFalse = "PercentFalse",
}

export interface AggregateDataResult {
    Id: string;
    AggregateDataType: AggregateDataType;
    Value?: any;
    StringValue: string;
}

export interface GroupResult {
    StartIndex: number;
    Count: number;
    NestedGroupResults?: GroupResult[];
    AggregateDataResults?: AggregateDataResult[];
}

export interface ValidationResult {
    ErrorMessages: string[];
    NestedValidations: { [x: string]: ValidationResult };
}

export interface Setting {
    Id: string;
    Name: string;
    Description?: string;
    GroupName?: string;
    IsAllowedForSystem: boolean;
    IsAllowedForRole: boolean;
    IsAllowedForUser: boolean;
    IsPrivate: boolean;
    Metadata?: any;
}

export interface SettingValue<T = string> {
    Id: string;
    SystemValue?: T;
    RoleValues: T[];
    UserValue?: T;
    Values: T[];
}

export interface DirectoryContent {
    Directories: DirectoryInfo[];
    Files: FileInfo[];
}

export interface DirectoryInfo {
    Name: string;
    TimeAdded?: Date;
    UserStringId?: string;
    UserName?: string;
    UserDisplayName?: string;
    Directories: DirectoryInfo[];
    Files: FileInfo[];
}

export interface FileInfo {
    Name: string;
    TimeAdded?: Date;
    UserStringId?: string;
    UserName?: string;
    UserDisplayName?: string;
    Size: number;
}

export interface Note {
    Id: string;
    Text: string;
    TimeAdded: Date;
    UserStringId?: string;
    UserName?: string;
    UserDisplayName?: string;
}

export interface BackgroundApiRequest {
    key?: string;
    name?: string;
}

export interface CheckBackgroundApiRequest {
    id?: string;
    key?: string;
}

export interface JobInfo {
    Id: string;
    ServiceId: string;
    Key?: string;
    Name?: string;
    Time?: Date;
    State: BackgroundJobState;
}

export enum BackgroundJobState {
    Scheduled = "Scheduled",
    Enqueued = "Enqueued",
    Processing = "Processing",
    Failed = "Failed",
    Succeeded = "Succeeded",
    Deleted = "Deleted",
}

export const getUrlWithQueryString = (url: string, obj: any) => {
    if (!obj) {
        return url;
    }

    let result = url;

    Object.keys(obj).forEach((x, i) => {
        result += (i === 0 ? "?" : "&") + encodeURIComponent(x) + "=" + encodeURIComponent(obj[x]?.toString());
    });

    return result;
};
