import { GetAdditionalUserInfo, GetCurrencies, GetEnvironment, SetLastUse } from "@server/API/V1";
import {
    DefaultLayout,
    FormCheckbox,
    FormDropdown,
    FormEntityDataTableDropdown,
    FormGroup,
    IInCoreLocalization,
    InCoreApp,
    TextField,
    createTheme,
    registerIcons,
} from "@in-core";
import { createContext, useCallback, useContext } from "react";
import { GetCountries, GetInsuranceCompanies, GetInsuranceTypes } from "@server/API/V1/LocationSpecific";

registerIcons({
    icons: {
        Dashboard: (
            <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_63_2328)">
                    <path
                        d="M60.7613 0H19.2387C8.61345 0 0 8.61345 0 19.2387V60.7613C0 71.3866 8.61345 80 19.2387 80H60.7613C71.3866 80 80 71.3866 80 60.7613V19.2387C80 8.61345 71.3866 0 60.7613 0Z"
                        fill="url(#paint0_linear_63_2328)"
                    />
                    <g filter="url(#filter0_d_63_2328)">
                        <path
                            opacity="0.5"
                            d="M63.0771 60.668V51.8969C63.0771 50.5711 62.0024 49.4963 60.6766 49.4963H19.3236C17.9978 49.4963 16.923 50.5711 16.923 51.8969V60.668C16.923 61.9938 17.9978 63.0686 19.3236 63.0686H60.6766C62.0024 63.0686 63.0771 61.9938 63.0771 60.668Z"
                            fill="white"
                        />
                        <path
                            d="M26.8391 16.9229H19.3234C17.9976 16.9229 16.9229 17.9977 16.9229 19.3235V42.5321C16.9229 43.8579 17.9976 44.9327 19.3234 44.9327H26.8391C28.1649 44.9327 29.2397 43.8579 29.2397 42.5321V19.3235C29.2397 17.9977 28.1649 16.9229 26.8391 16.9229Z"
                            fill="white"
                        />
                        <path
                            d="M60.6765 30.9278H53.1608C51.835 30.9278 50.7603 32.0026 50.7603 33.3284V42.5321C50.7603 43.8579 51.835 44.9327 53.1608 44.9327H60.6765C62.0023 44.9327 63.0771 43.8579 63.0771 42.5321V33.3284C63.0771 32.0026 62.0023 30.9278 60.6765 30.9278Z"
                            fill="white"
                        />
                        <path
                            d="M43.7536 23.9211H36.238C34.9122 23.9211 33.8374 24.9959 33.8374 26.3217V42.5321C33.8374 43.8579 34.9122 44.9327 36.238 44.9327H43.7536C45.0795 44.9327 46.1542 43.8579 46.1542 42.5321V26.3217C46.1542 24.9959 45.0795 23.9211 43.7536 23.9211Z"
                            fill="white"
                        />
                    </g>
                </g>
                <defs>
                    <filter
                        id="filter0_d_63_2328"
                        x="15.5029"
                        y="15.5029"
                        width="51.8343"
                        height="51.8257"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="1.42" dy="1.42" />
                        <feGaussianBlur stdDeviation="1.42" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_63_2328" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_63_2328" result="shape" />
                    </filter>
                    <linearGradient
                        id="paint0_linear_63_2328"
                        x1="5.63249"
                        y1="5.63249"
                        x2="74.359"
                        y2="74.359"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#66C4D6" />
                        <stop offset="1" stop-color="#308BAB" />
                    </linearGradient>
                    <clipPath id="clip0_63_2328">
                        <rect width="80" height="80" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
        Policies: (
            <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_63_2359)">
                    <path
                        d="M60.7613 0H19.2387C8.61345 0 0 8.61345 0 19.2387V60.7613C0 71.3866 8.61345 80 19.2387 80H60.7613C71.3866 80 80 71.3866 80 60.7613V19.2387C80 8.61345 71.3866 0 60.7613 0Z"
                        fill="url(#paint0_linear_63_2359)"
                    />
                    <g filter="url(#filter0_d_63_2359)">
                        <path
                            opacity="0.5"
                            d="M63.4332 23.8363C52.4906 21.2491 44.7205 16.2358 41.5649 13.9285C40.6318 13.2414 39.3679 13.2414 38.4348 13.9285C35.2793 16.2358 27.5092 21.2576 16.5665 23.8363C15.3026 24.1332 14.4459 25.3038 14.5307 26.6016C14.9887 33.5998 18.0934 54.9083 38.5281 68.0649C39.4273 68.6417 40.5725 68.6417 41.4716 68.0649C61.9064 54.9083 65.011 33.5998 65.4691 26.6016C65.5539 25.3038 64.6972 24.1332 63.4332 23.8363ZM58.4794 31.2247C57.9365 37.0014 55.2135 51.0062 41.7007 60.0657C40.6658 60.7613 39.317 60.7613 38.2906 60.0657C24.7862 50.9978 22.0548 36.9929 21.5119 31.2247C21.3762 29.7487 22.3432 28.4169 23.7768 28.0522C30.6902 26.2708 35.7628 23.2086 38.2567 21.4696C39.3001 20.7401 40.6827 20.7401 41.7261 21.4696C44.22 23.2086 49.2926 26.2708 56.206 28.0522C57.6396 28.4169 58.6151 29.7572 58.4709 31.2247H58.4794Z"
                            fill="white"
                        />
                        <path
                            d="M52.041 31.1653C46.9005 29.842 43.1427 27.5687 41.285 26.2793C40.513 25.7364 39.4866 25.7364 38.7062 26.2793C36.857 27.5687 33.0907 29.8505 27.9502 31.1653C26.8814 31.4368 26.1604 32.4292 26.2706 33.5235C26.6778 37.8157 28.6967 48.207 38.7317 54.9422C39.4951 55.4597 40.4961 55.4597 41.268 54.9422C51.2945 48.207 53.3219 37.8072 53.729 33.5235C53.8308 32.4292 53.1098 31.4368 52.0495 31.1653H52.041Z"
                            fill="white"
                        />
                    </g>
                </g>
                <defs>
                    <filter
                        id="filter0_d_63_2359"
                        x="13.1049"
                        y="11.9932"
                        width="56.63"
                        height="60.7643"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="1.42" dy="1.42" />
                        <feGaussianBlur stdDeviation="1.42" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_63_2359" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_63_2359" result="shape" />
                    </filter>
                    <linearGradient
                        id="paint0_linear_63_2359"
                        x1="5.63249"
                        y1="5.63249"
                        x2="74.359"
                        y2="74.359"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#8BE0A1" />
                        <stop offset="1" stop-color="#34B956" />
                    </linearGradient>
                    <clipPath id="clip0_63_2359">
                        <rect width="80" height="80" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
        Installments: (
            <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_63_2351)">
                    <path
                        d="M60.7613 0H19.2387C8.61345 0 0 8.61345 0 19.2387V60.7613C0 71.3866 8.61345 80 19.2387 80H60.7613C71.3866 80 80 71.3866 80 60.7613V19.2387C80 8.61345 71.3866 0 60.7613 0Z"
                        fill="url(#paint0_linear_63_2351)"
                    />
                    <g filter="url(#filter0_d_63_2351)">
                        <path
                            opacity="0.4"
                            d="M47.7658 50.7942C45.5858 52.3974 42.9053 53.3475 40.0042 53.3475C37.1031 53.3475 34.4141 52.3889 32.2426 50.7942L24.2095 61.8471C28.6374 65.0875 34.1003 67.0046 40.0042 67.0046C45.9081 67.0046 51.371 65.0875 55.7989 61.8471L47.7658 50.7942Z"
                            fill="white"
                        />
                        <path
                            opacity="0.6"
                            d="M26.8561 40.2078C26.8561 38.7573 27.1021 37.3661 27.5347 36.0598L14.5392 31.8354C13.674 34.4736 13.199 37.2813 13.199 40.2078C13.199 49.0977 17.5336 56.9696 24.201 61.8471L32.2341 50.7942C28.9767 48.4021 26.8561 44.5594 26.8561 40.2078Z"
                            fill="white"
                        />
                        <path
                            opacity="0.8"
                            d="M14.5476 31.8354L27.543 36.0598C29.282 30.8345 34.2019 27.0682 40.0041 27.0682V13.4111C28.1284 13.4111 18.0679 21.1388 14.5476 31.8354Z"
                            fill="white"
                        />
                        <path
                            d="M52.4566 36.0598L65.4521 31.8354C61.9318 21.1388 51.8713 13.4111 39.9956 13.4111V27.0682C45.7977 27.0682 50.7177 30.8345 52.4566 36.0598Z"
                            fill="white"
                        />
                        <path
                            opacity="0.2"
                            d="M65.4522 31.8354L52.4567 36.0598C52.8893 37.3661 53.1353 38.7573 53.1353 40.2078C53.1353 44.5594 51.0147 48.4021 47.7573 50.7942L55.7904 61.8471C62.4578 56.9696 66.7924 49.0977 66.7924 40.2078C66.7924 37.2813 66.3174 34.4736 65.4522 31.8354Z"
                            fill="white"
                        />
                    </g>
                </g>
                <defs>
                    <filter
                        id="filter0_d_63_2351"
                        x="11.779"
                        y="11.9911"
                        width="59.2735"
                        height="59.2735"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="1.42" dy="1.42" />
                        <feGaussianBlur stdDeviation="1.42" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_63_2351" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_63_2351" result="shape" />
                    </filter>
                    <linearGradient
                        id="paint0_linear_63_2351"
                        x1="5.63249"
                        y1="5.63249"
                        x2="74.359"
                        y2="74.359"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#308CAB" />
                        <stop offset="1" stop-color="#195275" />
                    </linearGradient>
                    <clipPath id="clip0_63_2351">
                        <rect width="80" height="80" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
        Clients: (
            <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_63_2335)">
                    <path
                        d="M60.7613 0H19.2387C8.61345 0 0 8.61345 0 19.2387V60.7613C0 71.3866 8.61345 80 19.2387 80H60.7613C71.3866 80 80 71.3866 80 60.7613V19.2387C80 8.61345 71.3866 0 60.7613 0Z"
                        fill="url(#paint0_linear_63_2335)"
                    />
                    <g filter="url(#filter0_d_63_2335)">
                        <path
                            d="M39.9957 37.926C45.7955 37.926 50.4972 33.2243 50.4972 27.4244C50.4972 21.6246 45.7955 16.9229 39.9957 16.9229C34.1958 16.9229 29.4941 21.6246 29.4941 27.4244C29.4941 33.2243 34.1958 37.926 39.9957 37.926Z"
                            fill="white"
                        />
                        <path
                            opacity="0.5"
                            d="M59.3447 63.0771H20.6468C19.3999 63.0771 18.3989 62.0676 18.3989 60.8292C18.3989 50.9554 26.3981 42.9562 36.2719 42.9562H43.7282C53.602 42.9562 61.6011 50.9554 61.6011 60.8292C61.6011 62.0761 60.5917 63.0771 59.3532 63.0771H59.3447Z"
                            fill="white"
                        />
                    </g>
                </g>
                <defs>
                    <filter
                        id="filter0_d_63_2335"
                        x="16.9789"
                        y="15.5029"
                        width="48.8821"
                        height="51.8342"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="1.42" dy="1.42" />
                        <feGaussianBlur stdDeviation="1.42" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_63_2335" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_63_2335" result="shape" />
                    </filter>
                    <linearGradient
                        id="paint0_linear_63_2335"
                        x1="5.63249"
                        y1="5.63249"
                        x2="74.359"
                        y2="74.359"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#F28282" />
                        <stop offset="1" stop-color="#F03232" />
                    </linearGradient>
                    <clipPath id="clip0_63_2335">
                        <rect width="80" height="80" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
        Products: (
            <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_63_2340)">
                    <path
                        d="M60.7613 0H19.2387C8.61345 0 0 8.61345 0 19.2387V60.7613C0 71.3866 8.61345 80 19.2387 80H60.7613C71.3866 80 80 71.3866 80 60.7613V19.2387C80 8.61345 71.3866 0 60.7613 0Z"
                        fill="url(#paint0_linear_63_2340)"
                    />
                    <g filter="url(#filter0_d_63_2340)">
                        <mask
                            id="mask0_63_2340"
                            style={{ maskType: "luminance" }}
                            maskUnits="userSpaceOnUse"
                            x="15"
                            y="13"
                            width="50"
                            height="55"
                        >
                            <path
                                d="M38.8505 13.6571L16.8126 23.6921C15.8286 24.1417 15.1924 25.1256 15.1924 26.2029V53.6953C15.1924 54.7556 15.8031 55.7311 16.7617 56.1892L38.7996 66.7331C39.5546 67.0979 40.4283 67.0979 41.1833 66.7331L63.2212 56.1892C64.1798 55.7311 64.7905 54.7641 64.7905 53.6953V26.2029C64.7905 25.1172 64.1543 24.1332 63.1703 23.6921L41.1324 13.6571C40.4029 13.3263 39.5716 13.3263 38.8421 13.6571H38.8505Z"
                                fill="white"
                            />
                        </mask>
                        <g mask="url(#mask0_63_2340)">
                            <path
                                d="M39.9957 13.1142L15.1924 24.4046L39.9957 35.6866L64.799 24.4046L39.9957 13.1142Z"
                                fill="white"
                            />
                            <path
                                opacity="0.5"
                                d="M15.1924 55.4088V24.4046L39.9957 35.6866V67.276L15.1924 55.4088Z"
                                fill="white"
                            />
                            <path
                                opacity="0.75"
                                d="M64.7989 55.4088V24.4046L39.9956 35.6866V67.276L64.7989 55.4088Z"
                                fill="white"
                            />
                        </g>
                    </g>
                </g>
                <defs>
                    <filter
                        id="filter0_d_63_2340"
                        x="13.7724"
                        y="11.989"
                        width="55.2781"
                        height="59.2777"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="1.42" dy="1.42" />
                        <feGaussianBlur stdDeviation="1.42" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_63_2340" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_63_2340" result="shape" />
                    </filter>
                    <linearGradient
                        id="paint0_linear_63_2340"
                        x1="5.63249"
                        y1="5.63249"
                        x2="74.359"
                        y2="74.359"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#F0ED82" />
                        <stop offset="1" stop-color="#D7D330" />
                    </linearGradient>
                    <clipPath id="clip0_63_2340">
                        <rect width="80" height="80" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
        ImportData: (
            <svg viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_247_2286)">
                    <rect width="70" height="70" rx="20" fill="url(#paint0_linear_247_2286)" />
                    <rect width="70" height="70" fill="url(#paint1_linear_247_2286)" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M33.8112 53H43.0567V52.987C47.9851 52.987 52 49.021 52 44.1382V25.8488C52 20.9725 47.9916 17 43.0567 17H33.8112C31.7019 17 30 18.6839 30 20.771C30 22.858 31.7085 24.542 33.8112 24.542H43.0567C43.7861 24.542 44.3841 25.1336 44.3841 25.8553V44.1447C44.3841 44.8664 43.7861 45.458 43.0567 45.458H33.8112C31.7019 45.458 30 47.142 30 49.229C30 51.3161 31.7085 53 33.8112 53ZM25.405 26.7424L32.2202 33.1364L32.2071 33.1302C32.7117 33.6036 33 34.2553 33 34.9255C33 35.5956 32.7182 36.2411 32.2071 36.7207L25.2412 43.2561C24.7169 43.7541 24.0157 44 23.3277 44C22.6396 44 21.945 43.7541 21.4142 43.2561C20.3591 42.2601 20.3591 40.6493 21.4142 39.6595L23.7077 37.5138H15.7064C14.2123 37.5138 13 36.3764 13 34.9746C13 33.5729 14.2123 32.4355 15.7064 32.4355H23.806L21.5714 30.339C20.5164 29.343 20.5164 27.7322 21.5714 26.7424C22.633 25.7525 24.3499 25.7525 25.405 26.7424Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_247_2286"
                        x1="0"
                        y1="0"
                        x2="70"
                        y2="70"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#308BAB" />
                        <stop offset="1" stop-color="#195275" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_247_2286"
                        x1="0"
                        y1="0"
                        x2="70"
                        y2="70"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#308BAB" />
                        <stop offset="1" stop-color="#195275" />
                    </linearGradient>
                    <clipPath id="clip0_247_2286">
                        <rect width="70" height="70" rx="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
        ImportProducts: (
            <svg viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_107_3168)">
                    <path
                        d="M53.1662 0H16.8338C7.53677 0 0 7.53677 0 16.8338V53.1662C0 62.4632 7.53677 70 16.8338 70H53.1662C62.4632 70 70 62.4632 70 53.1662V16.8338C70 7.53677 62.4632 0 53.1662 0Z"
                        fill="url(#paint0_linear_107_3168)"
                    />
                    <path
                        d="M35.8246 15.3732C35.3005 15.136 34.6996 15.136 34.1754 15.3732L18.545 22.4467C17.8657 22.7541 17.8659 23.7188 18.5452 24.026L34.176 31.0944C34.6998 31.3313 35.3003 31.3313 35.8241 31.0944L51.4549 24.026C52.1342 23.7188 52.1343 22.7541 51.4551 22.4467L35.8246 15.3732Z"
                        fill="white"
                    />
                    <path
                        d="M18.1589 46.646C17.8579 46.502 17.6663 46.1979 17.6663 45.8642V27.9079C17.6663 27.2774 18.3179 26.8579 18.8918 27.119L33.0374 33.5532C33.3467 33.6939 33.5453 34.0023 33.5453 34.3421V52.6323C33.5453 53.27 32.8798 53.6893 32.3045 53.4141L18.1589 46.646Z"
                        fill="white"
                    />
                    <path
                        d="M51.8598 46.646C52.1608 46.502 52.3524 46.1979 52.3524 45.8642V27.9079C52.3524 27.2774 51.7007 26.8579 51.1269 27.119L36.9812 33.5532C36.6719 33.6939 36.4734 34.0023 36.4734 34.3421V52.6323C36.4734 53.27 37.1389 53.6893 37.7141 53.4141L51.8598 46.646Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_107_3168"
                        x1="4.92843"
                        y1="4.92843"
                        x2="65.0642"
                        y2="65.0642"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#195275" />
                        <stop offset="1" stop-color="#10354C" />
                    </linearGradient>
                    <clipPath id="clip0_107_3168">
                        <rect width="70" height="70" rx="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
    },
});

const theme = createTheme({
    palette: {
        themePrimary: "#66c4d6",
        themeLighterAlt: "#f8fcfd",
        themeLighter: "#e4f5f8",
        themeLight: "#cdecf3",
        themeTertiary: "#9fdbe7",
        themeSecondary: "#77cadb",
        themeDarkAlt: "#5db0c1",
        themeDark: "#4e95a3",
        themeDarker: "#3a6e78",
        neutralLighterAlt: "#f3f2f1",
        neutralLighter: "#efeeed",
        neutralLight: "#e5e4e3",
        neutralQuaternaryAlt: "#d6d5d4",
        neutralQuaternary: "#cccbca",
        neutralTertiaryAlt: "#c4c3c2",
        neutralTertiary: "#b2b2ae",
        neutralSecondary: "#999994",
        neutralPrimaryAlt: "#80807b",
        neutralPrimary: "#1d1d1b",
        neutralDark: "#4e4e4a",
        black: "#353532",
        white: "#faf9f8",
    },
});

const App = () => {
    const getEnvironmentApi = GetEnvironment.useApi();
    const getAdditionalUserInfoApi = GetAdditionalUserInfo.useApi();
    const getCountriesApi = GetCountries.useApi();
    const getInsuranceCompaniesApi = GetInsuranceCompanies.useApi();
    const getInsuranceTypesApi = GetInsuranceTypes.useApi();
    const getCurrenciesApi = GetCurrencies.useApi();

    const getCurrencyText = useCallback(
        (value: number, currencyId?: number) => {
            const actualCurrencyId = currencyId ?? getAdditionalUserInfoApi?.data?.CurrencyId;
            const actualCurrency =
                actualCurrencyId !== undefined
                    ? getCurrenciesApi.data?.find((x) => {
                          return x.Id === actualCurrencyId;
                      })
                    : undefined;
            const locale = getAdditionalUserInfoApi.data?.CountryLocale;

            let currencyText = value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            if (actualCurrency !== undefined) {
                if (actualCurrency.IsPrefix) {
                    currencyText = `${actualCurrency.Symbol} ${currencyText}`;
                } else {
                    currencyText = `${currencyText} ${actualCurrency.Symbol}`;
                }
            }

            return currencyText;
        },
        [getAdditionalUserInfoApi.data, getCurrenciesApi.data],
    );

    return (
        <appContext.Provider
            value={{
                environment: getEnvironmentApi.data!,
                additionalUserInfo: getAdditionalUserInfoApi.data!,
                countries: getCountriesApi.data!,
                insuranceCompanies: getInsuranceCompaniesApi.data!,
                insuranceTypes: getInsuranceTypesApi.data!,
                currencies: getCurrenciesApi.data!,
                getCurrencyText: getCurrencyText,
            }}
        >
            <InCoreApp
                theme={theme}
                onInit={async () => {
                    SetLastUse.callApi({});
                    await Promise.all([
                        getEnvironmentApi.call({}),
                        getAdditionalUserInfoApi.call({}),
                        getCountriesApi.call({}),
                        getInsuranceCompaniesApi.call({}),
                        getInsuranceTypesApi.call({}),
                        getCurrenciesApi.call({}),
                    ]);
                }}
                localization={{ hr: hrLocalization, sl: slLocalization }}
                onRenderLayout={(layoutProps) => {
                    return (
                        <DefaultLayout
                            {...layoutProps}
                            hideSearchAndListView
                            homeIcon="Dashboard"
                            homeText="Dashboard"
                            documentsHidden
                            iconSize={28}
                        />
                    );
                }}
                additionalUserContent={(form, entityDataTableContext) => {
                    const isRead = entityDataTableContext.innerPermission?.Id === "Read";

                    return (
                        <FormGroup>
                            <FormCheckbox label="Is blocked?" value={form.IsBlocked} disabled={isRead} />

                            <FormEntityDataTableDropdown
                                label="Country"
                                value={form.CountryId}
                                entity="Country"
                                disabled={isRead}
                            />

                            <FormDropdown
                                label="Data import status"
                                value={form.DataImportStatus}
                                options={[
                                    {
                                        key: "Success",
                                        text: "Success",
                                    },
                                    {
                                        key: "Dissmissed",
                                        text: "Dissmissed",
                                    },
                                ]}
                                disabled={isRead}
                            />

                            <FormDropdown
                                label="Product import status"
                                value={form.ProductImportStatus}
                                options={[
                                    {
                                        key: "Success",
                                        text: "Success",
                                    },
                                    {
                                        key: "Dissmissed",
                                        text: "Dissmissed",
                                    },
                                ]}
                                disabled={isRead}
                            />

                            <FormCheckbox label="Is first use?" value={form.IsFirstUse} disabled={isRead} />

                            <FormCheckbox
                                label="Is data imort prompt dismissed?"
                                value={form.IsDataImportPromptDismissed}
                                disabled={isRead}
                            />

                            <TextField label="Last use" value={form.LastUse.$value?.toLocaleString("hr") ?? ""} />
                        </FormGroup>
                    );
                }}
            />
        </appContext.Provider>
    );
};

export default App;

export interface AppContext {
    environment: GetEnvironment.Response;
    additionalUserInfo: GetAdditionalUserInfo.Response;
    countries: GetCountries.CountryInfo[];
    insuranceCompanies: GetInsuranceCompanies.InsuranceCompanyInfo[];
    insuranceTypes: GetInsuranceTypes.InsuranceTypeInfo[];
    currencies: GetCurrencies.CurrencyInfo[];
    getCurrencyText: (value: number, currencyId?: number) => string;
}

const appContext = createContext<AppContext | undefined>(undefined);

export const useAppContext = () => {
    return useContext(appContext) as AppContext;
};

const hrLocalization: IInCoreLocalization = {
    Error: "Izgleda da se desila greška. Greška je zapisana i informatika je obaviještena.",
    Search: "Pretraga...",
    Accept: "Prihvati",
    Select: "Odaberi",
    Clear: "Ukloni",
    Ok: "U redu",
    Yes: "Da",
    No: "Ne",
    Details: "Detalji",
    Edit: "Uredi",
    Add: "Dodaj",
    Delete: "Obriši",
    Remove: "Ukloni",
    Download: "Preuzmi",
    Upload: "Učitaj",
    Save: "Spremi",
    Continue: "Nastavi",
    Submit: "Prihvati",
    UnsavedChanges: "Nespremljene promjene",
    UnsavedChangesMessage:
        "Ako nastavite, promjene koje ste napravili neće biti prihvaćene. Jeste li sigurni da želite nastaviti?",
    Cancel: "Odustani",
    Other: "Ostalo",
    Loading: "Učitavanje...",
    PleaseWait: "Molimo pričekajte...",
    Total: "Ukupno",
    HomeTitle: "Dashboard",
    DocumentsTitle: "Dokumenti",
    NotificationsTitle: "Obavijesti",
    UserMenuTitle: "Korisnik",
    UserMenuSettingsTitle: "Postavke",
    UserMenuLogOutTitle: "Odjava",
    UsersTitle: "Korisnici",
    RolesTitle: "Uloge",
    SettingsTitle: "Postavke",
    ConnectedAppsTitle: "Povezane aplikacije",
    NotFound: "Nije moguće pronaći traženi dio sustava.",
    UnauthorizedPartOfSystem: "Nemate pristup ovom dijelu sustava.",
    Menu: "Izbornik",
    MenuViewText: "Izbornik",
    ListViewText: "Popis",
    MenuLoadingText: "Izbornik se učitava...",
    Documents: {
        MyDocuments: "Moji dokumenti",
        OrganizationDocuments: "Dokumenti organizacije",
        System: "Sustav",
        Refresh: "Osvježi",
        Upload: "Prenesi",
        NewFolder: "Nova mapa",
        Rename: "Preimenuj",
        Copy: "Kopiraj",
        Move: "Premjesti",
        Delete: "Obriši",
        Download: "Preuzmi",
        NotFound: "Nije moguće pronaći traženi direktorij.",
        NameColumn: "Naziv",
        TimeAddedColumn: "Dodano",
        AddedByColumn: "Dodao/la",
        SizeColumn: "Veličina",
        DeleteConfirmation: "Jeste li sigurni da želite obrisati dokumente?",
        PreviewNotSupported: "Pregled ovog tipa datoteke nije podržan.",
        NameInvalid: "Neispravan naziv.",
        NameAlreadyExists: "Datoteka s ovim nazivom već postoji.",
    },
    Notes: {
        NewNote: "Nova bilješka",
        DeleteNote: "Brisanje bilješke",
        DeleteNoteConfirm: "Jeste li sigurni da želite obrisati ovu bilješku?",
    },
    DataTable: {
        RemoveSort: "Ukloni poredak",
        SortAscending: "Poredaj uzlazno",
        SortDescending: "Poredaj silazno",
        Filter: "Uvjet",
        Sort: "Poredak",
        Pagination: "Podskup podataka",
        Selection: "Odabir",
        Grouping: "Grupiranje",
        ColumnDropdownPlaceholder: "Odaberite stupac",
        DirectionDropdownPlaceholder: "Odaberite smjer",
        Ascending: "Uzlazno",
        Descending: "Silazno",
        SelectionHiddenColumns: "Sakriveni stupci",
        SelectionVisibleColumns: "Vidljivi stupci",
        SelectionShow: "Prikaži",
        SelectionHide: "Sakrij",
        SelectionMoveUp: "Pomakni gore",
        SelectionMoveDown: "Pomakni dole",
        SelectionAggregateDataPlaceholder: "Agregirani podaci",
        SelectionAggregateCountEmpty: "Broj praznih",
        SelectionAggregateCountNonEmpty: "Broj ne praznih",
        SelectionAggregatePercentageEmpty: "Postotak praznih",
        SelectionAggregatePercentageNonEmpty: "Postotak ne praznih",
        SelectionAggregateSum: "Suma",
        SelectionAggregateAverage: "Prosjek",
        SelectionAggregateMinimum: "Minimum",
        SelectionAggregateMaximum: "Maksimum",
        SelectionAggregateCountTrue: "Broj istinitih",
        SelectionAggregateCountFalse: "Broj neistinitih",
        SelectionAggregatePercentageTrue: "Postotak istinitih",
        SelectionAggregatePercentageFalse: "Postotak neistinitih",
        Equal: "Jednako",
        NotEqual: "Različito",
        GreaterThan: "Veće od",
        GreaterThanOrEqual: "Veće od ili jednako",
        LessThan: "Manje od",
        LessThanOrEqual: "Manje od ili jednako",
        IsEmpty: "Prazno",
        IsNotEmpty: "Nije prazno",
        Contains: "Sadrži",
        NotContains: "Ne sadrži",
        StartsWith: "Počinje sa",
        NotStartsWith: "Ne počinje sa",
        EndsWith: "Završava sa",
        NotEndsWith: "Ne završava sa",
        InRange: "U rasponu",
        NumberOfItems: "Broj prikazanih stavki:",
        OutOf: "od",
        NumberOfItemsSelected: "Broj označenih stavki:",
        PaginationNumberOfItems: "Broj stavki",
        PaginationPage: "Stranica",
        PaginationGo: "Idi",
        CountEmpty: "Broj praznih",
        CountNonEmpty: "Broj ne praznih",
        PercentEmpty: "Postotak praznih",
        PercentNonEmpty: "Postotak ne praznih",
        Sum: "Suma",
        Average: "Prosjek",
        Minimum: "Minimum",
        Maximum: "Maksimum",
        CountTrue: "Broj istinitih",
        CountFalse: "Broj neistinitih",
        PercentTrue: "Postotak istinitih",
        PercentFalse: "Postotak neistinitih",
        AddCountEmpty: "Dodaj broj praznih",
        AddCountNonEmpty: "Dodaj broj ne praznih",
        AddPercentEmpty: "Dodaj postotak praznih",
        AddPercentNonEmpty: "Dodaj postotak ne praznih",
        AddSum: "Dodaj sumu",
        AddAverage: "Dodaj prosjek",
        AddMinimum: "Dodaj minimum",
        AddMaximum: "Dodaj maksimum",
        AddCountTrue: "Dodaj broj istinitih",
        AddCountFalse: "Dodaj broj neistinitih",
        AddPercentTrue: "Dodaj postotak istinitih",
        AddPercentFalse: "Dodaj postotak neistinitih",
        AggregateDataNotAvailable: "Agregirani podatak nije dostupan",
        AllAggregateDataShown: "Svi agregirani podaci su prikazani",
        AddAggregateData: "Dodaj agregirani podatak",
        RemoveAggregateData: "Ukloni agregirani podatak",
        SelectDate: "Odaberite",
        SpecificDate: "Točan datum",
        Today: "Danas",
        Yesterday: "Jučer",
        Tommorrow: "Sutra",
        BeginningOfWeek: "Početak tjedna",
        EndOfWeek: "Kraj tjedna",
        BeforeOneWeek: "Prije tjedan dana",
        AfterOneWeek: "Za tjedan dana",
        BeginningOfMonth: "Početak mjeseca",
        EndOfMonth: "Kraj mjeseca",
        BeforeOneMonth: "Prije mjesec dana",
        AfterOneMonth: "Za mjesec dana",
        BeginningOfQuarter: "Početak kvartala",
        EndOfQuarter: "Kraj kvartala",
        BeforeOneQuarter: "Prije tri mjeseca",
        AfterOneQuarter: "Za tri mjeseca",
        BeginningOfYear: "Početak godine",
        EndOfYear: "Kraj godine",
        BeforeOneYear: "Prije godinu dana",
        AfterOneYear: "Za godinu dana",
        SelectView: "Odaberite pregled",
        SetAsDefault: "Postavi kao zadano",
        UnsetAsDefault: "Ukloni kao zadano",
        SaveView: "Spremi",
        SaveViewAs: "Spremi kao",
        NoData: "Nema podataka za prikaz",
        AddNewItem: "Dodaj novu stavku",
    },
    EntityDataTable: {
        ConfigurationLoading: "Konfiguracija se učitava...",
        Refresh: "Osvježi",
        Create: "Novo",
        Read: "Pregled",
        Update: "Uređivanje",
        Delete: "Brisanje",
        Basic: "Osnovno",
        Actions: "Akcije",
        Documents: "Dokumenti",
        Notes: "Bilješke",
        Activity: "Aktivnosti",
        AuditLog: "Promjene",
        Export: "Izvoz",
        ReportName: "Naziv izvještaja",
        ReportFormat: "Format",
        ReportFormatPdfVertical: "PDF vertikalno",
        ReportFormatPdfHorizontal: "PDF horizontalno",
        ReportFormatExcel: "Excel",
        ReportFormatCsv: "CSV",
        ReportTooManyColumnsPrefix: "Zbog odabranog formata ne preporučamo da uključujete više od ",
        ReportTooManyColumnsSuffix: " stupaca.",
        ReportError: "Greška kod generiranja izvještaja",
        ReportErrorMessage:
            "Izgleda da se desila greška kod generiranja izvještaja. Jedan od mogućih razloga je ako ste odabrali prevelik broj stupaca za prikaz.",
        SavingView: "Spremanje pregleda",
        ViewName: "Naziv pregleda",
        SetAsDefaultView: "Postavi kao zadani pregled za mene",
        SetAsPublicView: "Javno dostupan pregled (za sve korisnike)",
        SetAsPublicDefaultView:
            "Postavi kao zadani pregled za sve korisnike (dok si on ne postave drugi pregled kao zadani)",
        DeletingView: "Brisanje pregleda",
        DeletingViewConfirmation: "Jeste li sigurni da želite obrisati pregled",
        ActivityUserPrefix: "Korisnik",
        ActivityUserSuffix: "je izvršio/la",
        ActivityConnectedAppPrefix: "Aplikacija",
        ActivityConnectedAppSuffix: "je izvršila",
        ActivityGenericPrefix: "Aktivnost",
        ActivityGenericSuffix: "je izvršena",
        ActivityViewChanges: "Pregled promjena",
        ActivityGeneratedId: "Generirani ID",
        ActivityName: "Naziv",
        ActivityTime: "Vrijeme",
        ActivityPermission: "Ovlast",
        ActivityUser: "Korisnik",
        ActivityConnectedApp: "Povezana aplikacija",
        ActivityAuditLogTime: "Vrijeme",
        ActivityAuditLogType: "Tip",
        ActivityAuditLogOnlyChanges: "Prikaži samo promjene",
        AuditLogId: "ID",
        AuditLogTime: "Vrijeme",
        AuditLogType: "Tip",
        AuditLogUser: "Korisnik",
        AuditLogConnectedApp: "Povezana aplikacija",
        AuditLogActivity: "Aktivnost",
        AuditLogOnlyChanges: "Prikaži samo promjene",
        DeleteError: "Izgleda da nije moguće obrisati ovu stavku.",
        DeleteNotPossible: "Brisanje nije moguće jer postoje zapisi koji to onemogućuju.",
        DeleteWarning:
            "Brisanje je vjerojatno moguće, ali budite oprezni jer će ovo brisanje automatski obrisati i dodatne zapise.",
        DeleteConfirm: "Jeste li sigurni da želite obrisati ovaj zapis?",
        NotesTextRequired: "Tekst je obavezan",
    },
    DataTableDropdown: {
        MinSearchEnter: "Upišite",
        MinSearchDescriptionWithoutAdvanced: "znaka za brzu pretragu.",
        MinSearchDescriptionWithAdvanced: "znaka za brzu pretragu ili stisnite enter za naprednu pretragu.",
        SelectItemWithoutAdvanced: "Odaberite stavku.",
        SelectItemWithAdvanced: "Odaberite stavku ili stisnite enter za naprednu pretragu.",
        NothingFound: "Nije pronađena niti jedna stavka",
        AdvancedSearch: "Napredna pretraga...",
    },
    EntityDataTableDropdown: {
        AddNew: "Dodaj novo",
    },
    Settings: {
        Edit: "Uredi",
        IdColumn: "ID",
        NameColumn: "Naziv",
        DescriptionColumn: "Opis",
        IsPrivateColumn: "Privatno?",
        IsAllowedForSystemColumn: "Sustav?",
        IsAllowedForRoleColumn: "Uloge?",
        IsAllowedForUserColumn: "Korisnici?",
        System: "Sustav",
        Roles: "Uloge",
        Users: "Korisnici",
        Role: "Uloga",
        User: "Korisnik",
        RoleIdColumn: "ID",
        RoleNameColumn: "Oznaka",
        RoleDescriptionColumn: "Naziv",
        RoleHasValueColumn: "Ima vrijednost?",
        RoleValueColumn: "Vrijednost",
        UserIdColumn: "ID",
        UserUserNameColumn: "Korisničko ime",
        UserNameColumn: "Naziv",
        UserHasValueColumn: "Ima vrijednost?",
        UserValueColumn: "Vrijednost",
        ValueNotDefined: "Vrijednost nije definirana",
        Value: "Vrijednost",
        SetValue: "Postavi vrijednost",
        ClearValue: "Ukloni vrijednost",
    },
    UserManagement: {
        Basic: "Osnovno",
        Permissions: "Ovlasti",
        Users: "Korisnici",
        Roles: "Uloge",
        Menu: "Izbornik",
        Settings: "Postavke",
        RoleId: "ID",
        RoleName: "Oznaka",
        RoleDescription: "Naziv",
        UserId: "ID",
        UserUserName: "Korisničko ime",
        UserName: "Naziv",
        UserEmail: "Email",
        UserCulture: "Kultura",
        UserPassword: "Lozinka",
        UserLockout: "Lockout?",
        Assigned: "Dodijeljeno?",
        SubstructurePlaceholder: "Odabir podstrukture",
        SearchAllStructuresPlaceholder: "Pretraži sve strukture...",
        PermissionsIdColumn: "ID",
        PermissionsNameColumn: "Naziv",
        PermissionsDescriptionColumn: "Opis",
        PermissionsIsSelectedColumn: "Ovlast",
        PermissionsAssigned: "Dodijeljeno",
        PermissionsAssignedExplicit: "Dodijeljeno - eksplicitno",
        PermissionsAssignedImplicit: "Dodijeljeno - implicitno",
        PermissionsAssignedInherited: "Dodijeljeno - nasljeđeno",
        PermissionsNotAssigned: "Nije dodijeljeno",
        PermissionsNotAssignedExplicit: "Nije dodijeljeno - eksplicitno",
        PermissionsNotAssignedInherited: "Nije dodijeljeno - nasljeđeno",
        PermissionsYesImplied: "Da (implicirano)",
        PermissionsImpliedBy: "Implicirano od:",
        PermissionsInheritedFrom: "Nasljeđeno od:",
        PermissionsImplied: "implicirano",
        PermissionsInherited: "nasljeđeno",
        MenuDefault: "Zadani izbornik",
        MenuCustom: "Uređeni izbornik",
        MenuRemove: "Ukloni",
        MenuMoveUp: "Pomakni gore",
        MenuMoveDown: "Pomakni dole",
        MenuRename: "Preimenuj",
        MenuAddItem: "Dodaj stavku",
        MenuAddSubmenu: "Dodaj podizbornik",
        MenuDefaultSelected: "Odabran je zadani izbornik.",
        MenuAddSubmenuTitle: "Dodaj podizbornik",
        MenuAddSubmenuText: "Unesite naziv i odaberite opciju 'Dodaj'.",
        MenuAddSubmenuSubmit: "Dodaj",
        MenuRenameTitle: "Preimenuj izbornik",
        MenuRenameText: "Unesite naziv i odaberite opciju 'Spremi'",
        MenuRenameSubmit: "Spremi",
        Setting: "Postavka",
        SettingsIdColumn: "ID",
        SettingsNameColumn: "Naziv",
        SettingsDescriptionColumn: "Opis",
        SettingsHasValueColumn: "Ima vrijednost?",
        SettingsValueColumn: "Vrijednost",
        SettingsIsPrivateColumn: "Privatno?",
        SettingsIsAllowedForSystemColumn: "Sustav?",
        SettingsIsAllowedForRoleColumn: "Uloga?",
        SettingsIsAllowedForUserColumn: "Korisnik?",
    },
    ConnectedApps: {
        CreateMessage:
            "Nakon što dodijelite ovlasti povezanoj aplikaciji i spremite ju, moći ćete joj dodati pristupne ključeve i generirati OpenAPI dokumentaciju.",
        ShowOnlyAssigned: "Prikaži samo dodijeljene",
        IdColumn: "ID",
        NameColumn: "Naziv",
        DescriptionColumn: "Opis",
        DocumentationFormat: "Format dokumentacije",
    },
    DatePicker: {
        Today: "Danas",
        January: "Siječanj",
        February: "Veljača",
        March: "Ožujak",
        April: "Travanj",
        May: "Svibanj",
        June: "Lipanj",
        July: "Srpanj",
        August: "Kolovoz",
        September: "Rujan",
        October: "Listopad",
        November: "Studeni",
        December: "Prosinac",
        JanuaryShort: "Sij.",
        FebruaryShort: "Velj.",
        MarchShort: "Ožu.",
        AprilShort: "Tra.",
        MayShort: "Svi.",
        JuneShort: "Lip.",
        JulyShort: "Srp.",
        AugustShort: "Kol.",
        SeptemberShort: "Ruj.",
        OctoberShort: "Lis.",
        NovemberShort: "Stu.",
        DecemberShort: "Pro.",
        Monday: "Ponedjeljak",
        Tuesday: "Utorak",
        Wednesday: "Srijeda",
        Thursday: "Četvrtak",
        Friday: "Petak",
        Saturday: "Subota",
        Sunday: "Nedjelja",
        MondayShort: "Pon",
        TuesdayShort: "Uto",
        WednesdayShort: "Sri",
        ThursdayShort: "Čet",
        FridayShort: "Pet",
        SaturdayShort: "Sub",
        SundayShort: "Ned",
    },
    FileUpload: {
        SelectFilesAndFoldersSingular: "Dovucite datoteku, mapu ili odaberite opciju.",
        SelectFilesAndFoldersPlural: "Dovucite datoteke, mape ili odaberite opciju.",
        SelectFilesSingular: "Dovucite datoteku ili odaberite opciju.",
        SelectFilesPlural: "Dovucite datoteke ili odaberite opciju.",
        SelectFoldersSingular: "Dovucite mapu ili odaberite opciju.",
        SelectFoldersPlural: "Dovucite mape ili odaberite opciju.",
        Drop: "Ispustite kako bi dodali.",
        Select: "Odaberite",
        File: "datoteku",
        Files: "datoteke",
        Folder: "mapu",
        IncorrectName: "Neispravan naziv.",
        FileAreadyExists: "Datoteka s ovim imenom već postoji.",
        FolderAlreadyExists: "Mapa s ovim nazivom već postoji.",
    },
    FileUploadModal: {
        Title: "Prijenos datoteka",
    },
    ModalPdfViewer: {
        DefaultName: "Dokument",
        Loading: "Datoteka se učitava...",
    },
    Pagination: {
        First: "Prva",
        Previous: "Prethodna",
        Next: "Sljedeća",
        Last: "Posljednja",
    },
    MultiStepContent: {
        Back: "Natrag",
        Next: "Dalje",
        Complete: "Završi",
    },
    DataEntryForm: {
        DataLoading: "Podaci se učitavaju...",
        Success: "Podaci su uspješno spremljeni.",
        AlreadyExistsError: "Stavka s unesenom šifrom već postoji.",
        AlreadyExistsMessage:
            "Stavka s unesenom šifrom već postoji u sustavu. Možete unijeti drugu šifru, ili otići na pregled ili uređivanje.",
        AlreadyExistsUpdate: "Uređivanje",
        AlreadyExistsRead: "Pregled",
    },
};

const slLocalization: IInCoreLocalization = {
    Error: "Zdi se, da je prišlo do napake. Napaka je zabeležena in informatika je bila obveščena.",
    Search: "Iskanje...",
    Accept: "Sprejmi",
    Select: "Izberi",
    Clear: "Počisti",
    Ok: "V redu",
    Yes: "Da",
    No: "Ne",
    Details: "Podrobnosti",
    Edit: "Uredi",
    Add: "Dodaj",
    Delete: "Izbriši",
    Remove: "Odstrani",
    Download: "Prenesi",
    Upload: "Naloži",
    Save: "Shrani",
    Continue: "Nadaljuj",
    Submit: "Pošlji",
    UnsavedChanges: "Neshranjene spremembe",
    UnsavedChangesMessage:
        "Če nadaljujete, spremembe, ki ste jih naredili, ne bodo shranjene. Ste prepričani, da želite nadaljevati?",
    Cancel: "Prekliči",
    Other: "Drugo",
    Loading: "Nalaganje...",
    PleaseWait: "Prosimo, počakajte...",
    Total: "Skupaj",
    HomeTitle: "Nadzorna plošča",
    DocumentsTitle: "Dokumenti",
    NotificationsTitle: "Obvestila",
    UserMenuTitle: "Uporabnik",
    UserMenuSettingsTitle: "Nastavitve",
    UserMenuLogOutTitle: "Odjava",
    UsersTitle: "Uporabniki",
    RolesTitle: "Vloge",
    SettingsTitle: "Nastavitve",
    ConnectedAppsTitle: "Povezane aplikacije",
    NotFound: "Iskani del sistema ni mogoče najti.",
    UnauthorizedPartOfSystem: "Tega dela sistema nimate dovoljenja ogledati.",
    Menu: "Meni",
    MenuViewText: "Meni",
    ListViewText: "Seznam",
    MenuLoadingText: "Nalaganje menija...",
    Documents: {
        MyDocuments: "Moji dokumenti",
        OrganizationDocuments: "Dokumenti organizacije",
        System: "Sistem",
        Refresh: "Osveži",
        Upload: "Naloži",
        NewFolder: "Nova mapa",
        Rename: "Preimenuj",
        Copy: "Kopiraj",
        Move: "Premakni",
        Delete: "Izbriši",
        Download: "Prenesi",
        NotFound: "Iskane mape ni mogoče najti.",
        NameColumn: "Ime",
        TimeAddedColumn: "Dodano",
        AddedByColumn: "Dodano od",
        SizeColumn: "Velikost",
        DeleteConfirmation: "Ste prepričani, da želite izbrisati dokumente?",
        PreviewNotSupported: "Predogled tega tipa datoteke ni podprt.",
        NameInvalid: "Neveljavno ime.",
        NameAlreadyExists: "Datoteka s tem imenom že obstaja.",
    },
    Notes: {
        NewNote: "Nova opomba",
        DeleteNote: "Izbriši opombo",
        DeleteNoteConfirm: "Ste prepričani, da želite izbrisati to opombo?",
    },
    DataTable: {
        RemoveSort: "Odstrani razvrščanje",
        SortAscending: "Razvrsti naraščajoče",
        SortDescending: "Razvrsti padajoče",
        Filter: "Filter",
        Sort: "Razvrsti",
        Pagination: "Stran",
        Selection: "Izbira",
        Grouping: "Združevanje",
        ColumnDropdownPlaceholder: "Izberite stolpec",
        DirectionDropdownPlaceholder: "Izberite smer",
        Ascending: "Naraščajoče",
        Descending: "Padajoče",
        SelectionHiddenColumns: "Skriti stolpci",
        SelectionVisibleColumns: "Vidni stolpci",
        SelectionShow: "Pokaži",
        SelectionHide: "Skrij",
        SelectionMoveUp: "Premakni gor",
        SelectionMoveDown: "Premakni dol",
        SelectionAggregateDataPlaceholder: "Združeni podatki",
        SelectionAggregateCountEmpty: "Število praznih",
        SelectionAggregateCountNonEmpty: "Število nepraznih",
        SelectionAggregatePercentageEmpty: "Odstotek praznih",
        SelectionAggregatePercentageNonEmpty: "Odstotek nepraznih",
        SelectionAggregateSum: "Vsota",
        SelectionAggregateAverage: "Povprečje",
        SelectionAggregateMinimum: "Najmanj",
        SelectionAggregateMaximum: "Največ",
        SelectionAggregateCountTrue: "Število pravih",
        SelectionAggregateCountFalse: "Število napačnih",
        SelectionAggregatePercentageTrue: "Odstotek pravih",
        SelectionAggregatePercentageFalse: "Odstotek napačnih",
        Equal: "Enako",
        NotEqual: "Neenako",
        GreaterThan: "Večje od",
        GreaterThanOrEqual: "Večje ali enako",
        LessThan: "Manjše od",
        LessThanOrEqual: "Manjše ali enako",
        IsEmpty: "Je prazno",
        IsNotEmpty: "Ni prazno",
        Contains: "Vsebuje",
        NotContains: "Ne vsebuje",
        StartsWith: "Se začne z",
        NotStartsWith: "Se ne začne z",
        EndsWith: "Se konča z",
        NotEndsWith: "Se ne konča z",
        InRange: "Je v obsegu",
        NumberOfItems: "Število prikazanih postavk:",
        OutOf: "od",
        NumberOfItemsSelected: "Število izbranih postavk:",
        PaginationNumberOfItems: "Število postavk",
        PaginationPage: "Stran",
        PaginationGo: "Pojdi",
        CountEmpty: "Število praznih",
        CountNonEmpty: "Število nepraznih",
        PercentEmpty: "Odstotek praznih",
        PercentNonEmpty: "Odstotek nepraznih",
        Sum: "Vsota",
        Average: "Povprečje",
        Minimum: "Najmanj",
        Maximum: "Največ",
        CountTrue: "Število pravih",
        CountFalse: "Število napačnih",
        PercentTrue: "Odstotek pravih",
        PercentFalse: "Odstotek napačnih",
        AddCountEmpty: "Dodaj število praznih",
        AddCountNonEmpty: "Dodaj število nepraznih",
        AddPercentEmpty: "Dodaj odstotek praznih",
        AddPercentNonEmpty: "Dodaj odstotek nepraznih",
        AddSum: "Dodaj vsoto",
        AddAverage: "Dodaj povprečje",
        AddMinimum: "Dodaj najmanj",
        AddMaximum: "Dodaj največ",
        AddCountTrue: "Dodaj število pravih",
        AddCountFalse: "Dodaj število napačnih",
        AddPercentTrue: "Dodaj odstotek pravih",
        AddPercentFalse: "Dodaj odstotek napačnih",
        AggregateDataNotAvailable: "Združeni podatki niso na voljo",
        AllAggregateDataShown: "Prikazani so vsi združeni podatki",
        AddAggregateData: "Dodaj združene podatke",
        RemoveAggregateData: "Odstrani združene podatke",
        SelectDate: "Izberi datum",
        SpecificDate: "Določen datum",
        Today: "Danes",
        Yesterday: "Včeraj",
        Tommorrow: "Jutri",
        BeginningOfWeek: "Začetek tedna",
        EndOfWeek: "Konec tedna",
        BeforeOneWeek: "Pred enim tednom",
        AfterOneWeek: "Po enem tednu",
        BeginningOfMonth: "Začetek meseca",
        EndOfMonth: "Konec meseca",
        BeforeOneMonth: "Pred enim mesecem",
        AfterOneMonth: "Po enem mesecu",
        BeginningOfQuarter: "Začetek četrtletja",
        EndOfQuarter: "Konec četrtletja",
        BeforeOneQuarter: "Pred tremi meseci",
        AfterOneQuarter: "Po treh mesecih",
        BeginningOfYear: "Začetek leta",
        EndOfYear: "Konec leta",
        BeforeOneYear: "Pred enim letom",
        AfterOneYear: "Po enem letu",
        SelectView: "Izberi pogled",
        SetAsDefault: "Nastavi kot privzeto",
        UnsetAsDefault: "Odstrani kot privzeto",
        SaveView: "Shrani",
        SaveViewAs: "Shrani kot",
        NoData: "Ni podatkov za prikaz",
        AddNewItem: "Dodaj novo postavko",
    },
    EntityDataTable: {
        ConfigurationLoading: "Nalaganje konfiguracije...",
        Refresh: "Osveži",
        Create: "Ustvari",
        Read: "Preberi",
        Update: "Posodobi",
        Delete: "Izbriši",
        Basic: "Osnovno",
        Actions: "Dejanja",
        Documents: "Dokumenti",
        Notes: "Opombe",
        Activity: "Dejavnost",
        AuditLog: "Audični dnevnik",
        Export: "Izvozi",
        ReportName: "Ime poročila",
        ReportFormat: "Format",
        ReportFormatPdfVertical: "PDF navpično",
        ReportFormatPdfHorizontal: "PDF vodoravno",
        ReportFormatExcel: "Excel",
        ReportFormatCsv: "CSV",
        ReportTooManyColumnsPrefix: "Zaradi izbranega formata ne priporočamo vključitve več kot ",
        ReportTooManyColumnsSuffix: " stolpcev.",
        ReportError: "Napaka pri ustvarjanju poročila",
        ReportErrorMessage:
            "Zdi se, da je prišlo do napake pri ustvarjanju poročila. Ena od možnih napak je, če ste izbrali preveč stolpcev za prikaz.",
        SavingView: "Shranjevanje pogleda",
        ViewName: "Ime pogleda",
        SetAsDefaultView: "Nastavi kot privzeti pogled zame",
        SetAsPublicView: "Javno dostopen pogled (za vse uporabnike)",
        SetAsPublicDefaultView:
            "Nastavi kot privzeti pogled za vse uporabnike (dokler drugi uporabniki ne nastavijo drugega pogleda kot privzetega)",
        DeletingView: "Brisanje pogleda",
        DeletingViewConfirmation: "Ste prepričani, da želite izbrisati ta pogled",
        ActivityUserPrefix: "Uporabnik",
        ActivityUserSuffix: "je izvedel",
        ActivityConnectedAppPrefix: "Povezana aplikacija",
        ActivityConnectedAppSuffix: "je izvedla",
        ActivityGenericPrefix: "Dejavnost",
        ActivityGenericSuffix: "je bila izvedena",
        ActivityViewChanges: "Pregled sprememb",
        ActivityGeneratedId: "Generirani ID",
        ActivityName: "Ime",
        ActivityTime: "Čas",
        ActivityPermission: "Dovoljenje",
        ActivityUser: "Uporabnik",
        ActivityConnectedApp: "Povezana aplikacija",
        ActivityAuditLogTime: "Čas",
        ActivityAuditLogType: "Vrsta",
        ActivityAuditLogOnlyChanges: "Pokaži samo spremembe",
        AuditLogId: "ID",
        AuditLogTime: "Čas",
        AuditLogType: "Vrsta",
        AuditLogUser: "Uporabnik",
        AuditLogConnectedApp: "Povezana aplikacija",
        AuditLogActivity: "Dejavnost",
        AuditLogOnlyChanges: "Pokaži samo spremembe",
        DeleteError: "Zdi se, da te postavke ni mogoče izbrisati.",
        DeleteNotPossible: "Brisanje ni mogoče, ker obstajajo zapisi, ki to preprečujejo.",
        DeleteWarning:
            "Brisanje je verjetno mogoče, vendar bodite previdni, saj bo brisanje samodejno izbrisalo tudi dodatne zapise.",
        DeleteConfirm: "Ste prepričani, da želite izbrisati to postavko?",
        NotesTextRequired: "Besedilo je obvezno",
    },
    DataTableDropdown: {
        MinSearchEnter: "Vnesite",
        MinSearchDescriptionWithoutAdvanced: "znakov za hitro iskanje.",
        MinSearchDescriptionWithAdvanced: "znakov za hitro iskanje ali pritisnite Enter za napredno iskanje.",
        SelectItemWithoutAdvanced: "Izberite element.",
        SelectItemWithAdvanced: "Izberite element ali pritisnite Enter za napredno iskanje.",
        NothingFound: "Ni najdenih elementov",
        AdvancedSearch: "Napredno iskanje...",
    },
    EntityDataTableDropdown: {
        AddNew: "Dodaj novo",
    },
    Settings: {
        Edit: "Uredi",
        IdColumn: "ID",
        NameColumn: "Ime",
        DescriptionColumn: "Opis",
        IsPrivateColumn: "Zasebno?",
        IsAllowedForSystemColumn: "Sistem?",
        IsAllowedForRoleColumn: "Vloge?",
        IsAllowedForUserColumn: "Uporabniki?",
        System: "Sistem",
        Roles: "Vloge",
        Users: "Uporabniki",
        Role: "Vloga",
        User: "Uporabnik",
        RoleIdColumn: "ID",
        RoleNameColumn: "Ime",
        RoleDescriptionColumn: "Opis",
        RoleHasValueColumn: "Ima vrednost?",
        RoleValueColumn: "Vrednost",
        UserIdColumn: "ID",
        UserUserNameColumn: "Uporabniško ime",
        UserNameColumn: "Ime",
        UserHasValueColumn: "Ima vrednost?",
        UserValueColumn: "Vrednost",
        ValueNotDefined: "Vrednost ni določena",
        Value: "Vrednost",
        SetValue: "Nastavi vrednost",
        ClearValue: "Počisti vrednost",
    },
    UserManagement: {
        Basic: "Osnovno",
        Permissions: "Dovoljenja",
        Users: "Uporabniki",
        Roles: "Vloge",
        Menu: "Meni",
        Settings: "Nastavitve",
        RoleId: "ID",
        RoleName: "Ime",
        RoleDescription: "Opis",
        UserId: "ID",
        UserUserName: "Uporabniško ime",
        UserName: "Ime",
        UserEmail: "E-pošta",
        UserCulture: "Kultura",
        UserPassword: "Geslo",
        UserLockout: "Zaklenjeno?",
        Assigned: "Dodeljeno?",
        SubstructurePlaceholder: "Izberite podstrukturo",
        SearchAllStructuresPlaceholder: "Išči vse strukture...",
        PermissionsIdColumn: "ID",
        PermissionsNameColumn: "Ime",
        PermissionsDescriptionColumn: "Opis",
        PermissionsIsSelectedColumn: "Dovoljenje",
        PermissionsAssigned: "Dodeljeno",
        PermissionsAssignedExplicit: "Dodeljeno - eksplicitno",
        PermissionsAssignedImplicit: "Dodeljeno - implicitno",
        PermissionsAssignedInherited: "Dodeljeno - podedovano",
        PermissionsNotAssigned: "Ni dodeljeno",
        PermissionsNotAssignedExplicit: "Ni dodeljeno - eksplicitno",
        PermissionsNotAssignedInherited: "Ni dodeljeno - podedovano",
        PermissionsYesImplied: "Da (predpostavljeno)",
        PermissionsImpliedBy: "Predpostavljeno s strani:",
        PermissionsInheritedFrom: "Podedovano od:",
        PermissionsImplied: "predpostavljeno",
        PermissionsInherited: "podedovano",
        MenuDefault: "Privzeti meni",
        MenuCustom: "Prilagojen meni",
        MenuRemove: "Odstrani",
        MenuMoveUp: "Premakni navzgor",
        MenuMoveDown: "Premakni navzdol",
        MenuRename: "Preimenuj",
        MenuAddItem: "Dodaj element",
        MenuAddSubmenu: "Dodaj podmeni",
        MenuDefaultSelected: "Izbran je privzeti meni.",
        MenuAddSubmenuTitle: "Dodaj podmeni",
        MenuAddSubmenuText: "Vnesite ime in izberite možnost 'Dodaj'.",
        MenuAddSubmenuSubmit: "Dodaj",
        MenuRenameTitle: "Preimenuj meni",
        MenuRenameText: "Vnesite ime in izberite možnost 'Shrani'",
        MenuRenameSubmit: "Shrani",
        Setting: "Nastavitev",
        SettingsIdColumn: "ID",
        SettingsNameColumn: "Ime",
        SettingsDescriptionColumn: "Opis",
        SettingsHasValueColumn: "Ima vrednost?",
        SettingsValueColumn: "Vrednost",
        SettingsIsPrivateColumn: "Zasebno?",
        SettingsIsAllowedForSystemColumn: "Sistem?",
        SettingsIsAllowedForRoleColumn: "Vloga?",
        SettingsIsAllowedForUserColumn: "Uporabnik?",
    },
    ConnectedApps: {
        CreateMessage:
            "Ko dodelite dovoljenja povezani aplikaciji in jih shranite, boste lahko dodali dostopne ključe in ustvarili dokumentacijo OpenAPI.",
        ShowOnlyAssigned: "Prikaži samo dodeljene",
        IdColumn: "ID",
        NameColumn: "Ime",
        DescriptionColumn: "Opis",
        DocumentationFormat: "Format dokumentacije",
    },
    DatePicker: {
        Today: "Danes",
        January: "Januar",
        February: "Februar",
        March: "Marec",
        April: "April",
        May: "Maj",
        June: "Junij",
        July: "Julij",
        August: "Avgust",
        September: "September",
        October: "Oktober",
        November: "November",
        December: "December",
        JanuaryShort: "Jan",
        FebruaryShort: "Feb",
        MarchShort: "Mar",
        AprilShort: "Apr",
        MayShort: "Maj",
        JuneShort: "Jun",
        JulyShort: "Jul",
        AugustShort: "Avg",
        SeptemberShort: "Sep",
        OctoberShort: "Okt",
        NovemberShort: "Nov",
        DecemberShort: "Dec",
        Monday: "Ponedeljek",
        Tuesday: "Torek",
        Wednesday: "Sreda",
        Thursday: "Četrtek",
        Friday: "Petek",
        Saturday: "Sobota",
        Sunday: "Nedelja",
        MondayShort: "Pon",
        TuesdayShort: "Tor",
        WednesdayShort: "Sre",
        ThursdayShort: "Čet",
        FridayShort: "Pet",
        SaturdayShort: "Sob",
        SundayShort: "Ned",
    },
    FileUpload: {
        SelectFilesAndFoldersSingular: "Povlecite datoteko, mapo ali izberite možnost.",
        SelectFilesAndFoldersPlural: "Povlecite datoteke, mape ali izberite možnost.",
        SelectFilesSingular: "Povlecite datoteko ali izberite možnost.",
        SelectFilesPlural: "Povlecite datoteke ali izberite možnost.",
        SelectFoldersSingular: "Povlecite mapo ali izberite možnost.",
        SelectFoldersPlural: "Povlecite mape ali izberite možnost.",
        Drop: "Spustite za dodajanje.",
        Select: "Izberite",
        File: "datoteko",
        Files: "datoteke",
        Folder: "mapo",
        IncorrectName: "Napačno ime.",
        FileAreadyExists: "Datoteka s tem imenom že obstaja.",
        FolderAlreadyExists: "Mapa s tem imenom že obstaja.",
    },
    FileUploadModal: {
        Title: "Prenos datotek",
    },
    ModalPdfViewer: {
        DefaultName: "Dokument",
        Loading: "Nalaganje dokumenta...",
    },
    Pagination: {
        First: "Prva",
        Previous: "Prejšnja",
        Next: "Naslednja",
        Last: "Zadnja",
    },
    MultiStepContent: {
        Back: "Nazaj",
        Next: "Naprej",
        Complete: "Končaj",
    },
    DataEntryForm: {
        DataLoading: "Nalaganje podatkov...",
        Success: "Podatki so uspešno shranjeni.",
        AlreadyExistsError: "Vnos s podano kodo že obstaja.",
        AlreadyExistsMessage:
            "Vnos s podano kodo že obstaja v sistemu. Lahko vnesete drugo kodo ali pa greste na pregled ali urejanje.",
        AlreadyExistsUpdate: "Urejanje",
        AlreadyExistsRead: "Pregled",
    },
};
