import { DatePicker, IDatePickerProps } from "@in-core";
import { FormValue } from "..";

export interface IFormDatePickerProps extends Omit<IDatePickerProps, "value"> {
    value: FormValue<Date>;
    ignoreDirty?: boolean;
}

const FormDatePicker = (props: IFormDatePickerProps) => {
    return (
        <DatePicker
            {...props}
            value={props.value.$value}
            onSelectDate={(newValue) => {
                props.value.$setValue(newValue);
                props.onSelectDate && props.onSelectDate(newValue);
            }}
            errorMessage={
                (!!props.ignoreDirty ? true : props.value.$isDirty) &&
                !props.value.$isPendingValidation &&
                !props.value.$isValidating &&
                props.value.$errors.length > 0
                    ? props.value.$errors.join(" ")
                    : undefined
            }
        />
    );
};

export default FormDatePicker;
