import {
    ActionButton,
    DataTable,
    Link,
    Panel,
    PanelType,
    SelectionMode,
    Spinner,
    Stack,
    UseForm,
    useInCoreLocalization,
    useOnMount,
    useTheme,
} from "@in-core";
import { DataType } from "@in-core/api/Entity";
import { GetSettings } from "@in-core/api/Settings";
import { useState } from "react";
import UserSettingDetails from "./UserSettingDetails";

export interface IUserSettingsProps {
    form: UseForm;
    roles: any[];
}

const UserSettings = (props: IUserSettingsProps) => {
    const getSettingsApi = GetSettings.useApi();
    const [currentSettingId, setCurrentSettingId] = useState<string>();
    const theme = useTheme();
    const localization = useInCoreLocalization();

    const form = props.form as any;

    useOnMount(() => {
        getSettingsApi.call({});
    });

    if (!getSettingsApi.data) {
        return <Spinner />;
    }

    const data = getSettingsApi.data
        .filter((x) => {
            return x.IsAllowedForUser;
        })
        .map((x) => {
            const value = form.Settings.$value?.find((y: any) => {
                return y.SettingId === x.Id;
            })?.Value;

            return { ...x, HasValue: value !== undefined, Value: value ?? "" };
        });

    const currentSetting = getSettingsApi.data!.find((x) => {
        return x.Id === currentSettingId;
    });

    return (
        <Stack styles={{ root: { height: "100%" } }}>
            <DataTable
                data={data}
                columns={[
                    {
                        id: "Edit",
                        name: "",
                        onRender: (item) => {
                            return (
                                <ActionButton
                                    iconProps={{ iconName: "Edit" }}
                                    styles={{ root: { height: "auto", padding: 0 } }}
                                    onClick={() => {
                                        setCurrentSettingId(item.Id);
                                    }}
                                >
                                    {localization.Edit}
                                </ActionButton>
                            );
                        },
                        width: 100,
                        isSortable: false,
                        isSearchable: false,
                        isFilterable: false,
                        isResizable: false,
                    },
                    {
                        id: "Id",
                        property: "Id",
                        name: localization.UserManagement.SettingsIdColumn,
                        dataType: DataType.String,
                        onRender: (item) => {
                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        setCurrentSettingId(item.Id);
                                    }}
                                >
                                    {item.Id}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "Name",
                        property: "Name",
                        name: localization.UserManagement.SettingsNameColumn,
                        dataType: DataType.String,
                        onRender: (item) => {
                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        setCurrentSettingId(item.Id);
                                    }}
                                >
                                    {item.Name}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "Description",
                        property: "Description",
                        name: localization.UserManagement.SettingsDescriptionColumn,
                        dataType: DataType.String,
                        width: 300,
                        isSortable: false,
                    },
                    {
                        id: "HasValue",
                        property: "HasValue",
                        name: localization.UserManagement.SettingsHasValueColumn,
                        dataType: DataType.Boolean,
                        isSortable: false,
                        width: 100,
                        isResizable: false,
                    },
                    {
                        id: "Value",
                        property: "Value",
                        name: localization.UserManagement.SettingsValueColumn,
                        dataType: DataType.String,
                        isSortable: false,
                        width: 300,
                    },
                    {
                        id: "IsPrivate",
                        property: "IsPrivate",
                        name: localization.UserManagement.SettingsIsPrivateColumn,
                        dataType: DataType.Boolean,
                        isSortable: false,
                        width: 100,
                        isResizable: false,
                    },
                    {
                        id: "IsAllowedForSystem",
                        property: "IsAllowedForSystem",
                        name: localization.UserManagement.SettingsIsAllowedForSystemColumn,
                        dataType: DataType.Boolean,
                        isSortable: false,
                        width: 100,
                        isResizable: false,
                    },
                    {
                        id: "IsAllowedForRole",
                        property: "IsAllowedForRole",
                        name: localization.UserManagement.SettingsIsAllowedForRoleColumn,
                        dataType: DataType.Boolean,
                        isSortable: false,
                        width: 100,
                        isResizable: false,
                    },
                ]}
                group={{ Id: "GroupName" }}
                hideToolbar
                disablePagination
                disableAggregating
                selectionMode={SelectionMode.none}
                defaultSort={{ Id: "Name", IsDescending: false }}
            />

            <Panel
                isOpen={currentSettingId !== undefined}
                headerText={
                    form.Description.$value
                        ? `${form.Description.$value} - ${localization.UserManagement.Setting} ${currentSetting?.Name}`
                        : `${localization.UserManagement.Setting} ${currentSetting?.Name}`
                }
                type={PanelType.medium}
                onDismiss={() => {
                    setCurrentSettingId(undefined);
                }}
                styles={{
                    scrollableContent: {
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                    },
                    content: {
                        flex: 1,
                    },
                }}
            >
                {currentSetting && (
                    <UserSettingDetails
                        setting={currentSetting}
                        value={
                            form.Settings.$value?.find((x: any) => {
                                return x.SettingId === currentSettingId;
                            })?.Value
                        }
                        onChange={(newValue) => {
                            const newSettingValues =
                                form.Settings.$value
                                    ?.filter((x: any) => {
                                        return x.SettingId !== currentSettingId;
                                    })
                                    .map((x: any) => {
                                        return { ...x };
                                    }) ?? [];

                            if (newValue !== undefined) {
                                newSettingValues.push({ SettingId: currentSettingId, Value: newValue });
                            }

                            form.Settings.$setValue(newSettingValues);
                        }}
                        onOk={() => {
                            setCurrentSettingId(undefined);
                        }}
                        roles={props.roles}
                    />
                )}
            </Panel>
        </Stack>
    );
};

export default UserSettings;
