import React, { useRef, useState } from "react";
import {
    DefaultButton,
    Icon,
    IStackStyles,
    PrimaryButton,
    Stack,
    Text,
    useInCoreLocalization,
    useOnChange,
    useTheme,
} from "@in-core";
import Content from "./Content";
import { NewContentInfo, NewDirectoryInfo, NewFileInfo } from "@in-core/api/Documents";

export const fileNameRegex = /^(?!(\.|\.\.)$)[^/\\:*?"<>|]+$/;

const getName = (initialName: string, existingNames?: string[]) => {
    if (!existingNames) {
        return initialName;
    }

    let name = initialName;

    let i = 1;
    while (existingNames.includes(name)) {
        name = `${initialName} (${i})`;
        i++;
    }

    return name;
};

// @ts-ignore
const getResultItemForFile = (file: File, existingNames?: string[]) => {
    return new Promise<NewFileInfo>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            return resolve({
                Name: getName(file.name, existingNames),
                ContentBase64: (reader.result as string).split(",").slice(1).join(""),
            });
        };
        reader.onerror = (error) => {
            return reject(error);
        };
    });
};

const getResultItemForDirectory = (
    // @ts-ignore
    fileSystemEntry: FileSystemEntry,
    existingNames?: string[],
) => {
    return new Promise<NewDirectoryInfo>((resolve, reject) => {
        // @ts-ignore
        var reader = fileSystemEntry.createReader();
        // @ts-ignore
        reader.readEntries(async (entries: FileSystemEntry[]) => {
            const files: NewFileInfo[] = [];
            const directories: NewDirectoryInfo[] = [];

            for (let i = 0; i < entries.length; i++) {
                const entry = entries[i];

                if (entry.isFile) {
                    // @ts-ignore
                    entry.file(async (file: File) => {
                        return files.push(await getResultItemForFile(file));
                    });
                } else if (entry.isDirectory) {
                    directories.push(await getResultItemForDirectory(entry));
                }
            }

            resolve({
                Name: getName(fileSystemEntry.name, existingNames),
                Subdirectories: directories,
                Files: files,
            });
        });
    });
};

const getWithouFile = (
    directories: NewDirectoryInfo[],
    files: NewFileInfo[],
    path: string[],
    file: NewFileInfo,
): [NewDirectoryInfo[], NewFileInfo[]] => {
    if (path.length === 0) {
        return [
            [...directories],
            files.filter((x) => {
                return x !== file;
            }),
        ];
    }

    return [
        directories.map((x) => {
            if (x.Name !== path[0]) {
                return x;
            }

            const [newSubdirectories, newFiles] = getWithouFile(x.Subdirectories, x.Files, path.slice(1), file);

            return {
                Name: x.Name,
                Subdirectories: newSubdirectories,
                Files: newFiles,
            };
        }),
        [...files],
    ];
};

const getWithoutDirectory = (
    directories: NewDirectoryInfo[],
    files: NewFileInfo[],
    path: string[],
    directory: NewDirectoryInfo,
): [NewDirectoryInfo[], NewFileInfo[]] => {
    if (path.length === 0) {
        return [
            directories.filter((x) => {
                return x !== directory;
            }),
            [...files],
        ];
    }

    return [
        directories.map((x) => {
            if (x.Name !== path[0]) {
                return x;
            }

            const [newSubdirectories, newFiles] = getWithoutDirectory(
                x.Subdirectories,
                x.Files,
                path.slice(1),
                directory,
            );

            return {
                Name: x.Name,
                Subdirectories: newSubdirectories,
                Files: newFiles,
            };
        }),
        [...files],
    ];
};

export interface IFileUploadProps {
    acceptDirectories?: boolean;
    acceptFiles?: boolean;
    allowMultiple?: boolean;
    allowedExtensions?: string[];
    rootStackStyles?: IStackStyles;
    contentChanged?: (result: NewContentInfo) => void;
    disabled?: boolean;
    existingNames?: string[];
    disableRename?: boolean;
}

const FileUpload = (props: IFileUploadProps) => {
    const [isDragging, setIsDragging] = useState(false);
    const [result, setResult] = useState<NewContentInfo>({
        Directories: [],
        Files: [],
    });
    const directoryInputRef = useRef<HTMLInputElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const theme = useTheme();
    const localization = useInCoreLocalization();

    const allowMultiple = props.allowMultiple ?? false;
    const acceptDirectories = allowMultiple ? props.acceptDirectories ?? false : false;
    const acceptFiles = props.acceptFiles ?? true;
    const disableRename = props.disableRename ?? false;

    useOnChange(() => {
        props.contentChanged && props.contentChanged(result);
    }, [result]);

    const removeFile = (path: string[], file: NewFileInfo) => {
        const [newDirectories, newFiles] = getWithouFile(result.Directories, result.Files, path, file);

        setResult({
            Directories: newDirectories,
            Files: newFiles,
        });
    };

    const removeDirectory = (path: string[], directory: NewDirectoryInfo) => {
        const [newDirectories, newFiles] = getWithoutDirectory(result.Directories, result.Files, path, directory);

        setResult({
            Directories: newDirectories,
            Files: newFiles,
        });
    };

    const duplicateDirectories = (directories: NewDirectoryInfo[]): NewDirectoryInfo[] => {
        return directories.map((x) => {
            return {
                Name: x.Name,
                Subdirectories: duplicateDirectories(x.Subdirectories),
                Files: duplicateFiles(x.Files),
            };
        });
    };

    const duplicateFiles = (files: NewFileInfo[]): NewFileInfo[] => {
        return [...files];
    };

    const updateResult = () => {
        setResult({
            Directories: duplicateDirectories(result.Directories),
            Files: duplicateFiles(result.Files),
        });
    };

    const message = acceptDirectories
        ? acceptFiles
            ? allowMultiple
                ? localization.FileUpload.SelectFilesAndFoldersPlural
                : localization.FileUpload.SelectFilesAndFoldersSingular
            : allowMultiple
            ? localization.FileUpload.SelectFoldersPlural
            : localization.FileUpload.SelectFoldersSingular
        : acceptFiles
        ? allowMultiple
            ? localization.FileUpload.SelectFilesPlural
            : localization.FileUpload.SelectFilesSingular
        : allowMultiple
        ? ""
        : "";

    return (
        <Stack tokens={{ padding: theme.spacing.m }} styles={props.rootStackStyles}>
            <div
                style={{
                    backgroundColor: props.disabled
                        ? theme.palette.neutralLighter
                        : isDragging
                        ? theme.palette.themeLighter
                        : undefined,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderStyle: "dashed",
                    borderWidth: 4,
                    borderColor: props.disabled ? theme.palette.neutralTertiary : theme.palette.themeTertiary,
                    padding: theme.spacing.s1,
                }}
                onDrop={async (e) => {
                    e.preventDefault();

                    setIsDragging(false);

                    if (props.disabled || !e.dataTransfer?.items) {
                        return;
                    }

                    const newResult: NewContentInfo = {
                        Directories: [...result.Directories],
                        Files: [...result.Files],
                    };

                    let items = Array.from(e.dataTransfer.items).map((x) => {
                        return x.webkitGetAsEntry();
                    });

                    let files = Array.from(e.dataTransfer.items).map((x) => {
                        return x.getAsFile();
                    });

                    if (allowMultiple) {
                        for (let i = 0; i < items.length; i++) {
                            const item = items[i];

                            if (!item) {
                                continue;
                            }

                            if (
                                item.isDirectory &&
                                !newResult.Directories.find((x) => {
                                    return x.Name === item.name;
                                }) &&
                                acceptDirectories
                            ) {
                                newResult.Directories.push(await getResultItemForDirectory(item, props.existingNames));
                            }

                            if (
                                item.isFile &&
                                !newResult.Files.find((x) => {
                                    return x.Name === item.name;
                                }) &&
                                acceptFiles
                            ) {
                                const file = files[i];
                                if (!file) {
                                    continue;
                                }

                                newResult.Files.push(await getResultItemForFile(file, props.existingNames));
                            }
                        }
                    } else if (items[0]) {
                        const item = items[0];
                        const file = files[0];

                        if (item.isDirectory && acceptDirectories) {
                            newResult.Directories = [await getResultItemForDirectory(item, props.existingNames)];
                        }

                        if (item.isFile && acceptFiles && file) {
                            newResult.Files = [await getResultItemForFile(file, props.existingNames)];
                        }
                    }

                    setResult(newResult);
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                }}
                onDragEnter={(e) => {
                    e.stopPropagation();

                    let isActualDragEnter = false;
                    for (let i = 0; i < e.dataTransfer.items.length; i++) {
                        if (e.dataTransfer.items[i].kind === "file") {
                            isActualDragEnter = true;
                            break;
                        }
                    }

                    if (isActualDragEnter) {
                        setIsDragging(true);
                    }
                }}
                onDragLeave={(e) => {
                    e.stopPropagation();

                    setIsDragging(false);
                }}
            >
                {isDragging ? (
                    <>
                        <Icon
                            iconName="Upload"
                            styles={{
                                root: {
                                    fontSize: 32,
                                    color: props.disabled ? theme.palette.neutralPrimary : theme.palette.themePrimary,
                                    pointerEvents: "none",
                                    marginBottom: theme.spacing.s1,
                                },
                            }}
                        />

                        <Text
                            variant="mediumPlus"
                            styles={{
                                root: {
                                    zIndex: 1,
                                    pointerEvents: "none",
                                    color: props.disabled ? theme.palette.neutralSecondary : theme.palette.themeDarker,
                                },
                            }}
                        >
                            {localization.FileUpload.Drop}
                        </Text>
                    </>
                ) : (
                    <>
                        <Text
                            variant="mediumPlus"
                            styles={{
                                root: {
                                    marginBottom: theme.spacing.s1,
                                    zIndex: 1,
                                    pointerEvents: "none",
                                    color: props.disabled ? theme.palette.neutralSecondary : theme.palette.themeDarker,
                                },
                            }}
                        >
                            {message}
                        </Text>

                        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
                            {acceptFiles && (
                                <PrimaryButton
                                    styles={{
                                        root: {
                                            zIndex: 1,
                                            pointerEvents: isDragging ? "none" : undefined,
                                        },
                                    }}
                                    onClick={() => {
                                        fileInputRef.current?.click();
                                    }}
                                    disabled={props.disabled}
                                >
                                    {localization.FileUpload.Select}{" "}
                                    {allowMultiple ? localization.FileUpload.Files : localization.FileUpload.File}
                                </PrimaryButton>
                            )}

                            {acceptDirectories && (
                                <DefaultButton
                                    primary
                                    styles={{
                                        root: {
                                            zIndex: 1,
                                            pointerEvents: isDragging ? "none" : undefined,
                                        },
                                    }}
                                    onClick={() => {
                                        directoryInputRef.current?.click();
                                    }}
                                    disabled={props.disabled}
                                >
                                    {localization.FileUpload.Select} {localization.FileUpload.Folder}
                                </DefaultButton>
                            )}
                        </Stack>
                    </>
                )}
            </div>

            <Stack>
                <Content
                    directories={result.Directories}
                    files={result.Files}
                    path={[]}
                    onRemoveDirectory={removeDirectory}
                    onRemoveFile={removeFile}
                    forceUpdate={updateResult}
                    additionalExistingNames={props.existingNames}
                    disableRename={disableRename}
                />
            </Stack>

            {acceptFiles && (
                <input
                    ref={fileInputRef}
                    type="file"
                    multiple={allowMultiple}
                    style={{ display: "none" }}
                    disabled={props.disabled}
                    accept={props.allowedExtensions
                        ?.map((x) => {
                            return x.startsWith(".") ? x : "." + x;
                        })
                        .join(",")}
                    onChange={async (e) => {
                        if (!e.target.files) {
                            return;
                        }

                        const newFiles: NewFileInfo[] = [];

                        for (let i = 0; i < e.target.files.length; i++) {
                            newFiles.push(await getResultItemForFile(e.target.files[i], props.existingNames));
                        }

                        setResult({
                            Directories: result.Directories,
                            Files: allowMultiple
                                ? [...result.Files, ...newFiles]
                                : newFiles.length === 0
                                ? result.Files
                                : [newFiles[0]],
                        });

                        // @ts-ignore
                        e.target.value = null;
                    }}
                />
            )}

            {/* eslint-disable */}
            {/* @ts-ignore */}
            {acceptDirectories && <input ref={directoryInputRef} type='file' webkitdirectory='' directory='' multiple style={{display: 'none'}} disabled={props.disabled}
                onChange={async (e) => {
                    if (!e.target.files) return;

                    const newDirectories: NewDirectoryInfo[] = [];

                    for (let i = 0; i < e.target.files.length; i++) {
                        const item = e.target.files[i];
                        // @ts-ignore
                        const path = item.webkitRelativePath.split('/');
                        const directoryPath = path.slice(0, -1);
                        const fileName = path[path.length - 1];

                        const fileNameSegments = fileName.split(".");
                        const allowedExtensions = props.allowedExtensions?.map((x) => x.startsWith(".") ? x.slice(1) : x);
                        if (fileNameSegments.length > 1 && allowedExtensions && !allowedExtensions.includes(fileNameSegments[fileNameSegments.length - 1])) {
                            continue;
                        }

                        let currentDirectory = newDirectories.find(x => x.Name === directoryPath[0]);
                        if (!currentDirectory) {
                            currentDirectory = { Name: getName(directoryPath[0], props.existingNames), Subdirectories: [], Files: []};
                            newDirectories.push(currentDirectory);
                        }

                        for (let i = 1; i < directoryPath.length; i++) {
                            let currentSubdirectory: NewDirectoryInfo | undefined = currentDirectory.Subdirectories.find(x => x.Name === directoryPath[i]);
                            if (!currentSubdirectory) {
                                currentSubdirectory = { Name: directoryPath[i], Subdirectories: [], Files: []};
                                currentDirectory.Subdirectories.push(currentSubdirectory);
                            }
                            currentDirectory = currentSubdirectory;
                        }

                        if (!currentDirectory.Files.find(x => x.Name === fileName)) {
                            currentDirectory.Files.push(await getResultItemForFile(item));
                        }
                    }

                    setResult({
                        Directories: allowMultiple 
                            ? [...result.Directories, ...newDirectories] 
                            : newDirectories.length === 0 
                            ? result.Directories 
                            : [newDirectories[0]],
                        Files: result.Files,
                    });
                }
            }
      />}
    </Stack>
  );
};

export default FileUpload;

