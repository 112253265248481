import {
    DataTableDropdown,
    EntityDataTable,
    EntityDataTablePanelSelect,
    IDataTableDropdownProps,
    IEntityDataTableProps,
    useInCoreLocalization,
    usePermissionService,
} from "@in-core";
import { GetDataRequest } from "@in-core/api";
import { GetData, GetEntityDescriptor } from "@in-core/api/Entity";
import useOnChange from "@in-core/hooks/useOnChange";
import { useCallback, useState } from "react";

export interface IEntityDataTableDropdownProps
    extends Omit<IDataTableDropdownProps, "columns" | "idProperties" | "descriptionProperty">,
        Omit<IEntityDataTableProps, "onChange"> {
    hideAddNew?: boolean;
}

const EntityDataTableDropdown = (props: IEntityDataTableDropdownProps) => {
    const getEntityDescriptorApi = GetEntityDescriptor.useApi();
    const permissionService = usePermissionService();
    const [selectedKeys, setSelectedKeys] = useState<any[] | null | undefined>(
        props.multiSelect
            ? props.selectedKeys
            : props.selectedKey !== undefined && props.selectedKey !== null
            ? [props.selectedKey]
            : props.selectedKey,
    );
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const localization = useInCoreLocalization();

    useOnChange(() => {
        getEntityDescriptorApi.call({ Permission: props.entity });
    }, [props.entity]);

    const getData = useCallback(
        async (request: GetDataRequest) => {
            const result = await GetData.callApi({ ...request, Permission: props.entity });
            return result.data!;
        },
        [props.entity],
    );

    const closeCreate = useCallback(() => {
        setIsCreateOpen(false);
    }, []);

    const successCreate = useCallback(
        (keys?: any[], data?: any) => {
            let key = keys![0];

            if ((getEntityDescriptorApi.data?.PrimaryKey?.PropertyIds.length ?? 0) === 1) {
                key = key[getEntityDescriptorApi.data!.PrimaryKey!.PropertyIds[0]];
            }

            const currentKeys = props.multiSelect
                ? props.selectedKeys ?? selectedKeys ?? []
                : props.selectedKey !== undefined
                ? props.selectedKey === null
                    ? []
                    : [props.selectedKey]
                : selectedKeys === null || selectedKeys === undefined || selectedKeys.length === 0
                ? []
                : selectedKeys.slice(0, 1);

            setSelectedKeys([key]);

            props.onChange &&
                props.onChange([
                    ...currentKeys.map((x) => {
                        return { key: x, isSelected: false };
                    }),
                    { key: key, isSelected: true },
                ]);

            setIsCreateOpen(false);
        },
        [selectedKeys, props.onChange, getEntityDescriptorApi.data],
    );

    return (
        <>
            <DataTableDropdown
                {...props}
                selectedKey={
                    props.selectedKey !== undefined
                        ? props.selectedKey
                        : props.multiSelect
                        ? undefined
                        : selectedKeys !== null && selectedKeys !== undefined
                        ? selectedKeys[0]
                        : selectedKeys
                }
                selectedKeys={props.selectedKeys ?? (props.multiSelect ? selectedKeys : undefined)}
                onChange={(changes) => {
                    if (!props.multiSelect) {
                        const selectedKey = changes.find((x) => {
                            return x.isSelected;
                        })?.key;

                        setSelectedKeys(selectedKey === undefined || selectedKey === null ? null : [selectedKey]);
                    } else {
                        setSelectedKeys((prevSelectedKeys) => {
                            return [
                                ...(prevSelectedKeys ?? []).filter((x) => {
                                    return !changes
                                        .filter((y) => {
                                            return !y.isSelected;
                                        })
                                        .map((y) => {
                                            return y.key;
                                        })
                                        .includes(x);
                                }),
                                ...changes
                                    .filter((x) => {
                                        return x.isSelected;
                                    })
                                    .map((x) => {
                                        return x.key;
                                    }),
                            ];
                        });
                    }

                    props.onChange && props.onChange(changes);
                }}
                columns={[]}
                data={getData}
                idProperties={getEntityDescriptorApi.data?.PrimaryKey?.PropertyIds}
                descriptionProperties={getEntityDescriptorApi.data?.DescriptionPropertyIds}
                renderDataTablePanelSelect={(dataTablePanelSelectProps) => {
                    return (
                        <EntityDataTablePanelSelect
                            {...dataTablePanelSelectProps}
                            columns={undefined}
                            entity={props.entity}
                        />
                    );
                }}
                additionalActions={
                    permissionService.isAuthorized(`${props.entity}.Create`) && props.hideAddNew !== true
                        ? [
                              {
                                  key: "Create",
                                  text: localization.EntityDataTableDropdown.AddNew,
                                  icon: "Add",
                                  onClick: () => {
                                      setIsCreateOpen(true);
                                  },
                              },
                          ]
                        : undefined
                }
            />

            {isCreateOpen && (
                <EntityDataTable
                    entity={props.entity}
                    displayedInnerPermission="Create"
                    onDisplayedInnerPermissionClose={closeCreate}
                    onDisplayedInnerPermissionSuccess={successCreate}
                />
            )}
        </>
    );
};

export default EntityDataTableDropdown;
