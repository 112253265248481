import React from "react";
import { ActionButton, IconButton, Stack, TooltipHost, useInCoreLocalization, useTheme } from "@in-core";

export interface IPaginationProps {
    dataCount: number;
    pageCount: number;
    currentPage: number;
    isDisabled?: boolean;
    onPageSelected?: (page: number) => void;
}

const Pagination = (props: IPaginationProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();

    const maxPageCount = Math.ceil(props.dataCount / props.pageCount);

    let prevCount = 0;
    if (props.currentPage - 2 >= 1) {
        prevCount = 2;
    } else if (props.currentPage - 1 >= 1) {
        prevCount = 1;
    }

    let nextCount = 0;
    if (props.currentPage + 2 <= maxPageCount) {
        nextCount = 2;
    } else if (props.currentPage + 1 <= maxPageCount) {
        nextCount = 1;
    }

    if (prevCount < 2) {
        while (nextCount + prevCount < 4 && props.currentPage + nextCount < maxPageCount) {
            nextCount++;
        }
    }

    if (nextCount < 2) {
        while (nextCount + prevCount < 4 && props.currentPage - prevCount > 1) {
            prevCount++;
        }
    }

    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
            <TooltipHost content={props.currentPage <= 1 ? undefined : localization.Pagination.First}>
                <IconButton
                    iconProps={{
                        iconName: "DoubleChevronLeft",
                        styles: { root: { fontSize: 11 } },
                    }}
                    styles={{
                        root: { width: 20 },
                        rootDisabled: { backgroundColor: "transparent" },
                    }}
                    disabled={props.currentPage <= 1 || props.isDisabled}
                    onClick={() => {
                        return props.onPageSelected && props.onPageSelected(1);
                    }}
                />
            </TooltipHost>

            <TooltipHost content={props.currentPage <= 1 ? undefined : localization.Pagination.Previous}>
                <IconButton
                    iconProps={{
                        iconName: "ChevronLeft",
                        styles: { root: { fontSize: 11 } },
                    }}
                    styles={{
                        root: { width: 20 },
                        rootDisabled: { backgroundColor: "transparent" },
                    }}
                    disabled={props.currentPage <= 1 || props.isDisabled}
                    onClick={() => {
                        return props.onPageSelected && props.onPageSelected(props.currentPage - 1);
                    }}
                />
            </TooltipHost>

            {Array.from({ length: prevCount }, (_, i) => {
                return i;
            })
                .reverse()
                .map((i) => {
                    return (
                        <ActionButton
                            key={i}
                            styles={{
                                root: { height: "auto", padding: "4px 8px" },
                                rootHovered: {
                                    backgroundColor: theme.palette.neutralLight,
                                },
                            }}
                            onClick={() => {
                                return props.onPageSelected && props.onPageSelected(props.currentPage - (i + 1));
                            }}
                            disabled={props.isDisabled}
                        >
                            {props.currentPage - (i + 1)}
                        </ActionButton>
                    );
                })}

            <ActionButton
                disabled
                styles={{
                    rootDisabled: {
                        backgroundColor: theme.palette.neutralLight,
                        color: theme.palette.themePrimary,
                        height: "auto",
                        padding: "4px 8px",
                    },
                }}
            >
                {props.currentPage}
            </ActionButton>

            {Array.from({ length: nextCount }, (_, i) => {
                return i;
            }).map((i) => {
                return (
                    <ActionButton
                        key={i}
                        styles={{
                            root: { height: "auto", padding: "4px 8px" },
                            rootHovered: {
                                backgroundColor: theme.palette.neutralLight,
                            },
                        }}
                        onClick={() => {
                            return props.onPageSelected && props.onPageSelected(props.currentPage + (i + 1));
                        }}
                        disabled={props.isDisabled}
                    >
                        {props.currentPage + (i + 1)}
                    </ActionButton>
                );
            })}

            <TooltipHost
                content={
                    props.currentPage * props.pageCount >= props.dataCount ? undefined : localization.Pagination.Next
                }
            >
                <IconButton
                    iconProps={{
                        iconName: "ChevronRight",
                        styles: { root: { fontSize: 11 } },
                    }}
                    styles={{
                        root: { width: 20 },
                        rootDisabled: { backgroundColor: "transparent" },
                    }}
                    disabled={props.currentPage * props.pageCount >= props.dataCount || props.isDisabled}
                    onClick={() => {
                        return props.onPageSelected && props.onPageSelected(props.currentPage + 1);
                    }}
                />
            </TooltipHost>

            <TooltipHost
                content={
                    props.currentPage * props.pageCount >= props.dataCount
                        ? undefined
                        : `${localization.Pagination.Last} (${maxPageCount})`
                }
            >
                <IconButton
                    iconProps={{
                        iconName: "DoubleChevronRight",
                        styles: { root: { fontSize: 11 } },
                    }}
                    styles={{
                        root: { width: 20 },
                        rootDisabled: { backgroundColor: "transparent" },
                    }}
                    disabled={props.currentPage * props.pageCount >= props.dataCount || props.isDisabled}
                    onClick={() => {
                        return props.onPageSelected && props.onPageSelected(maxPageCount);
                    }}
                />
            </TooltipHost>
        </Stack>
    );
};

export default Pagination;
