import { callApi as callGenericApi, useApi as useGenericApi } from "@in-core";

export namespace GetMenu {
    export const URL = "CoreAPI/Shell/GetMenu";

    export interface Request {}

    export interface MenuItem {
        Id: string;
        Text?: string;
        PermissionId?: string;
        Submenus?: MenuItem[];
    }

    export const callApi = (request: any) => {
        return callGenericApi<Request, MenuItem[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, MenuItem[]>(URL);
    };
}

export namespace GetUserInfo {
    export const URL = "CoreAPI/Shell/GetUserInfo";

    export interface Request {}

    export interface UserInfo {
        UserId: any;
        UserName?: string;
        Email?: string;
        FullName?: string;
        Culture?: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, UserInfo>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, UserInfo>(URL);
    };
}

export namespace LogActivity {
    export const URL = "CoreAPI/Shell/LogActivity";

    export interface Request {
        Keys?: any[];
        Data?: any;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, void>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, void>(URL);
    };
}

export namespace LogError {
    export const URL = "CoreAPI/Shell/LogError";

    export interface Request {
        Error?: any;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, void>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, void>(URL);
    };
}
