import { Checkbox, ICheckboxProps } from "@in-core";
import { FormValue } from "..";

export interface IFormCheckboxProps extends Omit<ICheckboxProps, "checked"> {
    value: FormValue<boolean>;
    ignoreDirty?: boolean;
}

const FormCheckbox = (props: IFormCheckboxProps) => {
    return (
        <Checkbox
            {...props}
            checked={props.value.$value ?? false}
            onChange={(e, newValue) => {
                props.value.$setValue(newValue);
                props.onChange && props.onChange(e, newValue);
            }}
        />
    );
};

export default FormCheckbox;
