import { useState } from "react";
import {
    DirectionalHint,
    FocusZone,
    FocusZoneDirection,
    IconButton,
    ScrollablePane,
    SearchBox,
    Spinner,
    SpinnerSize,
    Stack,
    TooltipHost,
    useInCoreLocalization,
} from "@in-core";
import ListView from "./ListView";
import MenuView from "./MenuView";
import { IMenuItem, IRoutableItem } from "@in-core/layouts";

const defaultIconSize = 18;

export interface ISidebarProps {
    isHidden: boolean;
    routableItems: IRoutableItem[];
    menuItems?: IMenuItem[];
    hideSearchAndListView?: boolean;
    homeIcon?: string;
    homeText?: string;
    homeHidden?: boolean;
    documentsIcon?: string;
    documentsText?: string;
    documentsHidden?: boolean;
    iconSize?: number;
    onItemSelected?: () => void;
}

const Sidebar = (props: ISidebarProps) => {
    const [searchText, setSearchText] = useState<string>("");
    const [isListView, setIsListView] = useState(false);
    const localization = useInCoreLocalization();

    const isSearch = !!searchText?.trim();
    const iconSize = props.iconSize ?? defaultIconSize;

    return (
        <Stack
            style={{
                height: "100%",
                visibility: props.isHidden ? "hidden" : undefined,
                opacity: props.isHidden ? 0 : undefined,
                transition: "all 0.2s",
            }}
        >
            {props.hideSearchAndListView !== true && (
                <FocusZone
                    direction={FocusZoneDirection.vertical}
                    isCircularNavigation
                    style={{
                        height: isSearch ? "100%" : undefined,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Stack horizontal tokens={{ padding: 6, childrenGap: 8 }}>
                        <SearchBox
                            value={searchText}
                            onChange={(_, newValue) => {
                                setSearchText(newValue ?? "");
                            }}
                            placeholder={localization.Search}
                            autoComplete="off"
                            underlined
                            styles={{ root: { flex: 1 } }}
                        />

                        {!isSearch && (
                            <TooltipHost
                                content={isListView ? localization.MenuViewText : localization.ListViewText}
                                directionalHint={DirectionalHint.rightCenter}
                            >
                                <IconButton
                                    iconProps={{
                                        iconName: isListView ? "SecondaryNav" : "AllApps",
                                    }}
                                    onClick={() => {
                                        setSearchText("");
                                        setIsListView((prevIsListView) => {
                                            return !prevIsListView;
                                        });
                                    }}
                                />
                            </TooltipHost>
                        )}
                    </Stack>

                    {isSearch && (
                        <ScrollablePane
                            styles={{
                                root: {
                                    position: "relative",
                                    flex: 1,
                                },
                            }}
                        >
                            <ListView
                                routableItems={props.routableItems}
                                search={searchText}
                                iconSize={iconSize}
                                onItemSelected={props.onItemSelected}
                            />
                        </ScrollablePane>
                    )}
                </FocusZone>
            )}

            <ScrollablePane
                styles={{
                    root: {
                        position: "relative",
                        flex: 1,
                    },
                }}
            >
                {!isListView &&
                    !isSearch &&
                    (props.menuItems === undefined ? (
                        <Spinner
                            size={SpinnerSize.large}
                            label={localization.MenuLoadingText}
                            styles={{ root: { marginTop: 15 } }}
                        />
                    ) : (
                        <MenuView
                            menuItems={props.menuItems}
                            routableItems={props.routableItems}
                            iconSize={iconSize}
                            onItemSelected={props.onItemSelected}
                            homeHidden={props.homeHidden ?? false}
                            homeText={props.homeText}
                            homeIcon={props.homeIcon}
                            documentsHidden={props.documentsHidden ?? false}
                            documentsText={props.documentsText}
                            documentsIcon={props.documentsIcon}
                        />
                    ))}

                {isListView && !isSearch && (
                    <ListView
                        routableItems={props.routableItems}
                        search={searchText.trim() === "" ? undefined : searchText.trim()}
                        iconSize={iconSize}
                        onItemSelected={props.onItemSelected}
                    />
                )}
            </ScrollablePane>
        </Stack>
    );
};

export default Sidebar;
