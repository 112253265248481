import { useState } from "react";

const useToggle = (defaultValue: boolean): [value: boolean, toggleValue: (value?: boolean) => void] => {
    const [value, setValue] = useState<boolean>(defaultValue);

    const toggleValue = (value?: boolean) => {
        setValue((currentValue) => {
            return value === undefined ? !currentValue : value;
        });
    };

    return [value, toggleValue];
};

export default useToggle;
