import {
    Breadcrumb,
    DataTablePanelSelect,
    DefaultButton,
    Dialog,
    DialogFooter,
    IContextualMenuItem,
    INavLink,
    INavLinkGroup,
    Icon,
    IconButton,
    Link,
    MessageBar,
    Nav,
    Permission,
    PrimaryButton,
    ScrollablePane,
    Separator,
    Stack,
    Text,
    TextField,
    Toggle,
    generateUuid,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import { DataType } from "@in-core/api/Entity";
import { useRef, useState } from "react";
import { DragDropContext, Draggable, DraggingStyle, DropResult, Droppable } from "react-beautiful-dnd";

export interface IMenuItem {
    Id?: string;
    Text?: string;
    PermissionId?: string;
    Submenus?: IMenuItem[];
}

export interface IMenuManagerProps {
    menuItems?: IMenuItem[];
    defaultMenuItems: IMenuItem[];
    onMenuItemsChanged: (menuItems?: IMenuItem[]) => void;
    allowedPermissions: Permission[];
    disabled?: boolean;
}

const MenuManager = (props: IMenuManagerProps) => {
    const [menuPath, setMenuPath] = useState<IMenuItem[]>([]);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();
    const localization = useInCoreLocalization();
    const menuIds = useRef<Map<IMenuItem, string>>(new Map<IMenuItem, string>());
    const [isAddNewSubmenuDisplayed, setIsAddNewSubmenuDisplayed] = useState(false);
    const [newSubmenuText, setNewSubmenuText] = useState("");
    const [isAddPermissionsDisplayed, setIsAddPermissionsDisplayed] = useState(false);
    const [renamingMenu, setRenamingMenu] = useState<IMenuItem>();
    const [renameText, setRenameText] = useState("");

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        moveMenuItem(result.source.index, result.destination.index);
    };

    const getMenuId = (menuItem: IMenuItem) => {
        if (menuItem.Id) {
            return menuItem.Id;
        }

        if (!menuIds.current.has(menuItem)) {
            menuIds.current.set(menuItem, generateUuid());
        }

        return menuIds.current.get(menuItem)!;
    };

    const moveMenuItem = (fromIndex: number, toIndex: number) => {
        const result = Array.from(getCurrentMenuItems());
        const [removed] = result.splice(fromIndex, 1);
        result.splice(toIndex, 0, removed);

        updateMenuItems(result);
    };

    const removeMenuItem = (index: number) => {
        const result = Array.from(getCurrentMenuItems());
        const [removed] = result.splice(index, 1);
        menuIds.current.delete(removed);

        updateMenuItems(result);
    };

    const addSubmenu = (text: string) => {
        const result = [...getCurrentMenuItems(), { Text: text, PermissionId: undefined, Submenus: [] }];

        updateMenuItems(result);
    };

    const addBusinessFunctionalities = (keys: string[]) => {
        const result = [
            ...getCurrentMenuItems(),
            ...keys.map((x) => {
                return {
                    Text: undefined,
                    PermissionId: x,
                    Submenus: undefined,
                };
            }),
        ];

        updateMenuItems(result);
    };

    const changeMenuItemName = (menuItem: IMenuItem, text?: string) => {
        const result = Array.from(getCurrentMenuItems());
        const index = result.indexOf(menuItem);
        result.splice(index, 1);
        menuIds.current.delete(menuItem);

        const newMenuItem: IMenuItem = {
            Text: text === undefined || text.trim() === "" ? (menuItem.PermissionId ? undefined : "") : text,
            PermissionId: menuItem.PermissionId,
            Submenus: [...(menuItem.Submenus ?? [])],
        };

        result.splice(index, 0, newMenuItem);

        updateMenuItems(result);
    };

    const updateMenuItems = (newMenuItems: IMenuItem[]) => {
        if (menuPath.length === 0) {
            props.onMenuItemsChanged(newMenuItems);
            return;
        }

        menuPath[menuPath.length - 1].Submenus = newMenuItems;
        props.onMenuItemsChanged([...props.menuItems!]);
    };

    const getCurrentMenuItems = () => {
        return menuPath.length === 0 ? props.menuItems! : menuPath[menuPath.length - 1].Submenus!;
    };

    const actualMenuItems = props.menuItems
        ? getCurrentMenuItems().filter((x) => {
              return (
                  !x.PermissionId ||
                  props.allowedPermissions.find((y) => {
                      return y.CompleteId === x.PermissionId;
                  }) !== undefined
              );
          })
        : [];

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: (props.menuItems ?? props.defaultMenuItems)
                .filter((x) => {
                    return menuItemsFilter(x, props.allowedPermissions);
                })
                .map((x) => {
                    return _getMenuItemLink(x, props.allowedPermissions);
                }),
        },
    ];

    return (
        <Stack horizontal styles={{ root: { height: "100%" } }}>
            <ScrollablePane
                styles={{
                    root: { flex: 1, position: "relative" },
                }}
            >
                <Stack
                    tokens={{ childrenGap: theme.spacing.m }}
                    styles={{ root: { paddingTop: theme.spacing.s1, paddingBottom: theme.spacing.s1 } }}
                >
                    <Toggle
                        offText={localization.UserManagement.MenuDefault}
                        onText={localization.UserManagement.MenuCustom}
                        checked={props.menuItems !== undefined}
                        onChange={(_, checked) => {
                            props.onMenuItemsChanged(checked ? props.defaultMenuItems : undefined);
                        }}
                        disabled={props.disabled}
                    />

                    {props.menuItems ? (
                        <>
                            <Breadcrumb
                                items={[
                                    {
                                        key: "root",
                                        text: localization.UserManagement.Menu,
                                        onClick:
                                            menuPath.length === 0
                                                ? undefined
                                                : () => {
                                                      setMenuPath([]);
                                                  },
                                    },
                                    ...menuPath.map((x, i) => {
                                        return {
                                            key: `menu${i}`,
                                            text: x.Text ?? "",
                                            onClick:
                                                i === menuPath.length - 1
                                                    ? undefined
                                                    : () => {
                                                          setMenuPath((prevMenuPath) => {
                                                              return prevMenuPath.slice(0, i - 1);
                                                          });
                                                      },
                                        };
                                    }),
                                ]}
                                styles={{ root: { marginTop: 0 } }}
                            />

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(droppableProvided, snapshot) => {
                                        const offsetTop = containerRef.current?.getBoundingClientRect().top ?? 0;

                                        return (
                                            <div
                                                ref={(el) => {
                                                    droppableProvided.innerRef(el);
                                                    containerRef.current = el;
                                                }}
                                                {...droppableProvided.droppableProps}
                                                style={{ position: "relative" }}
                                            >
                                                {actualMenuItems.map((x, i) => {
                                                    const permission = props.allowedPermissions.find((y) => {
                                                        return y.CompleteId === x.PermissionId;
                                                    });

                                                    const defaultMenuItems: IContextualMenuItem[] = [
                                                        {
                                                            key: "remove",
                                                            text: localization.UserManagement.MenuRemove,
                                                            iconProps: {
                                                                iconName: "Delete",
                                                            },
                                                            onClick: () => {
                                                                return removeMenuItem(i);
                                                            },
                                                        },
                                                        {
                                                            key: "moveUp",
                                                            text: localization.UserManagement.MenuMoveUp,
                                                            iconProps: {
                                                                iconName: "ChevronUp",
                                                            },
                                                            disabled: i === 0,
                                                            onClick: () => {
                                                                return moveMenuItem(i, i - 1);
                                                            },
                                                        },
                                                        {
                                                            key: "moveDown",
                                                            text: localization.UserManagement.MenuMoveDown,
                                                            iconProps: {
                                                                iconName: "ChevronDown",
                                                            },
                                                            disabled: i === actualMenuItems.length - 1,
                                                            onClick: () => {
                                                                return moveMenuItem(i, i + 1);
                                                            },
                                                        },
                                                        {
                                                            key: "rename",
                                                            text: localization.UserManagement.MenuRename,
                                                            iconProps: {
                                                                iconName: "Rename",
                                                            },
                                                            onClick: () => {
                                                                setRenamingMenu(x);
                                                                setRenameText(x.Text ?? "");
                                                            },
                                                        },
                                                    ];

                                                    return (
                                                        <Draggable
                                                            key={getMenuId(x)}
                                                            draggableId={getMenuId(x)}
                                                            index={i}
                                                            isDragDisabled={props.disabled}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            alignItems: "center",
                                                                            ...provided.draggableProps.style,
                                                                            position: snapshot.isDragging
                                                                                ? "absolute"
                                                                                : undefined,
                                                                            top: snapshot.isDragging
                                                                                ? (
                                                                                      provided.draggableProps
                                                                                          .style as DraggingStyle
                                                                                  ).top - offsetTop
                                                                                : undefined,
                                                                            left: snapshot.isDragging ? 0 : undefined,
                                                                        }}
                                                                    >
                                                                        <div {...provided.dragHandleProps}>
                                                                            <Icon
                                                                                iconName="DragObject"
                                                                                styles={{
                                                                                    root: { fontSize: 24, padding: 4 },
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        {permission ? (
                                                                            <>
                                                                                <Icon
                                                                                    iconName={permission.Icon}
                                                                                    styles={{
                                                                                        root: {
                                                                                            fontSize: 18,
                                                                                            color: theme.palette
                                                                                                .neutralPrimary,
                                                                                            marginLeft: 4,
                                                                                            marginRight: 4,
                                                                                        },
                                                                                    }}
                                                                                />

                                                                                <Text styles={{ root: { flex: 1 } }}>
                                                                                    {x.Text ?? permission.Name}
                                                                                </Text>
                                                                            </>
                                                                        ) : (
                                                                            <Link
                                                                                onClick={() => {
                                                                                    setMenuPath((prevMenuPath) => {
                                                                                        return [...prevMenuPath, x];
                                                                                    });
                                                                                }}
                                                                                styles={{
                                                                                    root: {
                                                                                        "display": "flex",
                                                                                        "flexDirection": "row",
                                                                                        "alignItems": "center",
                                                                                        "marginLeft": 4,
                                                                                        "flex": 1,
                                                                                        "color":
                                                                                            theme.palette
                                                                                                .neutralPrimary,
                                                                                        ":hover": {
                                                                                            textDecoration: "none",
                                                                                        },
                                                                                        ":active": {
                                                                                            textDecoration: "none",
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                underline={false}
                                                                            >
                                                                                <Icon
                                                                                    iconName="Folder"
                                                                                    styles={{
                                                                                        root: {
                                                                                            fontSize: 18,
                                                                                            marginRight: 4,
                                                                                        },
                                                                                    }}
                                                                                />

                                                                                <Text
                                                                                    styles={{
                                                                                        root: {
                                                                                            textDecoration: "underline",
                                                                                            color: "inherit",
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    {x.Text}
                                                                                </Text>
                                                                            </Link>
                                                                        )}

                                                                        {!props.disabled && (
                                                                            <IconButton
                                                                                menuProps={{
                                                                                    items: [...defaultMenuItems],
                                                                                }}
                                                                                menuIconProps={{ iconName: "More" }}
                                                                                styles={{
                                                                                    root: {
                                                                                        color: theme.palette
                                                                                            .neutralPrimary,
                                                                                    },
                                                                                    menuIcon: {
                                                                                        fontSize: 16,
                                                                                    },
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                })}

                                                {droppableProvided.placeholder}
                                            </div>
                                        );
                                    }}
                                </Droppable>
                            </DragDropContext>

                            {!props.disabled && (
                                <Stack horizontal tokens={{ childrenGap: theme.spacing.m }}>
                                    <DefaultButton
                                        onClick={() => {
                                            setIsAddPermissionsDisplayed(true);
                                        }}
                                    >
                                        {localization.UserManagement.MenuAddItem}
                                    </DefaultButton>

                                    <DefaultButton
                                        onClick={() => {
                                            setIsAddNewSubmenuDisplayed(true);
                                        }}
                                    >
                                        {localization.UserManagement.MenuAddSubmenu}
                                    </DefaultButton>
                                </Stack>
                            )}
                        </>
                    ) : (
                        <MessageBar>{localization.UserManagement.MenuDefaultSelected}</MessageBar>
                    )}
                </Stack>
            </ScrollablePane>

            <Separator vertical />

            <ScrollablePane
                styles={{
                    root: { width: 300, position: "relative" },
                }}
            >
                <Nav
                    groups={navLinkGroups}
                    styles={{
                        root: { paddingTop: theme.spacing.s1, paddingBottom: theme.spacing.s1 },
                        linkText: {
                            color: theme.semanticColors.bodyText,
                        },
                        group: {
                            "> button": {
                                margin: 0,
                                height: 45,
                            },
                        },
                    }}
                />
            </ScrollablePane>

            <Dialog
                hidden={!isAddNewSubmenuDisplayed}
                dialogContentProps={{
                    title: localization.UserManagement.MenuAddSubmenuTitle,
                    subText: localization.UserManagement.MenuAddSubmenuText,
                }}
                onDismiss={() => {
                    setIsAddNewSubmenuDisplayed(false);
                    setNewSubmenuText("");
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        addSubmenu(newSubmenuText);
                        setIsAddNewSubmenuDisplayed(false);
                        setNewSubmenuText("");
                    }}
                >
                    <TextField
                        value={newSubmenuText}
                        onChange={(_, newValue) => {
                            return setNewSubmenuText(newValue || "");
                        }}
                    />

                    <DialogFooter>
                        <PrimaryButton type="submit" text={localization.UserManagement.MenuAddSubmenuSubmit} />

                        <DefaultButton
                            text={localization.Cancel}
                            onClick={() => {
                                setIsAddNewSubmenuDisplayed(false);
                                setNewSubmenuText("");
                            }}
                        />
                    </DialogFooter>
                </form>
            </Dialog>

            <Dialog
                hidden={!renamingMenu}
                dialogContentProps={{
                    title: localization.UserManagement.MenuRenameTitle,
                    subText: localization.UserManagement.MenuRenameText,
                }}
                onDismiss={() => {
                    setRenamingMenu(undefined);
                    setRenameText("");
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        changeMenuItemName(renamingMenu!, renameText);
                        setRenamingMenu(undefined);
                        setRenameText("");
                    }}
                >
                    <TextField
                        value={renameText}
                        placeholder={
                            props.allowedPermissions.find((x) => {
                                return x.CompleteId === renamingMenu?.PermissionId;
                            })?.Name ?? undefined
                        }
                        onChange={(_, newValue) => {
                            return setRenameText(newValue || "");
                        }}
                    />

                    <DialogFooter>
                        <PrimaryButton type="submit" text={localization.UserManagement.MenuRenameSubmit} />

                        <DefaultButton
                            text={localization.Cancel}
                            onClick={() => {
                                setRenamingMenu(undefined);
                                setRenameText("");
                            }}
                        />
                    </DialogFooter>
                </form>
            </Dialog>

            <DataTablePanelSelect
                headerText={localization.UserManagement.Permissions}
                isOpen={isAddPermissionsDisplayed}
                data={props.allowedPermissions.filter((x) => {
                    return x.Metadata["Ui"] === true || x.Metadata["SpecialType"] === "EntityDataBrowser";
                })}
                columns={[
                    {
                        id: "CompleteId",
                        property: "CompleteId",
                        dataType: DataType.String,
                        name: localization.UserManagement.PermissionsIdColumn,
                    },
                    {
                        id: "Name",
                        property: "Name",
                        dataType: DataType.String,
                        name: localization.UserManagement.PermissionsNameColumn,
                        onRender: (item) => {
                            return (
                                <Text
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                            display: "flex",
                                            alignItems: "center",
                                        },
                                    }}
                                >
                                    <Icon
                                        iconName={item.Icon}
                                        styles={{
                                            root: {
                                                fontSize: 16,
                                                color: theme.palette.themePrimary,
                                                marginRight: theme.spacing.s2,
                                            },
                                        }}
                                    />

                                    {item.Name}
                                </Text>
                            );
                        },
                    },
                    {
                        id: "Description",
                        property: "Description",
                        dataType: DataType.String,
                        name: localization.UserManagement.PermissionsDescriptionColumn,
                    },
                ]}
                idProperties={["CompleteId"]}
                onItemsSelected={(keys) => {
                    addBusinessFunctionalities(keys);
                    setIsAddPermissionsDisplayed(false);
                }}
                onDismiss={() => {
                    setIsAddPermissionsDisplayed(false);
                }}
                hideToolbar
            />
        </Stack>
    );
};

export default MenuManager;

const _getMenuItemLink = (item: IMenuItem, permissions: Permission[]): INavLink => {
    const permission = permissions.find((x) => {
        return x.CompleteId === item.PermissionId;
    });

    const itemLink: INavLink = {
        name: item.Text ?? permission?.Name ?? "",
        url: "",
    };

    if (item.PermissionId) {
        itemLink.icon = permission!.Icon;
    } else {
        itemLink.links = item.Submenus?.filter((x) => {
            return menuItemsFilter(x, permissions);
        }).map((x) => {
            return _getMenuItemLink(x, permissions);
        });
    }

    return itemLink;
};

const menuItemsFilter = (menuItem: IMenuItem, permissions: Permission[]): boolean => {
    return (
        menuItem.Submenus !== undefined ||
        (menuItem.PermissionId !== undefined &&
            permissions
                .map((x) => {
                    return x.CompleteId;
                })
                .includes(menuItem.PermissionId))
    );
};
