import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import {
    Breadcrumb,
    ConstrainMode,
    DetailsListLayoutMode,
    DetailsRow,
    DirectoryContent,
    IBreadcrumbItem,
    Icon,
    Link,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
    useOnChange,
    useTheme,
} from "@in-core";
import React, { useMemo, useState } from "react";

interface IFileExplorerListItem {
    type: "directory" | "file";
    name: string;
    timeAdded?: Date;
    addedBy: string;
    fileSize?: number;
}

export interface IDocumentLocationFileExplorerLocation {
    directoryPath: string[];
}

export interface IDocumentLocationFileExplorerProps {
    rootText?: string;
    onRenderRoot?: () => React.ReactElement | null;
    rootName?: string;

    refreshKey?: string;

    defaultLocation?: IDocumentLocationFileExplorerLocation;
    location?: IDocumentLocationFileExplorerLocation;
    onLocationChanged?: (location: IDocumentLocationFileExplorerLocation) => void;

    onGetDirectoryContent: (directoryPath: string[]) => Promise<DirectoryContent | null>;
}

const FileExplorer = (props: IDocumentLocationFileExplorerProps) => {
    const [internalLocation, setInternalLocation] = useState<IDocumentLocationFileExplorerLocation>(
        props.location ?? props.defaultLocation ?? { directoryPath: [] },
    );

    const [directoryContent, setDirectoryContent] = useState<DirectoryContent | null>();

    const [sort, setSort] = useState<"name" | "timeAdded" | "addedBy" | "fileSize">("name");
    const [isSortDescending, setIsSortDescending] = useState(false);
    const theme = useTheme();

    const directoryPath = (props.location ?? internalLocation).directoryPath;

    const refreshContent = async () => {
        setDirectoryContent(undefined);
        const newDirectoryContent = await props.onGetDirectoryContent(directoryPath);
        setDirectoryContent(newDirectoryContent);
    };

    const setCompleteLocation = (location: IDocumentLocationFileExplorerLocation) => {
        setInternalLocation(location);
        props.onLocationChanged && props.onLocationChanged(location);
    };

    useOnChange(() => {
        refreshContent();
    }, [directoryPath, props.refreshKey]);

    const onColumnClicked = (columnName: "name" | "timeAdded" | "addedBy" | "fileSize") => {
        return () => {
            if (sort !== columnName) {
                setSort(columnName);
                setIsSortDescending(false);
            } else {
                setIsSortDescending((prevIsSortDescending) => {
                    return !prevIsSortDescending;
                });
            }
        };
    };

    const breadcrumbItems: IBreadcrumbItem[] = [];

    breadcrumbItems.push({
        key: "__root",
        text: props.rootText ?? "/",
        onRenderContent: props.onRenderRoot
            ? () => {
                  return props.onRenderRoot!();
              }
            : undefined,
        onClick: props.onRenderRoot
            ? undefined
            : () => {
                  setCompleteLocation({ directoryPath: [] });
              },
    });

    breadcrumbItems.push(
        ...directoryPath.map((x, i) => {
            return {
                key: x,
                text: x,
                onClick: () => {
                    const newDirectoryPath = directoryPath.slice(0, i + 1);
                    setCompleteLocation({ directoryPath: newDirectoryPath });
                },
            };
        }),
    );

    const sortFunc = (a: IFileExplorerListItem, b: IFileExplorerListItem) => {
        if (sort === "addedBy" || sort === "name") {
            return isSortDescending ? b[sort].localeCompare(a[sort]) : a[sort].localeCompare(b[sort]);
        }

        const aValue = a[sort] ?? 0;
        const bValue = b[sort] ?? 0;
        return aValue < bValue ? (isSortDescending ? -1 : 1) : aValue === bValue ? 0 : isSortDescending ? 1 : -1;
    };

    const getFullDisplayName = (displayName: string | undefined, userName: string | undefined) => {
        if (displayName === undefined && userName === undefined) {
            return undefined;
        }

        if (displayName !== undefined && userName !== undefined) {
            return `${displayName} (@${userName})`;
        }

        return displayName ?? `@${userName}`;
    };

    const items: IFileExplorerListItem[] | null | undefined = useMemo(() => {
        return directoryContent === null
            ? null
            : directoryContent === undefined
            ? undefined
            : [
                  ...directoryContent.Directories.map((x) => {
                      return {
                          type: "directory",
                          name: x.Name,
                          timeAdded: x.TimeAdded,
                          addedBy: getFullDisplayName(x.UserDisplayName, x.UserName) ?? "Sustav",
                      } as IFileExplorerListItem;
                  }).sort(sortFunc),
                  ...directoryContent.Files.map((x) => {
                      return {
                          type: "file",
                          name: x.Name,
                          timeAdded: x.TimeAdded,
                          addedBy: getFullDisplayName(x.UserDisplayName, x.UserName) ?? "Sustav",
                          fileSize: x.Size,
                      } as IFileExplorerListItem;
                  }).sort(sortFunc),
              ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directoryContent]);

    return (
        <Stack styles={{ root: { height: "100%" } }}>
            <Breadcrumb
                items={breadcrumbItems}
                styles={{
                    root: { margin: 0, paddingLeft: theme.spacing.s1, paddingRight: theme.spacing.s1 },
                    itemLink: { borderRadius: theme.effects.roundedCorner2 },
                }}
            />

            {items === null ? (
                <MessageBar
                    messageBarType={MessageBarType.severeWarning}
                    styles={{ root: { margin: theme.spacing.s1, width: "auto" } }}
                >
                    Nije moguće pronaći traženi direktorij.
                </MessageBar>
            ) : (
                <Stack horizontal styles={{ root: { flex: 1 } }}>
                    <ScrollablePane styles={{ root: { position: "relative", flex: 1 } }}>
                        <ShimmeredDetailsList
                            shimmerLines={3}
                            enableShimmer={items === undefined}
                            onRenderRow={(renderProps, defaultRender) => {
                                return (
                                    <DetailsRow
                                        {...renderProps!}
                                        styles={{
                                            ...renderProps!.styles,
                                            cell: {
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                display: "flex",
                                                alignItems: "center",
                                            },
                                            root: {
                                                ":not(:hover) .document-more": { display: "none" },
                                            },
                                        }}
                                    />
                                );
                            }}
                            columns={[
                                {
                                    key: "fileType",
                                    name: "",
                                    minWidth: 16,
                                    maxWidth: 16,
                                    iconName: "Page",
                                    isIconOnly: true,
                                    onRender: (item: IFileExplorerListItem) => {
                                        return (
                                            <Icon
                                                {...getFileTypeIconProps({
                                                    type: item.type === "directory" ? 2 : undefined,
                                                    extension:
                                                        item.type === "directory" || item.name.split(".").length < 2
                                                            ? undefined
                                                            : item.name.split(".").reverse()[0],
                                                    size: 16,
                                                })}
                                            />
                                        );
                                    },
                                },
                                {
                                    key: "name",
                                    name: "Naziv",
                                    minWidth: 200,
                                    isResizable: true,
                                    isSorted: sort === "name",
                                    isSortedDescending: isSortDescending,
                                    onColumnClick: onColumnClicked("name"),
                                    onRender: (item: IFileExplorerListItem) => {
                                        return (
                                            <Stack
                                                horizontal
                                                verticalAlign="center"
                                                styles={{
                                                    root: {
                                                        flex: 1,
                                                    },
                                                }}
                                            >
                                                {item.type === "directory" ? (
                                                    <Link
                                                        onClick={() => {
                                                            const newDirectoryPath = [...directoryPath, item.name];
                                                            setCompleteLocation({
                                                                directoryPath: newDirectoryPath,
                                                            });
                                                            return;
                                                        }}
                                                        styles={{
                                                            root: {
                                                                "fontSize": 13,
                                                                "color": theme.palette.neutralPrimary,
                                                                "textDecoration": "underline",
                                                                ":hover": {
                                                                    color: theme.palette.themePrimary,
                                                                },
                                                                "flex": 1,
                                                            },
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                ) : (
                                                    <Text
                                                        styles={{
                                                            root: {
                                                                "fontSize": 13,
                                                                "color": theme.palette.neutralPrimary,
                                                                "textDecoration": "underline",
                                                                ":hover": {
                                                                    color: theme.palette.themePrimary,
                                                                },
                                                                "flex": 1,
                                                            },
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Text>
                                                )}
                                            </Stack>
                                        );
                                    },
                                },
                                {
                                    key: "timeAdded",
                                    name: "Dodano",
                                    minWidth: 160,
                                    maxWidth: 160,
                                    isSorted: sort === "timeAdded",
                                    isSortedDescending: isSortDescending,
                                    onColumnClick: onColumnClicked("timeAdded"),
                                    onRender: (item: IFileExplorerListItem) => {
                                        return (
                                            <Text
                                                styles={{
                                                    root: {
                                                        fontSize: 13,
                                                        color: theme.palette.neutralPrimary,
                                                    },
                                                }}
                                            >
                                                {item.timeAdded?.toLocaleString("hr")}
                                            </Text>
                                        );
                                    },
                                },
                                {
                                    key: "addedBy",
                                    name: "Dodao/la",
                                    minWidth: 120,
                                    isResizable: true,
                                    isSorted: sort === "addedBy",
                                    isSortedDescending: isSortDescending,
                                    onColumnClick: onColumnClicked("addedBy"),
                                    onRender: (item: IFileExplorerListItem) => {
                                        return (
                                            <Text
                                                styles={{
                                                    root: {
                                                        fontSize: 13,
                                                        color: theme.palette.neutralPrimary,
                                                    },
                                                }}
                                            >
                                                {item.addedBy}
                                            </Text>
                                        );
                                    },
                                },
                                {
                                    key: "fileSize",
                                    name: "Veličina",
                                    minWidth: 80,
                                    maxWidth: 80,
                                    isSorted: sort === "fileSize",
                                    isSortedDescending: isSortDescending,
                                    onColumnClick: onColumnClicked("fileSize"),
                                    onRender: (item: IFileExplorerListItem) => {
                                        return item.fileSize ? (
                                            <Text
                                                styles={{
                                                    root: {
                                                        fontSize: 13,
                                                        color: theme.palette.neutralPrimary,
                                                    },
                                                }}
                                            >
                                                {getFileSizeString(item.fileSize)}
                                            </Text>
                                        ) : null;
                                    },
                                },
                            ]}
                            items={items ?? []}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            constrainMode={ConstrainMode.unconstrained}
                            onItemInvoked={(item: IFileExplorerListItem) => {
                                if (item.type === "directory") {
                                    const newPath = [...directoryPath, item.name];
                                    setCompleteLocation({ directoryPath: newPath });
                                    return;
                                }
                            }}
                            onRenderDetailsHeader={(renderProps, defaultRender) => {
                                return (
                                    <Sticky stickyPosition={StickyPositionType.Header}>
                                        {defaultRender!(renderProps)}
                                    </Sticky>
                                );
                            }}
                        />
                    </ScrollablePane>
                </Stack>
            )}
        </Stack>
    );
};

export default FileExplorer;

const getFileSizeString = (size: number) => {
    if (size > 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
    if (size > 1024) {
        return `${(size / 1024).toFixed(2)} KB`;
    }
    return `${size} B`;
};
