import { Panel, PanelType, Stack, useInCoreLocalization, useTheme } from "@in-core";
import { ILayoutProps } from "..";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useMediaQuery } from "react-responsive";

export interface IDefaultLayoutProps extends ILayoutProps {
    hideSearchAndListView?: boolean;
    homeIcon?: string;
    homeText?: string;
    homeHidden?: boolean;
    documentsIcon?: string;
    documentsText?: string;
    documentsHidden?: boolean;
    iconSize?: number;
}

const DefaultLayout = (props: IDefaultLayoutProps) => {
    const isDesktop = useMediaQuery({ minWidth: 960 });
    const theme = useTheme();
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(!isDesktop);
    const localization = useInCoreLocalization();

    return (
        <Stack verticalFill>
            <Header
                title={props.titleProps.text}
                onMenuClicked={() => {
                    setIsSidebarCollapsed((prevIsSidebarCollapsed) => {
                        return !prevIsSidebarCollapsed;
                    });
                }}
                userInfo={props.userInfo}
            />

            <Stack
                verticalFill
                horizontal
                verticalAlign="stretch"
                style={{
                    overflow: "hidden",
                }}
            >
                {isDesktop ? (
                    <div
                        style={{
                            minWidth: isSidebarCollapsed ? 0 : 300,
                            maxWidth: isSidebarCollapsed ? 0 : 300,
                            height: "100%",
                            borderRightWidth: 1,
                            borderRightColor: theme.palette.neutralLight,
                            borderRightStyle: "solid",
                            transition: "all 0.2s",
                            position: "relative",
                        }}
                    >
                        <Sidebar
                            isHidden={isSidebarCollapsed}
                            routableItems={props.routableItems}
                            menuItems={props.menuItems}
                            hideSearchAndListView={props.hideSearchAndListView}
                            homeIcon={props.homeIcon}
                            homeText={props.homeText}
                            homeHidden={props.homeHidden}
                            documentsIcon={props.documentsIcon}
                            documentsText={props.documentsText}
                            documentsHidden={props.documentsHidden}
                            iconSize={props.iconSize}
                        />
                    </div>
                ) : (
                    <Panel
                        isOpen={!isSidebarCollapsed}
                        onDismiss={() => {
                            setIsSidebarCollapsed(true);
                        }}
                        styles={{
                            scrollableContent: { height: "100%", display: "flex", flexDirection: "column" },
                            content: { flex: 1, padding: 0 },
                        }}
                        type={PanelType.customNear}
                        customWidth="400px"
                        headerText={localization.Menu}
                        shouldKeepChildrenActive
                    >
                        <Sidebar
                            isHidden={false}
                            routableItems={props.routableItems}
                            menuItems={props.menuItems}
                            hideSearchAndListView={props.hideSearchAndListView}
                            homeIcon={props.homeIcon}
                            homeText={props.homeText}
                            homeHidden={props.homeHidden}
                            documentsIcon={props.documentsIcon}
                            documentsText={props.documentsText}
                            documentsHidden={props.documentsHidden}
                            iconSize={props.iconSize}
                            onItemSelected={() => {
                                setIsSidebarCollapsed(true);
                            }}
                        />
                    </Panel>
                )}

                <div style={{ flex: 1, overflow: "auto" }}>
                    <Outlet />
                </div>
            </Stack>
        </Stack>
    );
};

export default DefaultLayout;
