import { IDataTableColumn, Sort, Stack, useTheme } from "@in-core";
import SortItem from "./SortItem";

export interface ISortSectionProps<TData = any> {
    columns: IDataTableColumn<TData>[];
    sort: Sort | undefined;
    onSortChanged: (newSort: Sort | undefined) => void;
}

const SortSection = (props: ISortSectionProps) => {
    const theme = useTheme();

    return (
        <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
            <SortItem
                columns={props.columns.filter((x) => {
                    return x.isSortable !== false;
                })}
                sort={props.sort}
                onSortChanged={props.onSortChanged}
            />
        </Stack>
    );
};

export default SortSection;
