import { useEffect, useRef } from "react";

const useOnMount = (effect: () => void, cleanup?: () => void) => {
    const firstRenderRef = useRef(true);

    return useEffect(() => {
        if (!firstRenderRef.current) {
            return;
        }

        firstRenderRef.current = false;

        effect();

        if (cleanup) {
            return cleanup;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useOnMount;
