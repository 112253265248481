export const IsoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{7})Z$/;

export const getDateIsoString = (value: Date) => {
    const year = value.getUTCFullYear();
    const month = value.getUTCMonth() + 1;
    const date = value.getUTCDate();
    const hours = value.getUTCHours();
    const minutes = value.getUTCMinutes();
    const seconds = value.getUTCSeconds();
    const milliseconds = value.getUTCMilliseconds();

    return `${padNumberLeft(year, 4)}-${padNumberLeft(month, 2)}-${padNumberLeft(date, 2)}T${padNumberLeft(
        hours,
        2,
    )}:${padNumberLeft(minutes, 2)}:${padNumberLeft(seconds, 2)}.${padNumberRight(milliseconds, 7)}Z`;
};

export const padNumberLeft = (value: number, size: number, padCharacter: string = "0"): string => {
    let str = value.toString();
    while (str.length < size) {
        str = padCharacter + str;
    }
    return str;
};

export const padNumberRight = (value: number, size: number, padCharacter: string = "0"): string => {
    let str = value.toString();
    while (str.length < size) {
        str = str + padCharacter;
    }
    return str;
};

export const composeKey = (obj: any): string => {
    if (obj === undefined || obj === null) {
        return obj;
    }

    if (typeof obj !== "object") {
        throw Error(`composeKey is intended to generate key string from object type and got type ${typeof obj}`);
    }

    const keys = Object.keys(obj).sort();
    let result = keys.length.toString();

    keys.forEach((x, i) => {
        result += `-${x}:${obj[x].toString().trim().length}`;
    });

    keys.forEach((x, i) => {
        result += `-${obj[x].toString().trim()}`;
    });

    return result;
};

export const parseKey = (str: string): any => {
    const firstDashIndex = str.indexOf("-");
    const numberOfKeys = Number(str.substring(0, firstDashIndex));
    let slicedStr = str.substring(firstDashIndex + 1);

    const keyLengths: Record<string, number> = {};
    for (let i = 0; i < numberOfKeys; i++) {
        const dashIndex = slicedStr.indexOf("-");
        const keyInfo = slicedStr.substring(0, dashIndex).split(":");
        keyLengths[keyInfo[0]] = Number(keyInfo[1]);
        slicedStr = slicedStr.substring(dashIndex + 1);
    }

    const obj: any = {};
    Object.entries(keyLengths).forEach(([name, length], i) => {
        obj[name] = slicedStr.substring(0, length);
        slicedStr = slicedStr.substring(length + 1);
    });

    return obj;
};

export const getKeyObject = <T = any>(item: T, idProperties: (keyof T)[]) => {
    if (idProperties.length === 0 || item === undefined || item === null || typeof item !== "object") {
        return item;
    }

    if (idProperties.length === 1) {
        return item[idProperties[0]];
    }

    const obj: any = {};

    idProperties.forEach((x) => {
        obj[x] = item[x];
    });

    return obj;
};

export const getKeyString = <T = any>(item: T, idProperties: (keyof T)[]) => {
    const keyObject = getKeyObject(item, idProperties);

    return typeof keyObject === "object" ? composeKey(keyObject) : (keyObject as any)?.toString();
};

export const timeout = (ms: number) => {
    return new Promise((resolve) => {
        return setTimeout(resolve, ms);
    });
};

export const areArraysEqual = <T>(
    arr1: T[] | undefined | null,
    arr2: T[] | undefined | null,
    orderNotImportant?: boolean,
    compareFunc?: (el1: T, el2: T) => boolean,
) => {
    if (arr1 === arr2) {
        return true;
    }
    if (arr1 === undefined || arr2 === undefined || arr1 === null || arr2 === null) {
        return false;
    }
    if (arr1.length !== arr2.length) {
        return false;
    }

    const a1 = orderNotImportant ? [...arr1].sort() : arr1;
    const a2 = orderNotImportant ? [...arr2].sort() : arr2;

    for (var i = 0; i < a1.length; i++) {
        var comparisonResult = compareFunc !== undefined ? compareFunc(a1[i], a2[i]) : a1[i] === a2[i];
        if (!comparisonResult) {
            return false;
        }
    }

    return true;
};

export const isObject = (value: any): boolean => {
    if (value === undefined || value === null) {
        return false;
    }

    return typeof value === "object" && !Array.isArray(value) && !(value instanceof Date);
};

const getTypeDescriptor = (obj: any): "undefined" | "null" | "object" | "array" | "value" => {
    return obj === undefined
        ? "undefined"
        : obj === null
        ? "null"
        : isObject(obj)
        ? "object"
        : Array.isArray(obj)
        ? "array"
        : "value";
};

export const areObjectsEqual = <T = any>(obj1: T, obj2: T, arrayOrderNotImportant?: boolean): boolean => {
    const type1 = getTypeDescriptor(obj1);
    const type2 = getTypeDescriptor(obj2);

    if (type1 !== type2) {
        return false;
    }

    if (type1 === "array") {
        const arr1 = obj1 as unknown as any[];
        const arr2 = obj2 as unknown as any[];

        if (arr1.length !== arr2.length) {
            return false;
        }

        return areArraysEqual(arr1, arr2, arrayOrderNotImportant, (el1, el2) => {
            return areObjectsEqual(el1, el2, arrayOrderNotImportant);
        });
    }

    if (type1 === "object") {
        const keys1 = Object.keys(obj1 as unknown as object);
        const keys2 = Object.keys(obj2 as unknown as object);

        if (keys1.length !== keys2.length || [...Array.from(new Set([...keys1, ...keys2]))].length !== keys1.length) {
            return false;
        }

        return keys1.every((x) => {
            return areObjectsEqual((obj1 as any)[x], (obj2 as any)[x], arrayOrderNotImportant);
        });
    }

    return obj1 === obj2;
};

export const getNormalizedUrl = (url: string, defaultProtocol: string, defaultHostname: string) => {
    const urlProtocolIndicatorIndex = url.indexOf("://");
    const urlProtocol = urlProtocolIndicatorIndex < 0 ? defaultProtocol : url.substring(0, urlProtocolIndicatorIndex);

    const urlWithoutProtocol = url.substring(urlProtocolIndicatorIndex + 3);
    const urlPathIndicatorIndex = urlWithoutProtocol.indexOf("/");
    const urlHost =
        urlPathIndicatorIndex < 0 ? defaultHostname : urlWithoutProtocol.substring(0, urlPathIndicatorIndex);
    const urlPath =
        urlPathIndicatorIndex < 0 ? urlWithoutProtocol : urlWithoutProtocol.substring(urlPathIndicatorIndex + 1);

    return `${urlProtocol}://${urlHost}/${urlPath}`;
};

export const areUrlsEqual = (url1: string, url2: string, defaultProtocol: string, defaultHostname: string) => {
    return (
        getNormalizedUrl(url1, defaultProtocol, defaultHostname) ===
        getNormalizedUrl(url2, defaultProtocol, defaultHostname)
    );
};

export interface SearchOptions<T extends string | object> {
    equalsScore: number;
    startsWithScore: number;
    includesScore: number;
    isCaseSensitive: boolean;
    getValue: (item: T, key: keyof T) => string;
}

const getDefaultSearchOptions = <T extends string | object = string>(): SearchOptions<T> => {
    return {
        equalsScore: 1000,
        startsWithScore: 800,
        includesScore: 400,
        isCaseSensitive: false,
        getValue: (item, key) => {
            return item[key]?.toString() ?? "";
        },
    };
};

export type SearchOptionsType<T extends string | object> = T extends string
    ? Partial<SearchOptions<T>>
    : Record<keyof T, Partial<SearchOptions<T>>>;

export const search = <T extends string | object = string>(
    items: T[],
    searchTerm: string,
    options?: T extends string ? Partial<SearchOptions<T>> : { [K in keyof T]?: Partial<SearchOptions<T>> },
): T[] => {
    return items
        .map((item) => {
            let score = 0;

            if (typeof item === "string") {
                const searchOptions = {
                    ...getDefaultSearchOptions<T>(),
                    ...((options as Partial<SearchOptions<T>> | undefined) ?? {}),
                } as SearchOptions<T>;

                const relevantSearchTerm = searchOptions.isCaseSensitive ? searchTerm : searchTerm.toLowerCase();
                const relevantValue = searchOptions.isCaseSensitive ? item : item.toLowerCase();

                if (relevantValue === relevantSearchTerm) {
                    score += searchOptions.equalsScore;
                } else if (relevantValue.startsWith(relevantSearchTerm)) {
                    score += searchOptions.startsWithScore;
                } else if (relevantValue.includes(relevantSearchTerm)) {
                    score += searchOptions.includesScore;
                }
            } else if (typeof item === "object") {
                Object.keys(item).forEach((key) => {
                    if (options !== undefined && !(key in options)) {
                        return;
                    }

                    const searchOptions = {
                        ...getDefaultSearchOptions<T>(),
                        ...(((options as Record<keyof T, Partial<SearchOptions<T>>> | undefined as any) ?? {})[key] ??
                            {}),
                    } as SearchOptions<T>;

                    const relevantSearchTerm = searchOptions.isCaseSensitive ? searchTerm : searchTerm.toLowerCase();
                    const relevantValue = searchOptions.isCaseSensitive
                        ? searchOptions.getValue(item, key as keyof T)
                        : searchOptions.getValue(item, key as keyof T).toLowerCase();

                    if (relevantValue === relevantSearchTerm) {
                        score += searchOptions.equalsScore;
                    } else if (relevantValue.startsWith(relevantSearchTerm)) {
                        score += searchOptions.startsWithScore;
                    } else if (relevantValue.includes(relevantSearchTerm)) {
                        score += searchOptions.includesScore;
                    }
                });
            }

            return { item: item, score: score };
        })
        .filter((x) => {
            return x.score > 0;
        })
        .sort((a, b) => {
            return b.score - a.score;
        })
        .map((x) => {
            return x.item;
        });
};

export class DirectionalGraph<T> {
    private nodes: T[] = [];
    private connections: Map<T, T[]> = new Map<T, T[]>();

    addNode(node: T) {
        if (this.nodes.includes(node)) {
            return;
        }

        this.nodes.push(node);
    }

    removeNode(node: T) {
        const index = this.nodes.indexOf(node);
        if (index < 0) {
            return;
        }

        this.nodes.splice(index, 1);

        this.connections.delete(node);
        this.connections.forEach((connections) => {
            const connectionIndex = connections.indexOf(node);
            if (connectionIndex < 0) {
                return;
            }

            connections.splice(connectionIndex, 1);
        });
    }

    addConnection(from: T, to: T) {
        this.addNode(from);
        this.addNode(to);

        let connections = this.connections.get(from);
        if (connections === undefined) {
            connections = [];
            this.connections.set(from, connections);
        }

        if (connections.includes(to)) {
            return;
        }

        connections.push(to);
    }

    removeConnection(from: T, to: T) {
        const connections = this.connections.get(from);
        if (connections === undefined) {
            return;
        }

        const index = connections.indexOf(to);
        if (index < 0) {
            return;
        }

        connections.splice(index, 1);

        if (connections.length === 0) {
            this.connections.delete(from);
        }
    }

    getPaths(from: T, to: T): T[][] | undefined {
        if (!this.nodes.includes(from) || !this.nodes.includes(to)) {
            return undefined;
        }

        return this.getPathRecursive(from, to, []);
    }

    isConnected(from: T, to: T) {
        return this.getPaths(from, to) !== undefined;
    }

    containsNode(node: T) {
        return this.nodes.includes(node);
    }

    private getPathRecursive(from: T, to: T, visitedNodes: T[]): T[][] | undefined {
        const fromConnections = this.connections.get(from);
        if (fromConnections === undefined) {
            return undefined;
        }

        const result: T[][] = [];
        fromConnections.forEach((connection) => {
            if (visitedNodes.includes(connection)) {
                return;
            }

            if (connection === to) {
                result.push([from, to]);
                return;
            }

            const nestedPath = this.getPathRecursive(connection, to, [...visitedNodes, from]);
            if (nestedPath === undefined || nestedPath.length === 0) {
                return;
            }

            result.push(
                ...nestedPath.map((x) => {
                    return [from, ...x];
                }),
            );
        });

        return result.length === 0 ? undefined : result;
    }
}

export const downloadBase64File = (contentBase64: string, fileName: string) => {
    const linkSource = `data:application/octet-stream;base64,${contentBase64}`;
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = "_self";
    downloadLink.download = fileName;
    downloadLink.click();
};
