import { Theme, initializeIcons, mergeStyles } from "@fluentui/react";
import { ThemeProvider } from "@in-core";
import { getDateIsoString, IsoDateFormat } from "@in-core/Utils";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import LocationChangeListener from "./LocationChangeListener";
import Shell, { IShellProps } from "./Shell";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";

mergeStyles({
    ":global(body,html,#root)": {
        margin: 0,
        padding: 0,
        height: "100vh",
    },
});

initializeIcons();
initializeFileTypeIcons();

axios.interceptors.response.use(
    (response) => {
        response.data = getDatesFromStrings(response.data);
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            window.location.href = `/Identity/Account/Login?ReturnUrl=${encodeURIComponent(
                window.location.pathname + window.location.search,
            )}`;
            return;
        }

        if (error.response.status === 403) {
            console.error(
                `User not authorized. Check for the appropriate [Authorize] attribute (${error.request.url}).`,
            );
            return;
        }

        error.response.data = getDatesFromStrings(error);
        return error;
    },
);

axios.interceptors.request.use(
    (request) => {
        request.data = getStringsFromDates(request.data);
        request.maxRedirects = 0;
        return request;
    },
    (error) => {
        return getStringsFromDates(error);
    },
);

const getDatesFromStrings = (value: any): any => {
    if (value === undefined || value === null) {
        return value;
    }

    if (typeof value === "string" && IsoDateFormat.test(value)) {
        return new Date(value);
    }

    if (typeof value !== "object") {
        return value;
    }

    if (Array.isArray(value)) {
        return value.map(getDatesFromStrings);
    }

    const result: any = {};

    Object.entries(value).forEach(([key, value]) => {
        result[key] = getDatesFromStrings(value);
    });

    return result;
};

const getStringsFromDates = (value: any): any => {
    if (value === undefined || value === null) {
        return value;
    }

    if (value instanceof Date) {
        return getDateIsoString(value);
    }

    if (typeof value !== "object") {
        return value;
    }

    if (Array.isArray(value)) {
        return value.map(getStringsFromDates);
    }

    const result: any = {};

    Object.entries(value).forEach(([key, value]) => {
        result[key] = getStringsFromDates(value);
    });

    return result;
};

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

export interface IInCoreAppProps extends IShellProps {
    theme?: Theme;
}

const InCoreApp = (props: IInCoreAppProps) => {
    return (
        <BrowserRouter basename={baseUrl!}>
            <style>
                {`
                #rootApp {
                    height: 100vh;
                    height: 100dvh;
                }`}
            </style>
            <ThemeProvider theme={props.theme} id="rootApp">
                <Shell {...props} />
                <LocationChangeListener />
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default InCoreApp;
