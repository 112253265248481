import {
    FileExplorer,
    Pivot,
    PivotItem,
    Spinner,
    useInCoreLocalization,
    useOnMount,
    usePermissionService,
} from "@in-core";
import {
    Delete as PersonalDelete,
    Download as PersonalDownload,
    GetDirectoryContent as PersonalGetDirectoryContent,
    GetFilePreview as PersonalGetFilePreview,
    Rename as PersonalRename,
    UploadContent as PersonalUploadContent,
} from "@in-core/api/Documents/Personal";
import {
    Delete as OrganizationDelete,
    Download as OrganizationDownload,
    GetDirectoryContent as OrganizationGetDirectoryContent,
    GetFilePreview as OrganizationGetFilePreview,
    Rename as OrganizationRename,
    UploadContent as OrganizationUploadContent,
} from "@in-core/api/Documents/Organization";
import { IFileExplorerLocation } from "@in-core/components/FileExplorer";
import { useState } from "react";

export interface IDocumentBrowserLocation extends IFileExplorerLocation {
    libraryId: string;
}

export interface IDocumentBrowserProps {
    defaultLocation?: IDocumentBrowserLocation | null;
    location?: IDocumentBrowserLocation | null;
    onLocationChanged?: (location: IDocumentBrowserLocation) => void;
}

const DocumentBrowser = (props: IDocumentBrowserProps) => {
    const permissionService = usePermissionService();
    const localization = useInCoreLocalization();

    const [internalLocation, setInternalLocation] = useState<IDocumentBrowserLocation | null>(
        props.location ?? props.defaultLocation ?? null,
    );

    const location = props.location !== undefined ? props.location : internalLocation;

    const setCompleteLocation = (location: IDocumentBrowserLocation) => {
        setInternalLocation(location);
        props.onLocationChanged && props.onLocationChanged(location);
    };

    useOnMount(() => {
        const hasPersonalLibrary = permissionService.isAuthorized("PersonalDocuments");
        const hasOrganizationLibrary = permissionService.isAuthorized("OrganizationDocuments");
        // TODO shared libraries

        const defaultLibrary = hasPersonalLibrary ? "Personal" : hasOrganizationLibrary ? "Organization" : undefined;

        if (!location && defaultLibrary) {
            setCompleteLocation({ libraryId: defaultLibrary, directoryPath: [], fileName: null });
        }
    });

    if (location === undefined || location === null) {
        return <Spinner />;
    }

    const libraryId = location.libraryId;
    const directoryPath = location.directoryPath;
    const fileName = location.fileName;

    return (
        <Pivot
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
            styles={{ itemContainer: { flex: 1 } }}
            selectedKey={libraryId}
            onLinkClick={(item) => {
                setCompleteLocation({ libraryId: item!.props.itemKey!, directoryPath: [], fileName: null });
            }}
        >
            {permissionService.isAuthorized("PersonalDocuments") && (
                <PivotItem
                    itemKey="Personal"
                    headerText={localization.Documents.MyDocuments}
                    itemIcon="FabricFolder"
                    style={{ height: "100%" }}
                >
                    <FileExplorer
                        rootName={localization.Documents.MyDocuments}
                        refreshKey={libraryId}
                        location={{ directoryPath: directoryPath, fileName: fileName }}
                        onLocationChanged={(newLocation) => {
                            setCompleteLocation({ ...newLocation, libraryId: location.libraryId });
                        }}
                        onGetDirectoryContent={async (directoryPath) => {
                            const response = await PersonalGetDirectoryContent.callApi({
                                DirectoryPath: directoryPath,
                            });

                            if (response.isSuccess) {
                                return response.data!;
                            }

                            return null;
                        }}
                        onGetFilePreview={async (directoryPath, fileName) => {
                            const response = await PersonalGetFilePreview.callApi({
                                DirectoryPath: directoryPath,
                                FileName: fileName,
                            });

                            if (response.isSuccess) {
                                return response.data!;
                            }

                            return null;
                        }}
                        onUploadContent={async (directoryPath, newContent) => {
                            await PersonalUploadContent.callApi({
                                DirectoryPath: directoryPath,
                                NewContentInfo: newContent,
                            });
                        }}
                        onCreateNewDirectory={async (directoryPath) => {
                            await PersonalUploadContent.callApi({
                                DirectoryPath: directoryPath.slice(0, directoryPath.length - 1),
                                NewContentInfo: {
                                    Directories: [
                                        {
                                            Name: directoryPath[directoryPath.length - 1],
                                            Subdirectories: [],
                                            Files: [],
                                        },
                                    ],
                                    Files: [],
                                },
                            });
                        }}
                        onRename={async (path, newName) => {
                            await PersonalRename.callApi({
                                Path: path,
                                NewName: newName,
                            });
                        }}
                        onDelete={async (path) => {
                            await PersonalDelete.callApi({
                                Path: path,
                            });
                        }}
                        onDownload={async (path, itemsToAdd) => {
                            const response = await PersonalDownload.callApi({
                                Path: path,
                                ItemsToAdd: itemsToAdd,
                            });
                            return response.data!;
                        }}
                    />
                </PivotItem>
            )}

            {permissionService.isAuthorized("OrganizationDocuments") && (
                <PivotItem
                    itemKey="Organization"
                    headerText={localization.Documents.OrganizationDocuments}
                    itemIcon="People"
                    style={{ height: "100%" }}
                >
                    <FileExplorer
                        rootName={localization.Documents.OrganizationDocuments}
                        refreshKey={libraryId}
                        location={{ directoryPath: directoryPath, fileName: fileName }}
                        onLocationChanged={(newLocation) => {
                            setCompleteLocation({ ...newLocation, libraryId: location.libraryId });
                        }}
                        onGetDirectoryContent={async (directoryPath) => {
                            const response = await OrganizationGetDirectoryContent.callApi({
                                DirectoryPath: directoryPath,
                            });

                            if (response.isSuccess) {
                                return response.data!;
                            }

                            return null;
                        }}
                        onGetFilePreview={async (directoryPath, fileName) => {
                            const response = await OrganizationGetFilePreview.callApi({
                                DirectoryPath: directoryPath,
                                FileName: fileName,
                            });

                            if (response.isSuccess) {
                                return response.data!;
                            }

                            return null;
                        }}
                        onUploadContent={
                            permissionService.isAuthorized("OrganizationDocuments.Add")
                                ? async (directoryPath, newContent) => {
                                      await OrganizationUploadContent.callApi({
                                          DirectoryPath: directoryPath,
                                          NewContentInfo: newContent,
                                      });
                                  }
                                : undefined
                        }
                        onCreateNewDirectory={
                            permissionService.isAuthorized("OrganizationDocuments.Add")
                                ? async (directoryPath) => {
                                      await OrganizationUploadContent.callApi({
                                          DirectoryPath: directoryPath.slice(0, directoryPath.length - 1),
                                          NewContentInfo: {
                                              Directories: [
                                                  {
                                                      Name: directoryPath[directoryPath.length - 1],
                                                      Subdirectories: [],
                                                      Files: [],
                                                  },
                                              ],
                                              Files: [],
                                          },
                                      });
                                  }
                                : undefined
                        }
                        onRename={
                            permissionService.isAuthorized("OrganizationDocuments.Rename")
                                ? async (path, newName) => {
                                      await OrganizationRename.callApi({
                                          Path: path,
                                          NewName: newName,
                                      });
                                  }
                                : undefined
                        }
                        onDelete={
                            permissionService.isAuthorized("OrganizationDocuments.Delete")
                                ? async (path) => {
                                      await OrganizationDelete.callApi({
                                          Path: path,
                                      });
                                  }
                                : undefined
                        }
                        onDownload={
                            permissionService.isAuthorized("OrganizationDocuments.Download")
                                ? async (path, itemsToAdd) => {
                                      const response = await OrganizationDownload.callApi({
                                          Path: path,
                                          ItemsToAdd: itemsToAdd,
                                      });
                                      return response.data!;
                                  }
                                : undefined
                        }
                    />
                </PivotItem>
            )}
        </Pivot>
    );
};

export default DocumentBrowser;
