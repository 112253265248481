import {
    FileExplorer,
    IFileExplorerLocation,
    Spinner,
    getKeyString,
    useEntityDataTableContext,
    useOnChange,
    useOnMount,
    usePanelContext,
    usePermissionService,
} from "@in-core";
import {
    Delete,
    Download,
    GetDirectoryContent,
    GetFilePreview,
    IsFile,
    Rename,
    UploadContent,
} from "@in-core/api/Documents/Entity";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface IDocumentsProps {
    routable?: boolean;
}

const Documents = (props: IDocumentsProps) => {
    const permissionService = usePermissionService();
    const location = useLocation();
    const navigate = useNavigate();
    const entityDataTableContext = useEntityDataTableContext()!;
    const panelContext = usePanelContext();
    const [fileExplorerLocation, setFileExplorerLocation] = useState<IFileExplorerLocation>();

    useOnMount(
        () => {
            panelContext?.setProps({
                styles: {
                    scrollableContent: { height: "100%", display: "flex", flexDirection: "column" },
                    content: { flex: 1 },
                },
            });
        },
        () => {
            panelContext?.setProps({ styles: undefined });
        },
    );

    useOnChange(async () => {
        if (!props.routable) {
            return;
        }

        if (fileExplorerLocation !== undefined) {
            return;
        }

        const completePath = location.pathname.split("/").filter((x) => {
            return x.length > 0;
        });

        const path = completePath.slice(2);
        let isFile = await IsFile.callApi({
            Entity: entityDataTableContext.permission.Metadata.EntityName,
            KeyString: getKeyString(
                entityDataTableContext.selectedKeys[0],
                entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? [],
            ),
            Path: path,
        });

        setFileExplorerLocation(
            isFile
                ? {
                      directoryPath: path.slice(0, path.length - 1),
                      fileName: path[path.length - 1],
                  }
                : { directoryPath: path, fileName: null },
        );
    }, [location]);

    if (props.routable && fileExplorerLocation === undefined) {
        return <Spinner />;
    }

    return (
        <FileExplorer
            rootText={entityDataTableContext.permission.Name}
            location={fileExplorerLocation}
            onLocationChanged={(newFileExplorerLocation) => {
                setFileExplorerLocation(newFileExplorerLocation);

                navigate({
                    pathname: `/${entityDataTableContext.permission.Id}/${entityDataTableContext.innerPermission
                        ?.Id!}${newFileExplorerLocation.directoryPath
                        .map((x) => {
                            return `/${x}`;
                        })
                        .join("")}${newFileExplorerLocation.fileName ? `/${newFileExplorerLocation.fileName}` : ""}`,
                    search: location.search,
                });
            }}
            onGetDirectoryContent={async (directoryPath) => {
                const response = await GetDirectoryContent.callApi({
                    Entity: entityDataTableContext.permission.Metadata.EntityName,
                    KeyString: getKeyString(
                        entityDataTableContext.selectedKeys[0],
                        entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? [],
                    ),
                    DirectoryPath: directoryPath,
                });

                if (response.isSuccess) {
                    return response.data!;
                }

                return null;
            }}
            onGetFilePreview={async (directoryPath, fileName) => {
                const response = await GetFilePreview.callApi({
                    Entity: entityDataTableContext.permission.Metadata.EntityName,
                    KeyString: getKeyString(
                        entityDataTableContext.selectedKeys[0],
                        entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? [],
                    ),
                    DirectoryPath: directoryPath,
                    FileName: fileName,
                });

                if (response.isSuccess) {
                    return response.data!;
                }

                return null;
            }}
            onUploadContent={
                permissionService.isAuthorized(`${entityDataTableContext.permission.CompleteId}.Documents.Add`)
                    ? async (directoryPath, newContent) => {
                          await UploadContent.callApi({
                              Entity: entityDataTableContext.permission.Metadata.EntityName,
                              KeyString: getKeyString(
                                  entityDataTableContext.selectedKeys[0],
                                  entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? [],
                              ),
                              DirectoryPath: directoryPath,
                              NewContentInfo: newContent,
                          });
                      }
                    : undefined
            }
            onCreateNewDirectory={
                permissionService.isAuthorized(`${entityDataTableContext.permission.CompleteId}.Documents.Add`)
                    ? async (directoryPath) => {
                          await UploadContent.callApi({
                              Entity: entityDataTableContext.permission.Metadata.EntityName,
                              KeyString: getKeyString(
                                  entityDataTableContext.selectedKeys[0],
                                  entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? [],
                              ),
                              DirectoryPath: directoryPath.slice(0, directoryPath.length - 1),
                              NewContentInfo: {
                                  Directories: [
                                      {
                                          Name: directoryPath[directoryPath.length - 1],
                                          Subdirectories: [],
                                          Files: [],
                                      },
                                  ],
                                  Files: [],
                              },
                          });
                      }
                    : undefined
            }
            onRename={
                permissionService.isAuthorized(`${entityDataTableContext.permission.CompleteId}.Documents.Rename`)
                    ? async (path, newName) => {
                          await Rename.callApi({
                              Entity: entityDataTableContext.permission.Metadata.EntityName,
                              KeyString: getKeyString(
                                  entityDataTableContext.selectedKeys[0],
                                  entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? [],
                              ),
                              Path: path,
                              NewName: newName,
                          });
                      }
                    : undefined
            }
            onDelete={
                permissionService.isAuthorized(`${entityDataTableContext.permission.CompleteId}.Documents.Delete`)
                    ? async (path) => {
                          await Delete.callApi({
                              Entity: entityDataTableContext.permission.Metadata.EntityName,
                              KeyString: getKeyString(
                                  entityDataTableContext.selectedKeys[0],
                                  entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? [],
                              ),
                              Path: path,
                          });
                      }
                    : undefined
            }
            onDownload={
                permissionService.isAuthorized(`${entityDataTableContext.permission.CompleteId}.Documents.Download`)
                    ? async (path, itemsToAdd) => {
                          const response = await Download.callApi({
                              Entity: entityDataTableContext.permission.Metadata.EntityName,
                              KeyString: getKeyString(
                                  entityDataTableContext.selectedKeys[0],
                                  entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? [],
                              ),
                              Path: path,
                              ItemsToAdd: itemsToAdd,
                          });
                          return response.data!;
                      }
                    : undefined
            }
        />
    );
};

export default Documents;
