import { DataTableDropdown, IDataTableDropdownProps } from "@in-core";
import { FormValue } from "..";

export interface IFormDataTableDropdownProps extends Omit<IDataTableDropdownProps, "selectedKey" | "selectedKeys"> {
    value: FormValue<any>;
    ignoreDirty?: boolean;
    keyMapping?: {
        keyToSourceKey: (key: any) => any;
        sourceKeyToKey: (sourceKey: any) => any;
    };
}

const FormDataTableDropdown = (props: IFormDataTableDropdownProps) => {
    const getSourceKey = (key: any) => {
        if (key === undefined || key === null) {
            return null;
        }

        return props.keyMapping === undefined ? key : props.keyMapping.keyToSourceKey(key);
    };

    const getKey = (key: any) => {
        if (key === undefined || key === null) {
            return null;
        }

        return props.keyMapping === undefined ? key : props.keyMapping.sourceKeyToKey(key);
    };

    return (
        <DataTableDropdown
            {...props}
            selectedKey={props.multiSelect ? undefined : getSourceKey(props.value.$value)}
            selectedKeys={props.multiSelect ? props.value.$value?.map(getSourceKey) ?? null : undefined}
            onChange={(changes) => {
                if (!props.multiSelect) {
                    const selectedKey = changes.find((x) => {
                        return x.isSelected;
                    })?.key;

                    props.value.$setValue(selectedKey === undefined ? undefined : getKey(selectedKey));
                } else {
                    props.value.$setValue([
                        ...(Array.isArray(props.value.$value) ? props.value.$value : []).filter((x) => {
                            return !changes
                                .filter((y) => {
                                    return !y.isSelected;
                                })
                                .map((y) => {
                                    return getKey(y.key);
                                })
                                .includes(x);
                        }),
                        ...changes
                            .filter((x) => {
                                return x.isSelected;
                            })
                            .map((x) => {
                                return getKey(x.key);
                            }),
                    ]);
                }

                props.onChange && props.onChange(changes);
            }}
        />
    );
};

export default FormDataTableDropdown;
