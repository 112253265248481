export interface IInCoreLocalization {
    Error: string;
    Search: string;
    Accept: string;
    Select: string;
    Clear: string;
    Ok: string;
    Yes: string;
    No: string;
    Details: string;
    Edit: string;
    Add: string;
    Delete: string;
    Remove: string;
    Download: string;
    Upload: string;
    Save: string;
    Continue: string;
    Submit: string;
    UnsavedChanges: string;
    UnsavedChangesMessage: string;
    Cancel: string;
    Other: string;
    Loading: string;
    PleaseWait: string;
    Total: string;
    HomeTitle: string;
    DocumentsTitle: string;
    NotificationsTitle: string;
    UserMenuTitle: string;
    UserMenuSettingsTitle: string;
    UserMenuLogOutTitle: string;
    UsersTitle: string;
    RolesTitle: string;
    SettingsTitle: string;
    ConnectedAppsTitle: string;
    UnauthorizedPartOfSystem: string;
    NotFound: string;
    Menu: string;
    MenuViewText: string;
    ListViewText: string;
    MenuLoadingText: string;
    Documents: {
        MyDocuments: string;
        OrganizationDocuments: string;
        System: string;
        Refresh: string;
        Upload: string;
        NewFolder: string;
        Rename: string;
        Copy: string;
        Move: string;
        Delete: string;
        Download: string;
        NotFound: string;
        NameColumn: string;
        TimeAddedColumn: string;
        AddedByColumn: string;
        SizeColumn: string;
        DeleteConfirmation: string;
        PreviewNotSupported: string;
        NameInvalid: string;
        NameAlreadyExists: string;
    };
    Notes: {
        NewNote: string;
        DeleteNote: string;
        DeleteNoteConfirm: string;
    };
    DataTable: {
        RemoveSort: string;
        SortAscending: string;
        SortDescending: string;
        Filter: string;
        Sort: string;
        Pagination: string;
        Selection: string;
        Grouping: string;
        ColumnDropdownPlaceholder: string;
        DirectionDropdownPlaceholder: string;
        Ascending: string;
        Descending: string;
        SelectionHiddenColumns: string;
        SelectionVisibleColumns: string;
        SelectionShow: string;
        SelectionHide: string;
        SelectionMoveUp: string;
        SelectionMoveDown: string;
        SelectionAggregateDataPlaceholder: string;
        SelectionAggregateCountEmpty: string;
        SelectionAggregateCountNonEmpty: string;
        SelectionAggregatePercentageEmpty: string;
        SelectionAggregatePercentageNonEmpty: string;
        SelectionAggregateSum: string;
        SelectionAggregateAverage: string;
        SelectionAggregateMinimum: string;
        SelectionAggregateMaximum: string;
        SelectionAggregateCountTrue: string;
        SelectionAggregateCountFalse: string;
        SelectionAggregatePercentageTrue: string;
        SelectionAggregatePercentageFalse: string;
        Equal: string;
        NotEqual: string;
        GreaterThan: string;
        GreaterThanOrEqual: string;
        LessThan: string;
        LessThanOrEqual: string;
        IsEmpty: string;
        IsNotEmpty: string;
        Contains: string;
        NotContains: string;
        StartsWith: string;
        NotStartsWith: string;
        EndsWith: string;
        NotEndsWith: string;
        InRange: string;
        NumberOfItems: string;
        OutOf: string;
        NumberOfItemsSelected: string;
        PaginationNumberOfItems: string;
        PaginationPage: string;
        PaginationGo: string;
        CountEmpty: string;
        CountNonEmpty: string;
        PercentEmpty: string;
        PercentNonEmpty: string;
        Sum: string;
        Average: string;
        Minimum: string;
        Maximum: string;
        CountTrue: string;
        CountFalse: string;
        PercentTrue: string;
        PercentFalse: string;
        AddCountEmpty: string;
        AddCountNonEmpty: string;
        AddPercentEmpty: string;
        AddPercentNonEmpty: string;
        AddSum: string;
        AddAverage: string;
        AddMinimum: string;
        AddMaximum: string;
        AddCountTrue: string;
        AddCountFalse: string;
        AddPercentTrue: string;
        AddPercentFalse: string;
        AggregateDataNotAvailable: string;
        AllAggregateDataShown: string;
        AddAggregateData: string;
        RemoveAggregateData: string;
        SelectDate: string;
        SpecificDate: string;
        Today: string;
        Yesterday: string;
        Tommorrow: string;
        BeginningOfWeek: string;
        EndOfWeek: string;
        BeforeOneWeek: string;
        AfterOneWeek: string;
        BeginningOfMonth: string;
        EndOfMonth: string;
        BeforeOneMonth: string;
        AfterOneMonth: string;
        BeginningOfQuarter: string;
        EndOfQuarter: string;
        BeforeOneQuarter: string;
        AfterOneQuarter: string;
        BeginningOfYear: string;
        EndOfYear: string;
        BeforeOneYear: string;
        AfterOneYear: string;
        SelectView: string;
        SetAsDefault: string;
        UnsetAsDefault: string;
        SaveView: string;
        SaveViewAs: string;
        NoData: string;
        AddNewItem: string;
    };
    EntityDataTable: {
        ConfigurationLoading: string;
        Refresh: string;
        Create: string;
        Read: string;
        Update: string;
        Delete: string;
        Basic: string;
        Actions: string;
        Documents: string;
        Notes: string;
        Activity: string;
        AuditLog: string;
        Export: string;
        ReportName: string;
        ReportFormat: string;
        ReportFormatPdfVertical: string;
        ReportFormatPdfHorizontal: string;
        ReportFormatExcel: string;
        ReportFormatCsv: string;
        ReportTooManyColumnsPrefix: string;
        ReportTooManyColumnsSuffix: string;
        ReportError: string;
        ReportErrorMessage: string;
        SavingView: string;
        ViewName: string;
        SetAsDefaultView: string;
        SetAsPublicView: string;
        SetAsPublicDefaultView: string;
        DeletingView: string;
        DeletingViewConfirmation: string;
        ActivityUserPrefix: string;
        ActivityUserSuffix: string;
        ActivityConnectedAppPrefix: string;
        ActivityConnectedAppSuffix: string;
        ActivityGenericPrefix: string;
        ActivityGenericSuffix: string;
        ActivityViewChanges: string;
        ActivityGeneratedId: string;
        ActivityName: string;
        ActivityTime: string;
        ActivityPermission: string;
        ActivityUser: string;
        ActivityConnectedApp: string;
        ActivityAuditLogTime: string;
        ActivityAuditLogType: string;
        ActivityAuditLogOnlyChanges: string;
        AuditLogId: string;
        AuditLogTime: string;
        AuditLogType: string;
        AuditLogUser: string;
        AuditLogConnectedApp: string;
        AuditLogActivity: string;
        AuditLogOnlyChanges: string;
        DeleteError: string;
        DeleteNotPossible: string;
        DeleteWarning: string;
        DeleteConfirm: string;
        NotesTextRequired: string;
    };
    DataTableDropdown: {
        MinSearchEnter: string;
        MinSearchDescriptionWithoutAdvanced: string;
        MinSearchDescriptionWithAdvanced: string;
        SelectItemWithoutAdvanced: string;
        SelectItemWithAdvanced: string;
        NothingFound: string;
        AdvancedSearch: string;
    };
    EntityDataTableDropdown: {
        AddNew: string;
    };
    Settings: {
        Edit: string;
        IdColumn: string;
        NameColumn: string;
        DescriptionColumn: string;
        IsPrivateColumn: string;
        IsAllowedForSystemColumn: string;
        IsAllowedForRoleColumn: string;
        IsAllowedForUserColumn: string;
        System: string;
        Roles: string;
        Users: string;
        Role: string;
        User: string;
        RoleIdColumn: string;
        RoleNameColumn: string;
        RoleDescriptionColumn: string;
        RoleHasValueColumn: string;
        RoleValueColumn: string;
        UserIdColumn: string;
        UserUserNameColumn: string;
        UserNameColumn: string;
        UserHasValueColumn: string;
        UserValueColumn: string;
        ValueNotDefined: string;
        Value: string;
        SetValue: string;
        ClearValue: string;
    };
    UserManagement: {
        Basic: string;
        Permissions: string;
        Users: string;
        Roles: string;
        Menu: string;
        Settings: string;
        RoleId: string;
        RoleName: string;
        RoleDescription: string;
        UserId: string;
        UserUserName: string;
        UserName: string;
        UserEmail: string;
        UserCulture: string;
        UserPassword: string;
        UserLockout: string;
        Assigned: string;
        SubstructurePlaceholder: string;
        SearchAllStructuresPlaceholder: string;
        PermissionsIdColumn: string;
        PermissionsNameColumn: string;
        PermissionsDescriptionColumn: string;
        PermissionsIsSelectedColumn: string;
        PermissionsAssigned: string;
        PermissionsAssignedExplicit: string;
        PermissionsAssignedImplicit: string;
        PermissionsAssignedInherited: string;
        PermissionsNotAssigned: string;
        PermissionsNotAssignedExplicit: string;
        PermissionsNotAssignedInherited: string;
        PermissionsYesImplied: string;
        PermissionsImpliedBy: string;
        PermissionsInheritedFrom: string;
        PermissionsImplied: string;
        PermissionsInherited: string;
        MenuDefault: string;
        MenuCustom: string;
        MenuRemove: string;
        MenuMoveUp: string;
        MenuMoveDown: string;
        MenuRename: string;
        MenuAddItem: string;
        MenuAddSubmenu: string;
        MenuDefaultSelected: string;
        MenuAddSubmenuTitle: string;
        MenuAddSubmenuText: string;
        MenuAddSubmenuSubmit: string;
        MenuRenameTitle: string;
        MenuRenameText: string;
        MenuRenameSubmit: string;
        Setting: string;
        SettingsIdColumn: string;
        SettingsNameColumn: string;
        SettingsDescriptionColumn: string;
        SettingsHasValueColumn: string;
        SettingsValueColumn: string;
        SettingsIsPrivateColumn: string;
        SettingsIsAllowedForSystemColumn: string;
        SettingsIsAllowedForRoleColumn: string;
        SettingsIsAllowedForUserColumn: string;
    };
    ConnectedApps: {
        CreateMessage: string;
        ShowOnlyAssigned: string;
        IdColumn: string;
        NameColumn: string;
        DescriptionColumn: string;
        DocumentationFormat: string;
    };
    DatePicker: {
        Today: string;
        January: string;
        February: string;
        March: string;
        April: string;
        May: string;
        June: string;
        July: string;
        August: string;
        September: string;
        October: string;
        November: string;
        December: string;
        JanuaryShort: string;
        FebruaryShort: string;
        MarchShort: string;
        AprilShort: string;
        MayShort: string;
        JuneShort: string;
        JulyShort: string;
        AugustShort: string;
        SeptemberShort: string;
        OctoberShort: string;
        NovemberShort: string;
        DecemberShort: string;
        Monday: string;
        Tuesday: string;
        Wednesday: string;
        Thursday: string;
        Friday: string;
        Saturday: string;
        Sunday: string;
        MondayShort: string;
        TuesdayShort: string;
        WednesdayShort: string;
        ThursdayShort: string;
        FridayShort: string;
        SaturdayShort: string;
        SundayShort: string;
    };
    FileUpload: {
        SelectFilesAndFoldersSingular: string;
        SelectFilesAndFoldersPlural: string;
        SelectFilesSingular: string;
        SelectFilesPlural: string;
        SelectFoldersSingular: string;
        SelectFoldersPlural: string;
        Drop: string;
        Select: string;
        File: string;
        Files: string;
        Folder: string;
        IncorrectName: string;
        FileAreadyExists: string;
        FolderAlreadyExists: string;
    };
    FileUploadModal: {
        Title: string;
    };
    ModalPdfViewer: {
        DefaultName: string;
        Loading: string;
    };
    Pagination: {
        First: string;
        Previous: string;
        Next: string;
        Last: string;
    };
    MultiStepContent: {
        Back: string;
        Next: string;
        Complete: string;
    };
    DataEntryForm: {
        DataLoading: string;
        Success: string;
        AlreadyExistsError: string;
        AlreadyExistsMessage: string;
        AlreadyExistsUpdate: string;
        AlreadyExistsRead: string;
    };
}

export const DefaultInCoreLocalization: IInCoreLocalization = {
    Error: "It seems an error occured. The error is logged and reported.",
    Search: "Search...",
    Accept: "Accept",
    Select: "Select",
    Clear: "Clear",
    Ok: "Ok",
    Yes: "Yes",
    No: "No",
    Details: "Details",
    Edit: "Edit",
    Add: "Add",
    Delete: "Delete",
    Remove: "Remove",
    Upload: "Upload",
    Download: "Download",
    Save: "Save",
    Continue: "Continue",
    Submit: "Submit",
    UnsavedChanges: "Unsaved changes",
    UnsavedChangesMessage: "If you continue, changes you made will not be saved. Are you sure you want to continue?",
    Cancel: "Cancel",
    Other: "Other",
    Loading: "Loading...",
    PleaseWait: "Please wait...",
    Total: "Total",
    HomeTitle: "Home",
    DocumentsTitle: "Documents",
    NotificationsTitle: "Notifications",
    UserMenuTitle: "User",
    UserMenuSettingsTitle: "Settings",
    UserMenuLogOutTitle: "Log out",
    UsersTitle: "Users",
    RolesTitle: "Roles",
    SettingsTitle: "Settings",
    ConnectedAppsTitle: "Connected apps",
    NotFound: "Could not find the requested part of the system.",
    UnauthorizedPartOfSystem: "You don't have access to this part of the system.",
    Menu: "Menu",
    MenuViewText: "Menu",
    ListViewText: "List",
    MenuLoadingText: "Menu is loading...",
    Documents: {
        MyDocuments: "My documents",
        OrganizationDocuments: "Organization documents",
        System: "System",
        Refresh: "Refresh",
        Upload: "Upload",
        NewFolder: "New folder",
        Rename: "Rename",
        Copy: "Copy",
        Move: "Move",
        Delete: "Delete",
        Download: "Download",
        NotFound: "Directory not found.",
        NameColumn: "Name",
        TimeAddedColumn: "Time added",
        AddedByColumn: "Added by",
        SizeColumn: "Size",
        DeleteConfirmation: "Are you sure you want to delete the files?",
        PreviewNotSupported: "Preview of this file type is not supported.",
        NameInvalid: "Invalid name.",
        NameAlreadyExists: "File with this name already exists.",
    },
    Notes: {
        NewNote: "New note",
        DeleteNote: "Delete note",
        DeleteNoteConfirm: "Are you sure you want to delete this note?",
    },
    DataTable: {
        RemoveSort: "Remove sort",
        SortAscending: "Sort ascending",
        SortDescending: "Sort descending",
        Filter: "Filter",
        Sort: "Sort",
        Pagination: "Data subset",
        Selection: "Selection",
        Grouping: "Grouping",
        ColumnDropdownPlaceholder: "Select column",
        DirectionDropdownPlaceholder: "Select direction",
        Ascending: "Ascending",
        Descending: "Descending",
        SelectionHiddenColumns: "Hidden columns",
        SelectionVisibleColumns: "Visible columns",
        SelectionShow: "Show",
        SelectionHide: "Hide",
        SelectionMoveUp: "Move up",
        SelectionMoveDown: "Move down",
        SelectionAggregateDataPlaceholder: "Aggregate data",
        SelectionAggregateCountEmpty: "Count empty",
        SelectionAggregateCountNonEmpty: "Count non empty",
        SelectionAggregatePercentageEmpty: "Percentage empty",
        SelectionAggregatePercentageNonEmpty: "Percentage non empty",
        SelectionAggregateSum: "Sum",
        SelectionAggregateAverage: "Average",
        SelectionAggregateMinimum: "Minimum",
        SelectionAggregateMaximum: "Maximum",
        SelectionAggregateCountTrue: "Count true",
        SelectionAggregateCountFalse: "Count false",
        SelectionAggregatePercentageTrue: "Percentage true",
        SelectionAggregatePercentageFalse: "Percentage false",
        Equal: "Equal",
        NotEqual: "Not equal",
        GreaterThan: "Greater than",
        GreaterThanOrEqual: "Greater than or equal",
        LessThan: "Less than",
        LessThanOrEqual: "Less than or equal",
        IsEmpty: "Empty",
        IsNotEmpty: "Not empty",
        Contains: "Contains",
        NotContains: "Not contains",
        StartsWith: "Starts with",
        NotStartsWith: "Not starts with",
        EndsWith: "Ends with",
        NotEndsWith: "Not ends with",
        InRange: "In range",
        NumberOfItems: "Number of items shown:",
        OutOf: "out of",
        NumberOfItemsSelected: "Items selected:",
        PaginationNumberOfItems: "Number of items",
        PaginationPage: "Page",
        PaginationGo: "Go",
        CountEmpty: "Count empty",
        CountNonEmpty: "Count non empty",
        PercentEmpty: "Percent empty",
        PercentNonEmpty: "Percent non empty",
        Sum: "Sum",
        Average: "Average",
        Minimum: "Minimum",
        Maximum: "Maximum",
        CountTrue: "Count true",
        CountFalse: "Count false",
        PercentTrue: "Percent true",
        PercentFalse: "Percent false",
        AddCountEmpty: "Add count empty",
        AddCountNonEmpty: "Add count non empty",
        AddPercentEmpty: "Add percent empty",
        AddPercentNonEmpty: "Add percent non empty",
        AddSum: "Add sum",
        AddAverage: "Add average",
        AddMinimum: "Add minimum",
        AddMaximum: "Add maximum",
        AddCountTrue: "Add count true",
        AddCountFalse: "Add count false",
        AddPercentTrue: "Add percent true",
        AddPercentFalse: "Add percent false",
        AggregateDataNotAvailable: "Aggregate data not available",
        AllAggregateDataShown: "All aggregate data shown",
        AddAggregateData: "Add aggregate data",
        RemoveAggregateData: "Remove aggregate data",
        SelectDate: "Select",
        SpecificDate: "Specific date",
        Today: "Today",
        Yesterday: "Yesterday",
        Tommorrow: "Tommorrow",
        BeginningOfWeek: "Beginning of week",
        EndOfWeek: "End of week",
        BeforeOneWeek: "Before one week",
        AfterOneWeek: "After one week",
        BeginningOfMonth: "Beginning of month",
        EndOfMonth: "End of month",
        BeforeOneMonth: "Before one month",
        AfterOneMonth: "After one month",
        BeginningOfQuarter: "Beginning of quarter",
        EndOfQuarter: "End of quarter",
        BeforeOneQuarter: "Before one quarter",
        AfterOneQuarter: "After one quarter",
        BeginningOfYear: "Beginning of year",
        EndOfYear: "End of year",
        BeforeOneYear: "Before one year",
        AfterOneYear: "After one year",
        SelectView: "Select view",
        SetAsDefault: "Set as default",
        UnsetAsDefault: "Remove as default",
        SaveView: "Save",
        SaveViewAs: "Save as",
        NoData: "No data to display.",
        AddNewItem: "Add new item",
    },
    EntityDataTable: {
        ConfigurationLoading: "Configuration is loading...",
        Refresh: "Refresh",
        Create: "New",
        Read: "View",
        Update: "Edit",
        Delete: "Delete",
        Basic: "Basic",
        Actions: "Actions",
        Documents: "Documents",
        Notes: "Notes",
        Activity: "Activity",
        AuditLog: "Audit log",
        Export: "Export",
        ReportName: "Report name",
        ReportFormat: "Format",
        ReportFormatPdfVertical: "PDF vertical",
        ReportFormatPdfHorizontal: "PDF horizontal",
        ReportFormatExcel: "Excel",
        ReportFormatCsv: "CSV",
        ReportTooManyColumnsPrefix: "Because of the selected format, we advise you not to include more than ",
        ReportTooManyColumnsSuffix: " columns.",
        ReportError: "Error while generating report",
        ReportErrorMessage:
            "It seems like an error occured while generating the report. One of the possible causes could be too many columns to show.",
        SavingView: "Save view",
        ViewName: "View name",
        SetAsDefaultView: "Set as default view for myselft",
        SetAsPublicView: "Public view (accessible to all users)",
        SetAsPublicDefaultView: "Set as default view for all users (until they set another view as default)",
        DeletingView: "Delete view",
        DeletingViewConfirmation: "Are you sure you want to delete view",
        ActivityUserPrefix: "User",
        ActivityUserSuffix: "has done",
        ActivityConnectedAppPrefix: "Application",
        ActivityConnectedAppSuffix: "has done",
        ActivityGenericPrefix: "Activity",
        ActivityGenericSuffix: "has been done",
        ActivityViewChanges: "Audit log",
        ActivityGeneratedId: "Generated ID",
        ActivityName: "Name",
        ActivityTime: "Time",
        ActivityPermission: "Permission",
        ActivityUser: "User",
        ActivityConnectedApp: "Connected app",
        ActivityAuditLogTime: "Time",
        ActivityAuditLogType: "Type",
        ActivityAuditLogOnlyChanges: "Show only changes",
        AuditLogId: "ID",
        AuditLogTime: "Time",
        AuditLogType: "Type",
        AuditLogUser: "User",
        AuditLogConnectedApp: "Connected app",
        AuditLogActivity: "Activity",
        AuditLogOnlyChanges: "Show only changes",
        DeleteError: "It seems it is not possible to delete this item.",
        DeleteNotPossible: "Deleting is not possible because there are other records that are prohibiting it.",
        DeleteWarning:
            "Deleting is probably possible, but be careful because this deletion will also delete other records.",
        DeleteConfirm: "Are you sure you vant to delete this item?",
        NotesTextRequired: "Text is required",
    },
    DataTableDropdown: {
        MinSearchEnter: "Enter",
        MinSearchDescriptionWithoutAdvanced: "characters for quick search.",
        MinSearchDescriptionWithAdvanced: "characters for quick search or press enter for advanced search.",
        SelectItemWithoutAdvanced: "Select an item.",
        SelectItemWithAdvanced: "Select an item or press enter for advanced search.",
        NothingFound: "No items found",
        AdvancedSearch: "Advanced search...",
    },
    EntityDataTableDropdown: {
        AddNew: "Add new",
    },
    Settings: {
        Edit: "Edit",
        IdColumn: "ID",
        NameColumn: "Name",
        DescriptionColumn: "Description",
        IsPrivateColumn: "Private?",
        IsAllowedForSystemColumn: "System?",
        IsAllowedForRoleColumn: "Role?",
        IsAllowedForUserColumn: "User?",
        System: "System",
        Roles: "Roles",
        Users: "Users",
        Role: "Role",
        User: "User",
        RoleIdColumn: "ID",
        RoleNameColumn: "Label",
        RoleDescriptionColumn: "Name",
        RoleHasValueColumn: "Has value?",
        RoleValueColumn: "Value",
        UserIdColumn: "ID",
        UserUserNameColumn: "Username",
        UserNameColumn: "Name",
        UserHasValueColumn: "Has value?",
        UserValueColumn: "Value",
        ValueNotDefined: "Value not defined",
        Value: "Value",
        SetValue: "Set value",
        ClearValue: "Clear value",
    },
    UserManagement: {
        Basic: "Basic",
        Permissions: "Permissions",
        Users: "Users",
        Roles: "Roles",
        Menu: "Menu",
        Settings: "Settings",
        RoleId: "ID",
        RoleName: "Label",
        RoleDescription: "Name",
        UserId: "ID",
        UserUserName: "Username",
        UserName: "Name",
        UserEmail: "Email",
        UserCulture: "Culture",
        UserPassword: "Password",
        UserLockout: "Lockout?",
        Assigned: "Assigned?",
        SubstructurePlaceholder: "Substructure selection",
        SearchAllStructuresPlaceholder: "Search all structures...",
        PermissionsIdColumn: "ID",
        PermissionsNameColumn: "Name",
        PermissionsDescriptionColumn: "Description",
        PermissionsIsSelectedColumn: "Permission",
        PermissionsAssigned: "Assigned",
        PermissionsAssignedExplicit: "Assigned - explicit",
        PermissionsAssignedImplicit: "Assigned - implicit",
        PermissionsAssignedInherited: "Assigned - inherited",
        PermissionsNotAssigned: "Not assigned",
        PermissionsNotAssignedExplicit: "Not assigned - explicit",
        PermissionsNotAssignedInherited: "Not assigned - inherited",
        PermissionsYesImplied: "Yes (implied)",
        PermissionsImpliedBy: "Implied by:",
        PermissionsInheritedFrom: "Inherited from:",
        PermissionsImplied: "implied",
        PermissionsInherited: "inherited",
        MenuDefault: "Default menu",
        MenuCustom: "Custom menu",
        MenuRemove: "Remove",
        MenuMoveUp: "Move up",
        MenuMoveDown: "Move down",
        MenuRename: "Rename",
        MenuAddItem: "Add item",
        MenuAddSubmenu: "Add submenu",
        MenuDefaultSelected: "Default menu is selected.",
        MenuAddSubmenuTitle: "Add submenu",
        MenuAddSubmenuText: "Enter the name of the submenu and select 'Add'.",
        MenuAddSubmenuSubmit: "Add",
        MenuRenameTitle: "Rename submenu",
        MenuRenameText: "Enter the name of the item and select 'Save'",
        MenuRenameSubmit: "Save",
        Setting: "Setting",
        SettingsIdColumn: "ID",
        SettingsNameColumn: "Name",
        SettingsDescriptionColumn: "Description",
        SettingsHasValueColumn: "Has value?",
        SettingsValueColumn: "Value",
        SettingsIsPrivateColumn: "Private?",
        SettingsIsAllowedForSystemColumn: "System?",
        SettingsIsAllowedForRoleColumn: "Role?",
        SettingsIsAllowedForUserColumn: "User?",
    },
    ConnectedApps: {
        CreateMessage:
            "After assigning permissions to the connected app and saving it, you will be able to assign keys to it and generate OpenAPI documentation.",
        ShowOnlyAssigned: "Show only assigned",
        IdColumn: "ID",
        NameColumn: "Name",
        DescriptionColumn: "Description",
        DocumentationFormat: "Documentation format",
    },
    DatePicker: {
        Today: "Today",
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
        JanuaryShort: "Jan",
        FebruaryShort: "Feb",
        MarchShort: "Mar",
        AprilShort: "Apr",
        MayShort: "May",
        JuneShort: "Jun",
        JulyShort: "Jul",
        AugustShort: "Aug",
        SeptemberShort: "Sep",
        OctoberShort: "Oct",
        NovemberShort: "Nov",
        DecemberShort: "Dec",
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Sunday: "Sunday",
        MondayShort: "Mon",
        TuesdayShort: "Tue",
        WednesdayShort: "Wed",
        ThursdayShort: "Thu",
        FridayShort: "Fri",
        SaturdayShort: "Sat",
        SundayShort: "Sun",
    },
    FileUpload: {
        SelectFilesAndFoldersSingular: "Drag a file, a folder or select the option.",
        SelectFilesAndFoldersPlural: "Drag files, folders or select the option.",
        SelectFilesSingular: "Drag a file or select the option.",
        SelectFilesPlural: "Drag files or select the option.",
        SelectFoldersSingular: "Drag a folder or select the option.",
        SelectFoldersPlural: "Drag folders or select the option.",
        Drop: "Drop to add.",
        Select: "Select",
        File: "file",
        Files: "files",
        Folder: "folder",
        IncorrectName: "Invalid name.",
        FileAreadyExists: "File with this name already exists.",
        FolderAlreadyExists: "Folder with this name already exists.",
    },
    FileUploadModal: {
        Title: "File upload",
    },
    ModalPdfViewer: {
        DefaultName: "Document",
        Loading: "File is loading...",
    },
    Pagination: {
        First: "First",
        Previous: "Previous",
        Next: "Next",
        Last: "Last",
    },
    MultiStepContent: {
        Back: "Back",
        Next: "Next",
        Complete: "Finish",
    },
    DataEntryForm: {
        DataLoading: "Data is loading...",
        Success: "Data is saved successfully.",
        AlreadyExistsError: "Item with the ID already exists.",
        AlreadyExistsMessage:
            "Item with the ID already exists in the system. You can enter another ID, or go to view or edit the edit.",
        AlreadyExistsUpdate: "Edit",
        AlreadyExistsRead: "View",
    },
};
