import {
    ActionButton,
    DataTable,
    Link,
    Panel,
    PanelType,
    SelectionMode,
    Spinner,
    Stack,
    useInCoreLocalization,
    useOnMount,
    useTheme,
} from "@in-core";
import { DataType } from "@in-core/api/Entity";
import { GetSettings } from "@in-core/api/Settings";
import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import RouteListener from "./RouteListener";
import SettingDetails from "./SettingDetails";

const Settings = () => {
    const getSettingsApi = GetSettings.useApi();
    const [currentSettingId, setCurrentSettingId] = useState<string>();
    const theme = useTheme();
    const localization = useInCoreLocalization();
    const navigate = useNavigate();

    useOnMount(() => {
        getSettingsApi.call({});
    });

    if (!getSettingsApi.data) {
        return <Spinner />;
    }

    const currentSetting = getSettingsApi.data!.find((x) => {
        return x.Id === currentSettingId;
    });

    return (
        <Stack styles={{ root: { height: "100%" } }}>
            <DataTable
                data={getSettingsApi.data}
                columns={[
                    {
                        id: "Edit",
                        name: "",
                        onRender: (item) => {
                            return (
                                <ActionButton
                                    iconProps={{ iconName: "Edit" }}
                                    styles={{ root: { height: "auto", padding: 0 } }}
                                    onClick={() => {
                                        navigate(item.Id);
                                    }}
                                >
                                    {localization.Settings.Edit}
                                </ActionButton>
                            );
                        },
                        width: 100,
                        isSortable: false,
                        isSearchable: false,
                        isFilterable: false,
                        isResizable: false,
                    },
                    {
                        id: "Id",
                        property: "Id",
                        name: localization.Settings.IdColumn,
                        dataType: DataType.String,
                        onRender: (item) => {
                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        navigate(item.Id);
                                    }}
                                >
                                    {item.Id}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "Name",
                        property: "Name",
                        name: localization.Settings.NameColumn,
                        dataType: DataType.String,
                        onRender: (item) => {
                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        navigate(item.Id);
                                    }}
                                >
                                    {item.Name}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "Description",
                        property: "Description",
                        name: localization.Settings.DescriptionColumn,
                        dataType: DataType.String,
                        width: 300,
                        isSortable: false,
                    },
                    {
                        id: "IsPrivate",
                        property: "IsPrivate",
                        name: localization.Settings.IsPrivateColumn,
                        dataType: DataType.Boolean,
                        isSortable: false,
                        width: 100,
                        isResizable: false,
                    },
                    {
                        id: "IsAllowedForSystem",
                        property: "IsAllowedForSystem",
                        name: localization.Settings.IsAllowedForSystemColumn,
                        dataType: DataType.Boolean,
                        isSortable: false,
                        width: 100,
                        isResizable: false,
                    },
                    {
                        id: "IsAllowedForRole",
                        property: "IsAllowedForRole",
                        name: localization.Settings.IsAllowedForRoleColumn,
                        dataType: DataType.Boolean,
                        isSortable: false,
                        width: 100,
                        isResizable: false,
                    },
                    {
                        id: "IsAllowedForUser",
                        property: "IsAllowedForUser",
                        name: localization.Settings.IsAllowedForUserColumn,
                        dataType: DataType.Boolean,
                        isSortable: false,
                        width: 100,
                        isResizable: false,
                    },
                ]}
                group={{ Id: "GroupName" }}
                hideToolbar
                disablePagination
                disableAggregating
                selectionMode={SelectionMode.none}
                defaultSort={{ Id: "Name", IsDescending: false }}
            />

            <Panel
                isOpen={currentSettingId !== undefined}
                headerText={currentSetting?.Name}
                type={PanelType.large}
                onDismiss={() => {
                    navigate("");
                }}
                styles={{
                    scrollableContent: {
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                    },
                    content: {
                        flex: 1,
                    },
                }}
            >
                <SettingDetails
                    setting={currentSetting!}
                    onFinished={() => {
                        navigate("");
                    }}
                />
            </Panel>

            <Routes>
                <Route
                    path=":settingId?/*"
                    element={
                        <RouteListener
                            onChange={(settingId) => {
                                setCurrentSettingId(settingId);
                            }}
                        />
                    }
                />
            </Routes>
        </Stack>
    );
};

export default Settings;
