import { Dropdown, Stack, useOnChange, useTheme } from "@in-core";
import { IFilterProps } from "..";
import { FilterValueOperator } from "@in-core/api";
import OperatorDropdown from "../OperatorDropdown";
import { DefaultFiltersForDataType } from "../..";
import { useState } from "react";
import { DataType } from "@in-core/api/Entity";

const EnumFilter = (props: IFilterProps) => {
    const theme = useTheme();
    const [operator, setOperator] = useState(props.filter?.Value?.Operator ?? DefaultFiltersForDataType[DataType.Enum]);
    const [value, setValue] = useState<number | undefined>(props.filter?.Value?.RightOperand?.Value);

    useOnChange(() => {
        setOperator((prevOperator) => {
            return props.filter?.Value?.Operator ?? prevOperator;
        });
        setValue(props.filter?.Value?.RightOperand?.Value);
    }, [props.filter]);

    return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
            <OperatorDropdown
                operators={[FilterValueOperator.Equal, FilterValueOperator.NotEqual]}
                selectedOperator={operator}
                onChange={(newOperator) => {
                    if (newOperator === "InRange") {
                        return;
                    }

                    if (operator === newOperator) {
                        return;
                    }

                    setOperator(newOperator);

                    if (value === undefined) {
                        return;
                    }

                    props.onFilterChanged({
                        IsNegated: false,
                        Value: {
                            LeftOperand: {
                                Id: props.id,
                            },
                            Operator: newOperator,
                            RightOperand: {
                                Value: value,
                            },
                        },
                    });
                }}
            />

            <Dropdown
                options={(props.allowedValues ?? []).map((x) => {
                    return { key: x.key, text: x.text };
                })}
                selectedKey={value ?? null}
                onChange={(changes) => {
                    const selectedKey = changes.find((x) => {
                        return x.isSelected;
                    })?.key as number | undefined;

                    setValue(selectedKey);

                    props.onFilterChanged(
                        selectedKey === undefined
                            ? undefined
                            : {
                                  IsNegated: false,
                                  Value: {
                                      LeftOperand: {
                                          Id: props.id,
                                      },
                                      Operator: FilterValueOperator.Equal,
                                      RightOperand: {
                                          Value: selectedKey,
                                      },
                                  },
                              },
                    );
                }}
                styles={{
                    root: {
                        flex: 1,
                        minWidth: 0,
                    },
                    title: {
                        backgroundColor: theme.palette.neutralLighterAlt,
                        borderColor: theme.palette.neutralQuaternary,
                    },
                }}
            />
        </Stack>
    );
};

export default EnumFilter;
