import { callApi as callGenericApi, useApi as useGenericApi, Permission } from "@in-core";

export namespace GetPermissions {
    export const URL = "CoreAPI/Permissions/GetPermissions";

    export interface Request {}

    export const callApi = (request: Request) => {
        return callGenericApi<Request, Permission[] | string>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, Permission[] | string>(URL);
    };
}

export namespace GetUserPermissions {
    export const URL = "CoreAPI/Permissions/GetUserPermissions";

    export interface Request {}

    export const callApi = (request: Request) => {
        return callGenericApi<Request, string[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, string[]>(URL);
    };
}
