import {
    AggregateDataType,
    DefaultButton,
    Dropdown,
    IButtonStyles,
    IDataTableColumn,
    IDropdownOption,
    Icon,
    IconButton,
    List,
    PrimaryButton,
    Selector,
    Separator,
    Stack,
    Text,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import { DataType } from "@in-core/api/Entity";
import { useState } from "react";

export interface ISelectorFormProps<TData = any> {
    visibleColumns: IDataTableColumn<TData>[];
    initialSelectors: Record<string, Selector>;
    initialAggregateDataTypes: Record<string, AggregateDataType[]>;
    onSubmit: (selectors: Record<string, Selector>, aggregateDataTypes: Record<string, AggregateDataType[]>) => void;
    onDismiss: () => void;
}

const SelectorForm = <TData extends object = any>(props: ISelectorFormProps<TData>) => {
    const [selectors, setSelectors] = useState<Record<string, Selector>>(() => {
        const selectorsClone: Record<string, Selector> = {};
        Object.keys(props.initialSelectors).forEach((x) => {
            selectorsClone[x] = props.initialSelectors[x];
        });
        return selectorsClone;
    });
    const [aggregateDataTypes, setAggregateDataTypes] = useState<Record<string, AggregateDataType[]>>(
        cloneAggregateDataTypes(props.initialAggregateDataTypes),
    );
    const theme = useTheme();
    const localization = useInCoreLocalization();

    const hiddenData = props.visibleColumns
        .filter((x) => {
            return !Object.keys(selectors).includes(x.id);
        })
        .sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

    const visibleData = props.visibleColumns
        .filter((x) => {
            return Object.keys(selectors).includes(x.id);
        })
        .sort((a, b) => {
            const aIndex = Object.keys(selectors).indexOf(a.id);
            const bIndex = Object.keys(selectors).indexOf(b.id);

            return aIndex - bIndex;
        });

    const iconButtonStyles: IButtonStyles = {
        root: {
            width: 30,
            height: 30,
        },
        icon: { fontSize: 14 },
    };

    return (
        <Stack>
            <Stack horizontal>
                <Stack styles={{ root: { flex: 1 } }}>
                    <Text
                        variant="mediumPlus"
                        styles={{
                            root: {
                                fontWeight: 600,
                                paddingLeft: theme.spacing.s1,
                                paddingRight: theme.spacing.s1,
                                paddingTop: theme.spacing.m,
                                paddingBottom: theme.spacing.m,
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                backgroundColor: theme.palette.white,
                                borderBottomWidth: 1,
                                borderBottomStyle: "solid",
                                borderBottomColor: theme.palette.neutralLighter,
                            },
                        }}
                    >
                        {localization.DataTable.SelectionHiddenColumns}
                    </Text>

                    <List
                        items={hiddenData}
                        onRenderCell={(item) => {
                            return (
                                <Stack
                                    horizontal
                                    verticalAlign="center"
                                    styles={{
                                        root: {
                                            paddingLeft: theme.spacing.s1,
                                            paddingRight: theme.spacing.s1,
                                            paddingTop: theme.spacing.s2,
                                            paddingBottom: theme.spacing.s2,
                                            borderBottomWidth: 1,
                                            borderBottomStyle: "solid",
                                            borderBottomColor: theme.palette.neutralLighter,
                                        },
                                    }}
                                >
                                    <Text styles={{ root: { flex: 1, display: "flex", alignItems: "center" } }}>
                                        {item?.icon && (
                                            <Icon
                                                iconName={item.icon}
                                                styles={{ root: { marginRight: theme.spacing.s2 } }}
                                            />
                                        )}

                                        {item!.name}
                                    </Text>

                                    <IconButton
                                        iconProps={{ iconName: "View" }}
                                        title={localization.DataTable.SelectionShow}
                                        onClick={() => {
                                            setSelectors((prevSelectors) => {
                                                const newSelectors = { ...prevSelectors };
                                                newSelectors[item!.id] = item!.defaultSelector ?? {
                                                    Id: item!.id,
                                                    Value: {},
                                                };
                                                return newSelectors;
                                            });
                                        }}
                                        styles={iconButtonStyles}
                                    />
                                </Stack>
                            );
                        }}
                    />
                </Stack>

                <Separator vertical styles={{ root: { padding: 0 } }} />

                <Stack styles={{ root: { flex: 2 } }}>
                    <Text
                        variant="mediumPlus"
                        styles={{
                            root: {
                                fontWeight: 600,
                                paddingLeft: theme.spacing.s1,
                                paddingRight: theme.spacing.s1,
                                paddingTop: theme.spacing.m,
                                paddingBottom: theme.spacing.m,
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                backgroundColor: theme.palette.white,
                                borderBottomWidth: 1,
                                borderBottomStyle: "solid",
                                borderBottomColor: theme.palette.neutralLighter,
                            },
                        }}
                    >
                        {localization.DataTable.SelectionVisibleColumns}
                    </Text>

                    <List
                        items={visibleData}
                        onRenderCell={(item, index) => {
                            const options: IDropdownOption[] = [
                                {
                                    key: AggregateDataType.CountEmpty,
                                    text: localization.DataTable.SelectionAggregateCountEmpty,
                                },
                                {
                                    key: AggregateDataType.CountNonEmpty,
                                    text: localization.DataTable.SelectionAggregateCountNonEmpty,
                                },
                                {
                                    key: AggregateDataType.PercentEmpty,
                                    text: localization.DataTable.SelectionAggregatePercentageEmpty,
                                },
                                {
                                    key: AggregateDataType.PercentNonEmpty,
                                    text: localization.DataTable.SelectionAggregatePercentageNonEmpty,
                                },
                            ];

                            if (item?.dataType === DataType.Number) {
                                options.splice(
                                    0,
                                    0,
                                    {
                                        key: AggregateDataType.Sum,
                                        text: localization.DataTable.SelectionAggregateSum,
                                    },
                                    {
                                        key: AggregateDataType.Average,
                                        text: localization.DataTable.SelectionAggregateAverage,
                                    },
                                    {
                                        key: AggregateDataType.Minimum,
                                        text: localization.DataTable.SelectionAggregateMinimum,
                                    },
                                    {
                                        key: AggregateDataType.Maximum,
                                        text: localization.DataTable.SelectionAggregateMaximum,
                                    },
                                );
                            }

                            if (item?.dataType === DataType.DateTime) {
                                options.splice(
                                    0,
                                    0,
                                    {
                                        key: AggregateDataType.Minimum,
                                        text: localization.DataTable.SelectionAggregateMinimum,
                                    },
                                    {
                                        key: AggregateDataType.Maximum,
                                        text: localization.DataTable.SelectionAggregateMaximum,
                                    },
                                );
                            }

                            if (item?.dataType === DataType.Boolean) {
                                options.splice(
                                    0,
                                    0,
                                    {
                                        key: AggregateDataType.CountTrue,
                                        text: localization.DataTable.SelectionAggregateCountTrue,
                                    },
                                    {
                                        key: AggregateDataType.CountFalse,
                                        text: localization.DataTable.SelectionAggregateCountFalse,
                                    },
                                    {
                                        key: AggregateDataType.PercentTrue,
                                        text: localization.DataTable.SelectionAggregatePercentageTrue,
                                    },
                                    {
                                        key: AggregateDataType.PercentFalse,
                                        text: localization.DataTable.SelectionAggregatePercentageFalse,
                                    },
                                );
                            }

                            return (
                                <Stack
                                    horizontal
                                    verticalAlign="center"
                                    styles={{
                                        root: {
                                            paddingLeft: theme.spacing.s1,
                                            paddingRight: theme.spacing.s1,
                                            paddingTop: theme.spacing.s2,
                                            paddingBottom: theme.spacing.s2,
                                            borderBottomWidth: 1,
                                            borderBottomStyle: "solid",
                                            borderBottomColor: theme.palette.neutralLighter,
                                        },
                                    }}
                                >
                                    <Text styles={{ root: { flex: 1, display: "flex", alignItems: "center" } }}>
                                        {item?.icon && (
                                            <Icon
                                                iconName={item.icon}
                                                styles={{ root: { marginRight: theme.spacing.s2 } }}
                                            />
                                        )}

                                        {item!.name}
                                    </Text>

                                    <IconButton
                                        iconProps={{ iconName: "Up" }}
                                        title={localization.DataTable.SelectionMoveUp}
                                        disabled={index === 0}
                                        onClick={() => {
                                            setSelectors((prevSelectors) => {
                                                const newSelectors: Record<string, Selector> = {};

                                                const keys = Object.keys(prevSelectors);

                                                for (let i = 0; i < index! - 1; i++) {
                                                    const key = keys[i];
                                                    newSelectors[key] = prevSelectors[key];
                                                }

                                                const currentKey = keys[index!];
                                                const previousKey = keys[index! - 1];

                                                newSelectors[currentKey] = prevSelectors[currentKey];
                                                newSelectors[previousKey] = prevSelectors[previousKey];

                                                for (let i = index! + 1; i < keys.length; i++) {
                                                    const key = keys[i];
                                                    newSelectors[key] = prevSelectors[key];
                                                }

                                                return newSelectors;
                                            });
                                        }}
                                        styles={iconButtonStyles}
                                    />

                                    <IconButton
                                        iconProps={{ iconName: "Down" }}
                                        title={localization.DataTable.SelectionMoveDown}
                                        disabled={index === visibleData.length - 1}
                                        onClick={() => {
                                            setSelectors((prevSelectors) => {
                                                const newSelectors: Record<string, Selector> = {};

                                                const keys = Object.keys(prevSelectors);

                                                for (let i = 0; i < index!; i++) {
                                                    const key = keys[i];
                                                    newSelectors[key] = prevSelectors[key];
                                                }

                                                const currentKey = keys[index!];
                                                const nextKey = keys[index! + 1];

                                                newSelectors[nextKey] = prevSelectors[nextKey];
                                                newSelectors[currentKey] = prevSelectors[currentKey];

                                                for (let i = index! + 2; i < keys.length; i++) {
                                                    const key = keys[i];
                                                    newSelectors[key] = prevSelectors[key];
                                                }

                                                return newSelectors;
                                            });
                                        }}
                                        styles={iconButtonStyles}
                                    />

                                    <IconButton
                                        iconProps={{ iconName: "Hide2" }}
                                        title={localization.DataTable.SelectionHide}
                                        onClick={() => {
                                            setSelectors((prevSelectors) => {
                                                const newSelectors = { ...prevSelectors };
                                                delete newSelectors[item!.id];
                                                return newSelectors;
                                            });
                                        }}
                                        disabled={item!.isSelectable === false}
                                        styles={iconButtonStyles}
                                    />

                                    <Dropdown
                                        multiSelect
                                        options={options}
                                        styles={{
                                            root: { width: 180 },
                                            title: {
                                                height: 30,
                                                borderColor: theme.palette.themeLight,
                                            },
                                        }}
                                        placeholder={localization.DataTable.SelectionAggregateDataPlaceholder}
                                        selectedKeys={aggregateDataTypes[item!.id] ?? []}
                                        onChange={(changes) => {
                                            const currentSelection = aggregateDataTypes[item!.id] ?? [];

                                            const newSelection = [
                                                ...currentSelection.filter((x) => {
                                                    return !changes
                                                        .filter((y) => {
                                                            return !y.isSelected;
                                                        })
                                                        .map((y) => {
                                                            return y.key;
                                                        })
                                                        .includes(x);
                                                }),
                                                ...changes
                                                    .filter((y) => {
                                                        return y.isSelected;
                                                    })
                                                    .map((y) => {
                                                        return y.key as AggregateDataType;
                                                    }),
                                            ];

                                            const newAggregateDataTypes = { ...aggregateDataTypes };
                                            delete newAggregateDataTypes[item!.id];
                                            if (newSelection.length > 0) {
                                                newAggregateDataTypes[item!.id] = newSelection;
                                            }

                                            setAggregateDataTypes(newAggregateDataTypes);
                                        }}
                                    />
                                </Stack>
                            );
                        }}
                    />
                </Stack>
            </Stack>

            <Stack
                horizontal
                styles={{
                    root: {
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: theme.palette.white,
                        zIndex: 1,
                        padding: theme.spacing.s1,
                        borderTopWidth: 1,
                        borderTopStyle: "solid",
                        borderTopColor: theme.palette.neutralLighter,
                    },
                }}
                tokens={{ childrenGap: theme.spacing.s1 }}
            >
                <PrimaryButton
                    onClick={() => {
                        props.onSubmit(selectors, aggregateDataTypes);
                        props.onDismiss();
                    }}
                >
                    {localization.Accept}
                </PrimaryButton>

                <DefaultButton onClick={props.onDismiss}>{localization.Cancel}</DefaultButton>
            </Stack>
        </Stack>
    );
};

export default SelectorForm;

const cloneAggregateDataTypes = (aggregateDataTypes: Record<string, AggregateDataType[]>) => {
    const result: Record<string, AggregateDataType[]> = {};

    Object.keys(aggregateDataTypes).forEach((x) => {
        result[x] = [...aggregateDataTypes[x]];
    });

    return result;
};
