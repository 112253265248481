import { Stack, useOnChange } from "@in-core";
import { useState } from "react";
import { IFilterProps } from "..";
import { FilterGroupOperator, FilterValueOperator } from "@in-core/api";
import OperatorDropdown, { OperatorDropdownOption } from "../OperatorDropdown";
import { DefaultFiltersForDataType } from "../..";
import { DataType } from "@in-core/api/Entity";
import FilterDatePicker from "./FilterDatePicker";

export const ExpressionLocalizationKeys: Record<string, string> = {
    "$Today()": "Today",
    "$AddDays($Today(),-1)": "Yesterday",
    "$AddDays($Today(),1)": "Tommorrow",
    "$WeekDay(1)": "BeginningOfWeek",
    "$WeekDay(7)": "EndOfWeek",
    "$AddDays($Today(),-7)": "BeforeOneWeek",
    "$AddDays($Today(),7)": "AfterOneWeek",
    "$BeginningOfMonth(0)": "BeginningOfMonth",
    "$EndOfMonth(0)": "EndOfMonth",
    "$AddMonths($Today(),-1)": "BeforeOneMonth",
    "$AddMonths($Today(),1)": "AfterOneMonth",
    "$BeginningOfQuarter(0)": "BeginningOfQuarter",
    "$EndOfQuarter(0)": "EndOfQuarter",
    "$AddMonths($Today(),-3)": "BeforeOneQuarter",
    "$AddMonths($Today(),3)": "AfterOneQuarter",
    "$BeginningOfYear(0)": "BeginningOfYear",
    "$EndOfYear(0)": "EndOfYear",
    "$AddYears($Today(),-1)": "BeforeOneYear",
    "$AddYears($Today(),1)": "AfterOneYear",
};

const DateFilter = (props: IFilterProps) => {
    const [operator, setOperator] = useState<OperatorDropdownOption>(
        props.filter?.Group ? "InRange" : props.filter?.Value?.Operator ?? DefaultFiltersForDataType[DataType.DateTime],
    );
    const [value, setValue] = useState<Date | undefined>(props.filter?.Value?.RightOperand?.Value);
    const [expression, setExpression] = useState<string | undefined>(props.filter?.Value?.RightOperand?.Expression);
    const [fromValue, setFromValue] = useState<Date | undefined>(
        props.filter?.Group?.Filters[0]?.Value?.RightOperand?.Value,
    );
    const [fromExpression, setFromExpression] = useState<string | undefined>(
        props.filter?.Group?.Filters[0]?.Value?.RightOperand?.Expression,
    );
    const [toValue, setToValue] = useState<Date | undefined>(
        props.filter?.Group?.Filters[1]?.Value?.RightOperand?.Value,
    );
    const [toExpression, setToExpression] = useState<string | undefined>(
        props.filter?.Group?.Filters[1]?.Value?.RightOperand?.Expression,
    );

    useOnChange(() => {
        setOperator((prevOperator) => {
            return props.filter?.Group ? "InRange" : props.filter?.Value?.Operator ?? prevOperator;
        });
        setValue(props.filter?.Value?.RightOperand?.Value);
        setExpression(props.filter?.Value?.RightOperand?.Expression);
        setFromValue(props.filter?.Group?.Filters[0]?.Value?.RightOperand?.Value);
        setFromExpression(props.filter?.Group?.Filters[0]?.Value?.RightOperand?.Expression);
        setToValue(props.filter?.Group?.Filters[1]?.Value?.RightOperand?.Value);
        setToExpression(props.filter?.Group?.Filters[1]?.Value?.RightOperand?.Expression);
    }, [props.filter]);

    return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
            <OperatorDropdown
                operators={[
                    FilterValueOperator.Equal,
                    FilterValueOperator.NotEqual,
                    FilterValueOperator.GreaterThan,
                    FilterValueOperator.GreaterThanOrEqual,
                    FilterValueOperator.LessThan,
                    FilterValueOperator.LessThanOrEqual,
                    FilterValueOperator.IsEmpty,
                    FilterValueOperator.IsNotEmpty,
                    "InRange",
                ]}
                selectedOperator={operator}
                onChange={(newOperator) => {
                    if (operator === newOperator) {
                        return;
                    }

                    if (newOperator === "InRange") {
                        setOperator(newOperator);
                        setValue(undefined);
                        setExpression(undefined);
                        setFromValue(undefined);
                        setFromExpression(undefined);
                        setToValue(undefined);
                        setToExpression(undefined);

                        props.onFilterChanged(undefined);

                        return;
                    }

                    if (newOperator === FilterValueOperator.IsEmpty || newOperator === FilterValueOperator.IsNotEmpty) {
                        props.onFilterChanged({
                            IsNegated: false,
                            Value: {
                                LeftOperand: {
                                    Id: props.id,
                                },
                                Operator: newOperator,
                                RightOperand: undefined,
                            },
                        });

                        return;
                    }

                    if (operator === FilterValueOperator.IsEmpty || operator === FilterValueOperator.IsNotEmpty) {
                        setOperator(newOperator);
                        setValue(undefined);
                        setExpression(undefined);
                        setFromValue(undefined);
                        setFromExpression(undefined);
                        setToValue(undefined);
                        setToExpression(undefined);

                        return;
                    }

                    if (value === undefined && expression === undefined) {
                        setOperator(newOperator);
                        setValue(undefined);
                        setExpression(undefined);
                        setFromValue(undefined);
                        setFromExpression(undefined);
                        setToValue(undefined);
                        setToExpression(undefined);

                        return;
                    }

                    props.onFilterChanged({
                        IsNegated: false,
                        Value: {
                            LeftOperand: {
                                Id: props.id,
                            },
                            Operator: newOperator,
                            RightOperand: {
                                Value: value,
                                Expression: expression,
                            },
                        },
                    });
                }}
            />

            {operator === "InRange" ? (
                <>
                    <FilterDatePicker
                        value={fromValue}
                        onValueChanged={(newValue) => {
                            if (toValue === undefined && toExpression === undefined) {
                                setFromValue(newValue);

                                return;
                            }

                            props.onFilterChanged({
                                IsNegated: false,
                                Group: {
                                    Operator: FilterGroupOperator.And,
                                    Filters: [
                                        {
                                            IsNegated: false,
                                            Value: {
                                                LeftOperand: {
                                                    Id: props.id,
                                                },
                                                Operator: FilterValueOperator.GreaterThanOrEqual,
                                                RightOperand: {
                                                    Value: newValue,
                                                },
                                            },
                                        },
                                        {
                                            IsNegated: false,
                                            Value: {
                                                LeftOperand: {
                                                    Id: props.id,
                                                },
                                                Operator: FilterValueOperator.LessThanOrEqual,
                                                RightOperand: {
                                                    Value: toValue,
                                                    Expression: toExpression,
                                                },
                                            },
                                        },
                                    ],
                                },
                            });
                        }}
                        expression={fromExpression}
                        onExpressionChanged={(newExpression) => {
                            if (toValue === undefined && toExpression === undefined) {
                                setFromExpression(newExpression);

                                return;
                            }

                            props.onFilterChanged({
                                IsNegated: false,
                                Group: {
                                    Operator: FilterGroupOperator.And,
                                    Filters: [
                                        {
                                            IsNegated: false,
                                            Value: {
                                                LeftOperand: {
                                                    Id: props.id,
                                                },
                                                Operator: FilterValueOperator.GreaterThanOrEqual,
                                                RightOperand: {
                                                    Expression: newExpression,
                                                },
                                            },
                                        },
                                        {
                                            IsNegated: false,
                                            Value: {
                                                LeftOperand: {
                                                    Id: props.id,
                                                },
                                                Operator: FilterValueOperator.LessThanOrEqual,
                                                RightOperand: {
                                                    Value: toValue,
                                                    Expression: toExpression,
                                                },
                                            },
                                        },
                                    ],
                                },
                            });
                        }}
                        onClear={() => {
                            setFromValue(undefined);
                            setFromExpression(undefined);

                            props.onFilterChanged(undefined);
                        }}
                    />

                    <FilterDatePicker
                        value={toValue}
                        onValueChanged={(newValue) => {
                            if (fromValue === undefined && fromExpression === undefined) {
                                setToValue(newValue);

                                return;
                            }

                            props.onFilterChanged({
                                IsNegated: false,
                                Group: {
                                    Operator: FilterGroupOperator.And,
                                    Filters: [
                                        {
                                            IsNegated: false,
                                            Value: {
                                                LeftOperand: {
                                                    Id: props.id,
                                                },
                                                Operator: FilterValueOperator.GreaterThanOrEqual,
                                                RightOperand: {
                                                    Value: fromValue,
                                                    Expression: fromExpression,
                                                },
                                            },
                                        },
                                        {
                                            IsNegated: false,
                                            Value: {
                                                LeftOperand: {
                                                    Id: props.id,
                                                },
                                                Operator: FilterValueOperator.LessThanOrEqual,
                                                RightOperand: {
                                                    Value: newValue,
                                                },
                                            },
                                        },
                                    ],
                                },
                            });
                        }}
                        expression={toExpression}
                        onExpressionChanged={(newExpression) => {
                            if (fromValue === undefined && fromExpression === undefined) {
                                setToExpression(newExpression);

                                return;
                            }

                            props.onFilterChanged({
                                IsNegated: false,
                                Group: {
                                    Operator: FilterGroupOperator.And,
                                    Filters: [
                                        {
                                            IsNegated: false,
                                            Value: {
                                                LeftOperand: {
                                                    Id: props.id,
                                                },
                                                Operator: FilterValueOperator.GreaterThanOrEqual,
                                                RightOperand: {
                                                    Value: fromValue,
                                                    Expression: fromExpression,
                                                },
                                            },
                                        },
                                        {
                                            IsNegated: false,
                                            Value: {
                                                LeftOperand: {
                                                    Id: props.id,
                                                },
                                                Operator: FilterValueOperator.LessThanOrEqual,
                                                RightOperand: {
                                                    Expression: newExpression,
                                                },
                                            },
                                        },
                                    ],
                                },
                            });
                        }}
                        onClear={() => {
                            setToValue(undefined);
                            setToExpression(undefined);

                            props.onFilterChanged(undefined);
                        }}
                    />
                </>
            ) : (
                <FilterDatePicker
                    value={value}
                    onValueChanged={(newValue) => {
                        props.onFilterChanged({
                            IsNegated: false,
                            Value: {
                                LeftOperand: {
                                    Id: props.id,
                                },
                                Operator: operator as FilterValueOperator,
                                RightOperand: {
                                    Value: newValue,
                                },
                            },
                        });
                    }}
                    expression={expression}
                    onExpressionChanged={(newExpression) => {
                        props.onFilterChanged({
                            IsNegated: false,
                            Value: {
                                LeftOperand: {
                                    Id: props.id,
                                },
                                Operator: operator as FilterValueOperator,
                                RightOperand: {
                                    Expression: newExpression,
                                },
                            },
                        });
                    }}
                    onClear={() => {
                        setValue(undefined);
                        setExpression(undefined);

                        props.onFilterChanged(undefined);
                    }}
                    disabled={operator === FilterValueOperator.IsEmpty || operator === FilterValueOperator.IsNotEmpty}
                />
            )}
        </Stack>
    );
};

export default DateFilter;
