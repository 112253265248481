import {
    DefaultButton,
    Dialog,
    DialogFooter,
    Icon,
    ModalSpinner,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TooltipHost,
    UseForm,
    useInCoreLocalization,
    useOnChange,
    useOnMount,
    usePanelContext,
    useTheme,
} from "@in-core";
import { useState } from "react";

export enum FormButtonMode {
    BelowForm,
    PanelIfPossible,
    None,
}

export interface IFormProps
    extends Omit<
        React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
        "noValidate" | "id"
    > {
    form: UseForm;
    children?: React.ReactNode;
    /**
     * @default PanelIfPossible
     */
    buttonMode?: FormButtonMode;
    onCancel?: () => void;
    ignoreDirty?: boolean;
    removePaddingBottom?: boolean;
    fullHeight?: boolean;
    hideSaveAndCancel?: boolean;
    hideErrors?: boolean;
}

const Form = (props: IFormProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();
    const panelContext = usePanelContext();
    const [isDirtyDialogVisible, setIsDirtyDialogVisible] = useState(false);

    useOnMount(
        () => {},
        () => {
            if (
                panelContext === undefined ||
                (props.buttonMode !== FormButtonMode.PanelIfPossible && props.buttonMode !== undefined)
            ) {
                return;
            }

            panelContext.clearProps();
        },
    );

    useOnChange(() => {
        if (
            panelContext === undefined ||
            (props.buttonMode !== FormButtonMode.PanelIfPossible && props.buttonMode !== undefined) ||
            (!!props.hideSaveAndCancel && !!props.hideErrors)
        ) {
            return;
        }

        panelContext.setProps((defaultProps) => {
            const currentStyles = typeof defaultProps.styles === "function" ? {} : defaultProps.styles ?? {};

            return {
                isFooterAtBottom: true,
                styles: {
                    content: {
                        ...(typeof currentStyles.content === "object" ? currentStyles.content : {}),
                        paddingBottom: props.removePaddingBottom
                            ? 0
                            : typeof currentStyles.content === "object"
                            ? (currentStyles.content as any).paddintBottom
                            : undefined,
                    },
                    footer: {
                        ...(typeof currentStyles.footer === "object" ? currentStyles.footer : {}),
                        borderTopWidth: 1,
                        borderTopColor: theme.palette.neutralLight,
                        borderTopStyle: "solid",
                        backgroundColor: theme.palette.white,
                    },
                },
                onDismiss: props.onCancel
                    ? props.onCancel
                    : () => {
                          if (props.form.$isDirty) {
                              setIsDirtyDialogVisible(true);
                          } else {
                              defaultProps.onDismiss && defaultProps.onDismiss();
                          }
                      },
            };
        });
    }, [props.onCancel, props.form.$isDirty]);

    useOnChange(() => {
        if (
            panelContext === undefined ||
            (props.buttonMode !== FormButtonMode.PanelIfPossible && props.buttonMode !== undefined) ||
            (!!props.hideSaveAndCancel && !!props.hideErrors)
        ) {
            return;
        }

        panelContext.setProps({
            onRenderFooterContent:
                props.buttonMode === FormButtonMode.PanelIfPossible || props.buttonMode === undefined
                    ? () => {
                          return renderButtons();
                      }
                    : undefined,
        });
    }, [
        props.buttonMode,
        props.form.$allErrors,
        props.form.$id,
        props.form.$isDirty,
        props.form.$isPendingValidation,
        props.form.$isValidating,
        props.form.$isValid,
        props.onCancel,
        theme.spacing.l1,
        theme.spacing.s1,
    ]);

    const renderButtons = (withMargin?: boolean) => {
        const errors =
            (!!props.ignoreDirty ? true : props.form.$isDirty) &&
            !props.form.$isPendingValidation &&
            !props.form.$isValidating &&
            props.form.$allErrors.length > 0
                ? props.form.$allErrors
                : [];

        return (
            <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: theme.spacing.s1 }}
                styles={{
                    root: {
                        marginTop: withMargin ? theme.spacing.l1 : undefined,
                        height: 32,
                    },
                }}
            >
                {!props.hideSaveAndCancel && (
                    <>
                        <PrimaryButton
                            type="submit"
                            form={props.form.$id}
                            disabled={
                                (!!props.ignoreDirty ? false : !props.form.$isDirty) ||
                                props.form.$isPendingValidation ||
                                props.form.$isValidating ||
                                !props.form.$isValid
                            }
                        >
                            {localization.Submit}
                        </PrimaryButton>

                        <DefaultButton
                            onClick={() => {
                                if (props.onCancel) {
                                    props.onCancel();
                                    return;
                                }

                                if (panelContext?.overridenProps.onDismiss) {
                                    panelContext.overridenProps.onDismiss();
                                }
                            }}
                        >
                            {localization.Cancel}
                        </DefaultButton>
                    </>
                )}

                {props.form.$isValidating && <Spinner size={SpinnerSize.small} />}

                {!props.hideErrors && errors.length > 0 && (
                    <TooltipHost
                        content={
                            <Stack>
                                {errors.map((x, i) => {
                                    return (
                                        <Text key={i} variant="small">
                                            {x}
                                        </Text>
                                    );
                                })}
                            </Stack>
                        }
                        styles={{ root: { display: "flex" } }}
                    >
                        <Icon iconName="Error" styles={{ root: { fontSize: 18, color: theme.palette.red } }} />
                    </TooltipHost>
                )}
            </Stack>
        );
    };

    const { form, children, buttonMode, onCancel, ignoreDirty, removePaddingBottom, hideSaveAndCancel, ...formProps } =
        props;

    return (
        <form {...formProps} noValidate id={props.form.$id}>
            {props.children}

            {(panelContext === undefined || props.buttonMode === FormButtonMode.BelowForm) &&
                (!props.hideSaveAndCancel || !props.hideErrors) &&
                renderButtons(true)}

            <Dialog
                hidden={!isDirtyDialogVisible}
                dialogContentProps={{
                    title: localization.UnsavedChanges,
                    subText: localization.UnsavedChangesMessage,
                }}
                onDismiss={() => {
                    setIsDirtyDialogVisible(false);
                }}
            >
                <DialogFooter>
                    <PrimaryButton
                        text={localization.Continue}
                        onClick={async () => {
                            setIsDirtyDialogVisible(false);

                            if (props.onCancel) {
                                props.onCancel();
                                return;
                            }

                            if (panelContext?.defaultProps.onDismiss) {
                                panelContext.defaultProps.onDismiss();
                                return;
                            }
                        }}
                    />

                    <DefaultButton
                        text={localization.Cancel}
                        onClick={() => {
                            setIsDirtyDialogVisible(false);
                        }}
                    />
                </DialogFooter>
            </Dialog>

            <ModalSpinner isOpen={props.form.$isSubmitting} />
        </form>
    );
};

export default Form;
