import { DeepPartial, Dropdown, FormValue, IDropdownProps } from "@in-core";

export interface IFormDropdownProps<T extends string | number = string> extends Omit<IDropdownProps, "value"> {
    value: FormValue<T> | FormValue<T[]>;
    ignoreDirty?: boolean;
}

const FormDropdown = <T extends string | number = string>(props: IFormDropdownProps<T>) => {
    return (
        <Dropdown
            {...props}
            selectedKey={props.multiSelect ? undefined : (props.value.$value as string | number | undefined) ?? null}
            selectedKeys={props.multiSelect ? (props.value.$value as string[] | number[] | undefined) ?? [] : undefined}
            onChange={(changes) => {
                if (!props.multiSelect) {
                    (props.value as FormValue<T>).$setValue(
                        changes[0].isSelected ? (changes[0].key as DeepPartial<T>) : undefined,
                    );
                } else {
                    (props.value as FormValue<T[]>).$setValue([
                        ...((Array.isArray(props.value.$value) ? props.value.$value : []) as T[]).filter((x) => {
                            return !changes
                                .filter((y) => {
                                    return !y.isSelected;
                                })
                                .map((y) => {
                                    return y.key;
                                })
                                .includes(x);
                        }),
                        ...changes
                            .filter((x) => {
                                return x.isSelected;
                            })
                            .map((x) => {
                                return x.key;
                            }),
                    ] as T[]);
                }

                props.onChange && props.onChange(changes);
            }}
            errorMessage={
                (!!props.ignoreDirty ? true : props.value.$isDirty) &&
                !props.value.$isPendingValidation &&
                !props.value.$isValidating &&
                props.value.$errors.length > 0
                    ? props.value.$errors.join(" ")
                    : undefined
            }
        />
    );
};

export default FormDropdown;
