import { useLocation, useNavigate } from "react-router-dom";
import DocumentBrowser, { IDocumentBrowserLocation } from "../../components/DocumentBrowser";
import { useState } from "react";
import { Spinner, useOnChange } from "@in-core";
import { IsFile as PersonalIsFile } from "@in-core/api/Documents/Personal";
import { IsFile as OrganizationIsFile } from "@in-core/api/Documents/Organization";

const Documents = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [documentBrowserLocation, setDocumentBrowserLocation] = useState<IDocumentBrowserLocation | null>();

    useOnChange(async () => {
        if (documentBrowserLocation !== undefined) {
            return;
        }

        const completePath = location.pathname.split("/").filter((x) => {
            return x.length > 0;
        });

        const libraryId = completePath[1];

        if (!libraryId) {
            setDocumentBrowserLocation(null);
            return;
        }

        const path = completePath.slice(2);
        let isFile = false;
        if (path.length > 0) {
            if (libraryId === "Personal") {
                isFile = (await PersonalIsFile.callApi({ Path: path })).data!;
            }
            if (libraryId === "Organization") {
                isFile = (await OrganizationIsFile.callApi({ Path: path })).data!;
            }
        }

        setDocumentBrowserLocation(
            isFile
                ? {
                      libraryId: libraryId,
                      directoryPath: path.slice(0, path.length - 1),
                      fileName: path[path.length - 1],
                  }
                : { libraryId: libraryId, directoryPath: path, fileName: null },
        );
    }, [location]);

    if (documentBrowserLocation === undefined) {
        return <Spinner />;
    }

    return (
        <DocumentBrowser
            location={documentBrowserLocation}
            onLocationChanged={(newDocumentBrowserLocation) => {
                setDocumentBrowserLocation(newDocumentBrowserLocation);
                navigate(
                    `/Documents/${newDocumentBrowserLocation.libraryId}${newDocumentBrowserLocation.directoryPath
                        .map((x) => {
                            return `/${x}`;
                        })
                        .join("")}${
                        newDocumentBrowserLocation.fileName ? `/${newDocumentBrowserLocation.fileName}` : ""
                    }`,
                );
            }}
        />
    );
};

export default Documents;
