import { Dropdown, Group, IDataTableColumn, Icon, Stack, useInCoreLocalization, useTheme } from "@in-core";

export interface IGroupDropdownProps<TData = any> {
    columns: IDataTableColumn<TData>[];
    group: Group | undefined;
    onGroupChanged: (newGroup: Group | undefined) => void;
    level?: number;
}

const GroupDropdown = <TData extends object = any>(props: IGroupDropdownProps<TData>) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();

    const level = props.level ?? 0;

    return (
        <>
            <Dropdown
                options={props.columns.map((x) => {
                    return { key: x.id, text: x.name, data: x };
                })}
                selectedKey={props.group?.Id ?? null}
                onChange={(items) => {
                    const selectedItemKey = items.find((x) => {
                        return x.isSelected;
                    })?.key;

                    if (selectedItemKey === undefined) {
                        props.onGroupChanged(undefined);
                        return;
                    }

                    props.onGroupChanged({
                        Id: selectedItemKey as string,
                    });
                }}
                placeholder={localization.DataTable.ColumnDropdownPlaceholder}
                styles={{ root: { marginLeft: level * 12 } }}
                onRenderOption={(renderProps, defaultRender) => {
                    if (!renderProps?.data.icon) {
                        return defaultRender!(renderProps);
                    }

                    return (
                        <Stack horizontal>
                            <Icon
                                iconName={renderProps.data.icon}
                                styles={{ root: { marginRight: theme.spacing.s2 } }}
                            />

                            {defaultRender!(renderProps)}
                        </Stack>
                    );
                }}
            />

            {props.group && props.columns.length > 1 && (
                <GroupDropdown
                    columns={props.columns.filter((x) => {
                        return x.id !== props.group!.Id;
                    })}
                    group={props.group.NestedGroup}
                    onGroupChanged={(newNestedGroup) => {
                        props.onGroupChanged({
                            Id: props.group!.Id,
                            NestedGroup: newNestedGroup,
                        });
                    }}
                    level={level + 1}
                />
            )}
        </>
    );
};

export default GroupDropdown;
