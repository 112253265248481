import {
    IMenuItem,
    INavLink,
    INavLinkGroup,
    IRoutableItem,
    Nav,
    useInCoreLocalization,
    useOnChange,
    usePermissionService,
    useTheme,
} from "@in-core";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const getMenuItemLink = (
    item: IMenuItem,
    onLinkClick: (url: string) => void,
    routableItems: IRoutableItem[],
    iconSize: number,
): INavLink => {
    const routableItem = item.permissionId
        ? routableItems.find((x) => {
              return x.id === item.permissionId;
          })
        : undefined;

    const itemLink: INavLink = {
        name: item.text ?? routableItem?.name ?? "",
        url: "",
        title: (item.text ?? routableItem?.name ?? "") + (routableItem ? ` (${routableItem.id})` : ""),
    };

    if (routableItem) {
        itemLink.url = `/${routableItem.url}`;
        itemLink.iconProps = {
            style: {
                fontSize: iconSize,
                lineHeight: iconSize + "px",
                width: iconSize,
                height: iconSize,
            },
            iconName: routableItem.icon,
        };
        itemLink.onClick = (ev, item) => {
            ev?.preventDefault();
            onLinkClick(item?.url!);
        };
    } else {
        itemLink.links = item.submenus
            ?.filter((x) => {
                return menuItemsFilter(x, routableItems);
            })
            .map((x) => {
                return getMenuItemLink(x, onLinkClick, routableItems, iconSize);
            });
    }

    return itemLink;
};

const menuItemsFilter = (menuItem: IMenuItem, routableItems: IRoutableItem[]): boolean => {
    return (
        menuItem.submenus !== undefined ||
        (menuItem.permissionId !== undefined &&
            routableItems
                .map((x) => {
                    return x.id;
                })
                .includes(menuItem.permissionId))
    );
};

export interface IMenuViewProps {
    menuItems: IMenuItem[];
    routableItems: IRoutableItem[];
    iconSize: number;
    onItemSelected?: () => void;
    homeHidden: boolean;
    homeText: string | undefined;
    homeIcon: string | undefined;
    documentsHidden: boolean;
    documentsText: string | undefined;
    documentsIcon: string | undefined;
}

const MenuView = (props: IMenuViewProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [navLinkGroups, setNavLinkGroups] = useState<INavLinkGroup[]>([]);
    const localization = useInCoreLocalization();
    const permissionService = usePermissionService();

    useOnChange(() => {
        const defaultNavLinks: INavLink[] = [];

        if (!props.homeHidden) {
            defaultNavLinks.push({
                name: props.homeText ?? localization.HomeTitle,
                url: "/",
                title: props.homeText ?? localization.HomeTitle,
                iconProps: {
                    style: {
                        fontSize: props.iconSize,
                        lineHeight: props.iconSize + "px",
                        width: props.iconSize,
                        height: props.iconSize,
                    },
                    iconName: props.homeIcon ?? "Home",
                },
                onClick: (ev) => {
                    ev?.preventDefault();
                    navigate("/");
                    props.onItemSelected && props.onItemSelected();
                },
            });
        }

        if (!props.documentsHidden && permissionService.isAuthorized("PersonalDocuments", "OrganizationDocuments")) {
            defaultNavLinks.push({
                name: props.documentsText ?? localization.DocumentsTitle,
                url: "/Documents",
                title: props.documentsText ?? localization.DocumentsTitle,
                iconProps: {
                    style: {
                        fontSize: props.iconSize,
                        lineHeight: props.iconSize + "px",
                        width: props.iconSize,
                        height: props.iconSize,
                    },
                    iconName: props.documentsIcon ?? "Documentation",
                },
                onClick: (ev) => {
                    ev?.preventDefault();
                    navigate("/Documents");
                    props.onItemSelected && props.onItemSelected();
                },
            });
        }

        setNavLinkGroups([
            {
                links: [
                    ...defaultNavLinks,
                    ...props.menuItems
                        .filter((x) => {
                            return menuItemsFilter(x, props.routableItems);
                        })
                        .map((x) => {
                            return {
                                ...getMenuItemLink(
                                    x,
                                    (url) => {
                                        navigate(url);
                                        props.onItemSelected && props.onItemSelected();
                                    },
                                    props.routableItems!,
                                    props.iconSize,
                                ),
                                isExpanded: true,
                            };
                        }),
                ],
            },
        ]);
    }, [
        props.menuItems,
        props.routableItems,
        props.iconSize,
        props.homeHidden,
        props.homeText,
        props.homeText,
        props.documentsHidden,
        props.documentsText,
        props.documentsText,
    ]);

    return (
        <Nav
            groups={navLinkGroups}
            styles={{
                linkText: {
                    color: theme.semanticColors.bodyText,
                },
                group: {
                    "> button": {
                        margin: 0,
                        height: 44,
                    },
                },
            }}
        />
    );
};

export default MenuView;
