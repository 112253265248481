import { EntityDataTable } from "@in-core";
import DataEntry from "./DataEntry";

export interface IRolesProps {}

const Roles = (props: IRolesProps) => {
    return (
        <EntityDataTable
            routable
            entity="Roles"
            defaultSort={{ Id: "Description", IsDescending: false }}
            innerComponents={{
                Create: <DataEntry />,
                Read: <DataEntry />,
                Update: <DataEntry />,
            }}
        />
    );
};

export default Roles;
