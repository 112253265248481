import { Dropdown, Icon, Stack, Text, useInCoreLocalization, useTheme } from "@in-core";
import { FilterValueOperator } from "@in-core/api";

export type OperatorDropdownOption = FilterValueOperator | "InRange";

export const FilterValueOperatorSymbols: Record<OperatorDropdownOption, string> = {
    Equal: "=",
    NotEqual: "≠",
    GreaterThan: ">",
    GreaterThanOrEqual: "≥",
    LessThan: "<",
    LessThanOrEqual: "≤",
    IsEmpty: "=''",
    IsNotEmpty: "≠''",
    Contains: "*a*",
    NotContains: "!*a*",
    StartsWith: "a*",
    NotStartsWith: "!a*",
    EndsWith: "*a",
    NotEndsWith: "!*a",
    InRange: "↔",
};

export interface IOperatorDropdownProps {
    operators: OperatorDropdownOption[];
    selectedOperator: OperatorDropdownOption;
    onChange: (operator: OperatorDropdownOption) => void;
}

const OperatorDropdown = (props: IOperatorDropdownProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();

    return (
        <Dropdown
            title={localization.DataTable[props.selectedOperator as FilterValueOperator]}
            options={props.operators.map((x) => {
                return { key: x, text: FilterValueOperatorSymbols[x] };
            })}
            selectedKey={props.selectedOperator}
            onChange={(changes) => {
                props.onChange(changes[0].key as FilterValueOperator);
            }}
            styles={{
                dropdown: {
                    ":hover": {
                        ".ms-Dropdown-title": {
                            borderColor: theme.palette.themeLighter,
                        },
                    },
                },
                title: {
                    "backgroundColor": theme.palette.neutralLight,
                    "borderColor": theme.palette.neutralLight,
                    "color": theme.palette.neutralPrimary,
                    "paddingRight": 8,
                    "paddingLeft": 8,
                    "width": 68,
                    ":hover": {
                        backgroundColor: theme.palette.themeLighter,
                        borderColor: theme.palette.themeLighter,
                    },
                },
                caretDownWrapper: {
                    display: "none",
                },
                callout: {
                    minWidth: 175,
                },
            }}
            onRenderTitle={(renderProps, defaultRender) => {
                return (
                    <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
                        <Icon iconName="FilterSolid" />

                        <div>{defaultRender!(renderProps)}</div>
                    </Stack>
                );
            }}
            onRenderOption={(renderProps, defaultRender) => {
                return (
                    <Stack horizontal>
                        <div style={{ width: 30 }}>{defaultRender!(renderProps)}</div>

                        <Text>{localization.DataTable[renderProps!.key as FilterValueOperator]}</Text>
                    </Stack>
                );
            }}
        />
    );
};

export default OperatorDropdown;
