import { INumericFieldProps, NumericField, Spinner, SpinnerSize, useTheme } from "@in-core";
import { FormValue } from "..";

export interface IFormNumericFieldProps extends Omit<INumericFieldProps, "value" | "type"> {
    value: FormValue<number>;
    ignoreDirty?: boolean;
}

const FormNumericField = (props: IFormNumericFieldProps) => {
    const theme = useTheme();

    const defaultRenderInput = (
        defaultRender:
            | ((
                  props?:
                      | (React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>)
                      | undefined,
              ) => JSX.Element | null)
            | undefined,
    ) => {
        return (
            renderProps?:
                | (React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>)
                | undefined,
        ) => {
            return (
                <>
                    {defaultRender!(renderProps)}

                    {props.value.$isValidating && (
                        <Spinner
                            size={SpinnerSize.small}
                            styles={{ root: { marginLeft: theme.spacing.s2, marginRight: theme.spacing.s2 } }}
                        />
                    )}
                </>
            );
        };
    };

    return (
        <NumericField
            {...props}
            value={props.value.$value ?? null}
            onChange={(e, newValue) => {
                props.value.$setValue(newValue ?? undefined);
                props.onChange && props.onChange(e, newValue);
            }}
            onRenderInput={(renderProps, defaultRender) => {
                return props.onRenderInput
                    ? props.onRenderInput(renderProps, defaultRenderInput(defaultRender))
                    : defaultRenderInput(defaultRender)(renderProps);
            }}
            errorMessage={
                (!!props.ignoreDirty ? true : props.value.$isDirty) &&
                !props.value.$isPendingValidation &&
                !props.value.$isValidating &&
                props.value.$errors.length > 0
                    ? props.value.$errors.join(" ")
                    : undefined
            }
        />
    );
};

export default FormNumericField;
