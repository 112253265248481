import { useOnChange } from "@in-core";
import { useParams } from "react-router-dom";

export interface IRouteListenerProps {
    onChange: (settingId?: string) => void;
}

const RouteListener = (props: IRouteListenerProps) => {
    const params = useParams();

    useOnChange(() => {
        props.onChange(params.settingId);
    }, [params.settingId]);

    return null;
};

export default RouteListener;
