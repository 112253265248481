import { useOnChange } from "@in-core";
import { useLocation, useParams } from "react-router-dom";
import * as qs from "query-string";

export interface IRouteListenerProps {
    onChange: (innerPermission?: string, qsObject?: any) => void;
}

const RouteListener = (props: IRouteListenerProps) => {
    const params = useParams();
    const location = useLocation();

    useOnChange(() => {
        props.onChange(params.innerPermission, qs.parse(location.search));
    }, [params.innerPermission, location.search]);

    return null;
};

export default RouteListener;
