import { IconButton, IRawStyle, ITextFieldProps, ITextFieldStyles, TextField, useTheme } from "@fluentui/react";
import { DeepPartial } from "@in-core/forms";
import useOnChange from "@in-core/hooks/useOnChange";
import { useState } from "react";

export interface INumericFieldProps extends Omit<ITextFieldProps, "type" | "value" | "defaultValue" | "onChange"> {
    value?: number | null;
    defaultValue?: number;
    onChange?: (event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: number | null) => void;
    hideClear?: boolean;
}

const NumericField = (props: INumericFieldProps) => {
    const theme = useTheme();
    const [value, setValue] = useState<number | null | undefined>(
        props.value !== undefined ? props.value : props.defaultValue,
    );

    useOnChange(() => {
        setValue(props.value);
    }, [props.value]);

    const defaultRenderInput = (
        defaultRender:
            | ((
                  props?:
                      | (React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>)
                      | undefined,
              ) => JSX.Element | null)
            | undefined,
    ) => {
        return (
            renderProps?:
                | (React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>)
                | undefined,
        ) => {
            return (
                <>
                    {defaultRender!(renderProps)}

                    {!props.readOnly && !props.hideClear && !props.disabled && value !== undefined && (
                        <IconButton
                            iconProps={{
                                iconName: "Clear",
                                styles: {
                                    root: {
                                        fontSize: 10,
                                        color: theme.palette.neutralDark,
                                    },
                                },
                            }}
                            styles={{
                                root: {
                                    height: "100%",
                                    border: 0,
                                    backgroundColor: (
                                        (props.styles as DeepPartial<ITextFieldStyles> | undefined)?.field as IRawStyle
                                    )?.backgroundColor,
                                },
                            }}
                            onClick={() => {
                                setValue(null);
                                props.onChange && props.onChange(undefined, null);
                            }}
                            tabIndex={-1}
                        />
                    )}
                </>
            );
        };
    };

    return (
        <TextField
            {...props}
            type="number"
            defaultValue={props.defaultValue?.toString()}
            value={value === null ? "" : value?.toString()}
            onChange={(e, newValue) => {
                const actualNewValue = newValue === undefined ? undefined : newValue === "" ? null : Number(newValue);

                setValue(actualNewValue);
                props.onChange && props.onChange(e, actualNewValue);
            }}
            autoComplete="off"
            onRenderInput={(renderProps, defaultRender) => {
                return props.onRenderInput
                    ? props.onRenderInput(renderProps, defaultRenderInput(defaultRender))
                    : defaultRenderInput(defaultRender)(renderProps);
            }}
        />
    );
};

export default NumericField;
