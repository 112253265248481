import { useState } from "react";
import { DefaultButton, DialogFooter, PrimaryButton, Spinner, TextField, useInCoreLocalization } from "@in-core";
import { fileNameRegex } from "@in-core/components/FileUpload";

export interface IRenameProps {
    onDismiss: () => void;
    onSave: (name: string) => Promise<void>;
    currentName: string;
    existingNames: string[];
}

const Rename = (props: IRenameProps) => {
    const fileSegments = props.currentName.split(".");
    const fileName =
        fileSegments.length < 2 ? props.currentName : fileSegments.slice(0, fileSegments.length - 1).join(".");
    const fileExtension = fileSegments.length < 2 ? undefined : fileSegments.reverse()[0];

    const [name, setName] = useState(fileName);
    const [isDirty, setIsDirty] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const localization = useInCoreLocalization();

    const completeName = fileExtension ? `${name}.${fileExtension}` : name;

    let errorMessage: string | undefined = undefined;
    if (!fileNameRegex.test(completeName)) {
        errorMessage = localization.Documents.NameInvalid;
    }
    if (
        completeName !== props.currentName &&
        props.existingNames
            .map((x) => {
                return x.toLowerCase();
            })
            .includes(completeName.toLowerCase())
    ) {
        errorMessage = localization.Documents.NameAlreadyExists;
    }

    const isValid = errorMessage === undefined;

    return (
        <form
            onSubmit={async (e) => {
                e.preventDefault();
                setIsProcessing(true);
                await props.onSave(completeName);
                setIsProcessing(false);
                props.onDismiss && props.onDismiss();
            }}
        >
            <TextField
                label={localization.Documents.NameColumn}
                placeholder={fileName}
                value={name}
                onChange={(_, newValue) => {
                    setName(newValue?.trim() ?? "");
                    setIsDirty(true);
                }}
                errorMessage={isDirty ? errorMessage : undefined}
                suffix={fileExtension ? `.${fileExtension}` : undefined}
            />

            <DialogFooter
                styles={{
                    actionsRight: {
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                    },
                    action: { display: "flex" },
                }}
            >
                {isProcessing && <Spinner />}

                <PrimaryButton disabled={!isValid || isProcessing || name === props.currentName} type="submit">
                    {localization.Save}
                </PrimaryButton>

                <DefaultButton onClick={props.onDismiss} disabled={isProcessing}>
                    {localization.Cancel}
                </DefaultButton>
            </DialogFooter>
        </form>
    );
};

export default Rename;
