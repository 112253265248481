import { MessageBar, MessageBarType, Stack, useInCoreLocalization, useOnMount, useTheme } from "@in-core";
import { LogError } from "@in-core/api/Shell";
import { FallbackProps } from "react-error-boundary";

const ErrorHandler = (props: FallbackProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();

    useOnMount(() => {
        LogError.callApi({ Error: props.error });
    });

    return (
        <Stack styles={{ root: { padding: theme.spacing.m } }}>
            <MessageBar messageBarType={MessageBarType.error}>{localization.Error}</MessageBar>
        </Stack>
    );
};

export default ErrorHandler;
