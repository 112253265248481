import {
    Dropdown,
    Text,
    useTheme,
    Stack,
    SearchBox,
    IconButton,
    IDropdownProps,
    Spinner,
    IDataTableProps,
    DefaultButton,
    ISearchBox,
    List,
    SelectionMode,
    DataTablePanelSelect,
    IDataTablePanelSelectProps,
    IDropdown,
    getKeyString,
    areArraysEqual,
    getKeyObject,
    timeout,
    FilterGroupOperator,
    FilterValueOperator,
    useOnChange,
    SearchOptions,
    search,
    IStyleFunctionOrObject,
    IPanelStyleProps,
    IPanelStyles,
    generateUuid,
    areObjectsEqual,
    useInCoreLocalization,
} from "@in-core";
import React, { useRef, useState } from "react";
import { ISelectionChangeItem } from "../DataTable";

const defaultNumberOfSearchResults = 5;
const defaultMinSearchLength = 2;

export interface IAdditionalAction {
    key: string;
    text: string;
    icon?: string;
    onClick: (serachValue: string) => void;
}

export interface IDataTableDropdownProps<TData = any>
    extends Omit<
            IDropdownProps,
            "options" | "selectedKey" | "selectedKeys" | "defaultSelectedKey" | "defaultSelectedKeys" | "onChange"
        >,
        Omit<
            IDataTableProps<TData>,
            "selectedKey" | "selectedKeys" | "defaultSelectedKey" | "defaultSelectedKeys" | "onChange"
        > {
    selectedKey?: any | null;
    selectedKeys?: any[] | null;
    defaultSelectedKey?: any;
    defaultSelectedKeys?: any[];
    onChange?: (items: ISelectionChangeItem[]) => void;
    numberOfSearchResults?: number;
    containerStyle?: React.CSSProperties;
    panelStyles?: IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles>;
    headerText?: string;
    loadAllData?: boolean;
    loadInitialData?: boolean;
    inMemorySearch?: boolean;
    renderDataTablePanelSelect?: (dataTablePanelSelectProps: IDataTablePanelSelectProps<TData>) => React.ReactNode;
    hideId?: boolean;
    disableAdvancedSearch?: boolean;
    minSearchLength?: number;
    additionalActions?: IAdditionalAction[];
}

const DataTableDropdown = <TData extends object = any>(props: IDataTableDropdownProps) => {
    const minSearchLength =
        Array.isArray(props.data) || (props.loadAllData && props.inMemorySearch !== false)
            ? 0
            : props.minSearchLength ?? defaultMinSearchLength;
    const theme = useTheme();
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
    const [searchItems, setSearchItems] = useState<TData[]>();
    const lastSearchLengthRef = useRef<number>();
    const lastSeaechSelectedKeysRef = useRef<any[]>();
    const lastSearchIdRef = useRef(generateUuid());
    const [options, setOptions] = useState<TData[]>([]);
    const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
    const dropdownRef = useRef<IDropdown>(null);
    const searchBoxRef = useRef<ISearchBox>(null);
    const [inMemoryData, setInMemoryData] = useState<TData[]>();
    const localization = useInCoreLocalization();

    const getData = async () => {
        if (props.loadInitialData === false && searchValue.trim().length < minSearchLength) {
            setSearchItems([]);
            return;
        }

        const searchProperties = [];

        const idPropertiesArray =
            props.idProperties !== undefined
                ? props.idProperties
                : props.idProperty !== undefined
                ? [props.idProperty]
                : [];

        searchProperties.push(...idPropertiesArray);

        const descriptionPropertiesArray =
            props.descriptionProperties !== undefined
                ? props.descriptionProperties
                : props.descriptionProperty !== undefined
                ? [props.descriptionProperty]
                : [];

        searchProperties.push(...descriptionPropertiesArray);

        const inMemorySearchOptions: Record<string | number | symbol, Partial<SearchOptions<any>>> = {};

        idPropertiesArray.forEach((x) => {
            inMemorySearchOptions[x] = {};
        });

        descriptionPropertiesArray.forEach((x) => {
            inMemorySearchOptions[x] = { equalsScore: 800, startsWithScore: 600, includesScore: 200 };
        });

        const searchFilter =
            selectedKeys.length === 0
                ? undefined
                : {
                      IsNegated: true,
                      Group: {
                          Operator: FilterGroupOperator.Or,
                          Filters: selectedKeys.map((x) => {
                              return {
                                  IsNegated: false,
                                  Group: {
                                      Operator: FilterGroupOperator.And,
                                      Filters:
                                          idPropertiesArray.length === 1
                                              ? [
                                                    {
                                                        IsNegated: false,
                                                        Value: {
                                                            LeftOperand: {
                                                                Id: idPropertiesArray[0] as string,
                                                            },
                                                            Operator: FilterValueOperator.Equal,
                                                            RightOperand: {
                                                                Value: x,
                                                            },
                                                        },
                                                    },
                                                ]
                                              : idPropertiesArray.map((y) => {
                                                    return {
                                                        IsNegated: false,
                                                        Value: {
                                                            LeftOperand: {
                                                                Id: y as string,
                                                            },
                                                            Operator: FilterValueOperator.Equal,
                                                            RightOperand: {
                                                                Value: x[y],
                                                            },
                                                        },
                                                    };
                                                }),
                                  },
                              };
                          }),
                      },
                  };

        if (props.data === undefined) {
            return;
        }

        if (Array.isArray(props.data)) {
            setSearchItems(
                search(
                    props.data.filter((x) => {
                        return !selectedKeys
                            .map((y) => {
                                return getKeyString(y, idPropertiesArray);
                            })
                            .includes(getKeyString(x, idPropertiesArray));
                    }),
                    searchValue,
                    inMemorySearchOptions as any,
                ),
            );

            return;
        }

        if (props.loadAllData && props.inMemorySearch !== false) {
            let actualInMemoryData = inMemoryData;
            if (actualInMemoryData === undefined) {
                const completeInMemoryData = await props.data({
                    AdditionalFilter:
                        props.additionalFilter !== undefined && searchFilter !== undefined
                            ? {
                                  IsNegated: false,
                                  Group: {
                                      Operator: FilterGroupOperator.And,
                                      Filters: [props.additionalFilter, searchFilter],
                                  },
                              }
                            : props.additionalFilter
                            ? props.additionalFilter
                            : searchFilter
                            ? searchFilter
                            : undefined,
                });
                setInMemoryData(completeInMemoryData.Data);
                actualInMemoryData = completeInMemoryData.Data;
            }

            setSearchItems(
                search(
                    actualInMemoryData.filter((x) => {
                        return !selectedKeys
                            .map((y) => {
                                return getKeyString(y, idPropertiesArray);
                            })
                            .includes(getKeyString(x! as any, idPropertiesArray));
                    }),
                    searchValue,
                    inMemorySearchOptions as any,
                ),
            );

            return;
        }

        const wasLastSearchLessThanMinSearchLenght = (lastSearchLengthRef.current ?? 0) < minSearchLength;
        const isThisSearchLessThanMinSearchLenght = searchValue.trim().length < minSearchLength;
        const arePreviousAndThisSelectedKeysEqual = areObjectsEqual(
            lastSeaechSelectedKeysRef.current,
            selectedKeys,
            true,
        );

        if (
            lastSearchLengthRef.current !== undefined &&
            wasLastSearchLessThanMinSearchLenght &&
            isThisSearchLessThanMinSearchLenght &&
            arePreviousAndThisSelectedKeysEqual
        ) {
            return;
        }

        lastSearchLengthRef.current = searchValue.trim().length;
        const searchId = generateUuid();
        lastSearchIdRef.current = searchId;

        setSearchItems(undefined);

        const newItems = await props.data({
            Filter: selectedKeys
                ? undefined
                : {
                      IsNegated: true,
                      Value: {
                          LeftOperand: { Value: selectedKeys },
                          Operator: FilterValueOperator.Contains,
                          RightOperand: { Id: props.idProperties as string | undefined },
                      },
                  },
            AdditionalFilter:
                props.additionalFilter !== undefined && searchFilter !== undefined
                    ? {
                          IsNegated: false,
                          Group: {
                              Operator: FilterGroupOperator.And,
                              Filters: [props.additionalFilter, searchFilter],
                          },
                      }
                    : props.additionalFilter
                    ? props.additionalFilter
                    : searchFilter
                    ? searchFilter
                    : undefined,
            Search:
                searchValue.trim().length < minSearchLength
                    ? undefined
                    : {
                          SearchTerm: searchValue.trim(),
                          SearchProperties: searchProperties as string[],
                      },
            Pagination: props.loadAllData
                ? undefined
                : {
                      PageIndex: 0,
                      ItemsPerPage: props.numberOfSearchResults ?? defaultNumberOfSearchResults,
                  },
        });

        if (lastSearchIdRef.current === searchId) {
            setSearchItems(newItems.Data);
        }
    };

    useOnChange(
        () => {
            getData();
        },
        [searchValue, selectedKeys],
        {
            debounce: Array.isArray(props.data) || (props.loadAllData && props.inMemorySearch !== false) ? 0 : 750,
            fireOnFirstSet: false,
        },
    );

    useOnChange(async () => {
        const idPropertiesArray =
            props.idProperties !== undefined
                ? props.idProperties
                : props.idProperty !== undefined
                ? [props.idProperty]
                : [];

        if (idPropertiesArray.length === 0) {
            return;
        }

        setSelectedKeys(
            (props.multiSelect
                ? props.selectedKeys === null
                    ? []
                    : props.selectedKeys
                : props.selectedKey === null
                ? []
                : props.selectedKey === undefined
                ? undefined
                : [props.selectedKey]) ?? [],
        );
    }, [props.selectedKey, props.selectedKeys, props.multiSelect, props.idProperty, props.idProperties]);

    useOnChange(async () => {
        const idPropertiesArray =
            props.idProperties !== undefined
                ? props.idProperties
                : props.idProperty !== undefined
                ? [props.idProperty]
                : [];

        if (idPropertiesArray.length === 0) {
            return;
        }

        if (props.data === undefined) {
            return;
        }

        if (Array.isArray(props.data)) {
            const selectedKeysStrings = selectedKeys.map((x) => {
                return getKeyString(x, idPropertiesArray);
            });

            const newOptions = props.data.filter((x) => {
                return selectedKeysStrings.includes(getKeyString(x, idPropertiesArray));
            });

            if (
                !areArraysEqual(newOptions, options, true, (a, b) => {
                    return getKeyString(a, idPropertiesArray) === getKeyString(b, idPropertiesArray);
                })
            ) {
                setOptions(newOptions);
            }

            return;
        }

        const optionKeyStrings = options.map((x) => {
            return getKeyString(x as any, idPropertiesArray);
        });
        const additionalOptionKeys = selectedKeys.filter((x) => {
            return !optionKeyStrings.includes(getKeyString(x, idPropertiesArray));
        });

        if (additionalOptionKeys.length > 0) {
            const newOptionsResponse = await props.data({
                Filter: {
                    IsNegated: false,
                    Group: {
                        Operator: FilterGroupOperator.Or,
                        Filters: additionalOptionKeys.map((x) => {
                            return {
                                IsNegated: false,
                                Group: {
                                    Operator: FilterGroupOperator.And,
                                    Filters:
                                        idPropertiesArray.length === 1
                                            ? [
                                                  {
                                                      IsNegated: false,
                                                      Value: {
                                                          LeftOperand: {
                                                              Id: idPropertiesArray[0] as string,
                                                          },
                                                          Operator: FilterValueOperator.Equal,
                                                          RightOperand: {
                                                              Value: x,
                                                          },
                                                      },
                                                  },
                                              ]
                                            : idPropertiesArray.map((y) => {
                                                  return {
                                                      IsNegated: false,
                                                      Value: {
                                                          LeftOperand: {
                                                              Id: y as string,
                                                          },
                                                          Operator: FilterValueOperator.Equal,
                                                          RightOperand: {
                                                              Value: x[y],
                                                          },
                                                      },
                                                  };
                                              }),
                                },
                            };
                        }),
                    },
                },
            });

            const newOptions = newOptionsResponse.Data.length > 0 ? [...options, ...newOptionsResponse.Data] : options;
            setOptions(newOptions);
        }
    }, [selectedKeys, options, props.idProperty, props.idProperties]);

    const getStringRepresentation = (item: TData) => {
        const idPropertiesArray =
            props.idProperties !== undefined
                ? props.idProperties
                : props.idProperty !== undefined
                ? [props.idProperty]
                : [];

        const descriptionPropertiesArray =
            props.descriptionProperties !== undefined
                ? props.descriptionProperties
                : props.descriptionProperty !== undefined
                ? [props.descriptionProperty]
                : [];

        const idPropertiesPart = idPropertiesArray
            .map((x) => {
                return (item as any)[x];
            })
            .join(", ");

        const descriptionPropertiesPart = descriptionPropertiesArray
            .map((x) => {
                return (item as any)[x];
            })
            .filter((x) => {
                return x !== undefined && x !== null;
            })
            .join(", ");

        if (descriptionPropertiesArray.length === 0) {
            return idPropertiesPart;
        }

        if (idPropertiesArray.length === 0 || props.hideId) {
            return descriptionPropertiesPart;
        }

        return `${descriptionPropertiesPart} (${idPropertiesPart})`;
    };

    const idPropertiesArray =
        props.idProperties !== undefined
            ? props.idProperties
            : props.idProperty !== undefined
            ? [props.idProperty]
            : [];

    return (
        <span style={props.containerStyle}>
            <Dropdown
                {...props}
                componentRef={props.componentRef ?? dropdownRef}
                options={
                    Array.isArray(props.data)
                        ? props.data.map((x) => {
                              return {
                                  key: getKeyString(x as any, idPropertiesArray),

                                  text: getStringRepresentation(x),

                                  data: x,
                              };
                          })
                        : options.map((x) => {
                              return {
                                  key: getKeyString(x as any, idPropertiesArray),
                                  text: getStringRepresentation(x),
                                  data: x,
                              };
                          })
                }
                onFocus={() => {
                    if (props.loadAllData !== false && inMemoryData === undefined && searchItems === undefined) {
                        getData();
                    }
                }}
                selectedKey={
                    props.multiSelect
                        ? undefined
                        : selectedKeys.length === 0
                        ? null
                        : getKeyString(selectedKeys[0], idPropertiesArray)
                }
                selectedKeys={
                    props.multiSelect
                        ? selectedKeys.length === 0
                            ? null
                            : selectedKeys.map((x) => {
                                  return getKeyString(x, idPropertiesArray);
                              })
                        : undefined
                }
                onChange={(changes) => {
                    setSelectedKeys((prevSelectedKeys) => {
                        return [
                            ...prevSelectedKeys.filter((x) => {
                                const idPropertiesArray =
                                    props.idProperties !== undefined
                                        ? props.idProperties
                                        : props.idProperty !== undefined
                                        ? [props.idProperty]
                                        : [];

                                return !changes
                                    .filter((y) => {
                                        return !y.isSelected;
                                    })
                                    .map((y) => {
                                        return y.key;
                                    })
                                    .includes(getKeyString(x, idPropertiesArray));
                            }),
                            ...changes
                                .filter((x) => {
                                    return x.isSelected;
                                })
                                .map((x) => {
                                    return getKeyObject(x, idPropertiesArray as any);
                                }),
                        ];
                    });

                    props.onChange && props.onChange(changes);
                }}
                onDismiss={() => {
                    if (!isAdvancedSearchOpen) {
                        setSearchValue("");
                    }

                    props.onDismiss && props.onDismiss();
                }}
                onRenderList={() => {
                    return (
                        <Stack>
                            <div
                                style={{
                                    padding: theme.spacing.s1,
                                }}
                            >
                                <SearchBox
                                    underlined
                                    placeholder={localization.Search}
                                    value={searchValue}
                                    onChange={(_, newValue) => {
                                        return setSearchValue(newValue ?? "");
                                    }}
                                    componentRef={searchBoxRef}
                                    onSearch={
                                        props.disableAdvancedSearch
                                            ? undefined
                                            : () => {
                                                  setIsAdvancedSearchOpen(true);
                                              }
                                    }
                                />

                                <Text styles={{ root: { color: theme.palette.neutralTertiary, fontSize: 12 } }}>
                                    {searchValue.trim().length < minSearchLength
                                        ? props.disableAdvancedSearch
                                            ? `${localization.DataTableDropdown.MinSearchEnter} ${minSearchLength} ${localization.DataTableDropdown.MinSearchDescriptionWithoutAdvanced}`
                                            : `${localization.DataTableDropdown.MinSearchEnter} ${minSearchLength} ${localization.DataTableDropdown.MinSearchDescriptionWithAdvanced}`
                                        : props.disableAdvancedSearch
                                        ? localization.DataTableDropdown.SelectItemWithoutAdvanced
                                        : localization.DataTableDropdown.SelectItemWithAdvanced}
                                </Text>
                            </div>

                            <List
                                items={selectedKeys}
                                onRenderCell={(x) => {
                                    const keyString = getKeyString(x, idPropertiesArray);
                                    const option = options.find((y) => {
                                        return getKeyString(y as any, idPropertiesArray) === keyString;
                                    });

                                    return (
                                        <Stack horizontal verticalAlign="center">
                                            <IconButton
                                                iconProps={{ iconName: "Cancel" }}
                                                onClick={() => {
                                                    const numberOfItems = selectedKeys.length;

                                                    setSelectedKeys((prevSelectedKeys) => {
                                                        return prevSelectedKeys.filter((y) => {
                                                            return getKeyString(y, idPropertiesArray) !== keyString;
                                                        });
                                                    });

                                                    setOptions((prevOptions) => {
                                                        return prevOptions.filter((y) => {
                                                            return (
                                                                getKeyString(y as any, idPropertiesArray) !== keyString
                                                            );
                                                        });
                                                    });

                                                    if (numberOfItems === 1) {
                                                        searchBoxRef.current?.focus();
                                                    }

                                                    props.onChange &&
                                                        props.onChange([
                                                            {
                                                                key: x,
                                                                isSelected: false,
                                                            },
                                                        ]);
                                                }}
                                            />

                                            {option && (
                                                <Text
                                                    styles={{
                                                        root: {
                                                            marginLeft: theme.spacing.s2,
                                                            paddingRight: theme.spacing.s1,
                                                            display: "block",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis",
                                                        },
                                                    }}
                                                    title={getStringRepresentation(option)}
                                                >
                                                    {getStringRepresentation(option)}
                                                </Text>
                                            )}
                                        </Stack>
                                    );
                                }}
                            />

                            {searchItems === undefined && (
                                <Spinner styles={{ root: { marginBottom: theme.spacing.s1 } }} />
                            )}

                            {searchItems !== undefined &&
                                searchItems.length === 0 &&
                                !(
                                    (minSearchLength === 0 && searchValue.trim().length === 0) ||
                                    searchValue.trim().length < minSearchLength
                                ) && (
                                    <Text styles={{ root: { padding: theme.spacing.s1 } }}>
                                        {localization.DataTableDropdown.NothingFound}
                                    </Text>
                                )}

                            {searchItems !== undefined && searchItems.length > 0 && (
                                <List
                                    items={searchItems}
                                    onRenderCell={(x) => {
                                        return (
                                            <DefaultButton
                                                styles={{
                                                    root: {
                                                        border: 0,
                                                        textAlign: "left",
                                                        padding: theme.spacing.s1,
                                                        height: "auto",
                                                        borderRadius: 0,
                                                        borderTopWidth: 1,
                                                        borderTopStyle: "solid",
                                                        borderTopColor: theme.palette.neutralLighter,
                                                        width: "100%",
                                                    },
                                                    rootFocused: { backgroundColor: theme.palette.themeLighterAlt },
                                                    flexContainer: {
                                                        display: "block",
                                                    },
                                                }}
                                                title={getStringRepresentation(x!)}
                                                onClick={() => {
                                                    const key = getKeyObject(x! as any, idPropertiesArray);

                                                    setSelectedKeys(
                                                        props.multiSelect === true
                                                            ? (prevSelectedKeys) => {
                                                                  if (!prevSelectedKeys.includes(key)) {
                                                                      return [...prevSelectedKeys, key];
                                                                  } else {
                                                                      return prevSelectedKeys;
                                                                  }
                                                              }
                                                            : [key],
                                                    );
                                                    setOptions((prevOptions) => {
                                                        return props.multiSelect ? [...prevOptions, x!] : [x!];
                                                    });
                                                    setSearchValue("");
                                                    (
                                                        (props.componentRef as React.RefObject<IDropdown>) ??
                                                        dropdownRef
                                                    ).current?.dismissMenu();

                                                    props.onChange &&
                                                        props.onChange([
                                                            {
                                                                key: key,
                                                                isSelected: true,
                                                            },
                                                        ]);
                                                }}
                                            >
                                                <Text
                                                    styles={{
                                                        root: {
                                                            display: "block",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis",
                                                        },
                                                    }}
                                                >
                                                    {getStringRepresentation(x!)}
                                                </Text>
                                            </DefaultButton>
                                        );
                                    }}
                                />
                            )}

                            {!props.disableAdvancedSearch && (
                                <DefaultButton
                                    styles={{
                                        root: {
                                            border: 0,
                                            textAlign: "left",
                                            padding: theme.spacing.s1,
                                            height: "auto",
                                            borderRadius: 0,
                                            borderTopWidth: 1,
                                            borderTopStyle: "solid",
                                            borderTopColor: theme.palette.neutralLighter,
                                        },
                                        rootFocused: { backgroundColor: theme.palette.themeLighterAlt },
                                        flexContainer: {
                                            justifyContent: "auto",
                                        },
                                        icon: {
                                            color: theme.palette.themePrimary,
                                        },
                                    }}
                                    title={localization.DataTableDropdown.AdvancedSearch}
                                    iconProps={{ iconName: "Search" }}
                                    onClick={() => {
                                        setIsAdvancedSearchOpen(true);
                                    }}
                                >
                                    <Text
                                        styles={{
                                            root: {
                                                marginLeft: theme.spacing.s2,
                                                display: "block",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                            },
                                        }}
                                    >
                                        {localization.DataTableDropdown.AdvancedSearch}
                                    </Text>
                                </DefaultButton>
                            )}

                            {(props.additionalActions ?? []).map((x) => {
                                return (
                                    <DefaultButton
                                        key={x.key}
                                        styles={{
                                            root: {
                                                border: 0,
                                                textAlign: "left",
                                                padding: theme.spacing.s1,
                                                height: "auto",
                                                borderRadius: 0,
                                                borderTopWidth: 1,
                                                borderTopStyle: "solid",
                                                borderTopColor: theme.palette.neutralLighter,
                                            },
                                            rootFocused: { backgroundColor: theme.palette.themeLighterAlt },
                                            flexContainer: {
                                                justifyContent: "auto",
                                            },
                                            icon: {
                                                color: theme.palette.themePrimary,
                                            },
                                        }}
                                        title={x.text}
                                        iconProps={x.icon ? { iconName: x.icon } : undefined}
                                        onClick={() => {
                                            x.onClick(searchValue);
                                        }}
                                    >
                                        <Text
                                            styles={{
                                                root: {
                                                    marginLeft: theme.spacing.s2,
                                                    display: "block",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                },
                                            }}
                                        >
                                            {x.text}
                                        </Text>
                                    </DefaultButton>
                                );
                            })}
                        </Stack>
                    );
                }}
            />

            {props.renderDataTablePanelSelect ? (
                props.renderDataTablePanelSelect({
                    ...(props as IDataTablePanelSelectProps),
                    styles: props.panelStyles,
                    isOpen: isAdvancedSearchOpen,
                    selectionMode: props.multiSelect ? SelectionMode.multiple : SelectionMode.single,
                    onChange: (e) => {
                        e.stopPropagation();
                    },
                    onDismiss: async () => {
                        setIsAdvancedSearchOpen(false);

                        await timeout(200);
                        ((props.componentRef as React.RefObject<IDropdown>) ?? dropdownRef).current?.focus(true);
                    },
                    headerText: props.headerText ?? props.label,
                    onItemsSelected: async (keys) => {
                        if (props.onChange) {
                            const prevKeys = selectedKeys.map((x) => {
                                return getKeyString(x, idPropertiesArray);
                            });
                            const newKeys = keys.map((x) => {
                                return getKeyString(x, idPropertiesArray);
                            });

                            const selectionChangeItems: ISelectionChangeItem[] = [
                                ...selectedKeys
                                    .filter((x) => {
                                        return !newKeys.includes(getKeyString(x, idPropertiesArray));
                                    })
                                    .map((x) => {
                                        return {
                                            key: x,
                                            isSelected: false,
                                        };
                                    }),
                                ...keys
                                    .filter((x) => {
                                        return !prevKeys.includes(getKeyString(x, idPropertiesArray));
                                    })
                                    .map((x) => {
                                        return {
                                            key: x,
                                            isSelected: true,
                                        };
                                    }),
                            ];

                            props.onChange(selectionChangeItems);
                        }

                        setSelectedKeys(keys);
                        setOptions((prevOptions) => {
                            const newKeys = keys.map((x) => {
                                return getKeyString(x, idPropertiesArray);
                            });
                            return prevOptions.filter((x) => {
                                return newKeys.includes(getKeyString(x as any, idPropertiesArray));
                            });
                        });
                        setIsAdvancedSearchOpen(false);
                        setSearchValue("");

                        await timeout(200);
                        ((props.componentRef as React.RefObject<IDropdown>) ?? dropdownRef).current?.focus(true);
                    },
                    defaultSelectedKey: props.multiSelect ? undefined : selectedKeys[0],
                    defaultSelectedKeys: props.multiSelect ? selectedKeys : undefined,
                    defaultSearchValue: searchValue,
                })
            ) : (
                <DataTablePanelSelect
                    {...(props as IDataTablePanelSelectProps)}
                    onChange={(e) => {
                        e.stopPropagation();
                    }}
                    styles={props.panelStyles}
                    isOpen={isAdvancedSearchOpen}
                    selectionMode={props.multiSelect ? SelectionMode.multiple : SelectionMode.single}
                    onDismiss={async () => {
                        setIsAdvancedSearchOpen(false);

                        await timeout(200);
                        ((props.componentRef as React.RefObject<IDropdown>) ?? dropdownRef).current?.focus(true);
                    }}
                    headerText={props.headerText ?? props.label}
                    onItemsSelected={async (keys) => {
                        if (props.onChange) {
                            const prevKeys = selectedKeys.map((x) => {
                                return getKeyString(x, idPropertiesArray);
                            });
                            const newKeys = keys.map((x) => {
                                return getKeyString(x, idPropertiesArray);
                            });

                            const selectionChangeItems: ISelectionChangeItem[] = [
                                ...selectedKeys
                                    .filter((x) => {
                                        return !newKeys.includes(getKeyString(x, idPropertiesArray));
                                    })
                                    .map((x) => {
                                        return {
                                            key: x,
                                            isSelected: false,
                                        };
                                    }),
                                ...keys
                                    .filter((x) => {
                                        return !prevKeys.includes(getKeyString(x, idPropertiesArray));
                                    })
                                    .map((x) => {
                                        return {
                                            key: x,
                                            isSelected: true,
                                        };
                                    }),
                            ];

                            props.onChange(selectionChangeItems);
                        }

                        setSelectedKeys(keys);
                        setOptions((prevOptions) => {
                            const newKeys = keys.map((x) => {
                                return getKeyString(x, idPropertiesArray);
                            });
                            return prevOptions.filter((x) => {
                                return newKeys.includes(getKeyString(x as any, idPropertiesArray));
                            });
                        });
                        setIsAdvancedSearchOpen(false);
                        setSearchValue("");

                        await timeout(200);
                        ((props.componentRef as React.RefObject<IDropdown>) ?? dropdownRef).current?.focus(true);
                    }}
                    defaultSelectedKey={props.multiSelect ? undefined : selectedKeys[0]}
                    defaultSelectedKeys={props.multiSelect ? selectedKeys : undefined}
                    defaultSearchValue={searchValue}
                />
            )}
        </span>
    );
};

export default DataTableDropdown;
