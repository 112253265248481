import {
    Authorize,
    DeepPartial,
    DefaultLayout,
    IInCoreLocalization,
    ILayoutProps,
    IMenuItem,
    IPanelProps,
    MessageBar,
    MessageBarType,
    Renderer,
    Spinner,
    Stack,
    TitleErrorType,
    generateUuid,
    useOnChange,
} from "@in-core";
import { Permission, SettingValue } from "@in-core";
import { GetPermissions, GetUserPermissions } from "@in-core/api/Permissions";
import { GetSettingValues } from "@in-core/api/Settings";
import EntityDataTable, { IEntityDataTableContext } from "@in-core/components/EntityDataTable";
import useOnMount from "@in-core/hooks/useOnMount";
import Users from "@in-core/ui/Users";
import { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import LazyComponent, { ILazyComponentInfo } from "../../components/LazyComponent";
import Roles from "@in-core/ui/Roles";
import NotFound from "../NotFound";
import Settings from "@in-core/ui/Settings";
import { GetMenu, GetUserInfo, LogActivity } from "@in-core/api/Shell";
import Documents from "@in-core/ui/Documents";
import ConnectedApps from "@in-core/ui/ConnectedApps";
import { Translator, languageTag, translate } from "typed-intl";
import { DefaultInCoreLocalization } from "../Localization";
import PermissionImplicationGraph from "./PermissionImplicationGraph";
import { ActivityInfo, activityContext } from "./Activity";
import axios from "axios";
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandler from "../ErrorHandler";

declare const DecompressionStream: any;

export enum AuthorizeResult {
    Unauthorized = "Unauthorized",
    AuthorizedExplicit = "AuthorizedExplicit",
    AuthorizedImplicit = "AuthorizedImplicit",
}

const getFlatPermissions = (permissions: Permission[]): Permission[] => {
    return [
        ...permissions,
        ...permissions.flatMap((x) => {
            return x.ChildPermissions;
        }),
    ];
};

const getPermissionImplicationGraph = (allFlatPermissions: Permission[]) => {
    const graph = new PermissionImplicationGraph();

    for (const permission of allFlatPermissions) {
        for (const impliedPermission of permission.ImpliedPermissions) {
            graph.addImplication(impliedPermission, permission.CompleteId);
        }
    }

    return graph;
};

export interface IShellProps {
    onInit?: () => Promise<void>;
    onRenderLayout?: (layoutProps: ILayoutProps) => JSX.Element;
    onRenderContent?: (
        defaultRender: () => JSX.Element,
        permission?: Permission,
        lazyComponents?: ILazyComponentInfo[],
    ) => JSX.Element | null;
    onRenderLoading?: () => JSX.Element | null;
    additionalUserContent?: (form: any, entityDataTableContext: IEntityDataTableContext) => React.ReactNode;
    localization?: Record<string, DeepPartial<IInCoreLocalization>>;
    defaultCulture?: string;
    permissionsCompressed?: boolean;
}

const getTranslator = (localization?: Record<string, DeepPartial<IInCoreLocalization>>) => {
    let result = translate(DefaultInCoreLocalization);

    if (localization) {
        Object.entries(localization).forEach(([key, value]) => {
            result = result.partiallySupporting(key, value as any);
        });
    }

    return result;
};

const getCompletePermissions = async (isCompressed: boolean): Promise<Permission[]> => {
    const getPermissionsResponse = await GetPermissions.callApi({});

    if (!isCompressed) {
        return getPermissionsResponse.data as Permission[];
    }

    const decodedData = atob(getPermissionsResponse.data as string);

    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
        uint8Array[i] = decodedData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array]);
    const descompressionStream = new DecompressionStream("gzip");

    const decompressedStream = blob.stream().pipeThrough(descompressionStream);

    const readDecompressedStream = await readStream(decompressedStream);

    return JSON.parse(readDecompressedStream) as Permission[];
};

async function readStream(stream: ReadableStream) {
    const reader = stream.getReader();
    let result = "";
    while (true) {
        const { done, value } = await reader.read();
        if (done) {
            break;
        }
        result += new TextDecoder().decode(value);
    }
    return result;
}

const Shell = (props: IShellProps) => {
    const [isInitLoading, setIsInitLoading] = useState<boolean | undefined>(undefined);
    const [retrievedPermissions, setRetrievedPermissions] = useState<Permission[]>();
    const getSettingValuesApi = GetSettingValues.useApi();
    const getUserPermissionsApi = GetUserPermissions.useApi();
    const getUserInfoApi = GetUserInfo.useApi();
    const getMenuApi = GetMenu.useApi();
    const [titleText, setTitleText] = useState<string>("");
    const [titleError, setTitleError] = useState<TitleErrorType>();
    const [currentPermission, setCurrentPermission] = useState<Permission>();
    const translatorRef = useRef<Translator<IInCoreLocalization>>(getTranslator(props.localization));
    const defaultCultureRef = useRef(props.defaultCulture);
    const messages = useMemo(() => {
        const culture = getUserInfoApi.data?.Culture ?? defaultCultureRef.current;
        return culture ? translatorRef.current.messagesFor(languageTag(culture)) : translatorRef.current.messages();
    }, [getUserInfoApi.data?.Culture]);
    const allFlatPermissions = useMemo(() => {
        if (!retrievedPermissions) {
            return undefined;
        }

        return getFlatPermissions(retrievedPermissions);
    }, [retrievedPermissions]);
    const permissionImplicationGraph = useMemo(() => {
        if (!allFlatPermissions) {
            return undefined;
        }

        return getPermissionImplicationGraph(allFlatPermissions);
    }, [allFlatPermissions]);
    const [activityInfo, setActivityInfo] = useState<ActivityInfo>();

    useOnMount(async () => {
        if (props.onInit !== undefined) {
            setIsInitLoading(true);
            await props.onInit();
            setIsInitLoading(false);
        }

        setRetrievedPermissions(await getCompletePermissions(props.permissionsCompressed ?? false));
        getSettingValuesApi.call({});
        getUserPermissionsApi.call({});
        getUserInfoApi.call({});
        getMenuApi.call({});
    });

    useOnChange(() => {
        document.title = titleText ?? "";
    }, [titleText, titleError]);

    const authorize = useCallback(
        (permission: string) => {
            if (!getUserPermissionsApi.data || !retrievedPermissions || !permissionImplicationGraph) {
                return AuthorizeResult.Unauthorized;
            }

            if (getUserPermissionsApi.data.includes(permission)) {
                return AuthorizeResult.AuthorizedExplicit;
            }

            const permissionsThatImply = permissionImplicationGraph.getPermissionsThatImply(permission);

            if (
                permissionsThatImply.some((x) => {
                    return getUserPermissionsApi.data!.includes(x);
                })
            ) {
                return AuthorizeResult.AuthorizedImplicit;
            }

            return AuthorizeResult.Unauthorized;
        },
        [getUserPermissionsApi.data, retrievedPermissions, permissionImplicationGraph],
    );

    const isAuthorized = useCallback(
        (...permissions: string[]) => {
            if (permissions.length === 0) {
                return true;
            }

            return permissions.some((x) => {
                return authorize(x) !== AuthorizeResult.Unauthorized;
            });
        },
        [authorize],
    );

    const getPermissions = useCallback(() => {
        if (!retrievedPermissions) {
            return [];
        }

        return retrievedPermissions;
    }, [retrievedPermissions]);

    const getPermission = useCallback(
        (permission: string) => {
            if (!getUserPermissionsApi.data || !retrievedPermissions) {
                return undefined;
            }

            const segments = permission.split(".");

            let foundPermission = retrievedPermissions.find((x) => {
                return x.Id === segments[0];
            });
            let i = 1;
            while (foundPermission !== undefined && i < segments.length) {
                // eslint-disable-next-line no-loop-func
                foundPermission = foundPermission.ChildPermissions.find((x) => {
                    return x.Id === segments[i];
                });
                i++;
            }

            return foundPermission;
        },
        [getUserPermissionsApi.data, retrievedPermissions],
    );

    const getSettingValue = useCallback(
        (settingId: string) => {
            if (!getSettingValuesApi.data) {
                return undefined;
            }

            return getSettingValuesApi.data.find((x) => {
                return x.Id === settingId;
            });
        },
        [getSettingValuesApi.data],
    );

    const getCastedSettingValue = useCallback(
        <T extends any>(settingId: string, castFunction: (value: string) => T) => {
            const settingValue = getSettingValue(settingId);
            if (!settingValue) {
                return undefined;
            }

            const castedSettingValue: SettingValue<T> = {
                Id: settingValue.Id,
                RoleValues: [],
                Values: [],
            };

            if (settingValue.UserValue !== undefined) {
                const castedValue = castFunction(settingValue.UserValue);
                castedSettingValue.UserValue = castedValue;
                castedSettingValue.Values.push(castedValue);
            }

            settingValue.RoleValues.forEach((roleValue) => {
                const castedValue = castFunction(roleValue);
                castedSettingValue.RoleValues.push(castedValue);
                castedSettingValue.Values.push(castedValue);
            });

            if (settingValue.SystemValue !== undefined) {
                const castedValue = castFunction(settingValue.SystemValue);
                castedSettingValue.SystemValue = castedValue;
                castedSettingValue.Values.push(castedValue);
            }

            return castedSettingValue;
        },
        [getSettingValue],
    );

    const getSystemSettingValue = useCallback(
        (permissionId: string) => {
            return getSettingValue(permissionId)?.SystemValue;
        },
        [getSettingValue],
    );

    const getCastedSystemSettingValue = useCallback(
        <T extends any>(settingId: string, castFunction: (value: string) => T) => {
            const systemSettingValue = getSystemSettingValue(settingId);
            if (!systemSettingValue) {
                return undefined;
            }

            return castFunction(systemSettingValue);
        },
        [getSystemSettingValue],
    );

    const createActivity = useCallback((activityName: string, keys?: any[]) => {
        const newActivityInfo: ActivityInfo = {
            id: generateUuid(),
            name: activityName,
            keys: keys,
        };

        axios.defaults.headers.common["X-ACTIVITY-ID"] = newActivityInfo.id;
        axios.defaults.headers.common["X-ACTIVITY-NAME"] = newActivityInfo.name;

        setActivityInfo(newActivityInfo);
    }, []);

    const clearActivity = useCallback(() => {
        delete axios.defaults.headers.common["X-ACTIVITY-ID"];
        delete axios.defaults.headers.common["X-ACTIVITY-NAME"];

        setActivityInfo(undefined);
    }, []);

    const logActivity = useCallback(
        async (keys?: any[], data?: string, dontCreateNewActivity?: boolean) => {
            if (!activityInfo) {
                return;
            }

            await LogActivity.callApi({ Keys: keys ?? activityInfo.keys, Data: data });

            const newActivity: ActivityInfo = { ...activityInfo };

            clearActivity();

            if (dontCreateNewActivity !== true) {
                createActivity(newActivity.name, newActivity.keys);
            }
        },
        [activityInfo, clearActivity, createActivity],
    );

    if (
        (props.onInit !== undefined && isInitLoading !== false) ||
        !retrievedPermissions ||
        !allFlatPermissions ||
        !permissionImplicationGraph ||
        !getUserPermissionsApi.data ||
        !getUserInfoApi.data ||
        !getSettingValuesApi.data
    ) {
        return props.onRenderLoading ? (
            props.onRenderLoading()
        ) : (
            <Stack verticalAlign="center" styles={{ root: { height: "100%" } }}>
                <Spinner label={messages.Loading} />
            </Stack>
        );
    }

    const getAuthorizedComponent = (permission: string, element: React.ReactElement) => {
        const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

        return isDevelopment ? (
            element
        ) : (
            <Authorize
                permissions={permission}
                unauthorizedElement={
                    <Renderer
                        renderKey={"Unauthorized"}
                        onMounted={() => {
                            const actualPermission = retrievedPermissions!.find((x) => {
                                return x.Id === permission;
                            });

                            setTitleText(actualPermission?.Name ?? "");
                            setTitleError(TitleErrorType.Unauthorized);
                        }}
                    >
                        <MessageBar messageBarType={MessageBarType.error}>
                            {messages.UnauthorizedPartOfSystem}
                        </MessageBar>
                    </Renderer>
                }
            >
                {element}
            </Authorize>
        );
    };

    const getPermissionLazyComponents = (permission: Permission) => {
        return [
            {
                path:
                    permission.Metadata.UiPath ??
                    (permission.Path.length > 0 ? `${permission.Path.join("/")}/${permission.Id}` : permission.Id),
                parameters: permission.Metadata.UiParameters,
            },
        ];
    };

    const getPermissionComponent = (permission: Permission) => {
        return (
            <LazyComponent
                lazyComponents={getPermissionLazyComponents(permission)}
                fallback={
                    permission.Metadata.SpecialType === "EntityDataBrowser" ? (
                        <EntityDataTable entity={permission.Id} routable />
                    ) : undefined
                }
                onLoaded={(isFound) => {
                    if (isFound) {
                        return;
                    }

                    setTitleError(TitleErrorType.NotFound);
                }}
            />
        );
    };

    const mapMenuToMenuItem = (menu: GetMenu.MenuItem): IMenuItem => {
        return {
            text: menu.Text,
            permissionId: menu.PermissionId,
            submenus: menu.Submenus?.map(mapMenuToMenuItem),
        };
    };

    const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
    const permissions = isDevelopment
        ? retrievedPermissions
        : retrievedPermissions.filter((x) => {
              return getUserPermissionsApi.data!.includes(x.Id);
          });

    return (
        <inCoreContext.Provider
            value={{
                authorize: authorize,
                isAuthorized: isAuthorized,
                getPermissions: getPermissions,
                getPermission: getPermission,
                getSettingValue: getSettingValue,
                getCastedSettingValue: getCastedSettingValue,
                getSystemSettingValue: getSystemSettingValue,
                getCastedSystemSettingValue: getCastedSystemSettingValue,
                onRenderContent: props.onRenderContent,
                userInfo: getUserInfoApi.data!,
                localization: messages,
            }}
        >
            <activityContext.Provider
                value={{
                    activity: activityInfo,
                    createActivity: createActivity,
                    clearActivity: clearActivity,
                    logActivity: logActivity,
                }}
            >
                <Routes>
                    <Route
                        path="*"
                        element={
                            props.onRenderLayout ? (
                                props.onRenderLayout({
                                    titleProps: { text: titleText, error: titleError },
                                    routableItems: permissions
                                        .filter((x) => {
                                            return (
                                                x.Metadata.Ui === true || x.Metadata.SpecialType === "EntityDataBrowser"
                                            );
                                        })
                                        .map((x) => {
                                            return {
                                                id: x.Id,
                                                name: x.Name,
                                                icon: x.Icon ?? undefined,
                                                url: x.Metadata.Url ?? x.Id,
                                            };
                                        }),
                                    menuItems: getMenuApi.data?.map(mapMenuToMenuItem),
                                    userInfo: getUserInfoApi.data!,
                                })
                            ) : (
                                <DefaultLayout
                                    titleProps={{ text: titleText, error: titleError }}
                                    routableItems={permissions
                                        .filter((x) => {
                                            return (
                                                x.Metadata.Ui === true || x.Metadata.SpecialType === "EntityDataBrowser"
                                            );
                                        })
                                        .map((x) => {
                                            return {
                                                id: x.Id,
                                                name: x.Name,
                                                icon: x.Icon ?? undefined,
                                                url: x.Metadata.Url ?? x.Id,
                                            };
                                        })}
                                    menuItems={getMenuApi.data?.map(mapMenuToMenuItem)}
                                    userInfo={getUserInfoApi.data!}
                                />
                            )
                        }
                    >
                        <Route
                            index
                            element={
                                <Renderer
                                    renderKey={"Home"}
                                    onMounted={() => {
                                        setTitleText(messages.HomeTitle);
                                        setTitleError(undefined);
                                        setCurrentPermission(undefined);
                                    }}
                                >
                                    <ErrorBoundary
                                        FallbackComponent={ErrorHandler}
                                        resetKeys={[currentPermission?.CompleteId]}
                                    >
                                        {props.onRenderContent ? (
                                            props.onRenderContent(() => {
                                                return <LazyComponent lazyComponents={[{ path: "Home" }]} />;
                                            })
                                        ) : (
                                            <LazyComponent lazyComponents={[{ path: "Home" }]} />
                                        )}
                                    </ErrorBoundary>
                                </Renderer>
                            }
                        />

                        <Route
                            path="Documents/*"
                            element={
                                <Renderer
                                    renderKey={"Documents"}
                                    onMounted={() => {
                                        setTitleText(messages.DocumentsTitle);
                                        setTitleError(undefined);
                                        setCurrentPermission(undefined);
                                    }}
                                >
                                    <ErrorBoundary
                                        FallbackComponent={ErrorHandler}
                                        resetKeys={[currentPermission?.CompleteId]}
                                    >
                                        {props.onRenderContent ? (
                                            props.onRenderContent(() => {
                                                return <Documents />;
                                            })
                                        ) : (
                                            <Documents />
                                        )}
                                    </ErrorBoundary>
                                </Renderer>
                            }
                        />

                        <Route
                            path="Users/*"
                            element={getAuthorizedComponent(
                                "Users",
                                <Renderer
                                    renderKey={"Users"}
                                    onMounted={() => {
                                        setTitleText(messages.UsersTitle);
                                        setTitleError(undefined);
                                        setCurrentPermission(
                                            retrievedPermissions!.find((x) => {
                                                return x.Id === "Users";
                                            }),
                                        );
                                    }}
                                >
                                    <ErrorBoundary
                                        FallbackComponent={ErrorHandler}
                                        resetKeys={[currentPermission?.CompleteId]}
                                    >
                                        {props.onRenderContent ? (
                                            props.onRenderContent(
                                                () => {
                                                    return (
                                                        <Users additionalUserContent={props.additionalUserContent} />
                                                    );
                                                },
                                                retrievedPermissions!.find((x) => {
                                                    return x.Id === "Users";
                                                }),
                                            )
                                        ) : (
                                            <Users additionalUserContent={props.additionalUserContent} />
                                        )}
                                    </ErrorBoundary>
                                </Renderer>,
                            )}
                        />

                        <Route
                            path="Roles/*"
                            element={getAuthorizedComponent(
                                "Roles",
                                <Renderer
                                    renderKey={"Roles"}
                                    onMounted={() => {
                                        setTitleText(messages.RolesTitle);
                                        setTitleError(undefined);
                                        setCurrentPermission(
                                            retrievedPermissions!.find((x) => {
                                                return x.Id === "Roles";
                                            }),
                                        );
                                    }}
                                >
                                    <ErrorBoundary
                                        FallbackComponent={ErrorHandler}
                                        resetKeys={[currentPermission?.CompleteId]}
                                    >
                                        {props.onRenderContent ? (
                                            props.onRenderContent(
                                                () => {
                                                    return <Roles />;
                                                },
                                                retrievedPermissions!.find((x) => {
                                                    return x.Id === "Roles";
                                                }),
                                            )
                                        ) : (
                                            <Roles />
                                        )}
                                    </ErrorBoundary>
                                </Renderer>,
                            )}
                        />

                        <Route
                            path="Settings/*"
                            element={getAuthorizedComponent(
                                "Settings",
                                <Renderer
                                    renderKey={"Settings"}
                                    onMounted={() => {
                                        setTitleText(messages.SettingsTitle);
                                        setTitleError(undefined);
                                        setCurrentPermission(
                                            retrievedPermissions!.find((x) => {
                                                return x.Id === "Settings";
                                            }),
                                        );
                                    }}
                                >
                                    <ErrorBoundary
                                        FallbackComponent={ErrorHandler}
                                        resetKeys={[currentPermission?.CompleteId]}
                                    >
                                        {props.onRenderContent ? (
                                            props.onRenderContent(
                                                () => {
                                                    return <Settings />;
                                                },
                                                retrievedPermissions!.find((x) => {
                                                    return x.Id === "Settings";
                                                }),
                                            )
                                        ) : (
                                            <Settings />
                                        )}
                                    </ErrorBoundary>
                                </Renderer>,
                            )}
                        />

                        <Route
                            path="ConnectedApps/*"
                            element={getAuthorizedComponent(
                                "ConnectedApps",
                                <Renderer
                                    renderKey={"ConnectedApps"}
                                    onMounted={() => {
                                        setTitleText(messages.ConnectedAppsTitle);
                                        setTitleError(undefined);
                                        setCurrentPermission(
                                            retrievedPermissions!.find((x) => {
                                                return x.Id === "ConnectedApps";
                                            }),
                                        );
                                    }}
                                >
                                    <ErrorBoundary
                                        FallbackComponent={ErrorHandler}
                                        resetKeys={[currentPermission?.CompleteId]}
                                    >
                                        {props.onRenderContent ? (
                                            props.onRenderContent(
                                                () => {
                                                    return <ConnectedApps />;
                                                },
                                                retrievedPermissions!.find((x) => {
                                                    return x.Id === "ConnectedApps";
                                                }),
                                            )
                                        ) : (
                                            <ConnectedApps />
                                        )}
                                    </ErrorBoundary>
                                </Renderer>,
                            )}
                        />

                        {retrievedPermissions
                            .filter((x) => {
                                return (
                                    x.Id !== "Users" &&
                                    x.Id !== "Roles" &&
                                    x.Id !== "Settings" &&
                                    x.Id !== "ConnectedApps" &&
                                    (x.Metadata.Ui === true || x.Metadata.SpecialType === "EntityDataBrowser")
                                );
                            })
                            .map((x) => {
                                return (
                                    <Route
                                        key={x.Id}
                                        path={`${x.Metadata.Url ?? x.Id}/*`}
                                        element={getAuthorizedComponent(
                                            x.Id,
                                            <Renderer
                                                renderKey={x.Id}
                                                onMounted={() => {
                                                    setTitleText(x.Name);
                                                    setTitleError(undefined);
                                                    setCurrentPermission(x);

                                                    if (x.Metadata.SpecialType !== "EntityDataBrowser") {
                                                        createActivity(x.CompleteId, undefined);
                                                    }
                                                }}
                                                onUnmounted={() => {
                                                    if (x.Metadata.SpecialType !== "EntityDataBrowser") {
                                                        clearActivity();
                                                    }
                                                }}
                                            >
                                                <ErrorBoundary
                                                    FallbackComponent={ErrorHandler}
                                                    resetKeys={[currentPermission?.CompleteId]}
                                                >
                                                    {props.onRenderContent
                                                        ? props.onRenderContent(
                                                              () => {
                                                                  return getPermissionComponent(x);
                                                              },
                                                              x,
                                                              currentPermission
                                                                  ? getPermissionLazyComponents(currentPermission)
                                                                  : undefined,
                                                          )
                                                        : getPermissionComponent(x)}
                                                </ErrorBoundary>
                                            </Renderer>,
                                        )}
                                    />
                                );
                            })}

                        <Route
                            path="*"
                            element={
                                <Renderer
                                    renderKey={"NotFound"}
                                    onMounted={() => {
                                        setTitleText("");
                                        setTitleError(TitleErrorType.NotFound);
                                        setCurrentPermission(undefined);
                                    }}
                                >
                                    <NotFound />
                                </Renderer>
                            }
                        />
                    </Route>
                </Routes>
            </activityContext.Provider>
        </inCoreContext.Provider>
    );
};

export default Shell;

export interface InCoreContext {
    authorize: (permission: string) => AuthorizeResult;
    isAuthorized: (...permissions: string[]) => boolean;
    getPermissions: () => Permission[];
    getPermission: (permission: string) => Permission | undefined;
    getSettingValue: (settingId: string) => SettingValue | undefined;
    getCastedSettingValue: <T>(settingId: string, castFunction: (value: string) => T) => SettingValue<T> | undefined;
    getSystemSettingValue: (settingId: string) => string | undefined;
    getCastedSystemSettingValue: <T>(settingId: string, castFunction: (value: string) => T) => T | undefined;
    onRenderContent:
        | ((
              defaultRender: () => JSX.Element,
              permission?: Permission,
              lazyComponents?: ILazyComponentInfo[],
          ) => JSX.Element | null)
        | undefined;
    userInfo: GetUserInfo.UserInfo;
    localization: IInCoreLocalization;
}

const inCoreContext = createContext<InCoreContext | undefined>(undefined);

export const useInCoreContext = () => {
    return useContext(inCoreContext) as InCoreContext;
};
