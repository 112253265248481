import { Checkbox as FluentCheckbox, ICheckboxProps as IFluentCheckboxProps } from "@fluentui/react";

export interface ICheckboxProps extends IFluentCheckboxProps {
    isInline?: boolean;
}

const Checkbox = (props: ICheckboxProps) => {
    return (
        <FluentCheckbox
            {...props}
            styles={
                props.isInline
                    ? props.styles
                    : {
                          ...props.styles,
                          label: { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end" },
                          text: {
                              fontSize: 14,
                              fontWeight: 600,
                              color: "rgb(50, 49, 48)",
                              boxSizing: "border-box",
                              boxShadow: "none",
                              margin: 0,
                              display: "block",
                              padding: "5px 0px",
                              overflowWrap: "break-word",
                          },
                      }
            }
        />
    );
};

export default Checkbox;
