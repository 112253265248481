import { Icon, MessageBar, MessageBarType, Spinner, Stack, Text, useInCoreLocalization } from "@in-core";
import React, { useState } from "react";
import useOnChange from "@in-core/hooks/useOnChange";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";

export const SupportedTypes = ["txt", "png", "jpeg", "jpg", "gif", "bmp", "pdf"];

export interface IFilePreviewerProps {
    fileName: string;
    onLoad: () => Promise<string | null>;
    renderUnsupported?: React.ReactNode;
}

const FilePreviewer = (props: IFilePreviewerProps) => {
    const [content, setContent] = useState<string | null>();
    const localization = useInCoreLocalization();

    useOnChange(async () => {
        setContent(undefined);
        setContent(await props.onLoad());
    }, [props.fileName]);

    if (content === undefined) {
        return <Spinner />;
    }

    if (content === null) {
        return (
            <MessageBar messageBarType={MessageBarType.severeWarning}>Nije moguće pronaći traženu datoteku.</MessageBar>
        );
    }

    const fileNameSplit = props.fileName.split(".");
    const fileType = fileNameSplit.length < 2 ? "" : fileNameSplit.reverse()[0].toLowerCase();

    if (fileType === "txt") {
        return (
            <iframe
                title="content"
                src={`data:text/plain;base64,${content}`}
                style={{
                    border: "none",
                    height: "100%",
                    width: "100%",
                }}
            />
        );
    }

    if (fileType === "pdf") {
        return (
            <iframe
                title="content"
                src={`data:application/pdf;base64,${content}`}
                style={{ border: "none", height: "100%", width: "100%" }}
            />
        );
    }

    if (fileType === "png" || fileType === "jpg" || fileType === "jpeg" || fileType === "gif" || fileType === "bmp") {
        return (
            <img
                alt="content"
                src={`data:image/${fileType};base64,${content}`}
                style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
            />
        );
    }

    if (props.renderUnsupported !== undefined) {
        return <>{props.renderUnsupported}</>;
    }

    return (
        <Stack horizontalAlign="center" verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <Icon {...getFileTypeIconProps({ extension: fileType, size: 32 })} />

            <Text>{localization.Documents.PreviewNotSupported}</Text>
        </Stack>
    );
};

export default FilePreviewer;
