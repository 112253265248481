import { IPanelProps, IRenderFunction, Permission } from "@in-core";

export interface IChildTitleProps {
    panelProps?: IPanelProps;
    defaultRender?: IRenderFunction<IPanelProps>;
    permission?: Permission;
}

const ChildTitle = (props: IChildTitleProps) => {
    return <div style={{ whiteSpace: "nowrap" }}>{props.defaultRender!(props.panelProps)}</div>;
};

export default ChildTitle;
