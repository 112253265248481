import { callApi as callGenericApi, useApi as useGenericApi, ValidationResult } from "@in-core";

export namespace GetApis {
    export const URL = "CoreAPI/ConnectedApps/GetApis";

    export interface Request {}

    export interface ApiInfo {
        Id: string;
        Name: string;
        Description?: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, ApiInfo[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, ApiInfo[]>(URL);
    };
}

export namespace Create {
    export const URL = "CoreAPI/ConnectedApps/Create";

    export interface Request extends Data {}

    export const callApi = (request: Request) => {
        return callGenericApi<Request, Data>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, Data>(URL);
    };
}

export namespace Read {
    export const URL = "CoreAPI/ConnectedApps/Read";

    export interface Request extends Key {}

    export const callApi = (request: Request) => {
        return callGenericApi<Request, Data>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, Data>(URL);
    };
}

export namespace Update {
    export const URL = "CoreAPI/ConnectedApps/Update";

    export interface Request extends Data {}

    export const callApi = (request: Request) => {
        return callGenericApi<Request, Data>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, Data>(URL);
    };
}

export namespace Validate {
    export const URL = "CoreAPI/ConnectedApps/Validate";

    export interface Request extends Data {}

    export const callApi = (request: Request) => {
        return callGenericApi<Request, ValidationResult>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, ValidationResult>(URL);
    };
}

export namespace GetOpenAPI {
    export const URL = "CoreAPI/ConnectedApps/GetOpenAPI";

    export interface Request {
        AppId: string;
    }

    export interface OpenApiInfo {
        Name: string;
        Json: string;
        Yaml: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, OpenApiInfo>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, OpenApiInfo>(URL);
    };
}

export interface Key {
    Id?: string;
}

export interface Data extends Key {
    Name: string;
    IsBlocked: boolean;
    Apis: string[];
}

export namespace GetKeys {
    export const URL = "CoreAPI/ConnectedApps/GetKeys";

    export interface Request {
        AppId: string;
    }

    export interface KeyInfo {
        Id: string;
        Description: string;
        ValidFrom: Date;
        ValidUntil: Date;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, KeyInfo[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, KeyInfo[]>(URL);
    };
}

export namespace GenerateSecret {
    export const URL = "CoreAPI/ConnectedApps/GenerateSecret";

    export interface Request {
        AppId: string;
        Description: string;
        ValidFrom: Date;
        ValidUntil: Date;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, string>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, string>(URL);
    };
}

export namespace ValidateGenerateSecret {
    export const URL = "CoreAPI/ConnectedApps/ValidateGenerateSecret";

    export interface Request {
        AppId: string;
        Description: string;
        ValidFrom: Date;
        ValidUntil: Date;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, ValidationResult>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, ValidationResult>(URL);
    };
}

export namespace DeleteKey {
    export const URL = "CoreAPI/ConnectedApps/DeleteKey";

    export interface Request {
        Id: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, void>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, void>(URL);
    };
}
