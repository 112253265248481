import { Checkbox, useOnChange } from "@in-core";
import { FilterValueOperator } from "@in-core/api";
import { useState } from "react";
import { IFilterProps } from "..";

const BooleanFilter = (props: IFilterProps) => {
    const [value, setValue] = useState<boolean | undefined>(props.filter?.Value?.RightOperand?.Value);

    useOnChange(() => {
        setValue(props.filter?.Value?.RightOperand?.Value);
    }, [props.filter]);

    return (
        <Checkbox
            indeterminate={value === undefined}
            checked={value === true}
            onChange={() => {
                const newValue = value === undefined ? true : value === true ? false : undefined;

                setValue(newValue);

                props.onFilterChanged(
                    newValue === undefined
                        ? undefined
                        : {
                              IsNegated: false,
                              Value: {
                                  LeftOperand: {
                                      Id: props.id,
                                  },
                                  Operator: FilterValueOperator.Equal,
                                  RightOperand: {
                                      Value: newValue,
                                  },
                              },
                          },
                );
            }}
            styles={{
                root: {
                    height: 32,
                },
            }}
        />
    );
};

export default BooleanFilter;
