import { useLocation } from "react-router-dom";
import useOnChange from "@in-core/hooks/useOnChange";

export interface ILocationChangeListenerProps {}

const LocationChangeListener = (props: ILocationChangeListenerProps) => {
    const { pathname } = useLocation();

    return null;
};

export default LocationChangeListener;
