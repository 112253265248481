import { Navigate } from "react-router-dom";
import { usePermissionService } from "@in-core";

export interface IAuthorizeProps {
    permissions: string | string[];
    shouldRedirectToLogin?: boolean;
    unauthorizedElement?: JSX.Element;
    children: JSX.Element | null;
}

const Authorize = (props: IAuthorizeProps) => {
    const { isAuthorized } = usePermissionService();

    const isAnyAuthorized = Array.isArray(props.permissions)
        ? props.permissions.some((x) => {
              return isAuthorized(x);
          })
        : isAuthorized(props.permissions);

    if (isAnyAuthorized) {
        return props.children;
    }

    return props.shouldRedirectToLogin ? (
        <Navigate to={`/Login?returnUrl=${encodeURIComponent(window.location.href)}`} />
    ) : (
        props.unauthorizedElement ?? null
    );
};

export default Authorize;
