import {
    FormCheckbox,
    FormGroup,
    FormTextField,
    IEntityDataTableContext,
    ScrollablePane,
    Separator,
    TextField,
    useEntityDataTableContext,
    UseForm,
    useInCoreLocalization,
} from "@in-core";

export interface IBasicUserDataProps {
    form: UseForm;
    additionalUserContent?: (form: any, entityDataTableContext: IEntityDataTableContext) => React.ReactNode;
}

const BasicUserData = (props: IBasicUserDataProps) => {
    const localization = useInCoreLocalization();
    const form = props.form as any;
    const entityDataTableContext = useEntityDataTableContext()!;

    const isRead = entityDataTableContext.innerPermission?.Id === "Read";

    return (
        <ScrollablePane styles={{ root: { position: "relative", height: "100%" } }}>
            <FormGroup>
                <TextField
                    value={form.Id.$value?.toString() ?? ""}
                    disabled
                    label={localization.UserManagement.UserId}
                />

                <FormTextField
                    value={form.Name}
                    label={localization.UserManagement.UserName}
                    required
                    disabled={isRead}
                />

                <FormTextField
                    value={form.UserName}
                    label={localization.UserManagement.UserUserName}
                    required
                    disabled={isRead}
                />

                <FormTextField value={form.Email} label={localization.UserManagement.UserEmail} disabled={isRead} />

                <FormTextField value={form.Culture} label={localization.UserManagement.UserCulture} disabled={isRead} />
            </FormGroup>

            <Separator />

            <FormGroup>
                <FormTextField
                    value={form.Password}
                    label={localization.UserManagement.UserPassword}
                    type="password"
                    placeholder={entityDataTableContext.innerPermission?.Id !== "Create" ? "●●●●●●●●" : undefined}
                    required={entityDataTableContext.innerPermission?.Id === "Create"}
                    canRevealPassword={entityDataTableContext.innerPermission?.Id !== "Read"}
                    disabled={isRead}
                />

                <FormCheckbox
                    value={form.EnableLockout}
                    label={localization.UserManagement.UserLockout}
                    disabled={isRead}
                />
            </FormGroup>

            {props.additionalUserContent && (
                <>
                    <Separator />

                    {props.additionalUserContent(props.form, entityDataTableContext)}
                </>
            )}
        </ScrollablePane>
    );
};

export default BasicUserData;
