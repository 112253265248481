import { InCoreContext, useInCoreContext } from "@in-core";

export interface IPermissionService
    extends Pick<InCoreContext, "authorize" | "isAuthorized" | "getPermissions" | "getPermission"> {}

export const usePermissionService = () => {
    const { authorize, isAuthorized, getPermissions, getPermission } = useInCoreContext();

    return {
        authorize,
        isAuthorized,
        getPermissions,
        getPermission,
    };
};
