import React, { useState } from "react";
import { DefaultButton, useTheme } from "@in-core";

export interface IMultiStepTabProps {
    disabled?: boolean;
    index: number;
    selectedIndex: number;
    isMobile: boolean;
    children?: React.ReactNode;
    onClick: () => void;
}

const MultiStepTab = (props: IMultiStepTabProps) => {
    const [isHover, setIsHover] = useState(false);
    const theme = useTheme();

    return (
        <DefaultButton
            disabled={props.disabled}
            onMouseEnter={() => {
                return setIsHover(true);
            }}
            onMouseLeave={() => {
                return setIsHover(false);
            }}
            onClick={props.onClick}
            styles={{
                root: {
                    border: 0,
                    padding: 0,
                    flex: !props.isMobile || props.index === props.selectedIndex ? 1 : undefined,
                    height: "auto",
                    borderRadius: 0,
                    minWidth: 0,
                },
                flexContainer: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    textAlign: "left",
                },
                rootHovered: {
                    backgroundColor: theme.palette.neutralLighterAlt,
                },
                rootDisabled: {
                    backgroundColor: theme.palette.neutralLighter,
                    cursor: "not-allowed",
                },
            }}
        >
            {typeof props.children === "function" ? (props.children as Function)(isHover) : props.children}

            <div
                style={{
                    height: 4,
                    backgroundColor:
                        props.index <= props.selectedIndex
                            ? theme.palette.themePrimary
                            : props.disabled
                            ? theme.palette.neutralLight
                            : theme.palette.neutralLighter,
                    position: "relative",
                }}
            />
        </DefaultButton>
    );
};

export default MultiStepTab;
