import {
    DataEntryForm,
    DataEntryType,
    Permission,
    Pivot,
    PivotItem,
    useForm,
    useInCoreLocalization,
    usePermissionService,
} from "@in-core";
import { Create, Read, Update, Validate } from "@in-core/api/Roles";
import BasicRoleData from "./BasicRoleData";
import RolePermissions from "./RolePermissions";
import RoleSettings from "./RoleSettings";
import RoleUsers from "./RoleUsers";
import RoleMenu from "./RoleMenu";
import { useRef } from "react";
import PermissionImplicationGraph from "./PermissionImplicationGraph";

const getFlatPermissions = (permissions: Permission[]): Permission[] => {
    return [
        ...permissions,
        ...permissions.flatMap((x) => {
            return x.ChildPermissions;
        }),
    ];
};

const getPermissionImplicationGraph = (allFlatPermissions: Permission[]) => {
    const graph = new PermissionImplicationGraph();

    for (const permission of allFlatPermissions) {
        for (const impliedPermission of permission.ImpliedPermissions) {
            graph.addImplication(impliedPermission, permission.CompleteId);
        }
    }

    return graph;
};

const DataEntry = () => {
    const localization = useInCoreLocalization();
    const form = useForm({
        validate: async (values) => {
            var response = await Validate.callApi(values);
            return response.data!;
        },
        shouldClearEmptyArrays: false,
    }) as any;
    const permissionService = usePermissionService();
    const allFlatPermissionsRef = useRef(getFlatPermissions(permissionService.getPermissions()));
    const permissionImplicationGraphRef = useRef(getPermissionImplicationGraph(allFlatPermissionsRef.current));

    const permissions = permissionService.getPermissions();

    return (
        <DataEntryForm
            type={DataEntryType.AutoKey}
            form={form as any}
            keyValues={{ Id: (form as any).Id }}
            createApi={Create.callApi}
            readApi={Read.callApi}
            updateApi={Update.callApi}
            removePaddingBottom
            style={{ height: "100%" }}
        >
            <Pivot
                style={{ height: "100%", display: "flex", flexDirection: "column" }}
                styles={{ itemContainer: { flex: 1 } }}
            >
                <PivotItem
                    headerText={localization.UserManagement.Basic}
                    itemIcon="Info"
                    alwaysRender
                    style={{ height: "100%" }}
                >
                    <BasicRoleData form={form} />
                </PivotItem>

                <PivotItem
                    headerText={localization.UserManagement.Permissions}
                    itemIcon="Permissions"
                    alwaysRender
                    style={{ height: "100%" }}
                >
                    <RolePermissions
                        form={form}
                        permissions={permissions}
                        permissionImplicationGraph={permissionImplicationGraphRef.current}
                        allFlatPermissions={allFlatPermissionsRef.current}
                    />
                </PivotItem>

                <PivotItem
                    headerText={localization.UserManagement.Users}
                    itemIcon="People"
                    alwaysRender
                    style={{ height: "100%" }}
                >
                    <RoleUsers form={form} />
                </PivotItem>

                <PivotItem
                    headerText={localization.UserManagement.Menu}
                    itemIcon="SecondaryNav"
                    alwaysRender
                    style={{ height: "100%" }}
                >
                    <RoleMenu form={form} permissions={permissions} />
                </PivotItem>

                <PivotItem
                    headerText={localization.UserManagement.Settings}
                    itemIcon="Settings"
                    alwaysRender
                    style={{ height: "100%" }}
                >
                    <RoleSettings form={form} />
                </PivotItem>
            </Pivot>
        </DataEntryForm>
    );
};

export default DataEntry;
