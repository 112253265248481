import Content from "../Content";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { Icon, IconButton, Stack, TextField, useInCoreLocalization, useTheme, useToggle } from "@in-core";
import { fileNameRegex } from "..";
import { NewDirectoryInfo, NewFileInfo } from "@in-core/api/Documents";

export interface IDirectoryProps {
    directory: NewDirectoryInfo;
    onRemove: () => void;
    onRemoveDirectory: (path: string[], directory: NewDirectoryInfo) => void;
    onRemoveFile: (path: string[], file: NewFileInfo) => void;
    forceUpdate: () => void;
    path: string[];
    existingNames: string[];
    disableRename: boolean;
}

const Directory = (props: IDirectoryProps) => {
    const [isExpanded, toggleIsExpanded] = useToggle(false);
    const theme = useTheme();
    const localization = useInCoreLocalization();

    let errorMessage: string | undefined = undefined;
    if (!fileNameRegex.test(props.directory.Name)) {
        errorMessage = localization.FileUpload.IncorrectName;
    }
    if (
        props.existingNames
            .map((x) => {
                return x.toLowerCase();
            })
            .includes(props.directory.Name.toLowerCase())
    ) {
        errorMessage = localization.FileUpload.FolderAlreadyExists;
    }

    return (
        <Stack>
            <Stack
                horizontal
                verticalAlign="center"
                tokens={{
                    padding: theme.spacing.s2,
                    childrenGap: theme.spacing.s1,
                }}
                styles={{
                    root: {
                        borderBottomStyle: "solid",
                        borderBottomWidth: 1,
                        borderBottomColor: theme.palette.neutralLighter,
                    },
                }}
            >
                <IconButton
                    iconProps={{
                        iconName: "ChevronDown",
                        styles: {
                            root: {
                                fontSize: 14,
                                color: theme.palette.neutralPrimary,
                                transform: isExpanded ? "rotate(180deg)" : undefined,
                                transition: "transform 0.2s",
                            },
                        },
                    }}
                    onClick={() => {
                        return toggleIsExpanded();
                    }}
                />

                <Icon
                    {...getFileTypeIconProps({
                        type: 2,
                        size: 16,
                    })}
                />

                <TextField
                    value={props.directory.Name}
                    onChange={(_, newValue) => {
                        props.directory.Name = newValue ?? "";
                        props.forceUpdate();
                    }}
                    styles={{
                        root: { flex: 1 },
                        fieldGroup: {
                            borderColor: errorMessage ? undefined : "transparent",
                            height: 28,
                        },
                        field: { paddingLeft: theme.spacing.s2 },
                        errorMessage: { display: "none" },
                    }}
                    errorMessage={errorMessage}
                    readOnly={props.disableRename}
                />

                <IconButton
                    iconProps={{ iconName: "Cancel" }}
                    onClick={() => {
                        return props.onRemove();
                    }}
                />
            </Stack>

            {isExpanded && (
                <Stack styles={{ root: { paddingLeft: theme.spacing.l2 } }}>
                    <Content
                        directories={props.directory.Subdirectories}
                        files={props.directory.Files}
                        onRemoveDirectory={props.onRemoveDirectory}
                        onRemoveFile={props.onRemoveFile}
                        forceUpdate={props.forceUpdate}
                        path={[...props.path, props.directory.Name]}
                        disableRename={props.disableRename}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default Directory;
