import React from "react";

export interface IMultiStepContentItemProps {
    text: string;
    disabled?: boolean;
    previousDisabled?: boolean;
    nextDisabled?: boolean;
    onPreviousClicked?: () => Promise<boolean>;
    onNextClicked?: () => Promise<boolean>;
    children?: React.ReactNode;
}

const MultiStepContentItem = (props: IMultiStepContentItemProps) => {
    return <>{props.children}</>;
};

export default MultiStepContentItem;
