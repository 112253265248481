import { Spinner } from "@fluentui/react";
import useOnChange from "@in-core/hooks/useOnChange";
import NotFound from "@in-core/in-core-app/NotFound";
import React, { lazy, Suspense, useState } from "react";

export interface ILazyComponentInfo {
    path: string;
    parameters?: any;
}

export interface ILazyComponentProps {
    lazyComponents: ILazyComponentInfo[];
    fallback?: React.ReactElement;
    onLoaded?: (isFound: boolean) => void;
}

const LazyComponent = (props: ILazyComponentProps) => {
    const [DynamicComponent, setDynamicComponent] = useState<React.LazyExoticComponent<React.ComponentType<any>>>();
    const [parameters, setParameters] = useState<any>();

    useOnChange(() => {
        setDynamicComponent(
            lazy(async () => {
                const result = await getLazyComponent(props.lazyComponents, props.fallback);
                setParameters(result.parameters);
                props.onLoaded && props.onLoaded(result.isFound);
                return result;
            }),
        );
    }, [props.lazyComponents]);

    if (!DynamicComponent) {
        return null;
    }

    return (
        <Suspense fallback={<Spinner />}>
            <DynamicComponent {...parameters} />
        </Suspense>
    );
};

const getLazyComponent = async (
    lazyComponents: ILazyComponentInfo[],
    renderNotFound: React.ReactElement | undefined,
): Promise<{
    default: React.ComponentType<any>;
    parameters?: any;
    isFound: boolean;
}> => {
    if (lazyComponents.length === 0) {
        return await Promise.resolve({
            default: () => {
                return renderNotFound ?? <NotFound />;
            },
            isFound: false,
        });
    }

    try {
        return {
            ...(await import(`@ui/${lazyComponents[0].path}`)),
            parameters: lazyComponents[0].parameters,
            isFound: true,
        };
    } catch {
        return await getLazyComponent(lazyComponents.slice(1), renderNotFound);
    }
};

export default LazyComponent;
