import {
    DataEntryForm,
    DataEntryType,
    IEntityDataTableContext,
    Permission,
    Pivot,
    PivotItem,
    useEntityDataTableContext,
    useForm,
    useInCoreLocalization,
    useOnChange,
    usePermissionService,
} from "@in-core";
import { ReadSome } from "@in-core/api/Roles";
import { Create, Read, Update, Validate } from "@in-core/api/Users";
import { useRef, useState } from "react";
import BasicUserData from "./BasicUserData";
import UserPermissions from "./UserPermissions";
import UserRoles from "./UserRoles";
import UserSettings from "./UserSettings";
import UserMenu from "./UserMenu";
import PermissionImplicationGraph from "./PermissionImplicationGraph";

const getFlatPermissions = (permissions: Permission[]): Permission[] => {
    return [
        ...permissions,
        ...permissions.flatMap((x) => {
            return x.ChildPermissions;
        }),
    ];
};

const getPermissionImplicationGraph = (allFlatPermissions: Permission[]) => {
    const graph = new PermissionImplicationGraph();

    for (const permission of allFlatPermissions) {
        for (const impliedPermission of permission.ImpliedPermissions) {
            graph.addImplication(impliedPermission, permission.CompleteId);
        }
    }

    return graph;
};

export interface IDataEntryProps {
    additionalUserContent?: (form: any, entityDataTableContext: IEntityDataTableContext) => React.ReactNode;
}

const DataEntry = (props: IDataEntryProps) => {
    const localization = useInCoreLocalization();
    const entityDataTableContext = useEntityDataTableContext()!;
    const form = useForm({
        validate: async (values) => {
            var response = await Validate.callApi({
                ...values,
                IsCreate: entityDataTableContext.innerPermission?.Id === "Create",
            });
            return response.data!;
        },
        shouldClearEmptyArrays: false,
    }) as any;
    const readSomeRolesApi = ReadSome.useApi();
    const [roles, setRoles] = useState<any[]>([]);
    const permissionService = usePermissionService();
    const allFlatPermissionsRef = useRef(getFlatPermissions(permissionService.getPermissions()));
    const permissionImplicationGraphRef = useRef(getPermissionImplicationGraph(allFlatPermissionsRef.current));

    const roleIds: string[] = form.RoleIds.$value ?? [];

    useOnChange(async () => {
        const existingRoleIds = roles.map((x) => {
            return x.Id;
        });

        const newRoleIds = roleIds.filter((x) => {
            return !existingRoleIds.includes(x);
        });

        const removedRoleIds = existingRoleIds.filter((x) => {
            return !roleIds.includes(x);
        });

        if (newRoleIds.length === 0 && removedRoleIds.length === 0) {
            return;
        }

        const readSomeRolesResponse = await readSomeRolesApi.call({ Ids: newRoleIds });

        setRoles((prevRoles) => {
            return [
                ...prevRoles.filter((x) => {
                    return !removedRoleIds.includes(x.Id);
                }),
                ...readSomeRolesResponse.data!,
            ];
        });
    }, [roleIds]);

    const permissions = permissionService.getPermissions();

    return (
        <DataEntryForm
            type={DataEntryType.AutoKey}
            form={form as any}
            keyValues={{ Id: (form as any).Id }}
            createApi={Create.callApi}
            readApi={Read.callApi}
            updateApi={Update.callApi}
            removePaddingBottom
            style={{ height: "100%" }}
        >
            <Pivot
                style={{ height: "100%", display: "flex", flexDirection: "column" }}
                styles={{ itemContainer: { flex: 1 } }}
            >
                <PivotItem
                    headerText={localization.UserManagement.Basic}
                    itemIcon="Info"
                    alwaysRender
                    style={{ height: "100%" }}
                >
                    <BasicUserData form={form} additionalUserContent={props.additionalUserContent} />
                </PivotItem>

                <PivotItem
                    headerText={localization.UserManagement.Roles}
                    itemIcon="PlayerSettings"
                    alwaysRender
                    style={{ height: "100%" }}
                >
                    <UserRoles form={form} />
                </PivotItem>

                <PivotItem
                    headerText={localization.UserManagement.Permissions}
                    itemIcon="Permissions"
                    alwaysRender
                    style={{ height: "100%" }}
                >
                    <UserPermissions
                        form={form}
                        permissions={permissions}
                        roles={roles}
                        isLoadingRoles={readSomeRolesApi.isLoading}
                        permissionImplicationGraph={permissionImplicationGraphRef.current}
                        allFlatPermissions={allFlatPermissionsRef.current}
                    />
                </PivotItem>

                <PivotItem
                    headerText={localization.UserManagement.Menu}
                    itemIcon="SecondaryNav"
                    alwaysRender
                    style={{ height: "100%" }}
                >
                    <UserMenu form={form} permissions={permissions} roles={roles} />
                </PivotItem>

                <PivotItem
                    headerText={localization.UserManagement.Settings}
                    itemIcon="Settings"
                    alwaysRender
                    style={{ height: "100%" }}
                >
                    <UserSettings form={form} roles={roles} />
                </PivotItem>
            </Pivot>
        </DataEntryForm>
    );
};

export default DataEntry;
