import {
    DefaultButton,
    Dialog,
    DialogFooter,
    FormTextField,
    IconButton,
    List,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    ValidationResult,
    getKeyString,
    useEntityDataTableContext,
    useForm,
    useInCoreLocalization,
    useOnMount,
    usePermissionService,
    useTheme,
} from "@in-core";
import { AddNote, DeleteNote, GetNotes } from "@in-core/api/Entity";
import { useState } from "react";

const Notes = () => {
    const getNotesApi = GetNotes.useApi();
    const deleteNoteApi = DeleteNote.useApi();
    const [deleteNoteId, setDeleteNoteId] = useState<string>();
    const entityDataTableContext = useEntityDataTableContext()!;
    const permissionService = usePermissionService();
    const form = useForm<AddNote.Request>({
        defaultValues: {
            Entity: entityDataTableContext.permission.Metadata.EntityName,
            KeyString: getKeyString(
                entityDataTableContext.selectedKeys[0],
                entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? [],
            ),
        },
        validate: async (values) => {
            if (values.Text === undefined || values.Text.trim() === "") {
                return {
                    ErrorMessages: [localization.EntityDataTable.NotesTextRequired],
                    NestedValidations: {
                        Text: {
                            ErrorMessages: [localization.EntityDataTable.NotesTextRequired],
                            NestedValidations: {},
                        },
                    },
                };
            }

            return {
                ErrorMessages: [],
                NestedValidations: {},
            } as ValidationResult;
        },
        shouldValidateOnInit: true,
    });
    const localization = useInCoreLocalization();
    const theme = useTheme();

    const getNotes = () => {
        getNotesApi.call({
            Entity: entityDataTableContext.permission.Metadata.EntityName,
            KeyString: getKeyString(
                entityDataTableContext.selectedKeys[0],
                entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? [],
            ),
        });
    };

    const deleteNote = async () => {
        if (deleteNoteId === undefined) {
            return;
        }

        await deleteNoteApi.call({
            Entity: entityDataTableContext.permission.Metadata.EntityName,
            KeyString: getKeyString(
                entityDataTableContext.selectedKeys[0],
                entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? [],
            ),
            NoteId: deleteNoteId,
        });

        setDeleteNoteId(undefined);
        deleteNoteApi.reset();
        getNotes();
    };

    const handleSubmit = async (data: AddNote.Request) => {
        await AddNote.callApi(data);
        getNotes();
        form.$reset();
    };

    const getFullDisplayName = (displayName: string | undefined, userName: string | undefined) => {
        if (displayName === undefined && userName === undefined) {
            return undefined;
        }

        if (displayName !== undefined && userName !== undefined) {
            return `${displayName} (@${userName})`;
        }

        return displayName ?? `@${userName}`;
    };

    useOnMount(() => {
        getNotes();
    });

    return (
        <>
            <Stack tokens={{ childrenGap: theme.spacing.l2 }}>
                {getNotesApi.isIdle || getNotesApi.isLoading ? (
                    <Spinner />
                ) : (
                    <List
                        items={getNotesApi.data!}
                        onRenderCell={(item) => {
                            return (
                                <Stack
                                    styles={{
                                        root: {
                                            borderWidth: 1,
                                            borderStyle: "solid",
                                            borderColor: theme.palette.neutralLight,
                                            borderRadius: theme.effects.roundedCorner4,
                                            marginTop: theme.spacing.m,
                                            padding: theme.spacing.s1,
                                        },
                                    }}
                                    tokens={{ childrenGap: theme.spacing.s1 }}
                                >
                                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                                        <Text variant="small" styles={{ root: { fontWeight: 600 } }}>
                                            {getFullDisplayName(item?.UserDisplayName, item?.UserName)},{" "}
                                            {item?.TimeAdded.toLocaleString("hr-HR")}
                                        </Text>

                                        <Stack horizontal>
                                            {permissionService.isAuthorized(
                                                `${entityDataTableContext.permission.CompleteId}.Notes.DeleteNotes`,
                                            ) && (
                                                <IconButton
                                                    iconProps={{ iconName: "Delete" }}
                                                    onClick={() => {
                                                        setDeleteNoteId(item?.Id);
                                                    }}
                                                    title={localization.Delete}
                                                />
                                            )}
                                        </Stack>
                                    </Stack>

                                    <div style={{ height: 1, backgroundColor: theme.palette.neutralLight }} />

                                    <Text>{item?.Text}</Text>
                                </Stack>
                            );
                        }}
                    />
                )}

                <form onSubmit={form.$handleSubmit(handleSubmit)}>
                    <Stack tokens={{ childrenGap: theme.spacing.m }}>
                        <FormTextField
                            value={form.Text}
                            label={localization.Notes.NewNote}
                            multiline
                            disabled={form.$isSubmitting}
                            rows={8}
                        />

                        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
                            <PrimaryButton
                                type="submit"
                                disabled={
                                    !form.$isValid ||
                                    form.$isPendingValidation ||
                                    form.$isValidating ||
                                    form.$isSubmitting
                                }
                            >
                                {localization.Save}
                            </PrimaryButton>

                            {form.$isSubmitting && <Spinner size={SpinnerSize.small} />}
                        </Stack>
                    </Stack>
                </form>
            </Stack>

            <Dialog
                hidden={deleteNoteId === undefined}
                dialogContentProps={{
                    title: localization.Notes.DeleteNote,
                    subText: localization.Notes.DeleteNoteConfirm,
                }}
            >
                <DialogFooter>
                    {deleteNoteApi.isLoading && <Spinner size={SpinnerSize.small} />}

                    <PrimaryButton onClick={deleteNote} text={localization.Delete} disabled={deleteNoteApi.isLoading} />

                    <DefaultButton
                        onClick={() => {
                            setDeleteNoteId(undefined);
                        }}
                        text={localization.Cancel}
                        disabled={deleteNoteApi.isLoading}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default Notes;
