import {
    DatePicker as FluentDatePicker,
    IconButton,
    IDatePickerProps as IFluentDatePickerProps,
    IDatePickerStrings,
    IRawStyle,
    ITextFieldStyles,
    useTheme,
    IDatePicker,
} from "@fluentui/react";
import { DeepPartial } from "@in-core/forms";
import { useState } from "react";
import React from "react";
import useOnChange from "@in-core/hooks/useOnChange";
import { useInCoreLocalization } from "@in-core/hooks/useInCoreLocalization";

const dateRegExp = /^(?<date>[1-9]|0[1-9]|[12][0-9]|3[01])\.(?<month>[1-9]|0[1-9]|1[012])\.(?<year>\d{1,4})\.?$/;
const formatDate = (date?: Date): string => {
    if (!date) {
        return "";
    }

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;
};

const parseDateFromString = (str: string): Date | null => {
    const match = dateRegExp.exec(str);
    if (match === null) {
        return null;
    }

    return new Date(Number(match.groups!["year"]), Number(match.groups!["month"]) - 1, Number(match.groups!["date"]));
};

export interface IDatePickerProps extends Omit<IFluentDatePickerProps, "onSelectDate" | "defaultValue"> {
    defaultValue?: Date;
    onSelectDate?: (date: Date | undefined) => void;
    description?: string;
    errorMessage?: string;
    required?: boolean;
    hideClear?: boolean;
}

const DatePicker = (props: IDatePickerProps) => {
    const theme = useTheme();
    const [value, setValue] = useState<Date | undefined>(props.defaultValue);
    const localization = useInCoreLocalization();

    const datePickerRef = React.useRef<IDatePicker>(null);

    const datePickerString: IDatePickerStrings = {
        goToToday: localization.DatePicker.Today,
        months: [
            localization.DatePicker.January,
            localization.DatePicker.February,
            localization.DatePicker.March,
            localization.DatePicker.April,
            localization.DatePicker.May,
            localization.DatePicker.June,
            localization.DatePicker.July,
            localization.DatePicker.August,
            localization.DatePicker.September,
            localization.DatePicker.October,
            localization.DatePicker.November,
            localization.DatePicker.December,
        ],
        shortMonths: [
            localization.DatePicker.JanuaryShort,
            localization.DatePicker.FebruaryShort,
            localization.DatePicker.MarchShort,
            localization.DatePicker.AprilShort,
            localization.DatePicker.MayShort,
            localization.DatePicker.JuneShort,
            localization.DatePicker.JulyShort,
            localization.DatePicker.AugustShort,
            localization.DatePicker.SeptemberShort,
            localization.DatePicker.OctoberShort,
            localization.DatePicker.NovemberShort,
            localization.DatePicker.DecemberShort,
        ],
        days: [
            localization.DatePicker.Monday,
            localization.DatePicker.Tuesday,
            localization.DatePicker.Wednesday,
            localization.DatePicker.Thursday,
            localization.DatePicker.Friday,
            localization.DatePicker.Saturday,
            localization.DatePicker.Sunday,
        ],
        shortDays: [
            localization.DatePicker.MondayShort,
            localization.DatePicker.TuesdayShort,
            localization.DatePicker.WednesdayShort,
            localization.DatePicker.ThursdayShort,
            localization.DatePicker.FridayShort,
            localization.DatePicker.SaturdayShort,
            localization.DatePicker.SundayShort,
        ],
    };

    useOnChange(async () => {
        if (value !== undefined && (props.value === undefined || props.value === null)) {
            datePickerRef.current?.reset();
        }
    }, [props.value]);

    return (
        <FluentDatePicker
            componentRef={datePickerRef}
            formatDate={formatDate}
            parseDateFromString={parseDateFromString}
            strings={datePickerString}
            allowTextInput
            {...props}
            defaultValue={undefined}
            value={props.value ?? value}
            onSelectDate={(date) => {
                setValue(date ?? undefined);
                props.onSelectDate && props.onSelectDate(date ?? undefined);
            }}
            textField={
                props.textField ||
                props.description ||
                props.errorMessage ||
                props.required ||
                props.hideClear !== false
                    ? {
                          ...props.textField,
                          description: props.description ?? props.textField?.description,
                          errorMessage: props.errorMessage ?? props.textField?.errorMessage,
                          required: props.required ?? props.textField?.required,
                          onRenderInput: (renderProps, defaultRender) => {
                              return (
                                  <>
                                      {defaultRender!(renderProps)}
                                      {!props.hideClear && !props.disabled && value !== undefined && (
                                          <IconButton
                                              iconProps={{
                                                  iconName: "Clear",
                                                  styles: {
                                                      root: {
                                                          fontSize: 10,
                                                          color: theme.palette.neutralDark,
                                                      },
                                                  },
                                              }}
                                              styles={{
                                                  root: {
                                                      height: "100%",
                                                      border: 0,
                                                      backgroundColor: (
                                                          (props.styles as DeepPartial<ITextFieldStyles> | undefined)
                                                              ?.field as IRawStyle
                                                      )?.backgroundColor,
                                                  },
                                              }}
                                              onClick={() => {
                                                  setValue(undefined);
                                                  props.onSelectDate && props.onSelectDate(undefined);
                                              }}
                                              tabIndex={-1}
                                          />
                                      )}
                                  </>
                              );
                          },
                      }
                    : undefined
            }
            styles={{
                ...props.styles,
                statusMessage: {
                    ...(props.styles as any)?.statusMessage,
                    display: props.description ? (props.styles as any)?.statusMessage?.display : "none",
                },
            }}
        />
    );
};

export default DatePicker;
