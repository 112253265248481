import { Pivot, PivotItem, Spinner, useOnMount, usePermissionService } from "@in-core";
import DocumentLocationFileExplorer, { IDocumentLocationFileExplorerLocation } from "./DocumentLocationFileExplorer";
import { GetDirectoryContent as PersonalGetDirectoryContent } from "@in-core/api/Documents/Personal";
import { GetDirectoryContent as OrganizationGetDirectoryContent } from "@in-core/api/Documents/Organization";
import { useState } from "react";

export interface IDocumentLocationSelectorLocation extends IDocumentLocationFileExplorerLocation {
    libraryId: string;
}

export interface IDocumentLocationSelectorProps {
    defaultLocation?: IDocumentLocationSelectorLocation | null;
    location?: IDocumentLocationSelectorLocation | null;
    onLocationChanged?: (location: IDocumentLocationSelectorLocation) => void;
}

const DocumentLocationSelector = (props: IDocumentLocationSelectorProps) => {
    const permissionService = usePermissionService();

    const [internalLocation, setInternalLocation] = useState<IDocumentLocationSelectorLocation | null>(
        props.location ?? props.defaultLocation ?? null,
    );

    const location = props.location !== undefined ? props.location : internalLocation;

    const setCompleteLocation = (location: IDocumentLocationSelectorLocation) => {
        setInternalLocation(location);
        props.onLocationChanged && props.onLocationChanged(location);
    };

    useOnMount(() => {
        const hasPersonalLibrary = permissionService.isAuthorized("PersonalDocuments");
        const hasOrganizationLibrary = permissionService.isAuthorized("OrganizationDocuments");
        // TODO shared libraries

        const defaultLibrary = hasPersonalLibrary ? "Personal" : hasOrganizationLibrary ? "Organization" : undefined;

        if (!location && defaultLibrary) {
            setCompleteLocation({ libraryId: defaultLibrary, directoryPath: [] });
        }
    });

    if (location === undefined || location === null) {
        return <Spinner />;
    }

    const libraryId = location.libraryId;
    const directoryPath = location.directoryPath;

    return (
        <Pivot
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
            styles={{ itemContainer: { flex: 1 } }}
            selectedKey={location.libraryId}
            onLinkClick={(item) => {
                setCompleteLocation({ libraryId: item!.props.itemKey!, directoryPath: [] });
            }}
        >
            {permissionService.isAuthorized("PersonalDocuments") && (
                <PivotItem
                    itemKey="Personal"
                    headerText="Moji dokumenti"
                    itemIcon="FabricFolder"
                    style={{ height: "100%" }}
                >
                    <DocumentLocationFileExplorer
                        rootName="Moji dokumenti"
                        refreshKey={libraryId}
                        location={{ directoryPath: directoryPath }}
                        onLocationChanged={(newLocation) => {
                            setCompleteLocation({ ...newLocation, libraryId: location.libraryId });
                        }}
                        onGetDirectoryContent={async (directoryPath) => {
                            const response = await PersonalGetDirectoryContent.callApi({
                                DirectoryPath: directoryPath,
                            });

                            if (response.isSuccess) {
                                return response.data!;
                            }

                            return null;
                        }}
                    />
                </PivotItem>
            )}

            {permissionService.isAuthorized("OrganizationDocuments") && (
                <PivotItem
                    itemKey="Organization"
                    headerText="Dokumenti organizacije"
                    itemIcon="People"
                    style={{ height: "100%" }}
                >
                    <DocumentLocationFileExplorer
                        rootName="Moji dokumenti"
                        refreshKey={libraryId}
                        location={{ directoryPath: directoryPath }}
                        onLocationChanged={(newLocation) => {
                            setCompleteLocation({ ...newLocation, libraryId: location.libraryId });
                        }}
                        onGetDirectoryContent={async (directoryPath) => {
                            const response = await OrganizationGetDirectoryContent.callApi({
                                DirectoryPath: directoryPath,
                            });

                            if (response.isSuccess) {
                                return response.data!;
                            }

                            return null;
                        }}
                    />
                </PivotItem>
            )}
        </Pivot>
    );
};

export default DocumentLocationSelector;
