import {
    DataTable,
    DefaultButton,
    IDataTableProps,
    IPanelProps,
    ISelectionChangeItem,
    Panel,
    PanelType,
    PrimaryButton,
    SelectionMode,
    Stack,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import useOnChange from "@in-core/hooks/useOnChange";
import { getKeyString } from "@in-core/Utils";
import { useCallback, useState } from "react";

export interface IDataTablePanelSelectProps<TData = any>
    extends Omit<IPanelProps, "children">,
        Omit<IDataTableProps<TData>, "onChange" | "selectedKey" | "selectedKeys"> {
    defaultSearchValue?: string;
    onItemsSelected?: (keys: any[]) => void;
    renderDataTable?: (dataTableProps: IDataTableProps<TData>) => React.ReactNode;
}

const DataTablePanelSelect = <TData extends object = any>(props: IDataTablePanelSelectProps<TData>) => {
    const theme = useTheme();
    const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
    const localization = useInCoreLocalization();

    useOnChange(() => {
        setSelectedKeys(
            (props.selectionMode === SelectionMode.none
                ? []
                : props.selectionMode === SelectionMode.multiple
                ? props.defaultSelectedKeys
                : props.selectionMode === SelectionMode.single && props.defaultSelectedKey !== undefined
                ? [props.defaultSelectedKey]
                : undefined) ?? [],
        );
    }, [props.isOpen]);

    const onSelectedKeysChange = useCallback(
        (items: ISelectionChangeItem[]) => {
            setSelectedKeys((prevSelectedKeys) => {
                return [
                    ...prevSelectedKeys.filter((x) => {
                        return !items
                            .filter((y) => {
                                return !y.isSelected;
                            })
                            .map((y) => {
                                return getKeyString(y.key, props.idProperties ?? []);
                            })
                            .includes(getKeyString(x, props.idProperties ?? []));
                    }),
                    ...items
                        .filter((x) => {
                            return x.isSelected;
                        })
                        .map((x) => {
                            return x.key;
                        }),
                ];
            });
        },
        [props.idProperties],
    );

    return (
        <Panel
            {...props}
            isLightDismiss={props.isLightDismiss ?? true}
            type={props.type ?? PanelType.largeFixed}
            styles={{
                ...props.styles,
                scrollableContent: {
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                },
                content: {
                    flex: 1,
                    padding: 0,
                    paddingTop: theme.spacing.s1,
                    display: "flex",
                },
                footer: {
                    borderTopWidth: 1,
                    borderTopStyle: "solid",
                    borderTopColor: theme.palette.neutralLight,
                },
            }}
            onRenderFooterContent={() => {
                return (
                    <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
                        <PrimaryButton
                            onClick={() => {
                                props.onItemsSelected && props.onItemsSelected([...selectedKeys]);

                                setSelectedKeys([]);
                                props.onDismiss && props.onDismiss();
                            }}
                        >
                            {localization.Select}
                        </PrimaryButton>

                        <DefaultButton
                            onClick={() => {
                                setSelectedKeys([]);
                                props.onDismiss && props.onDismiss();
                            }}
                        >
                            {localization.Cancel}
                        </DefaultButton>
                    </Stack>
                );
            }}
            isFooterAtBottom
        >
            {props.isOpen &&
                (props.renderDataTable ? (
                    props.renderDataTable({
                        ...props,
                        defaultSelectedKey: undefined,
                        defaultSelectedKeys: undefined,
                        selectedKey: props.selectionMode === SelectionMode.multiple ? undefined : selectedKeys[0],
                        selectedKeys: props.selectionMode === SelectionMode.multiple ? selectedKeys : undefined,
                        onSelectionChanged: onSelectedKeysChange,
                        onItemInvoked: (key) => {
                            props.onItemsSelected && props.onItemsSelected([key]);

                            setSelectedKeys([]);
                            props.onDismiss && props.onDismiss();
                        },
                    })
                ) : (
                    <DataTable
                        {...props}
                        defaultSelectedKey={undefined}
                        defaultSelectedKeys={undefined}
                        selectedKey={props.selectionMode === SelectionMode.multiple ? undefined : selectedKeys[0]}
                        selectedKeys={props.selectionMode === SelectionMode.multiple ? selectedKeys : undefined}
                        onSelectionChanged={onSelectedKeysChange}
                        onItemInvoked={(key) => {
                            props.onItemsSelected && props.onItemsSelected([key]);

                            setSelectedKeys([]);
                            props.onDismiss && props.onDismiss();
                        }}
                    />
                ))}
        </Panel>
    );
};

export default DataTablePanelSelect;
