import { EntityDataTable, FilterValueOperator } from "@in-core";
import DataEntry from "./DataEntry";
import GenerateOpenApi from "./GenerateOpenApi";
import ManageKeys from "./ManageKeys";

export interface IConnectedAppsProps {}

const ConnectedApps = (props: IConnectedAppsProps) => {
    return (
        <EntityDataTable
            routable
            entity="ConnectedApps"
            defaultSort={{ Id: "Name", IsDescending: false }}
            defaultFilters={{
                IsBlocked: {
                    IsNegated: false,
                    Value: {
                        LeftOperand: { Id: "IsBlocked" },
                        Operator: FilterValueOperator.Equal,
                        RightOperand: { Value: false },
                    },
                },
            }}
            innerComponents={{
                Create: <DataEntry />,
                Read: <DataEntry />,
                Update: <DataEntry />,
                ManageKeys: <ManageKeys />,
                GenerateOpenApi: <GenerateOpenApi />,
            }}
        />
    );
};

export default ConnectedApps;
