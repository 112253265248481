import {
    AggregateData,
    AggregateDataItem,
    AggregateDataType,
    ApiStatus,
    ChoiceGroup,
    DefaultButton,
    Dialog,
    DialogFooter,
    Filter,
    FilterGroupOperator,
    FormGroup,
    FormSection,
    IDataTableColumn,
    MessageBar,
    MessageBarType,
    ModalSpinner,
    Permission,
    PrimaryButton,
    Selector,
    Sort,
    Stack,
    TextField,
    downloadBase64File,
    useInCoreLocalization,
    useOnChange,
    useOnMount,
    usePanelContext,
    useTheme,
} from "@in-core";
import SelectorSection from "./SelectorSection";
import { useState } from "react";
import { ExportData } from "@in-core/api/Entity";
import ModalPdfViewer from "@in-core/components/ModalPdfViewer";
import FilterSection from "./FilterSection";
import SortSection from "./SortSection";
import { Group, Pagination } from "@in-core/api";
import PaginationSection from "./PaginationSection";
import GroupSection from "./GroupSection";

const defaultPageCount = 25;
const defaultPageIndex = 0;

const maxNumberOfColumns: Record<ExportData.ExportType, number | undefined> = {
    PdfVertical: 8,
    PdfHorizontal: 12,
    Excel: undefined,
    Csv: undefined,
};

export interface IExportProps {
    permission: Permission;
    columns: IDataTableColumn[];
    additionalFilter: Filter | undefined;
    initialSelectors: Record<string, Selector>;
    initialFilters: Record<string, Filter>;
    initialGroup: Group | undefined;
    initialSort: Sort | undefined;
    initialAggregateDataTypes: Record<string, AggregateDataType[]>;
    initialPagination: Pagination | undefined;
}

const Export = (props: IExportProps) => {
    const [fileName, setFileName] = useState("");
    const [exportType, setExportType] = useState<ExportData.ExportType>(ExportData.ExportType.PdfVertical);
    const [selectors, setSelectors] = useState<Record<string, Selector>>(() => {
        const selectorsClone: Record<string, Selector> = {};
        Object.keys(props.initialSelectors).forEach((x) => {
            selectorsClone[x] = props.initialSelectors[x];
        });
        return selectorsClone;
    });
    const [filters, setFilters] = useState<Record<string, Filter>>(() => {
        const filtersClone: Record<string, Filter> = {};
        Object.keys(props.initialFilters).forEach((x) => {
            filtersClone[x] = props.initialFilters[x];
        });
        return filtersClone;
    });
    const [group, setGroup] = useState<Group | undefined>(deepCloneGroup(props.initialGroup));
    const [sort, setSort] = useState<Sort | undefined>(props.initialSort ? { ...props.initialSort } : undefined);
    const [aggregateDataTypes, setAggregateDataTypes] = useState<Record<string, AggregateDataType[]>>(
        cloneAggregateDataTypes(props.initialAggregateDataTypes),
    );
    const [pagination, setPagination] = useState<Pagination>(
        props.initialPagination
            ? { ...props.initialPagination }
            : { ItemsPerPage: defaultPageCount, PageIndex: defaultPageIndex },
    );
    const exportDataApi = ExportData.useApi();

    const panelContext = usePanelContext();
    const theme = useTheme();
    const localization = useInCoreLocalization();

    useOnMount(
        () => {},
        () => {
            if (panelContext === undefined) {
                return;
            }

            panelContext.clearProps();
        },
    );

    useOnChange(() => {
        if (panelContext === undefined) {
            return;
        }

        panelContext.setProps(() => {
            return {
                isFooterAtBottom: true,
                styles: {
                    footer: {
                        borderTopWidth: 1,
                        borderTopColor: theme.palette.neutralLight,
                        borderTopStyle: "solid",
                        backgroundColor: theme.palette.white,
                    },
                },
                onRenderFooterContent: () => {
                    return (
                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: theme.spacing.s1 }}>
                            <PrimaryButton
                                onClick={async () => {
                                    const fixedFilter: Filter | undefined =
                                        Object.keys(filters).length > 0
                                            ? {
                                                  IsNegated: false,
                                                  Group: {
                                                      Operator: FilterGroupOperator.And,
                                                      Filters: Object.values(filters).filter((x) => {
                                                          return x !== undefined;
                                                      }) as Filter[],
                                                  },
                                              }
                                            : undefined;

                                    const fixedAggregateData: AggregateData = {
                                        Items: Object.keys(aggregateDataTypes).map((x) => {
                                            const result: AggregateDataItem = {
                                                Id: x,
                                                AggregateDataTypes: aggregateDataTypes[x],
                                            };

                                            return result;
                                        }),
                                    };

                                    const fixedSort = getFixedSort(sort, props.columns);
                                    const fixedGroup = getFixedGroup(group, props.columns);

                                    const exportDataResponse = await exportDataApi.call({
                                        Permission: props.permission.CompleteId,
                                        FileName: fileName.trim().length > 0 ? fileName.trim() : undefined,
                                        ExportType: exportType,
                                        AdditionalFilter: props.additionalFilter,
                                        Selector: { Id: "", Group: { Items: Object.values(selectors) } },
                                        AggregateData: fixedAggregateData,
                                        Filter: fixedFilter,
                                        Sort: fixedSort,
                                        Pagination: pagination,
                                        Group: fixedGroup,
                                    });

                                    if (
                                        exportDataResponse.isSuccess &&
                                        (exportType === ExportData.ExportType.Excel ||
                                            exportType === ExportData.ExportType.Csv)
                                    ) {
                                        const actualFileName =
                                            fileName.trim() === "" ? props.permission.Name : fileName;

                                        const fileNameWithExtension =
                                            exportType === ExportData.ExportType.Excel
                                                ? `${actualFileName}.xlsx`
                                                : exportType === ExportData.ExportType.Csv
                                                ? `${actualFileName}.csv`
                                                : actualFileName;

                                        downloadBase64File(exportDataResponse.data!, fileNameWithExtension);

                                        exportDataApi.reset();
                                    }
                                }}
                            >
                                {localization.Accept}
                            </PrimaryButton>

                            <DefaultButton
                                onClick={() => {
                                    if (panelContext?.overridenProps.onDismiss) {
                                        panelContext.overridenProps.onDismiss();
                                    }
                                }}
                            >
                                {localization.Cancel}
                            </DefaultButton>
                        </Stack>
                    );
                },
            };
        });
    }, [fileName, exportType, selectors, aggregateDataTypes, filters, sort, pagination, group, props.permission]);

    const isNoGroupingOrAggregateData = isNoGroupingOrAggregateDataType(exportType);

    return (
        <Stack styles={{ root: { marginTop: theme.spacing.m } }}>
            <FormGroup elementWidth={300} verticalGap={theme.spacing.m}>
                <FormSection label={localization.EntityDataTable.Basic}>
                    <Stack styles={{ root: { maxHeight: 500, overflow: "auto" } }}>
                        <TextField
                            label={localization.EntityDataTable.ReportName}
                            value={fileName}
                            onChange={(_, newValue) => {
                                setFileName(newValue ?? "");
                            }}
                            placeholder={props.permission.Name}
                        />

                        <ChoiceGroup
                            label={localization.EntityDataTable.ReportFormat}
                            options={[
                                {
                                    key: ExportData.ExportType.PdfVertical,
                                    text: localization.EntityDataTable.ReportFormatPdfVertical,
                                },
                                {
                                    key: ExportData.ExportType.PdfHorizontal,
                                    text: localization.EntityDataTable.ReportFormatPdfHorizontal,
                                },
                                {
                                    key: ExportData.ExportType.Excel,
                                    text: localization.EntityDataTable.ReportFormatExcel,
                                },
                                { key: ExportData.ExportType.Csv, text: localization.EntityDataTable.ReportFormatCsv },
                            ]}
                            selectedKey={exportType}
                            onChange={(_, option) => {
                                setExportType(option?.key as any);

                                if (option?.key && isNoGroupingOrAggregateDataType(option.key as any)) {
                                    setAggregateDataTypes({});
                                    setGroup(undefined);
                                }
                            }}
                        />
                    </Stack>
                </FormSection>

                <FormSection label={localization.DataTable.Selection} style={{ gridColumn: "span 3" }}>
                    {maxNumberOfColumns[exportType] &&
                        Object.keys(selectors).length > maxNumberOfColumns[exportType]! && (
                            <MessageBar messageBarType={MessageBarType.warning}>
                                {localization.EntityDataTable.ReportTooManyColumnsPrefix}
                                {maxNumberOfColumns[exportType]}
                                {localization.EntityDataTable.ReportTooManyColumnsSuffix}
                            </MessageBar>
                        )}

                    <SelectorSection
                        visibleColumns={props.columns}
                        selectors={selectors}
                        onSelectorsChanged={(selectors) => {
                            setSelectors(selectors);

                            const allowedSelectors = Object.keys(selectors);
                            setGroup(getAllowedGroup(group, allowedSelectors));
                        }}
                        aggregateDataTypes={aggregateDataTypes}
                        onAggregateDataChanged={setAggregateDataTypes}
                        hideAggregateData={isNoGroupingOrAggregateData}
                    />
                </FormSection>

                <FormSection label={localization.DataTable.Filter} style={{ gridColumn: "span 2" }}>
                    <FilterSection columns={props.columns} filters={filters} onFilterssChanged={setFilters} />
                </FormSection>

                <FormSection label={localization.DataTable.Sort}>
                    <SortSection columns={props.columns} sort={sort} onSortChanged={setSort} />
                </FormSection>

                {!isNoGroupingOrAggregateData && (
                    <FormSection label={localization.DataTable.Grouping}>
                        <GroupSection
                            columns={props.columns}
                            group={group}
                            onGroupChanged={setGroup}
                            selectors={selectors}
                        />
                    </FormSection>
                )}

                <FormSection label={localization.DataTable.Pagination}>
                    <PaginationSection pagination={pagination} onPaginationChanged={setPagination} />
                </FormSection>
            </FormGroup>

            <ModalPdfViewer
                isOpen={
                    (exportType === ExportData.ExportType.PdfHorizontal ||
                        exportType === ExportData.ExportType.PdfVertical) &&
                    (exportDataApi.status === ApiStatus.Loading || exportDataApi.status === ApiStatus.Success)
                }
                onClose={exportDataApi.reset}
                content={exportDataApi.data}
                fileName={fileName.trim().length > 0 ? fileName.trim() : props.permission.Name}
            />

            <ModalSpinner
                isOpen={
                    (exportType === ExportData.ExportType.Excel || exportType === ExportData.ExportType.Csv) &&
                    exportDataApi.status === ApiStatus.Loading
                }
            />

            <Dialog
                hidden={exportDataApi.status !== ApiStatus.Error}
                dialogContentProps={{
                    title: localization.EntityDataTable.ReportError,
                    subText: localization.EntityDataTable.ReportErrorMessage,
                    showCloseButton: true,
                }}
                onDismiss={exportDataApi.reset}
            >
                <DialogFooter>
                    <PrimaryButton onClick={exportDataApi.reset}>{localization.Ok}</PrimaryButton>
                </DialogFooter>
            </Dialog>
        </Stack>
    );
};

export default Export;

const cloneAggregateDataTypes = (aggregateDataTypes: Record<string, AggregateDataType[]>) => {
    const result: Record<string, AggregateDataType[]> = {};

    Object.keys(aggregateDataTypes).forEach((x) => {
        result[x] = [...aggregateDataTypes[x]];
    });

    return result;
};

const getFixedSort = (sort: Sort | undefined, columns: IDataTableColumn[]): Sort | undefined => {
    if (!sort) {
        return undefined;
    }

    const column = columns.find((x) => {
        return x.id === sort.Id;
    });

    return {
        Id: column ? column.sortId ?? column.id : sort.Id,
        IsDescending: sort.IsDescending,
        NestedSort: getFixedSort(sort.NestedSort, columns),
    };
};

const getFixedGroup = (group: Group | undefined, columns: IDataTableColumn[]): Group | undefined => {
    if (!group) {
        return undefined;
    }

    const column = columns.find((x) => {
        return x.id === group.Id;
    });

    return {
        Id: column ? column.sortId ?? column.id : group.Id,
        NestedGroup: getFixedGroup(group.NestedGroup, columns),
    };
};

const getAllowedGroup = (group: Group | undefined, allowedIds: string[]): Group | undefined => {
    if (!group) {
        return undefined;
    }

    if (!allowedIds.includes(group.Id)) {
        return undefined;
    }

    return { ...group, NestedGroup: getAllowedGroup(group.NestedGroup, allowedIds) };
};

const deepCloneGroup = (group?: Group): Group | undefined => {
    if (!group) {
        return undefined;
    }

    return { Id: group.Id, NestedGroup: deepCloneGroup(group.NestedGroup) };
};

const isNoGroupingOrAggregateDataType = (exportType: ExportData.ExportType) => {
    return exportType === ExportData.ExportType.Excel || exportType === ExportData.ExportType.Csv;
};
