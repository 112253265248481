export default class PermissionImplicationGraph {
    private _impliedBy: Map<string, string[]>;

    constructor() {
        this._impliedBy = new Map<string, string[]>();
    }

    public addImplication(implied: string, impliedBy: string): void {
        if (!this._impliedBy.has(implied)) {
            this._impliedBy.set(implied, []);
        }

        this._impliedBy.get(implied)?.push(impliedBy);
    }

    public getPermissionsThatImply(implied: string): string[] {
        if (!this._impliedBy.has(implied)) {
            return [];
        }

        const visited = new Set<string>();
        const result = new Set<string>();

        this.getPermissionsThatImplyHelper(implied, visited, result);

        return Array.from(result);
    }

    private getPermissionsThatImplyHelper(node: string, visited: Set<string>, result: Set<string>): void {
        const neighbors = this._impliedBy.get(node);
        if (neighbors) {
            for (const neighbor of neighbors) {
                if (!visited.has(neighbor)) {
                    visited.add(neighbor);
                    result.add(neighbor);
                    this.getPermissionsThatImplyHelper(neighbor, visited, result);
                }
            }
        }
    }
}
