import {
    ChoiceGroup as FluentChoiceGroup,
    IChoiceGroupOptionStyleProps,
    IChoiceGroupOptionStyles,
    IChoiceGroupProps as IFluentChoiceGroupProps,
    IChoiceGroupStyleProps,
    IChoiceGroupStyles,
    IStyleFunction,
    IStyleFunctionOrObject,
    Theme,
    useTheme,
} from "@fluentui/react";
import { DeepPartial } from "@fluentui/merge-styles/lib";

const getModifiedInlineStyleObject = (
    defaultStyles: DeepPartial<IChoiceGroupStyles>,
): DeepPartial<IChoiceGroupStyles> => {
    return {
        ...defaultStyles,
        flexContainer: {
            ...(typeof defaultStyles.flexContainer === "object" ? defaultStyles.flexContainer : {}),
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
        },
    };
};

const getModifiedInlineOptionStyleObject = (
    defaultStyles: DeepPartial<IChoiceGroupOptionStyles>,
    theme: Theme,
): DeepPartial<IChoiceGroupOptionStyles> => {
    return {
        ...defaultStyles,
        root: {
            ...(typeof defaultStyles.root === "object" ? defaultStyles.root : {}),
            marginTop: 0,
            marginRight: theme.spacing.m,
        },
    };
};

export interface IChoiceGroupProps extends IFluentChoiceGroupProps {
    isInline?: boolean;
}

const ChoiceGroup = (props: IChoiceGroupProps) => {
    const theme = useTheme();

    if (!props.isInline) {
        return <FluentChoiceGroup {...props} />;
    }

    let inlineStyles: IStyleFunctionOrObject<IChoiceGroupOptionStyleProps, IChoiceGroupStyles> | undefined;

    if (!props.styles) {
        inlineStyles = getModifiedInlineStyleObject({});
    } else if (typeof props.styles === "function") {
        const stylesFunction = props.styles as IStyleFunction<IChoiceGroupStyleProps, IChoiceGroupStyles>;

        inlineStyles = (styleProps: IChoiceGroupStyleProps) => {
            const defaultStyles = stylesFunction(styleProps);
            return getModifiedInlineStyleObject(defaultStyles);
        };
    } else {
        const stylesObject = props.styles as DeepPartial<IChoiceGroupStyles>;

        inlineStyles = getModifiedInlineStyleObject(stylesObject);
    }

    let inlineOptions = props.options?.map((x) => {
        let inlineOptionStyles:
            | IStyleFunctionOrObject<IChoiceGroupOptionStyleProps, IChoiceGroupOptionStyles>
            | undefined;

        if (!x.styles) {
            inlineOptionStyles = getModifiedInlineOptionStyleObject({}, theme);
        } else if (typeof x.styles === "function") {
            const stylesFunction = x.styles as IStyleFunction<IChoiceGroupOptionStyleProps, IChoiceGroupOptionStyles>;

            inlineOptionStyles = (styleProps: IChoiceGroupOptionStyleProps) => {
                const defaultStyles = stylesFunction(styleProps);
                return getModifiedInlineOptionStyleObject(defaultStyles, theme);
            };
        } else {
            const stylesObject = x.styles as DeepPartial<IChoiceGroupOptionStyles>;

            inlineOptionStyles = getModifiedInlineOptionStyleObject(stylesObject, theme);
        }

        return {
            ...x,
            styles: inlineOptionStyles,
        };
    });

    return <FluentChoiceGroup {...props} styles={inlineStyles} options={inlineOptions} />;
};

export default ChoiceGroup;
