import { useOnChange } from "@in-core";

export interface IRendererProps {
    renderKey?: React.Key | null;
    children?: JSX.Element | null;
    onMounted?: () => void;
    onUnmounted?: () => void;
}

const Renderer = (props: IRendererProps) => {
    useOnChange(
        () => {
            props.onMounted && props.onMounted();
        },
        [props.renderKey],
        undefined,
        () => {
            props.onUnmounted && props.onUnmounted();
        },
    );

    return props.children ?? null;
};

export default Renderer;
