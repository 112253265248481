import {
    DataEntryForm,
    DataEntryType,
    DataTable,
    FormCheckbox,
    FormGroup,
    FormTextField,
    MessageBar,
    Spinner,
    Stack,
    Toggle,
    useEntityDataTableContext,
    useForm,
    useInCoreLocalization,
    useOnMount,
    usePanelContext,
    useTheme,
} from "@in-core";
import { Create, Data, GetApis, Read, Update, Validate } from "@in-core/api/ConnectedApps";
import { DataType } from "@in-core/api/Entity";
import { useMemo, useState } from "react";

const DataEntry = () => {
    const theme = useTheme();
    const localization = useInCoreLocalization();
    const entityDataTableContext = useEntityDataTableContext();
    const panelContext = usePanelContext();
    const getApisApi = GetApis.useApi();
    const form = useForm<Data>({
        validate: async (values) => {
            var response = await Validate.callApi({
                ...values,
            } as any);
            return response.data!;
        },
        shouldClearEmptyArrays: false,
    });
    const [showOnlySelected, setShowOnlySelected] = useState(false);

    useOnMount(
        () => {
            getApisApi.call({});

            panelContext?.setProps({
                styles: {
                    scrollableContent: { height: "100%", display: "flex", flexDirection: "column" },
                    content: { flex: 1 },
                },
            });
        },
        () => {
            panelContext?.clearProps();
        },
    );

    const idProperties = useMemo<(keyof GetApis.ApiInfo)[]>(() => {
        return ["Id"];
    }, []);

    if (!getApisApi.data) {
        return <Spinner />;
    }

    return (
        <DataEntryForm
            type={DataEntryType.AutoKey}
            form={form}
            keyValues={{ Id: form.Id }}
            createApi={Create.callApi}
            readApi={Read.callApi}
            updateApi={Update.callApi}
            removePaddingBottom
            style={{ height: "100%" }}
        >
            <Stack tokens={{ childrenGap: theme.spacing.m }} styles={{ root: { height: "100%" } }}>
                <FormGroup>
                    {entityDataTableContext?.innerPermission?.Id !== "Create" && (
                        <FormTextField value={form.Id} label="ID" readOnly hideClear />
                    )}

                    <FormTextField value={form.Name} label="Naziv" />

                    {entityDataTableContext?.innerPermission?.Id !== "Create" && (
                        <FormCheckbox value={form.IsBlocked} label="Blokirana?" />
                    )}
                </FormGroup>

                {entityDataTableContext?.innerPermission?.Id === "Create" && (
                    <MessageBar>{localization.ConnectedApps.CreateMessage}</MessageBar>
                )}

                <Toggle
                    label={localization.ConnectedApps.ShowOnlyAssigned}
                    inlineLabel
                    checked={showOnlySelected}
                    onChange={(_, checked) => {
                        setShowOnlySelected(checked ?? false);
                    }}
                />

                <DataTable
                    data={
                        showOnlySelected
                            ? getApisApi.data.filter((x) => {
                                  return (form.Apis.$value ?? []).includes(x.Id);
                              })
                            : getApisApi.data
                    }
                    columns={[
                        {
                            id: "Id",
                            property: "Id",
                            name: localization.ConnectedApps.IdColumn,
                            dataType: DataType.String,
                        },
                        {
                            id: "Name",
                            property: "Name",
                            name: localization.ConnectedApps.NameColumn,
                            dataType: DataType.String,
                        },
                        {
                            id: "Description",
                            property: "Description",
                            name: localization.ConnectedApps.DescriptionColumn,
                            dataType: DataType.String,
                        },
                    ]}
                    idProperties={idProperties}
                    hideToolbar
                    disableAggregating
                    isSelectionCheckbox
                    selectedKeys={form.Apis.$value ?? []}
                    onSelectionChanged={(items) => {
                        form.Apis.$setValue([
                            ...(form.Apis.$value ?? []).filter((x) => {
                                return !items
                                    .filter((y) => {
                                        return !y.isSelected;
                                    })
                                    .map((y) => {
                                        return y.key as string;
                                    })
                                    .includes(x);
                            }),
                            ...items
                                .filter((x) => {
                                    return x.isSelected;
                                })
                                .map((x) => {
                                    return x.key as string;
                                }),
                        ]);
                    }}
                />
            </Stack>
        </DataEntryForm>
    );
};

export default DataEntry;
