import { NumericField, Stack, useInCoreLocalization } from "@in-core";
import { Pagination } from "@in-core/api";

const defaultPageCount = 25;
const defaultPageIndex = 0;

export interface IPaginationSectionProps {
    pagination: Pagination;
    onPaginationChanged: (newPagination: Pagination) => void;
}

const PaginationSection = (props: IPaginationSectionProps) => {
    const localization = useInCoreLocalization();

    return (
        <Stack>
            <NumericField
                label={localization.DataTable.PaginationNumberOfItems}
                value={props.pagination ? props.pagination.ItemsPerPage : null}
                onChange={(_, newValue) => {
                    if (newValue === null || newValue === undefined) {
                        return;
                    }

                    props.onPaginationChanged({ ...props.pagination, ItemsPerPage: newValue });
                }}
                onBlur={(e) => {
                    if (e.target.value.trim() === "") {
                        props.onPaginationChanged({ ...props.pagination, ItemsPerPage: defaultPageCount });
                    }
                }}
            />

            <NumericField
                label={localization.DataTable.PaginationPage}
                min={1}
                value={props.pagination ? props.pagination.PageIndex + 1 : null}
                onChange={(_, newValue) => {
                    if (newValue === null || newValue === undefined) {
                        return;
                    }

                    props.onPaginationChanged({ ...props.pagination, PageIndex: newValue < 1 ? 1 : newValue });
                }}
                onBlur={(e) => {
                    if (e.target.value.trim() === "") {
                        props.onPaginationChanged({ ...props.pagination, PageIndex: defaultPageIndex });
                    }
                }}
            />
        </Stack>
    );
};

export default PaginationSection;
