import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import {
    ChoiceGroup,
    FormSection,
    PrimaryButton,
    Spinner,
    Stack,
    useEntityDataTableContext,
    useInCoreLocalization,
    useOnMount,
    useTheme,
} from "@in-core";
import { GetOpenAPI } from "@in-core/api/ConnectedApps";
import { useState } from "react";

const GenerateOpenApi = () => {
    const entityDataTableContext = useEntityDataTableContext()!;
    const getOpenAPI = GetOpenAPI.useApi();
    const [selectedFormat, setSelectedFormat] = useState("json");
    const theme = useTheme();
    const localization = useInCoreLocalization();

    useOnMount(() => {
        getOpenAPI.call({ AppId: entityDataTableContext.selectedKeys[0] });
    });

    if (!getOpenAPI.isSuccess) {
        return <Spinner />;
    }

    return (
        <Stack tokens={{ childrenGap: theme.spacing.l2 }}>
            <Stack tokens={{ childrenGap: theme.spacing.s1 }} horizontalAlign="start">
                <ChoiceGroup
                    label={localization.ConnectedApps.DocumentationFormat}
                    options={[
                        { key: "json", text: "JSON" },
                        { key: "yaml", text: "YAML" },
                    ]}
                    isInline
                    onChange={(_, option) => {
                        setSelectedFormat(option!.key);
                    }}
                    selectedKey={selectedFormat}
                />

                <PrimaryButton
                    onClick={() => {
                        const content = selectedFormat === "yaml" ? getOpenAPI.data!.Yaml : getOpenAPI.data!.Json;
                        const a = document.createElement("a");
                        a.href = `data:application/octet-stream;base64,${btoa(content)}`;
                        a.download = `${getOpenAPI.data!.Name}.${selectedFormat}`;
                        a.click();
                    }}
                >
                    {localization.Download}
                </PrimaryButton>
            </Stack>

            <FormSection>
                <SwaggerUI spec={JSON.parse(getOpenAPI.data!.Json)} supportedSubmitMethods={[]} />
            </FormSection>
        </Stack>
    );
};

export default GenerateOpenApi;
