import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import {
    Breadcrumb,
    CommandBar,
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    DetailsRow,
    Dialog,
    DialogFooter,
    DirectoryContent,
    IBreadcrumbItem,
    IButtonStyles,
    ICommandBarItemProps,
    IObjectWithKey,
    Icon,
    IconButton,
    Link,
    MarqueeSelection,
    MessageBar,
    MessageBarType,
    Modal,
    PrimaryButton,
    ScrollablePane,
    Selection,
    SelectionMode,
    Separator,
    ShimmeredDetailsList,
    Spinner,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
    useInCoreLocalization,
    useOnChange,
    useTheme,
} from "@in-core";
import React, { useMemo, useState } from "react";
import FilePreviewer from "./FilePreviewer";
import FileUploadModal from "../FileUploadModal";
import { NewContentInfo } from "@in-core/api/Documents";
import NewFolder from "./NewFolder";
import Rename from "./Rename";
import LocationSelector from "./LocationSelector";

interface IFileExplorerListItem {
    type: "directory" | "file";
    name: string;
    timeAdded?: Date;
    addedBy: string;
    fileSize?: number;
}

export interface IFileExplorerLocation {
    directoryPath: string[];
    fileName: string | null;
}

export interface IFileExplorerProps {
    rootText?: string;
    onRenderRoot?: () => React.ReactElement | null;
    rootName?: string;

    refreshKey?: string;

    defaultLocation?: IFileExplorerLocation;
    location?: IFileExplorerLocation;
    onLocationChanged?: (location: IFileExplorerLocation) => void;

    onGetDirectoryContent: (directoryPath: string[]) => Promise<DirectoryContent | null>;
    onGetFilePreview: (directoryPath: string[], fileName: string) => Promise<string | null>;

    onUploadContent?: (directoryPath: string[], newContent: NewContentInfo) => Promise<void>;
    onCreateNewDirectory?: (directoryPath: string[]) => Promise<void>;
    onRename?: (path: string[], newName: string) => Promise<void>;
    onCopy?: (oldLocation: string[], items: string[], newLibraryId: string, newLocation: string[]) => Promise<void>;
    onMove?: (oldLocation: string[], items: string[], newLibraryId: string, newLocation: string[]) => Promise<void>;
    onDelete?: (path: string[]) => Promise<void>;
    onDownload?: (path: string[], itemsToAdd?: string[]) => Promise<string>;
}

const FileExplorer = (props: IFileExplorerProps) => {
    const localization = useInCoreLocalization();
    const [internalLocation, setInternalLocation] = useState<IFileExplorerLocation>(
        props.location ?? props.defaultLocation ?? { directoryPath: [], fileName: null },
    );

    const [directoryContent, setDirectoryContent] = useState<DirectoryContent | null>();

    const [sort, setSort] = useState<"name" | "timeAdded" | "addedBy" | "fileSize">("name");
    const [isSortDescending, setIsSortDescending] = useState(false);
    const theme = useTheme();

    const [isUpload, setIsUpload] = useState(false);
    const [isNewFolder, setIsNewFolder] = useState(false);
    const [renameItemName, setRenameItemName] = useState<string>();
    const [isCopy, setIsCopy] = useState(false);
    const [isMove, setIsMove] = useState(false);
    const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

    const [isSelectionChanged, setIsSelectionChanged] = useState(false);
    const selection = useMemo(() => {
        return new Selection<IFileExplorerListItem>({
            onSelectionChanged: () => {
                setIsSelectionChanged(true);
            },
            getKey: (item) => {
                return (item as any).name;
            },
        });
    }, []);
    useOnChange(() => {
        setIsSelectionChanged(false);
    }, [isSelectionChanged]);

    const commandBarButtonStyles: IButtonStyles = {
        root: {
            borderRadius: theme.effects.roundedCorner2,
            height: 32,
            backgroundColor: theme.palette.neutralLighterAlt,
            marginLeft: theme.spacing.s1,
        },
        rootHovered: {
            backgroundColor: theme.palette.themeLighterAlt,
        },
        rootPressed: {
            backgroundColor: theme.palette.themeLighterAlt,
        },
    };

    const directoryPath = (props.location ?? internalLocation).directoryPath;
    const fileName = (props.location ?? internalLocation).fileName;

    const refreshContent = async () => {
        setDirectoryContent(undefined);
        const newDirectoryContent = await props.onGetDirectoryContent(directoryPath);
        setDirectoryContent(newDirectoryContent);
    };

    const setCompleteLocation = (location: IFileExplorerLocation) => {
        setInternalLocation(location);
        props.onLocationChanged && props.onLocationChanged(location);
    };

    useOnChange(() => {
        refreshContent();
    }, [directoryPath, props.refreshKey]);

    const onColumnClicked = (columnName: "name" | "timeAdded" | "addedBy" | "fileSize") => {
        return () => {
            if (sort !== columnName) {
                setSort(columnName);
                setIsSortDescending(false);
            } else {
                setIsSortDescending((prevIsSortDescending) => {
                    return !prevIsSortDescending;
                });
            }
        };
    };

    const breadcrumbItems: IBreadcrumbItem[] = [];

    breadcrumbItems.push({
        key: "__root",
        text: props.rootText ?? "/",
        onRenderContent: props.onRenderRoot
            ? () => {
                  return props.onRenderRoot!();
              }
            : undefined,
        onClick: props.onRenderRoot
            ? undefined
            : () => {
                  setCompleteLocation({ directoryPath: [], fileName: fileName });
              },
    });

    breadcrumbItems.push(
        ...directoryPath.map((x, i) => {
            return {
                key: x,
                text: x,
                onClick: () => {
                    const newDirectoryPath = directoryPath.slice(0, i + 1);
                    setCompleteLocation({ directoryPath: newDirectoryPath, fileName: fileName });
                },
            };
        }),
    );

    const sortFunc = (a: IFileExplorerListItem, b: IFileExplorerListItem) => {
        if (sort === "addedBy" || sort === "name") {
            return isSortDescending ? b[sort].localeCompare(a[sort]) : a[sort].localeCompare(b[sort]);
        }

        const aValue = a[sort] ?? 0;
        const bValue = b[sort] ?? 0;
        return aValue < bValue ? (isSortDescending ? -1 : 1) : aValue === bValue ? 0 : isSortDescending ? 1 : -1;
    };

    const getFullDisplayName = (displayName: string | undefined, userName: string | undefined) => {
        if (displayName === undefined && userName === undefined) {
            return undefined;
        }

        if (displayName !== undefined && userName !== undefined) {
            return `${displayName} (@${userName})`;
        }

        return displayName ?? `@${userName}`;
    };

    const items: IFileExplorerListItem[] | null | undefined = useMemo(() => {
        return directoryContent === null
            ? null
            : directoryContent === undefined
            ? undefined
            : [
                  ...directoryContent.Directories.map((x) => {
                      return {
                          type: "directory",
                          name: x.Name,
                          timeAdded: x.TimeAdded,
                          addedBy: getFullDisplayName(x.UserDisplayName, x.UserName) ?? localization.Documents.System,
                      } as IFileExplorerListItem;
                  }).sort(sortFunc),
                  ...directoryContent.Files.map((x) => {
                      return {
                          type: "file",
                          name: x.Name,
                          timeAdded: x.TimeAdded,
                          addedBy: getFullDisplayName(x.UserDisplayName, x.UserName) ?? localization.Documents.System,
                          fileSize: x.Size,
                      } as IFileExplorerListItem;
                  }).sort(sortFunc),
              ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directoryContent, sort, isSortDescending]);

    const selectedItems = selection.getSelection();
    const hasSelectedItems = selectedItems.length > 0;

    const commandBarItems: ICommandBarItemProps[] = [];

    commandBarItems.push({
        key: "Refresh",
        text: localization.Documents.Refresh,
        iconProps: { iconName: "Refresh" },
        buttonStyles: commandBarButtonStyles,
        onClick: () => {
            refreshContent();
        },
    });

    if (props.onUploadContent) {
        commandBarItems.push({
            key: "Upload",
            text: localization.Documents.Upload,
            iconProps: { iconName: "Upload" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setIsUpload(true);
            },
        });
    }

    if (props.onCreateNewDirectory) {
        commandBarItems.push({
            key: "NewFolder",
            text: localization.Documents.NewFolder,
            iconProps: { iconName: "Folder" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setIsNewFolder(true);
            },
        });
    }

    if (selectedItems.length === 1 && props.onRename) {
        commandBarItems.push({
            key: "Rename",
            text: localization.Documents.Rename,
            iconProps: { iconName: "Rename" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setRenameItemName(selectedItems[0].name);
            },
        });
    }

    if (hasSelectedItems && props.onCopy) {
        commandBarItems.push({
            key: "Copy",
            text: localization.Documents.Copy,
            iconProps: { iconName: "Copy" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setIsCopy(true);
            },
        });
    }

    if (hasSelectedItems && props.onMove) {
        commandBarItems.push({
            key: "Move",
            text: localization.Documents.Move,
            iconProps: { iconName: "MoveToFolder" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setIsMove(true);
            },
        });
    }

    if (hasSelectedItems && props.onDelete) {
        commandBarItems.push({
            key: "Delete",
            text: localization.Documents.Delete,
            iconProps: { iconName: "Delete" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setIsDeleteConfirmation(true);
            },
        });
    }

    if (props.onDownload) {
        commandBarItems.push({
            key: "Download",
            text: localization.Documents.Download,
            iconProps: { iconName: "Download" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                const runner = async () => {
                    if (!props.onDownload) {
                        return;
                    }

                    const selectedFileName =
                        fileName ??
                        (selectedItems.length === 1 && selectedItems[0].type === "file"
                            ? selectedItems[0].name
                            : undefined);

                    const selectedDirectoryName =
                        selectedItems.length === 1 && selectedItems[0].type === "directory"
                            ? selectedItems[0].name
                            : undefined;

                    const rawContent = await props.onDownload(
                        selectedFileName
                            ? [...directoryPath, selectedFileName]
                            : selectedDirectoryName
                            ? [...directoryPath, selectedDirectoryName]
                            : directoryPath,
                        selectedFileName || selectedDirectoryName || selectedItems.length === 0
                            ? undefined
                            : selectedItems.map((x) => {
                                  return x.name;
                              }),
                    );

                    const a = document.createElement("a");
                    a.href = `data:application/octet-stream;base64,${rawContent}`;
                    a.download = selectedFileName
                        ? selectedFileName
                        : selectedDirectoryName
                        ? `${selectedDirectoryName}.zip`
                        : directoryPath.length > 0
                        ? `${directoryPath[directoryPath.length - 1]}.zip`
                        : `${props.rootName ?? props.rootText ?? "download"}.zip`;
                    a.click();
                };

                runner();
            },
        });
    }

    const fileCommandBarItems: ICommandBarItemProps[] = [];

    if (fileName) {
        if (props.onRename) {
            fileCommandBarItems.push({
                key: "Rename",
                text: localization.Documents.Rename,
                iconProps: { iconName: "Rename" },
                buttonStyles: commandBarButtonStyles,
                onClick: () => {
                    setRenameItemName(fileName);
                },
            });
        }

        if (props.onCopy) {
            fileCommandBarItems.push({
                key: "Copy",
                text: localization.Documents.Copy,
                iconProps: { iconName: "Copy" },
                buttonStyles: commandBarButtonStyles,
                onClick: () => {
                    setIsCopy(true);
                },
            });
        }

        if (props.onMove) {
            fileCommandBarItems.push({
                key: "Move",
                text: localization.Documents.Move,
                iconProps: { iconName: "MoveToFolder" },
                buttonStyles: commandBarButtonStyles,
                onClick: () => {
                    setIsMove(true);
                },
            });
        }

        if (props.onDelete) {
            fileCommandBarItems.push({
                key: "Delete",
                text: localization.Documents.Delete,
                iconProps: { iconName: "Delete" },
                buttonStyles: commandBarButtonStyles,
                onClick: () => {
                    setIsDeleteConfirmation(true);
                },
            });
        }

        if (props.onDownload) {
            fileCommandBarItems.push({
                key: "Download",
                text: localization.Documents.Download,
                iconProps: { iconName: "Download" },
                buttonStyles: commandBarButtonStyles,
                onClick: () => {
                    const runner = async () => {
                        if (!props.onDownload) {
                            return;
                        }

                        const rawContent = await props.onDownload([...directoryPath, fileName]);

                        const a = document.createElement("a");
                        a.href = `data:application/octet-stream;base64,${rawContent}`;
                        a.download = fileName;
                        a.click();
                    };

                    runner();
                },
            });
        }
    }

    return (
        <Stack styles={{ root: { height: "100%" } }}>
            <CommandBar
                items={commandBarItems}
                styles={{
                    root: {
                        padding: 0,
                    },
                    primarySet: {
                        alignItems: "center",
                    },
                    secondarySet: {
                        alignItems: "center",
                    },
                }}
            />

            <Breadcrumb
                items={breadcrumbItems}
                styles={{
                    root: { margin: 0, paddingLeft: theme.spacing.s1, paddingRight: theme.spacing.s1 },
                    itemLink: { borderRadius: theme.effects.roundedCorner2 },
                }}
            />

            {items === null ? (
                <MessageBar
                    messageBarType={MessageBarType.severeWarning}
                    styles={{ root: { margin: theme.spacing.s1, width: "auto" } }}
                >
                    {localization.NotFound}
                </MessageBar>
            ) : (
                <Stack horizontal styles={{ root: { flex: 1 } }}>
                    <ScrollablePane styles={{ root: { position: "relative", flex: 1 } }}>
                        <MarqueeSelection selection={selection as Selection<IObjectWithKey>}>
                            <ShimmeredDetailsList
                                shimmerLines={3}
                                enableShimmer={items === undefined}
                                selection={selection as Selection<IObjectWithKey>}
                                onRenderRow={(renderProps, defaultRender) => {
                                    return (
                                        <DetailsRow
                                            {...renderProps!}
                                            styles={{
                                                ...renderProps!.styles,
                                                cell: {
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    display: "flex",
                                                    alignItems: "center",
                                                },
                                                root: {
                                                    ":not(:hover) .document-more": { display: "none" },
                                                },
                                            }}
                                        />
                                    );
                                }}
                                columns={[
                                    {
                                        key: "fileType",
                                        name: "",
                                        minWidth: 16,
                                        maxWidth: 16,
                                        iconName: "Page",
                                        isIconOnly: true,
                                        onRender: (item: IFileExplorerListItem) => {
                                            return (
                                                <Icon
                                                    {...getFileTypeIconProps({
                                                        type: item.type === "directory" ? 2 : undefined,
                                                        extension:
                                                            item.type === "directory" || item.name.split(".").length < 2
                                                                ? undefined
                                                                : item.name.split(".").reverse()[0],
                                                        size: 16,
                                                    })}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        key: "name",
                                        name: localization.Documents.NameColumn,
                                        minWidth: 200,
                                        isResizable: true,
                                        isSorted: sort === "name",
                                        isSortedDescending: isSortDescending,
                                        onColumnClick: onColumnClicked("name"),
                                        onRender: (item: IFileExplorerListItem) => {
                                            return (
                                                <Stack
                                                    horizontal
                                                    verticalAlign="center"
                                                    styles={{
                                                        root: {
                                                            flex: 1,
                                                        },
                                                    }}
                                                >
                                                    <Link
                                                        onClick={() => {
                                                            if (item.type === "directory") {
                                                                const newDirectoryPath = [...directoryPath, item.name];
                                                                setCompleteLocation({
                                                                    directoryPath: newDirectoryPath,
                                                                    fileName: fileName,
                                                                });
                                                                return;
                                                            }

                                                            setCompleteLocation({
                                                                directoryPath: directoryPath,
                                                                fileName: item.name,
                                                            });
                                                        }}
                                                        styles={{
                                                            root: {
                                                                "fontSize": 13,
                                                                "color": theme.palette.neutralPrimary,
                                                                "textDecoration": "underline",
                                                                ":hover": {
                                                                    color: theme.palette.themePrimary,
                                                                },
                                                                "flex": 1,
                                                            },
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                </Stack>
                                            );
                                        },
                                    },
                                    {
                                        key: "timeAdded",
                                        name: localization.Documents.TimeAddedColumn,
                                        minWidth: 160,
                                        maxWidth: 160,
                                        isSorted: sort === "timeAdded",
                                        isSortedDescending: isSortDescending,
                                        onColumnClick: onColumnClicked("timeAdded"),
                                        onRender: (item: IFileExplorerListItem) => {
                                            return (
                                                <Text
                                                    styles={{
                                                        root: {
                                                            fontSize: 13,
                                                            color: theme.palette.neutralPrimary,
                                                        },
                                                    }}
                                                >
                                                    {item.timeAdded?.toLocaleString("hr")}
                                                </Text>
                                            );
                                        },
                                    },
                                    {
                                        key: "addedBy",
                                        name: localization.Documents.AddedByColumn,
                                        minWidth: 120,
                                        isResizable: true,
                                        isSorted: sort === "addedBy",
                                        isSortedDescending: isSortDescending,
                                        onColumnClick: onColumnClicked("addedBy"),
                                        onRender: (item: IFileExplorerListItem) => {
                                            return (
                                                <Text
                                                    styles={{
                                                        root: {
                                                            fontSize: 13,
                                                            color: theme.palette.neutralPrimary,
                                                        },
                                                    }}
                                                >
                                                    {item.addedBy}
                                                </Text>
                                            );
                                        },
                                    },
                                    {
                                        key: "fileSize",
                                        name: localization.Documents.SizeColumn,
                                        minWidth: 80,
                                        maxWidth: 80,
                                        isSorted: sort === "fileSize",
                                        isSortedDescending: isSortDescending,
                                        onColumnClick: onColumnClicked("fileSize"),
                                        onRender: (item: IFileExplorerListItem) => {
                                            return item.fileSize ? (
                                                <Text
                                                    styles={{
                                                        root: {
                                                            fontSize: 13,
                                                            color: theme.palette.neutralPrimary,
                                                        },
                                                    }}
                                                >
                                                    {getFileSizeString(item.fileSize)}
                                                </Text>
                                            ) : null;
                                        },
                                    },
                                ]}
                                items={items ?? []}
                                selectionMode={SelectionMode.multiple}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                constrainMode={ConstrainMode.unconstrained}
                                onItemInvoked={(item: IFileExplorerListItem) => {
                                    if (item.type === "directory") {
                                        const newPath = [...directoryPath, item.name];
                                        setCompleteLocation({ directoryPath: newPath, fileName: fileName });
                                        return;
                                    }

                                    setCompleteLocation({ directoryPath: directoryPath, fileName: item.name });
                                }}
                                onRenderDetailsHeader={(renderProps, defaultRender) => {
                                    return (
                                        <Sticky stickyPosition={StickyPositionType.Header}>
                                            {defaultRender!(renderProps)}
                                        </Sticky>
                                    );
                                }}
                            />
                        </MarqueeSelection>
                    </ScrollablePane>
                </Stack>
            )}

            {isUpload && (
                <FileUploadModal
                    onDismiss={() => {
                        setIsUpload(false);
                    }}
                    acceptFiles
                    acceptDirectories
                    allowMultiple
                    onUpload={async (content) => {
                        if (!props.onUploadContent) {
                            return;
                        }

                        await props.onUploadContent(directoryPath, content);
                        setIsUpload(false);

                        refreshContent();
                    }}
                    existingNames={
                        items?.map((x) => {
                            return x.name;
                        }) ?? []
                    }
                />
            )}

            <Dialog
                hidden={!isNewFolder}
                dialogContentProps={{ title: localization.Documents.NewFolder }}
                onDismiss={() => {
                    setIsNewFolder(false);
                }}
            >
                {isNewFolder && (
                    <NewFolder
                        onDismiss={() => {
                            setIsNewFolder(false);
                        }}
                        onSave={async (name: string) => {
                            if (!props.onCreateNewDirectory) {
                                return;
                            }

                            await props.onCreateNewDirectory([...directoryPath, name]);
                            setIsNewFolder(false);

                            refreshContent();
                        }}
                        existingNames={
                            items?.map((x) => {
                                return x.name;
                            }) ?? []
                        }
                    />
                )}
            </Dialog>

            <Dialog
                hidden={!renameItemName}
                dialogContentProps={{ title: localization.Documents.Rename }}
                onDismiss={() => {
                    setRenameItemName(undefined);
                }}
            >
                {renameItemName && (
                    <Rename
                        onDismiss={() => {
                            setRenameItemName(undefined);
                        }}
                        onSave={async (name: string) => {
                            if (!props.onRename) {
                                return;
                            }

                            await props.onRename([...directoryPath, fileName ?? selectedItems[0].name], name);
                            setRenameItemName(undefined);
                            await refreshContent();

                            if (fileName) {
                                setCompleteLocation({ directoryPath: directoryPath, fileName: name });
                            }
                        }}
                        existingNames={
                            items?.map((x) => {
                                return x.name;
                            }) ?? []
                        }
                        currentName={fileName ?? selectedItems[0]?.name ?? ""}
                    />
                )}
            </Dialog>

            <Dialog
                hidden={!isCopy}
                dialogContentProps={{ title: localization.Documents.Copy }}
                onDismiss={() => {
                    setIsCopy(false);
                }}
            >
                {isCopy && (
                    <LocationSelector
                        onDismiss={() => {
                            setIsCopy(false);
                        }}
                        onSelected={async (newLibraryId, newLocation) => {
                            if (!props.onCopy) {
                                return;
                            }

                            await props.onCopy(
                                directoryPath,
                                fileName
                                    ? [fileName]
                                    : selectedItems.map((x) => {
                                          return x.name;
                                      }),
                                newLibraryId,
                                newLocation,
                            );
                            setIsCopy(false);
                            await refreshContent();

                            if (fileName) {
                                setCompleteLocation({ directoryPath: directoryPath, fileName: null });
                            }
                        }}
                    />
                )}
            </Dialog>

            <Dialog
                hidden={!isMove}
                dialogContentProps={{ title: localization.Documents.Move }}
                onDismiss={() => {
                    setIsMove(false);
                }}
            >
                {isMove && (
                    <LocationSelector
                        onDismiss={() => {
                            setIsMove(false);
                        }}
                        onSelected={async (newLibraryId, newLocation) => {
                            if (!props.onMove) {
                                return;
                            }

                            await props.onMove(
                                directoryPath,
                                fileName
                                    ? [fileName]
                                    : selectedItems.map((x) => {
                                          return x.name;
                                      }),
                                newLibraryId,
                                newLocation,
                            );
                            setIsMove(false);
                            await refreshContent();

                            if (fileName) {
                                setCompleteLocation({ directoryPath: directoryPath, fileName: null });
                            }
                        }}
                    />
                )}
            </Dialog>

            <Dialog
                hidden={!isDeleteConfirmation}
                dialogContentProps={{
                    title: localization.Documents.Delete,
                    subText: localization.Documents.DeleteConfirmation,
                }}
                onDismiss={() => {
                    setIsDeleteConfirmation(false);
                }}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={async () => {
                            if (!props.onDelete) {
                                return;
                            }

                            await props.onDelete([...directoryPath, fileName ?? selectedItems[0].name]);
                            setIsDeleteConfirmation(false);
                            await refreshContent();

                            if (fileName) {
                                setCompleteLocation({ directoryPath: directoryPath, fileName: null });
                            }
                        }}
                    >
                        {localization.Documents.Delete}
                    </PrimaryButton>

                    <DefaultButton
                        onClick={() => {
                            setIsDeleteConfirmation(false);
                        }}
                    >
                        {localization.Cancel}
                    </DefaultButton>
                </DialogFooter>
            </Dialog>

            <Modal
                isOpen={!!fileName}
                styles={{
                    main: { height: "100%", width: "100%" },
                    scrollableContent: { height: "100%" },
                }}
            >
                {fileName && (
                    <>
                        <Stack styles={{ root: { height: "100%" } }}>
                            <Stack
                                horizontal
                                verticalAlign="center"
                                styles={{
                                    root: {
                                        borderBottomWidth: 1,
                                        borderBottomColor: theme.palette.neutralLighter,
                                        borderBottomStyle: "solid",
                                    },
                                }}
                            >
                                <CommandBar
                                    items={fileCommandBarItems}
                                    style={{ flex: 1 }}
                                    styles={{
                                        root: {
                                            padding: 0,
                                        },
                                        primarySet: {
                                            alignItems: "center",
                                        },
                                        secondarySet: {
                                            alignItems: "center",
                                        },
                                    }}
                                />

                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: theme.spacing.s2 }}>
                                    <Icon
                                        {...getFileTypeIconProps({
                                            extension:
                                                (fileName!.split(".").length ?? 0) < 2
                                                    ? undefined
                                                    : fileName!.split(".").reverse()[0],
                                        })}
                                    />
                                    <Text>{fileName}</Text>
                                </Stack>

                                <Stack
                                    horizontal
                                    horizontalAlign="end"
                                    styles={{ root: { flex: 1, paddingRight: theme.spacing.s1 } }}
                                >
                                    {directoryContent ? (
                                        <Stack
                                            horizontal
                                            verticalAlign="center"
                                            tokens={{ childrenGap: theme.spacing.s1 }}
                                        >
                                            <IconButton
                                                iconProps={{ iconName: "Previous" }}
                                                disabled={
                                                    items!
                                                        .filter((x) => {
                                                            return x.type === "file";
                                                        })
                                                        .map((x) => {
                                                            return x.name;
                                                        })
                                                        .indexOf(fileName!) === 0
                                                }
                                                onClick={() => {
                                                    const fileItems = items!.filter((x) => {
                                                        return x.type === "file";
                                                    });

                                                    const currentIndex = fileItems
                                                        .map((x) => {
                                                            return x.name;
                                                        })
                                                        .indexOf(fileName!);

                                                    const newFileName = fileItems[currentIndex - 1].name;
                                                    setCompleteLocation({
                                                        directoryPath: directoryPath,
                                                        fileName: newFileName,
                                                    });
                                                }}
                                            />

                                            <Text>
                                                {items!
                                                    .filter((x) => {
                                                        return x.type === "file";
                                                    })
                                                    .map((x) => {
                                                        return x.name;
                                                    })
                                                    .indexOf(fileName!) + 1}{" "}
                                                / {directoryContent.Files.length}
                                            </Text>

                                            <IconButton
                                                iconProps={{ iconName: "Next" }}
                                                disabled={
                                                    items!
                                                        .filter((x) => {
                                                            return x.type === "file";
                                                        })
                                                        .map((x) => {
                                                            return x.name;
                                                        })
                                                        .indexOf(fileName!) ===
                                                    items!.filter((x) => {
                                                        return x.type === "file";
                                                    }).length -
                                                        1
                                                }
                                                onClick={() => {
                                                    const fileItems = items!.filter((x) => {
                                                        return x.type === "file";
                                                    });

                                                    const currentIndex = fileItems
                                                        .map((x) => {
                                                            return x.name;
                                                        })
                                                        .indexOf(fileName!);

                                                    const newFileName = fileItems[currentIndex + 1].name;
                                                    setCompleteLocation({
                                                        directoryPath: directoryPath,
                                                        fileName: newFileName,
                                                    });
                                                }}
                                            />
                                        </Stack>
                                    ) : (
                                        <Spinner />
                                    )}

                                    <Separator vertical />

                                    <IconButton
                                        iconProps={{ iconName: "Cancel" }}
                                        onClick={() => {
                                            setCompleteLocation({
                                                directoryPath: directoryPath,
                                                fileName: null,
                                            });
                                        }}
                                    />
                                </Stack>
                            </Stack>

                            <Stack
                                verticalAlign="center"
                                styles={{
                                    root: {
                                        flex: 1,
                                        overflow: "hidden",
                                    },
                                }}
                            >
                                <FilePreviewer
                                    fileName={fileName!}
                                    onLoad={async () => {
                                        return await props.onGetFilePreview(directoryPath, fileName!);
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </>
                )}
            </Modal>
        </Stack>
    );
};

export default FileExplorer;

const getFileSizeString = (size: number) => {
    if (size > 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
    if (size > 1024) {
        return `${(size / 1024).toFixed(2)} KB`;
    }
    return `${size} B`;
};
