import {
    ApiResult,
    Form,
    FormValue,
    IFormProps,
    isObject,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    Spinner,
    useDebounce,
    useEntityDataTableContext,
    UseForm,
    useInCoreLocalization,
    useOnChange,
    usePermissionService,
    useTheme,
} from "@in-core";
import React, { useCallback, useMemo, useState } from "react";
import { DeepPartial } from "..";
import { DoesItemExist } from "@in-core/api/Entity";

export enum DataEntryType {
    ManualKey,
    AutoKey,
}

export enum DataEntryMode {
    Create,
    Read,
    Update,
}

export interface IDataEntryFormProps<T extends object = any, TKey extends object = any>
    extends Omit<IFormProps, "form" | "children"> {
    type: DataEntryType;
    permission?: string;
    mode?: DataEntryMode;
    redirect?: (redirectPermissions: string[], key?: any) => void;
    selectedKey?: TKey;
    form: UseForm<T>;
    keyValues: {
        [x in keyof TKey]: FormValue<TKey[x]>;
    };
    keyMapping?: Partial<{
        [x in keyof TKey]: string;
    }>;
    createApi: (data: T) => Promise<ApiResult<T>>;
    readApi: (key: TKey) => Promise<ApiResult<T>>;
    updateApi: (data: T) => Promise<ApiResult<T>>;
    onCreate?: (data: T) => void;
    onUpdate?: (data: T) => void;
    children?: React.ReactNode;
    defaultFormData?: DeepPartial<T>;
    onAfterSubmit?: (newData?: T) => void;
}

const DataEntryForm = <T extends object = any, TKey extends object = any>(props: IDataEntryFormProps<T, TKey>) => {
    const theme = useTheme();
    const entityDataTableContext = useEntityDataTableContext();
    const permissionService = usePermissionService();
    const doesItemExistApi = DoesItemExist.useApi();
    const [isReading, setIsReading] = useState(true);
    const localization = useInCoreLocalization();

    const mode = useMemo(() => {
        return (
            props.mode ??
            (entityDataTableContext?.innerPermission?.Id === "Create"
                ? DataEntryMode.Create
                : entityDataTableContext?.innerPermission?.Id === "Read"
                ? DataEntryMode.Read
                : entityDataTableContext?.innerPermission?.Id === "Update"
                ? DataEntryMode.Update
                : undefined)
        );
    }, [props.mode, entityDataTableContext?.innerPermission?.Id]);

    const getKey = (data: any | undefined, shouldUseMapping: boolean) => {
        const key = data ?? props.selectedKey ?? entityDataTableContext!.selectedKeys[0];

        const numberOfKeyValues = Object.keys(props.keyValues).length;
        const keyObject: any = {};
        Object.keys(props.keyValues).forEach((x) => {
            keyObject[
                props.keyMapping && props.keyMapping[x as keyof TKey] !== undefined && shouldUseMapping
                    ? props.keyMapping[x as keyof TKey]
                    : x
            ] = key[x] ?? (numberOfKeyValues === 1 && !isObject(key) ? key : undefined);
        });

        return keyObject;
    };

    useDebounce(
        async () => {
            if (
                props.type === DataEntryType.AutoKey ||
                mode !== DataEntryMode.Create ||
                (!props.permission && !entityDataTableContext)
            ) {
                return;
            }

            const doesItemExistResponse = await doesItemExistApi.call({
                Permission: props.permission ?? entityDataTableContext!.permission.CompleteId,
                Key: getKey(props.form.$value, false),
            });

            (props.form as any).__ExistingId.$setErrors(
                doesItemExistResponse.data ? [localization.DataEntryForm.AlreadyExistsError] : undefined,
            );
        },
        500,
        Object.values(props.keyValues).map((x) => {
            return (x as any).$value;
        }),
    );

    useOnChange(async () => {
        if (mode === undefined) {
            return;
        }

        if (mode === DataEntryMode.Create) {
            props.form.$reset(props.defaultFormData);
            setIsReading(false);
            return;
        }

        if (entityDataTableContext === undefined && props.selectedKey === undefined) {
            throw new Error(
                "CrudForm: either `entityDataTableContext` should be available or `selectedKey` should be provided.",
            );
        }

        const key = getKey(undefined, false);

        setIsReading(true);
        const valuesResponse = (await props.readApi(key)).data!;
        setIsReading(false);

        props.form.$reset(valuesResponse as DeepPartial<T>);
    }, [mode, entityDataTableContext?.selectedKeys, props.selectedKey]);

    const handleSubmit = useCallback(
        async (data: T) => {
            if (mode === DataEntryMode.Create) {
                const newData = (await props.createApi(data)).data!;

                if (entityDataTableContext) {
                    let keys: any[] | undefined = undefined;

                    if (entityDataTableContext.innerPermission?.Id === "Create") {
                        const key = getKey(newData, true);
                        keys = [key];
                    }

                    entityDataTableContext.success(keys);
                    entityDataTableContext.refresh();
                }

                if (props.onCreate) {
                    props.onCreate(newData);
                    return;
                }

                const redirectFunc = props.redirect ?? entityDataTableContext?.redirect;
                if (redirectFunc) {
                    const key = getKey(newData, true);
                    redirectFunc(["Update", "Read"], [key]);
                }

                props.onAfterSubmit && props.onAfterSubmit(newData);

                return;
            }

            if (mode === DataEntryMode.Update) {
                const newData = (await props.updateApi(data)).data!;
                props.form.$reset(newData as DeepPartial<T>);

                if (entityDataTableContext) {
                    entityDataTableContext.success();
                    entityDataTableContext.refresh();
                }

                if (props.onUpdate) {
                    props.onUpdate(newData);
                    return;
                }

                props.onAfterSubmit && props.onAfterSubmit(newData);

                return;
            }
        },
        [mode, props.createApi, props.updateApi, props.onAfterSubmit],
    );

    if (entityDataTableContext === undefined && props.mode === undefined) {
        throw new Error("CrudForm: either `entityDataTableContext` should be available or `mode` should be provided.");
    }

    if (mode === undefined) {
        return null;
    }

    if (isReading) {
        return <Spinner label={localization.DataEntryForm.DataLoading} />;
    }

    const {
        form,
        onSubmit,
        children,
        keyValues,
        createApi,
        readApi,
        updateApi,
        onCreate,
        onUpdate,
        defaultFormData,
        ...formProps
    } = props;

    const isReadRedirectAllowed =
        (props.permission !== undefined || entityDataTableContext !== undefined) &&
        (props.redirect !== undefined || entityDataTableContext !== undefined) &&
        permissionService.isAuthorized(`${props.permission ?? entityDataTableContext?.permission.CompleteId}.Read`);

    const isUpdateRedirectAllowed =
        entityDataTableContext !== undefined &&
        (props.redirect !== undefined || entityDataTableContext !== undefined) &&
        permissionService.isAuthorized(`${props.permission ?? entityDataTableContext?.permission.CompleteId}.Update`);

    return (
        <Form form={props.form} onSubmit={props.form.$handleSubmit(handleSubmit)} {...formProps}>
            {mode === DataEntryMode.Update && props.form.$isSubmitted && !props.form.$isDirty && (
                <MessageBar messageBarType={MessageBarType.success} styles={{ root: { marginTop: theme.spacing.m } }}>
                    {localization.DataEntryForm.Success}
                </MessageBar>
            )}

            {mode === DataEntryMode.Create && !(props.form as any).__ExistingId.$isValid && (
                <MessageBar
                    messageBarType={MessageBarType.severeWarning}
                    actions={
                        isReadRedirectAllowed || isUpdateRedirectAllowed ? (
                            <div>
                                {isUpdateRedirectAllowed && (
                                    <MessageBarButton
                                        onClick={() => {
                                            const redirectFunc = props.redirect ?? entityDataTableContext?.redirect;
                                            if (!redirectFunc) {
                                                return;
                                            }

                                            redirectFunc(["Update"], getKey(form.$value, false));
                                        }}
                                    >
                                        {localization.DataEntryForm.AlreadyExistsUpdate}
                                    </MessageBarButton>
                                )}

                                {isReadRedirectAllowed && (
                                    <MessageBarButton
                                        onClick={() => {
                                            const redirectFunc = props.redirect ?? entityDataTableContext?.redirect;
                                            if (!redirectFunc) {
                                                return;
                                            }

                                            redirectFunc(["Read"], getKey(form.$value, false));
                                        }}
                                    >
                                        {localization.DataEntryForm.AlreadyExistsRead}
                                    </MessageBarButton>
                                )}
                            </div>
                        ) : undefined
                    }
                >
                    {localization.DataEntryForm.AlreadyExistsMessage}
                </MessageBar>
            )}

            {getDisabledChildren(
                props.children,
                mode === DataEntryMode.Read
                    ? undefined
                    : mode === DataEntryMode.Update ||
                      (mode === DataEntryMode.Create && props.type === DataEntryType.AutoKey)
                    ? (x) => {
                          return Object.keys(props.keyValues).includes(x?.props?.id);
                      }
                    : () => {
                          return false;
                      },
            )}
        </Form>
    );
};

const getDisabledChildren = (children: React.ReactNode | undefined, filter?: (child: any) => boolean): any => {
    if (!children) {
        return null;
    }

    return React.Children.map(children, (x) => {
        if (typeof x !== "object" || x === null || x === undefined) {
            return x;
        }

        return {
            ...x,
            props: {
                ...((x as any).props ?? {}),
                disabled: (x as any).props?.disabled ?? (filter !== undefined ? filter(x) : true),
                children: getDisabledChildren((x as any).props?.children, filter),
            },
        };
    });
};

export default DataEntryForm;
