import { DataTablePanelSelect, EntityDataTable, IDataTablePanelSelectProps, IEntityDataTableProps } from "@in-core";

export interface IEntityDataTablePanelSelectProps
    extends Omit<IDataTablePanelSelectProps, "renderDataTable" | "columns">,
        Omit<IEntityDataTableProps, "onChange" | "selectedKey" | "selectedKeys"> {
    defaultSearchValue?: string;
    onItemsSelected?: (keys: any[]) => void;
}

const EntityDataTablePanelSelect = (props: IEntityDataTablePanelSelectProps) => {
    return (
        <DataTablePanelSelect
            {...props}
            columns={props.columns ?? []}
            renderDataTable={(dataTableProps) => {
                return (
                    <EntityDataTable
                        {...dataTableProps}
                        columns={undefined}
                        entity={props.entity}
                        onItemSelected={(itemKey: any) => {
                            props.onItemsSelected && props.onItemsSelected([itemKey]);
                        }}
                    />
                );
            }}
        />
    );
};

export default EntityDataTablePanelSelect;
