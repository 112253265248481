import {
    DefaultButton,
    Filter,
    IDataTableColumn,
    Icon,
    List,
    PrimaryButton,
    Stack,
    Text,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import { useState } from "react";
import DefaultFilter from "../DefaultFilter";

export interface IFilterFormProps<TData = any> {
    columns: IDataTableColumn<TData>[];
    initialFilters: Record<string, Filter>;
    onFiltersChanged: (newFilters: Record<string, Filter>) => void;
    onDismiss: () => void;
}

const FilterForm = <TData extends object = any>(props: IFilterFormProps<TData>) => {
    const [filters, setFilters] = useState<Record<string, Filter>>(() => {
        const selectorsClone: Record<string, Filter> = {};
        Object.keys(props.initialFilters).forEach((x) => {
            selectorsClone[x] = props.initialFilters[x];
        });
        return selectorsClone;
    });

    const theme = useTheme();
    const localization = useInCoreLocalization();

    const renderFilterComponent = (column: IDataTableColumn<TData>) => {
        if (column.onRenderFilter) {
            return (
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <div style={{ flex: 1, maxWidth: "100%" }}>
                        {column.onRenderFilter(filters[column.id], (newFilterItem) => {
                            const newFilters = {
                                ...filters,
                                [column.id]: newFilterItem,
                            } as Record<string, Filter>;

                            setFilters(newFilters);
                        })}
                    </div>
                </Stack>
            );
        }

        const columnFilter = filters[column.id as any];

        return (
            <DefaultFilter
                id={column.id}
                dataType={column.dataType!}
                filter={columnFilter}
                onFilterChanged={(newFilter) => {
                    const newFilters: Record<string, Filter> = {
                        ...filters,
                    };
                    if (newFilter === undefined) {
                        delete newFilters[column.id];
                    } else {
                        newFilters[column.id] = newFilter;
                    }
                    setFilters(newFilters);
                }}
                isChangeInstant
            />
        );
    };

    return (
        <Stack>
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    props.onFiltersChanged(filters);
                    props.onDismiss();
                }}
            >
                <List
                    items={props.columns.filter((x) => {
                        return x.isFilterable !== false && (x.dataType !== undefined || x.onRenderFilter !== undefined);
                    })}
                    onRenderCell={(column) => {
                        if (!column) {
                            return null;
                        }

                        return (
                            <Stack
                                styles={{
                                    root: {
                                        padding: theme.spacing.s1,
                                        borderBottomColor: theme.palette.neutralLight,
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: 1,
                                    },
                                }}
                                tokens={{ childrenGap: theme.spacing.s2 }}
                            >
                                <Text styles={{ root: { fontWeight: 600, display: "flex", alignItems: "center" } }}>
                                    {column.icon && (
                                        <Icon
                                            iconName={column.icon}
                                            styles={{ root: { marginRight: theme.spacing.s2 } }}
                                        />
                                    )}

                                    {column!.name}
                                </Text>

                                {renderFilterComponent(column)}
                            </Stack>
                        );
                    }}
                />

                <Stack
                    horizontal
                    styles={{
                        root: {
                            position: "sticky",
                            bottom: 0,
                            backgroundColor: theme.palette.white,
                            zIndex: 1,
                            padding: theme.spacing.s1,
                            borderTopWidth: 1,
                            borderTopStyle: "solid",
                            borderTopColor: theme.palette.neutralLighter,
                        },
                    }}
                    tokens={{ childrenGap: theme.spacing.s1 }}
                >
                    <PrimaryButton type="submit">{localization.Accept}</PrimaryButton>

                    <DefaultButton onClick={props.onDismiss}>{localization.Cancel}</DefaultButton>
                </Stack>
            </form>
        </Stack>
    );
};

export default FilterForm;
