import { useState } from "react";
import { languageTag, Translator } from "typed-intl";
import useOnChange from "./useOnChange";
import { useInCoreContext } from "@in-core/in-core-app/Shell";

const useLocalization = <T extends object>(translator: Translator<T>, locale?: string) => {
    const { userInfo } = useInCoreContext();
    const [userLocale, setUserLocale] = useState<string | undefined>(userInfo.Culture);
    const [messages, setMessages] = useState<T>(
        locale ?? userLocale ? translator.messagesFor(languageTag(locale ?? userLocale!)) : translator.messages(),
    );

    useOnChange(() => {
        setMessages(
            locale ?? userLocale ? translator.messagesFor(languageTag(locale ?? userLocale!)) : translator.messages(),
        );
    }, [locale, userLocale]);

    return messages;
};

export default useLocalization;
