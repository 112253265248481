import { GroupedList, Icon, IGroup, IRoutableItem, List, Stack, Text, useTheme } from "@in-core";
import { NavLink } from "react-router-dom";

export interface IListViewProps {
    routableItems: IRoutableItem[];
    search?: string;
    iconSize: number;
    onItemSelected?: () => void;
}

const ListView = (props: IListViewProps) => {
    const theme = useTheme();

    const groups: IGroup[] = [];

    const renderCell = (item: IRoutableItem) => {
        return (
            <NavLink
                to={item.url}
                style={({ isActive }) => {
                    return {
                        color: "black",
                        textDecoration: "none",
                        borderLeftWidth: 2,
                        borderLeftStyle: "solid",
                        display: "block",
                        borderLeftColor: isActive ? theme.palette.themePrimary : "transparent",
                        backgroundColor: isActive ? theme.palette.neutralLight : undefined,
                    };
                }}
                title={`${item.name ?? ""} (${item.id})`}
                onClick={props.onItemSelected}
            >
                <Stack
                    horizontal
                    verticalAlign="center"
                    tokens={{
                        padding: 10,
                        childrenGap: 10,
                    }}
                    styles={{
                        root: {
                            "height": 44,
                            "&:hover": {
                                backgroundColor: theme.palette.neutralLighter,
                            },
                        },
                    }}
                >
                    <Icon
                        iconName={item.icon ?? ""}
                        styles={{
                            root: {
                                color: theme.palette.themePrimary,
                                fontSize: props.iconSize,
                                lineHeight: props.iconSize,
                                width: props.iconSize,
                                height: props.iconSize,
                            },
                        }}
                    />

                    <Text block nowrap>
                        {item?.name}
                    </Text>
                </Stack>
            </NavLink>
        );
    };

    if (!!props.search?.trim()) {
        const searchTerm = props.search.trim().toLowerCase();
        const searchItems = props.routableItems.filter((x) => {
            return x.name.toLowerCase().includes(searchTerm) || x.id.toLowerCase().includes(searchTerm);
        });

        return (
            <List
                items={searchItems}
                onRenderCell={(item?: IRoutableItem) => {
                    return renderCell(item!);
                }}
            />
        );
    }

    const alphabeticalGroups: Record<string, IRoutableItem[]> = {};
    const regex = /^[a-zA-Z0-9ČčĆćĐđŠšŽž]*$/;

    const markedWorkProcedures = props.routableItems
        .map((x) => {
            return {
                mark: regex.test(x.name[0]) ? x.name[0].toUpperCase() : "#",
                workProcedure: x,
            };
        })
        .sort((a, b) => {
            const markComparison = a.mark.localeCompare(b.mark);
            if (markComparison !== 0) {
                return markComparison;
            }
            return a.workProcedure.name.localeCompare(b.workProcedure.name);
        });

    markedWorkProcedures.forEach((x) => {
        if (!(x.mark in alphabeticalGroups)) {
            alphabeticalGroups[x.mark] = [];
        }
        alphabeticalGroups[x.mark].push(x.workProcedure);
    });

    let index = 0;
    Object.entries(alphabeticalGroups).forEach(([mark, groupWorkProcedures]) => {
        groups.push({
            key: mark,
            name: mark,
            startIndex: index,
            count: groupWorkProcedures.length,
        });
        index += groupWorkProcedures.length;
    });

    return (
        <GroupedList
            items={markedWorkProcedures.map((x) => {
                return x.workProcedure;
            })}
            groups={groups}
            onRenderCell={(_, item: IRoutableItem) => {
                return renderCell(item);
            }}
            groupProps={{
                onRenderHeader: (props) => {
                    return (
                        <Text
                            styles={{
                                root: {
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 6,
                                    paddingBottom: 6,
                                    backgroundColor: theme.palette.neutralLighterAlt,
                                    display: "block",
                                },
                            }}
                            variant="large"
                        >
                            {props?.group?.name}
                        </Text>
                    );
                },
                onRenderFooter: () => {
                    return <div style={{ height: 15 }} />;
                },
            }}
        />
    );
};

export default ListView;
