import { Group, IDataTableColumn, Selector, Stack, useTheme } from "@in-core";
import GroupDropdown from "./GroupDropdown";

export interface IGroupSectionProps<TData = any> {
    columns: IDataTableColumn<TData>[];
    group: Group | undefined;
    onGroupChanged: (newGroup: Group | undefined) => void;
    selectors: Record<string, Selector>;
}

const GroupSection = (props: IGroupSectionProps) => {
    const theme = useTheme();

    return (
        <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
            <GroupDropdown
                columns={props.columns.filter((x) => {
                    return x.isGroupable !== false && Object.keys(props.selectors).includes(x.id);
                })}
                group={props.group}
                onGroupChanged={props.onGroupChanged}
            />
        </Stack>
    );
};

export default GroupSection;
