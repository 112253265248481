import {
    DataTable,
    DefaultButton,
    Dialog,
    DialogFooter,
    Dropdown,
    Form,
    FormDatePicker,
    FormTextField,
    IconButton,
    MessageBar,
    MessageBarType,
    Panel,
    PrimaryButton,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    TooltipHost,
    useEntityDataTableContext,
    useForm,
    useOnMount,
    usePanelContext,
    useTheme,
} from "@in-core";
import { DeleteKey, GenerateSecret, GetKeys, ValidateGenerateSecret } from "@in-core/api/ConnectedApps";
import { DataType } from "@in-core/api/Entity";
import { useRef, useState } from "react";

const getToday = () => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    return today;
};

const addMonths = (date: Date, months: number) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
};

const ManageKeys = () => {
    const theme = useTheme();
    const entityDataTableContext = useEntityDataTableContext()!;
    const panelContext = usePanelContext()!;
    const getKeysApi = GetKeys.useApi();
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [isSpecificExpiration, setIsSpecificExpiration] = useState(false);
    const [addedSecret, setSecret] = useState<string>();
    const [isCopied, setIsCopied] = useState(false);
    const [deletingId, setDeletingId] = useState<string>();
    const deleteKeyApi = DeleteKey.useApi();

    const appId = entityDataTableContext.selectedKeys[0] as string;

    const defaultValidFromRef = useRef(getToday());
    const defaultValidUntlRef = useRef(addMonths(getToday(), 6));

    const form = useForm<GenerateSecret.Request>({
        defaultValues: {
            AppId: appId,
            ValidFrom: defaultValidFromRef.current,
            ValidUntil: defaultValidUntlRef.current,
        },
        validate: async (values) => {
            var response = await ValidateGenerateSecret.callApi({
                ...values,
            } as any);
            return response.data!;
        },
    });

    useOnMount(() => {
        panelContext.setProps({
            styles: {
                scrollableContent: { height: "100%", display: "flex", flexDirection: "column" },
                content: { flex: 1 },
            },
        });

        getKeysApi.call({ AppId: appId });
    });

    const handleSubmit = async (request: GenerateSecret.Request) => {
        const generateKeyResponse = await GenerateSecret.callApi(request);
        setSecret(generateKeyResponse.data!);
        getKeysApi.call({ AppId: appId });
    };

    return (
        <Stack styles={{ root: { height: "100%" } }} tokens={{ childrenGap: theme.spacing.m }}>
            <Stack horizontal styles={{ root: { marginTop: theme.spacing.m } }}>
                <PrimaryButton
                    iconProps={{ iconName: "Add" }}
                    onClick={() => {
                        setIsAddOpen(true);
                    }}
                >
                    Dodaj novi ključ
                </PrimaryButton>
            </Stack>

            {getKeysApi.isIdle || getKeysApi.isLoading ? (
                <Spinner />
            ) : (
                <DataTable
                    data={getKeysApi.data!}
                    columns={[
                        {
                            id: "Actions",
                            name: "",
                            isSortable: false,
                            isFilterable: false,
                            isResizable: false,
                            onRender: (item) => {
                                return (
                                    <Stack horizontal>
                                        <TooltipHost content="Obriši">
                                            <IconButton
                                                iconProps={{ iconName: "Delete" }}
                                                styles={{ root: { height: 20, width: 20 } }}
                                                onClick={() => {
                                                    setDeletingId(item.Id);
                                                }}
                                            />
                                        </TooltipHost>
                                    </Stack>
                                );
                            },
                            width: 20,
                        },
                        { id: "Id", property: "Id", name: "ID", dataType: DataType.String, width: 240 },
                        { id: "Description", property: "Description", name: "Opis", dataType: DataType.String },
                        {
                            id: "ValidFrom",
                            property: "ValidFrom",
                            name: "Vrijedi od (uključujući)",
                            dataType: DataType.DateTime,
                            width: 220,
                        },
                        {
                            id: "ValidUntil",
                            property: "ValidUntil",
                            name: "Vrijedi do (ne uključujući)",
                            dataType: DataType.DateTime,
                            width: 220,
                        },
                    ]}
                    hideToolbar
                    disablePagination
                    disableAggregating
                    selectionMode={SelectionMode.none}
                />
            )}

            <Dialog
                hidden={deletingId === undefined}
                dialogContentProps={{
                    title: "Brisanje ključa",
                    subText: `Jeste li sigurni da želite obrisati ključ '${
                        getKeysApi.data?.find((x) => {
                            return x.Id === deletingId;
                        })?.Description
                    }'?`,
                }}
            >
                <DialogFooter>
                    {deleteKeyApi.isLoading && <Spinner size={SpinnerSize.small} />}

                    <PrimaryButton
                        onClick={async () => {
                            await deleteKeyApi.call({ Id: deletingId! });
                            setDeletingId(undefined);
                            getKeysApi.call({ AppId: appId });
                        }}
                        text="Obriši"
                        disabled={deleteKeyApi.isLoading}
                    />

                    <DefaultButton
                        onClick={() => {
                            setDeletingId(undefined);
                        }}
                        text="Odustani"
                        disabled={deleteKeyApi.isLoading}
                    />
                </DialogFooter>
            </Dialog>

            <Panel
                isOpen={isAddOpen}
                onDismiss={() => {
                    setIsAddOpen(false);
                    setIsSpecificExpiration(false);
                    setSecret(undefined);
                    form.$reset({
                        AppId: appId,
                        ValidFrom: defaultValidFromRef.current,
                        ValidUntil: defaultValidUntlRef.current,
                    });
                }}
                headerText="Novi ključ"
            >
                {addedSecret ? (
                    <Stack tokens={{ childrenGap: theme.spacing.m }}>
                        <MessageBar messageBarType={MessageBarType.severeWarning}>
                            <b>Važno!</b> Ovaj ključ spremite sada jer ga nakon ovoga više nije moguće vidjeti iz
                            sigurnosnih razloga.
                        </MessageBar>

                        <code
                            style={{
                                backgroundColor: theme.palette.neutralLighter,
                                padding: theme.spacing.s1,
                                borderRadius: theme.effects.roundedCorner2,
                            }}
                        >
                            {addedSecret}
                        </code>

                        <PrimaryButton
                            onClick={() => {
                                navigator.clipboard.writeText(addedSecret);

                                setIsCopied(true);
                                setTimeout(() => {
                                    setIsCopied(false);
                                }, 3000);
                            }}
                        >
                            Kopiraj
                        </PrimaryButton>

                        {isCopied && (
                            <MessageBar messageBarType={MessageBarType.success}>
                                Ključ uspješno kopiran u međuspremnik.
                            </MessageBar>
                        )}
                    </Stack>
                ) : (
                    <Form form={form} onSubmit={form.$handleSubmit(handleSubmit)}>
                        <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                            <FormTextField value={form.Description} label="Opis" />

                            <Dropdown
                                options={[
                                    { key: 6, text: "Preporučeno: 6 mjeseci" },
                                    { key: 3, text: "3 mjeseca" },
                                    { key: 12, text: "12 mjeseci" },
                                    { key: 18, text: "18 mjeseci" },
                                    { key: 24, text: "24 mjeseca" },
                                    { key: -1, text: "Točan datum" },
                                ]}
                                label="Trajanje"
                                hideClear
                                defaultSelectedKey={6}
                                onChange={(items) => {
                                    const selectedKey = items.find((x) => {
                                        return x.isSelected;
                                    })!.key;

                                    if (selectedKey === -1) {
                                        form.ValidFrom.$setValue(defaultValidFromRef.current);
                                        form.ValidUntil.$setValue(defaultValidUntlRef.current);
                                        setIsSpecificExpiration(true);
                                        return;
                                    }

                                    form.ValidFrom.$setValue(defaultValidFromRef.current);
                                    form.ValidUntil.$setValue(addMonths(getToday(), selectedKey as number));
                                    setIsSpecificExpiration(false);
                                }}
                            />

                            <FormDatePicker
                                value={form.ValidFrom}
                                label="Vrijedi od (uključujući)"
                                hideClear
                                disabled={!isSpecificExpiration}
                                minDate={getToday()}
                                onSelectDate={(date) => {
                                    if (
                                        form.ValidUntil.$value === undefined ||
                                        date === undefined ||
                                        form.ValidUntil.$value <= date
                                    ) {
                                        form.ValidUntil.$setValue(addMonths(date ?? getToday(), 6));
                                    }
                                }}
                            />

                            <FormDatePicker
                                value={form.ValidUntil}
                                label="Vrijedi do (ne uključujući)"
                                hideClear
                                disabled={!isSpecificExpiration}
                                minDate={form.ValidFrom.$value!}
                                maxDate={addMonths(form.ValidFrom.$value!, 24)}
                            />
                        </Stack>
                    </Form>
                )}
            </Panel>
        </Stack>
    );
};

export default ManageKeys;
