import React, { useMemo, useState } from "react";
import {
    Checkbox,
    CheckboxVisibility,
    ColumnActionsMode,
    CommandBarButton,
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    DetailsRow,
    GroupHeader,
    GroupedListV2_unstable as GroupedListV2,
    IButtonStyles,
    IColumn,
    Icon,
    IDetailsHeaderProps,
    IGroup,
    Label,
    MarqueeSelection,
    NumericField,
    Pagination as InCorePagination,
    PrimaryButton,
    ScrollablePane,
    SearchBox,
    Selection,
    SelectionMode,
    Separator,
    ShimmeredDetailsList,
    Spinner,
    SpinnerSize,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
    Theme,
    useTheme,
    Callout,
    DirectionalHint,
    generateUuid,
    ContextualMenu,
    IContextualMenuItem,
    useToggle,
    useInCoreLocalization,
    DetailsRowCheck,
    IconButton,
    GroupSpacer,
    Dropdown,
    useElementSize,
    Panel,
} from "@in-core";
import useOnChange from "@in-core/hooks/useOnChange";
import DefaultFilter from "./DefaultFilter";
import {
    AggregateData,
    AggregateDataItem,
    AggregateDataResult,
    AggregateDataType,
    Filter,
    FilterGroup,
    FilterGroupOperator,
    FilterValue,
    FilterValueOperand,
    FilterValueOperator,
    GetDataRequest,
    GetDataResponse,
    Group,
    GroupResult,
    Pagination,
    Search,
    Selector,
    Sort,
} from "@in-core/api";
import { DataType } from "@in-core/api/Entity";
import { SearchOptions, getKeyObject, getKeyString, search } from "@in-core/Utils";
import { useRef } from "react";
import SelectorForm from "./SelectorForm";
import FilterForm from "./FilterForm";
import GroupForm from "./GroupForm";
import SortForm from "./SortForm";
import { IInCoreLocalization } from "@in-core/in-core-app/Localization";
import Enumerable from "linq";

const defaultPageCount = 25;
const defaultPageIndex = 0;

export interface ISelectionChangeItem<TKey = any> {
    /**
     * Key of selection item that changed.
     * If there are multiple id properties, it will be an object with those properties.
     * If there is only one ID property, it will be the value of that property.
     * If there are no ID properties, it will be the whole object.
     */
    key: TKey;

    /**
     * Whether the item with the key is selected or deselected.
     */
    isSelected: boolean;
}

export interface IDataTableAllowedValue {
    key: any;
    textKey: string;
    text: any;
}

export interface IDataTableColumn<TData = any> {
    id: string;
    property?: keyof TData;
    name: string;
    icon?: string;
    dataType?: DataType;
    allowedValues?: IDataTableAllowedValue[];
    defaultSelector?: Selector;

    sortId?: string;
    groupId?: string;
    searchId?: string;

    width?: number;
    minWidth?: number;
    maxWidth?: number;

    isResizable?: boolean;
    isSortable?: boolean;
    isFilterable?: boolean;
    isSearchable?: boolean;
    isSelectable?: boolean;
    isGroupable?: boolean;
    isAggregatable?: boolean;

    getValue?: (item: TData) => any;
    onRender?: (item: TData, index: number) => React.ReactNode;

    onRenderFilter?: (filter: Filter | undefined, onChange: (newFilter?: Filter) => void) => React.ReactNode;
}

export interface IViewData {
    filters?: Record<string, Filter>;
    selectors?: Record<string, Selector>;
    group?: Group;
    sort?: Sort;
    paginationCount?: number;
    aggregateDataTypes?: Record<string, AggregateDataType[]>;
}

export interface IViewInfo {
    id: string | number;
    name: string;
    isDefault?: boolean;
    disableEdit?: boolean;
    disableUpdate?: boolean;
    disableDelete?: boolean;
    disableSetAsDefault?: boolean;
    viewData?: IViewData;
}

export interface IDataTableProps<TData = any> {
    columns: IDataTableColumn<TData>[];
    data?: TData[] | ((request: GetDataRequest) => Promise<GetDataResponse<TData>>);
    idProperty?: keyof TData;
    idProperties?: (keyof TData)[];
    descriptionProperty?: keyof TData;
    descriptionProperties?: (keyof TData)[];
    additionalFilter?: Filter;
    getDataSignal?: string;

    noDataText?: string;
    onAddNewItemClicked?: () => void;

    isCompact?: boolean;
    isStriped?: boolean;
    columnWidth?: number;
    columnMinWidth?: number;
    columnMaxWidth?: number;

    disableResizing?: boolean;
    disableSorting?: boolean;
    disableFiltering?: boolean;
    disableSearching?: boolean;
    disablePagination?: boolean;
    disableGrouping?: boolean;
    disableSelecting?: boolean;
    disableAggregating?: boolean;
    hideToolbar?: boolean;

    defaultSort?: Sort;
    sort?: Sort;
    onSortChanged?: (sort?: Sort) => void;

    defaultFilters?: Record<string, Filter>;
    filters?: Record<string, Filter>;
    onFiltersChanged?: (filters: Record<string, Filter>) => void;

    defaultPagination?: Pagination;
    pagination?: Pagination;
    onPaginationChanged?: (pagination?: Pagination) => void;

    defaultSearchValue?: string;
    searchValue?: string;
    onSearchValueChanged?: (searchValue?: string) => void;

    defaultGroup?: Group;
    group?: Group;
    onGroupChanged?: (group?: Group) => void;

    defaultSelectors?: Record<string, Selector>;
    selectors?: Record<string, Selector>;
    onSelectorsChanged?: (selectors: Record<string, Selector>) => void;

    defaultAggregateDataTypes?: Record<string, AggregateDataType[]>;
    aggregateDataTypes?: Record<string, AggregateDataType[]>;
    onAggregateDataTypesChanged?: (aggregateDataTypes: Record<string, AggregateDataType[]>) => void;

    checkboxVisibility?: CheckboxVisibility;
    selectionMode?: SelectionMode;
    isSelectionCheckbox?: boolean;

    selectedKey?: any | null;
    selectedKeys?: any[] | null;
    defaultSelectedKey?: any;
    defaultSelectedKeys?: any[];
    onSelectionChanged?: (items: ISelectionChangeItem[]) => void;

    views?: IViewInfo[];
    defaultViewId?: string | number;
    viewId?: string | number | null;
    onViewIdChanged?: (viewId: string | number | null) => void;
    onAddView?: (viewData?: IViewData) => void;
    onEditView?: (viewId: string | number) => void;
    onUpdateView?: (viewId: string | number, viewData?: IViewData) => void;
    onDeleteView?: (viewId: string | number) => void;
    onSetViewAsDefault?: (viewId: string | number | undefined) => void;
    emptyViewData?: IViewData;

    onItemInvoked?: (key: any, item: TData, index: number) => void;

    onItemContextMenu?: (key: any, item: TData, index: number) => IContextualMenuItem[] | undefined;
}

export const DefaultFiltersForDataType: Record<DataType, FilterValueOperator> = {
    String: FilterValueOperator.Contains,
    Number: FilterValueOperator.Equal,
    Boolean: FilterValueOperator.Equal,
    DateTime: FilterValueOperator.Equal,
    Enum: FilterValueOperator.Equal,
};

const DataTable = <TData extends object = any>(props: IDataTableProps<TData>) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();
    const [containerRef, containerSize] = useElementSize();

    const commandBarButtonStyles: IButtonStyles = {
        root: {
            borderRadius: theme.effects.roundedCorner2,
            height: 32,
            backgroundColor: theme.palette.neutralLight,
        },
        rootHovered: {
            backgroundColor: theme.palette.themeLighter,
        },
        rootPressed: {
            backgroundColor: theme.palette.themeLighter,
        },
        icon: {
            color: theme.palette.neutralPrimary,
        },
    };

    const [items, setItems] = useState<any[] | undefined>(() => {
        if (props.data === undefined || !Array.isArray(props.data)) {
            return undefined;
        }

        const fixedFilter: Filter | undefined =
            Object.keys(props.filters ?? {}).length > 0
                ? {
                      IsNegated: false,
                      Group: {
                          Operator: FilterGroupOperator.And,
                          Filters: Object.values(props.filters ?? {}).filter((x) => {
                              return x !== undefined;
                          }) as Filter[],
                      },
                  }
                : undefined;

        const defaultSelectors: Record<string, Selector> = {};

        props.columns.forEach((x) => {
            defaultSelectors[x.id] = { Id: x.id, Value: {} };
        });

        const fixedSelector: Selector | undefined =
            Object.keys(props.selectors ?? defaultSelectors).length > 0
                ? { Id: "", Group: { Items: Object.values(props.selectors ?? defaultSelectors) } }
                : undefined;

        const fixedAggregateData: AggregateData | undefined = props.aggregateDataTypes
            ? Object.keys(props.aggregateDataTypes).length === 0
                ? undefined
                : {
                      Items: Object.keys(props.aggregateDataTypes).map((x) => {
                          const result: AggregateDataItem = {
                              Id: x,
                              AggregateDataTypes: props.aggregateDataTypes![x],
                          };

                          return result;
                      }),
                  }
            : undefined;

        const fixedSort = getFixedSort(props.sort, props.columns);

        const fixedGroup = getFixedGroup(props.group, props.columns);

        const columns: IColumn[] = [];

        props.columns
            .filter((x) => {
                return fixedSelector === undefined ? true : Object.keys(fixedSelector).includes(x.id);
            })
            .forEach((column, i) => {
                columns.push({
                    key: column.id,
                    name: column.name,
                    fieldName: column.property?.toString() ?? undefined,
                    data: column,
                    minWidth: 0,
                });
            });

        return getInMemoryResult({
            initialItems: props.data,
            columns: columns,
            Sort: fixedSort,
            AdditionalFilter: props.additionalFilter,
            Search: props.searchValue ? { SearchTerm: props.searchValue, SearchProperties: [] } : undefined,
            Filter: fixedFilter,
            Pagination: props.pagination,
            Selector: fixedSelector,
            AggregateData: fixedAggregateData,
            Group: fixedGroup,
        }).Data;
    });
    const [itemsCount, setItemsCount] = useState<number>();
    const [aggregateDataResults, setAggregateDataResults] = useState<AggregateDataResult[]>();
    const [groupResults, setGroupResults] = useState<GroupResult[]>();
    const [sort, setSort] = useState(props.defaultSort);
    const [filters, setFilters] = useState<Record<string, Filter>>(
        props.disableFiltering ? {} : props.defaultFilters ?? {},
    );
    const [selectors, setSelectors] = useState<Record<string, Selector>>(() => {
        const defaultSelectors: Record<string, Selector> = {};

        props.columns.forEach((x) => {
            defaultSelectors[x.id] = { Id: x.id, Value: {} };
        });

        return props.disableSelecting ? defaultSelectors : props.defaultSelectors ?? defaultSelectors;
    });
    const [pagination, setPagination] = useState<Pagination | undefined>(
        props.disablePagination
            ? undefined
            : props.pagination ??
                  props.defaultPagination ?? { ItemsPerPage: defaultPageCount, PageIndex: defaultPageIndex },
    );
    const [paginationInputCount, setPaginationInputCount] = useState<number | undefined>(
        props.disablePagination
            ? undefined
            : props.pagination?.ItemsPerPage ?? props.defaultPagination?.ItemsPerPage ?? defaultPageCount,
    );
    const [paginationInputPage, setPaginationInputPage] = useState<number | undefined>(
        props.disablePagination
            ? undefined
            : (props.pagination?.PageIndex ?? props.defaultPagination?.PageIndex ?? defaultPageIndex) + 1,
    );
    const [search, setSearch] = useState<Search | undefined>(
        props.disableSearching || props.defaultSearchValue === undefined || props.defaultSearchValue.trim().length < 3
            ? undefined
            : {
                  SearchTerm: props.defaultSearchValue.trim(),
                  SearchProperties: props.columns
                      .filter((x) => {
                          return x.isSearchable !== false;
                      })
                      .map((x) => {
                          return x.id;
                      }),
              },
    );
    const [searchValue, setSearchValue] = useState<string | undefined>(
        props.disableSearching ? undefined : props.defaultSearchValue,
    );
    const [group, setGroup] = useState<Group | undefined>(props.disableGrouping ? undefined : props.defaultGroup);
    const [aggregateDataTypes, setAggregateDataTypes] = useState<Record<string, AggregateDataType[]>>(
        props.disableAggregating
            ? {}
            : props.defaultAggregateDataTypes
            ? cloneAggregateDataTypes(props.defaultAggregateDataTypes)
            : {},
    );
    const [collapsedGroups, setCollapsedGroups] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<any[]>(
        (props.selectionMode === SelectionMode.none
            ? []
            : props.selectionMode === SelectionMode.multiple
            ? props.selectedKeys === null
                ? []
                : props.selectedKeys
            : props.selectionMode === SelectionMode.single
            ? props.selectedKey === null
                ? []
                : props.selectedKey === undefined
                ? undefined
                : [props.selectedKey]
            : undefined) ?? [],
    );
    const [isSelectionChanged, setIsSelectionChanged] = useState(false);
    const selection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                setIsSelectionChanged(true);
            },
            getKey: (item: any) => {
                return getKeyString(
                    item,
                    props.idProperties !== undefined
                        ? props.idProperties
                        : props.idProperty !== undefined
                        ? [props.idProperty]
                        : [],
                );
            },
        });
    }, [props.idProperties, props.idProperty]);
    const filterButtonIdRef = useRef(`filter-button-${generateUuid()}`);
    const [isFilterCalloutVisible, toggleIsFilterCalloutVisible] = useToggle(false);
    const sortButtonIdRef = useRef(`sort-button-${generateUuid()}`);
    const [isSortCalloutVisible, toggleIsSortCalloutVisible] = useToggle(false);
    const selectorButtonIdRef = useRef(`selector-button-${generateUuid()}`);
    const [isSelectorCalloutVisible, toggleIsSelectorCalloutVisible] = useToggle(false);
    const groupButtonIdRef = useRef(`group-button-${generateUuid()}`);
    const [isGroupCalloutVisible, toggleIsGroupCalloutVisible] = useToggle(false);
    const [rightClickEvent, setRightClickEvent] = useState<MouseEvent>();
    const [contextMenuItems, setContextMenuItems] = useState<IContextualMenuItem[]>();
    const scrollablePaneIdRef = useRef(`scrollable-${generateUuid()}`);

    const [viewId, setViewId] = useState<string | number | null>(props.viewId ?? props.defaultViewId ?? null);

    const [isPaginationMorePanelVisible, toggleIsPaginationMorePanelVisible] = useToggle(false);

    const selectedView = props.views?.find((x) => {
        return x.id === (props.viewId ?? viewId);
    });

    useOnChange(() => {
        const viewData = selectedView?.viewData ?? props.emptyViewData;

        if (!viewData) {
            setViewId(null);
            props.onViewIdChanged && props.onViewIdChanged(null);
            return;
        }

        setFilters(viewData?.filters ?? props.emptyViewData?.filters ?? {});
        props.onFiltersChanged && props.onFiltersChanged(viewData?.filters ?? props.emptyViewData?.filters ?? {});

        const defaultSelectors: Record<string, Selector> = {};

        props.columns.forEach((x) => {
            defaultSelectors[x.id] = { Id: x.id, Value: {} };
        });

        setSelectors(viewData?.selectors ?? props.emptyViewData?.selectors ?? defaultSelectors);
        props.onSelectorsChanged &&
            props.onSelectorsChanged(viewData?.selectors ?? props.emptyViewData?.selectors ?? defaultSelectors);

        setGroup(viewData?.group);
        props.onGroupChanged && props.onGroupChanged(viewData?.group ?? props.emptyViewData?.group);

        setSort(viewData?.sort);
        props.onSortChanged && props.onSortChanged(viewData?.sort ?? props.emptyViewData?.sort);

        const itemsPerPage = viewData?.paginationCount ?? props.emptyViewData?.paginationCount ?? defaultPageCount;

        setPagination({ ItemsPerPage: itemsPerPage, PageIndex: defaultPageIndex });
        props.onPaginationChanged &&
            props.onPaginationChanged({ ItemsPerPage: itemsPerPage, PageIndex: defaultPageIndex });
        setPaginationInputCount(itemsPerPage);
        setPaginationInputPage(defaultPageIndex);

        setAggregateDataTypes(viewData?.aggregateDataTypes ?? {});
        props.onAggregateDataTypesChanged &&
            props.onAggregateDataTypesChanged(
                viewData?.aggregateDataTypes ?? props.emptyViewData?.aggregateDataTypes ?? {},
            );
    }, [props.viewId ?? viewId]);

    useOnChange(
        () => {
            if (searchValue === undefined || searchValue.trim().length < 3) {
                setSearch(undefined);
                return;
            }

            setSearch({
                SearchTerm: searchValue.trim(),
                SearchProperties: props.columns
                    .filter((x) => {
                        return Object.keys(props.selectors ?? selectors).includes(x.id) && x.isSearchable !== false;
                    })
                    .map((x) => {
                        return x.searchId ?? x.id;
                    }),
            });
        },
        [searchValue],
        {
            debounce: 500,
        },
    );

    useOnChange(() => {
        selection.setAllSelected(false);
    }, [props.pagination, pagination]);

    useOnChange(
        () => {
            const currentSelectedkeys = selectedKeys;
            setSelectedKeys([]);
            if (props.onSelectionChanged) {
                props.onSelectionChanged(
                    currentSelectedkeys.map((x) => {
                        return {
                            key: x,
                            isSelected: false,
                        };
                    }),
                );
            }
        },
        [props.filters, filters, search],
        { fireOnFirstSet: false },
    );

    useOnChange(async () => {
        if (props.data === undefined) {
            setItems(undefined);
            return;
        }

        const fixedFilter: Filter | undefined =
            Object.keys(props.filters ?? filters).length > 0
                ? {
                      IsNegated: false,
                      Group: {
                          Operator: FilterGroupOperator.And,
                          Filters: Object.values(props.filters ?? filters).filter((x) => {
                              return x !== undefined;
                          }) as Filter[],
                      },
                  }
                : undefined;

        const fixedSelector: Selector | undefined =
            Object.keys(props.selectors ?? selectors).length > 0
                ? { Id: "", Group: { Items: Object.values(props.selectors ?? selectors) } }
                : undefined;

        const fixedAggregateData: AggregateData | undefined =
            Object.keys(props.aggregateDataTypes ?? aggregateDataTypes).length === 0
                ? undefined
                : {
                      Items: Object.keys(props.aggregateDataTypes ?? aggregateDataTypes).map((x) => {
                          const result: AggregateDataItem = {
                              Id: x,
                              AggregateDataTypes: (props.aggregateDataTypes ?? aggregateDataTypes)[x],
                          };

                          return result;
                      }),
                  };

        const fixedSort = getFixedSort(props.sort ?? sort, props.columns);

        const fixedGroup = getFixedGroup(props.group ?? group, props.columns);

        setItems(undefined);
        setItemsCount(undefined);
        setAggregateDataResults(undefined);
        setGroupResults(undefined);

        const newItems = Array.isArray(props.data)
            ? getInMemoryResult({
                  initialItems: props.data,
                  columns: columns,
                  Sort: fixedSort,
                  AdditionalFilter: props.additionalFilter,
                  Search: search,
                  Filter: fixedFilter,
                  Pagination: pagination,
                  Selector: fixedSelector,
                  AggregateData: fixedAggregateData,
                  Group: fixedGroup,
              })
            : await props.data({
                  Sort: fixedSort,
                  AdditionalFilter: props.additionalFilter,
                  Search: search,
                  Filter: fixedFilter,
                  Pagination: pagination,
                  Selector: fixedSelector,
                  AggregateData: fixedAggregateData,
                  Group: fixedGroup,
              });

        setItems(newItems.Data);
        setItemsCount(newItems.Count);
        setAggregateDataResults(newItems.AggregateDataResults);
        setGroupResults(newItems.GroupResults);
    }, [
        props.data,
        props.additionalFilter,
        props.filters ?? filters,
        props.sort ?? sort,
        pagination,
        search,
        props.getDataSignal,
        props.selectors ?? selectors,
        props.group ?? group,
        props.aggregateDataTypes ?? aggregateDataTypes,
    ]);

    useOnChange(
        () => {
            if (!isSelectionChanged) {
                return;
            }

            const idPropertiesArray =
                props.idProperties !== undefined
                    ? props.idProperties
                    : props.idProperty !== undefined
                    ? [props.idProperty]
                    : [];

            const selectionItems = selection.getItems();
            const itemKeyStrings = selectionItems.map((x) => {
                return getKeyString(x, idPropertiesArray);
            });

            const currentSelectedKeys =
                (props.selectionMode === SelectionMode.none
                    ? []
                    : props.selectionMode === SelectionMode.multiple
                    ? props.selectedKeys
                    : props.selectionMode === SelectionMode.single && props.selectedKey !== undefined
                    ? [props.selectedKey]
                    : undefined) ?? selectedKeys;

            const currentSelection = selection.getSelection();

            const newSelectedKeys =
                props.selectionMode === SelectionMode.multiple || props.selectionMode === undefined
                    ? [
                          ...currentSelectedKeys.filter((x) => {
                              return !itemKeyStrings.includes(getKeyString(x, idPropertiesArray));
                          }),
                          ...currentSelection.map((x) => {
                              return getKeyObject(x, idPropertiesArray);
                          }),
                      ]
                    : currentSelection.length > 0 &&
                      selectionItems.some((x) => {
                          return (
                              getKeyString(x, idPropertiesArray) ===
                              getKeyString(currentSelection[0], idPropertiesArray)
                          );
                      })
                    ? currentSelection.map((x) => {
                          return getKeyObject(x, idPropertiesArray);
                      })
                    : currentSelectedKeys;

            setSelectedKeys(newSelectedKeys);

            if (props.onSelectionChanged) {
                const prevSelectedKeyStrings = currentSelectedKeys.map((x) => {
                    return getKeyString(x, idPropertiesArray);
                });

                const newSelectedKeyStrings = newSelectedKeys.map((x) => {
                    return getKeyString(x, idPropertiesArray);
                });

                const selectionChanges = [
                    ...newSelectedKeys
                        .filter((x) => {
                            return !prevSelectedKeyStrings.includes(getKeyString(x, idPropertiesArray));
                        })
                        .map((x) => {
                            return { key: x, isSelected: true };
                        }),
                    ...currentSelectedKeys
                        .filter((x) => {
                            return !newSelectedKeyStrings.includes(getKeyString(x, idPropertiesArray));
                        })
                        .map((x) => {
                            return { key: x, isSelected: false };
                        }),
                ];

                if (selectionChanges.length > 0) {
                    props.onSelectionChanged(selectionChanges);
                }
            }

            setIsSelectionChanged(false);
        },
        [isSelectionChanged],
        { debounce: 50 }, // Ovo je potrebno jer inace Marque selekcija ne radi kako treba iz nekog razloga
    );

    useOnChange(
        () => {
            const idPropertiesArray =
                props.idProperties !== undefined
                    ? props.idProperties
                    : props.idProperty !== undefined
                    ? [props.idProperty]
                    : [];

            const actualSelectedKeys = (
                (props.selectionMode === SelectionMode.none
                    ? []
                    : props.selectionMode === SelectionMode.multiple || props.selectionMode === undefined
                    ? props.selectedKeys === null
                        ? []
                        : props.selectedKeys
                    : props.selectionMode === SelectionMode.single
                    ? props.selectedKey === null
                        ? []
                        : props.selectedKey === undefined
                        ? undefined
                        : [props.selectedKey]
                    : undefined) ?? selectedKeys
            ).map((x) => {
                return getKeyString(x, idPropertiesArray);
            });

            const unselectedKeys = selection
                .getSelection()
                .map((x) => {
                    return getKeyString(x, idPropertiesArray);
                })
                .filter((x) => {
                    return !actualSelectedKeys.includes(x);
                });

            actualSelectedKeys.forEach((x) => {
                selection.setKeySelected(x, true, false);
            });

            unselectedKeys.forEach((x) => {
                selection.setKeySelected(x, false, false);
            });
        },
        [items, props.selectedKey, props.selectedKeys, props.selectionMode],
        { shouldCompareByReference: true }, // Potrebno zbog items
    );

    const onPaginationSubmit = () => {
        const newPaginationInputCount =
            paginationInputCount === undefined || paginationInputCount < 0 ? defaultPageCount : paginationInputCount;

        const newPaginationInputPage =
            paginationInputPage === undefined || paginationInputPage < 1 ? 1 : paginationInputPage;

        const newPagination: Pagination = {
            ItemsPerPage: newPaginationInputCount,
            PageIndex: newPaginationInputPage - 1,
        };
        setPagination(newPagination);
        setPaginationInputCount(newPaginationInputCount);
        setPaginationInputPage(newPaginationInputPage);
        props.onPaginationChanged && props.onPaginationChanged(newPagination);
        setCollapsedGroups([]);
        toggleIsPaginationMorePanelVisible(false);
    };

    const resetPagination = () => {
        if (props.disablePagination) {
            return;
        }

        const actualPagination = props.pagination ?? pagination;
        if (!actualPagination) {
            return;
        }

        const newPagination: Pagination = {
            ...actualPagination,
            PageIndex: 0,
        };
        setPagination(newPagination);
        props.onPaginationChanged && props.onPaginationChanged(newPagination);
    };

    const onRightClick = (item: any, index?: number | undefined, ev?: Event | undefined) => {
        if (!props.onItemContextMenu) {
            return;
        }

        setRightClickEvent(ev as MouseEvent);
        setContextMenuItems(props.onItemContextMenu(getKeyObject(item, props.idProperties ?? []), item, index!));
    };

    const onContextMenuDismissed = (ev?: Event | React.MouseEvent | React.KeyboardEvent, dismissAll?: boolean) => {
        if (ev?.type !== "scroll") {
            setRightClickEvent(undefined);
            setContextMenuItems(undefined);
        }
    };

    const onAddAggregateData = (id: string, aggregateDataType: AggregateDataType) => {
        const newAggregateDataTypes = cloneAggregateDataTypes(props.aggregateDataTypes ?? aggregateDataTypes);

        if (newAggregateDataTypes[id] === undefined) {
            newAggregateDataTypes[id] = [];
        }

        if (!newAggregateDataTypes[id].includes(aggregateDataType)) {
            newAggregateDataTypes[id].push(aggregateDataType);
        }

        setAggregateDataTypes(newAggregateDataTypes);
        props.onAggregateDataTypesChanged && props.onAggregateDataTypesChanged(newAggregateDataTypes);
    };

    const onRemoveAggregateData = (id: string, aggregateDataType: AggregateDataType) => {
        const newAggregateDataTypes = cloneAggregateDataTypes(props.aggregateDataTypes ?? aggregateDataTypes);

        if (newAggregateDataTypes[id] === undefined) {
            return;
        }

        if (newAggregateDataTypes[id].includes(aggregateDataType)) {
            newAggregateDataTypes[id] = newAggregateDataTypes[id].filter((x) => {
                return x !== aggregateDataType;
            });
        }

        setAggregateDataTypes(newAggregateDataTypes);
        props.onAggregateDataTypesChanged && props.onAggregateDataTypesChanged(newAggregateDataTypes);
    };

    const defaultColumnWidth = props.columnWidth ?? 200;
    const defaultColumnMinWidth = props.columnMinWidth ?? 40;
    const defaultColumnMaxWidth = props.columnMaxWidth;
    const defaultIsResizable = !props.disableResizing;
    const defaultIsSortable = !props.disableSorting;
    const defaultIsFilterable = !props.disableFiltering;

    const actualSort = props.sort ?? sort;
    const actualFilters = props.filters ?? filters;
    const actualPagination = props.pagination ?? pagination;
    const actualSearchValue = props.searchValue ?? searchValue;
    const actualGroup = props.group ?? group;
    const actualSelectors = props.selectors ?? selectors;
    const actualAggregateDataTypes = props.aggregateDataTypes ?? aggregateDataTypes;

    let isTableFilterable = defaultIsFilterable;

    const columns: IColumn[] = [];

    props.columns
        .filter((x) => {
            return Object.keys(actualSelectors).includes(x.id);
        })
        .forEach((column, i) => {
            const isFilterable = column.isFilterable ?? defaultIsFilterable;
            isTableFilterable ||= isFilterable;
            const columnFilter = actualFilters[column.id as any];

            const isSortable = column.isSortable ?? defaultIsSortable;
            const sorts: { id: string; isDescending: boolean }[] = [];
            let currentSort = actualSort;
            while (currentSort) {
                sorts.push({ id: currentSort.Id, isDescending: currentSort.IsDescending });
                currentSort = currentSort.NestedSort;
            }
            const isSorted = sorts.some((x) => {
                return x.id === column.id;
            });
            const isSortedDescending = sorts.some((x) => {
                return x.id === column.id && x.isDescending;
            });

            const filterComponent =
                isFilterable && (column.dataType !== undefined || column.onRenderFilter !== undefined) ? (
                    column.onRenderFilter !== undefined ? (
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <div style={{ flex: 1, maxWidth: "100%" }}>
                                {column.onRenderFilter(actualFilters[column.id], (newFilterItem) => {
                                    const newFilters = {
                                        ...actualFilters,
                                        [column.id]: newFilterItem,
                                    } as Record<string, Filter>;
                                    setFilters(newFilters);
                                    props.onFiltersChanged && props.onFiltersChanged(newFilters);

                                    resetPagination();
                                })}
                            </div>
                        </Stack>
                    ) : (
                        <DefaultFilter
                            id={column.id}
                            dataType={column.dataType!}
                            filter={columnFilter}
                            onFilterChanged={(newFilter) => {
                                const newFilters: Record<string, Filter> = {
                                    ...actualFilters,
                                };
                                if (newFilter === undefined) {
                                    delete newFilters[column.id];
                                } else {
                                    newFilters[column.id] = newFilter;
                                }
                                setFilters(newFilters);
                                props.onFiltersChanged && props.onFiltersChanged(newFilters);

                                resetPagination();
                            }}
                            isChangeInstant={props.data === undefined || Array.isArray(props.data)}
                            allowedValues={column.allowedValues}
                        />
                    )
                ) : null;

            columns.push({
                key: column.id,
                name: column.name,
                fieldName: column.property?.toString() ?? undefined,
                data: column,
                minWidth: column.minWidth ?? defaultColumnMinWidth,
                maxWidth: column.maxWidth ?? defaultColumnMaxWidth,
                calculatedWidth: column.width ?? defaultColumnWidth,
                isResizable: column.isResizable ?? defaultIsResizable,
                columnActionsMode: ColumnActionsMode.disabled,
                onRenderHeader: (renderProps) => {
                    return (
                        <>
                            {isSortable ? (
                                <DefaultButton
                                    styles={{
                                        root: {
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: 42,
                                            border: 0,
                                            backgroundColor: theme.palette.neutralLighter,
                                            borderRadius: 0,
                                            textAlign: "left",
                                            paddingLeft: 12,
                                            paddingRight: 8,
                                        },
                                    }}
                                    onClick={(e) => {
                                        renderProps!.column.onColumnClick &&
                                            renderProps!.column.onColumnClick(e as any, renderProps!.column);
                                    }}
                                    title={
                                        isSorted
                                            ? isSortedDescending
                                                ? localization.DataTable.RemoveSort
                                                : localization.DataTable.SortDescending
                                            : localization.DataTable.SortAscending
                                    }
                                >
                                    <Stack horizontal verticalAlign="center" styles={{ root: { width: "100%" } }}>
                                        <Text
                                            styles={{
                                                root: {
                                                    flex: 1,
                                                    fontWeight: "bold",
                                                    display: "flex",
                                                    alignItems: "center",
                                                },
                                            }}
                                        >
                                            {column.icon && (
                                                <Icon
                                                    iconName={column.icon}
                                                    styles={{ root: { marginRight: theme.spacing.s2 } }}
                                                />
                                            )}

                                            {renderProps!.column.name}
                                        </Text>

                                        {sorts.length > 1 && isSorted && (
                                            <Text variant="xSmall" styles={{ root: { alignSelf: "flex-start" } }}>
                                                {sorts.indexOf(
                                                    sorts.find((x) => {
                                                        return x.id === column.id;
                                                    })!,
                                                ) + 1}
                                            </Text>
                                        )}

                                        {(isSortable ?? defaultIsSortable) && (
                                            <Icon
                                                iconName={
                                                    isSorted
                                                        ? isSortedDescending
                                                            ? "SortDown"
                                                            : "SortUp"
                                                        : "SortLines"
                                                }
                                            />
                                        )}
                                    </Stack>
                                </DefaultButton>
                            ) : (
                                <Text
                                    styles={{
                                        root: { flex: 1, fontWeight: "bold", display: "flex", alignItems: "center" },
                                    }}
                                >
                                    {column.icon && (
                                        <Icon
                                            iconName={column.icon}
                                            styles={{ root: { marginRight: theme.spacing.s2 } }}
                                        />
                                    )}

                                    {renderProps!.column.name}
                                </Text>
                            )}

                            {filterComponent !== null && (
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: 5,
                                        left: 12,
                                        right: 8,
                                        boxSizing: "border-box",
                                    }}
                                >
                                    {filterComponent}
                                </div>
                            )}
                        </>
                    );
                },
                onRender: (item: TData, index) => {
                    if (column.onRender) {
                        return column.onRender(item, index!);
                    }

                    if ((!column.property || !(column.property in item)) && !column.getValue) {
                        return null;
                    }

                    var value = column.getValue ? column.getValue(item) : (item[column.property!] as any);

                    if (typeof value === "boolean") {
                        return <Checkbox checked={value} disabled />;
                    }

                    if (value instanceof Date) {
                        return `${value.getDate().toString().padStart(2, "0")}.${(value.getMonth() + 1)
                            .toString()
                            .padStart(2, "0")}.${value.getFullYear()}. ${value
                            .getHours()
                            .toString()
                            .padStart(2, "0")}:${value.getMinutes().toString().padStart(2, "0")}:${value
                            .getSeconds()
                            .toString()
                            .padStart(2, "0")}`;
                    }

                    if (column.dataType === DataType.Enum && column.allowedValues !== undefined) {
                        return (
                            column.allowedValues.find((x) => {
                                return x.key === value;
                            })?.text ??
                            column.allowedValues?.find((x) => {
                                return x.textKey === value;
                            })?.text ??
                            value
                        );
                    }

                    return value;
                },
                styles: {
                    root: {
                        height: "100%",
                        borderLeftWidth: 1,
                        borderLeftStyle: "solid",
                        borderLeftColor: theme.palette.neutralQuaternaryAlt,
                    },
                    cellName: { width: "100%" },
                    cellTitle: { width: "100%", height: "100%", position: "relative" },
                },
                onColumnClick:
                    items === undefined || !isSortable
                        ? undefined
                        : () => {
                              if (sorts.length === 1 && sorts[0].id === column.id) {
                                  if (sorts[0].isDescending) {
                                      setSort(undefined);
                                      props.onSortChanged && props.onSortChanged(undefined);
                                      resetPagination();
                                      return;
                                  }

                                  setSort({ Id: column.id, IsDescending: true });
                                  props.onSortChanged && props.onSortChanged({ Id: column.id, IsDescending: true });
                                  resetPagination();
                                  return;
                              }

                              setSort({ Id: column.id, IsDescending: false });
                              props.onSortChanged && props.onSortChanged({ Id: column.id, IsDescending: false });
                              resetPagination();
                              return;
                          },
            });
        });

    const groups: IGroup[] | undefined =
        !groupResults || groupResults.length === 0
            ? undefined
            : getGroups(
                  groupResults,
                  actualGroup,
                  items ?? [],
                  collapsedGroups,
                  props.columns,
                  actualPagination !== undefined && (items ?? []).length === actualPagination.ItemsPerPage,
                  localization.Other,
              );

    const renderDetailsList = () => {
        return (
            <>
                <ShimmeredDetailsList
                    setKey="none"
                    columns={columns}
                    groups={groups}
                    // TODO
                    // Ovo nije dobro u slucaju velikog broja podataka jer renderira sve redove i stupce.
                    // Napravljeno je jer se inace desava nesto cudno kod grupiranih podataka (npr. grupira se po stupcu i odskrola prema dole, UI poludi).
                    // Pomaze koristiti GroupedListV2, ali u tom slucaju se footer (agregirani podaci) ne prikazuju za sve grupe, vec samo za one najnestanije.
                    // onShouldVirtualize={() => {
                    //     return false;
                    // }}
                    groupProps={{
                        // groupedListAs: GroupedListV2,
                        onRenderShowAll: () => {
                            return null;
                        },
                        onToggleCollapseAll: (isAllCollapsed) => {
                            setCollapsedGroups(
                                isAllCollapsed
                                    ? groups?.map((x) => {
                                          return x.key;
                                      }) ?? []
                                    : [],
                            );
                        },
                        onRenderHeader: (renderProps, defaultRender) => {
                            return (
                                <GroupHeader
                                    {...renderProps}
                                    onToggleCollapse={(group) => {
                                        setCollapsedGroups((prevCollapsedGroups) => {
                                            if (prevCollapsedGroups.includes(group.key)) {
                                                return prevCollapsedGroups.filter((x) => {
                                                    return x !== group.key;
                                                });
                                            } else {
                                                return [...prevCollapsedGroups, group.key];
                                            }
                                        });
                                    }}
                                    styles={{
                                        root: {
                                            backgroundColor: theme.palette.neutralLighter,
                                        },
                                    }}
                                />
                            );
                        },
                        onRenderFooter: (renderProps) => {
                            if (
                                props.disableAggregating ||
                                !props.columns.some((x) => {
                                    return x.isAggregatable !== false;
                                }) ||
                                !aggregateDataResults
                            ) {
                                return null;
                            }

                            const distinctAggregateDataTypes = Array.from(
                                new Set(Object.values(aggregateDataTypes).flat()),
                            );

                            if (distinctAggregateDataTypes.length === 0) {
                                return null;
                            }

                            return (
                                <>
                                    <Stack
                                        horizontal
                                        styles={{
                                            root: {
                                                backgroundColor: theme.palette.neutralLighter,
                                                borderBottomColor: theme.palette.neutralLight,
                                                borderBottomWidth: 1,
                                                borderBottomStyle: "solid",
                                            },
                                        }}
                                    >
                                        <DetailsRowCheck
                                            canSelect={false}
                                            styles={{ root: { visibility: "hidden", height: 0 } }}
                                        />

                                        <GroupSpacer
                                            indentWidth={renderProps?.indentWidth}
                                            count={(renderProps?.groupLevel ?? 0) + 1}
                                        />

                                        <Text
                                            variant="small"
                                            styles={{
                                                root: {
                                                    fontWeight: "bold",
                                                    color: theme.palette.neutralSecondary,
                                                    padding: theme.spacing.s1,
                                                },
                                            }}
                                        >
                                            {renderProps?.group?.name}
                                        </Text>
                                    </Stack>

                                    {distinctAggregateDataTypes.map((aggregateDataType, i) => {
                                        const isLast = i === distinctAggregateDataTypes.length - 1;

                                        return (
                                            <DetailsRow
                                                key={aggregateDataType}
                                                columns={
                                                    renderProps?.columns?.map((x) => {
                                                        return { ...x, onRender: undefined };
                                                    }) ?? []
                                                }
                                                selectionMode={SelectionMode.single}
                                                item={{}}
                                                itemIndex={(actualPagination?.ItemsPerPage ?? 0) + 1}
                                                groupNestingDepth={
                                                    getMaxGroupLevel(renderProps?.groups) +
                                                    (renderProps?.groupLevel ?? 0)
                                                }
                                                onRenderItemColumn={(item, index, column) => {
                                                    if (column?.data?.isAggregatable === false) {
                                                        return null;
                                                    }

                                                    const aggregateDataResult =
                                                        renderProps?.group?.data?.groupResult?.AggregateDataResults?.find(
                                                            (x: AggregateDataResult) => {
                                                                return (
                                                                    x.Id === column?.data.id &&
                                                                    x.AggregateDataType === aggregateDataType
                                                                );
                                                            },
                                                        );

                                                    if (!aggregateDataResult) {
                                                        return null;
                                                    }

                                                    return (
                                                        <Stack styles={{ root: { flex: 1 } }}>
                                                            <Text
                                                                variant="xSmall"
                                                                styles={{
                                                                    root: {
                                                                        fontWeight: "bold",
                                                                        color: theme.palette.neutralSecondary,
                                                                    },
                                                                }}
                                                            >
                                                                {getAggregateDataTypeHeaderText(
                                                                    aggregateDataType,
                                                                    localization,
                                                                )}
                                                            </Text>

                                                            <Text styles={{ root: { fontWeight: "bold" } }}>
                                                                {aggregateDataResult.StringValue}
                                                            </Text>
                                                        </Stack>
                                                    );
                                                }}
                                                onRenderDetailsCheckbox={(checkboxRenderProps) => {
                                                    return (
                                                        <DetailsRowCheck
                                                            {...checkboxRenderProps}
                                                            canSelect={false}
                                                            styles={{ root: { visibility: "hidden" } }}
                                                        />
                                                    );
                                                }}
                                                styles={{
                                                    root: {
                                                        backgroundColor: theme.palette.neutralLighter,
                                                        borderBottomColor: isLast
                                                            ? theme.palette.neutralQuaternaryAlt
                                                            : theme.palette.neutralLight,
                                                        borderBottomWidth: isLast ? 5 : undefined,
                                                    },
                                                }}
                                            />
                                        );
                                    })}
                                </>
                            );
                        },
                    }}
                    items={items === undefined ? [] : items.length === 0 ? [{}] : items}
                    compact={props.isCompact}
                    selection={selection}
                    selectionMode={props.selectionMode}
                    checkboxVisibility={props.checkboxVisibility}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderDetailsHeader={(renderProps, defaultRender) => {
                        return renderDetailsHeader(
                            {
                                ...renderProps!,
                                // onRenderDetailsCheckbox: (checkboxRenderProps) => {
                                //     return (
                                //         <Checkbox
                                //             checked={checkboxRenderProps?.checked ?? false}
                                //             onChange={(_, checked) => {
                                //                 selection.setAllSelected(checked ?? false);
                                //             }}
                                //         />
                                //     );
                                // }, // TODO trebalo bi raditi, ali ne radi. s obicnim checkboxom radi
                            },
                            defaultRender!,
                            isTableFilterable,
                            theme,
                        );
                    }}
                    selectionPreservedOnEmptyClick={false}
                    enableShimmer={items === undefined}
                    shimmerLines={5}
                    selectionZoneProps={
                        props.isSelectionCheckbox ? { selection: selection, isSelectedOnFocus: false } : undefined
                    }
                    onRenderRow={(renderProps) => {
                        if (items !== undefined && items.length === 0) {
                            return (
                                <div style={{ position: "relative", height: props.onAddNewItemClicked ? 150 : 80 }}>
                                    <Stack
                                        styles={{
                                            root: {
                                                width: "100%",
                                                position: "absolute",
                                                top: 0,
                                                left: document.getElementById(scrollablePaneIdRef.current)?.children[1]
                                                    .scrollLeft,
                                                transition: "all 0.2s",
                                                height: "100%",
                                            },
                                        }}
                                        tokens={{ childrenGap: theme.spacing.s1 }}
                                        horizontalAlign="center"
                                        verticalAlign="center"
                                    >
                                        <Text>{props.noDataText ?? localization.DataTable.NoData}</Text>

                                        {props.onAddNewItemClicked && (
                                            <PrimaryButton onClick={props.onAddNewItemClicked}>
                                                {localization.DataTable.AddNewItem}
                                            </PrimaryButton>
                                        )}
                                    </Stack>
                                </div>
                            );
                        }

                        const itemIndex = renderProps!.itemIndex;
                        let isEvenRow = itemIndex % 2 === 0;
                        if (groups && groups.length > 0) {
                            let containingGroup: IGroup = {
                                key: "",
                                count: 0,
                                name: "",
                                startIndex: 0,
                                children: groups,
                            };

                            while (containingGroup?.children) {
                                containingGroup = containingGroup.children.find((x) => {
                                    return x.startIndex <= itemIndex && x.startIndex + x.count > itemIndex;
                                })!;
                            }

                            isEvenRow = (itemIndex - containingGroup.startIndex) % 2 === 0;
                        }

                        return (
                            <DetailsRow
                                {...renderProps!}
                                data-selection-disabled={props.isSelectionCheckbox ? true : false}
                                styles={{
                                    root: {
                                        backgroundColor:
                                            !(props.isStriped ?? true) ||
                                            isEvenRow ||
                                            selection.isIndexSelected(renderProps!.itemIndex)
                                                ? undefined
                                                : theme.palette.neutralLighterAlt,
                                    },
                                }}
                                onRenderDetailsCheckbox={
                                    props.isSelectionCheckbox
                                        ? (checkboxRenderProps) => {
                                              return (
                                                  <Checkbox
                                                      checked={checkboxRenderProps?.checked}
                                                      onChange={(_, checked) => {
                                                          selection.setKeySelected(
                                                              selection.getKey(renderProps!.item),
                                                              checked ?? false,
                                                              true,
                                                          );
                                                      }}
                                                  />
                                              );
                                          }
                                        : renderProps!.onRenderDetailsCheckbox
                                }
                            />
                        );
                    }}
                    onRenderDetailsFooter={(renderProps) => {
                        if (
                            props.disableAggregating ||
                            !props.columns.some((x) => {
                                return x.isAggregatable !== false;
                            }) ||
                            !aggregateDataResults
                        ) {
                            return null;
                        }

                        const distinctAggregateDataTypes = Array.from(
                            new Set(Object.values(aggregateDataTypes).flat()),
                        );

                        return (
                            <Sticky stickyPosition={StickyPositionType.Footer} isScrollSynced>
                                {distinctAggregateDataTypes.length > 0 && (
                                    <Stack
                                        horizontal
                                        styles={{
                                            root: {
                                                backgroundColor: theme.palette.neutralLighter,
                                                borderTopColor: theme.palette.neutralLight,
                                                borderTopWidth: 1,
                                                borderTopStyle: "solid",
                                            },
                                        }}
                                    >
                                        <DetailsRowCheck
                                            canSelect={false}
                                            styles={{ root: { visibility: "hidden", height: 0 } }}
                                        />

                                        <GroupSpacer
                                            indentWidth={renderProps?.indentWidth}
                                            count={getMaxGroupLevel(groups)}
                                        />

                                        <Text
                                            variant="small"
                                            styles={{
                                                root: {
                                                    fontWeight: "bold",
                                                    color: theme.palette.neutralSecondary,
                                                    padding: theme.spacing.s1,
                                                },
                                            }}
                                        >
                                            {localization.Total}
                                        </Text>
                                    </Stack>
                                )}

                                {distinctAggregateDataTypes.map((aggregateDataType, i) => {
                                    return (
                                        <DetailsRow
                                            key={aggregateDataType}
                                            {...renderProps}
                                            columns={
                                                renderProps?.columns.map((x) => {
                                                    return { ...x, onRender: undefined };
                                                }) ?? []
                                            }
                                            selectionMode={props.selectionMode}
                                            checkboxVisibility={props.checkboxVisibility}
                                            item={{}}
                                            itemIndex={(actualPagination?.ItemsPerPage ?? defaultPageCount) + i}
                                            onRenderItemColumn={(item, index, column) => {
                                                if (column?.data?.isAggregatable === false) {
                                                    return null;
                                                }

                                                const aggregateDataResult = aggregateDataResults.find((x) => {
                                                    return (
                                                        x.Id === column?.data.id &&
                                                        x.AggregateDataType === aggregateDataType
                                                    );
                                                });

                                                const isAggregateDataAllowed =
                                                    aggregateDataType === AggregateDataType.CountEmpty ||
                                                    aggregateDataType === AggregateDataType.CountNonEmpty ||
                                                    aggregateDataType === AggregateDataType.PercentEmpty ||
                                                    aggregateDataType === AggregateDataType.PercentNonEmpty ||
                                                    (aggregateDataType === AggregateDataType.Sum &&
                                                        column?.data?.dataType === DataType.Number) ||
                                                    (aggregateDataType === AggregateDataType.Average &&
                                                        column?.data?.dataType === DataType.Number) ||
                                                    (aggregateDataType === AggregateDataType.Minimum &&
                                                        (column?.data?.dataType === DataType.Number ||
                                                            column?.data?.dataType === DataType.DateTime)) ||
                                                    (aggregateDataType === AggregateDataType.Maximum &&
                                                        (column?.data?.dataType === DataType.Number ||
                                                            column?.data?.dataType === DataType.DateTime)) ||
                                                    (aggregateDataType === AggregateDataType.CountTrue &&
                                                        column?.data?.dataType === DataType.Boolean) ||
                                                    (aggregateDataType === AggregateDataType.CountFalse &&
                                                        column?.data?.dataType === DataType.Boolean) ||
                                                    (aggregateDataType === AggregateDataType.PercentTrue &&
                                                        column?.data?.dataType === DataType.Boolean) ||
                                                    (aggregateDataType === AggregateDataType.PercentFalse &&
                                                        column?.data?.dataType === DataType.Boolean);

                                                return (
                                                    <Stack>
                                                        {aggregateDataResult ? (
                                                            <Stack
                                                                horizontal
                                                                verticalAlign="start"
                                                                tokens={{ childrenGap: theme.spacing.s2 }}
                                                            >
                                                                <Stack styles={{ root: { flex: 1 } }}>
                                                                    <Text
                                                                        variant="xSmall"
                                                                        styles={{
                                                                            root: {
                                                                                fontWeight: "bold",
                                                                                color: theme.palette.neutralSecondary,
                                                                            },
                                                                        }}
                                                                    >
                                                                        {getAggregateDataTypeHeaderText(
                                                                            aggregateDataType,
                                                                            localization,
                                                                        )}
                                                                    </Text>

                                                                    <Text styles={{ root: { fontWeight: "bold" } }}>
                                                                        {aggregateDataResult.StringValue}
                                                                    </Text>
                                                                </Stack>

                                                                <IconButton
                                                                    iconProps={{ iconName: "Cancel" }}
                                                                    styles={{
                                                                        root: { color: theme.palette.neutralTertiary },
                                                                        rootHovered: {
                                                                            color: theme.palette.neutralSecondary,
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        onRemoveAggregateData(
                                                                            column?.data.id!,
                                                                            aggregateDataType,
                                                                        );
                                                                    }}
                                                                    title={localization.DataTable.RemoveAggregateData}
                                                                />
                                                            </Stack>
                                                        ) : (
                                                            <DefaultButton
                                                                styles={{
                                                                    root: {
                                                                        backgroundColor: "transparent",
                                                                        borderStyle: "dashed",
                                                                        fontSize: 11,
                                                                        borderColor: theme.palette.neutralQuaternary,
                                                                        color: theme.palette.neutralSecondary,
                                                                    },
                                                                    rootDisabled: {
                                                                        backgroundColor: "transparent",
                                                                        border: 0,
                                                                    },
                                                                }}
                                                                onClick={() => {
                                                                    onAddAggregateData(
                                                                        column?.data.id!,
                                                                        aggregateDataType,
                                                                    );
                                                                }}
                                                                disabled={!isAggregateDataAllowed}
                                                            >
                                                                {isAggregateDataAllowed
                                                                    ? getAggregateDataTypeAddText(
                                                                          aggregateDataType,
                                                                          localization,
                                                                      )
                                                                    : localization.DataTable.AggregateDataNotAvailable}
                                                            </DefaultButton>
                                                        )}
                                                    </Stack>
                                                );
                                            }}
                                            onRenderDetailsCheckbox={(checkboxRenderProps) => {
                                                return (
                                                    <DetailsRowCheck
                                                        {...checkboxRenderProps}
                                                        canSelect={false}
                                                        styles={{ root: { visibility: "hidden" } }}
                                                    />
                                                );
                                            }}
                                            styles={{
                                                root: {
                                                    backgroundColor: theme.palette.neutralLighter,
                                                    borderTopColor: theme.palette.neutralLight,
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                },
                                            }}
                                        />
                                    );
                                })}

                                <DetailsRow
                                    {...renderProps}
                                    columns={
                                        renderProps?.columns.map((x) => {
                                            return { ...x, onRender: undefined };
                                        }) ?? []
                                    }
                                    selectionMode={props.selectionMode}
                                    checkboxVisibility={props.checkboxVisibility}
                                    item={{}}
                                    itemIndex={
                                        (actualPagination?.ItemsPerPage ?? defaultPageCount) +
                                        distinctAggregateDataTypes.length
                                    }
                                    onRenderItemColumn={(item, index, column) => {
                                        if (column?.data?.isAggregatable === false) {
                                            return null;
                                        }

                                        let options: IContextualMenuItem[] = [
                                            {
                                                key: AggregateDataType.CountEmpty,
                                                text: localization.DataTable.SelectionAggregateCountEmpty,
                                                onClick: () => {
                                                    return onAddAggregateData(
                                                        column?.data.id!,
                                                        AggregateDataType.CountEmpty,
                                                    );
                                                },
                                            },
                                            {
                                                key: AggregateDataType.CountNonEmpty,
                                                text: localization.DataTable.SelectionAggregateCountNonEmpty,
                                                onClick: () => {
                                                    return onAddAggregateData(
                                                        column?.data.id!,
                                                        AggregateDataType.CountNonEmpty,
                                                    );
                                                },
                                            },
                                            {
                                                key: AggregateDataType.PercentEmpty,
                                                text: localization.DataTable.SelectionAggregatePercentageEmpty,
                                                onClick: () => {
                                                    return onAddAggregateData(
                                                        column?.data.id!,
                                                        AggregateDataType.PercentEmpty,
                                                    );
                                                },
                                            },
                                            {
                                                key: AggregateDataType.PercentNonEmpty,
                                                text: localization.DataTable.SelectionAggregatePercentageNonEmpty,
                                                onClick: () => {
                                                    return onAddAggregateData(
                                                        column?.data.id!,
                                                        AggregateDataType.PercentNonEmpty,
                                                    );
                                                },
                                            },
                                        ];

                                        if (column?.data?.dataType === DataType.Number) {
                                            options.splice(
                                                0,
                                                0,
                                                {
                                                    key: AggregateDataType.Sum,
                                                    text: localization.DataTable.SelectionAggregateSum,
                                                    onClick: () => {
                                                        return onAddAggregateData(
                                                            column?.data.id!,
                                                            AggregateDataType.Sum,
                                                        );
                                                    },
                                                },
                                                {
                                                    key: AggregateDataType.Average,
                                                    text: localization.DataTable.SelectionAggregateAverage,
                                                    onClick: () => {
                                                        return onAddAggregateData(
                                                            column?.data.id!,
                                                            AggregateDataType.Average,
                                                        );
                                                    },
                                                },
                                                {
                                                    key: AggregateDataType.Minimum,
                                                    text: localization.DataTable.SelectionAggregateMinimum,
                                                    onClick: () => {
                                                        return onAddAggregateData(
                                                            column?.data.id!,
                                                            AggregateDataType.Minimum,
                                                        );
                                                    },
                                                },
                                                {
                                                    key: AggregateDataType.Maximum,
                                                    text: localization.DataTable.SelectionAggregateMaximum,
                                                    onClick: () => {
                                                        return onAddAggregateData(
                                                            column?.data.id!,
                                                            AggregateDataType.Maximum,
                                                        );
                                                    },
                                                },
                                            );
                                        }

                                        if (column?.data?.dataType === DataType.DateTime) {
                                            options.splice(
                                                0,
                                                0,
                                                {
                                                    key: AggregateDataType.Minimum,
                                                    text: localization.DataTable.SelectionAggregateMinimum,
                                                    onClick: () => {
                                                        return onAddAggregateData(
                                                            column?.data.id!,
                                                            AggregateDataType.Minimum,
                                                        );
                                                    },
                                                },
                                                {
                                                    key: AggregateDataType.Maximum,
                                                    text: localization.DataTable.SelectionAggregateMaximum,
                                                    onClick: () => {
                                                        return onAddAggregateData(
                                                            column?.data.id!,
                                                            AggregateDataType.Maximum,
                                                        );
                                                    },
                                                },
                                            );
                                        }

                                        if (column?.data?.dataType === DataType.Boolean) {
                                            options.splice(
                                                0,
                                                0,
                                                {
                                                    key: AggregateDataType.CountTrue,
                                                    text: localization.DataTable.SelectionAggregateCountTrue,
                                                    onClick: () => {
                                                        return onAddAggregateData(
                                                            column?.data.id!,
                                                            AggregateDataType.CountTrue,
                                                        );
                                                    },
                                                },
                                                {
                                                    key: AggregateDataType.CountFalse,
                                                    text: localization.DataTable.SelectionAggregateCountFalse,
                                                    onClick: () => {
                                                        return onAddAggregateData(
                                                            column?.data.id!,
                                                            AggregateDataType.CountFalse,
                                                        );
                                                    },
                                                },
                                                {
                                                    key: AggregateDataType.PercentTrue,
                                                    text: localization.DataTable.SelectionAggregatePercentageTrue,
                                                    onClick: () => {
                                                        return onAddAggregateData(
                                                            column?.data.id!,
                                                            AggregateDataType.PercentTrue,
                                                        );
                                                    },
                                                },
                                                {
                                                    key: AggregateDataType.PercentFalse,
                                                    text: localization.DataTable.SelectionAggregatePercentageFalse,
                                                    onClick: () => {
                                                        return onAddAggregateData(
                                                            column?.data.id!,
                                                            AggregateDataType.PercentFalse,
                                                        );
                                                    },
                                                },
                                            );
                                        }

                                        const currentAggregateDataTypes = actualAggregateDataTypes[column?.data.id!];
                                        if (currentAggregateDataTypes) {
                                            options = options.filter((x) => {
                                                return !currentAggregateDataTypes.includes(x.key as AggregateDataType);
                                            });
                                        }

                                        return (
                                            <Stack>
                                                <DefaultButton
                                                    styles={{
                                                        root: {
                                                            backgroundColor: "transparent",
                                                            fontSize: 12,
                                                            borderColor: theme.palette.neutralQuaternary,
                                                            color: theme.palette.neutralSecondary,
                                                        },
                                                        rootDisabled: { backgroundColor: "transparent", border: 0 },
                                                    }}
                                                    menuProps={options.length === 0 ? undefined : { items: options }}
                                                    text={
                                                        options.length === 0
                                                            ? localization.DataTable.AllAggregateDataShown
                                                            : localization.DataTable.AddAggregateData
                                                    }
                                                    disabled={options.length === 0}
                                                />
                                            </Stack>
                                        );
                                    }}
                                    onRenderDetailsCheckbox={(checkboxRenderProps) => {
                                        return (
                                            <DetailsRowCheck
                                                {...checkboxRenderProps}
                                                canSelect={false}
                                                styles={{ root: { visibility: "hidden" } }}
                                            />
                                        );
                                    }}
                                    styles={{
                                        root: {
                                            backgroundColor: theme.palette.neutralLighter,
                                            borderTopColor: theme.palette.neutralLight,
                                            borderTopWidth: 1,
                                            borderTopStyle: "solid",
                                        },
                                    }}
                                />
                            </Sticky>
                        );
                    }}
                    onItemInvoked={(item, index) => {
                        props.onItemInvoked &&
                            props.onItemInvoked(getKeyObject(item, props.idProperties ?? []), item, index!);
                    }}
                    onItemContextMenu={onRightClick}
                />

                {rightClickEvent && contextMenuItems && (
                    <ContextualMenu
                        items={contextMenuItems}
                        target={rightClickEvent}
                        hidden={!rightClickEvent}
                        onDismiss={onContextMenuDismissed}
                    />
                )}
            </>
        );
    };

    const isCompactToolbar = containerSize.width !== 0 && containerSize.width < 1030;
    const isCompactPagination = containerSize.width !== 0 && containerSize.width < 1030;

    return (
        <div
            ref={containerRef}
            style={{
                display: "flex",
                flexDirection: "column",
                width: "auto",
                height: "auto",
                boxSizing: "border-box",
                flex: "1 1 0%",
            }}
        >
            {!props.hideToolbar && (
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    verticalAlign="center"
                    tokens={{ childrenGap: theme.spacing.s1 }}
                    styles={{
                        root: {
                            backgroundColor: theme.palette.neutralLighter,
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                            borderBottomColor: theme.palette.neutralQuaternaryAlt,
                            paddingLeft: theme.spacing.s1,
                            paddingRight: theme.spacing.s1,
                            height: 44,
                            overflowX: "auto",
                            overflowY: "hidden",
                        },
                    }}
                >
                    <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
                        {!props.disableFiltering &&
                            columns.some((x) => {
                                return x.data.isFilterable === true || x.data.isFilterable === undefined;
                            }) && (
                                <span>
                                    <CommandBarButton
                                        iconProps={{ iconName: "Filter" }}
                                        styles={commandBarButtonStyles}
                                        title={localization.DataTable.Filter}
                                        text={isCompactToolbar ? undefined : localization.DataTable.Filter}
                                        onClick={() => {
                                            toggleIsFilterCalloutVisible();
                                        }}
                                        id={filterButtonIdRef.current}
                                    />

                                    <Callout
                                        hidden={!isFilterCalloutVisible}
                                        target={`#${filterButtonIdRef.current}`}
                                        directionalHint={DirectionalHint.bottomLeftEdge}
                                        onDismiss={() => {
                                            toggleIsFilterCalloutVisible(false);
                                        }}
                                        styles={{ root: { width: "100%", maxWidth: 600 } }}
                                    >
                                        {isFilterCalloutVisible && (
                                            <FilterForm
                                                columns={props.columns.filter((x) => {
                                                    return Object.keys(actualSelectors).includes(x.id);
                                                })}
                                                initialFilters={actualFilters}
                                                onFiltersChanged={(newFilters) => {
                                                    setFilters(newFilters);
                                                    props.onFiltersChanged && props.onFiltersChanged(newFilters);
                                                }}
                                                onDismiss={() => {
                                                    toggleIsFilterCalloutVisible(false);
                                                }}
                                            />
                                        )}
                                    </Callout>
                                </span>
                            )}

                        {!props.disableSorting &&
                            props.columns.some((x) => {
                                return x.isSortable === true || x.isSortable === undefined;
                            }) && (
                                <span>
                                    <CommandBarButton
                                        iconProps={{ iconName: "SortLines" }}
                                        styles={commandBarButtonStyles}
                                        title={localization.DataTable.Sort}
                                        text={isCompactToolbar ? undefined : localization.DataTable.Sort}
                                        onClick={() => {
                                            toggleIsSortCalloutVisible();
                                        }}
                                        id={sortButtonIdRef.current}
                                    />

                                    <Callout
                                        hidden={!isSortCalloutVisible}
                                        target={`#${sortButtonIdRef.current}`}
                                        directionalHint={DirectionalHint.bottomLeftEdge}
                                        onDismiss={() => {
                                            toggleIsSortCalloutVisible(false);
                                        }}
                                        styles={{
                                            root: { width: "100%", maxWidth: 400, padding: theme.spacing.s1 },
                                        }}
                                    >
                                        {isSortCalloutVisible && (
                                            <SortForm
                                                columns={props.columns.filter((x) => {
                                                    return Object.keys(actualSelectors).includes(x.id);
                                                })}
                                                initialSort={actualSort}
                                                onSortChanged={(newSort) => {
                                                    setSort(newSort);
                                                    props.onSortChanged && props.onSortChanged(newSort);
                                                }}
                                                onDismiss={() => {
                                                    toggleIsSortCalloutVisible(false);
                                                }}
                                            />
                                        )}
                                    </Callout>
                                </span>
                            )}

                        {!props.disableSelecting &&
                            props.columns.some((x) => {
                                return x.isSelectable === true || x.isSelectable === undefined;
                            }) && (
                                <span>
                                    <CommandBarButton
                                        iconProps={{ iconName: "QuadColumn" }}
                                        styles={commandBarButtonStyles}
                                        title={localization.DataTable.Selection}
                                        text={isCompactToolbar ? undefined : localization.DataTable.Selection}
                                        onClick={() => {
                                            toggleIsSelectorCalloutVisible();
                                        }}
                                        id={selectorButtonIdRef.current}
                                    />

                                    <Callout
                                        hidden={!isSelectorCalloutVisible}
                                        target={`#${selectorButtonIdRef.current}`}
                                        directionalHint={DirectionalHint.bottomLeftEdge}
                                        onDismiss={() => {
                                            toggleIsSelectorCalloutVisible(false);
                                        }}
                                        styles={{
                                            root: { width: "100%", maxWidth: 900 },
                                        }}
                                    >
                                        {isSelectorCalloutVisible && (
                                            <SelectorForm
                                                visibleColumns={props.columns}
                                                initialSelectors={actualSelectors}
                                                initialAggregateDataTypes={actualAggregateDataTypes}
                                                onSubmit={(newSelectors, newAggregateDataTypes) => {
                                                    setSelectors(newSelectors);
                                                    setAggregateDataTypes(newAggregateDataTypes);
                                                    props.onSelectorsChanged && props.onSelectorsChanged(newSelectors);
                                                    props.onAggregateDataTypesChanged &&
                                                        props.onAggregateDataTypesChanged(newAggregateDataTypes);
                                                }}
                                                onDismiss={() => {
                                                    toggleIsSelectorCalloutVisible(false);
                                                }}
                                            />
                                        )}
                                    </Callout>
                                </span>
                            )}

                        {!props.disableGrouping &&
                            props.columns.some((x) => {
                                return x.isGroupable === true || x.isGroupable === undefined;
                            }) && (
                                <span>
                                    <CommandBarButton
                                        iconProps={{ iconName: "GroupedAscending" }}
                                        styles={commandBarButtonStyles}
                                        title={localization.DataTable.Grouping}
                                        text={isCompactToolbar ? undefined : localization.DataTable.Grouping}
                                        onClick={() => {
                                            toggleIsGroupCalloutVisible();
                                        }}
                                        id={groupButtonIdRef.current}
                                    />

                                    <Callout
                                        hidden={!isGroupCalloutVisible}
                                        target={`#${groupButtonIdRef.current}`}
                                        directionalHint={DirectionalHint.bottomLeftEdge}
                                        onDismiss={() => {
                                            toggleIsGroupCalloutVisible(false);
                                        }}
                                        styles={{
                                            root: { width: "100%", maxWidth: 300, padding: theme.spacing.s1 },
                                        }}
                                    >
                                        {isGroupCalloutVisible && (
                                            <GroupForm
                                                columns={props.columns.filter((x) => {
                                                    return Object.keys(actualSelectors).includes(x.id);
                                                })}
                                                initialGroup={actualGroup}
                                                onGroupChanged={(newGroup) => {
                                                    setGroup(newGroup);
                                                    props.onGroupChanged && props.onGroupChanged(newGroup);
                                                }}
                                                onDismiss={() => {
                                                    toggleIsGroupCalloutVisible(false);
                                                }}
                                            />
                                        )}
                                    </Callout>
                                </span>
                            )}

                        {props.views && (
                            <>
                                <Separator
                                    vertical
                                    styles={{
                                        root: { ":after": { backgroundColor: theme.palette.neutralQuaternaryAlt } },
                                    }}
                                />

                                <Dropdown
                                    options={props.views.map((x) => {
                                        return { key: x.id, text: x.name, title: x.name };
                                    })}
                                    onChange={(items) => {
                                        const selectedViewId = items.find((x) => {
                                            return x.isSelected;
                                        })?.key;

                                        setViewId(selectedViewId ?? null);
                                        props.onViewIdChanged && props.onViewIdChanged(selectedViewId ?? null);
                                    }}
                                    selectedKey={selectedView?.id ?? null}
                                    placeholder={localization.DataTable.SelectView}
                                    onRenderItem={(renderProps, defaultRender) => {
                                        const view = props.views?.find((x) => {
                                            return x.id === renderProps?.key;
                                        });

                                        return (
                                            <Stack key={renderProps?.key} horizontal verticalAlign="center">
                                                {defaultRender!(renderProps)}

                                                {props.onSetViewAsDefault && (
                                                    <IconButton
                                                        iconProps={{
                                                            iconName: view?.isDefault
                                                                ? "CheckboxCompositeReversed"
                                                                : "CheckboxComposite",
                                                        }}
                                                        title={
                                                            view?.isDefault
                                                                ? localization.DataTable.UnsetAsDefault
                                                                : localization.DataTable.SetAsDefault
                                                        }
                                                        onClick={() => {
                                                            props.onSetViewAsDefault!(
                                                                view?.isDefault ? undefined : renderProps!.key,
                                                            );
                                                        }}
                                                        disabled={view?.disableSetAsDefault === true}
                                                    />
                                                )}

                                                {props.onEditView && (
                                                    <IconButton
                                                        iconProps={{ iconName: "Edit" }}
                                                        title={localization.Edit}
                                                        onClick={() => {
                                                            props.onEditView!(renderProps!.key);
                                                        }}
                                                        disabled={view?.disableEdit === true}
                                                    />
                                                )}

                                                {props.onDeleteView && (
                                                    <IconButton
                                                        iconProps={{ iconName: "Delete" }}
                                                        title={localization.Delete}
                                                        onClick={() => {
                                                            props.onDeleteView!(renderProps!.key);
                                                        }}
                                                        disabled={view?.disableDelete === true}
                                                    />
                                                )}
                                            </Stack>
                                        );
                                    }}
                                    calloutProps={{ calloutMinWidth: 300 }}
                                    styles={{
                                        root: {
                                            width: 200,
                                        },
                                        title: {
                                            backgroundColor: theme.palette.neutralLighterAlt,
                                            borderColor: theme.palette.neutralQuaternary,
                                        },
                                    }}
                                />

                                {(props.onUpdateView || props.onAddView) && (
                                    <CommandBarButton
                                        iconProps={{ iconName: "Save" }}
                                        styles={commandBarButtonStyles}
                                        text={isCompactToolbar ? undefined : localization.DataTable.SaveView}
                                        title={localization.DataTable.SaveView}
                                        disabled={
                                            selectedView?.disableUpdate === true ||
                                            (props.onAddView !== undefined &&
                                                props.onUpdateView === undefined &&
                                                selectedView !== undefined) ||
                                            (props.onAddView === undefined &&
                                                props.onUpdateView !== undefined &&
                                                selectedView === undefined)
                                        }
                                        onClick={() => {
                                            const viewData = {
                                                filters: actualFilters,
                                                selectors: actualSelectors,
                                                group: actualGroup,
                                                sort: actualSort,
                                                paginationCount: actualPagination?.ItemsPerPage,
                                                aggregateDataTypes: actualAggregateDataTypes,
                                            };

                                            if (selectedView && props.onUpdateView) {
                                                props.onUpdateView(selectedView.id, viewData);
                                            }

                                            if (!selectedView && props.onAddView) {
                                                props.onAddView(viewData);
                                            }
                                        }}
                                    />
                                )}

                                {props.onAddView && (
                                    <CommandBarButton
                                        iconProps={{ iconName: "SaveAs" }}
                                        styles={commandBarButtonStyles}
                                        text={isCompactToolbar ? undefined : localization.DataTable.SaveViewAs}
                                        title={localization.DataTable.SaveViewAs}
                                        onClick={() => {
                                            props.onAddView!({
                                                filters: actualFilters,
                                                selectors: actualSelectors,
                                                group: actualGroup,
                                                sort: actualSort,
                                                paginationCount: actualPagination?.ItemsPerPage,
                                                aggregateDataTypes: actualAggregateDataTypes,
                                            });
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </Stack>

                    <Stack horizontal>
                        <SearchBox
                            placeholder={localization.Search}
                            value={actualSearchValue ?? ""}
                            onChange={(e, newValue) => {
                                const actualNewValue =
                                    newValue === undefined || newValue.trim().length === 0
                                        ? undefined
                                        : newValue.trim();
                                setSearchValue(actualNewValue);

                                props.onSearchValueChanged && props.onSearchValueChanged(actualNewValue);
                                resetPagination();
                            }}
                            styles={{
                                root: {
                                    width: 200,
                                    backgroundColor: theme.palette.neutralLighterAlt,
                                    borderColor: theme.palette.neutralQuaternary,
                                },
                            }}
                        />
                    </Stack>
                </Stack>
            )}

            <ScrollablePane styles={{ root: { position: "relative", flex: 1 } }} id={scrollablePaneIdRef.current}>
                {props.selectionMode === SelectionMode.multiple || props.selectionMode === undefined ? (
                    <MarqueeSelection selection={selection}>{renderDetailsList()}</MarqueeSelection>
                ) : (
                    renderDetailsList()
                )}
            </ScrollablePane>

            {props.data !== undefined &&
                actualPagination !== undefined &&
                props.disablePagination !== false &&
                (isCompactPagination ? (
                    <Stack
                        horizontalAlign="center"
                        verticalAlign="center"
                        tokens={{
                            childrenGap: 8,
                        }}
                        styles={{
                            root: {
                                minHeight: 74,
                                maxHeight: 74,
                                backgroundColor: theme.palette.white,
                                borderTopWidth: 1,
                                borderTopStyle: "solid",
                                borderTopColor: theme.palette.neutralLight,
                                paddingLeft: 20,
                                paddingRight: 20,
                            },
                        }}
                    >
                        {items === undefined ? (
                            <Spinner size={SpinnerSize.small} />
                        ) : (
                            <InCorePagination
                                currentPage={actualPagination.PageIndex + 1}
                                dataCount={itemsCount ?? items.length}
                                pageCount={actualPagination.ItemsPerPage}
                                onPageSelected={(newPage) => {
                                    const newPagination: Pagination = {
                                        ...actualPagination,
                                        PageIndex: newPage - 1,
                                    };

                                    selection.setAllSelected(false);
                                    setPagination(newPagination);
                                    setPaginationInputPage(newPage);
                                    setCollapsedGroups([]);

                                    props.onPaginationChanged && props.onPaginationChanged(newPagination);
                                }}
                            />
                        )}

                        <IconButton
                            iconProps={{ iconName: "More" }}
                            styles={{ root: { height: 16 } }}
                            onClick={() => {
                                toggleIsPaginationMorePanelVisible();
                            }}
                        />

                        <Panel
                            isOpen={isPaginationMorePanelVisible}
                            onDismiss={() => {
                                toggleIsPaginationMorePanelVisible(false);
                            }}
                        >
                            {isPaginationMorePanelVisible && (
                                <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                                    {items === undefined ? (
                                        <Spinner size={SpinnerSize.small} />
                                    ) : (
                                        <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                                            <Text variant="small">
                                                {actualPagination.PageIndex * actualPagination.ItemsPerPage + 1 >
                                                (itemsCount ?? items.length) ? (
                                                    localization.DataTable.NoData
                                                ) : (
                                                    <>
                                                        {localization.DataTable.NumberOfItems}{" "}
                                                        {actualPagination.PageIndex * actualPagination.ItemsPerPage + 1}
                                                        -
                                                        {Math.min(
                                                            (actualPagination.PageIndex + 1) *
                                                                actualPagination.ItemsPerPage,
                                                            itemsCount ?? items.length,
                                                        )}{" "}
                                                        {localization.DataTable.OutOf} {itemsCount ?? items.length}
                                                    </>
                                                )}
                                            </Text>

                                            {selectedKeys.length > 0 && (
                                                <Text variant="small">
                                                    {localization.DataTable.NumberOfItemsSelected}{" "}
                                                    <b style={{ color: theme.palette.themePrimary }}>
                                                        {selectedKeys.length}
                                                    </b>
                                                </Text>
                                            )}
                                        </Stack>
                                    )}

                                    <Separator />

                                    <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
                                        <Stack
                                            horizontal
                                            verticalAlign="center"
                                            tokens={{ childrenGap: theme.spacing.s2 }}
                                        >
                                            <NumericField
                                                label={localization.DataTable.PaginationNumberOfItems}
                                                value={paginationInputCount}
                                                onChange={(_, newValue) => {
                                                    setPaginationInputCount(newValue ?? undefined);
                                                }}
                                                styles={{
                                                    root: { flex: 1 },
                                                }}
                                                disabled={items === undefined}
                                                hideClear
                                                onKeyUp={(e) => {
                                                    if (e.code === "Enter") {
                                                        onPaginationSubmit();
                                                    }
                                                }}
                                            />

                                            <NumericField
                                                label={localization.DataTable.PaginationPage}
                                                value={paginationInputPage}
                                                onChange={(_, newValue) => {
                                                    setPaginationInputPage(newValue ?? undefined);
                                                }}
                                                styles={{
                                                    root: { flex: 1 },
                                                }}
                                                disabled={items === undefined}
                                                hideClear
                                                onKeyUp={(e) => {
                                                    if (e.code === "Enter") {
                                                        onPaginationSubmit();
                                                    }
                                                }}
                                            />
                                        </Stack>

                                        <Stack horizontal>
                                            <PrimaryButton
                                                styles={{
                                                    root: {
                                                        minWidth: "auto",
                                                        height: 29,
                                                        padding: "0 8px",
                                                    },
                                                }}
                                                disabled={items === undefined}
                                                onClick={onPaginationSubmit}
                                            >
                                                {localization.DataTable.PaginationGo}
                                            </PrimaryButton>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )}
                        </Panel>
                    </Stack>
                ) : (
                    <Stack
                        horizontal
                        horizontalAlign={"space-between"}
                        verticalAlign={"center"}
                        tokens={{
                            childrenGap: 15,
                        }}
                        styles={{
                            root: {
                                minHeight: 50,
                                maxHeight: 50,
                                backgroundColor: theme.palette.white,
                                borderTopWidth: 1,
                                borderTopStyle: "solid",
                                borderTopColor: theme.palette.neutralLight,
                                paddingLeft: 20,
                                paddingRight: 20,
                            },
                        }}
                    >
                        {items === undefined ? (
                            <Spinner size={SpinnerSize.small} />
                        ) : (
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                                <Text variant="small">
                                    {actualPagination.PageIndex * actualPagination.ItemsPerPage + 1 >
                                    (itemsCount ?? items.length) ? (
                                        localization.DataTable.NoData
                                    ) : (
                                        <>
                                            {localization.DataTable.NumberOfItems}{" "}
                                            {actualPagination.PageIndex * actualPagination.ItemsPerPage + 1}-
                                            {Math.min(
                                                (actualPagination.PageIndex + 1) * actualPagination.ItemsPerPage,
                                                itemsCount ?? items.length,
                                            )}{" "}
                                            {localization.DataTable.OutOf} {itemsCount ?? items.length}
                                        </>
                                    )}
                                </Text>

                                {selectedKeys.length > 0 && (
                                    <>
                                        <Separator vertical />

                                        <Text variant="small">
                                            {localization.DataTable.NumberOfItemsSelected}{" "}
                                            <b style={{ color: theme.palette.themePrimary }}>{selectedKeys.length}</b>
                                        </Text>
                                    </>
                                )}
                            </Stack>
                        )}

                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                            {items === undefined ? (
                                <Spinner size={SpinnerSize.small} />
                            ) : (
                                <InCorePagination
                                    currentPage={actualPagination.PageIndex + 1}
                                    dataCount={itemsCount ?? items.length}
                                    pageCount={actualPagination.ItemsPerPage}
                                    onPageSelected={(newPage) => {
                                        const newPagination: Pagination = {
                                            ...actualPagination,
                                            PageIndex: newPage - 1,
                                        };

                                        selection.setAllSelected(false);
                                        setPagination(newPagination);
                                        setPaginationInputPage(newPage);
                                        setCollapsedGroups([]);

                                        props.onPaginationChanged && props.onPaginationChanged(newPagination);
                                    }}
                                />
                            )}

                            <Separator vertical />

                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                                <Label styles={{ root: { fontWeight: 400, fontSize: 12 } }}>
                                    {localization.DataTable.PaginationNumberOfItems}:
                                </Label>

                                <NumericField
                                    value={paginationInputCount}
                                    onChange={(_, newValue) => {
                                        setPaginationInputCount(newValue ?? undefined);
                                    }}
                                    styles={{
                                        root: { marginRight: 8, width: 70 },
                                        fieldGroup: { height: "auto" },
                                        field: { padding: "4px 8px" },
                                    }}
                                    disabled={items === undefined}
                                    hideClear
                                    onKeyUp={(e) => {
                                        if (e.code === "Enter") {
                                            onPaginationSubmit();
                                        }
                                    }}
                                />

                                <Label styles={{ root: { fontWeight: 400, fontSize: 12 } }}>
                                    {localization.DataTable.PaginationPage}:
                                </Label>

                                <NumericField
                                    value={paginationInputPage}
                                    onChange={(_, newValue) => {
                                        setPaginationInputPage(newValue ?? undefined);
                                    }}
                                    styles={{
                                        root: { marginRight: 8, width: 70 },
                                        fieldGroup: { height: "auto" },
                                        field: { padding: "4px 8px" },
                                    }}
                                    disabled={items === undefined}
                                    hideClear
                                    onKeyUp={(e) => {
                                        if (e.code === "Enter") {
                                            onPaginationSubmit();
                                        }
                                    }}
                                />

                                <PrimaryButton
                                    styles={{
                                        root: {
                                            minWidth: "auto",
                                            height: 29,
                                            padding: "0 8px",
                                        },
                                    }}
                                    disabled={items === undefined}
                                    onClick={onPaginationSubmit}
                                >
                                    {localization.DataTable.PaginationGo}
                                </PrimaryButton>
                            </Stack>
                        </Stack>
                    </Stack>
                ))}
        </div>
    );
};

export default DataTable;

const renderDetailsHeader = (
    props: IDetailsHeaderProps,
    defaultRender: (props?: IDetailsHeaderProps | undefined) => JSX.Element | null,
    isFilterable: boolean,
    theme: Theme,
) => {
    if (!props) {
        return null;
    }

    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
                ...props,
                styles: {
                    root: {
                        padding: 0,
                        height: isFilterable ? 84 : undefined,
                        backgroundColor: theme.palette.neutralLighter,
                    },
                },
            })}
        </Sticky>
    );
};

interface GetInMemoryDataRequest<T extends object = any> extends GetDataRequest {
    initialItems: T[];
    columns: IColumn[];
}

const getInMemoryResult = <T extends object = any>(request: GetInMemoryDataRequest<T>): GetDataResponse => {
    const initialItems = request.initialItems;
    const columns = request.columns;
    const group = request.Group;
    const sort = request.Sort;
    const additionalFilter = request.AdditionalFilter;
    const requestSearch = request.Search;
    const filter = request.Filter;
    const selector = request.Selector;
    const pagination = request.Pagination;
    const aggregateData = request.AggregateData;

    let items = [...initialItems];

    if (additionalFilter !== undefined) {
        items = items.filter((x) => {
            return doesItemSatisfyFilter(x, additionalFilter, columns);
        });
    }

    if (requestSearch !== undefined) {
        const inMemorySearchOptions: Record<string | number | symbol, Partial<SearchOptions<any>>> = {};
        requestSearch.SearchProperties.forEach((x) => {
            inMemorySearchOptions[x] = {};
        });
        items = search(items, requestSearch.SearchTerm, inMemorySearchOptions as any);
    }

    if (filter !== undefined && requestSearch === undefined) {
        items = items.filter((x) => {
            return doesItemSatisfyFilter(x, filter, columns);
        });
    }

    const sortProperties: [string, boolean][] = []; // Id, IsDescending

    let currentGroup = group;
    while (currentGroup) {
        sortProperties.push([currentGroup.Id, false]);
        currentGroup = currentGroup.NestedGroup;
    }

    let currentSort = sort;
    while (currentSort) {
        sortProperties.push([currentSort.Id, currentSort.IsDescending]);
        currentSort = currentSort.NestedSort;
    }

    if (sortProperties.length > 0) {
        items = items.sort((a, b) => {
            for (let i = 0; i < sortProperties.length; i++) {
                const [id, isDescending] = sortProperties[i];

                const sortColumnData = columns.find((x) => {
                    return x.key === id;
                })?.data as IDataTableColumn<T> | undefined;

                const aValue = sortColumnData?.getValue ? sortColumnData.getValue(a) : a[id as keyof T];
                const bValue = sortColumnData?.getValue ? sortColumnData.getValue(b) : b[id as keyof T];

                const isAEmpty = aValue === undefined || aValue === null;
                const isBEmpty = bValue === undefined || bValue === null;

                if (isAEmpty && isBEmpty) {
                    continue;
                }

                if (isAEmpty) {
                    return isDescending ? -1 : 1;
                }

                if (isBEmpty) {
                    return isDescending ? 1 : -1;
                }

                const comparisonResult =
                    typeof aValue === "string" && typeof bValue === "string"
                        ? aValue.localeCompare(bValue)
                        : aValue < bValue
                        ? -1
                        : aValue > bValue
                        ? 1
                        : 0;

                if (comparisonResult === 0) {
                    continue;
                }

                return isDescending ? -comparisonResult : comparisonResult;
            }

            return 0;
        });
    }

    const count = items.length;

    if (pagination !== undefined) {
        const startIndex = pagination.PageIndex * pagination.ItemsPerPage;
        const endIndex = startIndex + pagination.ItemsPerPage;
        items = items.slice(Math.min(startIndex, items.length), Math.min(endIndex, items.length));
    }

    return {
        Data: items,
        Count: count,
        GroupResults: getGroupResults(group, aggregateData, items) ?? [],
        AggregateDataResults: getAggregateDataResults(aggregateData, items) ?? [],
    };
};

const doesItemSatisfyFilter = <T extends object = any>(item: T, filter: Filter, columns: IColumn[]): boolean => {
    let result = true;

    if (filter.Value) {
        result = doesItemSatisfyFilterValue(item, filter.Value, columns);
    } else if (filter.Group) {
        result = doesItemSatisfyFilterGroup(item, filter.Group, columns);
    }

    return filter.IsNegated ? !result : result;
};

const doesItemSatisfyFilterGroup = <T extends object = any>(
    item: T,
    filterGroup: FilterGroup,
    columns: IColumn[],
): boolean => {
    if (filterGroup.Filters.length === 0) {
        return true;
    }
    let result = filterGroup.Operator === FilterGroupOperator.And ? true : false;
    filterGroup.Filters.forEach((x) => {
        const filterResult = doesItemSatisfyFilter(item, x, columns);
        result = filterGroup.Operator === FilterGroupOperator.And ? result && filterResult : result || filterResult;
    });
    return result;
};

const doesItemSatisfyFilterValue = <T extends object = any>(
    item: T,
    filterValue: FilterValue,
    columns: IColumn[],
): boolean => {
    const leftOperandId = filterValue.LeftOperand?.Id;

    const leftOperandColumn = leftOperandId
        ? (columns.find((x) => {
              return x.key === leftOperandId;
          })?.data as IDataTableColumn<T> | undefined)
        : undefined;

    const leftOperandValue = leftOperandColumn?.getValue
        ? leftOperandColumn.getValue(item)
        : getFilterValueOperandValue(item, filterValue.LeftOperand);

    const rightOperandId = filterValue.RightOperand?.Id;

    const rightOperandColumn = rightOperandId
        ? (columns.find((x) => {
              return x.key === rightOperandId;
          })?.data as IDataTableColumn<T> | undefined)
        : undefined;

    const rightOperandValue = rightOperandColumn?.getValue
        ? rightOperandColumn.getValue(item)
        : filterValue.RightOperand !== undefined
        ? getFilterValueOperandValue(item, filterValue.RightOperand)
        : undefined;

    switch (filterValue.Operator) {
        case FilterValueOperator.Equal:
            return leftOperandValue === rightOperandValue;
        case FilterValueOperator.NotEqual:
            return leftOperandValue !== rightOperandValue;
        case FilterValueOperator.GreaterThan:
            return leftOperandValue > rightOperandValue;
        case FilterValueOperator.GreaterThanOrEqual:
            return leftOperandValue >= rightOperandValue;
        case FilterValueOperator.LessThan:
            return leftOperandValue < rightOperandValue;
        case FilterValueOperator.LessThanOrEqual:
            return leftOperandValue <= rightOperandValue;
        case FilterValueOperator.IsEmpty:
            return (
                leftOperandValue === null ||
                leftOperandValue === undefined ||
                (typeof leftOperandValue === "string" && leftOperandValue.trim() === "")
            );
        case FilterValueOperator.IsNotEmpty:
            return (
                leftOperandValue !== null &&
                leftOperandValue !== undefined &&
                (typeof leftOperandValue === "string" ? leftOperandValue.trim() === "" : true)
            );
        case FilterValueOperator.Contains:
            return (
                typeof leftOperandValue === "string" &&
                leftOperandValue.toLowerCase().includes(rightOperandValue?.toString().toLowerCase())
            );
        case FilterValueOperator.NotContains:
            return (
                typeof leftOperandValue === "string" &&
                !leftOperandValue.toLowerCase().includes(rightOperandValue?.toString().toLowerCase())
            );
        case FilterValueOperator.StartsWith:
            return (
                typeof leftOperandValue === "string" &&
                leftOperandValue.toLowerCase().startsWith(rightOperandValue?.toString().toLowerCase())
            );
        case FilterValueOperator.NotStartsWith:
            return (
                typeof leftOperandValue === "string" &&
                !leftOperandValue.toLowerCase().startsWith(rightOperandValue?.toString().toLowerCase())
            );
        case FilterValueOperator.EndsWith:
            return (
                typeof leftOperandValue === "string" &&
                leftOperandValue.toLowerCase().endsWith(rightOperandValue?.toString().toLowerCase())
            );
        case FilterValueOperator.NotEndsWith:
            return (
                typeof leftOperandValue === "string" &&
                !leftOperandValue.toLowerCase().endsWith(rightOperandValue?.toString().toLowerCase())
            );
        default:
            return true;
    }
};

const getFilterValueOperandValue = <T extends object = any>(item: T, filterValueOperand: FilterValueOperand) => {
    if (filterValueOperand.Id !== undefined) {
        return item !== undefined && item !== null ? (item as any)[filterValueOperand.Id] : undefined;
    }

    if (filterValueOperand.Value !== undefined && filterValueOperand !== null) {
        return filterValueOperand.Value;
    }

    return undefined;
};

const getGroupResults = (
    group: Group | undefined,
    aggregateData: AggregateData | undefined,
    data: any[],
): GroupResult[] | undefined => {
    if (!group) {
        return undefined;
    }

    const groupedData = Enumerable.from(data).groupBy((x) => {
        return getValue(x, group.Id);
    });

    const result: GroupResult[] = [];
    var startIndex = 0;

    groupedData.forEach((groupedDataSegment) => {
        result.push({
            StartIndex: startIndex,
            Count: groupedDataSegment.count(),
            AggregateDataResults: getAggregateDataResults(aggregateData, groupedDataSegment.toArray()),
            NestedGroupResults: getGroupResults(group.NestedGroup, aggregateData, groupedDataSegment.toArray())?.map(
                (x) => {
                    return {
                        ...x,
                        StartIndex: x.StartIndex + startIndex,
                    };
                },
            ),
        });

        startIndex += groupedDataSegment.count();
    });

    return result;
};

const getAggregateDataResults = (
    aggregateData: AggregateData | undefined,
    data: any[],
): AggregateDataResult[] | undefined => {
    if (!aggregateData) {
        return undefined;
    }

    return aggregateData.Items.flatMap((aggregateDataItem) => {
        return aggregateDataItem.AggregateDataTypes.map((aggregateDataType) => {
            const propertyData = data
                .map((x) => {
                    return getValue(x, aggregateDataItem.Id);
                })
                .filter((x) => {
                    return x !== undefined && x !== null;
                });

            let value;
            switch (aggregateDataType) {
                case AggregateDataType.CountEmpty:
                    value = data.length - propertyData.length;
                    break;
                case AggregateDataType.CountNonEmpty:
                    value = propertyData.length;
                    break;
                case AggregateDataType.PercentEmpty:
                    value = data.length === 0 ? 0 : ((data.length - propertyData.length) * 100) / data.length;
                    break;
                case AggregateDataType.PercentNonEmpty:
                    value = data.length === 0 ? 0 : (propertyData.length * 100) / data.length;
                    break;
                case AggregateDataType.Sum:
                    value =
                        propertyData.length === 0
                            ? 0
                            : propertyData
                                  .map((x) => {
                                      return parseFloat(x);
                                  })
                                  .reduce((a, b) => {
                                      return a + b;
                                  });
                    break;
                case AggregateDataType.Average:
                    value =
                        propertyData.length === 0
                            ? 0
                            : propertyData
                                  .map((x) => {
                                      return parseFloat(x);
                                  })
                                  .reduce((a, b) => {
                                      return a + b;
                                  }) / propertyData.length;
                    break;
                case AggregateDataType.Minimum:
                    value = propertyData.length === 0 ? undefined : Math.min(...propertyData);
                    break;
                case AggregateDataType.Maximum:
                    value = propertyData.length === 0 ? undefined : Math.max(...propertyData);
                    break;
                case AggregateDataType.CountTrue:
                    value = propertyData.filter((x) => {
                        return x === true;
                    }).length;
                    break;
                case AggregateDataType.CountFalse:
                    value = propertyData.filter((x) => {
                        return x === false;
                    }).length;
                    break;
                case AggregateDataType.PercentTrue:
                    value =
                        data.length === 0
                            ? 0
                            : (propertyData.filter((x) => {
                                  return x === true;
                              }).length *
                                  100) /
                              data.length;
                    break;
                case AggregateDataType.PercentFalse:
                    value =
                        (propertyData.filter((x) => {
                            return x === false;
                        }).length *
                            100) /
                        data.length;
                    break;
                default:
                    throw new Error("AggregateDataType not implemented.");
            }

            let stringValue;
            if (value === undefined) {
                stringValue = "";
            } else {
                switch (aggregateDataType) {
                    case AggregateDataType.CountEmpty:
                        stringValue = value.toString();
                        break;
                    case AggregateDataType.CountNonEmpty:
                        stringValue = value.toString();
                        break;
                    case AggregateDataType.PercentEmpty:
                        stringValue = `${value.toFixed(2)}%`;
                        break;
                    case AggregateDataType.PercentNonEmpty:
                        stringValue = `${value.toFixed(2)}%`;
                        break;
                    case AggregateDataType.Sum:
                        stringValue = value.toString();
                        break;
                    case AggregateDataType.Average:
                        stringValue = value.toString();
                        break;
                    case AggregateDataType.Minimum:
                        stringValue = value.toString();
                        break;
                    case AggregateDataType.Maximum:
                        stringValue = value.toString();
                        break;
                    case AggregateDataType.CountTrue:
                        stringValue = value.toString();
                        break;
                    case AggregateDataType.CountFalse:
                        stringValue = value.toString();
                        break;
                    case AggregateDataType.PercentTrue:
                        stringValue = `${value.toFixed(2)}%`;
                        break;
                    case AggregateDataType.PercentFalse:
                        stringValue = `${value.toFixed(2)}%`;
                        break;
                    default:
                        throw new Error("AggregateDataType not implemented.");
                }
            }

            const result: AggregateDataResult = {
                Id: aggregateDataItem.Id,
                AggregateDataType: aggregateDataType,
                Value: value,
                StringValue: stringValue,
            };

            return result;
        });
    });
};

const getGroups = (
    groupResults: GroupResult[] | undefined,
    group: Group | undefined,
    items: any[],
    collapsedGroups: string[],
    columns: IDataTableColumn[],
    lastGroupHasMoreElements: boolean,
    otherText: string,
    parentIndexPath: number[] = [],
): IGroup[] | undefined => {
    if (!groupResults || !group) {
        return undefined;
    }

    const result: IGroup[] = [];

    const column = columns.find((x) => {
        return x.id === group.Id;
    });

    groupResults.forEach((groupResult, i) => {
        const groupIndexPath = [...parentIndexPath, i];

        const item = items[groupResult.StartIndex];

        const value = column
            ? column.getValue
                ? column.getValue(item)
                : getValue(item, (column.groupId ?? column.property ?? column.id).toString())
            : (item as any)[group.Id];

        const groupKey = `__group${groupIndexPath
            .map((x) => {
                return `_${x}`;
            })
            .join("")}_${value?.toString() ?? "other"}`;
        const groupValue = value === undefined || value === null ? otherText : value;
        const groupName = column ? `${column.name}: ${groupValue}` : groupValue;

        result.push({
            key: groupKey,
            name: groupName,
            startIndex: groupResult.StartIndex,
            count: groupResult.Count,
            isCollapsed: collapsedGroups.includes(groupKey),
            level: parentIndexPath.length,
            children: getGroups(
                groupResult.NestedGroupResults,
                group.NestedGroup,
                items,
                collapsedGroups,
                columns,
                lastGroupHasMoreElements,
                otherText,
                groupIndexPath,
            ),
            data: {
                groupResult: groupResult,
                group: group,
            },
        });
    });

    if (
        lastGroupHasMoreElements &&
        result.length > 0 &&
        result[result.length - 1].startIndex + result[result.length - 1].count === items.length
    ) {
        result[result.length - 1].hasMoreData = true;
    }

    return result;
};

const getMaxGroupLevel = (groups: IGroup[] | undefined): number => {
    if (!groups || groups.length === 0) {
        return 0;
    }

    const nestedMaxGroupLevels = groups.map((x) => {
        return getMaxGroupLevel(x.children);
    });

    return Math.max(...nestedMaxGroupLevels) + 1;
};

const getValue = (item: any, path: string) => {
    const segments = path.split(".");
    let value = item as any;
    segments.forEach((x) => {
        value = value === undefined ? value : value[x];
    });
    return value;
};

const getFixedSort = (sort: Sort | undefined, columns: IDataTableColumn[]): Sort | undefined => {
    if (!sort) {
        return undefined;
    }

    const column = columns.find((x) => {
        return x.id === sort.Id;
    });

    return {
        Id: column ? column.sortId ?? column.id : sort.Id,
        IsDescending: sort.IsDescending,
        NestedSort: getFixedSort(sort.NestedSort, columns),
    };
};

const getFixedGroup = (group: Group | undefined, columns: IDataTableColumn[]): Group | undefined => {
    if (!group) {
        return undefined;
    }

    const column = columns.find((x) => {
        return x.id === group.Id;
    });

    return {
        Id: column ? column.sortId ?? column.id : group.Id,
        NestedGroup: getFixedGroup(group.NestedGroup, columns),
    };
};

const cloneAggregateDataTypes = (aggregateDataTypes: Record<string, AggregateDataType[]>) => {
    const result: Record<string, AggregateDataType[]> = {};

    Object.keys(aggregateDataTypes).forEach((x) => {
        result[x] = [...aggregateDataTypes[x]];
    });

    return result;
};

const getAggregateDataTypeHeaderText = (aggregateDataType: AggregateDataType, localization: IInCoreLocalization) => {
    switch (aggregateDataType) {
        case AggregateDataType.CountEmpty:
            return localization.DataTable.CountEmpty;
        case AggregateDataType.CountNonEmpty:
            return localization.DataTable.CountNonEmpty;
        case AggregateDataType.PercentEmpty:
            return localization.DataTable.PercentEmpty;
        case AggregateDataType.PercentNonEmpty:
            return localization.DataTable.PercentNonEmpty;
        case AggregateDataType.Sum:
            return localization.DataTable.Sum;
        case AggregateDataType.Average:
            return localization.DataTable.Average;
        case AggregateDataType.Minimum:
            return localization.DataTable.Minimum;
        case AggregateDataType.Maximum:
            return localization.DataTable.Maximum;
        case AggregateDataType.CountTrue:
            return localization.DataTable.CountTrue;
        case AggregateDataType.CountFalse:
            return localization.DataTable.CountFalse;
        case AggregateDataType.PercentTrue:
            return localization.DataTable.PercentTrue;
        case AggregateDataType.PercentFalse:
            return localization.DataTable.PercentFalse;
        default:
            return "";
    }
};

const getAggregateDataTypeAddText = (aggregateDataType: AggregateDataType, localization: IInCoreLocalization) => {
    switch (aggregateDataType) {
        case AggregateDataType.CountEmpty:
            return localization.DataTable.AddCountEmpty;
        case AggregateDataType.CountNonEmpty:
            return localization.DataTable.AddCountNonEmpty;
        case AggregateDataType.PercentEmpty:
            return localization.DataTable.AddPercentEmpty;
        case AggregateDataType.PercentNonEmpty:
            return localization.DataTable.AddPercentNonEmpty;
        case AggregateDataType.Sum:
            return localization.DataTable.AddSum;
        case AggregateDataType.Average:
            return localization.DataTable.AddAverage;
        case AggregateDataType.Minimum:
            return localization.DataTable.AddMinimum;
        case AggregateDataType.Maximum:
            return localization.DataTable.AddMaximum;
        case AggregateDataType.CountTrue:
            return localization.DataTable.AddCountTrue;
        case AggregateDataType.CountFalse:
            return localization.DataTable.AddCountFalse;
        case AggregateDataType.PercentTrue:
            return localization.DataTable.AddPercentTrue;
        case AggregateDataType.PercentFalse:
            return localization.DataTable.AddPercentFalse;
        default:
            return "";
    }
};
