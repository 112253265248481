import { InCoreContext, useInCoreContext } from "@in-core";

export interface ISettingService
    extends Pick<
        InCoreContext,
        "getSettingValue" | "getCastedSettingValue" | "getSystemSettingValue" | "getCastedSystemSettingValue"
    > {}

export const useSettingService = () => {
    const { getSettingValue, getCastedSettingValue, getSystemSettingValue, getCastedSystemSettingValue } =
        useInCoreContext();

    return {
        getSettingValue,
        getCastedSettingValue,
        getSystemSettingValue,
        getCastedSystemSettingValue,
    };
};
