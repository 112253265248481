import { EntityDataTableDropdown, IEntityDataTableDropdownProps } from "@in-core";
import { FormValue } from "..";

export interface IFormEntityDataTableDropdownProps
    extends Omit<IEntityDataTableDropdownProps, "selectedKey" | "selectedKeys"> {
    value: FormValue<any>;
    ignoreDirty?: boolean;
    keyMapping?: {
        keyToSourceKey: (key: any) => any;
        sourceKeyToKey: (sourceKey: any) => any;
    };
}

const FormEntityDataTableDropdown = (props: IFormEntityDataTableDropdownProps) => {
    const getSourceKey = (key: any) => {
        if (key === undefined || key === null) {
            return null;
        }

        return props.keyMapping === undefined ? key : props.keyMapping.keyToSourceKey(key);
    };

    const getKey = (key: any) => {
        if (key === undefined || key === null) {
            return null;
        }

        return props.keyMapping === undefined ? key : props.keyMapping.sourceKeyToKey(key);
    };

    return (
        <EntityDataTableDropdown
            {...props}
            selectedKey={props.multiSelect ? undefined : getSourceKey(props.value.$value)}
            selectedKeys={props.multiSelect ? props.value.$value?.map(getSourceKey) ?? null : undefined}
            onChange={(changes) => {
                if (!props.multiSelect) {
                    const selectedKey = changes.find((x) => {
                        return x.isSelected;
                    })?.key;

                    props.value.$setValue(selectedKey === undefined ? undefined : getKey(selectedKey));
                } else {
                    props.value.$setValue([
                        ...(Array.isArray(props.value.$value) ? props.value.$value : []).filter((x) => {
                            return !changes
                                .filter((y) => {
                                    return !y.isSelected;
                                })
                                .map((y) => {
                                    return getKey(y.key);
                                })
                                .includes(x);
                        }),
                        ...changes
                            .filter((x) => {
                                return x.isSelected;
                            })
                            .map((x) => {
                                return getKey(x.key);
                            }),
                    ]);
                }

                props.onChange && props.onChange(changes);
            }}
            errorMessage={
                (!!props.ignoreDirty ? true : props.value.$isDirty) &&
                !props.value.$isPendingValidation &&
                !props.value.$isValidating &&
                props.value.$errors.length > 0
                    ? props.value.$errors.join(" ")
                    : undefined
            }
        />
    );
};

export default FormEntityDataTableDropdown;
