import { ITextFieldProps, Spinner, SpinnerSize, TextField, useTheme } from "@in-core";
import { FormValue } from "..";

export interface IFormTextFieldProps extends Omit<ITextFieldProps, "value"> {
    value: FormValue<string>;
    ignoreDirty?: boolean;
}

const FormTextField = (props: IFormTextFieldProps) => {
    const theme = useTheme();

    const defaultRenderInput = (
        defaultRender:
            | ((
                  props?:
                      | (React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>)
                      | undefined,
              ) => JSX.Element | null)
            | undefined,
    ) => {
        return (
            renderProps?:
                | (React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>)
                | undefined,
        ) => {
            return (
                <>
                    {defaultRender!(renderProps)}

                    {props.value.$isValidating && (
                        <Spinner
                            size={SpinnerSize.small}
                            styles={{ root: { marginLeft: theme.spacing.s2, marginRight: theme.spacing.s2 } }}
                        />
                    )}
                </>
            );
        };
    };

    return (
        <TextField
            {...props}
            value={props.value.$value ?? ""}
            onChange={(e, newValue) => {
                const actualNewValue = newValue === undefined || newValue === "" ? undefined : newValue;
                props.value.$setValue(actualNewValue);
                props.onChange && props.onChange(e, newValue);
            }}
            onRenderInput={(renderProps, defaultRender) => {
                return props.onRenderInput
                    ? props.onRenderInput(renderProps, defaultRenderInput(defaultRender))
                    : defaultRenderInput(defaultRender)(renderProps);
            }}
            errorMessage={
                (!!props.ignoreDirty ? true : props.value.$isDirty) &&
                !props.value.$isPendingValidation &&
                !props.value.$isValidating &&
                props.value.$errors.length > 0
                    ? props.value.$errors.join(" ")
                    : undefined
            }
        />
    );
};

export default FormTextField;
