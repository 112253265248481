import { DirectoryContent, callApi as callGenericApi, useApi as useGenericApi } from "@in-core";
import { NewContentInfo } from "..";

export namespace GetEntityList {
    export const URL = "CoreAPI/Documents/Entity/GetEntityList";

    export interface Request {}

    export const callApi = (request: Request) => {
        return callGenericApi<Request, DirectoryContent>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, DirectoryContent>(URL);
    };
}

export namespace GetEntityObjectList {
    export const URL = "CoreAPI/Documents/Entity/GetEntityObjectList";

    export interface Request {
        Entity: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, DirectoryContent>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, DirectoryContent>(URL);
    };
}

export namespace GetDirectoryContent {
    export const URL = "CoreAPI/Documents/Entity/GetDirectoryContent";

    export interface Request {
        Entity: string;
        KeyString: string;
        DirectoryPath: string[];
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, DirectoryContent>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, DirectoryContent>(URL);
    };
}

export namespace GetFilePreview {
    export const URL = "CoreAPI/Documents/Entity/GetFilePreview";

    export interface Request {
        Entity: string;
        KeyString: string;
        DirectoryPath: string[];
        FileName: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, string>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, string>(URL);
    };
}

export namespace IsFile {
    export const URL = "CoreAPI/Documents/Entity/IsFile";

    export interface Request {
        Entity: string;
        KeyString: string;
        Path: string[];
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, boolean>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, boolean>(URL);
    };
}

export namespace UploadContent {
    export const URL = "CoreAPI/Documents/Entity/UploadContent";

    export interface Request {
        Entity: string;
        KeyString: string;
        DirectoryPath: string[];
        NewContentInfo: NewContentInfo;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, void>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, void>(URL);
    };
}

export namespace Rename {
    export const URL = "CoreAPI/Documents/Entity/Rename";

    export interface Request {
        Entity: string;
        KeyString: string;
        Path: string[];
        NewName: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, void>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, void>(URL);
    };
}

export namespace Delete {
    export const URL = "CoreAPI/Documents/Entity/Delete";

    export interface Request {
        Entity: string;
        KeyString: string;
        Path: string[];
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, void>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, void>(URL);
    };
}

export namespace Download {
    export const URL = "CoreAPI/Documents/Entity/Download";

    export interface Request {
        Entity: string;
        KeyString: string;
        Path: string[];
        ItemsToAdd?: string[];
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, string>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, string>(URL);
    };
}
