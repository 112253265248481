import { PrimaryButton, Setting, Spinner, Stack, useInCoreLocalization, useOnChange, useTheme } from "@in-core";
import { Read } from "@in-core/api/Settings";
import SettingValueEditor from "@in-core/ui/Settings/SettingValueEditor";

export interface IUserSettingDetailsProps {
    setting: Setting;
    value?: string;
    onChange?: (newValue?: string) => void;
    onOk?: () => void;
    roles: any[];
}

const UserSettingDetails = (props: IUserSettingDetailsProps) => {
    const readApi = Read.useApi();
    const theme = useTheme();
    const localization = useInCoreLocalization();

    useOnChange(() => {
        readApi.call({ Id: props.setting.Id });
    }, [props.setting.Id]);

    if (!readApi.data) {
        return <Spinner />;
    }

    return (
        <Stack tokens={{ childrenGap: theme.spacing.l2 }}>
            <SettingValueEditor
                value={props.value}
                onChange={props.onChange}
                relevantSettingValues={[
                    { Id: "__system", Name: `${localization.Settings.System}:`, Value: readApi.data.SystemValue },
                    ...props.roles.map((x) => {
                        return {
                            Id: x.Id,
                            Name: `${localization.Settings.Role} ${x.Description}:`,
                            Value: readApi.data.RoleValues.find((y: any) => {
                                return y.RoleId === x.Id;
                            })?.Value,
                        };
                    }),
                ]}
            />

            <Stack horizontal>
                <PrimaryButton onClick={props.onOk}>{localization.Ok}</PrimaryButton>
            </Stack>
        </Stack>
    );
};

export default UserSettingDetails;
