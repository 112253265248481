import React from "react";
import { IStackProps, Label, Stack, ILabelStyles, IStackStyles, useTheme } from "@in-core";

export interface IFormSectionProps extends IStackProps {
    label?: string;
    labelStyles?: ILabelStyles;
    children?: React.ReactNode;
}

const InCoreFormSection = (props: IFormSectionProps) => {
    const theme = useTheme();
    return (
        <Stack
            {...props}
            tokens={{
                childrenGap: theme.spacing.s1,
                padding: theme.spacing.s1,
                ...props.tokens,
            }}
            styles={{
                ...props.styles,
                root: {
                    ...((props.styles as IStackStyles | undefined)?.root as any),
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: theme.palette.neutralLight,
                    borderRadius: theme.effects.roundedCorner4,
                    position: "relative",
                },
            }}
        >
            {props.label && (
                <Label
                    styles={{
                        ...props.labelStyles,
                        root: {
                            ...(props.labelStyles?.root as any),
                            position: "absolute",
                            left: theme.spacing.s1,
                            top: 0,
                            paddingLeft: theme.spacing.s1,
                            paddingRight: theme.spacing.s1,
                            transform: "translateY(-50%)",
                            backgroundColor: theme.palette.white,
                        },
                    }}
                >
                    {props.label}
                </Label>
            )}

            {props.children}
        </Stack>
    );
};

export default InCoreFormSection;
