import React from "react";
import {
    DefaultButton,
    IButtonProps,
    Icon,
    IStyle,
    PrimaryButton,
    Stack,
    Text,
    useElementSize,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import MultiStepContentItem, { IMultiStepContentItemProps } from "./MultiStepContentItem";
import MultiStepTab from "./MultiStepTab";

export const defaultMobileBreakpoint = 720;

export interface IMultiStepContentProps {
    selectedIndex: number;
    onSelectedIndexChanged: (index: number) => void;
    removeOrdinalNumbers?: boolean;
    removeCheckmark?: boolean;
    onFinish: () => void;
    finishDisabled?: boolean;
    children: React.ReactNode;
    rootStyle?: IStyle;
    onRenderNavigation?: (previousButtonProps: IButtonProps, nextButtonProps: IButtonProps) => JSX.Element;
    contentWrapperStyle?: (active: boolean) => React.CSSProperties;
    stickyTabsTopOffset?: number;
    mobileBreakpoint?: number;
    previousLabel?: string;
    nextLabel?: string;
    completeLabel?: string;
}

const MultiStepContent = (props: IMultiStepContentProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();
    const [containerRef, containerSize] = useElementSize();

    React.Children.forEach(props.children, (x) => {
        if ((x as JSX.Element)?.type !== MultiStepContentItem) {
            throw Error("");
        }
    });

    const childrenProps =
        React.Children.map(props.children, (x) => {
            return (x as JSX.Element).props as IMultiStepContentItemProps;
        }) ?? [];

    const previousButtonProps: IButtonProps = {
        disabled: childrenProps[props.selectedIndex].previousDisabled,
        onClick: async () => {
            const canGoToPrevious = childrenProps[props.selectedIndex].onPreviousClicked
                ? await childrenProps[props.selectedIndex].onPreviousClicked!()
                : true;

            if (canGoToPrevious) {
                props.onSelectedIndexChanged(props.selectedIndex - 1);
            }
        },
        children: props.previousLabel ?? localization.MultiStepContent.Back,
    };

    const nextButtonProps: IButtonProps = {
        onClick: async () => {
            if (React.Children.count(props.children) - 1 === props.selectedIndex) {
                props.onFinish();
                return;
            }

            const canGoToNext = childrenProps[props.selectedIndex].onNextClicked
                ? await childrenProps[props.selectedIndex].onNextClicked!()
                : true;

            if (canGoToNext) {
                props.onSelectedIndexChanged(props.selectedIndex + 1);
            }
        },
        disabled:
            React.Children.count(props.children) - 1 === props.selectedIndex
                ? props.finishDisabled
                : childrenProps[props.selectedIndex].nextDisabled,
        children:
            React.Children.count(props.children) - 1 === props.selectedIndex
                ? props.completeLabel ?? localization.MultiStepContent.Complete
                : props.nextLabel ?? localization.MultiStepContent.Next,
    };

    const isMobile = containerSize.width < (props.mobileBreakpoint ?? defaultMobileBreakpoint);

    return (
        <div
            ref={containerRef}
            style={{ display: "flex", flexDirection: "column", width: "auto", height: "auto", boxSizing: "border-box" }}
        >
            <Stack
                horizontal
                tokens={{ childrenGap: theme.spacing.s1 }}
                styles={
                    props.stickyTabsTopOffset === undefined
                        ? undefined
                        : {
                              root: {
                                  position: "sticky",
                                  top: props.stickyTabsTopOffset,
                                  zIndex: 999,
                                  backgroundColor: theme.palette.white,
                              },
                          }
                }
            >
                {React.Children.map(props.children, (x, i) => {
                    const childProps = (x as JSX.Element).props as IMultiStepContentItemProps;

                    return (
                        <MultiStepTab
                            key={i}
                            disabled={childProps.disabled}
                            index={i}
                            selectedIndex={props.selectedIndex}
                            isMobile={isMobile}
                            onClick={() => {
                                return props.onSelectedIndexChanged(i);
                            }}
                        >
                            <Text
                                styles={{
                                    root: {
                                        padding: theme.spacing.s1,
                                        color:
                                            i <= props.selectedIndex
                                                ? theme.palette.themePrimary
                                                : theme.palette.neutralTertiary,
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: i === props.selectedIndex ? 500 : undefined,
                                    },
                                }}
                                nowrap
                            >
                                {!props.removeCheckmark && i < props.selectedIndex && (
                                    <Icon iconName="CheckMark" styles={{ root: { marginRight: theme.spacing.s2 } }} />
                                )}

                                {!props.removeOrdinalNumbers &&
                                    (i >= props.selectedIndex || props.removeCheckmark) &&
                                    `${i + 1}. `}

                                {(!isMobile || props.selectedIndex === i) && childProps.text}
                            </Text>
                        </MultiStepTab>
                    );
                })}
            </Stack>

            {React.Children.map(props.children, (x, i) => {
                const childProps = (x as JSX.Element).props as IMultiStepContentItemProps;

                return (
                    <div
                        style={{
                            ...(props.contentWrapperStyle ? props.contentWrapperStyle(props.selectedIndex === i) : {}),
                            display: props.selectedIndex === i ? "block" : "none",
                        }}
                    >
                        {childProps.children}
                    </div>
                );
            })}

            {props.onRenderNavigation ? (
                props.onRenderNavigation(previousButtonProps, nextButtonProps)
            ) : (
                <Stack
                    horizontal
                    horizontalAlign="end"
                    tokens={{ childrenGap: theme.spacing.s1 }}
                    styles={{ root: { paddingTop: theme.spacing.l2 } }}
                >
                    <DefaultButton {...previousButtonProps} />

                    <PrimaryButton {...nextButtonProps} />
                </Stack>
            )}
        </div>
    );
};

export default MultiStepContent;
