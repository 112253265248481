import {
    DefaultButton,
    Group,
    IDataTableColumn,
    Label,
    PrimaryButton,
    Stack,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import { useState } from "react";
import GroupDropdown from "./GroupDropdown";

export interface IGroupFormProps<TData = any> {
    columns: IDataTableColumn<TData>[];
    initialGroup: Group | undefined;
    onGroupChanged: (newGroup: Group | undefined) => void;
    onDismiss: () => void;
}

const GroupForm = <TData extends object = any>(props: IGroupFormProps<TData>) => {
    const [group, setGroup] = useState<Group | undefined>(deepCloneGroup(props.initialGroup));
    const theme = useTheme();
    const localization = useInCoreLocalization();

    return (
        <Stack>
            <Label>{localization.DataTable.Grouping}</Label>

            <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                <GroupDropdown
                    columns={props.columns.filter((x) => {
                        return x.isGroupable !== false;
                    })}
                    group={group}
                    onGroupChanged={setGroup}
                />
            </Stack>

            <Stack
                horizontal
                styles={{
                    root: {
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: theme.palette.white,
                        zIndex: 1,
                        paddingTop: theme.spacing.m,
                    },
                }}
                tokens={{ childrenGap: theme.spacing.s1 }}
            >
                <PrimaryButton
                    onClick={() => {
                        props.onGroupChanged(group);
                        props.onDismiss();
                    }}
                >
                    {localization.Accept}
                </PrimaryButton>

                <DefaultButton onClick={props.onDismiss}>{localization.Cancel}</DefaultButton>
            </Stack>
        </Stack>
    );
};

export default GroupForm;

const deepCloneGroup = (group?: Group): Group | undefined => {
    if (!group) {
        return undefined;
    }

    return { Id: group.Id, NestedGroup: deepCloneGroup(group.NestedGroup) };
};
