import {
    AggregateDataType,
    Authorize,
    Filter,
    IDataTableColumn,
    LazyComponent,
    MessageBar,
    MessageBarType,
    Permission,
    Selector,
    Sort,
} from "@in-core";
import AutoEntityForm from "../AutoEntityForm";
import { ILazyComponentInfo } from "@in-core/components/LazyComponent";
import Documents from "../Documents";
import Export from "../Export";
import { Group, Pagination } from "@in-core/api";
import AutoDelete from "../AutoDelete";
import Activity from "../Activity";
import AuditLog from "../AuditLog";
import Notes from "../Notes";

export const getChildLazyComponents = (permission: Permission, innerPermission: Permission) => {
    if (innerPermission.Id === "Create" || innerPermission.Id === "Read" || innerPermission.Id === "Update") {
        return [
            ...(innerPermission.Metadata.UiPath
                ? [
                      {
                          path: innerPermission.Metadata.UiPath,
                          parameters: innerPermission.Metadata.UiParameters,
                      },
                  ]
                : []),
            ...[
                {
                    path:
                        permission.Path.length > 0
                            ? `${permission.Path.join("/")}/${permission.Id}/${innerPermission.Id}`
                            : `${permission.Id}/${innerPermission.Id}`,
                },
                {
                    path:
                        permission.Path.length > 0
                            ? `${permission.Path.join("/")}/${permission.Id}/DataEntry`
                            : `${permission.Id}/DataEntry`,
                },
            ],
        ];
    }

    return [
        ...(innerPermission.Metadata.UiPath
            ? [
                  {
                      path: innerPermission.Metadata.UiPath,
                      parameters: innerPermission.Metadata.UiParameters,
                  },
              ]
            : []),
        ...[
            {
                path:
                    permission.Path.length > 0
                        ? `${permission.Path.join("/")}/${permission.Id}/${innerPermission.Id}`
                        : `${permission.Id}/${innerPermission.Id}`,
            },
        ],
    ];
};

export interface IInnerPermissionProps {
    routable?: boolean;
    permission: Permission;
    innerPermission: string;
    innerComponents?: {
        [x: string]: React.ReactElement;
    };
    onRenderChildContent?: (
        defaultRender: () => JSX.Element,
        permission?: Permission,
        lazyComponents?: ILazyComponentInfo[],
    ) => JSX.Element | null;
    columns: IDataTableColumn[];
    additionalFilter: Filter | undefined;
    selectors: Record<string, Selector>;
    aggregateDataTypes: Record<string, AggregateDataType[]>;
    filters: Record<string, Filter>;
    sort: Sort | undefined;
    group: Group | undefined;
    pagination: Pagination | undefined;
}

const InnerPermission = (props: IInnerPermissionProps) => {
    const innerPermission = props.innerPermission;

    const permission = props.permission;
    const actualInnerPermission = permission.ChildPermissions.find((x) => {
        return x.Id === innerPermission;
    });

    if (!actualInnerPermission) {
        return <div>nema</div>;
    }

    const getAuthorizedComponent = (permissions: string | string[], element: JSX.Element | null) => {
        const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

        return isDevelopment ? (
            element
        ) : (
            <Authorize
                permissions={permissions}
                unauthorizedElement={
                    <MessageBar messageBarType={MessageBarType.error}>Nemate pristup ovom dijelu sustava.</MessageBar>
                }
            >
                {element}
            </Authorize>
        );
    };

    const getDefaultComponent = () => {
        return innerPermission === "Documents" ? (
            <LazyComponent
                lazyComponents={getChildLazyComponents(permission, actualInnerPermission)}
                fallback={<Documents routable={props.routable} />}
            />
        ) : innerPermission === "Notes" ? (
            <LazyComponent
                lazyComponents={getChildLazyComponents(permission, actualInnerPermission)}
                fallback={<Notes />}
            />
        ) : innerPermission === "Export" ? (
            <LazyComponent
                lazyComponents={getChildLazyComponents(permission, actualInnerPermission)}
                fallback={
                    <Export
                        permission={permission}
                        columns={props.columns}
                        additionalFilter={props.additionalFilter}
                        initialSelectors={props.selectors}
                        initialAggregateDataTypes={props.aggregateDataTypes}
                        initialFilters={props.filters}
                        initialSort={props.sort}
                        initialGroup={props.group}
                        initialPagination={props.pagination}
                    />
                }
            />
        ) : innerPermission === "Activity" ? (
            <LazyComponent
                lazyComponents={getChildLazyComponents(permission, actualInnerPermission)}
                fallback={<Activity />}
            />
        ) : innerPermission === "AuditLog" ? (
            <LazyComponent
                lazyComponents={getChildLazyComponents(permission, actualInnerPermission)}
                fallback={<AuditLog />}
            />
        ) : innerPermission === "Create" || innerPermission === "Read" || innerPermission === "Update" ? (
            <LazyComponent
                lazyComponents={getChildLazyComponents(permission, actualInnerPermission)}
                fallback={
                    actualInnerPermission.Metadata.AutoCrudForm === true ? (
                        <AutoEntityForm entity={permission.Id} />
                    ) : undefined
                }
            />
        ) : innerPermission === "Delete" ? (
            <LazyComponent
                lazyComponents={getChildLazyComponents(permission, actualInnerPermission)}
                fallback={
                    actualInnerPermission.Metadata.AutoCrudForm === true ? (
                        <AutoDelete entity={permission.Id} />
                    ) : undefined
                }
            />
        ) : (
            <LazyComponent lazyComponents={getChildLazyComponents(permission, actualInnerPermission)} />
        );
    };

    return getAuthorizedComponent(
        actualInnerPermission.CompleteId,
        props.innerComponents !== undefined && Object.keys(props.innerComponents).includes(innerPermission ?? "")
            ? props.innerComponents[innerPermission ?? ""]
            : props.onRenderChildContent
            ? props.onRenderChildContent(
                  getDefaultComponent,
                  actualInnerPermission,
                  getChildLazyComponents(permission, actualInnerPermission),
              )
            : getDefaultComponent(),
    );
};

export default InnerPermission;
