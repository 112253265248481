import { GetUserInfo } from "@in-core/api/Shell";

export interface IRoutableItem {
    id: string;
    name: string;
    icon: string | undefined;
    url: string;
}

export interface IMenuItem {
    text?: string;
    permissionId?: string;
    submenus?: IMenuItem[];
}

export enum TitleErrorType {
    NotFound = "NotFound",
    Unauthorized = "Unauthorized",
}

export interface ITitleProps {
    text: string;
    error?: TitleErrorType;
}

export interface ILayoutProps {
    titleProps: ITitleProps;
    routableItems: IRoutableItem[];
    menuItems?: IMenuItem[];
    userInfo: GetUserInfo.UserInfo;
}
