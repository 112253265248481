import {
    Dropdown,
    EntityDataTableDropdown,
    Filter,
    FilterGroupOperator,
    FilterValueOperator,
    Icon,
    Stack,
    Text,
    getKeyString,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import { NavigationDescriptor } from "@in-core/api/Entity";
import { useState } from "react";

export enum RelatedDataFilterOperator {
    Equal = "Equal",
    NotEqual = "NotEqual",
}

export interface IRelatedDataFilterProps {
    navigationDescriptor: NavigationDescriptor;
    filter?: Filter;
    onFilterChanged: (filter?: Filter) => void;
}

const RelatedDataFilter = (props: IRelatedDataFilterProps) => {
    const theme = useTheme();
    const [internalSelectedOperator, setInternalSelectedOperator] = useState<RelatedDataFilterOperator>(
        RelatedDataFilterOperator.Equal,
    );
    const localization = useInCoreLocalization();

    let selectedOperator = internalSelectedOperator;
    let selectedKeys: any[] = [];

    if (props.filter) {
        selectedOperator = props.filter.IsNegated
            ? RelatedDataFilterOperator.NotEqual
            : RelatedDataFilterOperator.Equal;

        props.filter.Group!.Filters.forEach((x) => {
            const keyObj: any = {};

            x.Group!.Filters.forEach((y) => {
                const index = props.navigationDescriptor.ForeignKey!.PropertyIds.indexOf(y.Value!.LeftOperand.Id!);
                const keyObjId = props.navigationDescriptor.PrimaryKey!.PropertyIds[index];
                const keyObjValue = y.Value!.RightOperand!.Value!;

                keyObj[keyObjId] = keyObjValue;
            });

            selectedKeys.push(keyObj);
        });
    }

    return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Dropdown
                title={localization.DataTable[selectedOperator]}
                options={[
                    {
                        key: RelatedDataFilterOperator.Equal,
                        text: "=",
                    },
                    {
                        key: RelatedDataFilterOperator.NotEqual,
                        text: "≠",
                    },
                ]}
                selectedKey={selectedOperator}
                onChange={(changes) => {
                    setInternalSelectedOperator(
                        (changes.find((x) => {
                            return x.isSelected;
                        })?.key as RelatedDataFilterOperator | undefined) ?? RelatedDataFilterOperator.Equal,
                    );

                    if (props.filter) {
                        props.onFilterChanged({ ...props.filter, IsNegated: !props.filter.IsNegated });
                    }
                }}
                hideClear
                styles={{
                    dropdown: {
                        ":hover": {
                            ".ms-Dropdown-title": {
                                borderColor: theme.palette.themeLighter,
                            },
                        },
                    },
                    title: {
                        "backgroundColor": theme.palette.neutralLight,
                        "borderColor": theme.palette.neutralLight,
                        "color": theme.palette.neutralPrimary,
                        "paddingRight": 8,
                        "paddingLeft": 8,
                        "width": 52,
                        ":hover": {
                            backgroundColor: theme.palette.themeLighter,
                            borderColor: theme.palette.themeLighter,
                        },
                    },
                    caretDownWrapper: {
                        display: "none",
                    },
                    callout: {
                        minWidth: 175,
                    },
                }}
                onRenderTitle={(renderProps, defaultRender) => {
                    return (
                        <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
                            <Icon iconName="FilterSolid" />

                            <div>{defaultRender!(renderProps)}</div>
                        </Stack>
                    );
                }}
                onRenderOption={(renderProps, defaultRender) => {
                    return (
                        <Stack horizontal>
                            <div style={{ width: 30 }}>{defaultRender!(renderProps)}</div>

                            <Text>{localization.DataTable[renderProps!.key as RelatedDataFilterOperator]}</Text>
                        </Stack>
                    );
                }}
            />

            <EntityDataTableDropdown
                entity={props.navigationDescriptor.TargetEntity}
                multiSelect
                selectedKeys={
                    props.navigationDescriptor.PrimaryKey &&
                    props.navigationDescriptor.PrimaryKey.PropertyIds.length === 1
                        ? selectedKeys.map((x) => {
                              return x[props.navigationDescriptor.PrimaryKey!.PropertyIds[0]];
                          })
                        : selectedKeys
                }
                onChange={(items) => {
                    const removedKeys = items
                        .filter((x) => {
                            return !x.isSelected;
                        })
                        .map((x) => {
                            return props.navigationDescriptor.PrimaryKey &&
                                props.navigationDescriptor.PrimaryKey.PropertyIds.length === 1
                                ? {
                                      [props.navigationDescriptor.PrimaryKey.PropertyIds[0]]: x.key,
                                  }
                                : x.key;
                        });

                    const addedKeys = items
                        .filter((x) => {
                            return x.isSelected;
                        })
                        .map((x) => {
                            return props.navigationDescriptor.PrimaryKey &&
                                props.navigationDescriptor.PrimaryKey.PropertyIds.length === 1
                                ? {
                                      [props.navigationDescriptor.PrimaryKey.PropertyIds[0]]: x.key,
                                  }
                                : x.key;
                        });

                    const primaryKeyIds = props.navigationDescriptor.PrimaryKey?.PropertyIds ?? [];

                    const removedKeyStrings = removedKeys.map((x) => {
                        return getKeyString(x, primaryKeyIds);
                    });

                    const newSelectedKeys = [
                        ...selectedKeys.filter((x) => {
                            return !removedKeyStrings.includes(getKeyString(x, primaryKeyIds));
                        }),
                        ...addedKeys,
                    ];

                    if (newSelectedKeys.length === 0) {
                        props.onFilterChanged(undefined);
                        return;
                    }

                    const newFilter: Filter = {
                        IsNegated: selectedOperator === RelatedDataFilterOperator.NotEqual,
                        Group: {
                            Operator: FilterGroupOperator.Or,
                            Filters: newSelectedKeys.map((x) => {
                                return {
                                    IsNegated: false,
                                    Group: {
                                        Operator: FilterGroupOperator.And,
                                        Filters: primaryKeyIds.map((y, i) => {
                                            const forenKeyId = props.navigationDescriptor.ForeignKey!.PropertyIds[i];

                                            return {
                                                IsNegated: false,
                                                Value: {
                                                    LeftOperand: {
                                                        Id: forenKeyId,
                                                    },
                                                    Operator: FilterValueOperator.Equal,
                                                    RightOperand: { Value: x[y] },
                                                },
                                            };
                                        }),
                                    },
                                };
                            }),
                        },
                    };

                    props.onFilterChanged(newFilter);
                }}
                containerStyle={{ flex: 1, overflow: "hidden" }}
                headerText={props.navigationDescriptor.Display?.Name ?? props.navigationDescriptor.Id}
                styles={{
                    title: {
                        backgroundColor: theme.palette.neutralLighterAlt,
                        borderColor: theme.palette.neutralQuaternary,
                    },
                }}
                hideAddNew
            />
        </Stack>
    );
};

export default RelatedDataFilter;
