import {
    ActionButton,
    Checkbox,
    ChoiceGroup,
    DataTable,
    DirectionalHint,
    Dropdown,
    DropdownMenuItemType,
    Filter,
    FilterGroupOperator,
    FilterValueOperator,
    FormValue,
    IChoiceGroupOption,
    Icon,
    Link,
    Panel,
    PanelType,
    Permission,
    PrimaryButton,
    SelectionMode,
    Stack,
    Text,
    TooltipHost,
    UseForm,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import { DataType } from "@in-core/api/Entity";
import { useMemo, useState } from "react";
import PermissionImplicationGraph from "../../PermissionImplicationGraph";

export interface IRolePermissionsTableProps {
    form: UseForm;
    permissions: Permission[];
    permissionImplicationGraph: PermissionImplicationGraph;
    allFlatPermissions: Permission[];
    disabled?: boolean;
}

const RolePermissionsTable = (props: IRolePermissionsTableProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();
    const [selectedPermissionId, setSelectedPermissionId] = useState<string>();

    const idProperties = useMemo(() => {
        return ["Id"] as (keyof Permission)[];
    }, []);

    const setSelection = (permissionId: string | undefined) => {
        setSelectedPermissionId(permissionId);
    };

    const form = props.form as any;
    const addedPermissions: string[] = form.Permissions.$value ?? [];

    const selectedPermission = props.permissions.find((x) => {
        return x.Id === selectedPermissionId;
    });

    const data = props.permissions.map((currentPermission) => {
        const impliedBy: Permission[] = props.permissionImplicationGraph
            .getPermissionsThatImply(currentPermission.CompleteId)
            .filter((x) => {
                return addedPermissions.includes(x);
            })
            .map((x) => {
                return props.allFlatPermissions.find((y) => {
                    return y.CompleteId === x;
                })!;
            });
        const isImplied = impliedBy.length > 0;
        const isAdded = addedPermissions.includes(currentPermission.CompleteId);
        const isParentExplicit =
            currentPermission.ParentPermissionId !== undefined &&
            addedPermissions.includes(currentPermission.ParentPermissionId);

        const additionalInfo = {
            IsSelected: isAdded || isImplied,
            ImpliedBy: impliedBy,
            IsImplied: isImplied,
            IsAdded: isAdded,
            IsParentExplicit: isParentExplicit,
        };

        return {
            ...currentPermission,
            IsSelected: isAdded || isImplied,
            ImpliedBy: impliedBy,
            IsImplied: isImplied,
            IsAdded: isAdded,
            IsParentExplicit: isParentExplicit,
            PermissionType:
                additionalInfo === undefined
                    ? "notAssignedExplicit"
                    : additionalInfo.IsSelected && additionalInfo.IsAdded
                    ? "assignedExplicit"
                    : additionalInfo.IsSelected && additionalInfo.IsImplied && !additionalInfo.IsAdded
                    ? "assignedImplicit"
                    : "notAssignedExplicit",
        };
    });

    return (
        <>
            <DataTable
                data={data}
                idProperties={idProperties}
                group={{ Id: "GroupName" }}
                columns={[
                    {
                        id: "Id",
                        property: "Id",
                        name: localization.UserManagement.PermissionsIdColumn,
                        dataType: DataType.String,
                        onRender: (item) => {
                            if (item.ChildPermissions.length === 0) {
                                return (
                                    <Text
                                        styles={{
                                            root: {
                                                fontSize: "inherit",
                                                color: theme.palette.neutralDark,
                                            },
                                        }}
                                    >
                                        {item.Id}
                                    </Text>
                                );
                            }

                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        setSelection(item.Id);
                                    }}
                                >
                                    {item.Id}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "Name",
                        property: "Name",
                        name: localization.UserManagement.PermissionsNameColumn,
                        dataType: DataType.String,
                        onRender: (item) => {
                            if (item.ChildPermissions.length === 0) {
                                return (
                                    <Text
                                        styles={{
                                            root: {
                                                fontSize: "inherit",
                                                color: theme.palette.neutralDark,
                                                display: "flex",
                                                alignItems: "center",
                                            },
                                        }}
                                    >
                                        <Icon
                                            iconName={item.Icon}
                                            styles={{
                                                root: {
                                                    fontSize: 16,
                                                    color: theme.palette.themePrimary,
                                                    marginRight: theme.spacing.s2,
                                                },
                                            }}
                                        />

                                        {item.Name}
                                    </Text>
                                );
                            }

                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                            display: "flex",
                                            alignItems: "center",
                                        },
                                    }}
                                    onClick={() => {
                                        setSelection(item.Id);
                                    }}
                                >
                                    <Icon
                                        iconName={item.Icon}
                                        styles={{
                                            root: {
                                                fontSize: 16,
                                                color: theme.palette.themePrimary,
                                                marginRight: theme.spacing.s2,
                                            },
                                        }}
                                    />

                                    {item.Name}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "IsSelected",
                        name: localization.UserManagement.PermissionsIsSelectedColumn,
                        width: 300,
                        isResizable: false,
                        isSortable: false,
                        onRenderFilter: (filter, onChange) => {
                            let assignedExplicit = false;
                            let assignedImplicit = false;
                            let notAssignedExplicit = false;

                            if (filter) {
                                const permissionTypes = filter.Group!.Filters.map((x) => {
                                    return x.Value!.RightOperand!.Value;
                                });

                                assignedExplicit = permissionTypes.includes("assignedExplicit");
                                assignedImplicit = permissionTypes.includes("assignedImplicit");
                                notAssignedExplicit = permissionTypes.includes("notAssignedExplicit");
                            }

                            const selectedKeys: string[] = [];
                            if (assignedExplicit) {
                                selectedKeys.push("assignedExplicit");
                            }
                            if (assignedImplicit) {
                                selectedKeys.push("assignedImplicit");
                            }
                            if (notAssignedExplicit) {
                                selectedKeys.push("notAssignedExplicit");
                            }

                            return (
                                <Dropdown
                                    multiSelect
                                    selectedKeys={selectedKeys}
                                    onRenderItem={(renderProps, defaultRender) => {
                                        if (renderProps?.itemType !== DropdownMenuItemType.Header) {
                                            return defaultRender!(renderProps);
                                        }

                                        const checked =
                                            renderProps.key === "__assignedHeader"
                                                ? assignedExplicit && assignedImplicit
                                                : notAssignedExplicit;

                                        const indeterminate =
                                            renderProps.key === "__assignedHeader"
                                                ? !checked && (assignedExplicit || assignedImplicit)
                                                : !checked && notAssignedExplicit;

                                        return (
                                            <Checkbox
                                                key={renderProps.key}
                                                onRenderLabel={() => {
                                                    return defaultRender!(renderProps);
                                                }}
                                                isInline
                                                styles={{
                                                    root: {
                                                        border: "1px solid transparent",
                                                    },
                                                    label: {
                                                        paddingLeft: theme.spacing.s1,
                                                        width: "100%",
                                                    },
                                                }}
                                                checked={checked}
                                                indeterminate={indeterminate}
                                                onChange={(_, checked) => {
                                                    let newInnerFilters: Filter[] = [...(filter?.Group?.Filters ?? [])];

                                                    if (renderProps.key === "__assignedHeader") {
                                                        newInnerFilters = newInnerFilters.filter((x) => {
                                                            return !x.Value!.RightOperand!.Value.startsWith("assigned");
                                                        });

                                                        if (checked) {
                                                            newInnerFilters.push(
                                                                ...[
                                                                    {
                                                                        IsNegated: false,
                                                                        Value: {
                                                                            LeftOperand: { Id: "PermissionType" },
                                                                            Operator: FilterValueOperator.Equal,
                                                                            RightOperand: { Value: "assignedExplicit" },
                                                                        },
                                                                    },
                                                                    {
                                                                        IsNegated: false,
                                                                        Value: {
                                                                            LeftOperand: { Id: "PermissionType" },
                                                                            Operator: FilterValueOperator.Equal,
                                                                            RightOperand: { Value: "assignedImplicit" },
                                                                        },
                                                                    },
                                                                ],
                                                            );
                                                        }
                                                    } else {
                                                        newInnerFilters = newInnerFilters.filter((x) => {
                                                            return !x.Value!.RightOperand!.Value.startsWith(
                                                                "notAssigned",
                                                            );
                                                        });

                                                        if (checked) {
                                                            newInnerFilters.push(
                                                                ...[
                                                                    {
                                                                        IsNegated: false,
                                                                        Value: {
                                                                            LeftOperand: { Id: "PermissionType" },
                                                                            Operator: FilterValueOperator.Equal,
                                                                            RightOperand: {
                                                                                Value: "notAssignedExplicit",
                                                                            },
                                                                        },
                                                                    },
                                                                ],
                                                            );
                                                        }
                                                    }

                                                    onChange({
                                                        IsNegated: false,
                                                        Group: {
                                                            Operator: FilterGroupOperator.Or,
                                                            Filters: newInnerFilters,
                                                        },
                                                    });
                                                }}
                                            />
                                        );
                                    }}
                                    options={[
                                        {
                                            key: "__assignedHeader",
                                            text: localization.UserManagement.PermissionsAssigned,
                                            itemType: DropdownMenuItemType.Header,
                                        },
                                        {
                                            key: "assignedExplicit",
                                            text: localization.UserManagement.PermissionsAssignedExplicit,
                                        },
                                        {
                                            key: "assignedImplicit",
                                            text: localization.UserManagement.PermissionsAssignedImplicit,
                                        },
                                        {
                                            key: "__divider",
                                            text: "",
                                            itemType: DropdownMenuItemType.Divider,
                                        },
                                        {
                                            key: "__notAssignedHeader",
                                            text: localization.UserManagement.PermissionsNotAssigned,
                                            itemType: DropdownMenuItemType.Header,
                                        },
                                        {
                                            key: "notAssignedExplicit",
                                            text: localization.UserManagement.PermissionsNotAssignedExplicit,
                                        },
                                    ]}
                                    styles={{ dropdownItemHeader: { padding: 0, cursor: "pointer" } }}
                                    onChange={(selectionItems) => {
                                        const newAssignedExplicit =
                                            (assignedExplicit &&
                                                !selectionItems.some((x) => {
                                                    return x.key === "assignedExplicit" && !x.isSelected;
                                                })) ||
                                            (!assignedExplicit &&
                                                selectionItems.some((x) => {
                                                    return x.key === "assignedExplicit" && x.isSelected;
                                                }));

                                        const newAssignedImplicit =
                                            (assignedImplicit &&
                                                !selectionItems.some((x) => {
                                                    return x.key === "assignedImplicit" && !x.isSelected;
                                                })) ||
                                            (!assignedImplicit &&
                                                selectionItems.some((x) => {
                                                    return x.key === "assignedImplicit" && x.isSelected;
                                                }));

                                        const newNotAssignedExplicit =
                                            (notAssignedExplicit &&
                                                !selectionItems.some((x) => {
                                                    return x.key === "notAssignedExplicit" && !x.isSelected;
                                                })) ||
                                            (!notAssignedExplicit &&
                                                selectionItems.some((x) => {
                                                    return x.key === "notAssignedExplicit" && x.isSelected;
                                                }));

                                        if (!newAssignedExplicit && !newAssignedImplicit && !newNotAssignedExplicit) {
                                            onChange(undefined);
                                        }

                                        const newInnerFilters: Filter[] = [];

                                        if (newAssignedExplicit) {
                                            newInnerFilters.push({
                                                IsNegated: false,
                                                Value: {
                                                    LeftOperand: { Id: "PermissionType" },
                                                    Operator: FilterValueOperator.Equal,
                                                    RightOperand: { Value: "assignedExplicit" },
                                                },
                                            });
                                        }

                                        if (newAssignedImplicit) {
                                            newInnerFilters.push({
                                                IsNegated: false,
                                                Value: {
                                                    LeftOperand: { Id: "PermissionType" },
                                                    Operator: FilterValueOperator.Equal,
                                                    RightOperand: { Value: "assignedImplicit" },
                                                },
                                            });
                                        }

                                        if (newNotAssignedExplicit) {
                                            newInnerFilters.push({
                                                IsNegated: false,
                                                Value: {
                                                    LeftOperand: { Id: "PermissionType" },
                                                    Operator: FilterValueOperator.Equal,
                                                    RightOperand: { Value: "notAssignedExplicit" },
                                                },
                                            });
                                        }

                                        onChange({
                                            IsNegated: false,
                                            Group: {
                                                Operator: FilterGroupOperator.Or,
                                                Filters: newInnerFilters,
                                            },
                                        });
                                    }}
                                />
                            );
                        },
                        onRender: (item) => {
                            const impliedBy = item.ImpliedBy;
                            const isImplied = item.IsImplied;

                            const options: IChoiceGroupOption[] = [
                                {
                                    key: "Yes",
                                    text: localization.Yes,
                                    onClick:
                                        item.ChildPermissions.length === 0
                                            ? undefined
                                            : () => {
                                                  setSelection(item.Id);
                                              },
                                    styles: { root: { minHeight: 0 } },
                                },
                                {
                                    key: "No",
                                    text: localization.No,
                                    disabled: isImplied,
                                    styles: { root: { minHeight: 0 } },
                                },
                            ];

                            if (isImplied) {
                                options.push({
                                    key: "Special",
                                    text: localization.UserManagement.PermissionsYesImplied,
                                    onRenderLabel: (renderProps, defaultRender) => {
                                        return (
                                            <TooltipHost
                                                content={
                                                    <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                                                        <Text>{localization.UserManagement.PermissionsImpliedBy}</Text>

                                                        <Stack
                                                            horizontal
                                                            wrap
                                                            tokens={{ childrenGap: theme.spacing.s1 }}
                                                        >
                                                            {impliedBy.map((x) => {
                                                                return (
                                                                    <Text
                                                                        key={x.CompleteId}
                                                                        styles={{
                                                                            root: {
                                                                                backgroundColor:
                                                                                    theme.palette.themeLighter,
                                                                                borderRadius:
                                                                                    theme.effects.roundedCorner2,
                                                                                padding: "2px 5px",
                                                                            },
                                                                        }}
                                                                    >
                                                                        {x.Name}{" "}
                                                                        <span
                                                                            style={{
                                                                                color: theme.palette
                                                                                    .neutralSecondaryAlt,
                                                                            }}
                                                                        >
                                                                            ({x.CompleteId})
                                                                        </span>
                                                                    </Text>
                                                                );
                                                            })}
                                                        </Stack>
                                                    </Stack>
                                                }
                                                directionalHint={DirectionalHint.rightCenter}
                                            >
                                                {defaultRender!(renderProps)}
                                            </TooltipHost>
                                        );
                                    },
                                    styles: { root: { minHeight: 0 } },
                                });
                            }

                            return (
                                <Stack horizontal>
                                    <ChoiceGroup
                                        disabled={
                                            (item.ParentPermissionId !== undefined && !item.IsParentExplicit) ||
                                            props.disabled
                                        }
                                        options={options}
                                        isInline
                                        selectedKey={
                                            addedPermissions.includes(item.CompleteId)
                                                ? "Yes"
                                                : isImplied
                                                ? "Special"
                                                : "No"
                                        }
                                        onChange={(_, option) => {
                                            const addedPermissionFormValue = form.Permissions as FormValue<string[]>;

                                            if (option?.key === "Yes") {
                                                addedPermissionFormValue.$push(item.CompleteId);

                                                return;
                                            }

                                            if (option?.key === "No") {
                                                addedPermissionFormValue.$filter((x) => {
                                                    return (
                                                        x !== item.CompleteId && !x.startsWith(`${item.CompleteId}.`)
                                                    );
                                                });

                                                return;
                                            }

                                            addedPermissionFormValue.$filter((x) => {
                                                return item.IsImplied
                                                    ? x !== item.CompleteId
                                                    : x !== item.CompleteId && !x.startsWith(`${item.CompleteId}.`);
                                            });
                                        }}
                                    />
                                </Stack>
                            );
                        },
                    },
                    ...(data.some((x) => {
                        return x.ChildPermissions.length > 0;
                    })
                        ? [
                              {
                                  id: "Details",
                                  name: "",
                                  width: 90,
                                  onRender: (item: Permission) => {
                                      if (item.ChildPermissions.length === 0) {
                                          return null;
                                      }

                                      return (
                                          <ActionButton
                                              iconProps={{ iconName: "MultiSelect" }}
                                              styles={{ root: { height: "auto", padding: 0 } }}
                                              onClick={() => {
                                                  setSelection(item.Id);
                                              }}
                                          >
                                              {localization.Details}
                                          </ActionButton>
                                      );
                                  },
                                  isSortable: false,
                                  isResizable: false,
                              },
                          ]
                        : []),
                ]}
                hideToolbar
                defaultSort={{ Id: "Name", IsDescending: false }}
                disableSearching
                disablePagination
                disableAggregating
                selectionMode={SelectionMode.none}
                onItemInvoked={(key) => {
                    const permission = props.permissions.find((x) => {
                        return x.Id === key;
                    });

                    if (permission && permission.ChildPermissions.length > 0) {
                        setSelection(key);
                    }
                }}
            />

            <Panel
                isOpen={selectedPermission !== undefined}
                onDismiss={() => {
                    setSelectedPermissionId(undefined);
                }}
                isLightDismiss
                headerText={selectedPermission?.Name}
                type={PanelType.custom}
                customWidth="850px"
                styles={{
                    scrollableContent: { height: "100%", display: "flex", flexDirection: "column" },
                    content: {
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        padding: 0,
                        paddingTop: theme.spacing.m,
                    },
                    footer: {
                        borderTopWidth: 1,
                        borderTopStyle: "solid",
                        borderTopColor: theme.palette.neutralLighter,
                    },
                }}
                onRenderFooterContent={() => {
                    return (
                        <Stack horizontal>
                            <PrimaryButton
                                onClick={() => {
                                    return setSelectedPermissionId(undefined);
                                }}
                            >
                                {localization.Ok}
                            </PrimaryButton>
                        </Stack>
                    );
                }}
            >
                <RolePermissionsTable
                    form={props.form}
                    permissions={selectedPermission?.ChildPermissions ?? []}
                    permissionImplicationGraph={props.permissionImplicationGraph}
                    allFlatPermissions={props.allFlatPermissions}
                    disabled={props.disabled}
                />
            </Panel>
        </>
    );
};

export default RolePermissionsTable;
