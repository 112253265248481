import { useState } from "react";
import { DefaultButton, DialogFooter, PrimaryButton, Spinner } from "@fluentui/react";
import { TextField, useInCoreLocalization } from "@in-core";
import { fileNameRegex } from "@in-core/components/FileUpload";

export interface INewFolderProps {
    onDismiss: () => void;
    onSave: (folderName: string) => Promise<void>;
    existingNames: string[];
}

const NewFolder = (props: INewFolderProps) => {
    const [name, setName] = useState("");
    const [isDirty, setIsDirty] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const localization = useInCoreLocalization();

    let errorMessage: string | undefined = undefined;
    if (!fileNameRegex.test(name)) {
        errorMessage = localization.Documents.NameInvalid;
    }
    if (
        props.existingNames
            .map((x) => {
                return x.toLowerCase();
            })
            .includes(name.toLowerCase())
    ) {
        errorMessage = localization.Documents.NameAlreadyExists;
    }

    const isValid = errorMessage === undefined;

    return (
        <form
            onSubmit={async (e) => {
                e.preventDefault();
                setIsProcessing(true);
                await props.onSave(name);
                setIsProcessing(false);
                props.onDismiss && props.onDismiss();
            }}
        >
            <TextField
                label={localization.Documents.NameColumn}
                value={name}
                onChange={(_, newValue) => {
                    setName(newValue?.trim() ?? "");
                    setIsDirty(true);
                }}
                errorMessage={isDirty ? errorMessage : undefined}
            />

            <DialogFooter
                styles={{
                    actionsRight: {
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                    },
                    action: { display: "flex" },
                }}
            >
                {isProcessing && <Spinner />}

                <PrimaryButton disabled={!isValid || isProcessing}>{localization.Save}</PrimaryButton>

                <DefaultButton onClick={props.onDismiss} disabled={isProcessing}>
                    {localization.Cancel}
                </DefaultButton>
            </DialogFooter>
        </form>
    );
};

export default NewFolder;
