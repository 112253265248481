import { createContext, useContext } from "react";

export interface ActivityInfo {
    id: string;
    name: string;
    keys?: any[];
}

export interface ActivityContext {
    activity?: ActivityInfo;
    createActivity: (activityName: string, keys?: any[]) => void;
    clearActivity: () => void;
    logActivity: (keys?: any[], data?: any, dontCreateNewActivity?: boolean) => Promise<void>;
}

export const activityContext = createContext<ActivityContext | undefined>(undefined);

export const useActivityContext = (): ActivityContext | undefined => {
    return useContext(activityContext);
};
