import { IModalProps, ISpinnerProps, Modal, Spinner, SpinnerSize, useInCoreLocalization } from "@in-core";

export interface IModalSpinnerProps extends Omit<IModalProps, "children"> {
    spinnerProps?: ISpinnerProps;
}

const ModalSpinner = (props: IModalSpinnerProps) => {
    const { spinnerProps, ...modalProps } = props;
    const localization = useInCoreLocalization();

    return (
        <Modal {...modalProps} styles={{ main: { minHeight: "auto", minWidth: "auto", padding: 20 } }}>
            <Spinner
                {...spinnerProps}
                size={spinnerProps?.size ?? SpinnerSize.large}
                label={spinnerProps?.label ?? localization.PleaseWait}
            />
        </Modal>
    );
};

export default ModalSpinner;
