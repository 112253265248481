import {
    ActionButton,
    ChoiceGroup,
    DataTable,
    FormValue,
    SelectionMode,
    Spinner,
    Stack,
    useEntityDataTableContext,
    UseForm,
    useInCoreLocalization,
    useOnMount,
} from "@in-core";
import { DataType } from "@in-core/api/Entity";
import { ReadAll } from "@in-core/api/Users";
import { useMemo } from "react";

export interface IRoleUserProps {
    form: UseForm;
}

const RoleUsers = (props: IRoleUserProps) => {
    const localization = useInCoreLocalization();
    const getAllUsersApi = ReadAll.useApi();
    const entityDataTableContext = useEntityDataTableContext()!;

    const form = props.form as any;
    const userIds = (form.UserIds.$value ?? []) as string[];
    const isRead = entityDataTableContext.innerPermission?.Id === "Read";

    useOnMount(() => {
        getAllUsersApi.call({ ShouldIncludeRoleIds: false });
    });

    const idProperties = useMemo(() => {
        return ["Id"];
    }, []);

    if (!getAllUsersApi.data) {
        return <Spinner />;
    }

    return (
        <Stack styles={{ root: { height: "100%" } }}>
            <DataTable
                data={
                    getAllUsersApi.data?.map((x: any) => {
                        return { ...x, IsUsed: userIds.includes(x.Id) };
                    }) ?? []
                }
                idProperties={idProperties}
                descriptionProperty={"Description"}
                onSelectionChanged={(items) => {
                    const removedIds = items
                        .filter((x) => {
                            return !x.isSelected;
                        })
                        .map((x) => {
                            return x.key;
                        });

                    const addedIds = items
                        .filter((x) => {
                            return x.isSelected;
                        })
                        .map((x) => {
                            return x.key;
                        });

                    form.UserIds.$setValue([
                        ...(form.UserIds.$value?.filter((x: string) => {
                            return !removedIds.includes(x);
                        }) ?? []),
                        ...addedIds,
                    ]);
                }}
                columns={[
                    {
                        id: "Id",
                        name: localization.UserManagement.UserId,
                        property: "Id",
                        dataType: DataType.Number,
                        width: 150,
                    },
                    {
                        id: "UserName",
                        name: localization.UserManagement.UserUserName,
                        property: "UserName",
                        dataType: DataType.String,
                        width: 200,
                    },
                    {
                        id: "Name",
                        name: localization.UserManagement.UserName,
                        property: "Name",
                        dataType: DataType.String,
                        width: 300,
                    },
                    {
                        id: "IsSelected",
                        name: localization.UserManagement.Assigned,
                        property: "IsSelected",
                        dataType: DataType.Boolean,
                        width: 160,
                        isResizable: false,
                        isSortable: false,
                        getValue: (item) => {
                            return userIds.includes(item.Id);
                        },
                        onRender: (item) => {
                            return (
                                <ChoiceGroup
                                    disabled={isRead}
                                    options={[
                                        {
                                            key: "Yes",
                                            text: localization.Yes,
                                            styles: { root: { minHeight: 0 } },
                                        },
                                        {
                                            key: "No",
                                            text: localization.No,
                                            styles: { root: { minHeight: 0 } },
                                        },
                                    ]}
                                    isInline
                                    selectedKey={userIds.includes(item.Id) ? "Yes" : "No"}
                                    onChange={(_, option) => {
                                        const userIdsFormValue = form.UserIds as FormValue<string[]>;

                                        if (option?.key === "Yes") {
                                            userIdsFormValue.$push(item.Id);
                                        } else {
                                            userIdsFormValue.$filter((x) => {
                                                return x !== item.Id;
                                            });
                                        }
                                    }}
                                />
                            );
                        },
                    },
                    {
                        id: "Details",
                        name: "",
                        width: 90,
                        onRender: (item) => {
                            return (
                                <ActionButton
                                    iconProps={{ iconName: "MultiSelect" }}
                                    styles={{ root: { height: "auto", padding: 0 } }}
                                >
                                    {localization.Details}
                                </ActionButton>
                            );
                        },
                        isSortable: false,
                        isSearchable: false,
                        isFilterable: false,
                        isResizable: false,
                    },
                ]}
                defaultSort={{ Id: "Description", IsDescending: false }}
                hideToolbar
                selectionMode={SelectionMode.none}
                disableAggregating
            />
        </Stack>
    );
};

export default RoleUsers;
