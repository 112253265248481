import { areArraysEqual, areObjectsEqual } from "@in-core/Utils";
import { DependencyList, useCallback, useEffect, useRef } from "react";

export interface UseOnChangeOptions {
    fireOnFirstSet: boolean;
    shouldCompareByReference: boolean;
    arrayOrderNotImportant: boolean;
    debounce: number;
}

const useOnChange = (
    effect: () => void,
    deps: DependencyList,
    options?: Partial<UseOnChangeOptions>,
    cleanup?: () => any,
) => {
    const optionsRef = useRef<UseOnChangeOptions>({
        fireOnFirstSet: options?.fireOnFirstSet ?? true,
        shouldCompareByReference: options?.shouldCompareByReference ?? false,
        arrayOrderNotImportant: options?.arrayOrderNotImportant ?? true,
        debounce: options?.debounce ?? 0,
    });
    const firstRenderRef = useRef(true);
    const depsRef = useRef<DependencyList | undefined>(undefined);
    const timeoutRef = useRef<NodeJS.Timeout>();

    const areDepsEqual = useCallback(() => {
        if (optionsRef.current.shouldCompareByReference) {
            return deps === depsRef.current;
        }

        return areArraysEqual(deps as any[], depsRef.current as any[], false, (el1, el2) => {
            return areObjectsEqual(el1, el2, optionsRef.current.arrayOrderNotImportant);
        });
    }, [deps]);

    if (depsRef.current === undefined || !areDepsEqual()) {
        depsRef.current = deps;
    }

    return useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;

            if (!optionsRef.current.fireOnFirstSet) {
                return;
            }
        }

        if (optionsRef.current.debounce > 0) {
            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(effect, optionsRef.current.debounce);
        } else {
            effect();
        }

        if (cleanup) {
            return () => {
                cleanup();
                timeoutRef.current && clearTimeout(timeoutRef.current);
            };
        } else {
            return () => {
                timeoutRef.current && clearTimeout(timeoutRef.current);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, depsRef.current);
};

export default useOnChange;
