import {
    DataTable,
    IDataTableColumn,
    SelectionMode,
    Stack,
    Text,
    Toggle,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import { GetActivities } from "@in-core/api/Entity";
import { useState } from "react";

export interface IEntityAuditLogProps {
    auditLogEntity: GetActivities.AuditLogEntityInfo;
}

const EntityAuditLog = (props: IEntityAuditLogProps) => {
    const [onlyChanges, setOnlyChanges] = useState(false);
    const theme = useTheme();
    const localization = useInCoreLocalization();

    const columns: IDataTableColumn<GetActivities.AuditLogInfo>[] = [
        {
            id: "__time",
            name: localization.EntityDataTable.ActivityAuditLogTime,
            width: 150,
            onRender: (x) => {
                return x.Time.toLocaleString("hr-HR");
            },
        },
        {
            id: "__type",
            name: localization.EntityDataTable.ActivityAuditLogType,
            width: 120,
            onRender: (x) => {
                return x.Type;
            },
        },
    ];

    let allColumns = Array.from(
        new Set(
            props.auditLogEntity.AuditLogs.flatMap((x) => {
                return Object.keys(x.OldValues ?? {}).concat(Object.keys(x.NewValues ?? {}));
            }).sort(),
        ),
    );

    if (onlyChanges) {
        allColumns = allColumns.filter((x) => {
            return props.auditLogEntity.AuditLogs.some((y) => {
                const oldValue = (y.OldValues ? y.OldValues[x] : undefined) ?? undefined;
                const newValue = (y.NewValues ? y.NewValues[x] : undefined) ?? undefined;

                return oldValue !== newValue;
            });
        });
    }

    allColumns.forEach((x) => {
        columns.push({
            id: x,
            name: x,
            onRender: (y) => {
                const oldValue =
                    y.OldValues === undefined || y.OldValues === null ? undefined : y.OldValues[x] ?? undefined;
                const newValue =
                    y.NewValues === undefined || y.NewValues === null ? undefined : y.NewValues[x] ?? undefined;

                if (oldValue === newValue) {
                    return newValue;
                }

                return (
                    <>
                        {oldValue && (
                            <span style={{ color: theme.palette.red, textDecoration: "line-through" }}>{oldValue}</span>
                        )}{" "}
                        {newValue && <span style={{ color: theme.palette.green }}>{newValue}</span>}
                    </>
                );
            },
        });
    });

    return (
        <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
            <Text variant="mediumPlus" styles={{ root: { fontWeight: "bold" } }}>
                {props.auditLogEntity.Name ? `${props.auditLogEntity.Name} / ` : ""}
                {props.auditLogEntity.TableName ? `${props.auditLogEntity.TableName} / ` : ""}
                {props.auditLogEntity.Id}
            </Text>

            <Toggle
                onText={localization.EntityDataTable.ActivityAuditLogOnlyChanges}
                offText={localization.EntityDataTable.ActivityAuditLogOnlyChanges}
                checked={onlyChanges}
                onChange={(_, checked) => {
                    setOnlyChanges(checked ?? false);
                }}
            />

            <Stack style={{ height: 300 }}>
                <DataTable
                    data={props.auditLogEntity.AuditLogs}
                    columns={columns}
                    disablePagination
                    disableFiltering
                    disableSorting
                    disableAggregating
                    disableGrouping
                    disableSearching
                    disableSelecting
                    hideToolbar
                    selectionMode={SelectionMode.none}
                />
            </Stack>
        </Stack>
    );
};

export default EntityAuditLog;
