import { PrimaryButton, Setting, Spinner, Stack, useInCoreLocalization, useOnChange, useTheme } from "@in-core";
import { Read } from "@in-core/api/Settings";
import SettingValueEditor from "@in-core/ui/Settings/SettingValueEditor";

export interface IRoleSettingDetailsProps {
    setting: Setting;
    value?: string;
    onChange?: (newValue?: string) => void;
    onOk?: () => void;
}

const RoleSettingDetails = (props: IRoleSettingDetailsProps) => {
    const readApi = Read.useApi();
    const theme = useTheme();
    const localization = useInCoreLocalization();

    useOnChange(() => {
        readApi.call({ Id: props.setting.Id });
    }, [props.setting.Id]);

    if (!readApi.data) {
        return <Spinner />;
    }

    return (
        <Stack tokens={{ childrenGap: theme.spacing.l2 }}>
            <SettingValueEditor
                value={props.value}
                onChange={props.onChange}
                relevantSettingValues={[
                    { Id: "__system", Name: `${localization.Settings.System}`, Value: readApi.data.SystemValue },
                ]}
            />

            <Stack horizontal>
                <PrimaryButton onClick={props.onOk}>{localization.Ok}</PrimaryButton>
            </Stack>
        </Stack>
    );
};

export default RoleSettingDetails;
