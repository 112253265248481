import {
    ContextualMenuItemType,
    IconButton,
    OverflowSet,
    Stack,
    Text,
    TooltipHost,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import { GetUserInfo } from "@in-core/api/Shell";

export interface IHeaderProps {
    title: string;
    onMenuClicked: () => void;
    userInfo: GetUserInfo.UserInfo;
}

const Header = (props: IHeaderProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();

    return (
        <Stack
            horizontal
            verticalAlign="center"
            tokens={{
                padding: 10,
            }}
            style={{
                minHeight: 50,
                maxHeight: 50,
                borderBottomColor: theme.palette.neutralLight,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
            }}
        >
            <IconButton
                iconProps={{ iconName: "GlobalNavButton" }}
                styles={{ root: { marginRight: 10 } }}
                onClick={props.onMenuClicked}
            />

            <Text variant="large" title={props.title} nowrap styles={{ root: { flex: 1 } }}>
                {props.title}
            </Text>

            <Stack
                horizontal
                tokens={{
                    childrenGap: 5,
                }}
            >
                {/* <TooltipHost content={localization.NotificationsTitle}>
                    <IconButton
                        iconProps={{
                            iconName: "Ringer",
                        }}
                    />
                </TooltipHost> */}

                <TooltipHost content={localization.UserMenuTitle}>
                    <IconButton
                        iconProps={{
                            iconName: "Contact",
                        }}
                        menuProps={{
                            items: [
                                {
                                    key: "account",
                                    itemType: ContextualMenuItemType.Section,
                                    sectionProps: {
                                        topDivider: true,
                                        bottomDivider: true,
                                        title: props.userInfo.FullName
                                            ? `${props.userInfo.FullName} (${
                                                  props.userInfo.UserName ?? props.userInfo.UserId
                                              })`
                                            : props.userInfo.UserName ?? props.userInfo.UserId,
                                        items: [
                                            {
                                                key: "settings",
                                                text: localization.UserMenuSettingsTitle,
                                                iconProps: {
                                                    iconName: "Settings",
                                                },
                                                onClick: () => {
                                                    window.location.href = "/Identity/Account/Manage";
                                                },
                                            },
                                            {
                                                key: "logout",
                                                text: localization.UserMenuLogOutTitle,
                                                iconProps: {
                                                    iconName: "SignOut",
                                                },
                                                onClick: () => {
                                                    window.location.href = "/Identity/Account/Logout";
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        }}
                    />
                </TooltipHost>
            </Stack>
        </Stack>
    );
};

export default Header;
