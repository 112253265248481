import { translate } from "typed-intl";
import {
    useLocalization as useGenericLocalization,
    callApi as callGenericApi,
    useApi as useGenericApi,
    ValidationResult,
    BackgroundApiRequest,
    getUrlWithQueryString,
    CheckBackgroundApiRequest,
    JobInfo,
} from "@in-core";

export namespace GetCountries {
    export interface Request {}

    export interface CountryInfo {
        Id: number;
        Code: string;
        Name: string;
        CurrencyId?: number;
    }

    export const Localization = translate({});

    export const useLocalization = () => {
        return useGenericLocalization(Localization);
    };

    export const URL = "API/V1/LocationSpecific/GetCountries";

    export const ValidateURL = "ValidateAPI/V1/LocationSpecific/GetCountries";

    export const BackgroundURL = "BackgroundAPI/V1/LocationSpecific/GetCountries";

    export const CheckBackgroundURL = "CheckBackgroundAPI/V1/LocationSpecific/GetCountries";

    export const callApi = (request: GetCountries.Request) => {
        return callGenericApi<GetCountries.Request, GetCountries.CountryInfo[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<GetCountries.Request, GetCountries.CountryInfo[]>(URL);
    };

    export const callValidateApi = (request: GetCountries.Request) => {
        return callGenericApi<GetCountries.Request, ValidationResult>(ValidateURL, request);
    };

    export const useValidateApi = () => {
        return useGenericApi<GetCountries.Request, ValidationResult>(ValidateURL);
    };

    export const callBackgroundApi = (request: GetCountries.Request, backgroundApiRequest?: BackgroundApiRequest) => {
        return callGenericApi<GetCountries.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
            request,
        );
    };

    export const useBackgroundApi = (backgroundApiRequest?: BackgroundApiRequest) => {
        return useGenericApi<GetCountries.Request, string>(getUrlWithQueryString(BackgroundURL, backgroundApiRequest));
    };

    export const callCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return callGenericApi<any, JobInfo>(
            getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest),
            undefined,
        );
    };

    export const useCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return useGenericApi<any, JobInfo>(getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest));
    };
}

export namespace GetInsuranceCompanies {
    export interface Request {}

    export interface InsuranceCompanyInfo {
        Id: number;
        Code: string;
        Name: string;
        CountryCode: string;
    }

    export const Localization = translate({});

    export const useLocalization = () => {
        return useGenericLocalization(Localization);
    };

    export const URL = "API/V1/LocationSpecific/GetInsuranceCompanies";

    export const ValidateURL = "ValidateAPI/V1/LocationSpecific/GetInsuranceCompanies";

    export const BackgroundURL = "BackgroundAPI/V1/LocationSpecific/GetInsuranceCompanies";

    export const CheckBackgroundURL = "CheckBackgroundAPI/V1/LocationSpecific/GetInsuranceCompanies";

    export const callApi = (request: GetInsuranceCompanies.Request) => {
        return callGenericApi<GetInsuranceCompanies.Request, GetInsuranceCompanies.InsuranceCompanyInfo[]>(
            URL,
            request,
        );
    };

    export const useApi = () => {
        return useGenericApi<GetInsuranceCompanies.Request, GetInsuranceCompanies.InsuranceCompanyInfo[]>(URL);
    };

    export const callValidateApi = (request: GetInsuranceCompanies.Request) => {
        return callGenericApi<GetInsuranceCompanies.Request, ValidationResult>(ValidateURL, request);
    };

    export const useValidateApi = () => {
        return useGenericApi<GetInsuranceCompanies.Request, ValidationResult>(ValidateURL);
    };

    export const callBackgroundApi = (
        request: GetInsuranceCompanies.Request,
        backgroundApiRequest?: BackgroundApiRequest,
    ) => {
        return callGenericApi<GetInsuranceCompanies.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
            request,
        );
    };

    export const useBackgroundApi = (backgroundApiRequest?: BackgroundApiRequest) => {
        return useGenericApi<GetInsuranceCompanies.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
        );
    };

    export const callCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return callGenericApi<any, JobInfo>(
            getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest),
            undefined,
        );
    };

    export const useCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return useGenericApi<any, JobInfo>(getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest));
    };
}

export namespace GetInsuranceTypes {
    export interface Request {}

    export interface InsuranceTypeInfo {
        Id: number;
        Code: string;
        Name: string;
        DefaultTaxRate: number;
        DefaultCommissionRate: number;
        CountryCode: string;
    }

    export const Localization = translate({});

    export const useLocalization = () => {
        return useGenericLocalization(Localization);
    };

    export const URL = "API/V1/LocationSpecific/GetInsuranceTypes";

    export const ValidateURL = "ValidateAPI/V1/LocationSpecific/GetInsuranceTypes";

    export const BackgroundURL = "BackgroundAPI/V1/LocationSpecific/GetInsuranceTypes";

    export const CheckBackgroundURL = "CheckBackgroundAPI/V1/LocationSpecific/GetInsuranceTypes";

    export const callApi = (request: GetInsuranceTypes.Request) => {
        return callGenericApi<GetInsuranceTypes.Request, GetInsuranceTypes.InsuranceTypeInfo[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<GetInsuranceTypes.Request, GetInsuranceTypes.InsuranceTypeInfo[]>(URL);
    };

    export const callValidateApi = (request: GetInsuranceTypes.Request) => {
        return callGenericApi<GetInsuranceTypes.Request, ValidationResult>(ValidateURL, request);
    };

    export const useValidateApi = () => {
        return useGenericApi<GetInsuranceTypes.Request, ValidationResult>(ValidateURL);
    };

    export const callBackgroundApi = (
        request: GetInsuranceTypes.Request,
        backgroundApiRequest?: BackgroundApiRequest,
    ) => {
        return callGenericApi<GetInsuranceTypes.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
            request,
        );
    };

    export const useBackgroundApi = (backgroundApiRequest?: BackgroundApiRequest) => {
        return useGenericApi<GetInsuranceTypes.Request, string>(
            getUrlWithQueryString(BackgroundURL, backgroundApiRequest),
        );
    };

    export const callCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return callGenericApi<any, JobInfo>(
            getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest),
            undefined,
        );
    };

    export const useCheckBackgroundApi = (checkBackgroundApiRequest?: CheckBackgroundApiRequest) => {
        return useGenericApi<any, JobInfo>(getUrlWithQueryString(CheckBackgroundURL, checkBackgroundApiRequest));
    };
}
