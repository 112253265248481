import {
    Checkbox,
    CommandBar,
    ContextualMenuItemType,
    DefaultButton,
    Dialog,
    DialogFooter,
    IButtonStyles,
    ICommandBarItemProps,
    IContextualMenuItem,
    IInCoreLocalization,
    ModalSpinner,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
    Theme,
    generateUuid,
    useInCoreContext,
    useInCoreLocalization,
    usePermissionService,
    useTheme,
} from "@in-core";
import {
    AggregateDataType,
    Filter,
    FilterGroupOperator,
    FilterValueOperator,
    GetDataRequest,
    Group,
    Pagination,
    Permission,
    Selector,
    Sort,
} from "@in-core/api";
import {
    DataType,
    DeleteView,
    EntityDescriptor,
    GetData,
    GetEntityDescriptor,
    GetUserCurrentView,
    GetViews,
    SaveView,
    SetDefaultView,
    SetUserCurrentView,
    ViewData,
    ViewInfo,
} from "@in-core/api/Entity";
import useOnChange from "@in-core/hooks/useOnChange";
import { areObjectsEqual, getKeyString, isObject, parseKey } from "@in-core/Utils";
import { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
import DataTable, { IDataTableColumn, IDataTableProps, ISelectionChangeItem, IViewData } from "../DataTable";
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import InnerPermission from "./InnerPermission";
import RouteListener from "./RouteListener";
import * as qs from "query-string";
import ChildTitle from "./ChildTitle";
import RelatedDataFilter from "./RelatedDataFilter";
import { ActivityInfo, activityContext } from "@in-core/in-core-app/Shell/Activity";
import axios from "axios";
import { LogActivity } from "@in-core/api/Shell";
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandler from "@in-core/in-core-app/ErrorHandler";

const DefaultNumberOfColumns = 20;

export interface IEntityDataTableProps<TData = any>
    extends Omit<IDataTableProps<TData>, "columns" | "idProperties" | "descriptionProperty"> {
    entity: string;
    routable?: boolean;
    columns?: IDataTableColumn<TData>[];
    modifyColumn?: (column: IDataTableColumn<TData>) => IDataTableColumn<TData>;
    innerComponents?: {
        [x: string]: React.ReactElement;
    };
    onItemSelected?: (key: any) => void;
    displayedInnerPermission?: string;
    onDisplayedInnerPermissionRedirect?: (innerPermission: string | undefined, selectedKeys: any[]) => void;
    onDisplayedInnerPermissionClose?: () => void;
    onDisplayedInnerPermissionSuccess?: (keys?: any[], data?: any) => void;
}

const EntityDataTable = <TData extends object = any>(props: IEntityDataTableProps<TData>) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();
    const { onRenderContent } = useInCoreContext();
    const permissionService = usePermissionService();
    const getEntityDescriptorApi = GetEntityDescriptor.useApi();
    const [innerPermission, setInnerPermission] = useState<string | undefined>(props.displayedInnerPermission);
    const [dataTableSelectedKeys, setDataTableSelectedKeys] = useState<any[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
    const [sort, setSort] = useState<Sort>();
    const [group, setGroup] = useState<Group>();
    const [filters, setFilters] = useState<Record<string, Filter>>(props.defaultFilters ?? {});
    const [selectors, setSelectors] = useState<Record<string, Selector>>();
    const [aggregateDataTypes, setAggregateDataTypes] = useState<Record<string, AggregateDataType[]>>(
        props.defaultAggregateDataTypes ?? {},
    );
    const [pagination, setPagination] = useState<Pagination | undefined>(props.defaultPagination);
    const navigate = useNavigate();
    const location = useLocation();
    const [getDataSignal, setGetDataSignal] = useState(generateUuid());

    const addOrEditViewApi = SaveView.useApi();
    const updateViewApi = SaveView.useApi();
    const deleteViewApi = DeleteView.useApi();
    const setDefaultViewApi = SetDefaultView.useApi();
    const getUserCurrentViewApi = GetUserCurrentView.useApi();
    const [viewId, setViewId] = useState<string | null>(null);
    const [views, setViews] = useState<ViewInfo[]>();
    const [saveViewId, setSaveViewId] = useState<string>();
    const [saveViewName, setSaveViewName] = useState<string>();
    const [saveViewData, setSaveViewData] = useState<IViewData>();
    const [saveViewIsDefaultForUser, setSaveViewIdDefaultForUser] = useState<boolean>();
    const [saveViewIsPublic, setSaveViewIdPublic] = useState<boolean>();
    const [saveViewIsDefaultForPublic, setSaveViewIdDefaultForPublic] = useState<boolean>();
    const [deleteConfirmationViewId, setDeleteConfirmationViewId] = useState<string>();
    const emptyViewDataRef = useRef<ViewData | undefined>(undefined);
    const [activityInfo, setActivityInfo] = useState<ActivityInfo>();
    const [navigation, setNavigation] = useState<{
        navigationPermissionId: string;
        itemId: any;
        foreignKey: any;
        isLoadingForeignKey: boolean;
    }>();

    const permission = useMemo(() => {
        return permissionService.getPermission(props.entity)!;
    }, [props.entity]);

    const authorizedChildPermissions = useMemo(() => {
        return permissionService.getPermission(props.entity)!.ChildPermissions.filter((x) => {
            return permissionService.isAuthorized(x.CompleteId);
        });
    }, [props.entity]);

    const getDefaultViewData = (
        entityDescriptor: EntityDescriptor,
        currentViewData: ViewData | undefined,
    ): ViewData => {
        const defaultSort: Sort | undefined =
            entityDescriptor.PrimaryKey && entityDescriptor.PrimaryKey.PropertyIds.length > 0
                ? { Id: entityDescriptor.PrimaryKey.PropertyIds[0], IsDescending: false }
                : undefined;

        const defaultSelectors: Record<string, Selector> = {};

        const propertyOrNavigationChildPermissions = authorizedChildPermissions.filter((x) => {
            return x.Metadata.SpecialType === "Property" || x.Metadata.SpecialType === "Navigation";
        });

        const primaryKeyChildPermissions = propertyOrNavigationChildPermissions
            .filter((x) => {
                return x.Metadata.IsPrimaryKey === true;
            })
            .sort((a, b) => {
                if (a.Metadata.Order !== undefined || b.Metadata.Order !== undefined) {
                    return (
                        ((a.Metadata.Order as number | undefined) ?? Number.MAX_VALUE) -
                        ((b.Metadata.Order as number | undefined) ?? Number.MAX_VALUE)
                    );
                }

                return a.Id < b.Id ? -1 : a.Id > b.Id ? 1 : 0;
            });

        const descriptionPropertyChildPermissions = propertyOrNavigationChildPermissions
            .filter((x) => {
                return x.Metadata.IsDescriptionProperty === true;
            })
            .sort((a, b) => {
                if (a.Metadata.Order !== undefined || b.Metadata.Order !== undefined) {
                    return (
                        ((a.Metadata.Order as number | undefined) ?? Number.MAX_VALUE) -
                        ((b.Metadata.Order as number | undefined) ?? Number.MAX_VALUE)
                    );
                }

                return a.Id < b.Id ? -1 : a.Id > b.Id ? 1 : 0;
            });

        const otherChildPermissions = propertyOrNavigationChildPermissions
            .filter((x) => {
                return x.Metadata.IdPrimaryKey !== true && x.Metadata.isDescriptionProperty !== true;
            })
            .sort((a, b) => {
                if (a.Metadata.Order !== undefined || b.Metadata.Order !== undefined) {
                    return (
                        ((a.Metadata.Order as number | undefined) ?? Number.MAX_VALUE) -
                        ((b.Metadata.Order as number | undefined) ?? Number.MAX_VALUE)
                    );
                }

                return a.Id < b.Id ? -1 : a.Id > b.Id ? 1 : 0;
            });

        const allChildPermissions = primaryKeyChildPermissions
            .concat(descriptionPropertyChildPermissions)
            .concat(otherChildPermissions)
            .slice(0, DefaultNumberOfColumns);

        for (const childPermission of allChildPermissions) {
            if (childPermission.Metadata.SpecialType === "Property") {
                const propertyDescriptor = entityDescriptor.Properties.find((y) => {
                    return y.Id === childPermission.Id;
                })!;

                defaultSelectors[childPermission.Id] = {
                    Id: propertyDescriptor.Id,
                    Value: {},
                };

                continue;
            }

            if (childPermission.Metadata.SpecialType === "Navigation") {
                const navigationDescriptor = entityDescriptor.Navigations.find((y) => {
                    return y.Id === childPermission.Id;
                })!;

                const groupSelectorItems: Selector[] = [];

                navigationDescriptor.PrimaryKey?.PropertyIds.forEach((y) => {
                    groupSelectorItems.push({
                        Id: y,
                        Value: {},
                    });
                });

                groupSelectorItems.push(
                    ...navigationDescriptor.DescriptionPropertyIds.map((x) => {
                        return {
                            Id: x,
                            Value: {},
                        };
                    }),
                );

                defaultSelectors[childPermission.Id] = {
                    Id: navigationDescriptor.Id,
                    Group: {
                        Items: groupSelectorItems,
                    },
                };

                continue;
            }

            throw Error("");
        }

        return {
            Sort: defaultSort,
            Selectors: currentViewData?.Selectors ?? defaultSelectors,
        };
    };

    useOnChange(async () => {
        const [getEntityDescriptionResponse, getViewsResponse, getUserCurrentViewResponse] = await Promise.all([
            getEntityDescriptorApi.call({ Permission: props.entity }),
            GetViews.callApi({ Permission: props.entity }),
            getUserCurrentViewApi.call({ Permission: props.entity }),
        ]);

        emptyViewDataRef.current = getDefaultViewData(
            getEntityDescriptionResponse.data!,
            getUserCurrentViewResponse.data,
        );

        const defaultView =
            getViewsResponse.data!.find((x) => {
                return x.IsDefaultForCurrentUser;
            }) ??
            getViewsResponse.data!.find((x) => {
                return x.IsDefaultForPublic;
            });

        setViews(getViewsResponse.data!);
        setViewId(defaultView?.Id ?? null);
        setCompleteFilters(defaultView?.ViewData?.Filters ?? emptyViewDataRef.current.Filters ?? {});
        setCompleteSelectors(defaultView?.ViewData?.Selectors ?? emptyViewDataRef.current.Selectors ?? {});
        setCompleteGroup(defaultView?.ViewData?.Group ?? emptyViewDataRef.current.Group);
        setCompleteSort(defaultView?.ViewData?.Sort ?? emptyViewDataRef.current.Sort);
        setCompletePagination(
            (defaultView?.ViewData?.PaginationCount ?? emptyViewDataRef.current.PaginationCount) === undefined
                ? undefined
                : {
                      ItemsPerPage: (defaultView?.ViewData?.PaginationCount ??
                          emptyViewDataRef.current.PaginationCount)!,
                      PageIndex: 0,
                  },
        );
        setCompleteAggregateDataTypes(
            defaultView?.ViewData?.AggregateDataTypes ?? emptyViewDataRef.current.AggregateDataTypes ?? {},
        );
    }, [props.entity]);

    useOnChange(() => {
        setDataTableSelectedKeys(props.selectedKeys ?? []);
    }, [props.selectedKeys]);

    useOnChange(() => {
        clearActivity();

        if (innerPermission) {
            const newInnerPermission = permission.ChildPermissions.find((x) => {
                return x.Id === innerPermission;
            });

            if (newInnerPermission) {
                createActivity(newInnerPermission.CompleteId);
            }
        }
    }, [innerPermission]);

    const refresh = useCallback(() => {
        setGetDataSignal(generateUuid());
    }, []);

    const getData = useCallback(
        async (request: GetDataRequest) => {
            const result = await GetData.callApi({ ...request, Permission: props.entity });
            return result.data!;
        },
        [props.entity],
    );

    const getDefaultSort = useCallback<() => Sort | undefined>(() => {
        return getEntityDescriptorApi.data?.PrimaryKey && getEntityDescriptorApi.data.PrimaryKey.PropertyIds.length > 0
            ? { Id: getEntityDescriptorApi.data.PrimaryKey.PropertyIds[0], IsDescending: false }
            : undefined;
    }, [getEntityDescriptorApi.data]);

    const setCompleteInnerPermission = useCallback(
        (innerPermission: string | undefined, selectedKeys: any[]) => {
            if (props.routable) {
                if (!innerPermission) {
                    const qsObject = {
                        ...qs.parse(location.search),
                    };

                    delete qsObject.selectedKeys;

                    const qsString = qs.stringify(qsObject);

                    navigate({ pathname: "", search: qsString });
                    return;
                }

                const qsObject = {
                    ...qs.parse(location.search),
                };

                if (selectedKeys.length > 0) {
                    qsObject.selectedKeys = selectedKeys.map((x) => {
                        return getKeyString(x, getEntityDescriptorApi.data?.PrimaryKey?.PropertyIds ?? []);
                    });
                } else {
                    delete qsObject.selectedKeys;
                }

                const qsString = qs.stringify(qsObject);

                navigate({ pathname: innerPermission, search: qsString });
            } else {
                setInnerPermission(innerPermission);
                setSelectedKeys(selectedKeys);
            }
        },
        [props.routable, navigate, getEntityDescriptorApi.data, location],
    );

    const setCompleteSort = useCallback(
        (sort?: Sort) => {
            const actualSort = sort ?? getDefaultSort();

            setSort(actualSort);

            props.onSortChanged && props.onSortChanged(actualSort);
        },
        [props.onSortChanged, getDefaultSort],
    );

    const setCompleteGroup = useCallback(
        (group?: Group) => {
            setGroup(group);

            props.onGroupChanged && props.onGroupChanged(group);
        },
        [props.onGroupChanged],
    );

    const setCompletePagination = useCallback(
        (pagination?: Pagination) => {
            setPagination(pagination);

            props.onPaginationChanged && props.onPaginationChanged(pagination);
        },
        [props.onPaginationChanged],
    );

    const setCompleteFilters = useCallback(
        (filters: Record<string, Filter>) => {
            setFilters(filters);

            props.onFiltersChanged && props.onFiltersChanged(filters);
        },
        [props.onFiltersChanged],
    );

    const setCompleteSelectors = useCallback(
        (selectors: Record<string, Selector>) => {
            setSelectors(selectors);

            props.onSelectorsChanged && props.onSelectorsChanged(selectors);

            if (viewId === null) {
                SetUserCurrentView.callApi({ Permission: props.entity, ViewData: { Selectors: selectors } });
            }
        },
        [props.onSelectorsChanged, viewId],
    );

    const setCompleteAggregateDataTypes = useCallback(
        (aggregateDataTypes: Record<string, AggregateDataType[]>) => {
            setAggregateDataTypes(aggregateDataTypes);

            props.onAggregateDataTypesChanged && props.onAggregateDataTypesChanged(aggregateDataTypes);
        },
        [props.onAggregateDataTypesChanged],
    );

    const setCompleteViewId = useCallback(
        (viewId: string | number | null) => {
            setViewId(viewId as string | null);

            props.onViewIdChanged && props.onViewIdChanged(viewId);
        },
        [props.onViewIdChanged],
    );

    const getContextMenu = (key: any) => {
        const isKeyInCurrentlySelectedKeys = dataTableSelectedKeys.some((x: any) => {
            return areObjectsEqual(x, key);
        });

        return getContextMenuItems(
            permission,
            permissionService.isAuthorized,
            setCompleteInnerPermission,
            isKeyInCurrentlySelectedKeys ? dataTableSelectedKeys : [key],
            setCompleteNavigation,
            localization,
        );
    };

    const columns: IDataTableColumn[] | undefined = useMemo(() => {
        if (getEntityDescriptorApi.data === undefined || selectors === undefined) {
            return undefined;
        }

        const propertyOrNavigationChildPermissions = authorizedChildPermissions.filter((x) => {
            return x.Metadata.SpecialType === "Property" || x.Metadata.SpecialType === "Navigation";
        });

        const selectorKeys = Object.keys(selectors);

        return propertyOrNavigationChildPermissions
            .sort((a, b) => {
                return selectorKeys.indexOf(a.Id) - selectorKeys.indexOf(b.Id);
            })
            .map((childPermission) => {
                if (childPermission.Metadata.SpecialType === "Property") {
                    const propertyDescriptor = getEntityDescriptorApi.data?.Properties.find((y) => {
                        return y.Id === childPermission.Id;
                    })!;

                    const result: IDataTableColumn = {
                        id: childPermission.Id,
                        property: childPermission.Id,
                        name: propertyDescriptor.Display?.Name ?? childPermission.Id,
                        dataType: propertyDescriptor.DataType,
                        allowedValues:
                            propertyDescriptor.DataType === DataType.Enum
                                ? propertyDescriptor.EnumValues.map((x) => {
                                      return { key: x.Key, textKey: x.TextKey, text: x.Text };
                                  })
                                : undefined,
                        isSelectable:
                            !getEntityDescriptorApi.data!.PrimaryKey ||
                            !getEntityDescriptorApi.data!.PrimaryKey.PropertyIds.includes(propertyDescriptor.Id),
                        isSearchable:
                            (!!getEntityDescriptorApi.data!.PrimaryKey &&
                                getEntityDescriptorApi.data!.PrimaryKey.PropertyIds.includes(propertyDescriptor.Id)) ||
                            getEntityDescriptorApi.data!.DescriptionPropertyIds.includes(propertyDescriptor.Id),
                    };

                    return result;
                }

                if (childPermission.Metadata.SpecialType === "Navigation") {
                    const navigationDescriptor = getEntityDescriptorApi.data?.Navigations.find((y) => {
                        return y.Id === childPermission.Id;
                    })!;

                    const groupSelectorItems: Selector[] = [];

                    navigationDescriptor.PrimaryKey?.PropertyIds.forEach((y) => {
                        groupSelectorItems.push({
                            Id: y,
                            Value: {},
                        });
                    });

                    groupSelectorItems.push(
                        ...navigationDescriptor.DescriptionPropertyIds.map((x) => {
                            return {
                                Id: x,
                                Value: {},
                            };
                        }),
                    );

                    const isSortable =
                        !!childPermission.Metadata.ForeignKeyProperties &&
                        childPermission.Metadata.ForeignKeyProperties.length === 1 &&
                        !!childPermission.Metadata.TargetPrimaryKey &&
                        childPermission.Metadata.TargetPrimaryKey.length === 1;

                    const isGroupable =
                        !!childPermission.Metadata.ForeignKeyProperties &&
                        childPermission.Metadata.ForeignKeyProperties.length === 1 &&
                        !!childPermission.Metadata.TargetPrimaryKey &&
                        childPermission.Metadata.TargetPrimaryKey.length === 1;

                    const result: IDataTableColumn = {
                        id: childPermission.Id,
                        name: navigationDescriptor.Display?.Name ?? childPermission.Id,
                        icon: "TableLink",
                        defaultSelector: {
                            Id: navigationDescriptor.Id,
                            Group: {
                                Items: groupSelectorItems,
                            },
                        },
                        getValue: (item) => {
                            if (item[navigationDescriptor.Id] === undefined) {
                                return null;
                            }

                            const keySegment = navigationDescriptor.PrimaryKey?.PropertyIds.map((y) => {
                                return item[navigationDescriptor.Id][y];
                            }).join(", ");

                            if (navigationDescriptor.DescriptionPropertyIds.length === 0) {
                                return keySegment;
                            }

                            const descriptionSegment = navigationDescriptor.DescriptionPropertyIds.map((x) => {
                                return item[navigationDescriptor.Id][x];
                            }).join(", ");

                            return `${descriptionSegment} (${keySegment})`;
                        },
                        onRenderFilter: (filter, onChange) => {
                            return (
                                <RelatedDataFilter
                                    navigationDescriptor={navigationDescriptor}
                                    filter={filter}
                                    onFilterChanged={onChange}
                                />
                            );
                        },
                        isSortable: isSortable,
                        sortId: isSortable
                            ? `${childPermission.Id}.${
                                  childPermission.Metadata.TargetDescriptionProperty ??
                                  childPermission.Metadata.TargetPrimaryKey[0]
                              }`
                            : undefined,
                        isGroupable: isGroupable,
                        groupId: isGroupable
                            ? `${childPermission.Id}.${childPermission.Metadata.TargetPrimaryKey[0]}`
                            : undefined,
                        isSearchable: false,
                    };

                    return result;
                }

                throw Error("");
            });
    }, [getEntityDescriptorApi.data, selectors]);

    const onDataTableSelectedKeysChange = useCallback(
        (items: ISelectionChangeItem[]) => {
            if (getEntityDescriptorApi.data === undefined) {
                return;
            }

            setDataTableSelectedKeys((prevSelectedKeys) => {
                return [
                    ...prevSelectedKeys.filter((x) => {
                        return !items
                            .filter((y) => {
                                return !y.isSelected;
                            })
                            .map((y) => {
                                return getKeyString(y.key, getEntityDescriptorApi.data?.PrimaryKey?.PropertyIds ?? []);
                            })
                            .includes(getKeyString(x, getEntityDescriptorApi.data?.PrimaryKey?.PropertyIds ?? []));
                    }),
                    ...items
                        .filter((x) => {
                            return x.isSelected;
                        })
                        .map((x) => {
                            return x.key;
                        }),
                ];
            });

            props.onSelectionChanged && props.onSelectionChanged(items);
        },
        [getEntityDescriptorApi.data],
    );

    const redirect = useCallback(
        (innerPermission: string | string[], selectedKey?: any | any[]) => {
            const actualInnerPermissions = Array.isArray(innerPermission) ? innerPermission : [innerPermission];

            let newInnerPermission: string | undefined = undefined;
            let i = 0;
            while (newInnerPermission === undefined && i < actualInnerPermissions.length) {
                if (permissionService.isAuthorized(`${permission.CompleteId}.${actualInnerPermissions[i]}`)) {
                    newInnerPermission = actualInnerPermissions[i];
                }

                i++;
            }

            let actualSelectedKeys =
                selectedKey === undefined ? undefined : Array.isArray(selectedKey) ? selectedKey : [selectedKey];

            if (
                getEntityDescriptorApi.data?.PrimaryKey?.PropertyIds?.length === 1 &&
                actualSelectedKeys !== undefined
            ) {
                actualSelectedKeys = actualSelectedKeys.map((x) => {
                    if (x === undefined || !isObject(x)) {
                        return x;
                    }

                    return x[getEntityDescriptorApi.data!.PrimaryKey!.PropertyIds[0]];
                });
            }

            if (props.displayedInnerPermission !== undefined) {
                if (props.onDisplayedInnerPermissionRedirect) {
                    props.onDisplayedInnerPermissionRedirect(
                        newInnerPermission,
                        newInnerPermission === undefined ? [] : actualSelectedKeys ?? [],
                    );
                }

                return;
            }

            setCompleteInnerPermission(
                newInnerPermission,
                newInnerPermission === undefined ? [] : actualSelectedKeys ?? [],
            );

            if (newInnerPermission === undefined) {
                refresh();
            }
        },
        [
            props.displayedInnerPermission,
            props.onDisplayedInnerPermissionRedirect,
            setCompleteInnerPermission,
            permission.CompleteId,
            refresh,
            permissionService,
        ],
    );

    const close = useCallback(() => {
        if (props.displayedInnerPermission !== undefined) {
            if (props.onDisplayedInnerPermissionClose) {
                props.onDisplayedInnerPermissionClose();
            }

            return;
        }

        setCompleteInnerPermission(undefined, []);
        refresh();
    }, [props.displayedInnerPermission, props.onDisplayedInnerPermissionRedirect, setCompleteInnerPermission, refresh]);

    const invokeItem = useCallback(
        (key: any) => {
            redirect(["Update", "Read"], key);
        },
        [redirect],
    );

    const getKeyObjects = useCallback(() => {
        if (getEntityDescriptorApi) {
            return selectedKeys.map((x) => {
                if (!getEntityDescriptorApi.data?.PrimaryKey?.PropertyIds) {
                    return undefined;
                }

                const obj: any = {};

                [...getEntityDescriptorApi.data.PrimaryKey.PropertyIds].sort().forEach((y) => {
                    obj[y] = x[y] ?? x;
                });

                return obj;
            });
        }
    }, [selectedKeys, getEntityDescriptorApi.data]);

    const createActivity = useCallback(
        (activityName: string, keys?: any[]) => {
            const newActivityInfo: ActivityInfo = {
                id: generateUuid(),
                name: activityName,
                keys: keys ?? getKeyObjects(),
            };

            axios.defaults.headers.common["X-ACTIVITY-ID"] = newActivityInfo.id;
            axios.defaults.headers.common["X-ACTIVITY-NAME"] = newActivityInfo.name;

            setActivityInfo(newActivityInfo);
        },
        [getKeyObjects],
    );

    const clearActivity = useCallback(() => {
        delete axios.defaults.headers.common["X-ACTIVITY-ID"];
        delete axios.defaults.headers.common["X-ACTIVITY-NAME"];

        setActivityInfo(undefined);
    }, []);

    const logActivity = useCallback(
        async (keys?: any[], data?: string, dontCreateNewActivity?: boolean) => {
            if (!activityInfo) {
                return;
            }

            await LogActivity.callApi({ Keys: keys ?? activityInfo.keys ?? getKeyObjects(), Data: data });

            const newActivity: ActivityInfo = { ...activityInfo };

            clearActivity();

            if (dontCreateNewActivity !== true) {
                createActivity(newActivity.name, newActivity.keys);
            }
        },
        [activityInfo, getKeyObjects, clearActivity, createActivity],
    );

    const success = useCallback(
        (keys?: any[], data?: any) => {
            logActivity(keys, data);

            if (props.displayedInnerPermission && props.onDisplayedInnerPermissionSuccess) {
                props.onDisplayedInnerPermissionSuccess(keys, data);
            }
        },
        [logActivity, props.displayedInnerPermission, props.onDisplayedInnerPermissionSuccess],
    );

    const hasManagePrivateViewsPermission = useMemo(() => {
        return permissionService.isAuthorized(`${props.entity}.ManagePrivateViews`);
    }, [props.entity]);

    const hasManagePublicViewsPermission = useMemo(() => {
        return permissionService.isAuthorized(`${props.entity}.ManagePublicViews`);
    }, [props.entity]);

    const setCompleteNavigation = async (navigationPermissionId: string, itemId: any) => {
        const navigationDescriptor = getEntityDescriptorApi.data?.Navigations.find((x) => {
            return x.Id === navigationPermissionId;
        });

        if (!getEntityDescriptorApi.data || !navigationDescriptor || !navigationDescriptor.IsOnDependent) {
            setNavigation({
                navigationPermissionId: navigationPermissionId,
                itemId: itemId,
                foreignKey: undefined,
                isLoadingForeignKey: false,
            });

            return;
        }

        setNavigation({
            navigationPermissionId: navigationPermissionId,
            itemId: itemId,
            foreignKey: undefined,
            isLoadingForeignKey: true,
        });

        const getForeignKeyDataResponse = await GetData.callApi({
            Permission: props.entity,
            Filter:
                getEntityDescriptorApi.data.PrimaryKey!.PropertyIds.length === 1
                    ? {
                          IsNegated: false,
                          Value: {
                              LeftOperand: { Id: getEntityDescriptorApi.data.PrimaryKey!.PropertyIds[0] },
                              Operator: FilterValueOperator.Equal,
                              RightOperand: { Value: itemId },
                          },
                      }
                    : {
                          IsNegated: false,
                          Group: {
                              Operator: FilterGroupOperator.And,
                              Filters: getEntityDescriptorApi.data.PrimaryKey!.PropertyIds.map((x) => {
                                  return {
                                      IsNegated: false,
                                      Value: {
                                          LeftOperand: { Id: x },
                                          Operator: FilterValueOperator.Equal,
                                          RightOperand: { Value: itemId[x] },
                                      },
                                  };
                              }),
                          },
                      },
            Selector:
                navigationDescriptor.ForeignKey.PropertyIds.length === 1
                    ? {
                          Id: "TargetKey",
                          Value: { ValueId: navigationDescriptor.ForeignKey.PropertyIds[0] },
                      }
                    : {
                          Id: "TargetKey",
                          Group: {
                              Items: navigationDescriptor.ForeignKey.PropertyIds.map((x) => {
                                  return { Id: x, Value: {} };
                              }),
                          },
                      },
        });

        setNavigation({
            navigationPermissionId: navigationPermissionId,
            itemId: itemId,
            foreignKey: getForeignKeyDataResponse.data!.Data[0],
            isLoadingForeignKey: false,
        });
    };

    const getNavigationFilter = (): Filter | undefined => {
        if (!getEntityDescriptorApi.data || !navigation || navigation.isLoadingForeignKey) {
            return undefined;
        }

        const navigationDescriptor = getEntityDescriptorApi.data?.Navigations.find((x) => {
            return x.Id === navigation?.navigationPermissionId;
        });

        if (!navigationDescriptor) {
            return undefined;
        }

        if (navigationDescriptor.IsOnDependent) {
            if (navigation.foreignKey === null) {
                return {
                    IsNegated: false,
                    Value: {
                        LeftOperand: { Value: true },
                        Operator: FilterValueOperator.Equal,
                        RightOperand: { Value: false },
                    },
                };
            }

            return navigationDescriptor.PrimaryKey.PropertyIds.length === 1
                ? {
                      IsNegated: false,
                      Value: {
                          LeftOperand: { Id: navigationDescriptor.PrimaryKey.PropertyIds[0] },
                          Operator: FilterValueOperator.Equal,
                          RightOperand: { Value: navigation.foreignKey },
                      },
                  }
                : {
                      IsNegated: false,
                      Group: {
                          Operator: FilterGroupOperator.And,
                          Filters: navigationDescriptor.PrimaryKey.PropertyIds.map((x) => {
                              return {
                                  IsNegated: false,
                                  Value: {
                                      LeftOperand: { Id: x },
                                      Operator: FilterValueOperator.Equal,
                                      RightOperand: { Value: navigation.foreignKey[x] },
                                  },
                              };
                          }),
                      },
                  };
        }

        return navigationDescriptor.ForeignKey.PropertyIds.length === 1
            ? {
                  IsNegated: false,
                  Value: {
                      LeftOperand: { Id: navigationDescriptor.ForeignKey.PropertyIds[0] },
                      Operator: FilterValueOperator.Equal,
                      RightOperand: { Value: navigation.itemId },
                  },
              }
            : {
                  IsNegated: false,
                  Group: {
                      Operator: FilterGroupOperator.And,
                      Filters: navigationDescriptor.ForeignKey.PropertyIds.map((x) => {
                          return {
                              IsNegated: false,
                              Value: {
                                  LeftOperand: { Id: x },
                                  Operator: FilterValueOperator.Equal,
                                  RightOperand: { Value: navigation.itemId[x] },
                              },
                          };
                      }),
                  },
              };
    };

    if (
        props.displayedInnerPermission === undefined &&
        (getEntityDescriptorApi.isIdle ||
            getEntityDescriptorApi.isLoading ||
            getUserCurrentViewApi.isIdle ||
            getUserCurrentViewApi.isLoading ||
            columns === undefined ||
            selectors === undefined ||
            views === undefined ||
            emptyViewDataRef.current === undefined)
    ) {
        return <Spinner label={localization.EntityDataTable.ConfigurationLoading} />;
    }

    const commandBarButtonStyles: IButtonStyles = {
        root: {
            borderRadius: theme.effects.roundedCorner2,
            height: 32,
            backgroundColor: theme.palette.neutralLighterAlt,
            marginLeft: theme.spacing.s1,
        },
        rootHovered: {
            backgroundColor: theme.palette.themeLighterAlt,
        },
        rootPressed: {
            backgroundColor: theme.palette.themeLighterAlt,
        },
    };

    const actualInnerPermission = permission.ChildPermissions.find((x) => {
        return x.Id === innerPermission;
    });
    const commandBarItems = getCommandBarItems(
        permission,
        permissionService.isAuthorized,
        setCompleteInnerPermission,
        theme,
        dataTableSelectedKeys,
        undefined,
        refresh,
        setCompleteNavigation,
        localization,
    );

    const defaultViewId =
        views?.find((x) => {
            return x.IsDefaultForCurrentUser;
        })?.Id ??
        views?.find((x) => {
            return x.IsDefaultForPublic;
        })?.Id;

    return (
        <>
            {props.displayedInnerPermission === undefined && (
                <Stack styles={{ root: { height: "100%", width: "100%" } }}>
                    <CommandBar
                        items={commandBarItems}
                        overflowButtonProps={{
                            styles: commandBarButtonStyles,
                        }}
                        styles={{
                            root: {
                                padding: 0,
                                borderBottomColor: theme.palette.neutralLight,
                                borderBottomWidth: 1,
                                borderBottomStyle: "solid",
                            },
                            primarySet: {
                                alignItems: "center",
                            },
                            secondarySet: {
                                alignItems: "center",
                            },
                        }}
                    />

                    <Outlet />

                    <DataTable
                        {...props}
                        getDataSignal={getDataSignal}
                        onAddNewItemClicked={
                            permissionService.isAuthorized(`${props.entity}.Create`)
                                ? () => {
                                      setCompleteInnerPermission("Create", []);
                                  }
                                : undefined
                        }
                        columns={
                            props.modifyColumn
                                ? (props.columns ?? columns!).map(props.modifyColumn)
                                : props.columns ?? columns!
                        }
                        sort={props.sort ?? sort}
                        onSortChanged={setCompleteSort}
                        group={props.group ?? group}
                        onGroupChanged={setCompleteGroup}
                        filters={props.filters ?? filters}
                        onFiltersChanged={setCompleteFilters}
                        selectors={selectors}
                        onSelectorsChanged={setCompleteSelectors}
                        aggregateDataTypes={aggregateDataTypes}
                        onAggregateDataTypesChanged={setCompleteAggregateDataTypes}
                        pagination={props.pagination ?? pagination}
                        onPaginationChanged={setCompletePagination}
                        data={props.data ?? getData}
                        idProperties={
                            getEntityDescriptorApi.data?.PrimaryKey?.PropertyIds as (keyof TData)[] | undefined
                        }
                        descriptionProperties={
                            getEntityDescriptorApi.data?.DescriptionPropertyIds as (keyof TData)[] | undefined
                        }
                        selectedKeys={dataTableSelectedKeys}
                        onSelectionChanged={onDataTableSelectedKeysChange}
                        onItemInvoked={props.onItemInvoked ?? invokeItem}
                        onItemContextMenu={props.onItemContextMenu ?? getContextMenu}
                        viewId={props.viewId ?? viewId}
                        onViewIdChanged={setCompleteViewId}
                        views={views!.map((x) => {
                            return {
                                id: x.Id,
                                name: x.Name,
                                isDefault: x.Id === defaultViewId,
                                disableEdit: x.IsPublic
                                    ? !hasManagePublicViewsPermission
                                    : !hasManagePrivateViewsPermission,
                                disableUpdate: x.IsPublic
                                    ? !hasManagePublicViewsPermission
                                    : !hasManagePrivateViewsPermission,
                                disableDelete: x.IsPublic
                                    ? !hasManagePublicViewsPermission
                                    : !hasManagePrivateViewsPermission,
                                viewData: {
                                    filters: x.ViewData?.Filters,
                                    selectors: x.ViewData?.Selectors,
                                    group: x.ViewData?.Group,
                                    sort: x.ViewData?.Sort,
                                    paginationCount: x.ViewData?.PaginationCount,
                                    aggregateDataTypes: x.ViewData?.AggregateDataTypes,
                                },
                                disableSetAsDefault:
                                    views?.find((x) => {
                                        return x.IsDefaultForCurrentUser;
                                    }) === undefined &&
                                    x.IsPublic &&
                                    x.IsDefaultForPublic,
                            };
                        })}
                        onAddView={
                            hasManagePrivateViewsPermission || hasManagePublicViewsPermission
                                ? (viewData) => {
                                      setSaveViewName("");
                                      setSaveViewData(viewData);
                                      setSaveViewIdDefaultForUser(false);
                                      setSaveViewIdPublic(false);
                                      setSaveViewIdDefaultForPublic(false);
                                  }
                                : undefined
                        }
                        onEditView={
                            hasManagePrivateViewsPermission || hasManagePublicViewsPermission
                                ? (viewId) => {
                                      const view = views!.find((x) => {
                                          return x.Id === viewId;
                                      });

                                      if (!view) {
                                          return;
                                      }

                                      setSaveViewId(view.Id);
                                      setSaveViewName(view.Name);
                                      setSaveViewData({
                                          filters: view.ViewData?.Filters,
                                          selectors: view.ViewData?.Selectors,
                                          group: view.ViewData?.Group,
                                          sort: view.ViewData?.Sort,
                                          paginationCount: view.ViewData?.PaginationCount,
                                          aggregateDataTypes: view.ViewData?.AggregateDataTypes,
                                      });
                                      setSaveViewIdDefaultForUser(view.IsDefaultForCurrentUser);
                                      setSaveViewIdPublic(view.IsPublic);
                                      setSaveViewIdDefaultForPublic(view.IsDefaultForPublic);
                                  }
                                : undefined
                        }
                        onUpdateView={
                            hasManagePrivateViewsPermission || hasManagePublicViewsPermission
                                ? async (viewId, viewData) => {
                                      const existingView = views!.find((x) => {
                                          return x.Id === viewId;
                                      });

                                      if (!existingView) {
                                          return;
                                      }

                                      const updateViewApiResponse = await updateViewApi.call({
                                          Permission: props.entity,
                                          Id: viewId as string,
                                          IsPublic: existingView.IsPublic,
                                          Name: existingView.Name,
                                          IsDefaultForCurrentUser: existingView.IsDefaultForCurrentUser,
                                          IsDefaultForPublic: existingView.IsDefaultForPublic,
                                          ViewData: viewData
                                              ? {
                                                    Filters: viewData.filters,
                                                    Selectors: viewData.selectors,
                                                    Group: viewData.group,
                                                    Sort: viewData.sort,
                                                    PaginationCount: viewData.paginationCount,
                                                    AggregateDataTypes: viewData.aggregateDataTypes,
                                                }
                                              : undefined,
                                      });

                                      setViews((prevViews) => {
                                          return [
                                              ...(prevViews?.filter((x) => {
                                                  return x.Id !== viewId;
                                              }) ?? []),
                                              updateViewApiResponse.data!,
                                          ];
                                      });
                                  }
                                : undefined
                        }
                        onDeleteView={
                            hasManagePrivateViewsPermission || hasManagePublicViewsPermission
                                ? (viewId) => {
                                      setDeleteConfirmationViewId(viewId.toString());
                                  }
                                : undefined
                        }
                        onSetViewAsDefault={async (viewId) => {
                            await setDefaultViewApi.call({
                                Permission: props.entity,
                                Id: viewId as string | undefined,
                            });

                            setViews((prevViews) => {
                                if (viewId === undefined) {
                                    return prevViews?.map((x) => {
                                        return { ...x, IsDefaultForCurrentUser: false };
                                    });
                                }

                                const viewIndex = prevViews?.findIndex((x) => {
                                    return x.Id === viewId;
                                });

                                if (viewIndex === undefined || viewIndex === -1) {
                                    return prevViews;
                                }

                                const view = prevViews![viewIndex];

                                return [
                                    ...prevViews!.slice(0, viewIndex),
                                    { ...view, IsDefaultForCurrentUser: true },
                                    ...prevViews!.slice(viewIndex + 1),
                                ];
                            });
                        }}
                        emptyViewData={{
                            filters: emptyViewDataRef.current!.Filters,
                            selectors: emptyViewDataRef.current!.Selectors,
                            group: emptyViewDataRef.current!.Group,
                            sort: emptyViewDataRef.current!.Sort,
                            paginationCount: emptyViewDataRef.current!.PaginationCount,
                            aggregateDataTypes: emptyViewDataRef.current!.AggregateDataTypes,
                        }}
                    />

                    <Panel
                        isOpen={navigation !== undefined}
                        headerText={`${
                            permission.ChildPermissions.find((x) => {
                                return x.Id === navigation?.navigationPermissionId;
                            })?.Name ?? ""
                        } (${navigation?.itemId ?? ""})`}
                        onDismiss={() => {
                            setNavigation(undefined);
                        }}
                        type={PanelType.large}
                        styles={{
                            scrollableContent: { flex: 1, display: "flex", flexDirection: "column" },
                            content: { flex: 1, padding: 0 },
                        }}
                    >
                        <entityDataTableContext.Provider
                            value={{
                                permission: permission,
                                innerPermission: actualInnerPermission,
                                entityDescriptor: getEntityDescriptorApi.data!,
                                selectedKeys: selectedKeys,
                                success: success,
                                redirect: redirect,
                                close: close,
                                refresh: refresh,
                            }}
                        >
                            <activityContext.Provider
                                value={{
                                    activity: activityInfo,
                                    createActivity: createActivity,
                                    clearActivity: clearActivity,
                                    logActivity: logActivity,
                                }}
                            >
                                {navigation && navigation.isLoadingForeignKey === undefined && <Spinner />}

                                {navigation && !navigation.isLoadingForeignKey && (
                                    <EntityDataTable
                                        entity={
                                            permission.ChildPermissions.find((x) => {
                                                return x.Id === navigation?.navigationPermissionId;
                                            })!.Metadata.NavigationTo
                                        }
                                        additionalFilter={getNavigationFilter()}
                                    />
                                )}
                            </activityContext.Provider>
                        </entityDataTableContext.Provider>
                    </Panel>

                    <Dialog
                        hidden={saveViewName === undefined}
                        dialogContentProps={{ title: localization.EntityDataTable.SavingView }}
                        onDismiss={
                            addOrEditViewApi.isLoading
                                ? undefined
                                : () => {
                                      setSaveViewId(undefined);
                                      setSaveViewName(undefined);
                                      setSaveViewData(undefined);
                                      setSaveViewIdDefaultForUser(undefined);
                                      setSaveViewIdPublic(undefined);
                                      setSaveViewIdDefaultForPublic(undefined);
                                  }
                        }
                    >
                        <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                            <TextField
                                label={localization.EntityDataTable.ViewName}
                                value={saveViewName ?? ""}
                                onChange={(_, newValue) => {
                                    setSaveViewName(newValue ?? "");
                                }}
                            />

                            <Checkbox
                                label={localization.EntityDataTable.SetAsDefaultView}
                                isInline
                                checked={saveViewIsDefaultForUser ?? false}
                                onChange={(_, checked) => {
                                    setSaveViewIdDefaultForUser(checked ?? false);
                                }}
                            />

                            {hasManagePublicViewsPermission && (
                                <>
                                    <Checkbox
                                        label={localization.EntityDataTable.SetAsPublicView}
                                        isInline
                                        checked={saveViewIsPublic ?? false}
                                        onChange={(_, checked) => {
                                            setSaveViewIdPublic(checked ?? false);
                                            if ((checked ?? false) === false) {
                                                setSaveViewIdDefaultForPublic(false);
                                            }
                                        }}
                                    />

                                    <Checkbox
                                        label={localization.EntityDataTable.SetAsPublicDefaultView}
                                        isInline
                                        checked={saveViewIsDefaultForPublic ?? false}
                                        disabled={!saveViewIsPublic}
                                        onChange={(_, checked) => {
                                            setSaveViewIdDefaultForPublic(checked ?? false);
                                        }}
                                    />
                                </>
                            )}
                        </Stack>

                        <DialogFooter>
                            {addOrEditViewApi.isLoading && <Spinner size={SpinnerSize.small} />}

                            <PrimaryButton
                                disabled={
                                    !saveViewName || saveViewName.trim().length === 0 || addOrEditViewApi.isLoading
                                }
                                onClick={async () => {
                                    const saveViewApiResponse = await addOrEditViewApi.call({
                                        Permission: props.entity,
                                        Id: saveViewId,
                                        IsPublic: saveViewIsPublic ?? false,
                                        Name: saveViewName!,
                                        IsDefaultForCurrentUser: saveViewIsDefaultForUser ?? false,
                                        IsDefaultForPublic: saveViewIsDefaultForPublic ?? false,
                                        ViewData: saveViewData
                                            ? {
                                                  Filters: saveViewData.filters,
                                                  Selectors: saveViewData.selectors,
                                                  Group: saveViewData.group,
                                                  Sort: saveViewData.sort,
                                                  PaginationCount: saveViewData.paginationCount,
                                                  AggregateDataTypes: saveViewData.aggregateDataTypes,
                                              }
                                            : undefined,
                                    });

                                    setViews((prevViews) => {
                                        const viewIndex = prevViews?.findIndex((x) => {
                                            return x.Id === saveViewApiResponse.data!.Id;
                                        });

                                        if (viewIndex === undefined || viewIndex === -1) {
                                            return [...(prevViews ?? []), saveViewApiResponse.data!];
                                        }

                                        return [
                                            ...prevViews!.slice(0, viewIndex),
                                            saveViewApiResponse.data!,
                                            ...prevViews!.slice(viewIndex + 1),
                                        ];
                                    });

                                    const isAdd = saveViewId === undefined;

                                    setSaveViewId(undefined);
                                    setSaveViewName(undefined);
                                    setSaveViewData(undefined);
                                    setSaveViewIdDefaultForUser(undefined);
                                    setSaveViewIdPublic(undefined);
                                    setSaveViewIdDefaultForPublic(undefined);

                                    if (isAdd) {
                                        setCompleteViewId(saveViewApiResponse.data!.Id);
                                    }
                                }}
                            >
                                {localization.Save}
                            </PrimaryButton>

                            <DefaultButton
                                onClick={() => {
                                    setSaveViewId(undefined);
                                    setSaveViewName(undefined);
                                    setSaveViewData(undefined);
                                    setSaveViewIdDefaultForUser(undefined);
                                    setSaveViewIdPublic(undefined);
                                    setSaveViewIdDefaultForPublic(undefined);
                                }}
                                disabled={addOrEditViewApi.isLoading}
                            >
                                {localization.Cancel}
                            </DefaultButton>
                        </DialogFooter>
                    </Dialog>

                    <Dialog
                        hidden={deleteConfirmationViewId === undefined}
                        dialogContentProps={{
                            title: localization.EntityDataTable.DeletingView,
                            subText: `${localization.EntityDataTable.DeletingViewConfirmation} "${
                                views!.find((x) => {
                                    return x.Id === deleteConfirmationViewId;
                                })?.Name ?? ""
                            }"?`,
                        }}
                        onDismiss={
                            deleteViewApi.isLoading
                                ? undefined
                                : () => {
                                      setDeleteConfirmationViewId(undefined);
                                  }
                        }
                    >
                        <DialogFooter>
                            {deleteViewApi.isLoading && <Spinner size={SpinnerSize.small} />}

                            <PrimaryButton
                                disabled={deleteViewApi.isLoading}
                                onClick={async () => {
                                    const id = deleteConfirmationViewId!;

                                    await deleteViewApi.call({
                                        Permission: props.entity,
                                        Id: id,
                                    });

                                    setViews((prevViews) => {
                                        return prevViews?.filter((x) => {
                                            return x.Id !== id;
                                        });
                                    });
                                    setDeleteConfirmationViewId(undefined);

                                    if ((props.viewId ?? viewId) === id) {
                                        setCompleteViewId(null);
                                    }
                                }}
                            >
                                {localization.Delete}
                            </PrimaryButton>

                            <DefaultButton
                                onClick={() => {
                                    setDeleteConfirmationViewId(undefined);
                                }}
                                disabled={deleteViewApi.isLoading}
                            >
                                {localization.Cancel}
                            </DefaultButton>
                        </DialogFooter>
                    </Dialog>

                    <ModalSpinner isOpen={updateViewApi.isLoading || setDefaultViewApi.isLoading} />

                    {props.routable && (
                        <Routes>
                            <Route
                                path=":innerPermission?/*"
                                element={
                                    <RouteListener
                                        onChange={(newInnerPermission, qsObject) => {
                                            const keyPropertyIds =
                                                getEntityDescriptorApi.data?.PrimaryKey?.PropertyIds ?? [];

                                            setInnerPermission(newInnerPermission);

                                            if (!qsObject.selectedKeys) {
                                                setSelectedKeys([]);
                                            }

                                            if (newInnerPermission && qsObject.selectedKeys) {
                                                let newSelectedKeys: any[] = Array.isArray(qsObject.selectedKeys)
                                                    ? qsObject.selectedKeys.map((x: string) => {
                                                          return keyPropertyIds.length <= 1 ? x : parseKey(x);
                                                      })
                                                    : [
                                                          keyPropertyIds.length <= 1
                                                              ? qsObject.selectedKeys
                                                              : parseKey(qsObject.selectedKeys),
                                                      ];

                                                const isSimpleKey = keyPropertyIds.length <= 1;

                                                keyPropertyIds.forEach((x) => {
                                                    const property = getEntityDescriptorApi.data?.Properties.find(
                                                        (y) => {
                                                            return y.Id === x;
                                                        },
                                                    );

                                                    if (!property) {
                                                        return;
                                                    }

                                                    switch (property.DataType) {
                                                        case DataType.String:
                                                            newSelectedKeys = newSelectedKeys.map((y) => {
                                                                if (y === undefined || y === null) {
                                                                    return undefined;
                                                                }

                                                                if (isSimpleKey) {
                                                                    return y;
                                                                }

                                                                const newKey = { ...y };

                                                                if (newKey[x] === undefined || newKey[x] === null) {
                                                                    return undefined;
                                                                }

                                                                return newKey;
                                                            });
                                                            break;
                                                        case DataType.Number:
                                                            newSelectedKeys = newSelectedKeys.map((y) => {
                                                                if (y === undefined || y === null) {
                                                                    return undefined;
                                                                }

                                                                if (isSimpleKey) {
                                                                    return Number(y);
                                                                }

                                                                const newKey = { ...y };

                                                                if (newKey[x] === undefined || newKey[x] === null) {
                                                                    return undefined;
                                                                }

                                                                newKey[x] = Number(newKey[x]);

                                                                return newKey;
                                                            });
                                                            break;
                                                        case DataType.DateTime:
                                                            newSelectedKeys = newSelectedKeys.map((y) => {
                                                                if (y === undefined || y === null) {
                                                                    return undefined;
                                                                }

                                                                if (isSimpleKey) {
                                                                    return new Date(y);
                                                                }

                                                                const newKey = { ...y };

                                                                if (newKey[x] === undefined || newKey[x] === null) {
                                                                    return undefined;
                                                                }

                                                                newKey[x] = new Date(newKey[x]);

                                                                return newKey;
                                                            });
                                                            break;
                                                        case DataType.Boolean:
                                                            newSelectedKeys = newSelectedKeys.map((y) => {
                                                                if (y === undefined || y === null) {
                                                                    return undefined;
                                                                }

                                                                if (isSimpleKey) {
                                                                    return y.toLowerCase() === "true";
                                                                }

                                                                const newKey = { ...y };

                                                                if (newKey[x] === undefined || newKey[x] === null) {
                                                                    return undefined;
                                                                }

                                                                newKey[x] = newKey[x].toLowerCase() === "true";

                                                                return newKey;
                                                            });
                                                            break;
                                                    }
                                                });

                                                setSelectedKeys(newSelectedKeys);
                                            }
                                        }}
                                    />
                                }
                            />
                        </Routes>
                    )}
                </Stack>
            )}

            <Panel
                isOpen={innerPermission !== undefined}
                headerText={
                    permission.ChildPermissions.find((x) => {
                        return x.Id === innerPermission;
                    })?.Name ?? ""
                }
                onRenderHeader={(renderProps, defaultRender) => {
                    return (
                        <Stack
                            horizontal={true}
                            styles={{ root: { flex: 1, paddingBottom: theme.spacing.s2, overflow: "hidden" } }}
                        >
                            <ChildTitle panelProps={renderProps} defaultRender={defaultRender} />

                            {innerPermission !== "Create" && (
                                <CommandBar
                                    style={{ flex: 1, overflow: "hidden" }}
                                    items={getCommandBarItems(
                                        permission,
                                        permissionService.isAuthorized,
                                        setCompleteInnerPermission,
                                        theme,
                                        selectedKeys,
                                        props.onItemSelected,
                                        refresh,
                                        () => {},
                                        localization,
                                        innerPermission,
                                    )}
                                    overflowButtonProps={{
                                        styles: commandBarButtonStyles,
                                    }}
                                    styles={{
                                        root: {
                                            padding: 0,
                                            height: "auto",
                                        },
                                        primarySet: {
                                            alignItems: "center",
                                        },
                                        secondarySet: {
                                            alignItems: "center",
                                        },
                                    }}
                                />
                            )}
                        </Stack>
                    );
                }}
                onDismiss={() => {
                    if (props.displayedInnerPermission !== undefined) {
                        if (props.onDisplayedInnerPermissionClose) {
                            props.onDisplayedInnerPermissionClose();
                        }

                        return;
                    }

                    setCompleteInnerPermission(undefined, []);
                }}
                type={PanelType.large}
                styles={{ header: { flexGrow: "unset" } }}
            >
                {getEntityDescriptorApi.isIdle ||
                getEntityDescriptorApi.isLoading ||
                getUserCurrentViewApi.isIdle ||
                getUserCurrentViewApi.isLoading ||
                columns === undefined ||
                selectors === undefined ||
                views === undefined ||
                emptyViewDataRef.current === undefined ? (
                    <Spinner />
                ) : (
                    <entityDataTableContext.Provider
                        value={{
                            permission: permission,
                            innerPermission: actualInnerPermission,
                            entityDescriptor: getEntityDescriptorApi.data!,
                            selectedKeys: selectedKeys,
                            success: success,
                            redirect: redirect,
                            close: close,
                            refresh: refresh,
                        }}
                    >
                        <activityContext.Provider
                            value={{
                                activity: activityInfo,
                                createActivity: createActivity,
                                clearActivity: clearActivity,
                                logActivity: logActivity,
                            }}
                        >
                            <ErrorBoundary
                                FallbackComponent={ErrorHandler}
                                resetKeys={[actualInnerPermission?.CompleteId]}
                            >
                                <InnerPermission
                                    routable={props.routable}
                                    permission={permission}
                                    innerPermission={innerPermission!}
                                    innerComponents={props.innerComponents}
                                    onRenderChildContent={onRenderContent}
                                    columns={columns}
                                    additionalFilter={props.additionalFilter}
                                    selectors={selectors}
                                    aggregateDataTypes={aggregateDataTypes}
                                    filters={filters}
                                    sort={sort}
                                    group={group}
                                    pagination={pagination}
                                />
                            </ErrorBoundary>
                        </activityContext.Provider>
                    </entityDataTableContext.Provider>
                )}
            </Panel>
        </>
    );
};

export default EntityDataTable;

const getCommandBarItems = (
    permission: Permission,
    isAuthorized: (permissionId: string) => boolean,
    setInnerPermission: (innerPermission: string, selectedKeys: any[]) => void,
    theme: Theme,
    selectedKeys: any[],
    onItemSelected: ((key: any) => void) | undefined,
    onRefresh: () => void,
    setNavigation: (navigationPermissionId: string, selectedKey: any) => void,
    localization: IInCoreLocalization,
    innerPermission?: string,
): ICommandBarItemProps[] => {
    const commandBarButtonStyles: IButtonStyles = {
        root: {
            borderRadius: theme.effects.roundedCorner2,
            height: 32,
            backgroundColor: theme.palette.neutralLighterAlt,
            marginLeft: theme.spacing.s1,
        },
        rootHovered: {
            backgroundColor: theme.palette.themeLighterAlt,
        },
        rootPressed: {
            backgroundColor: theme.palette.themeLighterAlt,
        },
    };

    const commandBarItems: ICommandBarItemProps[] = [];

    if (onItemSelected) {
        commandBarItems.push({
            key: "select",
            text: localization.Select,
            iconProps: { iconName: "CheckMark" },
            buttonStyles: {
                root: {
                    borderRadius: theme.effects.roundedCorner2,
                    height: 32,
                    backgroundColor: theme.palette.themePrimary,
                    marginLeft: theme.spacing.s1,
                    color: theme.palette.white,
                },
                rootHovered: {
                    backgroundColor: theme.palette.themeSecondary,
                    color: theme.palette.white,
                },
                rootPressed: {
                    backgroundColor: theme.palette.themeSecondary,
                    color: theme.palette.white,
                },
                icon: {
                    color: theme.palette.white,
                },
                iconHovered: {
                    color: theme.palette.white,
                },
                iconPressed: {
                    color: theme.palette.white,
                },
            },
            onClick: () => {
                onItemSelected(selectedKeys[0]);
            },
        });
    }

    if (!innerPermission) {
        commandBarItems.push({
            key: "refresh",
            iconProps: { iconName: "Refresh" },
            buttonStyles: commandBarButtonStyles,
            onClick: onRefresh,
        });
    }

    const createPermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Create";
    });
    if (createPermission && createPermission.Id !== innerPermission && isAuthorized(createPermission.CompleteId)) {
        commandBarItems.push({
            key: "Create",
            text: localization.EntityDataTable.Create,
            iconProps: { iconName: "Add" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setInnerPermission("Create", []);
            },
        });
    }

    const readPermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Read";
    });
    if (readPermission && readPermission.Id !== innerPermission && isAuthorized(readPermission.CompleteId)) {
        commandBarItems.push({
            key: "Read",
            text: localization.EntityDataTable.Read,
            iconProps: { iconName: "View" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setInnerPermission("Read", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const updatePermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Update";
    });
    if (updatePermission && updatePermission.Id !== innerPermission && isAuthorized(updatePermission.CompleteId)) {
        commandBarItems.push({
            key: "Update",
            text: localization.EntityDataTable.Update,
            iconProps: { iconName: "Edit" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setInnerPermission("Update", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const deletePermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Delete";
    });
    if (deletePermission && deletePermission.Id !== innerPermission && isAuthorized(deletePermission.CompleteId)) {
        commandBarItems.push({
            key: "Delete",
            text: localization.EntityDataTable.Delete,
            iconProps: { iconName: "Delete" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setInnerPermission("Delete", selectedKeys);
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const actions: IContextualMenuItem[] = permission.ChildPermissions.filter((x) => {
        return (
            x.Id !== "Create" &&
            x.Id !== "Read" &&
            x.Id !== "Update" &&
            x.Id !== "Delete" &&
            x.Id !== "Documents" &&
            x.Id !== "Notes" &&
            x.Id !== "Export" &&
            x.Id !== "Activity" &&
            x.Id !== "AuditLog" &&
            x.Metadata.Ui === true &&
            isAuthorized(x.CompleteId)
        );
    })
        .filter((x) => {
            return x.Id !== innerPermission;
        })
        .map((x) => {
            return {
                key: x.Id,
                text: x.Name,
                onClick: () => {
                    setInnerPermission(x.Id, x.Metadata.ForMultiple ? selectedKeys : selectedKeys.slice(0, 1));
                },
                disabled: x.Metadata.IgnoreSelection
                    ? false
                    : selectedKeys.length === 0
                    ? true
                    : selectedKeys.length > 1
                    ? !x.Metadata.ForMultiple
                    : false,
                title: `${x.Name} (${x.Id})`,
            };
        });

    if (actions.length > 0) {
        commandBarItems.push({
            key: "actions",
            text: localization.EntityDataTable.Actions,
            iconProps: { iconName: "SetAction" },
            buttonStyles: commandBarButtonStyles,
            subMenuProps: {
                items: actions,
            },
        });
    }

    if (!innerPermission) {
        const entityNavigations: IContextualMenuItem[] = permission.ChildPermissions.filter((x) => {
            return (
                x.Id !== "Create" &&
                x.Id !== "Read" &&
                x.Id !== "Update" &&
                x.Id !== "Delete" &&
                x.Id !== "Documents" &&
                x.Id !== "Notes" &&
                x.Id !== "Export" &&
                x.Id !== "Activity" &&
                x.Id !== "AuditLog" &&
                x.Metadata.SpecialType === "Navigation" &&
                isAuthorized(x.CompleteId)
            );
        }).map((x) => {
            return {
                key: x.Id,
                text: x.Name,
                onClick: () => {
                    setNavigation(x.Id, selectedKeys[0]);
                },
                disabled: selectedKeys.length !== 1,
                title: `${x.Name} (${x.Id})`,
            };
        });

        if (entityNavigations.length > 0) {
            commandBarItems.push({
                key: "navigations",
                text: "Navigacija",
                iconProps: { iconName: "ModelingView" },
                buttonStyles: commandBarButtonStyles,
                disabled: selectedKeys.length !== 1,
                subMenuProps: {
                    items: entityNavigations,
                },
            });
        }
    }

    const documentsPermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Documents";
    });
    if (
        documentsPermission &&
        documentsPermission.Id !== innerPermission &&
        isAuthorized(documentsPermission.CompleteId)
    ) {
        commandBarItems.push({
            key: "Documents",
            text: localization.EntityDataTable.Documents,
            iconProps: { iconName: "Documentation" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setInnerPermission("Documents", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const notesPermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Notes";
    });
    if (notesPermission && notesPermission.Id !== innerPermission && isAuthorized(notesPermission.CompleteId)) {
        commandBarItems.push({
            key: "Notes",
            text: localization.EntityDataTable.Notes,
            iconProps: { iconName: "QuickNote" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setInnerPermission("Notes", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const activityPermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Activity";
    });
    if (
        activityPermission &&
        activityPermission.Id !== innerPermission &&
        isAuthorized(activityPermission.CompleteId)
    ) {
        commandBarItems.push({
            key: "Activity",
            text: localization.EntityDataTable.Activity,
            iconProps: { iconName: "ActivityFeed" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setInnerPermission("Activity", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const auditLogPermission = permission.ChildPermissions.find((x) => {
        return x.Id === "AuditLog";
    });
    if (
        auditLogPermission &&
        auditLogPermission.Id !== innerPermission &&
        isAuthorized(auditLogPermission.CompleteId)
    ) {
        commandBarItems.push({
            key: "AuditLog",
            text: localization.EntityDataTable.AuditLog,
            iconProps: { iconName: "History" },
            buttonStyles: commandBarButtonStyles,
            onClick: () => {
                setInnerPermission("AuditLog", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    if (!innerPermission) {
        const exportPermission = permission.ChildPermissions.find((x) => {
            return x.Id === "Export";
        });
        if (exportPermission && exportPermission.Id !== innerPermission && isAuthorized(exportPermission.CompleteId)) {
            commandBarItems.push({
                key: "Export",
                text: localization.EntityDataTable.Export,
                iconProps: { iconName: "Export" },
                buttonStyles: commandBarButtonStyles,
                onClick: () => {
                    setInnerPermission("Export", []);
                },
            });
        }
    }

    return commandBarItems;
};

const getContextMenuItems = (
    permission: Permission,
    isAuthorized: (permissionId: string) => boolean,
    setInnerPermission: (innerPermission: string, selectedKeys: any[]) => void,
    selectedKeys: any[],
    setNavigation: (navigationPermissionId: string, itemId: any) => void,
    localization: IInCoreLocalization,
    innerPermission?: string,
): IContextualMenuItem[] => {
    const contextMenuItems: IContextualMenuItem[] = [];

    const basic: IContextualMenuItem[] = [];

    const readPermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Read";
    });
    if (readPermission && isAuthorized(readPermission.CompleteId) && (!innerPermission || innerPermission !== "Read")) {
        basic.push({
            key: "Read",
            text: localization.EntityDataTable.Read,
            iconProps: { iconName: "View" },
            onClick: () => {
                setInnerPermission("Read", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const updatePermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Update";
    });
    if (
        updatePermission &&
        isAuthorized(updatePermission.CompleteId) &&
        (!innerPermission || innerPermission !== "Update")
    ) {
        basic.push({
            key: "Update",
            text: localization.EntityDataTable.Update,
            iconProps: { iconName: "Edit" },
            onClick: () => {
                setInnerPermission("Update", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const deletePermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Delete";
    });
    if (deletePermission && isAuthorized(deletePermission.CompleteId)) {
        basic.push({
            key: "Delete",
            text: localization.EntityDataTable.Delete,
            iconProps: { iconName: "Delete" },
            onClick: () => {
                setInnerPermission("Delete", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    if (basic.length > 0) {
        contextMenuItems.push({
            key: "__basicHeader",
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                title: localization.EntityDataTable.Basic,
                items: basic,
            },
        });
    }

    const actions: IContextualMenuItem[] = permission.ChildPermissions.filter((x) => {
        return (
            x.Id !== "Create" &&
            x.Id !== "Read" &&
            x.Id !== "Update" &&
            x.Id !== "Delete" &&
            x.Id !== "Documents" &&
            x.Id !== "Notes" &&
            x.Id !== "Export" &&
            x.Id !== "Activity" &&
            x.Id !== "AuditLog" &&
            x.Metadata.Ui === true &&
            isAuthorized(x.CompleteId)
        );
    }).map((x) => {
        return {
            key: x.Id,
            text: x.Name,
            onClick: () => {
                setInnerPermission(x.Id, x.Metadata.ForMultiple ? selectedKeys : selectedKeys.slice(0, 1));
            },
            disabled: x.Metadata.IgnoreSelection
                ? false
                : selectedKeys.length === 0
                ? true
                : selectedKeys.length > 1
                ? !x.Metadata.ForMultiple
                : false,
            title: `${x.Name} (${x.Id})`,
        };
    });

    if (actions.length > 0) {
        contextMenuItems.push({
            key: "__actionsHeader",
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                title: localization.EntityDataTable.Actions,
                items: actions,
            },
        });
    }

    const other: IContextualMenuItem[] = [];

    const entityNavigations: IContextualMenuItem[] = permission.ChildPermissions.filter((x) => {
        return (
            x.Id !== "Create" &&
            x.Id !== "Read" &&
            x.Id !== "Update" &&
            x.Id !== "Delete" &&
            x.Id !== "Documents" &&
            x.Id !== "Notes" &&
            x.Id !== "Export" &&
            x.Id !== "Activity" &&
            x.Id !== "AuditLog" &&
            x.Metadata.SpecialType === "Navigation" &&
            isAuthorized(x.CompleteId)
        );
    }).map((x) => {
        return {
            key: x.Id,
            text: x.Name,
            onClick: () => {
                setNavigation(x.Id, selectedKeys[0]);
            },
            disabled: selectedKeys.length !== 1,
            title: `${x.Name} (${x.Id})`,
        };
    });

    if (entityNavigations.length > 0) {
        other.push({
            key: "navigations",
            text: "Navigacija",
            iconProps: { iconName: "ModelingView" },
            subMenuProps: {
                items: entityNavigations,
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const documentsPermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Documents";
    });
    if (documentsPermission && isAuthorized(documentsPermission.CompleteId)) {
        other.push({
            key: "Documents",
            text: localization.EntityDataTable.Documents,
            iconProps: { iconName: "Documentation" },
            onClick: () => {
                setInnerPermission("Documents", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const notesPermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Notes";
    });
    if (notesPermission && isAuthorized(notesPermission.CompleteId)) {
        other.push({
            key: "Notes",
            text: localization.EntityDataTable.Notes,
            iconProps: { iconName: "QuickNote" },
            onClick: () => {
                setInnerPermission("Notes", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const activityPermission = permission.ChildPermissions.find((x) => {
        return x.Id === "Activity";
    });
    if (activityPermission && isAuthorized(activityPermission.CompleteId)) {
        other.push({
            key: "Activity",
            text: localization.EntityDataTable.Activity,
            iconProps: { iconName: "ActivityFeed" },
            onClick: () => {
                setInnerPermission("Activity", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    const auditLogPermission = permission.ChildPermissions.find((x) => {
        return x.Id === "AuditLog";
    });
    if (auditLogPermission && isAuthorized(auditLogPermission.CompleteId)) {
        other.push({
            key: "AuditLog",
            text: localization.EntityDataTable.AuditLog,
            iconProps: { iconName: "History" },
            onClick: () => {
                setInnerPermission("AuditLog", selectedKeys.slice(0, 1));
            },
            disabled: selectedKeys.length !== 1,
        });
    }

    if (other.length > 0) {
        contextMenuItems.push({
            key: "__restHeader",
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                title: localization.Other,
                items: other,
            },
        });
    }

    return contextMenuItems;
};

export interface IEntityDataTableContext<TKey = any> {
    permission: Permission;
    innerPermission: Permission | undefined;
    entityDescriptor: EntityDescriptor;
    selectedKeys: TKey[];
    success: (keys?: any[], data?: any) => void;
    redirect: (innerPermission: string | string[], selectedKey?: any | any[]) => void;
    close: () => void;
    refresh: () => void;
}

const entityDataTableContext = createContext<IEntityDataTableContext | undefined>(undefined);

export const useEntityDataTableContext = <TKey extends any = any>() => {
    return useContext(entityDataTableContext) as IEntityDataTableContext<TKey> | undefined;
};
