import {
    DataTable,
    IDataTableColumn,
    SelectionMode,
    Spinner,
    Toggle,
    useEntityDataTableContext,
    useInCoreLocalization,
    useOnChange,
    useOnMount,
    usePanelContext,
    useTheme,
} from "@in-core";
import { GetAuditLogs } from "@in-core/api/Entity";
import { useState } from "react";

const AuditLog = () => {
    const entityDataTableContext = useEntityDataTableContext()!;
    const panelContext = usePanelContext();
    const getAuditLogsApi = GetAuditLogs.useApi();
    const [onlyChanges, setOnlyChanges] = useState(false);
    const theme = useTheme();
    const localization = useInCoreLocalization();

    useOnMount(
        () => {
            if (!panelContext) {
                return;
            }

            panelContext.setProps({
                styles: {
                    scrollableContent: { height: "100%", display: "flex", flexDirection: "column" },
                    content: { flex: 1, display: "flex", flexDirection: "column" },
                },
            });
        },
        () => {
            if (!panelContext) {
                return;
            }

            panelContext.clearProps();
        },
    );

    useOnChange(() => {
        const key = entityDataTableContext.selectedKeys[0];

        if (key === undefined) {
            return;
        }

        const keyObj: any = {};
        (entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? []).sort().forEach((x) => {
            keyObj[x] = key[x] ?? key;
        });

        getAuditLogsApi.call({
            Permission: entityDataTableContext.permission.CompleteId,
            Key: keyObj,
        });
    }, [entityDataTableContext.permission.CompleteId, entityDataTableContext.selectedKeys]);

    if (getAuditLogsApi.isIdle || getAuditLogsApi.isLoading) {
        return <Spinner />;
    }

    const columns: IDataTableColumn<GetAuditLogs.AuditLogInfo>[] = [
        {
            id: "__id",
            property: "Id",
            name: localization.EntityDataTable.AuditLogId,
            width: 100,
        },
        {
            id: "__time",
            name: localization.EntityDataTable.AuditLogTime,
            width: 150,
            onRender: (x) => {
                return x.Time.toLocaleString("hr-HR");
            },
        },
        {
            id: "__type",
            name: localization.EntityDataTable.AuditLogType,
            width: 120,
            onRender: (x) => {
                return x.Type;
            },
        },
        {
            id: "__user",
            name: localization.EntityDataTable.AuditLogUser,
            width: 150,
            onRender: (x) => {
                if (!x.User) {
                    return null;
                }

                return `${x.User.Name} (${x.User.UserName}, ${x.User.Id})`;
            },
        },
        {
            id: "__connectedApp",
            name: localization.EntityDataTable.AuditLogConnectedApp,
            width: 150,
            onRender: (x) => {
                if (!x.ConnectedApp) {
                    return null;
                }

                return `${x.ConnectedApp.Name} (${x.ConnectedApp.Id})`;
            },
        },
        {
            id: "__activity",
            name: localization.EntityDataTable.AuditLogActivity,
            width: 150,
            onRender: (x) => {
                if (!x.Activity) {
                    return null;
                }

                return `${x.Activity.Name} (${x.Activity.GeneratedId})`;
            },
        },
    ];

    let allColumns = Array.from(
        new Set(
            getAuditLogsApi
                .data!.flatMap((x) => {
                    return Object.keys(x.OldValues ?? {}).concat(Object.keys(x.NewValues ?? {}));
                })
                .sort(),
        ),
    );

    if (onlyChanges) {
        allColumns = allColumns.filter((x) => {
            return getAuditLogsApi.data!.some((y) => {
                const oldValue = (y.OldValues ? y.OldValues[x] : undefined) ?? undefined;
                const newValue = (y.NewValues ? y.NewValues[x] : undefined) ?? undefined;

                return oldValue !== newValue;
            });
        });
    }

    allColumns.forEach((x) => {
        columns.push({
            id: x,
            name: x,
            onRender: (y) => {
                const oldValue =
                    y.OldValues === undefined || y.OldValues === null ? undefined : y.OldValues[x] ?? undefined;
                const newValue =
                    y.NewValues === undefined || y.NewValues === null ? undefined : y.NewValues[x] ?? undefined;

                if (oldValue === newValue) {
                    return newValue;
                }

                return (
                    <>
                        {oldValue && (
                            <span style={{ color: theme.palette.red, textDecoration: "line-through" }}>{oldValue}</span>
                        )}{" "}
                        {newValue && <span style={{ color: theme.palette.green }}>{newValue}</span>}
                    </>
                );
            },
        });
    });

    return (
        <>
            <Toggle
                onText={localization.EntityDataTable.AuditLogOnlyChanges}
                offText={localization.EntityDataTable.AuditLogOnlyChanges}
                checked={onlyChanges}
                onChange={(_, checked) => {
                    setOnlyChanges(checked ?? false);
                }}
            />

            <DataTable
                data={getAuditLogsApi.data!}
                columns={columns}
                disablePagination
                disableFiltering
                disableSorting
                disableAggregating
                disableGrouping
                disableSearching
                disableSelecting
                hideToolbar
                selectionMode={SelectionMode.none}
            />
        </>
    );
};

export default AuditLog;
