import {
    Form,
    MessageBar,
    Pivot,
    PivotItem,
    ScrollablePane,
    Setting,
    Spinner,
    Stack,
    Text,
    useForm,
    useInCoreLocalization,
    useOnChange,
    useOnMount,
    useTheme,
} from "@in-core";
import { ReadAll } from "@in-core/api/Roles";
import { Read, Save } from "@in-core/api/Settings";
import SettingValueEditor from "../SettingValueEditor";
import SettingDetailsRoles from "./SettingDetailsRoles";
import SettingDetailsUsers from "./SettingDetailsUsers";

export interface ISettingDetailsProps {
    setting: Setting;
    onFinished?: () => void;
}

const SettingDetails = (props: ISettingDetailsProps) => {
    const getRolesApi = ReadAll.useApi();
    const readApi = Read.useApi();
    const form = useForm() as any;
    const theme = useTheme();
    const localization = useInCoreLocalization();

    useOnMount(() => {
        getRolesApi.call({ ShouldIncludeUserIds: false });
    });

    useOnChange(async () => {
        const readResponse = await readApi.call({ Id: props.setting.Id });
        form.$reset({ Id: props.setting.Id, ...readResponse.data });
    }, [props.setting.Id]);

    const handleSubmit = async (data: any) => {
        await Save.callApi(data);
        props.onFinished && props.onFinished();
    };

    if (!readApi.data) {
        return <Spinner />;
    }

    return (
        <Form form={form} style={{ height: "100%" }} removePaddingBottom onSubmit={form.$handleSubmit(handleSubmit)}>
            <Stack tokens={{ childrenGap: theme.spacing.s1 }} styles={{ root: { height: "100%" } }}>
                <Text variant="small">{props.setting.Id}</Text>

                {props.setting.Description && <MessageBar>{props.setting.Description}</MessageBar>}

                <Pivot
                    style={{ flex: 1, display: "flex", flexDirection: "column" }}
                    styles={{ itemContainer: { flex: 1 } }}
                >
                    {props.setting.IsAllowedForSystem && (
                        <PivotItem
                            headerText={localization.Settings.System}
                            itemIcon="System"
                            alwaysRender
                            style={{ height: "100%" }}
                        >
                            <ScrollablePane
                                styles={{
                                    root: { position: "relative", height: "100%" },
                                    contentContainer: { paddingTop: theme.spacing.m, paddingBottom: theme.spacing.m },
                                }}
                            >
                                <SettingValueEditor
                                    value={form.SystemValue.$value}
                                    onChange={(newValue) => {
                                        form.SystemValue.$setValue(newValue);
                                    }}
                                />
                            </ScrollablePane>
                        </PivotItem>
                    )}

                    {props.setting.IsAllowedForRole && (
                        <PivotItem
                            headerText={localization.Settings.Roles}
                            itemIcon="PlayerSettings"
                            alwaysRender
                            style={{ height: "100%", display: "flex" }}
                        >
                            <SettingDetailsRoles form={form} setting={props.setting} allRoles={getRolesApi.data} />
                        </PivotItem>
                    )}

                    {props.setting.IsAllowedForUser && (
                        <PivotItem
                            headerText={localization.Settings.Users}
                            itemIcon="People"
                            alwaysRender
                            style={{ height: "100%", display: "flex" }}
                        >
                            <SettingDetailsUsers form={form} setting={props.setting} allRoles={getRolesApi.data} />
                        </PivotItem>
                    )}
                </Pivot>
            </Stack>
        </Form>
    );
};

export default SettingDetails;
