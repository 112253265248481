import {
    ActionButton,
    DataTable,
    Link,
    Panel,
    PanelType,
    PrimaryButton,
    SelectionMode,
    Setting,
    Spinner,
    Stack,
    useInCoreLocalization,
    useOnMount,
    useTheme,
} from "@in-core";
import { DataType } from "@in-core/api/Entity";
import { ReadAll } from "@in-core/api/Users";
import { useState } from "react";
import SettingValueEditor from "../../SettingValueEditor";

export interface ISettingDetailsUsersProps {
    setting: Setting;
    form: any;
    allRoles?: any[];
}

const SettingDetailsUsers = (props: ISettingDetailsUsersProps) => {
    const getUsersApi = ReadAll.useApi();
    const theme = useTheme();
    const localization = useInCoreLocalization();
    const [currentUserId, setCurrentUserId] = useState<any>();

    useOnMount(() => {
        getUsersApi.call({ ShouldIncludeRoleIds: true });
    });

    if (!getUsersApi.data || !props.allRoles) {
        return <Spinner />;
    }

    const userValues = props.form.UserValues.$value;

    const data = getUsersApi.data.map((x: any) => {
        const userValue = userValues?.find((y: any) => {
            return y.UserId === x.Id;
        })?.Value;

        return {
            Id: x.Id,
            UserName: x.UserName,
            Name: x.Name,
            HasValue: userValue !== undefined,
            Value: userValue ?? "",
        };
    });

    const currentUser = getUsersApi.data.find((x: any) => {
        return x.Id === currentUserId;
    });

    return (
        <>
            <DataTable
                data={data}
                columns={[
                    {
                        id: "Edit",
                        name: "",
                        onRender: (item) => {
                            return (
                                <ActionButton
                                    iconProps={{ iconName: "Edit" }}
                                    styles={{ root: { height: "auto", padding: 0 } }}
                                    onClick={() => {
                                        setCurrentUserId(item.Id);
                                    }}
                                >
                                    {localization.Settings.Edit}
                                </ActionButton>
                            );
                        },
                        width: 100,
                        isSortable: false,
                        isSearchable: false,
                        isFilterable: false,
                        isResizable: false,
                    },
                    {
                        id: "Id",
                        property: "Id",
                        name: localization.Settings.UserIdColumn,
                        dataType: DataType.Number,
                        width: 150,
                        onRender: (item) => {
                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        setCurrentUserId(item.Id);
                                    }}
                                >
                                    {item.Id}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "UserName",
                        property: "UserName",
                        name: localization.Settings.UserUserNameColumn,
                        dataType: DataType.String,
                        width: 150,
                        onRender: (item) => {
                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        setCurrentUserId(item.Id);
                                    }}
                                >
                                    {item.UserName}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "Name",
                        property: "Name",
                        name: localization.Settings.UserNameColumn,
                        dataType: DataType.String,
                        width: 200,
                        onRender: (item) => {
                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        setCurrentUserId(item.Id);
                                    }}
                                >
                                    {item.Name}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "HasValue",
                        property: "HasValue",
                        name: localization.Settings.UserHasValueColumn,
                        dataType: DataType.Boolean,
                        width: 120,
                        isResizable: false,
                        isSortable: false,
                    },
                    {
                        id: "Value",
                        property: "Value",
                        name: localization.Settings.UserValueColumn,
                        dataType: DataType.String,
                        width: 300,
                        isSortable: false,
                    },
                ]}
                hideToolbar
                disableAggregating
                defaultSort={{ Id: "Description", IsDescending: false }}
                selectionMode={SelectionMode.none}
            />

            <Panel
                isOpen={currentUserId !== undefined}
                onDismiss={() => {
                    setCurrentUserId(undefined);
                }}
                headerText={`${props.setting.Name} - ${localization.Settings.User} ${currentUser?.Name}`}
                type={PanelType.medium}
            >
                {currentUser && (
                    <Stack tokens={{ childrenGap: theme.spacing.l2 }}>
                        <SettingValueEditor
                            value={
                                props.form.UserValues.$value?.find((x: any) => {
                                    return x.UserId === currentUserId;
                                })?.Value
                            }
                            onChange={(newValue) => {
                                const newUserValues =
                                    props.form.UserValues.$value
                                        ?.filter((x: any) => {
                                            return x.UserId !== currentUserId;
                                        })
                                        .map((x: any) => {
                                            return { ...x };
                                        }) ?? [];

                                if (newValue !== undefined) {
                                    newUserValues.push({ UserId: currentUserId, Value: newValue });
                                }

                                props.form.UserValues.$setValue(newUserValues);
                            }}
                            relevantSettingValues={[
                                {
                                    Id: "__system",
                                    Name: `${localization.Settings.System}:`,
                                    Value: props.form.SystemValue.$value,
                                },
                                ...(currentUser.RoleIds.map((x: any) => {
                                    const roleName =
                                        props.allRoles?.find((y) => {
                                            return y.Id === x;
                                        })?.Description ?? x.toString();

                                    const roleValue = props.form.RoleValues.$value?.find((y: any) => {
                                        return y.RoleId === x;
                                    })?.Value;

                                    return {
                                        Id: x.toString(),
                                        Name: `${localization.Settings.Role} ${roleName}:`,
                                        Value: roleValue,
                                    };
                                }) ?? []),
                            ]}
                        />

                        <Stack horizontal>
                            <PrimaryButton
                                onClick={() => {
                                    setCurrentUserId(undefined);
                                }}
                            >
                                {localization.Ok}
                            </PrimaryButton>
                        </Stack>
                    </Stack>
                )}
            </Panel>
        </>
    );
};

export default SettingDetailsUsers;
