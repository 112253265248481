import { Callout, DefaultButton, generateUuid, useInCoreLocalization, useTheme } from "@in-core";
import { useRef, useState } from "react";
import FilterDateSelector from "./FilterDateSelector";
import { ExpressionLocalizationKeys } from "..";

export interface IFilterDatePickerProps {
    disabled?: boolean;
    value: Date | undefined;
    onValueChanged: (date: Date | undefined) => void;
    expression: string | undefined;
    onExpressionChanged: (expression: string | undefined) => void;
    onClear: () => void;
    prefix?: string;
}

const FilterDatePicker = (props: IFilterDatePickerProps) => {
    const buttonIdRef = useRef("btn-" + generateUuid());
    const [isCalloutVisible, setIsCalloutVisible] = useState(false);
    const localization = useInCoreLocalization();
    const theme = useTheme();

    return (
        <>
            <DefaultButton
                id={buttonIdRef.current}
                onClick={() => {
                    setIsCalloutVisible((prevIsCalloutVisible) => {
                        return !prevIsCalloutVisible;
                    });
                }}
                disabled={props.disabled}
                styles={{
                    root: {
                        backgroundColor: theme.palette.neutralLighterAlt,
                        borderColor: theme.palette.neutralQuaternary,
                        textAlign: "left",
                        flex: 1,
                        paddingLeft: theme.spacing.s2,
                        paddingRight: theme.spacing.s2,
                    },
                    flexContainer: {
                        justifyContent: "flex-start",
                        overflow: "hidden",
                    },
                    label: {
                        fontWeight: 400,
                        fontStyle: props.value === undefined && props.expression === undefined ? "italic" : undefined,
                        color:
                            props.value === undefined && props.expression === undefined
                                ? theme.palette.neutralSecondaryAlt
                                : undefined,
                    },
                }}
                title={
                    props.value
                        ? `${props.prefix ?? ""}${props.value?.toLocaleDateString("hr-HR")}`
                        : props.expression
                        ? `${props.prefix ?? ""}${
                              (localization.DataTable as any)[ExpressionLocalizationKeys[props.expression]]
                          }`
                        : localization.DataTable.SelectDate
                }
            >
                {props.value
                    ? `${props.prefix ?? ""}${props.value?.toLocaleDateString("hr-HR")}`
                    : props.expression
                    ? `${props.prefix ?? ""}${
                          (localization.DataTable as any)[ExpressionLocalizationKeys[props.expression]]
                      }`
                    : localization.DataTable.SelectDate}
            </DefaultButton>

            <Callout
                hidden={!isCalloutVisible}
                target={`#${buttonIdRef.current}`}
                onDismiss={() => {
                    setIsCalloutVisible(false);
                }}
                isBeakVisible={false}
            >
                {isCalloutVisible && (
                    <FilterDateSelector
                        initialValue={props.value}
                        onValueChanged={(value) => {
                            props.onValueChanged(value);
                            setIsCalloutVisible(false);
                        }}
                        initialExpression={props.expression}
                        onExpressionChanged={(expression) => {
                            props.onExpressionChanged(expression);
                            setIsCalloutVisible(false);
                        }}
                        onClear={() => {
                            props.onClear();
                            setIsCalloutVisible(false);
                        }}
                    />
                )}
            </Callout>
        </>
    );
};

export default FilterDatePicker;
