import {
    DefaultButton,
    IDataTableColumn,
    Label,
    PrimaryButton,
    Sort,
    Stack,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import { useState } from "react";
import SortItem from "./SortItem";

export interface ISortFormProps<TData = any> {
    columns: IDataTableColumn<TData>[];
    initialSort: Sort | undefined;
    onSortChanged: (newSort: Sort | undefined) => void;
    onDismiss: () => void;
}

const SortForm = (props: ISortFormProps) => {
    const [sort, setSort] = useState<Sort | undefined>(props.initialSort ? { ...props.initialSort } : undefined);
    const theme = useTheme();
    const localization = useInCoreLocalization();

    return (
        <Stack>
            <Label>{localization.DataTable.Sort}</Label>

            <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                <SortItem
                    columns={props.columns.filter((x) => {
                        return x.isSortable !== false;
                    })}
                    sort={sort}
                    onSortChanged={setSort}
                />
            </Stack>

            <Stack
                horizontal
                styles={{
                    root: {
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: theme.palette.white,
                        zIndex: 1,
                        paddingTop: theme.spacing.m,
                    },
                }}
                tokens={{ childrenGap: theme.spacing.s1 }}
            >
                <PrimaryButton
                    onClick={() => {
                        props.onSortChanged(sort);
                        props.onDismiss();
                    }}
                >
                    {localization.Accept}
                </PrimaryButton>

                <DefaultButton onClick={props.onDismiss}>{localization.Cancel}</DefaultButton>
            </Stack>
        </Stack>
    );
};

export default SortForm;
