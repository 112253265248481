import { Permission, Spinner, UseForm, useEntityDataTableContext } from "@in-core";
import MenuManager, { IMenuItem } from "@in-core/ui/MenuManager";

export interface IRoleMenuProps {
    form: UseForm;
    permissions?: Permission[];
}

const RoleMenu = (props: IRoleMenuProps) => {
    const form = props.form as any;
    const entityDataTableContext = useEntityDataTableContext()!;

    const isRead = entityDataTableContext.innerPermission?.Id === "Read";

    if (!props.permissions) {
        return <Spinner />;
    }

    const allowedPermissionIds = (form.Permissions.$value as string[]) ?? [];

    return (
        <MenuManager
            menuItems={form.Menu.$value}
            defaultMenuItems={getDefaultRoleMenu(allowedPermissionIds, props.permissions)}
            onMenuItemsChanged={(menuItems) => {
                form.Menu.$setValue(menuItems);
            }}
            allowedPermissions={getRoleAllowedPermissions(allowedPermissionIds, props.permissions)}
            disabled={isRead}
        />
    );
};

export default RoleMenu;

export const getRoleAllowedPermissions = (allowedPermissions: string[], permissions: Permission[]): Permission[] => {
    return allowedPermissions
        .map((x) => {
            return permissions.find((y) => {
                return y.CompleteId === x;
            })!;
        })
        .filter((x) => {
            return !!x;
        });
};

export const getDefaultRoleMenu = (allowedPermissions: string[], permissions: Permission[]): IMenuItem[] => {
    const result: IMenuItem[] = [];

    allowedPermissions
        .map((x) => {
            return permissions.find((y) => {
                return y.CompleteId === x;
            })!;
        })
        .filter((x) => {
            return !!x && (x.Metadata.Ui === true || x.Metadata.SpecialType === "EntityDataBrowser");
        })
        .forEach((x) => {
            result.push({ Id: `__permission-${x.CompleteId}`, PermissionId: x.CompleteId });
        });

    return sortMenuItems(result, permissions);
};

const sortMenuItems = (menuItems: IMenuItem[], permissions: Permission[]): IMenuItem[] => {
    return menuItems
        .sort((a, b) => {
            if (a.Submenus !== undefined && a.Submenus === undefined) {
                return -1;
            }

            if (a.Submenus === undefined && a.Submenus !== undefined) {
                return 1;
            }

            const aText =
                a.Text ??
                (a.PermissionId
                    ? permissions.find((x) => {
                          return x.CompleteId === a.PermissionId;
                      })?.Name
                    : a.Id) ??
                a.Id ??
                "";

            const bText =
                b.Text ??
                (b.PermissionId
                    ? permissions.find((x) => {
                          return x.CompleteId === b.PermissionId;
                      })?.Name
                    : b.Id) ??
                b.Id ??
                "";
            return aText.localeCompare(bText);
        })
        .map((x) => {
            return { ...x, Submenus: x.Submenus ? sortMenuItems(x.Submenus!, permissions) : undefined };
        });
};
