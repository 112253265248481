import {
    ActionButton,
    Checkbox,
    ChoiceGroup,
    DataTable,
    DirectionalHint,
    Dropdown,
    DropdownMenuItemType,
    Filter,
    FilterGroupOperator,
    FilterValueOperator,
    FormValue,
    Icon,
    Link,
    Panel,
    PanelType,
    Permission,
    PrimaryButton,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TooltipHost,
    UseForm,
    useInCoreLocalization,
    useTheme,
} from "@in-core";
import { DataType } from "@in-core/api/Entity";
import { useMemo, useState } from "react";
import PermissionImplicationGraph from "../../PermissionImplicationGraph";

export interface IUserPermissionsTableProps {
    form: UseForm;
    roles: any[];
    permissions: Permission[];
    permissionImplicationGraph: PermissionImplicationGraph;
    allFlatPermissions: Permission[];
    isLoadingRoles: boolean;
    disabled?: boolean;
}

const UserPermissionsTable = (props: IUserPermissionsTableProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();
    const [selectedPermissionId, setSelectedPermissionId] = useState<string>();

    const idProperties = useMemo(() => {
        return ["Id"] as (keyof Permission)[];
    }, []);

    const setSelection = (permissionId: string | undefined) => {
        setSelectedPermissionId(permissionId);
    };

    const form = props.form as any;
    const addedPermissions: string[] = form.AddedPermissions.$value ?? [];
    const removedPermissions: string[] = form.RemovedPermissions.$value ?? [];

    const selectedPermission = props.permissions.find((x) => {
        return x.Id === selectedPermissionId;
    });

    const inheritedPermissions: string[] = props.roles.flatMap((x) => {
        return x.Permissions;
    });

    const inheritedAndExplicitPermissions = [
        ...inheritedPermissions.filter((x) => {
            return !removedPermissions.includes(x);
        }),
        ...addedPermissions,
    ];

    const data = props.permissions.map((currentPermission) => {
        const impliedBy: Permission[] = props.permissionImplicationGraph
            .getPermissionsThatImply(currentPermission.CompleteId)
            .filter((x) => {
                return inheritedAndExplicitPermissions.includes(x);
            })
            .map((x) => {
                return props.allFlatPermissions.find((y) => {
                    return y.CompleteId === x;
                })!;
            });
        const inheritedFrom = props.roles.filter((x) => {
            return x.Permissions.includes(currentPermission.CompleteId);
        });
        const isImplied = impliedBy.length > 0;
        const isInherited = inheritedFrom.length > 0;
        const isAdded = addedPermissions.includes(currentPermission.CompleteId);
        const isRemoved = removedPermissions.includes(currentPermission.CompleteId);
        const isParentInheritedOrExplicit =
            currentPermission.ParentPermissionId !== undefined &&
            inheritedAndExplicitPermissions.includes(currentPermission.ParentPermissionId);

        const additionalInfo = {
            IsSelected: !isRemoved && (isAdded || isImplied || isInherited),
            ImpliedBy: impliedBy,
            IsImplied: isImplied,
            InheritedFrom: inheritedFrom,
            IsInherited: isInherited,
            IsAdded: isAdded,
            IsRemoved: isRemoved,
            IsParentInheritedOrExplicit: isParentInheritedOrExplicit,
        };

        return {
            ...currentPermission,
            IsSelected: !isRemoved && (isAdded || isImplied || isInherited),
            ImpliedBy: impliedBy,
            IsImplied: isImplied,
            InheritedFrom: inheritedFrom,
            IsInherited: isInherited,
            IsAdded: isAdded,
            IsRemoved: isRemoved,
            IsParentInheritedOrExplicit: isParentInheritedOrExplicit,
            PermissionType:
                additionalInfo.IsSelected && additionalInfo.IsAdded
                    ? "assignedExplicit"
                    : additionalInfo.IsSelected && additionalInfo.IsImplied && !additionalInfo.IsAdded
                    ? "assignedImplicit"
                    : additionalInfo.IsSelected &&
                      additionalInfo.IsInherited &&
                      !additionalInfo.IsImplied &&
                      !additionalInfo.IsAdded
                    ? "assignedInherited"
                    : !additionalInfo.IsSelected && additionalInfo.IsRemoved
                    ? "notAssignedExplicit"
                    : "notAssignedInherited",
        };
    });

    return (
        <>
            <DataTable
                data={data}
                idProperties={idProperties}
                group={{ Id: "GroupName" }}
                columns={[
                    {
                        id: "Id",
                        property: "Id",
                        name: localization.UserManagement.PermissionsIdColumn,
                        dataType: DataType.String,
                        onRender: (item) => {
                            if (item.ChildPermissions.length === 0) {
                                return (
                                    <Text
                                        styles={{
                                            root: {
                                                fontSize: "inherit",
                                                color: theme.palette.neutralDark,
                                            },
                                        }}
                                    >
                                        {item.Id}
                                    </Text>
                                );
                            }

                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                        },
                                    }}
                                    onClick={() => {
                                        setSelection(item.Id);
                                    }}
                                >
                                    {item.Id}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "Name",
                        property: "Name",
                        name: localization.UserManagement.PermissionsNameColumn,
                        dataType: DataType.String,
                        onRender: (item) => {
                            if (item.ChildPermissions.length === 0) {
                                return (
                                    <Text
                                        styles={{
                                            root: {
                                                fontSize: "inherit",
                                                color: theme.palette.neutralDark,
                                                display: "flex",
                                                alignItems: "center",
                                            },
                                        }}
                                    >
                                        <Icon
                                            iconName={item.Icon}
                                            styles={{
                                                root: {
                                                    fontSize: 16,
                                                    color: theme.palette.themePrimary,
                                                    marginRight: theme.spacing.s2,
                                                },
                                            }}
                                        />

                                        {item.Name}
                                    </Text>
                                );
                            }

                            return (
                                <Link
                                    styles={{
                                        root: {
                                            fontSize: "inherit",
                                            color: theme.palette.neutralDark,
                                            display: "flex",
                                            alignItems: "center",
                                        },
                                    }}
                                    onClick={() => {
                                        setSelection(item.Id);
                                    }}
                                >
                                    <Icon
                                        iconName={item.Icon}
                                        styles={{
                                            root: {
                                                fontSize: 16,
                                                color: theme.palette.themePrimary,
                                                marginRight: theme.spacing.s2,
                                            },
                                        }}
                                    />

                                    {item.Name}
                                </Link>
                            );
                        },
                    },
                    {
                        id: "IsSelected",
                        name: localization.UserManagement.PermissionsIsSelectedColumn,
                        width: 300,
                        isResizable: false,
                        isSortable: false,
                        onRenderFilter: (filter, onChange) => {
                            let assignedExplicit = false;
                            let assignedImplicit = false;
                            let assignedInherited = false;
                            let notAssignedExplicit = false;
                            let notAssignedInherited = false;

                            if (filter) {
                                const permissionTypes = filter.Group!.Filters.map((x) => {
                                    return x.Value!.RightOperand!.Value;
                                });

                                assignedExplicit = permissionTypes.includes("assignedExplicit");
                                assignedImplicit = permissionTypes.includes("assignedImplicit");
                                assignedInherited = permissionTypes.includes("assignedInherited");
                                notAssignedExplicit = permissionTypes.includes("notAssignedExplicit");
                                notAssignedInherited = permissionTypes.includes("notAssignedInherited");
                            }

                            const selectedKeys: string[] = [];
                            if (assignedExplicit) {
                                selectedKeys.push("assignedExplicit");
                            }
                            if (assignedImplicit) {
                                selectedKeys.push("assignedImplicit");
                            }
                            if (assignedInherited) {
                                selectedKeys.push("assignedInherited");
                            }
                            if (notAssignedExplicit) {
                                selectedKeys.push("notAssignedExplicit");
                            }
                            if (notAssignedInherited) {
                                selectedKeys.push("notAssignedInherited");
                            }

                            return (
                                <Dropdown
                                    multiSelect
                                    selectedKeys={selectedKeys}
                                    onRenderItem={(renderProps, defaultRender) => {
                                        if (renderProps?.itemType !== DropdownMenuItemType.Header) {
                                            return defaultRender!(renderProps);
                                        }

                                        const checked =
                                            renderProps.key === "__assignedHeader"
                                                ? assignedExplicit && assignedImplicit && assignedInherited
                                                : notAssignedExplicit && notAssignedInherited;

                                        const indeterminate =
                                            renderProps.key === "__assignedHeader"
                                                ? !checked &&
                                                  (assignedExplicit || assignedImplicit || assignedInherited)
                                                : !checked && (notAssignedExplicit || notAssignedInherited);

                                        return (
                                            <Checkbox
                                                key={renderProps.key}
                                                onRenderLabel={() => {
                                                    return defaultRender!(renderProps);
                                                }}
                                                isInline
                                                styles={{
                                                    root: {
                                                        border: "1px solid transparent",
                                                    },
                                                    label: {
                                                        paddingLeft: theme.spacing.s1,
                                                        width: "100%",
                                                    },
                                                }}
                                                checked={checked}
                                                indeterminate={indeterminate}
                                                onChange={(_, checked) => {
                                                    let newInnerFilters: Filter[] = [...(filter?.Group?.Filters ?? [])];

                                                    if (renderProps.key === "__assignedHeader") {
                                                        newInnerFilters = newInnerFilters.filter((x) => {
                                                            return !x.Value!.RightOperand!.Value.startsWith("assigned");
                                                        });

                                                        if (checked) {
                                                            newInnerFilters.push(
                                                                ...[
                                                                    {
                                                                        IsNegated: false,
                                                                        Value: {
                                                                            LeftOperand: { Id: "PermissionType" },
                                                                            Operator: FilterValueOperator.Equal,
                                                                            RightOperand: { Value: "assignedExplicit" },
                                                                        },
                                                                    },
                                                                    {
                                                                        IsNegated: false,
                                                                        Value: {
                                                                            LeftOperand: { Id: "PermissionType" },
                                                                            Operator: FilterValueOperator.Equal,
                                                                            RightOperand: { Value: "assignedImplicit" },
                                                                        },
                                                                    },
                                                                    {
                                                                        IsNegated: false,
                                                                        Value: {
                                                                            LeftOperand: { Id: "PermissionType" },
                                                                            Operator: FilterValueOperator.Equal,
                                                                            RightOperand: {
                                                                                Value: "assignedInherited",
                                                                            },
                                                                        },
                                                                    },
                                                                ],
                                                            );
                                                        }
                                                    } else {
                                                        newInnerFilters = newInnerFilters.filter((x) => {
                                                            return !x.Value!.RightOperand!.Value.startsWith(
                                                                "notAssigned",
                                                            );
                                                        });

                                                        if (checked) {
                                                            newInnerFilters.push(
                                                                ...[
                                                                    {
                                                                        IsNegated: false,
                                                                        Value: {
                                                                            LeftOperand: { Id: "PermissionType" },
                                                                            Operator: FilterValueOperator.Equal,
                                                                            RightOperand: {
                                                                                Value: "notAssignedExplicit",
                                                                            },
                                                                        },
                                                                    },
                                                                    {
                                                                        IsNegated: false,
                                                                        Value: {
                                                                            LeftOperand: { Id: "PermissionType" },
                                                                            Operator: FilterValueOperator.Equal,
                                                                            RightOperand: {
                                                                                Value: "notAssignedInherited",
                                                                            },
                                                                        },
                                                                    },
                                                                ],
                                                            );
                                                        }
                                                    }

                                                    onChange({
                                                        IsNegated: false,
                                                        Group: {
                                                            Operator: FilterGroupOperator.Or,
                                                            Filters: newInnerFilters,
                                                        },
                                                    });
                                                }}
                                            />
                                        );
                                    }}
                                    options={[
                                        {
                                            key: "__assignedHeader",
                                            text: localization.UserManagement.PermissionsAssigned,
                                            itemType: DropdownMenuItemType.Header,
                                        },
                                        {
                                            key: "assignedExplicit",
                                            text: localization.UserManagement.PermissionsAssignedExplicit,
                                        },
                                        {
                                            key: "assignedImplicit",
                                            text: localization.UserManagement.PermissionsAssignedImplicit,
                                        },
                                        {
                                            key: "assignedInherited",
                                            text: localization.UserManagement.PermissionsAssignedInherited,
                                        },
                                        {
                                            key: "__divider",
                                            text: "",
                                            itemType: DropdownMenuItemType.Divider,
                                        },
                                        {
                                            key: "__notAssignedHeader",
                                            text: localization.UserManagement.PermissionsNotAssigned,
                                            itemType: DropdownMenuItemType.Header,
                                        },
                                        {
                                            key: "notAssignedExplicit",
                                            text: localization.UserManagement.PermissionsNotAssignedExplicit,
                                        },
                                        {
                                            key: "notAssignedInherited",
                                            text: localization.UserManagement.PermissionsNotAssignedInherited,
                                        },
                                    ]}
                                    styles={{ dropdownItemHeader: { padding: 0, cursor: "pointer" } }}
                                    onChange={(selectionItems) => {
                                        const newAssignedExplicit =
                                            (assignedExplicit &&
                                                !selectionItems.some((x) => {
                                                    return x.key === "assignedExplicit" && !x.isSelected;
                                                })) ||
                                            (!assignedExplicit &&
                                                selectionItems.some((x) => {
                                                    return x.key === "assignedExplicit" && x.isSelected;
                                                }));

                                        const newAssignedImplicit =
                                            (assignedImplicit &&
                                                !selectionItems.some((x) => {
                                                    return x.key === "assignedImplicit" && !x.isSelected;
                                                })) ||
                                            (!assignedImplicit &&
                                                selectionItems.some((x) => {
                                                    return x.key === "assignedImplicit" && x.isSelected;
                                                }));

                                        const newAssignedInherited =
                                            (assignedInherited &&
                                                !selectionItems.some((x) => {
                                                    return x.key === "assignedInherited" && !x.isSelected;
                                                })) ||
                                            (!assignedInherited &&
                                                selectionItems.some((x) => {
                                                    return x.key === "assignedInherited" && x.isSelected;
                                                }));

                                        const newNotAssignedExplicit =
                                            (notAssignedExplicit &&
                                                !selectionItems.some((x) => {
                                                    return x.key === "notAssignedExplicit" && !x.isSelected;
                                                })) ||
                                            (!notAssignedExplicit &&
                                                selectionItems.some((x) => {
                                                    return x.key === "notAssignedExplicit" && x.isSelected;
                                                }));

                                        const newNotAssignedInherited =
                                            (notAssignedInherited &&
                                                !selectionItems.some((x) => {
                                                    return x.key === "notAssignedInherited" && !x.isSelected;
                                                })) ||
                                            (!notAssignedInherited &&
                                                selectionItems.some((x) => {
                                                    return x.key === "notAssignedInherited" && x.isSelected;
                                                }));

                                        if (
                                            !newAssignedExplicit &&
                                            !newAssignedImplicit &&
                                            !newAssignedInherited &&
                                            !newNotAssignedExplicit &&
                                            !newNotAssignedInherited
                                        ) {
                                            onChange(undefined);
                                        }

                                        const newInnerFilters: Filter[] = [];

                                        if (newAssignedExplicit) {
                                            newInnerFilters.push({
                                                IsNegated: false,
                                                Value: {
                                                    LeftOperand: { Id: "PermissionType" },
                                                    Operator: FilterValueOperator.Equal,
                                                    RightOperand: { Value: "assignedExplicit" },
                                                },
                                            });
                                        }

                                        if (newAssignedImplicit) {
                                            newInnerFilters.push({
                                                IsNegated: false,
                                                Value: {
                                                    LeftOperand: { Id: "PermissionType" },
                                                    Operator: FilterValueOperator.Equal,
                                                    RightOperand: { Value: "assignedImplicit" },
                                                },
                                            });
                                        }

                                        if (newAssignedInherited) {
                                            newInnerFilters.push({
                                                IsNegated: false,
                                                Value: {
                                                    LeftOperand: { Id: "PermissionType" },
                                                    Operator: FilterValueOperator.Equal,
                                                    RightOperand: { Value: "assignedInherited" },
                                                },
                                            });
                                        }

                                        if (newNotAssignedExplicit) {
                                            newInnerFilters.push({
                                                IsNegated: false,
                                                Value: {
                                                    LeftOperand: { Id: "PermissionType" },
                                                    Operator: FilterValueOperator.Equal,
                                                    RightOperand: { Value: "notAssignedExplicit" },
                                                },
                                            });
                                        }

                                        if (newNotAssignedInherited) {
                                            newInnerFilters.push({
                                                IsNegated: false,
                                                Value: {
                                                    LeftOperand: { Id: "PermissionType" },
                                                    Operator: FilterValueOperator.Equal,
                                                    RightOperand: { Value: "notAssignedInherited" },
                                                },
                                            });
                                        }

                                        onChange({
                                            IsNegated: false,
                                            Group: {
                                                Operator: FilterGroupOperator.Or,
                                                Filters: newInnerFilters,
                                            },
                                        });
                                    }}
                                />
                            );
                        },
                        onRender: (item) => {
                            const impliedBy = item.ImpliedBy;
                            const isImplied = item.IsImplied;
                            const inheritedFrom = item.InheritedFrom;
                            const isInherited = item.IsInherited;

                            return (
                                <Stack horizontal>
                                    <ChoiceGroup
                                        disabled={
                                            props.isLoadingRoles ||
                                            (item.ParentPermissionId !== undefined &&
                                                !item.IsParentInheritedOrExplicit) ||
                                            props.disabled
                                        }
                                        options={[
                                            {
                                                key: "Yes",
                                                text: localization.Yes,
                                                onClick:
                                                    item.ChildPermissions.length === 0
                                                        ? undefined
                                                        : () => {
                                                              setSelection(item.Id);
                                                          },
                                                styles: { root: { minHeight: 0 } },
                                            },
                                            {
                                                key: "No",
                                                text: localization.No,
                                                disabled: isImplied,
                                                styles: { root: { minHeight: 0 } },
                                            },
                                            {
                                                key: "Special",
                                                text: `${
                                                    isImplied || isInherited ? localization.Yes : localization.No
                                                } (${
                                                    isImplied
                                                        ? localization.UserManagement.PermissionsImplied
                                                        : localization.UserManagement.PermissionsInherited
                                                })`,
                                                onRenderLabel: (renderProps, defaultRender) => {
                                                    if (isImplied) {
                                                        return (
                                                            <TooltipHost
                                                                content={
                                                                    <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                                                                        <Text>Impliciraju ovlasti:</Text>

                                                                        <Stack
                                                                            horizontal
                                                                            wrap
                                                                            tokens={{ childrenGap: theme.spacing.s1 }}
                                                                        >
                                                                            {impliedBy.map((x) => {
                                                                                return (
                                                                                    <Text
                                                                                        key={x.CompleteId}
                                                                                        styles={{
                                                                                            root: {
                                                                                                backgroundColor:
                                                                                                    theme.palette
                                                                                                        .themeLighter,
                                                                                                borderRadius:
                                                                                                    theme.effects
                                                                                                        .roundedCorner2,
                                                                                                padding: "2px 5px",
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        {x.Name}{" "}
                                                                                        <span
                                                                                            style={{
                                                                                                color: theme.palette
                                                                                                    .neutralSecondaryAlt,
                                                                                            }}
                                                                                        >
                                                                                            ({x.CompleteId})
                                                                                        </span>
                                                                                    </Text>
                                                                                );
                                                                            })}
                                                                        </Stack>
                                                                    </Stack>
                                                                }
                                                                directionalHint={DirectionalHint.rightCenter}
                                                            >
                                                                {defaultRender!(renderProps)}
                                                            </TooltipHost>
                                                        );
                                                    }

                                                    if (isInherited) {
                                                        return (
                                                            <TooltipHost
                                                                content={
                                                                    <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                                                                        <Text>
                                                                            {
                                                                                localization.UserManagement
                                                                                    .PermissionsInheritedFrom
                                                                            }
                                                                        </Text>

                                                                        <Stack
                                                                            horizontal
                                                                            wrap
                                                                            tokens={{ childrenGap: theme.spacing.s1 }}
                                                                        >
                                                                            {inheritedFrom.map((x) => {
                                                                                return (
                                                                                    <Text
                                                                                        key={x.Id}
                                                                                        styles={{
                                                                                            root: {
                                                                                                backgroundColor:
                                                                                                    theme.palette
                                                                                                        .themeLighter,
                                                                                                borderRadius:
                                                                                                    theme.effects
                                                                                                        .roundedCorner2,
                                                                                                padding: "2px 5px",
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        {x.Description}{" "}
                                                                                        <span
                                                                                            style={{
                                                                                                color: theme.palette
                                                                                                    .neutralSecondaryAlt,
                                                                                            }}
                                                                                        >
                                                                                            ({x.Name})
                                                                                        </span>
                                                                                    </Text>
                                                                                );
                                                                            })}
                                                                        </Stack>
                                                                    </Stack>
                                                                }
                                                                directionalHint={DirectionalHint.rightCenter}
                                                            >
                                                                {defaultRender!(renderProps)}
                                                            </TooltipHost>
                                                        );
                                                    }

                                                    return defaultRender!(renderProps);
                                                },
                                                styles: { root: { minHeight: 0 } },
                                            },
                                        ]}
                                        isInline
                                        selectedKey={
                                            addedPermissions.includes(item.CompleteId)
                                                ? "Yes"
                                                : removedPermissions.includes(item.CompleteId)
                                                ? "No"
                                                : "Special"
                                        }
                                        onChange={(_, option) => {
                                            const addedPermissionFormValue = form.AddedPermissions as FormValue<
                                                string[]
                                            >;
                                            const removedPermissionFormValue = form.RemovedPermissions as FormValue<
                                                string[]
                                            >;

                                            if (option?.key === "Yes") {
                                                addedPermissionFormValue.$push(item.CompleteId);
                                                removedPermissionFormValue.$filter((x) => {
                                                    return x !== item.CompleteId;
                                                });

                                                return;
                                            }

                                            if (option?.key === "No") {
                                                removedPermissionFormValue.$setValue([
                                                    ...(removedPermissionFormValue.$value ?? []).filter((x) => {
                                                        return !x.startsWith(`${item.CompleteId}.`);
                                                    }),
                                                    item.CompleteId,
                                                ]);
                                                addedPermissionFormValue.$filter((x) => {
                                                    return (
                                                        x !== item.CompleteId && !x.startsWith(`${item.CompleteId}.`)
                                                    );
                                                });

                                                return;
                                            }

                                            addedPermissionFormValue.$filter((x) => {
                                                return item.IsInherited || item.IsImplied
                                                    ? x !== item.CompleteId
                                                    : x !== item.CompleteId && !x.startsWith(`${item.CompleteId}.`);
                                            });
                                            removedPermissionFormValue.$filter((x) => {
                                                return item.IsInherited || item.IsImplied
                                                    ? x !== item.CompleteId
                                                    : x !== item.CompleteId && !x.startsWith(`${item.CompleteId}.`);
                                            });
                                        }}
                                    />

                                    {props.isLoadingRoles && <Spinner size={SpinnerSize.small} />}
                                </Stack>
                            );
                        },
                    },
                    ...(data.some((x) => {
                        return x.ChildPermissions.length > 0;
                    })
                        ? [
                              {
                                  id: "Details",
                                  name: "",
                                  width: 90,
                                  onRender: (item: Permission) => {
                                      if (item.ChildPermissions.length === 0) {
                                          return null;
                                      }

                                      return (
                                          <ActionButton
                                              iconProps={{ iconName: "MultiSelect" }}
                                              styles={{ root: { height: "auto", padding: 0 } }}
                                              onClick={() => {
                                                  setSelection(item.Id);
                                              }}
                                          >
                                              {localization.Details}
                                          </ActionButton>
                                      );
                                  },
                                  isSortable: false,
                                  isResizable: false,
                              },
                          ]
                        : []),
                ]}
                hideToolbar
                defaultSort={{ Id: "Name", IsDescending: false }}
                disableSearching
                disablePagination
                disableAggregating
                selectionMode={SelectionMode.none}
                onItemInvoked={(key) => {
                    const permission = props.permissions.find((x) => {
                        return x.Id === key;
                    });

                    if (permission && permission.ChildPermissions.length > 0) {
                        setSelection(key);
                    }
                }}
            />

            <Panel
                isOpen={selectedPermission !== undefined}
                onDismiss={() => {
                    setSelectedPermissionId(undefined);
                }}
                isLightDismiss
                headerText={selectedPermission?.Name}
                type={PanelType.custom}
                customWidth="850px"
                styles={{
                    scrollableContent: { height: "100%", display: "flex", flexDirection: "column" },
                    content: {
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        padding: 0,
                        paddingTop: theme.spacing.m,
                    },
                    footer: {
                        borderTopWidth: 1,
                        borderTopStyle: "solid",
                        borderTopColor: theme.palette.neutralLighter,
                    },
                }}
                onRenderFooterContent={() => {
                    return (
                        <Stack horizontal>
                            <PrimaryButton
                                onClick={() => {
                                    return setSelectedPermissionId(undefined);
                                }}
                            >
                                {localization.Ok}
                            </PrimaryButton>
                        </Stack>
                    );
                }}
            >
                <UserPermissionsTable
                    form={props.form}
                    roles={props.roles}
                    permissions={selectedPermission?.ChildPermissions ?? []}
                    permissionImplicationGraph={props.permissionImplicationGraph}
                    allFlatPermissions={props.allFlatPermissions}
                    isLoadingRoles={props.isLoadingRoles}
                    disabled={props.disabled}
                />
            </Panel>
        </>
    );
};

export default UserPermissionsTable;
