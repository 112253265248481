import {
    ActionButton,
    ActivityItem,
    IButtonStyles,
    Icon,
    List,
    Panel,
    PanelType,
    Separator,
    Spinner,
    Stack,
    Text,
    useEntityDataTableContext,
    useInCoreLocalization,
    useOnChange,
    usePermissionService,
    useTheme,
} from "@in-core";
import { GetActivities } from "@in-core/api/Entity";
import { useMemo, useState } from "react";
import Details from "./Details";

const Activity = () => {
    const entityDataTableContext = useEntityDataTableContext()!;
    const permissionsService = usePermissionService();
    const getActivitiesApi = GetActivities.useApi();
    const [activityDetails, setActivityDetails] = useState<GetActivities.ActivityInfo>();
    const theme = useTheme();
    const localization = useInCoreLocalization();

    const commandBarButtonStyles: IButtonStyles = {
        root: {
            borderRadius: theme.effects.roundedCorner2,
            height: 32,
            backgroundColor: theme.palette.neutralLighterAlt,
        },
        rootHovered: {
            backgroundColor: theme.palette.themeLighterAlt,
            color: theme.palette.neutralPrimary,
        },
        rootPressed: {
            backgroundColor: theme.palette.themeLighterAlt,
            color: theme.palette.neutralPrimary,
        },
    };

    const isAuditLogAuthorized = useMemo(() => {
        return permissionsService.isAuthorized(`${entityDataTableContext.permission.CompleteId}.AuditLog`);
    }, [entityDataTableContext.permission.CompleteId]);

    useOnChange(() => {
        const key = entityDataTableContext.selectedKeys[0];

        if (key === undefined) {
            return;
        }

        const keyObj: any = {};
        (entityDataTableContext.entityDescriptor.PrimaryKey?.PropertyIds ?? []).sort().forEach((x) => {
            keyObj[x] = key[x] ?? key;
        });

        getActivitiesApi.call({
            Permission: entityDataTableContext.permission.CompleteId,
            Key: keyObj,
        });
    }, [entityDataTableContext.permission.CompleteId, entityDataTableContext.selectedKeys]);

    if (getActivitiesApi.isIdle || getActivitiesApi.isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <List
                items={getActivitiesApi.data!}
                onRenderCell={(item) => {
                    const activityDisplayName = item!.Permission?.Name ?? item!.Name;
                    const activityDisplayId = item!.Permission?.Id;

                    return (
                        <Stack>
                            <ActivityItem
                                activityIcon={
                                    item!.Permission?.Icon ? (
                                        <Icon
                                            iconName={item!.Permission.Icon}
                                            styles={{ root: { color: theme.palette.themePrimary } }}
                                        />
                                    ) : undefined
                                }
                                activityDescription={
                                    item!.User ? (
                                        <Text>
                                            {localization.EntityDataTable.ActivityUserPrefix}{" "}
                                            <span
                                                style={{
                                                    color: theme.palette.themePrimary,
                                                    fontWeight: "bold",
                                                    fontStyle: item!.User.Id ? undefined : "italic",
                                                }}
                                            >
                                                {item!.User.Name ? (
                                                    <>
                                                        {item!.User.Name}{" "}
                                                        <span style={{ color: theme.palette.themeTertiary }}>
                                                            ({item!.User.UserName})
                                                        </span>
                                                    </>
                                                ) : (
                                                    item!.User.UserName
                                                )}
                                            </span>{" "}
                                            {localization.EntityDataTable.ActivityUserSuffix}{" "}
                                            <span style={{ color: theme.palette.themePrimary, fontWeight: "bold" }}>
                                                {activityDisplayName}{" "}
                                                {activityDisplayId && (
                                                    <span style={{ color: theme.palette.themeTertiary }}>
                                                        ({activityDisplayId})
                                                    </span>
                                                )}
                                            </span>
                                            .
                                        </Text>
                                    ) : item!.ConnectedApp ? (
                                        <Text>
                                            {localization.EntityDataTable.ActivityConnectedAppPrefix}{" "}
                                            <span
                                                style={{
                                                    color: theme.palette.themePrimary,
                                                    fontWeight: "bold",
                                                    fontStyle: item!.ConnectedApp.Id ? undefined : "italic",
                                                }}
                                            >
                                                {item!.ConnectedApp.Name ? (
                                                    <>
                                                        {item!.ConnectedApp.Name}{" "}
                                                        <span style={{ color: theme.palette.themeTertiary }}>
                                                            ({item!.ConnectedApp.Id})
                                                        </span>
                                                    </>
                                                ) : (
                                                    item!.ConnectedApp.Id
                                                )}
                                            </span>{" "}
                                            {localization.EntityDataTable.ActivityConnectedAppSuffix}{" "}
                                            <span style={{ color: theme.palette.themePrimary, fontWeight: "bold" }}>
                                                {activityDisplayName}{" "}
                                                {activityDisplayId && (
                                                    <span style={{ color: theme.palette.themeTertiary }}>
                                                        ({activityDisplayId})
                                                    </span>
                                                )}
                                            </span>
                                            .
                                        </Text>
                                    ) : (
                                        <Text>
                                            {localization.EntityDataTable.ActivityGenericPrefix}{" "}
                                            <span style={{ color: theme.palette.themePrimary, fontWeight: "bold" }}>
                                                {activityDisplayName}{" "}
                                                {activityDisplayId && (
                                                    <span style={{ color: theme.palette.themeTertiary }}>
                                                        ({activityDisplayId})
                                                    </span>
                                                )}
                                            </span>{" "}
                                            {localization.EntityDataTable.ActivityGenericSuffix}.
                                        </Text>
                                    )
                                }
                                timeStamp={`${item!.Time.toLocaleString("hr")}`}
                            />

                            {isAuditLogAuthorized && (
                                <Stack
                                    horizontal
                                    tokens={{ childrenGap: theme.spacing.s1 }}
                                    styles={{ root: { marginTop: theme.spacing.s1 } }}
                                >
                                    <ActionButton
                                        iconProps={{ iconName: "History" }}
                                        styles={commandBarButtonStyles}
                                        onClick={() => {
                                            setActivityDetails(item!);
                                        }}
                                    >
                                        {localization.EntityDataTable.ActivityViewChanges}
                                    </ActionButton>
                                </Stack>
                            )}

                            <Separator />
                        </Stack>
                    );
                }}
                style={{ paddingTop: theme.spacing.m }}
            />

            <Panel
                headerText={localization.EntityDataTable.ActivityViewChanges}
                type={PanelType.medium}
                isOpen={activityDetails !== undefined}
                onDismiss={() => {
                    setActivityDetails(undefined);
                }}
            >
                {activityDetails && <Details activity={activityDetails} />}
            </Panel>
        </>
    );
};

export default Activity;
