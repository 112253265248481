import { Label, Stack, Text, TextField, Toggle, useInCoreLocalization, useTheme } from "@in-core";
import React from "react";

export interface ISettingValueEditorProps {
    value?: string;
    onChange?: (newValue?: string) => void;
    relevantSettingValues?: { Id: any; Name: string; Value: string | undefined }[];
}

const SettingValueEditor = (props: ISettingValueEditorProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();

    const getField = () => {
        return (
            <TextField
                value={props.value ?? ""}
                onChange={(_, newValue) => {
                    props.onChange && props.onChange(newValue ?? "");
                }}
                disabled={props.value === undefined}
            />
        );
    };

    return (
        <Stack tokens={{ childrenGap: theme.spacing.m }}>
            {props.relevantSettingValues && props.relevantSettingValues.length > 0 && (
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "minmax(100px, 25%) 1fr",
                        alignItems: "center",
                        justifyItems: "start",
                        rowGap: theme.spacing.s2,
                        columnGap: theme.spacing.m,
                    }}
                >
                    {props.relevantSettingValues.map((x, i) => {
                        return (
                            <React.Fragment key={x.Id}>
                                <Label styles={{ root: { gridColumn: "1", gridRow: (i + 1).toString() } }}>
                                    {x.Name}
                                </Label>

                                <Text
                                    styles={{
                                        root: {
                                            gridColumn: "2",
                                            gridRow: (i + 1).toString(),
                                            fontStyle: x.Value === undefined ? "italic" : undefined,
                                            backgroundColor:
                                                x.Value === undefined ? undefined : theme.palette.neutralLighter,
                                            padding: "2px 6px",
                                            borderRadius: theme.effects.roundedCorner2,
                                        },
                                    }}
                                >
                                    {x.Value ?? localization.Settings.ValueNotDefined}
                                </Text>
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
            <Stack styles={{ root: { maxWidth: 400 } }}>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: theme.spacing.s1 }}>
                    <Label>{localization.Settings.Value}</Label>

                    <Toggle
                        styles={{ root: { marginBottom: 0 } }}
                        checked={props.value !== undefined}
                        onChange={(_, checked) => {
                            props.onChange && props.onChange(checked ? "" : undefined);
                        }}
                        title={
                            props.value === undefined
                                ? localization.Settings.SetValue
                                : localization.Settings.ClearValue
                        }
                    />
                </Stack>

                {getField()}
            </Stack>
        </Stack>
    );
};

export default SettingValueEditor;
