import {
    FormGroup,
    useForm,
    FormTextField,
    useTheme,
    FormNumericField,
    FormDatePicker,
    FormCheckbox,
    EntityDataTableDropdown,
    useOnMount,
    DataEntryForm,
    DataEntryType,
    Spinner,
    useEntityDataTableContext,
    useInCoreLocalization,
    FormDropdown,
} from "@in-core";
import { Create, DataType, GetEntityDescriptor, PropertyEnumValue, Read, Update, Validate } from "@in-core/api/Entity";
import { useCallback } from "react";

interface IFormElement {
    id: string;
    label: string;
    description?: string;
    order: number;
    group?: string;
    data?: any;
}

export interface IAutoEntityFormProps {
    entity: string;
}

const AutoEntityForm = (props: IAutoEntityFormProps) => {
    const theme = useTheme();
    const entityDataTableContext = useEntityDataTableContext();
    const getEntityDescriptorApi = GetEntityDescriptor.useApi();
    const form = useForm({
        validate: async (values) => {
            const validationResponse = await Validate.callApi({
                Permission: props.entity,
                Data: values,
            });

            return validationResponse.data!;
        },
    });
    const localization = useInCoreLocalization();

    useOnMount(() => {
        getEntityDescriptorApi.call({ Permission: props.entity });
    });

    const create = useCallback(
        async (data: any) => {
            const automaticallyGeneratedPropertyIds = getEntityDescriptorApi.data
                ? getEntityDescriptorApi.data.Properties.filter((x) => {
                      return x.IsAutomaticallyGenerated;
                  }).map((x) => {
                      return x.Id;
                  })
                : [];

            const primaryKeyPropertyIds = getEntityDescriptorApi.data?.PrimaryKey?.PropertyIds ?? [];

            const keyObject: any = {};
            getEntityDescriptorApi.data?.Properties.filter((x) => {
                return primaryKeyPropertyIds.includes(x.Id) && !automaticallyGeneratedPropertyIds.includes(x.Id);
            }).forEach((x) => {
                keyObject[x.Id] = data[x.Id];
            });

            const dataObject: any = {};
            getEntityDescriptorApi.data?.Properties.filter((x) => {
                return !primaryKeyPropertyIds.includes(x.Id);
            }).forEach((x) => {
                dataObject[x.Id] = data[x.Id];
            });

            return await Create.callApi({
                Permission: props.entity,
                Key: Object.keys(keyObject).length > 0 ? keyObject : undefined,
                Data: dataObject,
            });
        },
        [props.entity, getEntityDescriptorApi.data],
    );

    const read = useCallback(
        async (key: any) => {
            return await Read.callApi({
                Permission: props.entity,
                Key: key,
            });
        },
        [props.entity],
    );

    const update = useCallback(
        async (data: any) => {
            const primaryKeyPropertyIds = getEntityDescriptorApi.data?.PrimaryKey?.PropertyIds ?? [];

            const keyObject: any = {};
            getEntityDescriptorApi.data?.Properties.filter((x) => {
                return primaryKeyPropertyIds.includes(x.Id);
            }).forEach((x) => {
                keyObject[x.Id] = data[x.Id];
            });

            const dataObject: any = {};
            getEntityDescriptorApi.data?.Properties.filter((x) => {
                return !primaryKeyPropertyIds.includes(x.Id);
            }).forEach((x) => {
                dataObject[x.Id] = data[x.Id];
            });

            return await Update.callApi({
                Permission: props.entity,
                Key: keyObject,
                Data: dataObject,
            });
        },
        [props.entity, getEntityDescriptorApi.data],
    );

    const renderGroup = (elements: IFormElement[], isSection: boolean, label: string | undefined) => {
        const automaticallyGeneratedPropertyIds = getEntityDescriptorApi.data
            ? getEntityDescriptorApi.data.Properties.filter((x) => {
                  return x.IsAutomaticallyGenerated;
              }).map((x) => {
                  return x.Id;
              })
            : [];

        const primaryKeysToDisable =
            entityDataTableContext?.innerPermission?.Id !== "Create" && getEntityDescriptorApi.data
                ? getEntityDescriptorApi.data.PrimaryKey?.PropertyIds ?? []
                : [];

        return (
            <FormGroup key={label} isSection={isSection} label={label}>
                {elements.map((x) => {
                    const disabled =
                        automaticallyGeneratedPropertyIds.includes(x.id) ||
                        primaryKeysToDisable.includes(x.id) ||
                        entityDataTableContext?.innerPermission?.Id === "Read";

                    if (x.data!.isNavigation) {
                        const values = x.data.ForeignKey.PropertyIds.map((y: any) => {
                            return (form as any)[y];
                        });

                        const errorMessages = values
                            .map((y: any) => {
                                return y.$isDirty && !y.$isPendingValidation && !y.$isValidating && y.$errors.length > 0
                                    ? y.$errors.join(" ")
                                    : undefined;
                            })
                            .filter((y: any) => {
                                return y !== undefined;
                            });

                        return (
                            <EntityDataTableDropdown
                                key={x.id}
                                id={x.id}
                                label={x.label ?? x.id}
                                // description={x.description}
                                entity={x.data!.TargetEntity}
                                required={x.data.ForeignKey?.PropertyIds.some((y: string) => {
                                    return !(
                                        getEntityDescriptorApi.data!.Properties.find((z) => {
                                            return z.Id === y;
                                        })?.IsNullable ?? false
                                    );
                                })}
                                selectedKey={
                                    x.data.ForeignKey.PropertyIds.length === 1
                                        ? (form as any)[x.data.ForeignKey.PropertyIds[0]].$value
                                        : undefined
                                }
                                selectedKeys={
                                    x.data.ForeignKey.PropertyIds.length === 1
                                        ? undefined
                                        : Object.assign(
                                              {},
                                              ...x.data.ForeignKey.PropertyIds.map((y: string) => {
                                                  return {
                                                      [y]: (form as any)[y].$value,
                                                  };
                                              }),
                                          )
                                }
                                onChange={(changes) => {
                                    if (x.data.ForeignKey.PropertyIds.length === 1) {
                                        (form as any)[x.data.ForeignKey.PropertyIds[0]].$setValue(
                                            changes[0].isSelected ? changes[0].key : undefined,
                                        );
                                        return;
                                    }

                                    x.data.ForeignKey.PropertyIds.forEach((y: string) => {
                                        (form as any)[y].$setValue(
                                            changes[0].isSelected ? changes[0].key[y] : undefined,
                                        );
                                    });
                                }}
                                disabled={disabled}
                                errorMessage={errorMessages.length === 0 ? undefined : errorMessages.join(" ")}
                            />
                        );
                    }

                    const dataType: DataType = x.data!.DataType;

                    switch (dataType) {
                        case DataType.String:
                            return (
                                <FormTextField
                                    key={x.id}
                                    id={x.id}
                                    label={x.label}
                                    description={x.description}
                                    value={(form as any)[x.id]}
                                    required={!x.data.IsNullable}
                                    disabled={disabled}
                                />
                            );
                        case DataType.Number:
                            return (
                                <FormNumericField
                                    key={x.id}
                                    id={x.id}
                                    label={x.label}
                                    description={x.description}
                                    value={(form as any)[x.id]}
                                    required={!x.data.IsNullable}
                                    disabled={disabled}
                                />
                            );
                        case DataType.DateTime:
                            return (
                                <FormDatePicker
                                    key={x.id}
                                    id={x.id}
                                    label={x.label}
                                    description={x.description}
                                    value={(form as any)[x.id]}
                                    required={!x.data.IsNullable}
                                    disabled={disabled}
                                />
                            );
                        case DataType.Boolean:
                            return (
                                <FormCheckbox
                                    key={x.id}
                                    id={x.id}
                                    label={x.label}
                                    value={(form as any)[x.id]}
                                    required={!x.data.IsNullable}
                                    disabled={disabled}
                                />
                            );

                        case DataType.Enum:
                            return (
                                <FormDropdown
                                    options={(x.data?.enumValues ?? []).map((y: PropertyEnumValue) => {
                                        return {
                                            key: y.TextKey,
                                            text: y.Text,
                                        };
                                    })}
                                    key={x.id}
                                    id={x.id}
                                    label={x.label}
                                    value={(form as any)[x.id]}
                                    required={!x.data.IsNullable}
                                    disabled={disabled}
                                />
                            );
                    }

                    return null;
                })}
            </FormGroup>
        );
    };

    if (!getEntityDescriptorApi.data) {
        return <Spinner label={localization.PleaseWait} />;
    }

    const foreignKeyNavigations = getEntityDescriptorApi.data.Navigations.filter((x) => {
        return x.IsOnDependent;
    });

    const foreignKeyPropertiyIds = foreignKeyNavigations
        .map((x) => {
            return x.ForeignKey!.PropertyIds;
        })
        .flat();

    const nonForeignKeyProperties = getEntityDescriptorApi.data.Properties.filter((x) => {
        return !foreignKeyPropertiyIds.includes(x.Id);
    });

    const formElements: IFormElement[] = [
        ...nonForeignKeyProperties.map((x) => {
            return {
                id: x.Id,
                label: x.Display?.Name === undefined || x.Display.Name.trim() === "" ? x.Id : x.Display.Name,
                description: x.Display?.Description,
                order: x.Display?.Order ?? Number.MAX_VALUE,
                group: x.Display?.GroupName,
                data: { ...x, isNavigation: false, enumValues: x.EnumValues },
            };
        }),
        ...foreignKeyNavigations.map((x) => {
            return {
                id: x.Id,
                label: x.Display?.Name === undefined || x.Display.Name.trim() === "" ? x.Id : x.Display.Name,
                description: x.Display?.Description,
                order: x.Display?.Order ?? Number.MAX_VALUE,
                group: x.Display?.GroupName,
                data: { ...x, isNavigation: true },
            };
        }),
    ].sort((a, b) => {
        return a.order - b.order;
    });

    const groups: string[] = [];

    formElements.forEach((x) => {
        if (x.group !== undefined && !groups.includes(x.group)) {
            groups.push(x.group);
        }
    });

    const keyFormValues: any = {};
    getEntityDescriptorApi.data.PrimaryKey?.PropertyIds.forEach((x) => {
        keyFormValues[x] = (form as any)[x];
    });

    const automaticallyGeneratedPropertyIds = getEntityDescriptorApi.data
        ? getEntityDescriptorApi.data.Properties.filter((x) => {
              return x.IsAutomaticallyGenerated;
          }).map((x) => {
              return x.Id;
          })
        : [];

    return (
        <DataEntryForm
            type={
                getEntityDescriptorApi.data.PrimaryKey?.PropertyIds.every((x) => {
                    return automaticallyGeneratedPropertyIds.includes(x);
                })
                    ? DataEntryType.AutoKey
                    : DataEntryType.ManualKey
            }
            form={form}
            keyValues={keyFormValues}
            createApi={create}
            readApi={read}
            updateApi={update}
        >
            <div style={{ paddingTop: theme.spacing.m, paddingBottom: theme.spacing.m }}>
                {groups.length === 0 ? (
                    renderGroup(formElements, false, undefined)
                ) : (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fill,minmax(600px, 1fr))",
                            rowGap: theme.spacing.m,
                            columnGap: theme.spacing.m,
                        }}
                    >
                        {groups.map((x) => {
                            return renderGroup(
                                formElements.filter((y) => {
                                    return y.group === x;
                                }),
                                true,
                                x,
                            );
                        })}

                        {formElements.some((x) => {
                            return x.group === undefined;
                        })
                            ? renderGroup(
                                  formElements.filter((x) => {
                                      return x.group === undefined;
                                  }),
                                  true,
                                  localization.Other,
                              )
                            : null}
                    </div>
                )}
            </div>
        </DataEntryForm>
    );
};

export default AutoEntityForm;
