import { useState } from "react";
import {
    Modal,
    ScrollablePane,
    Stack,
    Spinner,
    PrimaryButton,
    DefaultButton,
    Text,
    useInCoreLocalization,
} from "@in-core";
import FileUpload, { IFileUploadProps, fileNameRegex } from "../FileUpload";
import { useTheme } from "@fluentui/react";
import { NewContentInfo, NewDirectoryInfo, NewFileInfo } from "@in-core/api/Documents";

export interface IFileUploadModalProps extends Omit<IFileUploadProps, "disabled"> {
    onDismiss?: () => void;
    onUpload?: (content: NewContentInfo) => Promise<void>;
}

const FileUploadModal = (props: IFileUploadModalProps) => {
    const [content, setContent] = useState<NewContentInfo>();
    const [isProcessing, setIsProcessing] = useState(false);
    const theme = useTheme();
    const localization = useInCoreLocalization();

    const areAllValid = (directories: NewDirectoryInfo[], files: NewFileInfo[]) => {
        const handeledNames: string[] = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (handeledNames.includes(file.Name)) {
                return false;
            }
            if (!fileNameRegex.test(file.Name)) {
                return false;
            }

            handeledNames.push(file.Name);
        }

        for (let i = 0; i < directories.length; i++) {
            const directory = directories[i];

            if (handeledNames.includes(directory.Name)) {
                return false;
            }
            if (!fileNameRegex.test(directory.Name)) {
                return false;
            }
            if (!areAllValid(directory.Subdirectories, directory.Files)) {
                return false;
            }

            handeledNames.push(directory.Name);
        }

        return true;
    };

    const isInvalid =
        content &&
        ((props.existingNames &&
            [
                ...content.Directories.map((x) => {
                    return x.Name;
                }),
                ...content.Files.map((x) => {
                    return x.Name;
                }),
            ].filter((x) => {
                return props.existingNames?.includes(x);
            }).length > 0) ||
            !areAllValid(content.Directories, content.Files));

    return (
        <Modal
            isOpen
            onDismiss={props.onDismiss}
            isBlocking={isProcessing}
            styles={{
                main: { width: 700, height: 500 },
                scrollableContent: {
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                },
            }}
        >
            <Text variant="large" styles={{ root: { padding: theme.spacing.m } }}>
                {localization.FileUploadModal.Title}
            </Text>

            <ScrollablePane styles={{ root: { flex: 1, position: "relative" } }}>
                <FileUpload
                    {...props}
                    contentChanged={(result) => {
                        setContent(result);
                        props.contentChanged && props.contentChanged(result);
                    }}
                    disabled={isProcessing}
                />
            </ScrollablePane>

            <Stack
                horizontal
                horizontalAlign="end"
                tokens={{ padding: theme.spacing.m, childrenGap: theme.spacing.s1 }}
            >
                {isProcessing && <Spinner />}

                <PrimaryButton
                    primary
                    disabled={
                        !content ||
                        (content.Directories.length === 0 && content.Files.length === 0) ||
                        isProcessing ||
                        isInvalid
                    }
                    onClick={async () => {
                        if (!props.onUpload) {
                            return;
                        }

                        setIsProcessing(true);
                        await props.onUpload(content!);
                        setIsProcessing(false);
                        props.onDismiss && props.onDismiss();
                    }}
                >
                    {localization.Upload}
                </PrimaryButton>

                <DefaultButton
                    disabled={isProcessing}
                    onClick={() => {
                        props.onDismiss && props.onDismiss();
                    }}
                >
                    {localization.Cancel}
                </DefaultButton>
            </Stack>
        </Modal>
    );
};

export default FileUploadModal;
