import { FormSection, ILabelStyles, useTheme } from "@in-core";

export interface IFormGroupProps {
    label?: string;
    labelStyles?: ILabelStyles;
    isSection?: boolean;
    elementWidth?: number;
    verticalGap?: number | string;
    horizontalGap?: number | string;
    isVertical?: boolean;
    children?: React.ReactNode;
}

const FormGroup = (props: IFormGroupProps) => {
    const theme = useTheme();

    const isSection = props.isSection === true || props.label !== undefined;
    const elementWidth = props.elementWidth ?? 200;
    const verticalGap = props.verticalGap ?? theme.spacing.s1;
    const horizontalGap = props.horizontalGap ?? theme.spacing.s1;

    const renderGroup = () => {
        return (
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(auto-fill,minmax(${elementWidth}px, 1fr))`,
                    rowGap: verticalGap,
                    columnGap: horizontalGap,
                }}
            >
                {props.children}
            </div>
        );
    };

    return isSection ? (
        <FormSection label={props.label} labelStyles={props.labelStyles}>
            {renderGroup()}
        </FormSection>
    ) : (
        renderGroup()
    );
};

export default FormGroup;
