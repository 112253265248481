import { EntityDataTable, IEntityDataTableContext } from "@in-core";
import { FilterValueOperator } from "@in-core/api";
import DataEntry from "./DataEntry";

export interface IUsersProps {
    additionalUserContent?: (form: any, entityDataTableContext: IEntityDataTableContext) => React.ReactNode;
}

const Users = (props: IUsersProps) => {
    return (
        <EntityDataTable
            routable
            entity="Users"
            defaultSort={{ Id: "Name", IsDescending: false }}
            defaultFilters={{
                IsBlocked: {
                    IsNegated: false,
                    Value: {
                        LeftOperand: { Id: "IsBlocked" },
                        Operator: FilterValueOperator.Equal,
                        RightOperand: { Value: false },
                    },
                },
            }}
            innerComponents={{
                Create: <DataEntry additionalUserContent={props.additionalUserContent} />,
                Read: <DataEntry additionalUserContent={props.additionalUserContent} />,
                Update: <DataEntry additionalUserContent={props.additionalUserContent} />,
            }}
        />
    );
};

export default Users;
