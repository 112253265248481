import {
    FormGroup,
    FormTextField,
    ScrollablePane,
    TextField,
    useEntityDataTableContext,
    UseForm,
    useInCoreLocalization,
} from "@in-core";

export interface IBasicRoleDataProps {
    form: UseForm;
}

const BasicRoleData = (props: IBasicRoleDataProps) => {
    const form = props.form as any;
    const entityDataTableContext = useEntityDataTableContext()!;
    const localization = useInCoreLocalization();

    const isRead = entityDataTableContext.innerPermission?.Id === "Read";

    return (
        <ScrollablePane styles={{ root: { position: "relative", height: "100%" } }}>
            <FormGroup>
                <TextField
                    value={form.Id.$value?.toString() ?? ""}
                    disabled
                    label={localization.UserManagement.RoleId}
                />

                <FormTextField
                    value={form.Name}
                    label={localization.UserManagement.RoleName}
                    required
                    disabled={isRead}
                />

                <FormTextField
                    value={form.Description}
                    label={localization.UserManagement.RoleDescription}
                    required
                    disabled={isRead}
                />
            </FormGroup>
        </ScrollablePane>
    );
};

export default BasicRoleData;
