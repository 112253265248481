import { Stack, TextField, useOnChange, useTheme } from "@in-core";
import { IFilterProps } from "..";
import { FilterValueOperator } from "@in-core/api";
import OperatorDropdown from "../OperatorDropdown";
import { DefaultFiltersForDataType } from "../..";
import { useRef, useState } from "react";
import { DataType } from "@in-core/api/Entity";

const StringFilter = (props: IFilterProps) => {
    const theme = useTheme();
    const [operator, setOperator] = useState(
        props.filter?.Value?.Operator ?? DefaultFiltersForDataType[DataType.String],
    );
    const [value, setValue] = useState<string | undefined>(props.filter?.Value?.RightOperand?.Value);
    const lastInvokedChangeHanlderValueRef = useRef<string | undefined>(undefined);

    const onValueChanged = (actualNewValue: string | undefined) => {
        lastInvokedChangeHanlderValueRef.current = actualNewValue;

        props.onFilterChanged(
            actualNewValue === undefined || actualNewValue.trim() === ""
                ? undefined
                : {
                      IsNegated: false,
                      Value: {
                          LeftOperand: {
                              Id: props.id,
                          },
                          Operator: operator,
                          RightOperand: {
                              Value: actualNewValue.trim(),
                          },
                      },
                  },
        );
    };

    useOnChange(() => {
        setOperator((prevOperator) => {
            return props.filter?.Value?.Operator ?? prevOperator;
        });
        setValue(props.filter?.Value?.RightOperand?.Value);
    }, [props.filter]);

    useOnChange(
        () => {
            if (props.isChangeInstant || value === lastInvokedChangeHanlderValueRef.current) {
                return;
            }

            onValueChanged(value);
        },
        [value],
        {
            debounce: 500,
            fireOnFirstSet: false,
        },
    );

    return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
            <OperatorDropdown
                operators={[
                    FilterValueOperator.Contains,
                    FilterValueOperator.NotContains,
                    FilterValueOperator.StartsWith,
                    FilterValueOperator.NotStartsWith,
                    FilterValueOperator.EndsWith,
                    FilterValueOperator.NotEndsWith,
                    FilterValueOperator.Equal,
                    FilterValueOperator.NotEqual,
                    FilterValueOperator.IsEmpty,
                    FilterValueOperator.IsNotEmpty,
                ]}
                selectedOperator={operator}
                onChange={(newOperator) => {
                    if (newOperator === "InRange") {
                        return;
                    }

                    if (operator === newOperator) {
                        return;
                    }

                    if (newOperator === FilterValueOperator.IsEmpty || newOperator === FilterValueOperator.IsNotEmpty) {
                        setOperator(newOperator);
                        setValue(undefined);

                        lastInvokedChangeHanlderValueRef.current = undefined;

                        props.onFilterChanged({
                            IsNegated: false,
                            Value: {
                                LeftOperand: {
                                    Id: props.id,
                                },
                                Operator: newOperator,
                                RightOperand: undefined,
                            },
                        });

                        return;
                    }

                    if (operator === FilterValueOperator.IsEmpty || operator === FilterValueOperator.IsNotEmpty) {
                        setOperator(newOperator);
                        setValue(undefined);

                        lastInvokedChangeHanlderValueRef.current = undefined;

                        props.onFilterChanged(undefined);

                        return;
                    }

                    setOperator(newOperator);

                    if (value === undefined) {
                        return;
                    }

                    lastInvokedChangeHanlderValueRef.current = value;

                    props.onFilterChanged({
                        IsNegated: false,
                        Value: {
                            LeftOperand: {
                                Id: props.id,
                            },
                            Operator: newOperator,
                            RightOperand: {
                                Value: value,
                            },
                        },
                    });
                }}
            />

            <TextField
                value={value ?? ""}
                onChange={(_, newValue) => {
                    setValue(newValue);

                    if (props.isChangeInstant) {
                        onValueChanged(newValue);
                    }
                }}
                styles={{
                    root: {
                        flex: 1,
                    },
                    field: {
                        backgroundColor: theme.palette.neutralLighterAlt,
                    },
                    fieldGroup: {
                        borderColor: theme.palette.neutralQuaternary,
                    },
                }}
                disabled={operator === FilterValueOperator.IsEmpty || operator === FilterValueOperator.IsNotEmpty}
            />
        </Stack>
    );
};

export default StringFilter;
