import { generateUuid, isObject, UseApi, useOnChange } from "@in-core";
import { ValidationResult } from "@in-core";
import useOnMount from "@in-core/hooks/useOnMount";
import { useCallback, useRef, useState } from "react";

export type DeepPartial<T> = T extends Date
    ? T
    : T extends Array<any>
    ? T
    : T extends object
    ? {
          [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T extends boolean
    ? boolean
    : T;

export type DeepPartialTree<T, TValues> = T extends Date
    ? TValues
    : T extends object
    ? {
          [P in keyof T]?: DeepPartialTree<T[P], TValues>;
      } & TValues
    : TValues;

export type NonUndefined<T> = T extends undefined ? never : T;

export type MutatingArrayMethods = "pop" | "push" | "shift" | "unshift" | "reverse" | "sort" | "splice";

export type MutatingArrayMethodTypes<T = any> = {
    readonly $pop: () => T | undefined;
    readonly $push: (...items: T[]) => number;
    readonly $shift: () => T | undefined;
    readonly $unshift: (...items: T[]) => number;
    readonly $reverse: () => void;
    readonly $sort: (compareFn?: ((a: T, b: T) => number) | undefined) => void;
    readonly $splice: (start: number, deleteCount?: number, ...items: T[]) => T[];
    readonly $filter: (predicate: (value: T, index: number, array: T[]) => boolean) => void;
};

export type FormValue<T = any> = {
    readonly $value: DeepPartial<T> | undefined;
    readonly $setValue: (value: DeepPartial<T> | undefined) => void;
    readonly $clear: () => void;
    readonly $hasValue: boolean;
    readonly $errors: string[];
    readonly $setErrors: (errors: string[] | undefined) => void;
    readonly $isValid: boolean;
    readonly $setIsValid: (isValid: boolean) => void;
    readonly $isPendingValidation: boolean;
    readonly $setIsPendingValidation: (isPendingValidation: boolean) => void;
    readonly $isValidating: boolean;
    readonly $setIsValidating: (isValidating: boolean) => void;
    readonly $isDirty: boolean;
    readonly $setIsDirty: (isDirty: boolean) => void;
    readonly $path: string[];
} & (T extends Omit<Array<infer R>, MutatingArrayMethods>
    ? MutatingArrayMethodTypes<R> & {
          [key: number]: FormData<T[number]>;
          $map: <U>(callbackfn: (value: FormData<T[number]>, index: number, array: FormData<T[number]>[]) => U) => U;
      }
    : {});

export type FormData<T> = [T] extends [Array<any>]
    ? FormValue<T>
    : [T] extends [Date]
    ? FormValue<T>
    : [T] extends [object]
    ? {
          readonly [P in keyof T]-?: FormData<NonUndefined<T[P]>>;
      } & FormValue<NonUndefined<T>>
    : FormValue<NonUndefined<T>>;

export type UseForm<T extends object = any> = FormData<T> & {
    readonly $id: string;
    readonly $reset: (values?: DeepPartial<T>, options?: Partial<ResetOptions>) => void;
    readonly $handleSubmit: (
        validHandler: (data: T) => void,
        invalidHandler?: (data: DeepPartial<T>) => void,
    ) => (event?: React.FormEvent<HTMLFormElement>) => void;
    readonly $isSubmitting: boolean;
    readonly $isSubmitted: boolean;
    readonly $allErrors: string[];
    readonly $state: DeepPartialTree<
        T,
        {
            readonly $path: string[];
            readonly $value?: any;
            readonly $errors?: string[];
            readonly $isValid?: boolean;
            readonly $isPendingValidation?: boolean;
            readonly $isValidating?: boolean;
            readonly $isDirty?: boolean;
        }
    >;
};

export interface IUseFormOptions<T = any> {
    /**
     * Default form values.
     */
    defaultValues?: DeepPartial<T>;

    /**
     * Validation function.
     */
    validate?: (values: DeepPartial<T>) => Promise<ValidationResult>;

    /**
     * Function to cancel validation.
     */
    cancelValidate?: () => void;

    /**
     * Amount of milliseconds to debounce for validation.
     *
     * @default 500
     */
    validationDebounce?: number;

    /**
     * Should validate function be executed on init or not.
     *
     * @default false
     */
    shouldValidateOnInit?: boolean;

    /**
     * Should object properties be set to undefined if all it's properties are undefined.
     *
     * @default true
     */
    shouldClearEmptyObjects?: boolean;

    /**
     * Should array properties be set to undefined if it's empty.
     *
     * @default true
     */
    shouldClearEmptyArrays?: boolean;
}

export type ResetOptions = {
    shouldDirty: boolean;
    shouldValidate: boolean;
    keepErrors: boolean;
    keepDirty: boolean;
    keepValues: boolean;
    keepIsSubmitted: boolean;
};

export const useForm = <T extends object = any>(options?: IUseFormOptions<T>): UseForm<T> => {
    const idRef = useRef(generateUuid());
    const [values, setValues] = useState<DeepPartial<T>>(options?.defaultValues ?? ({} as DeepPartial<T>));
    const [fieldErrors, setFieldErrors] = useState(new Map<string, string[]>());
    const [validatingFields, setValidatingFields] = useState<string[]>([]);
    const [pendingValidationFields, setPendingValidationFields] = useState<string[]>([]);
    const [dirtyFields, setDirtyFields] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const validateTimerRef = useRef<NodeJS.Timeout>();

    useOnMount(() => {
        if (options?.shouldValidateOnInit ?? false) {
            validate(values);
        }
    });

    const validate = useCallback(
        async (values: DeepPartial<T>, validatingPaths?: string[][]) => {
            if (options?.validate === undefined) {
                return;
            }

            const keys = validatingPaths?.map((x) => {
                return x.join(".");
            });

            if (keys !== undefined) {
                setValidatingFields((prevValidatingFields) => {
                    return [...prevValidatingFields, ...keys].filter((x, i, a) => {
                        return a.indexOf(x) === i;
                    });
                });

                setPendingValidationFields([]);
            }

            const validateTimerId = validateTimerRef.current;
            const validationResult = await options.validate(values);

            if (validateTimerRef.current !== validateTimerId) {
                return;
            }

            if (keys !== undefined) {
                setValidatingFields([]);
            }

            setFieldErrors((prevFieldErrors) => {
                const validationResultErrors = getErrorsFromValidationResult(validationResult);

                prevFieldErrors.forEach((value, key) => {
                    if (!key.startsWith("__")) {
                        return;
                    }

                    validationResultErrors.set(key, value);
                });

                return validationResultErrors;
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [options?.validate],
    );

    const debounceValidate = useCallback(
        (values: DeepPartial<T>, pathToValidate: string[]) => {
            clearTimeout(validateTimerRef.current);
            if (options?.cancelValidate) {
                options?.cancelValidate();
            }

            validateTimerRef.current = setTimeout(() => {
                validate(values, [pathToValidate]);
            }, options?.validationDebounce ?? 500);
        },
        [options?.validationDebounce, validate],
    );

    const getValue = useCallback(
        (path: string[]) => {
            let value: any = values;

            for (let i = 0; i < path.length; i++) {
                value = value[path[i]];

                if (value === undefined) {
                    break;
                }
            }

            return value;
        },
        [values],
    );

    const setValue = useCallback(
        (path: string[], newValue: any) => {
            setValues((prevValues) => {
                const newValues = getModifiedObject(
                    prevValues,
                    path,
                    newValue,
                    options?.shouldClearEmptyObjects ?? true,
                    options?.shouldClearEmptyArrays ?? true,
                );
                debounceValidate(newValues, path);
                return newValues;
            });

            setDirtyFields((prevDirtyFields) => {
                return [...prevDirtyFields, path.join(".")].filter((x, i, a) => {
                    return a.indexOf(x) === i;
                });
            });

            if (options?.validate) {
                setPendingValidationFields((prevIsPendingValidationFields) => {
                    return [...prevIsPendingValidationFields, path.join(".")].filter((x, i, a) => {
                        return a.indexOf(x) === i;
                    });
                });
            }
        },
        [debounceValidate, options?.validate, options?.shouldClearEmptyArrays, options?.shouldClearEmptyObjects],
    );

    const clearValue = useCallback(
        (path: string[]) => {
            setValue(path, undefined);
        },
        [setValue],
    );

    const hasValue = useCallback(
        (path: string[]) => {
            return getValue(path) !== undefined;
        },
        [getValue],
    );

    const getErrors = useCallback(
        (path: string[]) => {
            const key = path.join(".");
            return Array.from(fieldErrors.entries())
                .filter(([entryKey]) => {
                    return entryKey === key;
                })
                .map(([_, entryValue]) => {
                    return entryValue;
                })
                .flat();
        },
        [fieldErrors],
    );

    const setErrors = useCallback((path: string[], errors: string[] | undefined) => {
        setFieldErrors((prevFieldErrors) => {
            const newErrors = new Map<string, string[]>(prevFieldErrors);

            const key = path.join(".");
            if (errors === undefined || errors.length === 0) {
                newErrors.delete(key);
            } else {
                newErrors.set(key, [...errors]);
            }

            return newErrors;
        });
    }, []);

    const getIsValid = useCallback(
        (path: string[]) => {
            if (path.length === 0) {
                return fieldErrors.size === 0;
            }

            const key = path.join(".");
            return !Array.from(fieldErrors.keys()).some((x) => {
                return x === key || x.startsWith(`${key}.`) || key.startsWith(`${x}.`);
            });
        },
        [fieldErrors],
    );

    const setIsValid = useCallback((path: string[], isValid: boolean) => {
        // TODO
        setValidatingFields((prevIsValidating) => {
            return [...prevIsValidating, path.join(".")].filter((x, i, a) => {
                return a.indexOf(x) === i;
            });
        });
    }, []);

    const getIsPendingValidation = useCallback(
        (path: string[]) => {
            if (path.length === 0) {
                return pendingValidationFields.length > 0;
            }

            const key = path.join(".");
            return pendingValidationFields.some((x) => {
                return x === key || x.startsWith(`${key}.`) || key.startsWith(`${x}.`);
            });
        },
        [pendingValidationFields],
    );

    const setIsPendingValidation = useCallback((path: string[], isPendingValidation: boolean) => {
        setPendingValidationFields((prevPendingValidationFields) => {
            return isPendingValidation
                ? [...prevPendingValidationFields, path.join(".")].filter((x, i, a) => {
                      return a.indexOf(x) === i;
                  })
                : prevPendingValidationFields.filter((x) => {
                      return x !== path.join(".");
                  });
        });
    }, []);

    const getIsValidating = useCallback(
        (path: string[]) => {
            if (path.length === 0) {
                return validatingFields.length > 0;
            }

            const key = path.join(".");
            return validatingFields.some((x) => {
                return x === key || x.startsWith(`${key}.`) || key.startsWith(`${x}.`);
            });
        },
        [validatingFields],
    );

    const setIsValidating = useCallback((path: string[], isValidating: boolean) => {
        setValidatingFields((prevValidatingFields) => {
            return isValidating
                ? [...prevValidatingFields, path.join(".")].filter((x, i, a) => {
                      return a.indexOf(x) === i;
                  })
                : prevValidatingFields.filter((x) => {
                      return x !== path.join(".");
                  });
        });
    }, []);

    const getIsDirty = useCallback(
        (path: string[]) => {
            if (path.length === 0) {
                return dirtyFields.length > 0;
            }

            const key = path.join(".");
            return dirtyFields.some((x) => {
                return x === key || x.startsWith(`${key}.`) || key.startsWith(`${x}.`);
            });
        },
        [dirtyFields],
    );

    const setIsDirty = useCallback((path: string[], isDirty: boolean) => {
        setDirtyFields((prevIsDirty) => {
            return isDirty
                ? [...prevIsDirty, path.join(".")].filter((x, i, a) => {
                      return a.indexOf(x) === i;
                  })
                : prevIsDirty.filter((x) => {
                      return x !== path.join(".");
                  });
        });
    }, []);

    const handleSubmit = useCallback(
        (validHandler: (data: T) => void, invalidHandler?: (data: DeepPartial<T>) => void) => {
            return async (event?: React.FormEvent<HTMLFormElement>) => {
                event?.preventDefault();

                if (fieldErrors.size === 0) {
                    setIsSubmitting(true);
                    setIsSubmitted(false);

                    await validHandler(values as T);

                    setIsSubmitting(false);
                    setIsSubmitted(true);
                } else if (invalidHandler !== undefined) {
                    setIsSubmitting(true);
                    setIsSubmitted(false);

                    await invalidHandler(values);

                    setIsSubmitting(false);
                    setIsSubmitted(true);
                }
            };
        },
        [values, fieldErrors.size],
    );

    const reset = useCallback(
        (values?: DeepPartial<T>, resetOptions?: Partial<ResetOptions>) => {
            if (!resetOptions?.keepValues) {
                setValues(values ?? options?.defaultValues ?? ({} as DeepPartial<T>));
            }

            if (!resetOptions?.keepDirty) {
                const dirtyFields: string[] = resetOptions?.shouldDirty && values ? getFieldPaths(values) : [];

                setDirtyFields(dirtyFields);
            }

            if (!resetOptions?.keepErrors) {
                setFieldErrors(new Map<string, string[]>());
            }

            if (resetOptions?.shouldValidate && values) {
                validate(values);
            }
        },
        [options?.defaultValues, validate],
    );

    const pop = useCallback(
        (path: string[]) => {
            const value = getValue(path);

            if (value === undefined) {
                return undefined;
            }

            const newValue = [...value];
            const result = newValue.pop();
            setValue(path, newValue);
            return result;
        },
        [getValue, setValue],
    );

    const push = useCallback(
        (path: string[], ...items: any[]) => {
            const value = getValue(path);
            const newValue = [...(value ?? [])];
            const result = newValue.push(...items);
            setValue(path, newValue);
            return result;
        },
        [getValue, setValue],
    );

    const filter = useCallback(
        (path: string[], predicate: (value: any, index: number, array: any[]) => boolean) => {
            const value = getValue(path);
            const newValue = [...(value ?? [])].filter(predicate);
            setValue(path, newValue.length === 0 ? undefined : newValue);
        },
        [getValue, setValue],
    );

    const state: any = {};

    assignStateValues(state, values, []);

    Array.from(fieldErrors.entries()).forEach(([key, value]) => {
        const keySegments = key.split(".");
        let currentObj = state;
        keySegments.forEach((x, i) => {
            currentObj.$isValid = false;
            assignDefaultValues(currentObj, keySegments.slice(0, i + 1));

            if (currentObj[x] === undefined) {
                currentObj[x] = {};
            }

            currentObj = currentObj[x];
        });
        assignDefaultValues(currentObj, keySegments);
        currentObj.$isValid = false;

        if (currentObj.$errors === undefined) {
            currentObj.$errors = [];
        }

        currentObj.$errors.push(...value);
    });

    pendingValidationFields.forEach((key) => {
        const keySegments = key.split(".");
        let currentObj = state;
        keySegments.forEach((x, i) => {
            if (currentObj[x] === undefined) {
                currentObj[x] = {};
            }

            assignDefaultValues(currentObj, keySegments.slice(0, i + 1));
            currentObj.$isPendingValidation = true;
            currentObj = currentObj[x];
        });

        assignDefaultValues(currentObj, keySegments);
        currentObj.$isPendingValidation = true;
    });

    validatingFields.forEach((key) => {
        const keySegments = key.split(".");
        let currentObj = state;
        keySegments.forEach((x, i) => {
            if (currentObj[x] === undefined) {
                currentObj[x] = {};
            }

            assignDefaultValues(currentObj, keySegments.slice(0, i + 1));
            currentObj.$isValidating = true;
            currentObj = currentObj[x];
        });

        assignDefaultValues(currentObj, keySegments);
        currentObj.$isValidating = true;
    });

    dirtyFields.forEach((key) => {
        const keySegments = key.split(".");
        let currentObj = state;
        keySegments.forEach((x, i) => {
            if (currentObj[x] === undefined) {
                currentObj[x] = {};
            }

            assignDefaultValues(currentObj, keySegments.slice(0, i + 1));
            currentObj.$isDirty = true;
            currentObj = currentObj[x];
        });

        assignDefaultValues(currentObj, keySegments);
        currentObj.$isDirty = true;
    });

    return getProxyOject({
        $id: idRef.current,
        $path: [],
        $getValue: getValue,
        $setValue: setValue,
        $clearValue: clearValue,
        $hasValue: hasValue,
        $getErrors: getErrors,
        $setErrors: setErrors,
        $getIsValid: getIsValid,
        $setIsValid: setIsValid,
        $getIsPendingValidation: getIsPendingValidation,
        $setIsPendingValidation: setIsPendingValidation,
        $getIsValidating: getIsValidating,
        $setIsValidating: setIsValidating,
        $getIsDirty: getIsDirty,
        $setIsDirty: setIsDirty,
        $handleSubmit: handleSubmit,
        $reset: reset,
        $isSubmitting: isSubmitting,
        $isSubmitted: isSubmitted,
        $allErrors: Array.from(fieldErrors.values()).flat(),
        $state: state,
        $pop: pop,
        $push: push,
        // $shift: () => T | undefined;
        // $unshift: (...items: T[]) => number;
        // $reverse: () => void;
        // $sort: (compareFn?: ((a: T, b: T) => number) | undefined) => void;
        // $splice: (start: number, deleteCount?: number, ...items: T[]) => T[];
        $filter: filter,
    });
};

export function useApiWithForm<TRequest extends object = any, TResponse = any>(
    api: UseApi<TRequest, TResponse>,
    form: UseForm<TRequest>,
): [api: UseApi<TRequest, TResponse>, form: UseForm<TRequest>] {
    return [api, form];
}

const assignStateValues = (target: any, source: any, path: string[]) => {
    target.$value = source;
    assignDefaultValues(target, path);

    if (typeof source !== "object") {
        return;
    }

    Object.keys(source).forEach((x) => {
        if (target[x] === undefined) {
            target[x] = {};
        }

        assignStateValues(target[x], source[x], [...path, x]);
    });
};

const assignDefaultValues = (obj: any, path: string[]) => {
    if (obj.$path === undefined) {
        obj.$path = path;
    }

    if (obj.$value === undefined) {
        obj.$value = undefined;
    }

    if (obj.$errors === undefined) {
        obj.$errors = [];
    }

    if (obj.$isValid === undefined) {
        obj.$isValid = true;
    }

    if (obj.$isPendingValidation === undefined) {
        obj.$isPendingValidation = false;
    }

    if (obj.$isValidating === undefined) {
        obj.$isValidating = false;
    }

    if (obj.$isDirty === undefined) {
        obj.$isDirty = false;
    }
};

const getProxyOject = (target?: any) => {
    const handler = {
        get: (target: any, key: any, receiver: any) => {
            const keyNumber = Number(key);
            if (!Number.isNaN(keyNumber)) {
                key = keyNumber;
            }

            if (key === "isProxy") {
                return true;
            }

            if (key === "$id") {
                return target.$id;
            }

            if (key === "$value") {
                return target.$getValue(target.$path);
            }

            if (key === "$setValue") {
                return (newValue: any) => {
                    return target.$setValue(target.$path, newValue);
                };
            }

            if (key === "$clear") {
                return () => {
                    return target.$clearValue(target.$path);
                };
            }

            if (key === "$hasValue") {
                return target.$hasValue(target.$path);
            }

            if (key === "$errors") {
                return target.$getErrors(target.$path);
            }

            if (key === "$setErrors") {
                return (errors: string[]) => {
                    return target.$setErrors(target.$path, errors);
                };
            }

            if (key === "$isValid") {
                return target.$getIsValid(target.$path);
            }

            if (key === "$setIsValid") {
                return (isValid: boolean) => {
                    return target.$setIsValid(target.$path, isValid);
                };
            }

            if (key === "$isPendingValidation") {
                return target.$getIsPendingValidation(target.$path);
            }

            if (key === "$setIsPendingValidation") {
                return (isPendingValidation: boolean) => {
                    return target.$setIsPendingValidation(target.$path, isPendingValidation);
                };
            }

            if (key === "$isValidating") {
                return target.$getIsValidating(target.$path);
            }

            if (key === "$setIsValidating") {
                return (isValidating: boolean) => {
                    return target.$setIsValidating(target.$path, isValidating);
                };
            }

            if (key === "$isDirty") {
                return target.$getIsDirty(target.$path);
            }

            if (key === "$setIsDirty") {
                return (isDirty: boolean) => {
                    return target.$setIsDirty(target.$path, isDirty);
                };
            }

            if (key === "$path") {
                return target.$path;
            }

            if (key === "$handleSubmit") {
                return target.$handleSubmit;
            }

            if (key === "$reset") {
                return target.$reset;
            }

            if (key === "$allErrors") {
                return target.$allErrors;
            }

            if (key === "$isSubmitting") {
                return target.$isSubmitting;
            }

            if (key === "$isSubmitted") {
                return target.$isSubmitted;
            }

            if (key === "$state") {
                return target.$state;
            }

            if (key === "$pop") {
                return () => {
                    return target.$pop(target.$path);
                };
            }

            if (key === "$push") {
                return (...items: any[]) => {
                    return target.$push(target.$path, ...items);
                };
            }

            if (key === "$filter") {
                return (predicate: (value: any, index: number, array: any[]) => boolean) => {
                    return target.$filter(target.$path, predicate);
                };
            }

            if (key === "$map") {
                return (callbackfn: (value: any, index: number, array: any[]) => any) => {
                    const length = target.$getValue(target.$path)?.length ?? 0;

                    const itemsFormData: any[] = [];
                    for (let i = 0; i < length; i++) {
                        itemsFormData.push(receiver[i]);
                    }

                    return itemsFormData.map(callbackfn);
                };
            }

            if (!("nestedProxies" in target)) {
                target.nestedProxies = typeof key === "number" ? [] : {};
            }

            if (!(key in target.nestedProxies)) {
                target.nestedProxies[key] = new Proxy(
                    {
                        $path: [...target.$path, key],
                        $getValue: target.$getValue,
                        $setValue: target.$setValue,
                        $clearValue: target.$clearValue,
                        $hasValue: target.$hasValue,
                        $getErrors: target.$getErrors,
                        $setErrors: target.$setErrors,
                        $getIsValid: target.$getIsValid,
                        $setIsValid: target.$setIsValid,
                        $getIsPendingValidation: target.$getIsPendingValidation,
                        $setIsPendingValidation: target.$setIsPendingValidation,
                        $getIsValidating: target.$getIsValidating,
                        $setIsValidating: target.$setIsValidating,
                        $getIsDirty: target.$getIsDirty,
                        $setIsDirty: target.$setIsDirty,
                        $pop: target.$pop,
                        $push: target.$push,
                        $filter: target.$filter,
                    },
                    handler,
                );
            }

            return target.nestedProxies[key];
        },
        set: (target: any, key: any, value: any, receiver: any) => {
            console.warn("Form data should not be modified directly, you should use $setValue instead.");
            return false;
        },
    };

    return new Proxy(target, handler);
};

const getModifiedObject = (
    obj: any,
    path: string[],
    newValue: any,
    shouldClearEmptyObjects: boolean,
    shouldClearEmptyArrays: boolean,
) => {
    if (path.length === 0) {
        return newValue;
    }

    const isPropertyArray = path.length > 1 && !Number.isNaN(Number(path[1]));
    const propertyName = !Number.isNaN(Number(path[0])) ? Number(path[0]) : path[0];

    const resultObj = Array.isArray(obj) ? [...obj] : { ...obj };
    if (!(propertyName in resultObj)) {
        resultObj[propertyName] = isPropertyArray ? [] : {};
    }

    const nestedModifiedObject = getModifiedObject(
        resultObj[propertyName],
        path.slice(1),
        newValue,
        shouldClearEmptyObjects,
        shouldClearEmptyArrays,
    );

    if (Array.isArray(nestedModifiedObject) && nestedModifiedObject.length === 0 && shouldClearEmptyArrays) {
        if (typeof propertyName === "number") {
            resultObj.splice(propertyName, 1);
        } else {
            delete resultObj[propertyName];
        }
    } else if (
        (nestedModifiedObject === undefined ||
            (typeof nestedModifiedObject === "object" &&
                !(nestedModifiedObject instanceof Date) &&
                Object.keys(nestedModifiedObject).length === 0)) &&
        shouldClearEmptyObjects
    ) {
        if (typeof propertyName === "number") {
            resultObj.splice(propertyName, 1);
        } else {
            delete resultObj[propertyName];
        }
    } else {
        resultObj[propertyName] = nestedModifiedObject;
    }

    return resultObj;
};

const getFieldPaths = (obj: any, prefix?: string): string[] => {
    const result: string[] = [];

    Object.entries(obj).forEach(([key, value]) => {
        result.push(prefix ? `${prefix}.${key}` : key);

        if (isObject(value)) {
            result.push(...getFieldPaths(value, key));
        }
    });

    return result;
};

const getErrorsFromValidationResult = (validationResult: ValidationResult): Map<string, string[]> => {
    const errors = new Map<string, string[]>();
    addRecursiveErrors(validationResult, errors, []);
    return errors;
};

const addRecursiveErrors = (validationResult: ValidationResult, errors: Map<string, string[]>, path: string[]) => {
    if (validationResult.ErrorMessages.length > 0) {
        const key = path.join(".");
        errors.set(key, validationResult.ErrorMessages);
    }

    Object.entries(validationResult.NestedValidations).forEach(([key, nestedValidationResult]) => {
        addRecursiveErrors(nestedValidationResult, errors, [...path, key]);
    });
};
