import {
    DefaultButton,
    DialogFooter,
    DocumentLocationSelector,
    IDocumentLocationSelectorLocation,
    PrimaryButton,
    Spinner,
    useInCoreLocalization,
} from "@in-core";
import { useState } from "react";

export interface ILocationSelectorProps {
    onDismiss: () => void;
    onSelected: (libraryId: string, location: string[]) => Promise<void>;
}

const LocationSelector = (props: ILocationSelectorProps) => {
    const [location, setLocation] = useState<IDocumentLocationSelectorLocation | null>(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const localization = useInCoreLocalization();

    return (
        <>
            <DocumentLocationSelector location={location} onLocationChanged={setLocation} />

            <DialogFooter
                styles={{
                    actionsRight: {
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                    },
                    action: { display: "flex" },
                }}
            >
                {isProcessing && <Spinner />}

                <PrimaryButton
                    disabled={isProcessing}
                    onClick={async () => {
                        setIsProcessing(true);
                        await props.onSelected(location!.libraryId, location!.directoryPath);
                        setIsProcessing(false);
                        props.onDismiss && props.onDismiss();
                    }}
                >
                    {localization.Select}
                </PrimaryButton>

                <DefaultButton onClick={props.onDismiss} disabled={isProcessing}>
                    {localization.Cancel}
                </DefaultButton>
            </DialogFooter>
        </>
    );
};

export default LocationSelector;
