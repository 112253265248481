import {
    callApi as callGenericApi,
    useApi as useGenericApi,
    GetDataResponse,
    GetDataRequest,
    ValidationResult,
    Filter,
    Selector,
    Sort,
    Group,
} from "@in-core";
import { AggregateDataType, Note } from "..";

export namespace AddNote {
    export const URL = "CoreAPI/Entity/AddNote";

    export interface Request {
        Entity: string;
        KeyString: string;
        Text: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, string>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, string>(URL);
    };
}

export namespace CheckDelete {
    export const URL = "CoreAPI/Entity/CheckDelete";

    export interface Request {
        Permission: string;
        Key: any;
    }

    export interface Response {
        NumberOfRestrictItems: ForeignKeyItemCount[];
        NumberOfCascadeItems: ForeignKeyItemCount[];
    }

    export interface ForeignKeyItemCount {
        Id: string;
        Name?: string;
        Count: number;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, Response>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, Response>(URL);
    };
}

export namespace Create {
    export const URL = "CoreAPI/Entity/Create";

    export interface Request {
        Permission: string;
        Key?: any;
        Data: any;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, any>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, any>(URL);
    };
}

export namespace Delete {
    export const URL = "CoreAPI/Entity/Delete";

    export interface Request {
        Permission: string;
        Key: any;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, void>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, void>(URL);
    };
}

export namespace DeleteNote {
    export const URL = "CoreAPI/Entity/DeleteNote";

    export interface Request {
        Entity: string;
        KeyString: string;
        NoteId: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, void>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, void>(URL);
    };
}

export namespace DeleteView {
    export const URL = "CoreAPI/Entity/DeleteView";

    export interface Request {
        Permission: string;
        Id: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, void>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, void>(URL);
    };
}

export namespace DoesItemExist {
    export const URL = "CoreAPI/Entity/DoesItemExist";

    export interface Request {
        Permission: string;
        Key: any;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, boolean>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, boolean>(URL);
    };
}

export namespace ExportData {
    export const URL = "CoreAPI/Entity/ExportData";

    export interface Request extends GetDataRequest {
        Permission: string;
        FileName?: string;
        ExportType: ExportType;
    }

    export enum ExportType {
        PdfVertical = "PdfVertical",
        PdfHorizontal = "PdfHorizontal",
        Excel = "Excel",
        Csv = "Csv",
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, string>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, string>(URL);
    };
}

export namespace GetActivities {
    export const URL = "CoreAPI/Entity/GetActivities";

    export interface Request {
        Permission: string;
        Key: any;
    }

    export interface ActivityInfo {
        Id: string;
        GeneratedId: string;
        Name: string;
        Time: Date;
        Permission?: PermissionInfo;
        User?: UserInfo;
        ConnectedApp?: ConnectedAppInfo;
        AuditLogEntities: AuditLogEntityInfo[];
    }

    export interface PermissionInfo {
        Id: string;
        Name?: string;
        Icon?: string;
    }

    export interface UserInfo {
        Id?: any;
        UserName: string;
        Name?: string;
    }

    export interface ConnectedAppInfo {
        Id?: string;
        Name: string;
    }

    export interface AuditLogEntityInfo {
        Id: string;
        TableName?: string;
        Name?: string;
        AuditLogs: AuditLogInfo[];
    }

    export interface AuditLogInfo {
        Id: string;
        Type: AuditLogType;
        Time: Date;
        OldValues?: any;
        NewValues?: any;
    }

    export enum AuditLogType {
        Create = "Create",
        Update = "Update",
        Delete = "Delete",
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, ActivityInfo[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, ActivityInfo[]>(URL);
    };
}

export namespace GetAuditLogs {
    export const URL = "CoreAPI/Entity/GetAuditLogs";

    export interface Request {
        Permission: string;
        Key: any;
    }

    export interface AuditLogInfo {
        Id: string;
        Type: AuditLogType;
        Time: Date;
        OldValues?: any;
        NewValues?: any;
        User?: UserInfo;
        ConnectedApp?: ConnectedAppInfo;
        Activity?: ActivityInfo;
    }

    export enum AuditLogType {
        Create = "Create",
        Update = "Update",
        Delete = "Delete",
    }

    export interface UserInfo {
        Id?: any;
        UserName: string;
        Name?: string;
    }

    export interface ConnectedAppInfo {
        Id?: string;
        Name: string;
    }

    export interface ActivityInfo {
        GeneratedId: string;
        Name: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, AuditLogInfo[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, AuditLogInfo[]>(URL);
    };
}

export namespace GetData {
    export const URL = "CoreAPI/Entity/GetData";

    export interface Request extends GetDataRequest {
        Permission: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, GetDataResponse<any>>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, GetDataResponse<any>>(URL);
    };
}

export namespace GetEntityDescriptor {
    export const URL = "CoreAPI/Entity/GetEntityDescriptor";

    export interface Request {
        Permission: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, EntityDescriptor>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, EntityDescriptor>(URL);
    };
}

export namespace GetNotes {
    export const URL = "CoreAPI/Entity/GetNotes";

    export interface Request {
        Entity: string;
        KeyString: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, Note[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, Note[]>(URL);
    };
}

export namespace GetUserCurrentView {
    export const URL = "CoreAPI/Entity/GetUserCurrentView";

    export interface Request {
        Permission: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, ViewData | undefined>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, ViewData | undefined>(URL);
    };
}

export namespace GetViews {
    export const URL = "CoreAPI/Entity/GetViews";

    export interface Request {
        Permission: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, ViewInfo[]>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, ViewInfo[]>(URL);
    };
}

export namespace Read {
    export const URL = "CoreAPI/Entity/Read";

    export interface Request {
        Permission: string;
        Key: any;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, any>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, any>(URL);
    };
}

export namespace SaveView {
    export const URL = "CoreAPI/Entity/SaveView";

    export interface Request {
        Permission: string;
        Id?: string;
        IsPublic: boolean;
        Name: string;
        IsDefaultForCurrentUser: boolean;
        IsDefaultForPublic: boolean;
        ViewData?: ViewData;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, ViewInfo>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, ViewInfo>(URL);
    };
}

export namespace SetDefaultView {
    export const URL = "CoreAPI/Entity/SetDefaultView";

    export interface Request {
        Permission: string;
        Id?: string;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, void>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, void>(URL);
    };
}

export namespace SetUserCurrentView {
    export const URL = "CoreAPI/Entity/SetUserCurrentView";

    export interface Request {
        Permission: string;
        ViewData?: ViewData;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, void>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, void>(URL);
    };
}

export namespace Update {
    export const URL = "CoreAPI/Entity/Update";

    export interface Request {
        Permission: string;
        Key: any;
        Data: any;
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, any>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, any>(URL);
    };
}

export namespace Validate {
    export const URL = "CoreAPI/Entity/Validate";

    export interface Request {
        Permission: string;
        Data?: any;
        PathsToValidate?: string[][];
    }

    export const callApi = (request: Request) => {
        return callGenericApi<Request, ValidationResult>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<Request, ValidationResult>(URL);
    };
}

export enum DataType {
    String = "String",
    Number = "Number",
    Boolean = "Boolean",
    DateTime = "DateTime",
    Enum = "Enum",
}

export interface KeyDescriptor {
    PropertyIds: string[];
}

export interface DisplayDescriptor {
    Name?: string;
    Description?: string;
    GroupName?: string;
    Order?: number;
}

export interface PropertyEnumValue {
    Key: number;
    TextKey: string;
    Text: string;
}

export interface PropertyDescriptor {
    Id: string;
    IsAutomaticallyGenerated: boolean;
    DataType: DataType;
    EnumValues: PropertyEnumValue[];
    IsNullable: boolean;
    Display?: DisplayDescriptor;
}

export interface NavigationDescriptor {
    Id: string;
    IsOnDependent: boolean;
    ForeignKey: KeyDescriptor;
    TargetEntity: string;
    Display?: DisplayDescriptor;
    PrimaryKey: KeyDescriptor;
    DescriptionPropertyIds: string[];
}

export interface EntityDescriptor {
    Id: string;
    Properties: PropertyDescriptor[];
    Navigations: NavigationDescriptor[];
    PrimaryKey?: KeyDescriptor;
    Display: DisplayDescriptor;
    DescriptionPropertyIds: string[];
}

export interface ViewData {
    Filters?: Record<string, Filter>;
    Selectors?: Record<string, Selector>;
    Group?: Group;
    Sort?: Sort;
    PaginationCount?: number;
    AggregateDataTypes?: Record<string, AggregateDataType[]>;
}

export interface ViewInfo {
    Id: string;
    IsOwnedByCurrentUser: boolean;
    IsPublic: boolean;
    Name: string;
    IsDefaultForCurrentUser: boolean;
    IsDefaultForPublic: boolean;
    ViewData?: ViewData;
}
