import {
    DefaultButton,
    MessageBar,
    MessageBarType,
    ModalSpinner,
    PrimaryButton,
    Spinner,
    Stack,
    Text,
    useEntityDataTableContext,
    useInCoreLocalization,
    useOnChange,
    useTheme,
} from "@in-core";
import { CheckDelete, Delete } from "@in-core/api/Entity";

export interface IAutoDeleteProps {
    entity: string;
}

const AutoDelete = (props: IAutoDeleteProps) => {
    const checkDeleteApi = CheckDelete.useApi();
    const deleteApi = Delete.useApi();
    const entityDataTableContext = useEntityDataTableContext()!;
    const localization = useInCoreLocalization();
    const theme = useTheme();

    const getKey = () => {
        const selectedKey = entityDataTableContext.selectedKeys[0];
        const primaryKeyPropertyIds = entityDataTableContext.entityDescriptor.PrimaryKey!.PropertyIds;

        let key: any = {};
        if (primaryKeyPropertyIds.length === 1) {
            key[primaryKeyPropertyIds[0]] = selectedKey;
        } else {
            Object.keys(selectedKey).forEach((x) => {
                key[x] = selectedKey[x];
            });
        }

        return key;
    };

    useOnChange(() => {
        checkDeleteApi.call({ Permission: props.entity, Key: getKey() });
    }, [props.entity]);

    if (checkDeleteApi.isIdle || checkDeleteApi.isLoading || !checkDeleteApi.data) {
        return <Spinner label={localization.PleaseWait} />;
    }

    if (deleteApi.isError) {
        return (
            <Stack tokens={{ childrenGap: theme.spacing.m }}>
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                    {localization.EntityDataTable.DeleteError}
                </MessageBar>

                <DefaultButton
                    onClick={() => {
                        entityDataTableContext.close();
                    }}
                >
                    {localization.Ok}
                </DefaultButton>
            </Stack>
        );
    }

    const restrictCount = checkDeleteApi.data.NumberOfRestrictItems.filter((x) => {
        return x.Count > 0;
    });

    const cascadeCount = checkDeleteApi.data.NumberOfCascadeItems.filter((x) => {
        return x.Count > 0;
    });

    return (
        <>
            <Stack tokens={{ childrenGap: theme.spacing.m }}>
                {restrictCount.length > 0 && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        <Text>{localization.EntityDataTable.DeleteNotPossible}</Text>

                        <ul>
                            {restrictCount.map((x) => {
                                return (
                                    <li>
                                        {x.Name ?? x.Id} ({x.Count})
                                    </li>
                                );
                            })}
                        </ul>
                    </MessageBar>
                )}

                {cascadeCount.length > 0 && (
                    <MessageBar messageBarType={MessageBarType.warning}>
                        <Text>{localization.EntityDataTable.DeleteWarning}</Text>

                        <ul>
                            {cascadeCount.map((x) => {
                                return (
                                    <li>
                                        {x.Name ?? x.Id} ({x.Count})
                                    </li>
                                );
                            })}
                        </ul>
                    </MessageBar>
                )}

                {restrictCount.length === 0 && <Text>{localization.EntityDataTable.DeleteConfirm}</Text>}

                <Stack horizontal tokens={{ childrenGap: theme.spacing.s1 }}>
                    <PrimaryButton
                        disabled={deleteApi.isLoading || restrictCount.length > 0}
                        onClick={async () => {
                            const deleteApiResponse = await deleteApi.call({ Permission: props.entity, Key: getKey() });
                            if (deleteApiResponse.isSuccess) {
                                entityDataTableContext.success();
                                entityDataTableContext.refresh();
                                entityDataTableContext.close();
                            }
                        }}
                    >
                        {localization.Delete}
                    </PrimaryButton>

                    <DefaultButton
                        onClick={() => {
                            entityDataTableContext.close();
                        }}
                    >
                        {localization.Cancel}
                    </DefaultButton>
                </Stack>
            </Stack>

            <ModalSpinner isOpen={deleteApi.isLoading} />
        </>
    );
};

export default AutoDelete;
