import { callApi as callGenericApi, useApi as useGenericApi, ValidationResult } from "@in-core";

export namespace Create {
    export const URL = "CoreAPI/Users/Create";

    export const callApi = (request: any) => {
        return callGenericApi<any, any>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<any, any>(URL);
    };
}

export namespace Read {
    export const URL = "CoreAPI/Users/Read";

    export const callApi = (request: any) => {
        return callGenericApi<any, any>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<any, any>(URL);
    };
}

export namespace Update {
    export const URL = "CoreAPI/Users/Update";

    export const callApi = (request: any) => {
        return callGenericApi<any, any>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<any, any>(URL);
    };
}

export namespace ReadAll {
    export const URL = "CoreAPI/Users/ReadAll";

    export const callApi = (request: any) => {
        return callGenericApi<any, any>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<any, any>(URL);
    };
}

export namespace Validate {
    export const URL = "CoreAPI/Users/Validate";

    export const callApi = (request: any) => {
        return callGenericApi<any, ValidationResult>(URL, request);
    };

    export const useApi = () => {
        return useGenericApi<any, ValidationResult>(URL);
    };
}
