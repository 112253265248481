import React from "react";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import { Icon, IconButton, Stack, TextField, useInCoreLocalization, useTheme } from "@in-core";
import { fileNameRegex } from "..";
import { NewFileInfo } from "@in-core/api/Documents";

export interface IFileProps {
    file: NewFileInfo;
    onRemove: () => void;
    forceUpdate: () => void;
    existingNames: string[];
    disableRename: boolean;
}

const File = (props: IFileProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();

    let errorMessage: string | undefined = undefined;
    if (!fileNameRegex.test(props.file.Name)) {
        errorMessage = localization.FileUpload.IncorrectName;
    }
    if (
        props.existingNames
            .map((x) => {
                return x.toLowerCase();
            })
            .includes(props.file.Name.toLowerCase())
    ) {
        errorMessage = localization.FileUpload.FileAreadyExists;
    }

    return (
        <Stack
            horizontal
            verticalAlign="center"
            tokens={{
                padding: theme.spacing.s2,
                childrenGap: theme.spacing.s1,
            }}
            styles={{
                root: {
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    borderBottomColor: theme.palette.neutralLighter,
                    paddingLeft: 12,
                },
            }}
        >
            <Icon
                {...getFileTypeIconProps({
                    extension:
                        props.file.Name.split(".").length < 2 ? undefined : props.file.Name.split(".").reverse()[0],
                    size: 16,
                })}
            />

            <TextField
                value={props.file.Name}
                onChange={(_, newValue) => {
                    props.file.Name = newValue ?? "";
                    props.forceUpdate();
                }}
                styles={{
                    root: { flex: 1 },
                    fieldGroup: {
                        borderColor: errorMessage ? undefined : "transparent",
                        height: 28,
                    },
                    field: { paddingLeft: theme.spacing.s2 },
                    errorMessage: { display: "none" },
                }}
                errorMessage={errorMessage}
                readOnly={props.disableRename}
            />

            <IconButton
                iconProps={{ iconName: "Cancel" }}
                onClick={() => {
                    return props.onRemove();
                }}
            />
        </Stack>
    );
};

export default File;
