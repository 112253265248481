import { Permission, Spinner, UseForm, useEntityDataTableContext } from "@in-core";
import MenuManager, { IMenuItem } from "@in-core/ui/MenuManager";
import { getDefaultRoleMenu } from "@in-core/ui/Roles/DataEntry/RoleMenu";

export interface IUserMenuProps {
    form: UseForm;
    permissions?: Permission[];
    roles: any[];
}

const UserMenu = (props: IUserMenuProps) => {
    const form = props.form as any;
    const entityDataTableContext = useEntityDataTableContext()!;

    const isRead = entityDataTableContext.innerPermission?.Id === "Read";

    if (!props.permissions) {
        return <Spinner />;
    }

    const userRemovedPermissionIds = (form.RemovedPermissions.$value as string[] | undefined) ?? [];
    const userAddedPermissionIds = (form.AddedPermissions.$value as string[] | undefined) ?? [];

    return (
        <MenuManager
            menuItems={form.Menu.$value}
            defaultMenuItems={getDefaultUserMenu(
                props.roles,
                userRemovedPermissionIds,
                userAddedPermissionIds,
                props.permissions,
            )}
            onMenuItemsChanged={(menuItems) => {
                form.Menu.$setValue(menuItems);
            }}
            allowedPermissions={getUserAllowedPermissions(
                props.roles,
                userRemovedPermissionIds,
                userAddedPermissionIds,
                props.permissions,
            )}
            disabled={isRead}
        />
    );
};

export default UserMenu;

export const getUserAllowedPermissions = (
    roles: any[],
    removedPermissions: string[],
    addedPermissions: string[],
    permissions: Permission[],
): Permission[] => {
    const allowedPermissionIds = Array.from(
        new Set(
            roles
                .flatMap((x) => {
                    return x.Permissions as string[];
                })
                .filter((x) => {
                    return !removedPermissions.includes(x);
                })
                .concat(addedPermissions),
        ),
    );

    return permissions.filter((x) => {
        return allowedPermissionIds.includes(x.CompleteId);
    });
};

export const getDefaultUserMenu = (
    roles: any[],
    removedPermissions: string[],
    addedPermissions: string[],
    permissions: Permission[],
): IMenuItem[] => {
    const result: IMenuItem[] = [];

    roles.forEach((x) => {
        result.push({
            Id: `__role-${x.Id}`,
            Text: x.Description,
            Submenus: getDefaultRoleMenu(x.Permissions, permissions),
        });
    });

    removePermissions(result, removedPermissions);

    result.push(...getDefaultRoleMenu(addedPermissions, permissions));

    return sortMenuItems(result, permissions);
};

const sortMenuItems = (menuItems: IMenuItem[], permissions: Permission[]): IMenuItem[] => {
    return menuItems
        .sort((a, b) => {
            if (a.Submenus !== undefined && b.Submenus === undefined) {
                return -1;
            }

            if (a.Submenus === undefined && b.Submenus !== undefined) {
                return 1;
            }

            const aText =
                a.Text ??
                (a.PermissionId
                    ? permissions.find((x) => {
                          return x.CompleteId === a.PermissionId;
                      })?.Name
                    : a.Id) ??
                a.Id ??
                "";

            const bText =
                b.Text ??
                (b.PermissionId
                    ? permissions.find((x) => {
                          return x.CompleteId === b.PermissionId;
                      })?.Name
                    : b.Id) ??
                b.Id ??
                "";

            return aText.localeCompare(bText);
        })
        .map((x) => {
            return { ...x, Submenus: x.Submenus ? sortMenuItems(x.Submenus!, permissions) : undefined };
        });
};

const removePermissions = (removeFrom: IMenuItem[], toRemove: string[]): IMenuItem[] => {
    return removeFrom
        .filter((x) => {
            return !x.PermissionId || !toRemove.includes(x.PermissionId);
        })
        .map((x) => {
            return {
                ...x,
                Submenus: x.Submenus ? removePermissions(x.Submenus, toRemove) : undefined,
            };
        });
};
