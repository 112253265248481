import { Label, Text, useInCoreLocalization, useTheme } from "@in-core";
import { GetActivities } from "@in-core/api/Entity";

export interface IDetailsSectionProps {
    activity: GetActivities.ActivityInfo;
}

const DetailsSection = (props: IDetailsSectionProps) => {
    const theme = useTheme();
    const localization = useInCoreLocalization();

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "minmax(100px, 25%) 1fr",
                alignItems: "center",
                justifyItems: "start",
                rowGap: theme.spacing.s2,
                columnGap: theme.spacing.m,
            }}
        >
            <Label styles={{ root: { gridColumn: "1", gridRow: "1" } }}>ID</Label>

            <Text
                styles={{
                    root: {
                        gridColumn: "2",
                        gridRow: "1",
                        padding: "2px 6px",
                        borderRadius: theme.effects.roundedCorner2,
                    },
                }}
            >
                {props.activity.Id}
            </Text>

            <Label styles={{ root: { gridColumn: "1", gridRow: "2" } }}>
                {localization.EntityDataTable.ActivityGeneratedId}
            </Label>

            <Text
                styles={{
                    root: {
                        gridColumn: "2",
                        gridRow: "2",
                        padding: "2px 6px",
                        borderRadius: theme.effects.roundedCorner2,
                    },
                }}
            >
                {props.activity.GeneratedId}
            </Text>

            <Label styles={{ root: { gridColumn: "1", gridRow: "3" } }}>
                {localization.EntityDataTable.ActivityName}
            </Label>

            <Text
                styles={{
                    root: {
                        gridColumn: "2",
                        gridRow: "3",
                        padding: "2px 6px",
                        borderRadius: theme.effects.roundedCorner2,
                    },
                }}
            >
                {props.activity.Name}
            </Text>

            <Label styles={{ root: { gridColumn: "1", gridRow: "4" } }}>
                {localization.EntityDataTable.ActivityTime}
            </Label>

            <Text
                styles={{
                    root: {
                        gridColumn: "2",
                        gridRow: "4",
                        padding: "2px 6px",
                        borderRadius: theme.effects.roundedCorner2,
                    },
                }}
            >
                {props.activity.Time.toLocaleString("hr-HR")}
            </Text>

            <Label styles={{ root: { gridColumn: "1", gridRow: "5" } }}>
                {localization.EntityDataTable.ActivityPermission}
            </Label>

            <Text
                styles={{
                    root: {
                        gridColumn: "2",
                        gridRow: "5",
                        padding: "2px 6px",
                        borderRadius: theme.effects.roundedCorner2,
                    },
                }}
            >
                {props.activity.Permission ? `${props.activity.Permission.Name} (${props.activity.Permission.Id})` : ""}
            </Text>

            <Label styles={{ root: { gridColumn: "1", gridRow: "6" } }}>
                {localization.EntityDataTable.ActivityUser}
            </Label>

            <Text
                styles={{
                    root: {
                        gridColumn: "2",
                        gridRow: "6",
                        padding: "2px 6px",
                        borderRadius: theme.effects.roundedCorner2,
                    },
                }}
            >
                {props.activity.User
                    ? `${props.activity.User.Name} (${props.activity.User.UserName}, ${props.activity.User.Id})`
                    : ""}
            </Text>

            <Label styles={{ root: { gridColumn: "1", gridRow: "7" } }}>
                {localization.EntityDataTable.ActivityConnectedApp}
            </Label>

            <Text
                styles={{
                    root: {
                        gridColumn: "2",
                        gridRow: "7",
                        padding: "2px 6px",
                        borderRadius: theme.effects.roundedCorner2,
                    },
                }}
            >
                {props.activity.ConnectedApp
                    ? `${props.activity.ConnectedApp.Name} (${props.activity.ConnectedApp.Id})`
                    : ""}
            </Text>
        </div>
    );
};

export default DetailsSection;
